import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Box, IconButton, Hidden, Tooltip } from "@mui/material";

const selectedStyle = {
  fontSize: "16px",
  fontWeight: "500",
  backgroundColor: "#E1ECF4",
  height: "84px",
  borderRadius: "5px",
};

const unselectedStyle = {
  fontSize: "16px",
  fontWeight: "500",
  height: "84px",
  borderRadius: "5px",
  backgroundColor: "#EEECE7",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "background.lighter",
  },
  "&:active": {
    cursor: "pointer",
    backgroundColor: "background.light",
  },
};

const ReviewingProducer = () => {
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState(false);
  const [companyRawWastes, setCompanyRawWastes] = useState(false);
  const [collectionPointList, setCollectionPointList] = useState(false);

  return (
    <>
      <Stack
        flexDirection={"column"}
        mx={20}
        mt={10}
        mb={10}
        sx={{ overflow: "hidden" }}
      >
        <Stack mb={5}>
          <Box
            sx={{
              fontSize: "40px",
              fontWeight: "400",
              lineHeight: "50px",
              letterSpacing: "-1%",
              marginBottom: "16px",
            }}
          >
            Welcome,
          </Box>
          <Box
            sx={{
              fontSize: "64px",
              fontWeight: "400",
              lineHeight: "80px",
              letterSpacing: "-2%",
              marginBottom: "16px",
            }}
          >
            UCO.Network
          </Box>
          <Box
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "24px",
              fontFamily: "Manrope",
            }}
            width={"400px"}
          >
            Let's quickly set up your Producer profile.
            <br />
            <br />
            This setup will guide you in selecting the types of raw waste you
            produce and adding your collection points to the list.
          </Box>
        </Stack>

        <Stack mt={5}>
          <Stack
            flexDirection={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Stack
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "32px",
                letterSpacing: "-1%",
                justifyContent: "flex-start",
              }}
            >
              Start your Producer profile
            </Stack>
            <Stack
              flexDirection={"row"}
              sx={{
                alignItems: "flex-start",
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "12px",
                letterSpacing: "3%",
                color: "text.accent",
              }}
            >
              EARNED:
              <Box
                sx={{
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "10px",
                  color: "black",
                }}
              >
                {`${
                  ((companyId & 1) +
                    (companyRawWastes & 1) +
                    (collectionPointList & 1)) *
                  1000
                } Credits`}
              </Box>
            </Stack>
          </Stack>
          <Stack>
            <Box display={"flex"} flexDirection={"row"} py={4} gap={2}>
              <Stack
                flex={1}
                gap={2}
                padding={2}
                justifyContent={"space-between"}
                sx={companyId ? selectedStyle : unselectedStyle}
                onClick={() => {
                  if (!companyId) navigate("/auth/create-company-id");
                }}
              >
                <Box>Company ID</Box>
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: "500",
                      fontSize: "10px",
                      lineHeight: "10px",
                    }}
                  >
                    EARN 1000 credits
                  </Box>
                  <IconButton>
                    {companyId ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <rect width="20" height="20" rx="10" fill="green" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.2222 7.84843L13.3528 6.00018L9 10.3038L6.86942 8.22241L5 10.0707L9 14.0002L15.2222 7.84843Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.25628 0.251051C7.59799 -0.0836838 8.15201 -0.0836838 8.49372 0.251051L13.7437 5.39391C13.9078 5.55466 14 5.77267 14 6C14 6.22733 13.9078 6.44535 13.7437 6.60609L8.49372 11.7489C8.15201 12.0837 7.59799 12.0837 7.25628 11.7489C6.91457 11.4142 6.91457 10.8715 7.25628 10.5368L11.0126 6.85714L0.875 6.85714C0.391751 6.85714 0 6.47339 0 6C0 5.52661 0.391751 5.14286 0.875 5.14286L11.0126 5.14286L7.25628 1.46323C6.91457 1.1285 6.91457 0.585786 7.25628 0.251051Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </IconButton>
                </Stack>
              </Stack>
              <Stack
                flex={1}
                gap={2}
                padding={2}
                justifyContent={"space-between"}
                sx={companyRawWastes ? selectedStyle : unselectedStyle}
                onClick={() => {
                  navigate("/define/waste-in-producer");
                }}
              >
                <Box>Company Raw Wastes</Box>
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: "500",
                      fontSize: "10px",
                      lineHeight: "10px",
                    }}
                  >
                    EARN 1000 credits
                  </Box>
                  <IconButton>
                    {companyRawWastes ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <rect width="20" height="20" rx="10" fill="green" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.2222 7.84843L13.3528 6.00018L9 10.3038L6.86942 8.22241L5 10.0707L9 14.0002L15.2222 7.84843Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.25628 0.251051C7.59799 -0.0836838 8.15201 -0.0836838 8.49372 0.251051L13.7437 5.39391C13.9078 5.55466 14 5.77267 14 6C14 6.22733 13.9078 6.44535 13.7437 6.60609L8.49372 11.7489C8.15201 12.0837 7.59799 12.0837 7.25628 11.7489C6.91457 11.4142 6.91457 10.8715 7.25628 10.5368L11.0126 6.85714L0.875 6.85714C0.391751 6.85714 0 6.47339 0 6C0 5.52661 0.391751 5.14286 0.875 5.14286L11.0126 5.14286L7.25628 1.46323C6.91457 1.1285 6.91457 0.585786 7.25628 0.251051Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </IconButton>
                </Stack>
              </Stack>
              <Stack
                flex={1}
                gap={2}
                padding={2}
                justifyContent={"space-between"}
                sx={collectionPointList ? selectedStyle : unselectedStyle}
                onClick={() => {
                  navigate("/define/collection-points");
                }}
              >
                <Box>Collection Point List</Box>
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: 500,
                      fontSize: "10px",
                      lineHeight: "10px",
                    }}
                  >
                    EARN 1000 credits
                  </Box>
                  <IconButton>
                    {collectionPointList ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <rect width="20" height="20" rx="10" fill="green" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.2222 7.84843L13.3528 6.00018L9 10.3038L6.86942 8.22241L5 10.0707L9 14.0002L15.2222 7.84843Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.25628 0.251051C7.59799 -0.0836838 8.15201 -0.0836838 8.49372 0.251051L13.7437 5.39391C13.9078 5.55466 14 5.77267 14 6C14 6.22733 13.9078 6.44535 13.7437 6.60609L8.49372 11.7489C8.15201 12.0837 7.59799 12.0837 7.25628 11.7489C6.91457 11.4142 6.91457 10.8715 7.25628 10.5368L11.0126 6.85714L0.875 6.85714C0.391751 6.85714 0 6.47339 0 6C0 5.52661 0.391751 5.14286 0.875 5.14286L11.0126 5.14286L7.25628 1.46323C6.91457 1.1285 6.91457 0.585786 7.25628 0.251051Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
            <Box
              position={"relative"}
              height={5}
              borderRadius={5}
              width={"100%"}
              sx={{
                backgroundColor: "#EEECE7",
              }}
            >
              <Box
                height={5}
                borderRadius={5}
                width={
                  companyId === true &&
                  companyRawWastes === true &&
                  collectionPointList === true
                    ? "100%"
                    : `${
                        ((companyId & 1) +
                          (companyRawWastes & 1) +
                          (collectionPointList & 1)) *
                        33
                      }%`
                }
                sx={{
                  backgroundColor: "rgba(0, 25, 90, 1)",
                }}
              ></Box>
            </Box>
            <Stack
              sx={{
                alignItems: "flex-end",
                fontSize: "10px",
                lineHeight: "10px",
                fontWeight: "500",
              }}
              mt={2}
            >
              YOUR PRODUCER PROFILE IS &nbsp;
              {companyId === true &&
              companyRawWastes === true &&
              collectionPointList &&
              true
                ? "100% CONFIGURED"
                : `${
                    ((companyId & 1) +
                      (companyRawWastes & 1) +
                      (collectionPointList & 1)) *
                    33
                  }% CONFIGURED`}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ReviewingProducer;
