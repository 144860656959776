import { team_axios, org_axios } from "../utils/axios";

// add new member
const createTeam = (data) => {
  return team_axios.post("/teams/create-team", data);
};

//fetch

const fetchDaysOff = (id) => {
  return org_axios.get(`/list-days-off/${id}`);
};

const fetchRoleList = () => {
  return team_axios.get("/roles/list-roles");
};

const fetchMemberList = () => {
  return org_axios.get("/list-members");
};
const fetchUserList = () => {
  return org_axios.get("/list-all-users");
};
const fetchTeamList = () => {
  return team_axios.get("/teams/list-teams");
};

// change
const changeTeamName = (id, data) => {
  return team_axios.put(`/teams/change-team-name/${id}`, data);
};

const archiveTeam = (id) => {
  return team_axios.put(`/teams/archive-team/${id}`);
};

const unarchiveTeam = (id) => {
  return team_axios.put(`/teams/unarchive-team/${id}`);
};

const allowMember = (id) => {
  return org_axios.put(`/allow-member/${id}`);
};

const disallowMember = (id) => {
  return org_axios.put(`/disallow-member/${id}`);
};

const allowTeam = (id) => {
  return team_axios.put(`/teams/allow-team/${id}`);
};
const disallowTeam = (id) => {
  return team_axios.put(`/teams/disallow-team/${id}`);
};

const inviteMember = (data) => {
  return org_axios.post("/invite-a-member", data);
};

const cancelInvite = (id) => {
  return org_axios.delete(`/cancel-invite/${id}`);
};

const addDaysOff = (id, data) => {
  return org_axios.post(`/add-days-off/${id}`, data);
};

const deleteDayOff = (dayOffId) => {
  return org_axios.delete(`/delete-day-off/${dayOffId}`);
};

const editDayOff = (id, data) => {
  return org_axios.put(`/edit-day-off/${id}`, data);
};

const changeMemberRole = (userId, role) => {
  return org_axios.put(`/change-member-role/${userId}`, {
    role,
  });
};
const archiveMember = (userId) => {
  return org_axios.put(`/archive-member/${userId}`);
};
const moveMemberTeam = (userId, team) => {
  return org_axios.put(`/move-member-to/${userId}/${team}`);
};

const MemberService = {
  archiveMember,
  moveMemberTeam,
  changeMemberRole,
  archiveTeam,
  unarchiveTeam,
  createTeam,
  fetchTeamList,
  changeTeamName,
  fetchUserList,
  fetchMemberList,
  fetchDaysOff,
  disallowTeam,
  allowTeam,
  fetchRoleList,
  inviteMember,
  cancelInvite,
  allowMember,
  disallowMember,
  addDaysOff,
  deleteDayOff,
  editDayOff,
};
export default MemberService;
