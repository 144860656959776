import { createSlice } from "@reduxjs/toolkit";
import { STATE_ACTIVE } from "../utils/type";

const initialState = {
  currentZone: 0,
  zoneList: [],
  transferList: [],
  totalCollected: 0,
  currentZonePickup: [],
  currentZoneData: null,
  visitHistory: [],
  scheduledVisitHistory: [],
  extraInfo: {
    count_of_producers: 0,
    count_of_pickup_points: 0,
    total_collected: 0,
  },
  visitList: [],
  scheduledVisitList: [],
  zoneRoutes: [],
};

export const zoneSlice = createSlice({
  name: "producer",
  initialState,
  reducers: {
    setScheduledVisitHistory: (state, action) => {
      state.scheduledVisitHistory = action.payload;
    },
    setVisitHistory: (state, action) => {
      state.visitHistory = action.payload;
    },
    setZoneScheduledVisitList: (state, action) => {
      state.scheduledVisitList = action.payload;
    },
    setZoneVisitList: (state, action) => {
      state.visitList = action.payload;
    },
    setTransferList: (state, action) => {
      state.transferList = action.payload;
    },
    setTotalCollected: (state, action) => {
      state.totalCollected = action.payload;
    },
    setCurrentZone: (state, action) => {
      state.currentZone = action.payload;
    },
    setZoneList: (state, action) => {
      state.zoneList = action.payload;
    },
    setZoneDetail: (state, action) => {
      state.currentZoneData = action.payload;
    },
    setZonePickup: (state, action) => {
      state.currentZonePickup = action.payload;
    },
    setZoneExtraInfo: (state, action) => {
      state.extraInfo = action.payload;
    },
    setZoneRoutes: (state, action) => {
      state.zoneRoutes = action.payload;
    },
  },
});

export const {
  setZoneRoutes,
  setTransferList,
  setVisitHistory,
  setScheduledVisitHistory,
  setTotalCollected,
  setCurrentZone,
  setZonePickup,
  setZoneDetail,
  setZoneList,
  setZoneExtraInfo,
  setZoneVisitList,
  setZoneScheduledVisitList,
} = zoneSlice.actions;

export default zoneSlice.reducer;
