import React from "react";
// import { Link } from "react-router-dom";

// material-ui
import { Typography, Button, Stack, Box } from "@mui/material";

// third party

// assets
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { classes } from "../../../utils/class";
import {
  accpetInvite,
  fetchAllInvites,
  rejectInvite,
  selectOrganization,
} from "../../../actions/organizations";
import AuthenticationService from "../../../services/AuthenticationService";
import { STATE_PENDING, STATE_REJECTED, SUCCESS } from "../../../utils/type";
import { showNotification } from "../../../utils/helpers";
import { signOut } from "../../../actions/user";
import { Android12Switch } from "../../../components/CustomInput";

import {
  OrganizationIcon,
  UcoIcon,
} from "../../../components/icons/menu_icons/icons";
import { StateSpan } from "../../../components/StateSpan";
import { BACKOFFICE, ORG_TYPES, OWNER } from "../../../utils/constants";

const SelectOrganizationForSigninPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector((state) => state.user.profile);
  const role = useSelector((state) => state.user.role);
  const handleCreateOrganization = () => {
    navigate("/auth/create-organization");
  };
  const redirect = new URLSearchParams(location.search).get("redirect");
  const { invites } = useSelector((state) => state.organizations);
  const [isVerified, setIsVerified] = useState(null);
  const [rejected, setRejected] = useState(false);
  const resendConfirmationEmail = (email) => {
    AuthenticationService.resendConfirmationEmail({ email })
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("An email has been sent to validate", "success");
        }
      })
      .catch((err) => {
        showNotification(
          "An error occurred while sending email.Please try again.",
          "error"
        );
      });
  };
  useEffect(() => {
    dispatch(fetchAllInvites());
  }, [dispatch]);
  useEffect(() => {
    if (profile?.email) {
      AuthenticationService.checkIfUserVerified(profile.email)
        .then((res) => {
          setIsVerified(res.data.data.verified);
        })
        .catch((err) => {});
    }
  }, [profile]);
  return (
    <Stack
      flexDirection={"row"}
      minHeight={"100vh"}
      width={"100%"}
      justifyContent={"space-between"}
    >
      <Stack
        sx={{
          display: { xs: "none", sm: "none", md: "none", lg: "block" },
        }}
        justifyContent={"space-between"}
        position={"relative"}
      >
        <Box
          position={"fixed"}
          height={"100vh"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          width={"40%"}
          zIndex={0}
          sx={{
            backgroundColor: "#C0D4E4",
          }}
        >
          <Box position={"absolute"} bottom={0} left={0} width={"100%"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                width: "100%",
              }}
              viewBox="0 0 640 490"
              fill="none"
            >
              <path
                opacity="0.1"
                d="M667.166 314.81C661.716 263.159 615.288 225.82 563.658 231.168C511.977 236.615 474.518 282.968 479.968 334.619C480.563 340.413 480.91 346.306 480.91 352.149C480.91 442.427 407.428 515.868 317.099 515.868C226.77 515.868 153.288 442.427 153.288 352.149C153.288 346.306 153.586 340.462 154.18 334.718C154.18 334.52 154.18 334.321 154.18 334.123C162.653 250.927 232.964 187.688 316.653 187.985C368.531 188.183 410.797 146.337 411.045 94.4884C411.293 42.5898 369.423 0.298362 317.495 0.00123245C268.59 -0.246375 228.257 36.8453 223.45 84.485C214.978 167.731 144.815 231.118 61.1262 230.821C13.2614 230.524 -27.9639 266.477 -33.0675 315.058C-34.3558 327.289 -35 339.818 -35 352.199C-35 546.175 122.915 704 317 704C511.085 704 669 546.175 669 352.199C669 339.769 668.356 327.19 667.018 314.859L667.166 314.81Z"
                fill="#7783A1"
              />
            </svg>
          </Box>
          <Box px={5} pt={4}>
            <UcoIcon fill="black" />
          </Box>
          <Stack px={5} flex={0.5}>
            <Stack flexDirection={"column"}>
              <Typography sx={{ fontSize: "40px", color: "#6D6D6D" }}>
                Welcome,
              </Typography>
              <Typography sx={{ fontSize: "32px", fontWeight: 700 }}>
                {profile.name}
              </Typography>
            </Stack>
            <Stack
              justifyContent={"space-between"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              {isVerified !== true && (
                <Stack
                  sx={{
                    fontFamily: "Manrope",
                    borderRadius: "20px",
                    backgroundColor: "#EBF155",
                    paddingX: 1,
                    paddingY: 0.5,
                  }}
                >
                  Unverified email address
                </Stack>
              )}
              {isVerified !== true && (
                <Button
                  onClick={() => {
                    resendConfirmationEmail(profile.email);
                  }}
                  sx={{
                    color: "text.accent",
                    fontFamily: "Manrope",
                    fontSize: "16px",
                  }}
                >
                  Re-send confirmation email
                </Button>
              )}
            </Stack>
          </Stack>
          <Stack
            pb={4}
            px={5}
            sx={{
              color: "#AFAFAF",
              fontSize: "12px",
            }}
          >
            © 2022 Uco software v2.0.23.0
          </Stack>
        </Box>
      </Stack>
      <Stack
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "60%" },
        }}
        zIndex={1}
      >
        <Stack
          sx={{
            backgroundColor: "white",
            minHeight: "100vh",
          }}
          alignItems={"center"}
          gap={2}
          px={5}
          py={4}
        >
          <Stack
            width={"100%"}
            flexDirection="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
            mb={20}
          >
            <Typography variant="body1">Not {`${profile.name}`}?</Typography>
            <Button
              sx={classes.buttonCancel}
              onClick={() => {
                dispatch(signOut());
              }}
            >
              Sign out
            </Button>
          </Stack>
          <Stack gap={1}>
            <Stack
              alignItems={"center"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                },
                width: {
                  xs: "350px",
                  sm: "400px",
                  md: "450px",
                  lg: "550px",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "text.accent",
                  fontFamily: "Manrope",
                }}
              >
                Please select an organization
              </Typography>
              <Button
                variant="outlined"
                sx={classes.orgCreateButton}
                onClick={() => {
                  if (isVerified) handleCreateOrganization();
                  else {
                    showNotification(
                      "Organization creation requires email verification. Please verify your email before proceeding.",
                      "error"
                    );
                  }
                }}
              >
                Create an Organization
              </Button>
            </Stack>
            <Stack gap={1}>
              {invites
                .filter((org) => org.status !== STATE_REJECTED)
                .map((org, index) => {
                  return (
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      sx={classes.organization}
                      key={index}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          color: "text.orgheader",
                          fontSize: "18px",
                          fontWeight: "700",
                          lineHeight: "150%",
                          letterSpacing: "-0.18px",
                          gap: 1,
                        }}
                      >
                        <Stack>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            {org.org_name}
                            {org.status === "pending" && (
                              <StateSpan status={STATE_PENDING} />
                            )}
                          </Box>
                          <Box
                            sx={{
                              fontSize: "16px",
                              color: "#464A53",
                              fontWeight: "500",
                            }}
                          >
                            {ORG_TYPES[org.org_type]}
                          </Box>
                        </Stack>
                      </Box>
                      {org.status === "pending" && (
                        <Stack flexDirection={"row"} gap={1}>
                          <Stack
                            disabled={!isVerified}
                            sx={classes.enterOrganization}
                            onClick={() => {
                              dispatch(rejectInvite(org.org));
                            }}
                          >
                            <CloseIcon />
                            Reject
                          </Stack>
                          <Stack
                            disabled={!isVerified}
                            sx={classes.enterOrganization}
                            onClick={() => {
                              dispatch(accpetInvite(org.org));
                            }}
                          >
                            <CheckIcon />
                            Accept
                          </Stack>
                        </Stack>
                      )}
                      {org.status === "accepted" && (
                        <Stack flexDirection={"row"} gap={1}>
                          <Stack
                            disabled={!isVerified}
                            sx={classes.enterOrganization}
                            gap={1}
                            flexDirection={"row"}
                            alignItems={"center"}
                            onClick={() => {
                              dispatch(
                                selectOrganization({ org_id: org.org }, () => {
                                  if (redirect) navigate(redirect);
                                  else navigate("/dashboard");
                                })
                              );
                            }}
                          >
                            Select
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.8877 8.42372C12.9433 8.52849 12.9749 8.64802 12.9749 8.7749C12.9749 8.947 12.9169 9.10556 12.8194 9.23213C12.798 9.25996 12.7746 9.28624 12.7496 9.31078L9.25513 12.8052C8.96223 13.0981 8.48736 13.0981 8.19447 12.8052C7.90157 12.5123 7.90157 12.0375 8.19447 11.7446L10.4141 9.5249L4.72485 9.5249C4.31064 9.5249 3.97485 9.18912 3.97485 8.7749C3.97485 8.36069 4.31064 8.0249 4.72485 8.0249L10.4141 8.0249L8.19447 5.80523C7.90157 5.51234 7.90157 5.03747 8.19447 4.74457C8.48736 4.45168 8.96223 4.45168 9.25513 4.74457L12.7498 8.23921C12.7737 8.26262 12.796 8.28762 12.8166 8.31403C12.8438 8.3489 12.8675 8.38562 12.8877 8.42372Z"
                                fill="#23222D"
                              />
                            </svg>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  );
                })}
              {invites.filter((org, index) => {
                return org.status !== "rejected";
              }).length === 0 && (
                <Box sx={classes.noOrganization} gap={1}>
                  <OrganizationIcon fill="#33343F" />
                  <Box
                    sx={{
                      fontSize: "24px",
                      color: "#282828",
                      fontWeight: "400",
                    }}
                  >
                    You don't belong to an organization yet.
                  </Box>
                  <Box
                    sx={{
                      fontSize: "16px",
                      color: "#282828",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    Please &nbsp;
                    <Box
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => {
                        if (isVerified) handleCreateOrganization();
                        else {
                          showNotification(
                            "Organization creation requires email verification. Please verify your email before proceeding.",
                            "error"
                          );
                        }
                      }}
                    >
                      create an organization
                    </Box>
                    &nbsp; to begin.
                  </Box>
                </Box>
              )}
            </Stack>
            <Stack gap={1}>
              <Stack
                alignItems={"flex-end"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: "text.accent",
                    fontSize: "14px",
                  }}
                  my={1}
                >
                  <Android12Switch
                    checked={rejected}
                    onChange={(e) => {
                      setRejected(e.target.checked);
                    }}
                  />
                  &nbsp; REJECTED INVITES
                </Typography>
              </Stack>
              {rejected && (
                <Stack gap={1}>
                  {invites
                    .filter((org) => {
                      return org.status === "rejected";
                    })
                    .map((org, index) => {
                      return (
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          sx={classes.organization}
                          key={index}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              color: "text.orgheader",
                              fontSize: "18px",
                              fontWeight: "700",
                              lineHeight: "150%",
                              letterSpacing: "-0.18px",
                              gap: 1,
                            }}
                          >
                            <Stack>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                {org.org_name}
                                {org.status === "rejected" && (
                                  <StateSpan status={STATE_REJECTED} />
                                )}
                              </Box>
                              <Box
                                sx={{
                                  fontSize: "16px",
                                  color: "#464A53",
                                  fontWeight: "500",
                                }}
                              >
                                {ORG_TYPES[org.org_type]}
                              </Box>
                            </Stack>
                          </Box>
                          <Box></Box>
                        </Stack>
                      );
                    })}
                  {invites.filter((org, index) => {
                    return org.status === "rejected";
                  }).length === 0 && (
                    <Box
                      sx={{
                        fontStyle: "italic",
                        fontSize: "18px",
                        fontWeight: "100",
                        color: "#C4C4C4",
                      }}
                    >
                      You have no rejected invitations
                    </Box>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelectOrganizationForSigninPage;
