import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";

// material-ui
import {
  Typography,
  Grid,
  Button,
  Stack,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { classes } from "../../../utils/class";
import { useNavigate } from "react-router-dom";
import { InputTitle, SubTitle } from "../../../components/Typography";
import {
  CAutoComplete,
  COfficialIdInput,
  CPhoneInput,
  CTextInput,
} from "../../../components/CustomInput";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { COUNTRIES_INFO, ERROR } from "../../../utils/constants";
import { fetchAllRawWastes } from "../../../actions/waste";

import OrganizationService from "../../../services/OrganizationService";
import { SUCCESS } from "../../../utils/type";
import { showNotification } from "../../../utils/helpers";
import FileService from "../../../services/FileService";
import { setUploadSpinnerStatus } from "../../../actions/spinner";
import { selectOrganization } from "../../../actions/organizations";
import { CustomMap } from "../../../components/CustomMap";
import moment from "moment-timezone";

const CreateOrganizationDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.user);
  const raw_wastes = useSelector((state) => state.waste.raw_wastes);
  const [redAccess, setRedAccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [raw_waste, setRawWaste] = useState(null);
  // variables

  const [company_name, setCompanyName] = useState("");
  const [country, setCountry] = useState(null);
  const [vat_code, setVatCode] = useState(null);
  const [vat_number, setVatNumber] = useState("");

  const [location_data, setLocationData] = useState(null);

  const [line_code, setLineCode] = useState();
  const [mobile_code, setMobileCode] = useState(null);
  const [line_number, setLineNumber] = useState(null);
  const [mobile_number, setMobileNumber] = useState(null);
  const [email, setEmail] = useState(null);

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [red_code, setRedCode] = useState(null);
  const [red_number, setRedNumber] = useState();
  const [uploadedDocument, setUploadedDocument] = useState();
  const [skipped, setSkipped] = useState(false);
  const [profile_url, setProfileUrl] = useState();
  const [timezone, setTimeZone] = useState();

  const [validation, setValidation] = useState(false);
  const handleSave = () => {
    const type = "wmo";
    if (vat_number && vat_code && company_name && country && timezone) {
      let data = {};
      if (redAccess) {
        // red is enabled
        data = {
          type,
          company_name,
          country: country.value,
          vat_number,
          vat_number_country: vat_code,
          red_2_identification: true,
          red_2_number: red_number,
          red_2_username: username,
          red_2_password: password,
          timezone,
          raw_wastes: [],
        };
      } else if (skipped) {
        data = {
          type,
          timezone,
          company_name,
          country: country?.value,
          vat_number,
          vat_number_country: vat_code,
          raw_wastes: [],
        };
      } else
        data = {
          type,
          company_name,
          country: country.value,
          vat_number,
          vat_number_country: vat_code,
          latitude: location_data?.latitude,
          longitude: location_data?.longitude,
          addr_street: location_data?.addr_street,
          addr_apartment: location_data?.addr_apartment,
          addr_postcode: location_data?.addr_postcode,
          addr_city: location_data?.addr_city,
          addr_country: location_data?.addr_country,
          phone: line_number,
          mobile_phone: mobile_number,
          email,
          raw_wastes: raw_waste?.value ? [raw_waste.value] : [],
          profile_url,
          timezone,
        };

      OrganizationService.createOrganization(data)
        .then((res) => {
          if (res.data.message === SUCCESS) {
            showNotification("New organization created", "success");
            dispatch(
              selectOrganization({ org_id: res.data.org_id }, () => {
                navigate("/auth/reviewing");
              })
            );
          }
        })
        .catch((err) => {
          showNotification(
            err.response
              ? ERROR[err.response.data.message] || "Network Error"
              : err.message,
            "error"
          );
        });
      setValidation(false);
    } else setValidation(true);
  };

  useEffect(() => {
    dispatch(fetchAllRawWastes());
  }, []);

  return (
    <>
      <Grid
        sx={{ marginTop: 5 }}
        px={20}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </Button>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={() => {
                // dispatch(logOut());
                window.history.back();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Stack
        flexDirection={"column"}
        mx={20}
        mt={10}
        alignItems={"center"}
        mb={10}
      >
        <Stack gap={1}>
          <Stack px={5} minWidth={"460px"}>
            <Stack py={1}>
              <SubTitle>Setup organization</SubTitle>
            </Stack>
            <CTextInput
              required
              validation={validation}
              title="Company name"
              placeholder="Insert your company name"
              value={company_name}
              onChange={(value) => {
                setCompanyName(value);
              }}
            />
            <CAutoComplete
              required
              validation={validation}
              fixed
              title="Country"
              value={country}
              onChange={(value) => {
                setCountry(value);
              }}
              options={COUNTRIES_INFO.map((country, index) => {
                return {
                  name: country.label,
                  value: country.code,
                };
              })}
              placeholder="select country"
            />
            <CAutoComplete
              placeholder="Select the timezone"
              required
              validation={validation}
              value={timezone}
              onChange={(value) => {
                setTimeZone(value);
              }}
              title="Time Zone"
              options={country ? moment.tz.zonesForCountry(country.value) : []}
              fixed
            />

            <COfficialIdInput
              countryCode={vat_code}
              content="VAT"
              label={"VAT number"}
              required
              validation={validation}
              value={vat_number}
              onCountryChange={(e, data) => {
                setVatCode(data.code);
              }}
              onChange={(e) => {
                setVatNumber(e.target.value);
              }}
            />
            {/* <Stack mt={5} mb={1}>
              <SubTitle>RED II Identification</SubTitle>
            </Stack>
            <Stack flexDirection={"row"} gap={1}>
              <Android12Switch
                checked={redAccess}
                onChange={(e) => {
                  setRedAccess(e.target.checked);
                }}
              />
              <Box sx={{ color: "text.accent" }}>
                This company has RED II identification
              </Box>
            </Stack> */}
          </Stack>
          {redAccess === false && !skipped && (
            <Stack
              padding={4}
              gap={2}
              sx={{
                backgroundColor: "background.lighter",
                borderRadius: "10px",
              }}
            >
              <Stack
                flexDirection={"row"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
              >
                <SubTitle>
                  Other configurations
                  <Box width="330px" mt={2} sx={{ fontSize: "10px" }}>
                    The forms below allow you to further configure your account.
                    They are not mandatory and you can complete them at any time
                    in your account settings.
                  </Box>
                </SubTitle>
                <Button
                  sx={classes.buttonPrimary}
                  onClick={() => {
                    setSkipped(true);
                  }}
                >
                  Skip
                </Button>
              </Stack>
              <Stack direction={"row"} gap={4} width={"100%"}>
                <Stack gap={1} width={"100%"}>
                  <CustomMap
                    direction="column"
                    description={"Organization"}
                    data={location_data}
                    onChange={(data) => {
                      setLocationData(data);
                    }}
                    mapHeight="300px"
                  />
                  <Stack spacing={2} sx={{ width: "100%" }} my={2}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      Contacts
                    </Typography>
                    <CPhoneInput
                      fullWidth
                      label="Phone"
                      countryCode={line_code}
                      onCountryChange={(e, data) => {
                        setLineCode(data.code);
                      }}
                      value={line_number}
                      onChange={(e) => {
                        setLineNumber(e.target.value);
                      }}
                    />
                    <CPhoneInput
                      fullWidth
                      label="Mobile Phone"
                      countryCode={mobile_code}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                      value={mobile_number}
                      onCountryChange={(e, data) => {
                        setMobileCode(data.code);
                      }}
                    />
                    <CTextInput
                      value={email}
                      onChange={(value) => {
                        setEmail(value);
                      }}
                      title="Email"
                      placeholder="Type email"
                    />
                  </Stack>
                  {/* <SubTitle sx={{ fontSize: "18px" }}>Raw wastes</SubTitle>
                  <CAutoComplete
                    title="Raw waste"
                    value={raw_waste}
                    placeholder="Select type"
                    onChange={(value) => {
                      setRawWaste(value);
                    }}
                    options={raw_wastes.map((raw, index) => {
                      return {
                        name: raw.name,
                        value: raw._id,
                      };
                    })}
                  /> */}
                  <Stack gap={1}>
                    <SubTitle sx={{ fontSize: "18px" }}>
                      Profile picture
                    </SubTitle>
                    <InputTitle>Upload document</InputTitle>
                    <Stack direction={"row"} alignItems={"flex-start"}>
                      <Stack width={"60%"}>
                        <CTextInput
                          value={uploadedDocument?.name}
                          placeholder="No document"
                          bottomTips="Only files with the following extensions are allowed: jpg or png"
                        />
                      </Stack>
                      <Stack width={"40%"} padding={1}>
                        <Button component="label" sx={classes.buttonUpload}>
                          <BackupOutlinedIcon
                            sx={{ color: "text.accent", paddingRight: "10px" }}
                          />
                          Upload
                          <input
                            accept="image/*"
                            onChange={(e) => {
                              setUploadedDocument(e.target.files[0]);
                              dispatch(setUploadSpinnerStatus(true));
                              FileService.uploadFile(e.target.files[0])
                                .then((res) => {
                                  if (res.data.message === SUCCESS) {
                                    setProfileUrl(res.data.filename);
                                    showNotification(
                                      "File uploaded",
                                      "success"
                                    );
                                    dispatch(setUploadSpinnerStatus(false));
                                  }
                                })
                                .catch((err) => {
                                  showNotification(
                                    err.response
                                      ? ERROR[err.response.data?.message] ||
                                          "Network Error"
                                      : err.message,
                                    "error"
                                  );
                                  dispatch(setUploadSpinnerStatus(false));
                                });
                            }}
                            type="file"
                            id="upload-file"
                            hidden
                          />
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
          {redAccess === true && (
            <Stack px={5} gap={2}>
              <COfficialIdInput
                countryCode={red_code}
                value={red_number}
                onCountryChange={(e, data) => {
                  setRedCode(data.code);
                }}
                onChange={(e) => {
                  setRedNumber(e.target.value);
                }}
                required
                validation={validation}
                label="RED II number"
                placeholder="RED II number"
              />
              <Stack
                sx={{
                  borderRadius: "10px",
                  color: "text.accent",
                  border: "1px solid",
                }}
                px={2}
                py={4}
              >
                <CTextInput
                  title="Username"
                  required
                  validation={validation}
                  value={username}
                  onChange={(value) => {
                    setUserName(value);
                  }}
                  placeholder="Insert your APA username"
                />
                <InputTitle required>Password</InputTitle>
                validation = {validation}
                <OutlinedInput
                  id="outlined-adornment-password-login"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  // value={values.password}
                  name="password"
                  // onBlur={handleBlur}
                  // onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        // onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  // label="Password"
                  placeholder="Insert your password"
                  inputProps={{}}
                  size="small"
                  sx={{ mt: "0.5rem", backgroundColor: "white" }}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default CreateOrganizationDetailPage;
