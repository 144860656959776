import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

import { BigTitle, PageTitle } from "../../../../components/Typography";
import {
  CAutoComplete,
  CDateInput,
  CTextInput,
} from "../../../../components/CustomInput";
import { DataTable } from "../../../../components/Table";
import { EditDetail } from "../../../../components/StateSpan";
import { EmptyWizard } from "../../../../components/wizard/Card";
import { FleetIcon } from "../../../../components/icons/menu_icons/icons";
import { showNotification, snackbarRef } from "../../../../utils/helpers";
import { classes } from "../../../../utils/class";
import { SUCCESS } from "../../../../utils/type";
import { ERROR } from "../../../../utils/constants";
import { summaryString } from "../../../../utils/string";
import { fetchAllVehicles } from "../../../../actions/fleet";
import { fetchWarehouseList } from "../../../../actions/warehouse";
import FleetService from "../../../../services/FleetService";
import AddDepositDialog from "./AddDepositDialog";
import EditDepositDialog from "./EditDepositDialog";
import RemoveDepositDialog from "./RemoveDepositDialog";

const AddFleet = ({
  onCancelHandler = () => {},
  onSaveHandler = () => {},
  onEditHandler = () => {},
  data = null,
  editMode = false,
}) => {
  const dispatch = useDispatch();
  const warehouses = useSelector((state) => state.warehouse.warehouses);

  const [name, setName] = useState();
  const [reg_plate, setRegPlate] = useState();
  const [brand, setBrand] = useState();
  const [model, setModel] = useState();
  const [capacity, setVehicleCapacity] = useState();
  const [year, setYear] = useState(new Date().getFullYear());
  const [warehouse, setWarehouse] = useState(null);
  const [currentRawWaste, setCurrent] = useState(null);
  const [editDlgOpen, setEditDlgOpen] = useState(false);
  const [removeDlgOpen, setRemoveDlgOpen] = useState(false);

  const columns = [
    {
      field: "name",
      width: "300",
      headerName: "Raw Waste",
    },
    {
      field: "capacity",
      width: "150",
      headerName: "capacity",
      renderCell: (props) => {
        return <>{props.value} kg</>;
      },
    },
    {
      field: "action",
      headerName: "",
      renderCell: (props) => {
        return (
          <EditDetail
            options={[
              { label: "Edit capacity", value: "edit" },
              { label: "Remove capacity", value: "remove" },
            ]}
            props={props}
            onStateChangeHandler={async (status) => {
              setCurrent(props.row);
              if (status === "edit") setEditDlgOpen(true);
              if (status === "remove") setRemoveDlgOpen(true);
            }}
          />
        );
      },
    },
  ];

  const [capacities, setCapacity] = useState([]);
  const [addCapacityDialog, setDialog] = useState(false);
  const [step, setStep] = useState(0);
  const [validation, setValidataion] = useState(false);

  useEffect(() => {
    dispatch(fetchWarehouseList());
  }, [dispatch]);

  const save = () => {
    const data = {
      name,
      reg_plate,
      brand,
      model,
      year,
      capacity,
      warehouse,
      capacities,
    };
    if (editMode) {
      onEditHandler(data);
    } else {
      onSaveHandler(data);
    }
  };

  useEffect(() => {
    if (editMode && data) {
      setName(data.name);
      setRegPlate(data.reg_plate);
      setBrand(data.brand);
      setModel(data.model);
      setYear(data.year);
      setVehicleCapacity(data.capacity);
      setCapacity(data.capacities);
      setWarehouse(data.warehouse);
    }
  }, [editMode, data]);

  return (
    <>
      {step === 0 && (
        <Stack px={"60px"} py={"20px"}>
          <Stack justifyContent={"flex-end"} flexDirection={"row"} gap={1}>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={onCancelHandler}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonSave}
              onClick={() => {
                setValidataion(true);
                if (
                  name &&
                  name !== "" &&
                  reg_plate &&
                  reg_plate !== "" &&
                  warehouse
                ) {
                  setStep(1);
                }
              }}
            >
              Next
            </Button>
          </Stack>
          <Stack alignItems={"center"}>
            <Box
              mt={10}
              sx={{
                width: "400px",
              }}
            >
              <PageTitle>Identification</PageTitle>
              <CTextInput
                title="Enter vehicle name"
                required
                validation={validation}
                placeholder="vehicle name"
                value={name}
                onChange={(v) => setName(v)}
              />
              <CTextInput
                caps
                title="Vehicle Registration Plate"
                required
                validation={validation}
                placeholder="23-HY-78"
                value={reg_plate}
                onChange={(v) => setRegPlate(v)}
              />
              <Stack flexDirection={"row"} gap={1}>
                <Box width="50%">
                  <CTextInput
                    title="Brand"
                    placeholder="Ford"
                    value={brand}
                    onChange={(v) => setBrand(v)}
                  />
                </Box>
                <Box width="50%">
                  <CTextInput
                    title="Model"
                    placeholder="Transit 1.9 TDI"
                    value={model}
                    onChange={(v) => setModel(v)}
                  />
                </Box>
              </Stack>
              <Stack gap={"8px"}>
                <CDateInput
                  views={["year"]}
                  title="Year"
                  value={dayjs().year(year)}
                  onChange={(newValue) => {
                    const d = new Date(newValue);
                    const y = d.getFullYear();
                    setYear(y);
                  }}
                />
              </Stack>
              <CTextInput
                type="number"
                title="Vehicle maximum capacity"
                value={capacity}
                onChange={(val) => {
                  setVehicleCapacity(val);
                }}
                placeholder="3500"
                endIcon={"kg"}
              />

              <CAutoComplete
                title="Warehouse"
                required
                validation={validation}
                fixed
                placeholder="Select warehouse"
                value={warehouse}
                onChange={(value) => {
                  setWarehouse(value);
                }}
                options={warehouses.map((item, index) => {
                  return {
                    name: item.name,
                    value: item._id,
                  };
                })}
              />
            </Box>
          </Stack>
        </Stack>
      )}
      {step === 1 && (
        <Stack
          flexDirection={"row"}
          sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
        >
          <Stack minWidth={"420px"} maxWidth={"420px"}>
            <Stack
              sx={{ backgroundColor: "#F7F6F3" }}
              pt={"72px"}
              px={"48px"}
              pb={"101px"}
              gap={"21px"}
            >
              <BigTitle> {name} - Waste capacity </BigTitle>
            </Stack>
            <Stack padding={"48px"} gap={"10px"}>
              {`We now need to add the waste types and capacity that this vehicle is allowed to transport, please remember that although your vehicle capacity is set to ${capacity}, this is the overall capacity of the vehicle, you are able to add multiple waste type and both can be a capacity of ${capacity}, smart routing will take the overall capacity into consideration when planning routes to ensure that it does not exceed the vehicles maximum capacity.`}
            </Stack>
          </Stack>
          <Stack
            width={"calc(100% - 420px);"}
            gap={2}
            padding={5}
            justifyContent={"flex-start"}
            textAlign={"flex-start"}
            overflow={"auto"}
            sx={{
              backgroundColor: "white",
            }}
          >
            <Stack justifyContent={"flex-end"} flexDirection={"row"} gap={1}>
              <Button
                variant="outlined"
                sx={classes.buttonPrimary}
                onClick={() => {
                  setStep(0);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                sx={classes.buttonSave}
                onClick={() => {
                  save();
                }}
              >
                Save
              </Button>
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
              gap={1}
            >
              {capacities.length === 0 ? (
                <>
                  <EmptyWizard
                    title={"No waste types have been added to this fleet yet"}
                    content={
                      "Add the first waste type to this fleet to continue. You can manage your fleet at anytime"
                    }
                    buttonText={"Add waste Type"}
                    onAdd={() => {
                      setDialog(true);
                    }}
                    Icon={
                      <WaterDropOutlinedIcon
                        sx={{ color: "text.tertiary", fontSize: 100 }}
                      />
                    }
                  />
                </>
              ) : (
                <>
                  <DataTable
                    rows={capacities.map((item) => {
                      return {
                        ...item,
                        id: item.raw_waste,
                      };
                    })}
                    columns={columns}
                  ></DataTable>
                  <Button
                    sx={classes.buttonSave}
                    onClick={() => {
                      setDialog(true);
                    }}
                  >
                    Add capacity
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
          <RemoveDepositDialog
            open={removeDlgOpen}
            onClose={() => {
              setRemoveDlgOpen(false);
            }}
            onRemove={() => {
              let caps = capacities.filter((capacity) => {
                return capacity.name !== currentRawWaste.name;
              });
              setCapacity(caps);
            }}
          />
          <EditDepositDialog
            maxCapacity={capacity}
            open={editDlgOpen}
            onContinue={(newCapacity) => {
              let caps = [...capacities];
              for (let cap of caps) {
                if (cap.name === currentRawWaste.name) {
                  cap.capacity = newCapacity;
                  break;
                }
              }
              setCapacity(caps);
            }}
            onClose={() => {
              setEditDlgOpen(false);
            }}
            rawWaste={currentRawWaste}
          />
          <AddDepositDialog
            maxCapacity={capacity}
            open={addCapacityDialog}
            onContinue={(id, name, newCapacity) => {
              let caps = [...capacities];
              let flag = false;
              for (let cap of caps) {
                if (cap.raw_waste === id) {
                  if (
                    Number(cap.capacity) + Number(newCapacity) >
                    Number(capacity)
                  ) {
                    showNotification(
                      "Your capacity is bigger than maximum capacity",
                      "error"
                    );
                  } else {
                    cap.capacity = Number(cap.capacity) + Number(newCapacity);
                  }
                  flag = true;
                  break;
                }
              }
              if (flag === false)
                caps.push({ raw_waste: id, name, capacity: newCapacity });
              setCapacity(caps);
            }}
            onClose={() => {
              setDialog(false);
            }}
          />
        </Stack>
      )}
    </>
  );
};

const SetupFleet = () => {
  snackbarRef.current = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  // const vehicles = useSelector((state) => state.fleet.vehicles);

  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [currentVehicle, setCurrentVehicle] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [currentVehicleIndex, setCurrentVehicleIndex] = useState(null);

  const deleteVehicle = (name) => {
    let temp = vehicleDetails.filter((v) => {
      return v.name !== name;
    });
    setVehicleDetails(temp);
  };
  const confirmvehicles = async () => {
    for (let vehicle of vehicleDetails) {
      const {
        name,
        reg_plate,
        brand,
        model,
        year,
        capacity,
        warehouse,
        capacities,
      } = vehicle;
      try {
        const res = await FleetService.createFleetWizard({
          name,
          reg_plate,
          brand,
          model,
          year,
          capacity,
          warehouse: warehouse.value,
        });
        if (res.data.message === SUCCESS) {
          const vehicle_id = res.data.vehicle_id;
          try {
            for (let cap of capacities) {
              await FleetService.assignRawWaste(vehicle_id, {
                waste: cap.raw_waste,
                capacity: cap.capacity,
              });
            }
          } catch (err) {
            showNotification(
              err.response
                ? ERROR[err.response.data.message] || "Network Error"
                : err.message,
              "error"
            );
          }
        }
      } catch (err) {
        showNotification(
          `Error was occured while creating a fleet(${vehicle.name}) ` +
            err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      }
      showNotification("New vehicle was added", "success");
    }
    navigate("/auth/reviewing");
  };

  useEffect(() => {
    dispatch(fetchAllVehicles());
  }, [dispatch]);

  // useEffect(() => {
  //   if (vehicles.length > 0) {
  //     showNotification(
  //       "You already setup a vehicle. It's the time to setup a producer",
  //       "success"
  //     );
  //     navigate("/auth/reviewing");
  //   }
  // }, [vehicles, navigate]);

  return (
    <>
      {step === 0 && (
        <Stack
          flexDirection={"row"}
          sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
        >
          <Stack maxWidth={"420px"} minWidth={"420px"}>
            <Stack
              sx={{ backgroundColor: "#F7F6F3" }}
              pt={"72px"}
              px={"48px"}
              pb={"21px"}
              gap={"21px"}
            >
              <BigTitle>Fleet</BigTitle>
              <Stack
                maxWidth={"472px"}
                sx={{ fontSize: "18px", fontFamily: "Manrope" }}
                gap={4}
              >
                <Box>
                  Now that you have a warehouse, you can create a vehicle to
                  facilitate the collection and transportation of waste from
                  producers.
                </Box>
                <Box>
                  After completing your onboarding, you can manage additional
                  information for your vehicles from the main menu under{" "}
                  <b>Fleet</b>
                </Box>
              </Stack>
            </Stack>
            <Stack padding={"48px"} gap={"10px"}>
              <Box sx={{ fontWeight: "bold", textDecoration: "underline" }}>
                Why is this important?
              </Box>
              Waste transfers can often be performed only by specific vehicle
              types, also WTNs require the details of the vehicle to be
              provided, this enables the platform to allocate waste collections
              to specific vehicles based on the waste type that they support and
              their capacity, creating optimised collection routes.
            </Stack>
          </Stack>
          <Stack
            width={"calc(100% - 420px);"}
            gap={2}
            padding={5}
            justifyContent={"flex-start"}
            textAlign={"flex-start"}
            overflow={"auto"}
            sx={{
              backgroundColor: "white",
            }}
          >
            <Stack alignItems={"flex-end"}>
              <Button
                variant="outlined"
                onClick={confirmvehicles}
                sx={classes.buttonPrimary}
              >
                Confirm Fleet
              </Button>
            </Stack>
            <Stack justifyContent={"center"} height={"100%"}>
              {vehicleDetails.length === 0 ? (
                <>
                  <EmptyWizard
                    title={"No vehicles have been added to the fleet yet"}
                    content={
                      "Add the first vehicle to the fleet to continue. You can manage your fleet at anytime."
                    }
                    Icon={<FleetIcon size="32px" fill="#33343F" />}
                    onAdd={() => {
                      setStep(1);
                    }}
                    buttonText={"Add Vehicle"}
                  />
                </>
              ) : (
                <>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    overflow={"auto"}
                    justifyContent={"flex-start"}
                    py={2}
                  >
                    {vehicleDetails.map((vehicle, index) => {
                      return (
                        <Stack key={index} sx={classes.wizardCard}>
                          <Stack
                            mb={"20px"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "14px",
                              }}
                            >
                              {summaryString(vehicle.name, 15)}
                            </Typography>
                            <Stack flexDirection={"row"}>
                              <Button
                                sx={classes.buttonInCard}
                                onClick={() => {
                                  deleteVehicle(vehicle.name);
                                }}
                              >
                                Delete
                              </Button>
                              <Button
                                sx={classes.buttonInCard}
                                onClick={() => {
                                  setCurrentVehicleIndex(index);
                                  setCurrentVehicle(vehicle);
                                  setEditMode(true);
                                  setStep(1);
                                }}
                              >
                                Edit
                              </Button>
                            </Stack>
                          </Stack>
                          <Typography sx={classes.wizardTitle}>
                            DETAILS
                          </Typography>
                          <Stack gap={2} mt={1}>
                            <Stack
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                              gap={3}
                              sx={{
                                color: "text.primary",
                                fontWeight: "400",
                                lineHeight: "26px",
                              }}
                            >
                              <Stack flex={1}>
                                <Box fontWeight={700}>Brand</Box>
                                {vehicle.brand}
                                <Divider sx={{ mt: "16px" }} />
                              </Stack>
                              <Stack flex={1}>
                                <Box fontWeight={700}>Model</Box>
                                {vehicle.model}
                                <Divider sx={{ mt: "16px" }} />
                              </Stack>
                            </Stack>
                            <Stack
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                              gap={3}
                              sx={{
                                color: "text.primary",
                                fontWeight: "400",
                                lineHeight: "26px",
                              }}
                            >
                              <Stack flex={1}>
                                <Box fontWeight={700}>Plate</Box>
                                {vehicle.reg_plate}
                                <Divider sx={{ mt: "16px" }} />
                              </Stack>
                              <Stack flex={1}>
                                <Box fontWeight={700}>Year</Box>
                                {vehicle.year}
                                <Divider sx={{ mt: "16px" }} />
                              </Stack>
                            </Stack>
                            <Stack sx={{ lineHeight: "26px" }} gap={1}>
                              <Typography sx={classes.wizardTitle}>
                                Maximum vehicle capacity
                              </Typography>
                              {vehicle.capacity || 0}kg
                              <Divider sx={{ mt: "16px" }} />
                            </Stack>
                            <Stack sx={{ lineHeight: "26px" }} gap={1}>
                              <Typography sx={classes.wizardTitle}>
                                Warehouse
                              </Typography>
                              {vehicle.warehouse.name}
                              <Divider sx={{ mt: "16px" }} />
                            </Stack>
                            <Stack sx={{ lineHeight: "26px" }} gap={1}>
                              <Typography sx={classes.wizardTitle}>
                                Raw waste type
                              </Typography>
                              {vehicle.capacities.map((capacity) => {
                                return (
                                  <Typography>
                                    {summaryString(capacity.name)}
                                  </Typography>
                                );
                              })}
                            </Stack>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                  <Stack alignItems={"flex-start"}>
                    <Button
                      sx={classes.buttonSave}
                      onClick={() => {
                        setStep(1);
                      }}
                    >
                      Add vehicle
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
      {step === 1 && (
        <AddFleet
          onCancelHandler={() => {
            setStep(0);
          }}
          onSaveHandler={(data) => {
            setVehicleDetails([...vehicleDetails, data]);
            setStep(0);
          }}
          onEditHandler={(data) => {
            let temp = [...vehicleDetails];
            temp[currentVehicleIndex] = data;
            setStep(0);
            setEditMode(false);
            setVehicleDetails(temp);
          }}
          editMode={editMode}
          data={currentVehicle}
        />
      )}
    </>
  );
};
export default SetupFleet;
