import { Button, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classes } from "../../../utils/class";

import { PageTitle } from "../../../components/Typography";
import { SearchBar } from "../../../components/CustomInput";
import { CTag, EditDetail, Badge } from "../../../components/StateSpan";
import { DataTable } from "../../../components/Table";

import {
  BADGE_A_TO_CLIENT,
  BADGE_A_TO_DRIVER,
  BADGE_DEPOSIT,
  BADGE_GENERIC,
  BADGE_IN_WAREHOUSE,
  BADGE_LOANED,
  BADGE_OFFER,
  BADGE_UNIQUE,
  EDIT_ROW,
  MAKE_GENERIC,
  MAKE_UNIQUE,
  REMOVE_ID,
} from "../../../utils/type";
import ItemTypeDialog from "./ItemTypeDialog";
import { useParams } from "react-router";
import {
  fetchInventory,
  fetchInventorySubList,
  makeGeneric,
  makeUnique,
  removeStock,
  updateStockItemId,
} from "../../../actions/inventory";
import EditItemDialog from "./EditItemDialog";

const InventorySubList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;
  const [rows, setRows] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [open, setOpen] = useState(false);
  const [anchorEL, setAnchorEL] = useState(null);
  const [itemTypeModal, setItemTypeModal] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [currentStock, setCurrentStock] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const [editData, setEditData] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  const currentInventory = useSelector(
    (state) => state.inventory.currentInventory
  );
  const inventories = useSelector((state) => state.inventory.inventories);
  const [currentWarehouse, setCurrentWarehouse] = useState("");
  const handleCloseItemTypeModal = () => {
    setItemTypeModal(false);
  };

  const handleCloseOptions = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "quantity",
      headerName: "Quantity",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 450,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} alignItems={"center"} spacing={4}>
            <Stack>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                {params.value}
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  color: "text.accent",
                }}
              >
                REF:{params.row.ref}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              flexWrap={"wrap"}
              maxWidth={"200px"}
              gap={0.5}
            >
              {params.row.inventories.map((inventory, index) => {
                return <Badge status={inventory} key={index} />;
              })}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "owner",
      width: 200,
      headerName: "Owner",
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            gap={5}
            alignItems={"center"}
          >
            <Stack>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "32px",
                }}
              >
                {params.row.owner_parent}
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  color: "text.accent",
                }}
              >
                {params.row.owner}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "tags",
      headerName: "ID tags",
      width: 200,
      renderCell: (params) => {
        return (
          <Stack spacing={1}>
            {params.row.tags.map((tag, index) => {
              if (tag.value)
                return <CTag key={index} title={tag.type} value={tag.value} />;
            })}
          </Stack>
        );
      },
    },
    {
      field: "location",
      headerName: "Current location",
      width: 300,
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            gap={5}
            alignItems={"center"}
          >
            <Stack>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "32px",
                }}
              >
                {params.row.location}
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  color: "text.accent",
                }}
              >
                {params.row.subLocation}
              </Typography>
            </Stack>
            <Stack>
              <Badge status={params.row.locationStatus} />
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "deposit",
      headerName: "Deposit",
      width: 100,
      renderCell: (params) => {
        if (params.value === "")
          return (
            <Typography
              sx={{
                fontSize: "18px",
                color: "text.accent",
              }}
            >
              -
            </Typography>
          );
        else {
          return (
            <Typography
              sx={{
                fontSize: "18px",
              }}
            >
              {params.value}
            </Typography>
          );
        }
      },
    },
    {
      field: "value",
      headerName: "Value",
      width: 100,
      renderCell: (params) => {
        if (params.value === "")
          return (
            <Typography
              sx={{
                fontSize: "18px",
                color: "text.accent",
              }}
            >
              -
            </Typography>
          );
        else {
          return (
            <Typography
              sx={{
                fontSize: "18px",
              }}
            >
              {params.value}
            </Typography>
          );
        }
      },
    },
    {
      field: "action",
      width: 50,
      headerName: "",
      renderCell: (params) => {
        let options = [];
        if (params.row.inventories.indexOf("unique") !== -1)
          options.push({
            label: "Make genric",
            value: MAKE_GENERIC,
          });
        else
          options.push({
            label: "Make unique",
            value: MAKE_UNIQUE,
          });
        if (params.row.inventories.indexOf("unique") === -1) {
        } else {
          options.push({
            label: "Edit ID",
            value: EDIT_ROW,
          });
          // options.push({
          //   label: "Remove ID",
          //   value: REMOVE_ID,
          // });
        }
        return (
          <EditDetail
            options={options}
            onStateChangeHandler={(state) => {
              if (state === MAKE_UNIQUE) {
                setQuantity(params.row.quantity);
                setCurrentStock(params.row.stock_id);
                setItemTypeModal(true);
              } else if (state === MAKE_GENERIC) {
                dispatch(makeGeneric(id, params.row.item_id));
              } else if (state === REMOVE_ID) {
                dispatch(removeStock(id, params.row.stock_id));
              } else if (state === EDIT_ROW) {
                setQuantity(params.row.quantity);
                setCurrentStock(params.row.item_id);
                setEditDialog(true);
                setEditData(params.row.tags);
              }
            }}
          />
        );
      },
    },
  ];
  useEffect(() => {
    for (let inventory of inventories) {
      for (let stock of inventory.stocks) {
        if (stock._id === id) {
          setCurrentWarehouse(stock.name);
          return;
        }
      }
    }
  }, [inventories, id]);
  useEffect(() => {
    dispatch(
      fetchInventorySubList(id, () => {
        setIsLoaded(true);
      })
    );
    dispatch(fetchInventory());
  }, [dispatch, id]);
  useEffect(() => {
    let tempRow = [];
    let cnt = 0;
    for (let item of currentInventory) {
      cnt++;
      let badges = [];
      if (item.has_unique) badges.push(BADGE_UNIQUE);
      else badges.push(BADGE_GENERIC);
      if (item.is_deposit) badges.push(BADGE_DEPOSIT);
      if (item.is_offer) badges.push(BADGE_OFFER);
      if (item.available_to_client) badges.push(BADGE_A_TO_CLIENT);
      if (item.available_to_driver) badges.push(BADGE_A_TO_DRIVER);
      const tags = [];
      if (item.id) tags.push({ type: "ID", value: item.id });
      if (item.rfid) tags.push({ type: "RFID", value: item.rfid });

      tempRow.push({
        id: cnt,
        item_id: item.item_id,
        stock_id: item.stock_id,
        quantity: item.quantity,
        name: item.name,
        ref: item.reference,
        inventories: badges,
        owner: item.owner,
        owner_parent: item.owner_parent,
        tags: tags,
        subLocation: item.current_location,
        location: item.current_location_parent,
        deposit: item.deposit_value + item.deposit_unit,
        value: item.offer_value + item.offer_unit,
        locationStatus:
          item.owner === item.current_location
            ? BADGE_IN_WAREHOUSE
            : BADGE_LOANED,
        has_unique: item.has_unique,
      });
    }
    setRows(tempRow);
  }, [currentInventory]);
  return (
    <>
      <Stack paddingX={"60px"} paddingY={"20px"} spacing={3}>
        <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
          <Button
            sx={{
              ...classes.buttonBack,
              marginLeft: "24px",
              marginBottom: "30px",
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </Button>
          {/* <Stack>
            <Button
              variant="outlined"
              sx={classes.buttonMoreOption}
              onClick={(e) => {
                setAnchorEL(e.currentTarget);
                setOpen(!open);
              }}
            >
              Options <KeyboardArrowDownIcon />
            </Button>
            <Menu
              id="basic-menu"
              open={open}
              anchorEl={anchorEL}
              onClose={handleCloseOptions}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                horizontal: "center",
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            ></Menu>
          </Stack> */}
        </Stack>
        <Stack>
          <Stack spacing={4}>
            <PageTitle>{currentWarehouse}</PageTitle>
          </Stack>
        </Stack>
        <Stack justifyContent={"space-between"} direction={"row"}>
          <SearchBar
            label="Search Item"
            onSearchChange={(value) => {
              setFilterModel({
                items: [
                  {
                    field: "name",
                    operator: "contains",
                    value: value,
                  },
                ],
              });
            }}
          />
          {/* <Button sx={classes.buttonPrimary}>
            <FilterAltOutlinedIcon />
            Filter
          </Button> */}
        </Stack>
        <Stack>
          {isLoaded ? (
            <DataTable
              autoSize
              fit="100%"
              filterModel={filterModel}
              columns={columns}
              rows={rows}
              rowHeight={75}
            />
          ) : (
            <Skeleton width={"100%"} height={"200px"} />
          )}
        </Stack>
      </Stack>
      <ItemTypeDialog
        open={itemTypeModal}
        handleClose={handleCloseItemTypeModal}
        genericItemCount={quantity}
        handleSave={(data) => {
          dispatch(
            makeUnique(id, currentStock, data, () => {
              handleCloseItemTypeModal();
            })
          );
        }}
      />
      <EditItemDialog
        open={editDialog}
        genericItemCount={quantity}
        handleClose={() => {
          setEditDialog(false);
        }}
        handleSave={(data) => {
          dispatch(
            updateStockItemId(id, currentStock, data, () => {
              setEditDialog(false);
            })
          );
        }}
        itemArray={editData}
      />
    </>
  );
};

export default InventorySubList;
