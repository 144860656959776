import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Stack, Typography } from "@mui/material";

import { EditDetail, StateSpan } from "../../../../components/StateSpan";
import { DataTable } from "../../../../components/Table";
import { CardPrimary } from "../../../../components/Card";
import { CRatio } from "../../../../components/CustomInput";
import {
  activateDeposit,
  archiveDeposit,
  deactivateDeposit,
  unarchiveDeposit,
} from "../../../../actions/warehouse";
import { setAddDepositStep } from "../../../../slices/warehouseSlice";
import AddDepositDialog from "../Dialog/AddDepositDialog";
import { useState } from "react";
import EditDepositDialog from "../Dialog/EditDepositDialog";
import RemoveDepositDialog from "../Dialog/RemoveDepositDialog";
import ChangeStatusDialog from "../Dialog/ChangeStatusDialog";
import {
  STATE_ACTIVE,
  STATE_ARCHIVE,
  STATE_INACTIVE,
} from "../../../../utils/type";
import { DRIVER, SALES, SALES_MANAGER } from "../../../../utils/constants";
import { classes } from "../../../../utils/class";

const Deposits = () => {
  const dispatch = useDispatch();
  const { id, deposits, profile } = useSelector(
    (state) => state.warehouse.current
  );
  const [addDlgOpen, setAddDlgOpen] = useState(false);
  const [editDlgOpen, setEditDlgOpen] = useState(false);
  const [removeDlgOpen, setRemoveDlgOpen] = useState(false);
  const [current, setCurrent] = useState(null);
  const [changeDlgOpen, setChangeDlgOpen] = useState(false);
  const role = useSelector((state) => state.user.role);

  const addDepositStep = useSelector(
    (state) => state.warehouse.current.add_deposit
  );
  const wh_id = id;

  useEffect(() => {
    if (addDepositStep) setAddDlgOpen(true);
  }, [addDepositStep]);

  const columns = [
    {
      field: "raw_waste_name",
      headerName: "Raw Waste",
      width: 300,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 350,
      flex: 1,
      renderCell: (props) => {
        return (
          <Stack direction="row" sx={{ alignItems: "center" }} gap={2}>
            <Typography sx={{ width: "80px" }}>
              {props.row.capacity} kg
            </Typography>
            <CRatio percent={props.row.ratio} />
            <Typography
              sx={{ color: props.row.ratio === 100 ? "red" : "black" }}
            >
              {parseInt(props.row.ratio) || 0}%
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (props) => {
        let options = [];
        if (props?.row?.status === "active") {
          options = [
            {
              label: "Inactive",
              value: STATE_INACTIVE,
            },
          ];
        } else if (props?.row?.status === "inactive") {
          if (props.row.archived) {
            options = [{ label: "Unarchive", value: "unarchive" }];
          } else {
            options = [
              {
                label: "Active",
                value: STATE_ACTIVE,
              },
              {
                label: "Archive",
                value: STATE_ARCHIVE,
              },
            ];
          }
        }

        return (
          <>
            <StateSpan
              disabled={
                role === SALES_MANAGER || role === SALES || role === DRIVER
              }
              status={props.row.archived ? STATE_ARCHIVE : props.row.status}
              options={options}
              onStateChangeHandler={(status) => {
                if (
                  status === STATE_ACTIVE &&
                  props.row.status === STATE_INACTIVE
                ) {
                  dispatch(activateDeposit(props.row.id, wh_id));
                } else if (
                  status === STATE_INACTIVE &&
                  props.row.status === STATE_ACTIVE
                ) {
                  setCurrent(props.row.id);
                  setChangeDlgOpen(true);
                }
                if (status === STATE_ARCHIVE)
                  dispatch(archiveDeposit(props.row.id, wh_id));
                if (status === "unarchive")
                  dispatch(unarchiveDeposit(props.row.id, wh_id));
              }}
            />
            {role === SALES_MANAGER || role === SALES || role === DRIVER ? (
              <></>
            ) : (
              <EditDetail
                options={[
                  { label: "Edit Waste Capacity", value: "edit" },
                  {
                    label: `${
                      props.row.status === "active" ? "Deactivate" : "Activate"
                    } Waste Capacity`,
                    value: "deactivate",
                  },
                  { label: "Remove Waste Capacity", value: "remove" },
                ]}
                props={props}
                onStateChangeHandler={async (status) => {
                  setCurrent(props.row.id);
                  if (status === "edit") setEditDlgOpen(true);
                  if (status === "remove") setRemoveDlgOpen(true);
                  if (status === "deactivate") {
                    if (props.row.status === "active") {
                      dispatch(deactivateDeposit(props.row._id, wh_id));
                    } else {
                      dispatch(activateDeposit(props.row._id, wh_id));
                    }
                  }
                }}
              />
            )}
          </>
        );
      },
    },
  ];

  const deactivateDepositConfirm = () => {
    dispatch(deactivateDeposit(current, wh_id));
    setChangeDlgOpen(false);
  };

  if (deposits.length === 0)
    return (
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            marginTop: "200px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "400px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="66"
            viewBox="0 0 54 66"
            fill="none"
          >
            <path
              d="M52.5 59.25C52.5 59.6147 52.1844 60.2437 50.8855 61.0186C49.651 61.7551 47.798 62.4534 45.4204 63.0534C40.6806 64.2494 34.0791 65 26.75 65C19.4209 65 12.8194 64.2494 8.07956 63.0534C5.70197 62.4534 3.84901 61.7551 2.61449 61.0186C1.31563 60.2437 1 59.6147 1 59.25C1 58.8853 1.31563 58.2563 2.61449 57.4814C3.84901 56.7449 5.70197 56.0466 8.07956 55.4466C12.8194 54.2506 19.4209 53.5 26.75 53.5C34.0791 53.5 40.6806 54.2506 45.4204 55.4466C47.798 56.0466 49.651 56.7449 50.8855 57.4814C52.1844 58.2563 52.5 58.8853 52.5 59.25Z"
              stroke="#DBDBDB"
              strokeWidth="2"
            />
            <path
              d="M1.25 58.75V7.5C1.25 7.5 9.25 12.75 26.875 12.75C44.5 12.75 52.5 7.5 52.5 7.5V58.75C52.5 58.75 51.25 65 26.875 65C2.5 65 1.25 58.75 1.25 58.75Z"
              fill="#F5F5F5"
              stroke="#DBDBDB"
              strokeWidth="2"
            />
            <path
              d="M1.25 27.5C1.25 27.5 9.25 32.75 26.875 32.75C44.5 32.75 52.5 27.5 52.5 27.5"
              stroke="#DBDBDB"
              strokeWidth="2"
            />
            <path
              d="M1.25 42.5C1.25 42.5 9.25 47.75 26.875 47.75C44.5 47.75 52.5 42.5 52.5 42.5"
              stroke="#DBDBDB"
              strokeWidth="2"
            />
            <path
              d="M52.5 6.75C52.5 7.11472 52.1844 7.74374 50.8855 8.51862C49.651 9.25513 47.798 9.95341 45.4204 10.5534C40.6806 11.7494 34.0791 12.5 26.75 12.5C19.4209 12.5 12.8194 11.7494 8.07956 10.5534C5.70197 9.95341 3.84901 9.25513 2.61449 8.51862C1.31563 7.74374 1 7.11472 1 6.75C1 6.38528 1.31563 5.75627 2.61449 4.98138C3.84901 4.24487 5.70197 3.54659 8.07956 2.94664C12.8194 1.75059 19.4209 1 26.75 1C34.0791 1 40.6806 1.75059 45.4204 2.94664C47.798 3.54659 49.651 4.24487 50.8855 4.98138C52.1844 5.75627 52.5 6.38528 52.5 6.75Z"
              stroke="#DBDBDB"
              strokeWidth="2"
            />
            <ellipse cx="39.25" cy="6.75" rx="2.25" ry="0.75" fill="#DBDBDB" />
          </svg>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Manrope",
              fontSize: "10px",
              color: "text.primary",
              marginBottom: "10px",
            }}
          >
            There are no waste capacities listed
          </Typography>
          <Button
            onClick={() => setAddDlgOpen(true)}
            sx={classes.buttonPrimary}
          >
            Add Waste Capacity
          </Button>
        </Box>
        <AddDepositDialog
          open={addDlgOpen}
          onClose={() => setAddDlgOpen(false)}
        />
      </Box>
    );

  return (
    <Stack>
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        <CardPrimary
          type={2}
          title={"Nº Wastes"}
          content={profile.count_of_deposits}
        />
        <CardPrimary
          type={2}
          title={"Nº Vehicles"}
          content={profile.count_of_vehicles}
        />
        <CardPrimary
          type={2}
          title={"Nº Collection Points"}
          content={profile.count_of_pickup_points}
        />
      </Stack>
      <Box sx={{ padding: "20px" }}>
        <DataTable
          rows={deposits}
          columns={columns}
          sx={classes.grid}
          onRowClick={(params, events, details) => {}}
        />
      </Box>
      <EditDepositDialog
        open={editDlgOpen}
        onClose={() => setEditDlgOpen(false)}
        deposit={deposits.find((d) => d.id === current)}
      />
      <RemoveDepositDialog
        open={removeDlgOpen}
        id={current}
        onClose={() => setRemoveDlgOpen(false)}
      />
      <ChangeStatusDialog
        open={changeDlgOpen}
        onClose={() => setChangeDlgOpen(false)}
        onConfirm={deactivateDepositConfirm}
        type="deposit"
      />
      <AddDepositDialog
        open={addDlgOpen}
        onClose={() => {
          setAddDlgOpen(false);
          dispatch(setAddDepositStep(false));
        }}
      />
    </Stack>
  );
};

export default Deposits;
