import { createSlice } from "@reduxjs/toolkit";
import { STATE_ACTIVE } from "../utils/type";

const initialState = {
  currentProducer: 0,
  producerData: [],
  producerDetail: {
    _id: "651a846f873a441c372578c5",
    org: "64a43fca159ba627f31c30ad",
    manager: "648833ad9a4bab511a4f3a3a",
    name: "Mayura",
    vat_number: "513452674",
    tax_name: "Olive Marpickings, Lda",
    status: STATE_ACTIVE,
    official_id: "OID48848",
    email: "mspot@email.com",
    fax: "963888741",
    mobile_phone: "963852741",
    phone: "259987854",
    closing_time: "22:00",
    opening_time: "07:30",
    restrictions_from_time: "00:00",
    restrictions_to_time: "05:00",
    days_off: ["sat", "sun"],
    addr_apartment: "Apartment",
    addr_city: "City",
    addr_country: "Country",
    addr_postcode: "2705-829",
    addr_street: "Street",
    latitude: 41.5614521,
    longitude: 41.5614521,
    resp_email: "mspot@email.com",
    resp_mobile_phone: "963852741",
    resp_phone: "259987854",
    responsible: "648833ad9a4bab511a4f3a3a",
    archived: false,
    id: "81513220",
    manager_name: "Zhang Chen",
    responsible_name: "Zhang Chen",
  },
  producerPickupPointList: [],
  producerTransferList: [],
  producerDocumentList: [],
  producerRawWasteList: [],
  producerVisitList: [],
  producerScheduledVisitList: [],
  producerInventoryList: [],
  producerOfferList: [],
  extraInfo: {
    balance: 0,
    count_of_pickup_points: 0,
    next_picking: 0,
  },
};

export const producerSlice = createSlice({
  name: "producer",
  initialState,
  reducers: {
    setProducerRawWasteList: (state, action) => {
      state.producerRawWasteList = action.payload;
    },
    setProducerInventoryList: (state, action) => {
      state.producerInventoryList = action.payload;
    },
    setProducerOfferList: (state, action) => {
      state.producerOfferList = action.payload;
    },
    setProducerPickupList: (state, action) => {
      state.producerPickupPointList = action.payload;
    },
    setProducerDocumentList: (state, action) => {
      state.producerDocumentList = action.payload;
    },
    setProducerTransferList: (state, action) => {
      state.producerTransferList = action.payload;
    },
    setProducerVisitList: (state, action) => {
      state.producerVisitList = action.payload;
    },
    setProducerScheduledVisitList: (state, action) => {
      state.producerScheduledVisitList = action.payload;
    },
    setProducerDetail: (state, action) => {
      state.producerDetail = action.payload;
    },
    setCurrentProducer: (state, action) => {
      state.currentProducer = action.payload;
    },
    setProducerList: (state, action) => {
      state.producerData = action.payload;
    },
    setExtraInfo: (state, action) => {
      state.extraInfo = {
        ...state.extraInfo,
        ...action.payload,
      };
    },
  },
});

export const {
  setExtraInfo,
  setProducerVisitList,
  setProducerScheduledVisitList,
  setCurrentProducer,
  setProducerList,
  setProducerDetail,
  setProducerTransferList,
  setProducerRawWasteList,
  setProducerInventoryList,
  setProducerOfferList,
  setProducerPickupList,
  setProducerDocumentList,
} = producerSlice.actions;

export default producerSlice.reducer;
