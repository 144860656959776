import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  security: {
    two_fa: false,
  },
  company: {
    tax_name: "OGR Waste Portugal",
    country_code: "PT",
    vat: "589698987",
    official_id: "OID48848",
    id: "KLG 59787",
    username: "ogrwaste",
    password: "942857",
    coords: {
      lat: 41.6463,
      long: 64.12,
    },
    address: {
      street: "Estrada de Alcolombal",
      apartment: 'Quinta das Palmeiras Armazém "A"',
      postcode: "2705-829",
      city: "Porto",
      country: "PT",
    },
    contacts: {
      phone: "2323981",
      mobile: "2323981",
      email: "ogrportugal@email.com",
    },
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setProfileInfo: (state, action) => {
      state.profile.name = action.payload.name;
      state.profile.nationality = action.payload.nationality;
    },
    setPhoneNumbers: (state, action) => {
      state.profile.mobile = action.payload.mobile;
      state.profile.phone = action.payload.phone;
    },
    setTwoFactorAuth: (state, action) => {
      state.security.two_fa = action.payload;
    },
    setCompanyProfileInfo: (state, action) => {
      const {
        tax_name,
        country_code,
        vat,
        username,
        password,
        phone,
        mobile,
        email,
      } = action.payload;
      if (tax_name) state.company.tax_name = tax_name;
      if (country_code) state.company.country_code = country_code;
      if (vat) state.company.vat = vat;
      if (username) state.company.username = username;
      if (password) state.company.password = password;
      if (phone) state.company.contacts.phone = phone;
      if (mobile) state.company.contacts.mobile = mobile;
      if (email) state.company.contacts.email = email;
    },
  },
});

export const {
  addItemCategory,
  editItemCategory,
  removeItemCategory,
  setProfileInfo,
  setPhoneNumbers,
  setTwoFactorAuth,
  setCompanyProfileInfo,
} = settingsSlice.actions;

export default settingsSlice.reducer;
