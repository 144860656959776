import FleetService from "../services/FleetService";
import {
  setCurrentRawWastes,
  setCurrentTransferList,
  setDrivers,
  setVehicleRoutes,
  setVehicles,
} from "../slices/fleetSlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";

export const fetchTransferList = (vehicleId) => (dispatch) => {
  FleetService.getTransfer(vehicleId)
    .then((res) => {
      if (res.data.message === SUCCESS)
        dispatch(setCurrentTransferList(res.data.data));
    })
    .catch((err) => {});
};

export const fetchCurrentRawWastes = (vehicleId) => (dispatch) => {
  FleetService.getRawWasteTypes(vehicleId)
    .then((res) => {
      if (res.data.message === SUCCESS)
        dispatch(setCurrentRawWastes(res.data.data));
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const editRawWaste =
  (vehicleId, rawWasteId, data, callBack = () => {}) =>
  (dispatch) => {
    FleetService.editRawWaste(rawWasteId, data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Capacity has been changed", "success");
          dispatch(fetchCurrentRawWastes(vehicleId));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };
export const assignRawWaste =
  (vehicleId, data, callBack = () => {}) =>
  (dispatch) => {
    FleetService.assignRawWaste(vehicleId, data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("New Raw Waste Added", "success");
          dispatch(fetchCurrentRawWastes(vehicleId));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const unassignRawWaste = (vehicleId, data) => (dispatch) => {
  FleetService.unassignRawWaste(vehicleId, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Raw Waste removed", "success");
        dispatch(fetchCurrentRawWastes(vehicleId));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const archiveRawWaste = (vehicleId, wasteId) => (dispatch) => {
  FleetService.archiveRawWaste(wasteId)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Raw Waste archived", "success");
        dispatch(fetchCurrentRawWastes(vehicleId));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const unarchiveRawWaste = (vehicleId, wasteId) => (dispatch) => {
  FleetService.unarchiveRawWaste(wasteId)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Raw Waste unarchived", "success");
        dispatch(fetchCurrentRawWastes(vehicleId));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const activateRawWaste = (vehicleId, wasteId) => (dispatch) => {
  FleetService.activateRawWaste(wasteId)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Raw Waste activated", "success");
        dispatch(fetchCurrentRawWastes(vehicleId));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const deactivateRawWaste = (vehicleId, wasteId) => (dispatch) => {
  FleetService.deactivateRawWaste(wasteId)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Raw Waste deactivated", "success");
        dispatch(fetchCurrentRawWastes(vehicleId));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const fetchAllVehicles = () => (dispatch) => {
  FleetService.getListOfVehicle()
    .then((res) => {
      dispatch(setVehicles(res));
    })
    .catch((err) => {
      showNotification(err, "error");
    });
};

export const fetchAllDrivers = () => (dispatch) => {
  FleetService.getDrivers()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setDrivers(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(err, "error");
    });
};
export const editMaintenanceRecord = (id, data, callBack) => (dispatch) => {
  FleetService.editMaintenanceRecord(id, data)
    .then((res) => {
      callBack();
      showNotification("Maintenance record updated", "success");
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const deleteMaintenanceRecord =
  (id, callBack = () => {}) =>
  (dispatch) => {
    FleetService.deleteMaintenanceRecord(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification(
            "Maintenance Records removed successfully",
            "success"
          );
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const fetchVehicleRouteList = () => (dispatch) => {
  FleetService.fetchVehicleRouteList()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setVehicleRoutes(res.data.data));
      }
    })
    .catch((err) => {});
};
