import { classes } from "../../../utils/class";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Item, ItemList } from "../../../components/ItemList";
import { useState } from "react";
import {
  setAddDepositStep,
  setCurrentPickup,
} from "../../../slices/pickupSlice";
import { InputTitle, PageTitle } from "../../../components/Typography";
import { StateSpan } from "../../../components/StateSpan";
import {
  Tab,
  TabBody,
  TabHeader,
  TabHeaderItem,
  Tabs,
} from "../../../components/Tab";
import {
  Menu,
  MenuItem,
  Button,
  Stack,
  Box,
  Modal,
  Skeleton,
} from "@mui/material";
import Profile from "./Tab/Profile";
import ChangeStatus from "./Dialog/PickupStatusChangeDialog";
import BalanceHistory from "./Tab/BalanceHistory";
import VisitPage from "./Tab/VisitPage";
import Deposits from "./Tab/Deposits";
import Transfers from "./Tab/Transfers";
import Documents from "./Tab/Documents";
import AdjustBalance from "./Dialog/AdjustBalance";
import { PickupDocumentsModal } from "../../../components/pickup/Modal";
import { useEffect } from "react";
import {
  activatePickup,
  addDocument,
  archivePickup,
  deactivatePickup,
  fetchPickupDeposits,
  fetchPickupInventory,
  fetchPickupOffer,
  fetchPickupPointDetail,
  fetchPickupPointDocuments,
  fetchPickupPointScheduledVisits,
  fetchPickupPointTransfers,
  fetchPickupPointVisits,
  fetchPickupPoints,
  removePickup,
  unarchivePickup,
} from "../../../actions/pickup";
import {
  STATE_ACTIVE,
  STATE_ARCHIVE,
  STATE_INACTIVE,
} from "../../../utils/type";
import Inventory from "./Tab/Inventory";
import Offer from "./Tab/Offer";
import { MoreVertOutlined } from "@mui/icons-material";
import {
  BACKOFFICE,
  DRIVER,
  OWNER,
  SALES,
  SALES_MANAGER,
  WAREHOUSE_MANAGER,
} from "../../../utils/constants";

const PickupDetailPage = () => {
  const dispatch = useDispatch();
  const {
    currentPickup,
    pickupList,
    pickupDetail,
    transferList,
    visitList,
    inventoryList,
    offerList,
    depositList,
    scheduledVisitList,
    documentList,
  } = useSelector((state) => state.pickup);
  const role = useSelector((state) => state.user.role);

  const [currentPickupId, setCurrentPickupId] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [anchorEL, setAnchorEL] = useState(null);
  const [open, setOpen] = useState(false);
  const [activation, setActivation] = useState(false);
  const [changeStatusModal, setChangStatusModal] = useState(false);
  const [adjustBalance, setAdjustBalance] = useState(false);
  const [addDocumentModal, setAddDocumentModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };
  const onPickupChange = (pickup) => {
    dispatch(setCurrentPickup(pickupList[pickup]._id));
  };

  const tabChange = (value) => {
    setCurrentTab(value);
    localStorage.setItem("pickupTab", value);
  };

  const getPickupIndex = (id) => {
    for (let i = 0; i < pickupList.length; i++) {
      if (pickupList[i]._id === id) {
        return i;
      }
    }
    return 0;
  };

  useEffect(() => {
    dispatch(fetchPickupPoints());
    const t = localStorage.getItem("pickupTab");
    if (t) setCurrentTab(Number(t));
  }, []);

  useEffect(() => {
    if (pickupList.length > 0)
      setCurrentPickupId(pickupList[getPickupIndex(currentPickup)]?._id);
  }, [currentPickup, pickupList]);

  useEffect(() => {
    if (currentPickupId) {
      setIsLoaded(false);
      dispatch(
        fetchPickupPointDetail(currentPickupId, () => {
          setIsLoaded(true);
        })
      );
      switch (currentTab) {
        case 1:
          if (role !== WAREHOUSE_MANAGER) {
            dispatch(
              fetchPickupDeposits(currentPickupId, () => {
                setIsLoaded(true);
              })
            );
          }
          // dispatch(fetchPickupPointDetail(currentPickupId));
          break;
        case 2:
          // dispatch(fetchPickupPointDetail(currentPickupId));
          break;
        case 3:
          dispatch(
            fetchPickupPointTransfers(currentPickupId, () => {
              setIsLoaded(true);
            })
          );
          break;
        case 4:
          dispatch(
            fetchPickupPointVisits(currentPickupId, () => {
              setIsLoaded(true);
            })
          );
          dispatch(
            fetchPickupPointScheduledVisits(currentPickupId, () => {
              setIsLoaded(true);
            })
          );
          break;
        case 5:
          if (role !== WAREHOUSE_MANAGER) {
            dispatch(
              fetchPickupPointDocuments(currentPickupId, () => {
                setIsLoaded(true);
              })
            );
          }
          break;
        case 6:
          // dispatch(fetchPickupPointDetail(currentPickupId));
          dispatch(
            fetchPickupInventory(currentPickupId, () => {
              setIsLoaded(true);
            })
          );
          break;
        case 7:
          dispatch(
            fetchPickupOffer(currentPickupId, () => {
              setIsLoaded(true);
            })
          );
          // dispatch(fetchPickupPointDetail(currentPickupId));
          break;
        default:
          break;
      }

      setActivation(false);
    }
  }, [currentPickupId, dispatch, currentTab]);

  let options = [];
  if (
    role === BACKOFFICE ||
    role === OWNER ||
    role === SALES_MANAGER ||
    role === SALES
  ) {
    if (pickupDetail?.status === "active") {
      options = [
        {
          label: "Inactive",
          value: "inactive",
        },
      ];
    } else if (pickupDetail?.status === "inactive") {
      if (pickupDetail?.archived) {
        options = [
          {
            label: "Unarchive",
            value: "unarchive",
          },
        ];
      } else {
        options = [
          {
            label: "Active",
            value: "active",
          },
          {
            label: "Archive",
            value: "archive",
          },
        ];
      }
    }
  }

  return (
    <>
      <Stack>
        <Stack minHeight={"100vh"} height={"100%"} direction={"row"}>
          <Stack sx={{ backgroundColor: "#F3F1EB", paddingTop: "32px" }}>
            <Box
              sx={{
                ...classes.buttonBack,
                marginLeft: "24px",
                marginBottom: "30px",
              }}
              onClick={() => {
                navigate("/collection");
              }}
            >
              <ArrowBackIcon fontSize="" sx={{ marginRight: "8px" }} /> Back to
              list
            </Box>
            <ItemList
              value={getPickupIndex(currentPickup)}
              onSelectChange={onPickupChange}
              label="Select Collection Point"
            >
              {pickupList.map((item, index) => {
                return <Item key={index} title={item.name} content={item.id} />;
              })}
            </ItemList>
          </Stack>
          <Stack
            paddingTop={"50px"}
            paddingX={"17px"}
            width={"calc(100% - 250px);"}
            sx={{
              backgroundColor: "white",
              height: "100%",
              minHeight: "100vh",
            }}
            gap={1}
          >
            <Stack gap={1}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <PageTitle>{pickupDetail?.name}</PageTitle>
                  <StateSpan
                    disabled={role === WAREHOUSE_MANAGER || role === DRIVER}
                    status={
                      pickupDetail?.archived
                        ? STATE_ARCHIVE
                        : pickupDetail?.status
                    }
                    options={options}
                    onStateChangeHandler={(status) => {
                      if (status === STATE_ACTIVE) {
                        dispatch(
                          activatePickup(currentPickupId, () => {
                            setActivation(true);
                          })
                        );
                      } else if (status === STATE_INACTIVE) {
                        dispatch(deactivatePickup(currentPickupId));
                      } else if (status === "archive") {
                        dispatch(archivePickup(currentPickupId));
                      } else {
                        dispatch(unarchivePickup(currentPickupId));
                      }
                    }}
                  />
                </Stack>
                <Stack mr={"17px"}>
                  <Button
                    variant="outlined"
                    sx={classes.buttonMoreOption}
                    onClick={(e) => {
                      setAnchorEL(e.currentTarget);
                      setOpen(!open);
                    }}
                  >
                    Options
                    <MoreVertOutlined />
                  </Button>
                  <Menu
                    sx={classes.optionMenu}
                    id="basic-menu"
                    open={open}
                    anchorEl={anchorEL}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      horizontal: "center",
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {currentTab === 0 && (
                      <>
                        {role !== WAREHOUSE_MANAGER &&
                          role !== DRIVER &&
                          pickupDetail?.status === "inactive" && (
                            <MenuItem
                              sx={{
                                minWidth: "228px",
                              }}
                              onClick={() => {
                                dispatch(
                                  activatePickup(currentPickupId, () => {
                                    setActivation(true);
                                  })
                                );
                                handleClose();
                              }}
                            >
                              Activate
                            </MenuItem>
                          )}
                        {role !== WAREHOUSE_MANAGER &&
                          role !== DRIVER &&
                          pickupDetail?.status === "active" && (
                            <MenuItem
                              sx={{
                                minWidth: "228px",
                              }}
                              onClick={() => {
                                dispatch(deactivatePickup(currentPickupId));
                                handleClose();
                              }}
                            >
                              Deactivate
                            </MenuItem>
                          )}
                      </>
                    )}
                    {currentTab === 1 && (
                      <>
                        <MenuItem
                          onClick={() => {
                            dispatch(setAddDepositStep(true));
                            handleClose();
                          }}
                          disabled={role === WAREHOUSE_MANAGER}
                        >
                          Add capacity
                        </MenuItem>
                      </>
                    )}
                    {(currentTab === 2 ||
                      currentTab === 3 ||
                      currentTab === 4 ||
                      currentTab === 5 ||
                      currentTab === 6 ||
                      currentTab === 7 ||
                      currentTab === 8) && (
                      <>
                        <MenuItem
                          sx={{
                            minWidth: "228px",
                          }}
                          onClick={() => navigate("/add-visit-log")}
                        >
                          Add visit
                        </MenuItem>
                        {/* <MenuItem
                          sx={{
                            minWidth: "228px",
                          }}
                          onClick={() => {
                            setAdjustBalance(true);
                          }}
                        >
                          Adjust balance
                        </MenuItem> */}
                        {role !== WAREHOUSE_MANAGER && (
                          <MenuItem
                            sx={{
                              minWidth: "228px",
                            }}
                            onClick={() => {
                              setAddDocumentModal(true);
                              handleClose();
                            }}
                          >
                            Add document
                          </MenuItem>
                        )}

                        {role !== WAREHOUSE_MANAGER &&
                          role !== DRIVER &&
                          role !== SALES && (
                            <MenuItem
                              sx={{
                                minWidth: "228px",
                              }}
                              onClick={() => {
                                dispatch(removePickup(currentPickupId));
                                handleClose();
                              }}
                            >
                              Delete collection point
                            </MenuItem>
                          )}

                        {/* <MenuItem
                          sx={{
                            minWidth: "228px",
                          }}
                        >
                          Register offer
                        </MenuItem> */}
                      </>
                    )}
                  </Menu>
                </Stack>
              </Stack>
              <InputTitle>{pickupDetail?.description}</InputTitle>
            </Stack>
            <Stack>
              <Tabs onTabChangeHandler={tabChange} tab={currentTab}>
                <TabHeader>
                  <TabHeaderItem>Profile</TabHeaderItem>
                  <TabHeaderItem>Waste Capacity</TabHeaderItem>
                  <TabHeaderItem>Balance </TabHeaderItem>
                  <TabHeaderItem>Transfers</TabHeaderItem>
                  <TabHeaderItem>Visits</TabHeaderItem>
                  <TabHeaderItem>Documents</TabHeaderItem>
                  {/* <TabHeaderItem>Inventory</TabHeaderItem>
                  <TabHeaderItem>Offers</TabHeaderItem> */}
                </TabHeader>
                <></>
                <TabBody>
                  {isLoaded ? (
                    <Tab>
                      <Profile props={pickupDetail} activation={activation} />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Stack
                          flexDirection={"column"}
                          gap={2}
                          mt={2}
                          flexWrap={"wrap"}
                        >
                          <Skeleton
                            variant="rounded"
                            width={"300px"}
                            height={"376px"}
                          />
                          <Skeleton
                            variant="rounded"
                            width={"300px"}
                            height={"200px"}
                          />
                          <Skeleton
                            variant="rounded"
                            width={"300px"}
                            height={"270px"}
                          />
                        </Stack>
                        <Stack
                          flexDirection={"column"}
                          gap={2}
                          mt={2}
                          flexWrap={"wrap"}
                        >
                          <Skeleton
                            variant="rounded"
                            width={"300px"}
                            height={"176px"}
                          />
                          <Skeleton
                            variant="rounded"
                            width={"300px"}
                            height={"200px"}
                          />
                        </Stack>
                        <Stack
                          flexDirection={"column"}
                          gap={2}
                          mt={2}
                          flexWrap={"wrap"}
                        >
                          <Skeleton
                            variant="rounded"
                            width={"300px"}
                            height={"376px"}
                          />
                          <Skeleton
                            variant="rounded"
                            width={"300px"}
                            height={"200px"}
                          />
                        </Stack>
                        <Stack
                          flexDirection={"column"}
                          gap={2}
                          mt={2}
                          flexWrap={"wrap"}
                        >
                          <Skeleton
                            variant="rounded"
                            width={"300px"}
                            height={"376px"}
                          />
                          <Skeleton
                            variant="rounded"
                            width={"300px"}
                            height={"200px"}
                          />
                          <Skeleton
                            variant="rounded"
                            width={"300px"}
                            height={"300px"}
                          />
                        </Stack>
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <Deposits
                        id={currentPickupId}
                        deposits={depositList}
                        extraInfo={pickupDetail}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <BalanceHistory
                        extraInfo={pickupDetail}
                        props={pickupList[getPickupIndex(currentPickup)]}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <Transfers
                        extraInfo={pickupDetail}
                        id={currentPickupId}
                        props={transferList}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <VisitPage
                        id={currentPickupId}
                        visitList={visitList}
                        scheduledVisitList={scheduledVisitList}
                        extraInfo={pickupDetail}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} my={2}>
                        <Skeleton
                          variant="rounded"
                          width={"200px"}
                          height={"60px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <Documents
                        extraInfo={pickupDetail}
                        props={documentList}
                        id={currentPickupId}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} my={2}>
                        <Skeleton
                          variant="rounded"
                          width={"200px"}
                          height={"60px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <Inventory
                        inventories={inventoryList}
                        extraInfo={pickupDetail}
                        id={currentPickupId}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <Offer
                        id={currentPickupId}
                        offers={offerList}
                        extraInfo={pickupDetail}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} my={2}>
                        <Skeleton
                          variant="rounded"
                          width={"200px"}
                          height={"60px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                </TabBody>
              </Tabs>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <ChangeStatus
        open={changeStatusModal}
        handleClose={() => {
          setChangStatusModal(false);
        }}
        handleContinue={() => {
          setChangStatusModal(false);
        }}
      />
      <AdjustBalance
        open={adjustBalance}
        handleClose={() => {
          setAdjustBalance(false);
        }}
        handleContinue={() => {
          setAdjustBalance(false);
        }}
      />
      <Modal
        open={addDocumentModal}
        onClose={() => {
          setAddDocumentModal(false);
        }}
      >
        <PickupDocumentsModal
          docType="Contract"
          data={pickupDetail}
          extraData={pickupDetail}
          onSaveHandler={(data) => {
            dispatch(
              addDocument(currentPickupId, data, () => {
                dispatch(fetchPickupPointDocuments(currentPickupId));
                setAddDocumentModal(false);
              })
            );
          }}
          onCancelHandler={() => {
            setAddDocumentModal(false);
          }}
        />
      </Modal>
    </>
  );
};
export default PickupDetailPage;
