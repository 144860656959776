import { Box, Stack, Tooltip } from "@mui/material";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getDateStringFromNum } from "../utils/helpers";

const exampleData = [
  {
    label: "12 Jan",
    estimatedAmount: 180,
    realAmount: 150,
    pickedPoints: 1,
  },
  {
    label: "12 Jan",
    estimatedAmount: 100,
    realAmount: 100,
    pickedPoints: 10,
  },
  {
    label: "12 Jan",
    estimatedAmount: 160,
    realAmount: 50,
    pickedPoints: 40,
  },
  {
    label: "12 Jan",
    estimatedAmount: 170,
    realAmount: 90,
    pickedPoints: 30,
  },
];
export const CustomGraph = ({
  data = exampleData,
  expected_waste_perday = 100000,
  pickup_per_day = 100000,
  max_pickup = 100000,
  height = 100,
  width = 10,
  gap = 30,
}) => {
  const [max, setMax] = useState(100000);
  const [points, setPoints] = useState();

  const getPickupHeight = (value) => {
    if (value > max_pickup) return height;
    else return (height * value) / max_pickup;
  };

  const getHeight = (value) => {
    if (value > max) return height;
    else return (height * value) / max;
  };

  const orgData = useSelector((state) => state.organizations.profile);

  useEffect(() => {
    let m = 0;
    let p = "";
    let cnt = 0;
    for (let d of data) {
      if (d.estimatedAmount > m) m = d.estimatedAmount;
      p =
        p +
        `${cnt * (width + gap) + gap / 2},${getPickupHeight(d.pickedPoints)} `;
      cnt = cnt + 1;
    }
    if (m === 0) setMax(100000);
    else setMax(m);
    setPoints(p);
  }, [data]);
  return (
    <>
      <Stack
        position={"relative"}
        flexDirection={"row"}
        gap={`${gap}px`}
        alignItems={"flex-end"}
      >
        <svg
          style={{
            position: "absolute",
            bottom: 0,
            zIndex: 10,
            pointerEvents: "none",
          }}
          height={height}
          width={(width + gap) * data.length}
        >
          <polyline
            points={points}
            style={{
              fill: "none",
              stroke: "black",
              strokeWidth: 2,
            }}
          />
        </svg>
        <svg
          style={{
            position: "absolute",
            bottom: 0,
            pointerEvents: "none",
          }}
          height={height}
          width={(width + gap) * data.length}
        >
          <polyline
            points={`${0},${getPickupHeight(pickup_per_day)} ${
              (width + gap) * data.length - gap
            },${getPickupHeight(pickup_per_day)}`}
            style={{
              fill: "none",
              stroke: "gray",
              strokeWidth: 2,
            }}
          />
          <polyline
            points={`${0},${getHeight(expected_waste_perday)} ${
              (width + gap) * data.length - gap
            },${getHeight(expected_waste_perday)}`}
            style={{
              strokeDasharray: "5,5",
              fill: "none",
              stroke: "gray",
              strokeWidth: 1,
            }}
          />
        </svg>
        {data.map((item, index) => {
          return (
            <>
              <Tooltip
                slotProps={{
                  tooltip: {
                    sx: {
                      background: "black",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "black",
                    },
                  },
                }}
                arrow
                title={
                  <Stack
                    padding={1}
                    gap={1}
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    <Stack flexDirection={"row"}>
                      <Box sx={{ fontWeight: "700" }}>Date:&nbsp;</Box>
                      <Box>
                        {getDateStringFromNum(
                          new Date(item.label).getTime(),
                          orgData.timezone
                        )}
                      </Box>
                    </Stack>
                    <Stack flexDirection={"row"}>
                      <Box sx={{ fontWeight: "700" }}>
                        Planed Expected Collection:&nbsp;
                      </Box>
                      <Box>{item.estimatedAmount} kg</Box>
                    </Stack>
                    <Stack flexDirection={"row"}>
                      <Box sx={{ fontWeight: "700" }}>
                        Suggested Expected Collection:&nbsp;
                      </Box>
                      <Box>{item.realAmount}kg</Box>
                    </Stack>
                    <Stack flexDirection={"row"}>
                      <Box sx={{ fontWeight: "700" }}>
                        Collection Points:&nbsp;
                      </Box>
                      <Box>{item.pickedPoints}</Box>
                    </Stack>
                  </Stack>
                }
                placement="top"
              >
                <Box
                  position={"relative"}
                  sx={{
                    backgroundColor: "background.light",
                    "&:hover": {
                      cursor: "pointer",
                      "& *": {
                        display: "block",
                      },
                    },
                  }}
                  width={width}
                  bottom={0}
                  height={getHeight(item.estimatedAmount)}
                >
                  <Box
                    sx={{ backgroundColor: "text.accent" }}
                    width={width}
                    position={"absolute"}
                    bottom={0}
                    height={getHeight(item.realAmount)}
                  ></Box>
                  <Box
                    position={"absolute"}
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                      display: "none",
                    }}
                    left={-40}
                    width={80}
                    bottom={-30}
                  >
                    {getDateStringFromNum(
                      new Date(item.label).getTime(),
                      orgData.timezone
                    )}
                  </Box>
                </Box>
              </Tooltip>
            </>
          );
        })}
      </Stack>
    </>
  );
};
