import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import { Box, Button, Stack, Typography } from "@mui/material";

// project imports
import {
  InputTitle,
  PageTitle,
  SubTitle,
} from "../../../components/Typography";
import { validateInput } from "../../../utils/validation";
// service imports

// third party

//sx class definition
import { classes } from "../../../utils/class";
import {
  CAutoComplete,
  COfficialIdInput,
  CTextInput,
} from "../../../components/CustomInput";
import { CProducerItem } from "../../../components/StateSpan";
import { CModalChange } from "../../../components/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../../../actions/member";
import { addProducer } from "../../../actions/producers";
import UserService from "../../../services/UserService";

const AddProducerPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const managers = useSelector((state) => state.member.users);
  const { profile } = useSelector((state) => state.user);
  const [changeModal, setChangeModal] = useState(false);
  const [vat_number, setVatNumber] = useState();
  const [vat_number_country, setVatNumberCountry] = useState(
    profile.countryCode
  );
  const [tax_name, setTaxName] = useState();
  const [name, setName] = useState();
  // const [manager, setManager] = useState();

  useEffect(() => {
    dispatch(fetchUserList());
  }, []);

  const handleCloseChangeModal = () => {
    setChangeModal(false);
  };

  const handleSave = () => {
    setChangeModal(false);
  };

  const save = () => {
    dispatch(
      addProducer({ vat_number, tax_name, vat_number_country, name }, () => {
        navigate("/producers");
      })
    );
  };

  const cancel = () => {
    setChangeModal(true);
  };

  return (
    <>
      <Stack sx={{ minHeight: "100vh", height: "100%" }} gap={10}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <PageTitle>Add producer</PageTitle>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={cancel}
            >
              Cancel
            </Button>
            <Button variant="outlined" sx={classes.buttonSave} onClick={save}>
              Save
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "36px",
              minWidth: "422px",
              justifyContent: "center",
              marginBottom: 50,
            }}
          >
            <SubTitle> Identification </SubTitle>
            <Stack>
              <COfficialIdInput
                countryCode={vat_number_country}
                content="VAT"
                placeholder="number"
                onCountryChange={(e, data) => {
                  setVatNumberCountry(data.code);
                }}
                onChange={(e) => {
                  setVatNumber(e.target.value);
                }}
                label={<InputTitle required>VAT number</InputTitle>}
              />
            </Stack>
            <Stack>
              <CTextInput
                placeholder="Add company TAX name"
                title="Company Tax name"
                required
                onChange={(value) => {
                  setTaxName(value);
                }}
              />
            </Stack>
            <Stack>
              <CTextInput
                required
                placeholder="Producer name"
                title="Add producer name"
                onChange={(value) => {
                  setName(value);
                }}
              />
            </Stack>
            {/* <Stack>
              <CAutoComplete
                fixed
                disableDropdown
                title="Manager"
                placeholder="Select manager"
                options={managers.map((user, index) => {
                  return {
                    name: user.name,
                    value: user._id,
                  };
                })}
                onChange={(manager) => {
                  setManager(manager.value);
                }}
              />
            </Stack> */}
          </Box>
        </Box>
      </Stack>

      <CModalChange
        open={changeModal}
        handleCancel={() => {
          handleCloseChangeModal();
          window.history.back();
        }}
        handleSave={handleSave}
        handleClose={handleCloseChangeModal}
      />
    </>
  );
};
export default AddProducerPage;
