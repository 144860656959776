import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material ui
import { Box, Button, Stack, Typography } from "@mui/material";

// proj imports
import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { CPhoneInput, CTextInput } from "../../../../components/CustomInput";
import UnsavedDialog from "../dialogs/UnsavedDialog";
import { useEffect } from "react";
import { showNotification } from "../../../../utils/helpers";
import { updateOrganizationInfo } from "../../../../actions/organizations";
import { fetchUserProfile } from "../../../../actions/user";

const ContactsView = ({ setStage }) => {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.organizations.profile);
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [unsavedOpen, setUnsavedOpen] = useState(false);
  const [phoneCountry, setPhoneCountry] = useState(null);
  const [mobileCountry, setMobileCountry] = useState(null);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);

  useEffect(() => {
    setEmail(company.email);
    setPhone(company.phone);
    setMobile(company.mobile_phone);
  }, [company]);

  const onClickCancel = () => {
    if (
      phone === company.phone &&
      mobile === company.mobile_phone &&
      email === company.email
    ) {
      setStage(0);
      return;
    }
    setUnsavedOpen(true);
  };

  const handleSave = () => {
    if (phone?.length === 0 || mobile?.length === 0 || email?.length === 0) {
      showNotification(
        "Some fields are empty. Please input required fields.",
        "error"
      );
      return;
    }
    dispatch(
      updateOrganizationInfo(
        {
          phone: phone,
          mobile_phone: mobile,
          email,
        },
        () => setStage(0)
      )
    );
  };
  return (
    <Box>
      <PageTitle>Company Profile</PageTitle>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          display: "flex",
          minHeight: "80%",
          marginTop: "40px",
        }}
      >
        <Box sx={{ width: "40%", minWidth: "300px" }}></Box>
        <Stack spacing={3} sx={{ width: "100%", maxWidth: "500px" }}>
          <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
            Contacts
          </Typography>
          <CPhoneInput
            label="Phone"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          <CPhoneInput
            label="Mobile Phone"
            onChange={(e) => setMobile(e.target.value)}
            value={mobile}
          />
          <CTextInput
            title="Email"
            required={true}
            value={email}
            onChange={(v) => setEmail(v)}
            placeholder="Type email"
            bottomTips="Use this field to indicate your professional contact email address within this organization. UCO Network never shares your personal email address with the organizations to which you belong"
          />
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "space-between" }}
          >
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={onClickCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonSave}
              onClick={handleSave}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <UnsavedDialog
        open={unsavedOpen}
        handleLeave={() => setStage(0)}
        handleClose={() => setUnsavedOpen(false)}
        handleSave={handleSave}
      />
    </Box>
  );
};

export default ContactsView;
