import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transfer: [],
  total_collected: 0,
  resume: [],
  reject_reason: "",
  edit_quantity: 0,
  current: {
    details: {},
  },
  wtn_details: null,
};

export const transferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    setRejectReason: (state, action) => {
      state.reject_reason = action.payload;
    },
    setTransfersList: (state, action) => {
      state.transfer = action.payload;
    },
    setCurrentTransferId: (state, action) => {
      state.current.id = action.payload;
    },
    setTransferDetails: (state, action) => {
      state.current.details = action.payload;
    },
    setEditQuantity: (state, action) => {
      state.edit_quantity = action.payload;
    },
    setTransferTotalCollected: (state, action) => {
      state.total_collected = action.payload;
    },
    setTransferResume: (state, action) => {
      state.resume = action.payload;
    },
    setWTNDetails: (state, action) => {
      state.wtn_details = action.payload;
    },
  },
});

export const {
  setRejectReason,
  setTransfersList,
  setCurrentTransferId,
  setTransferDetails,
  setEditQuantity,
  setTransferTotalCollected,
  setTransferResume,
  setWTNDetails,
} = transferSlice.actions;

export default transferSlice.reducer;
