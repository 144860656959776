import React from "react";
import { useSelector } from "react-redux";

// material-ui
import { Box, Stack } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

// project imports
import { DataTable } from "../../../../components/Table";

// sx
import { classes } from "../../../../utils/class";

import { CardPrimary } from "../../../../components/Card";

// action import
const PickupPoints = () => {
  const { pickup, profile } = useSelector((state) => state.warehouse.current);

  const columns = [
    {
      field: "name",
      headerName: "Collection Points",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "official_id",
      headerName: "Official ID",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "id",
      headerName: "ID",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "producer_name",
      headerName: "Producer Name",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "raw_waste_name",
      headerName: "Raw Waste",
      width: 300,
      flex: 2,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
  ];

  return (
    <Stack>
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        <CardPrimary
          type={2}
          title={"Nº Wastes"}
          content={profile.count_of_deposits}
        />
        <CardPrimary
          type={2}
          title={"Nº Vehicles"}
          content={profile.count_of_vehicles}
        />
        <CardPrimary
          type={2}
          title={"Nº Collection Points"}
          content={profile.count_of_pickup_points}
        />
      </Stack>
      <Box sx={{ width: "100%", padding: "20px" }}>
        <DataTable
          rows={pickup}
          columns={columns}
          sx={classes.grid}
          onRowClick={(params, events, details) => {}}
        />
      </Box>
    </Stack>
  );
};

export default PickupPoints;
