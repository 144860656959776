import React from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Button } from "@mui/material";

import { InputTitle, PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

import {
  CDateInput,
  CExpand,
  CRadioSelect,
  CSelect,
  CTextInput,
} from "../../../../components/CustomInput";
import { useState } from "react";
import dayjs from "dayjs";
import { formatDate } from "../../../../utils/date";
const DaysOffDialog = ({
  open = false,
  handleClose = function () {},
  handleContinue = function () {},
}) => {
  const [reason, setReason] = useState("Holiday");
  const [from, setFrom] = useState(dayjs(Date.now()));
  const [to, setTo] = useState(dayjs(Date.now()));
  const [note, setNote] = useState("days off");

  const orgData = useSelector((state) => state.organizations.profile);

  const fromChange = (value) => {
    if (new Date(value).getTime() <= new Date(to).getTime()) {
      setFrom(value);
    } else {
      setTo(value);
      setFrom(value);
    }
  };

  const toChange = (value) => {
    if (new Date(value).getTime() >= new Date(from).getTime()) setTo(value);
    else {
      setFrom(value);
      setTo(value);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            Width: "520px",
            maxWidth: "520px",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} padding={5}>
            <PageTitle>Add days off</PageTitle>
            <Stack direction={"row"} gap={1}>
              <CDateInput
                title="Start date"
                onChange={fromChange}
                value={from}
              />
              <CDateInput title="End date" onChange={toChange} value={to} />
            </Stack>
            <CSelect
              title="Reason list"
              value={reason}
              onSelectChange={(value) => setReason(value)}
              options={["Holiday", "Sick", "Other"]}
            />
            {reason === "Other" && (
              <CTextInput
                rows={4}
                value={note}
                title="Description"
                placeholder="Describe reason"
                onChange={(value) => {
                  setNote(value);
                }}
              />
            )}
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <Button sx={classes.buttonCancel} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                sx={classes.buttonSave}
                onClick={() => {
                  handleContinue({
                    reason: reason,
                    start: formatDate(from.toDate(), orgData.timezone),
                    end: formatDate(to.toDate(), orgData.timezone),
                    note: note,
                  });
                }}
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default DaysOffDialog;
