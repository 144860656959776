import React from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Stack, Tooltip } from "@mui/material";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";

import { DELETE_ROW, VIEW_ROW } from "../../../../utils/type";
import { Percentage } from "../../../../components/Typography";
import { EditDetail } from "../../../../components/StateSpan";
import { DataTable } from "../../../../components/Table";
import { CardPrimary } from "../../../../components/Card";
import { removeZonePickup } from "../../../../actions/zone";

const PickupListItem = ({ id, props, extraInfo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rows = props.map((data) => {
    return {
      ...data,
      id: data._id,
    };
  });

  const columns = [
    {
      width: 200,
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: (props) => {
        return (
          <>
            <Stack>
              <Stack>
                <Stack
                  sx={{
                    fontSize: "18px",
                    fontWeight: 700,
                  }}
                >
                  {props.row.name}
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                gap={1}
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  color: "text.accent",
                }}
              >
                <Stack>{props.row.official_id}</Stack>
                <Stack>{props.row.id}</Stack>
                <Stack>{props.row.producer}</Stack>
              </Stack>
            </Stack>
          </>
        );
      },
    },
    {
      field: "raw_waste",
      headerName: "Raw waste",
      minWidth: 100,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "balance",
      headerName: "Balance",
      minWidth: 100,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "lastPicking",
      width: 200,
      flex: 2,
      headerName: "Last picking",
      renderCell: (props) => {
        const daysAgo = props.row.last_picking_days;
        if (daysAgo === null && daysAgo === undefined) return <>-</>;
        else
          return (
            <Stack direction={"row"} gap={1}>
              {props.row.last_picking_days ? (
                props.row.last_picking_days === 0 ? (
                  "Today"
                ) : (
                  `${props.row.last_picking_days} days ago`
                )
              ) : (
                <>-</>
              )}

              {props.row.description && props.row.description !== "" && (
                <Tooltip
                  slotProps={{
                    tooltip: {
                      sx: {
                        background: "black",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "black",
                      },
                    },
                  }}
                  title={props.row.description}
                  placement="top"
                >
                  <CommentOutlinedIcon
                    sx={{
                      color: "text.light",
                    }}
                  />
                </Tooltip>
              )}
            </Stack>
          );
      },
    },
    {
      minWidth: 100,
      flex: 1,
      field: "qt",
      headerName: "Qt ",
      renderCell: (props) => {
        return (
          <Stack direction={"row"} gap={1}>
            {props.row.total_quantity}
            <Tooltip
              slotProps={{
                tooltip: {
                  sx: {
                    background: "black",
                  },
                },
                arrow: {
                  sx: {
                    color: "black",
                  },
                },
              }}
              title="40% less than expected (167kg)"
              placement="top"
            >
              <Percentage value={props.row.ratio} />
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      minWidth: 100,
      flex: 1,
      field: "nextPicking",
      headerName: "Next collection",
      renderCell: (props) => {
        if (
          props.row.next_picking_days === null ||
          props.row.next_picking_days === undefined
        )
          return <>-</>;
        else
          return (
            <>
              {props.row.next_picking_days === 0 ? (
                "Today"
              ) : (
                <>In {props.row.next_picking_days} days</>
              )}
            </>
          );
      },
    },
    {
      minWidth: 100,
      flex: 1,
      field: "frequency",
      headerName: "Frequency",
      renderCell: (props) => {
        if (props.row.frequency === null) return <>-</>;
        else return <>{props.row.frequency} days</>;
      },
    },
    {
      minWinWidth: 100,
      field: "action",
      headerName: "",
      headerClassName: "custom-header",
      renderCell: (props) => {
        return (
          <>
            <EditDetail
              options={[
                { label: "View collection point", value: VIEW_ROW },
                // { label: "Edit collection point", value: EDIT_ROW },
                { label: "Remove collection point", value: DELETE_ROW },
              ]}
              onStateChangeHandler={(state) => {
                if (state === VIEW_ROW) navigate("/collection-detail");
                else {
                  dispatch(
                    removeZonePickup(id, { pickup_point: props.row._id })
                  );
                }
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <Stack gap={2}>
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        <CardPrimary
          type={1}
          title={"Nº Producers"}
          content={extraInfo?.count_of_producers}
        />
        <CardPrimary
          type={2}
          title={"Nº Collection Points"}
          content={extraInfo?.count_of_pickup_points}
        />
        <CardPrimary
          type={3}
          title={"Total collected"}
          content={extraInfo?.total_collected}
        />
      </Stack>
      <Stack>
        <DataTable
          autoSize
          rows={rows}
          columns={columns}
          // initialState={{
          //   pinnedColumns: { right: ["action"] },
          // }}
          onRowClick={(e) => {}}
          sx={{
            "& .MuiDataGrid-pinnedColumnHeaders": {
              backgroundColor: "green !important",
              opacity: 0,
            },
          }}
        />
      </Stack>
    </Stack>
  );
};
const PickupList = React.memo(PickupListItem);
export default PickupList;
