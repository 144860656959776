import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// project imports
import { PageTitle, SubTitle } from "../../../components/Typography";

// sx
import { classes } from "../../../utils/class";
import {
  editTransferWaybill,
  fetchTransferDetails,
  rejectTransfers,
  validateTransfers,
} from "../../../actions/transfer";
import { PURPOSE_TYPES, getDateStringFromNum } from "../../../utils/helpers";
import { DRIVER } from "../../../utils/constants";

// action import
const TransferDetailPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, type } = params;

  const rejectReason = useSelector((state) => state.transfer.reject_reason);
  const editQuantity = useSelector((state) => state.transfer.edit_quantity);
  const { details } = useSelector((state) => state.transfer.current);
  const orgData = useSelector((state) => state.organizations.profile);
  const role = useSelector((state) => state.user.role);

  useEffect(() => {
    dispatch(fetchTransferDetails(id));
  }, [id]);

  useEffect(() => {
    if (type === "edit")
      dispatch(editTransferWaybill({ ids: [id], quantity: editQuantity }));
  }, [type]);

  const onClickValidate = () => {
    if (role !== DRIVER) {
      dispatch(validateTransfers({ ids: [id] }, navigate));
    }
  };
  const onClickReject = () => {
    dispatch(rejectTransfers({ ids: [id], reason: rejectReason }, navigate));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "23px",
        gap: "8px",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <PageTitle>Transfer</PageTitle>
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
          {type === "view" ? (
            <>
              <Button
                variant="outlined"
                sx={classes.buttonPrimary}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                sx={classes.buttonPrimary}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              {type === "reject" && (
                <Button
                  variant="outlined"
                  sx={classes.buttonPrimary}
                  onClick={onClickReject}
                >
                  <CloseOutlinedIcon />
                  Reject
                </Button>
              )}
              {type !== "reject" && (
                <Button
                  variant="outlined"
                  sx={classes.buttonPrimary}
                  onClick={onClickValidate}
                  disabled={role === DRIVER}
                >
                  <CheckIcon
                    sx={{
                      fontSize: "14px",
                    }}
                  />
                  &nbsp;Validate
                </Button>
              )}
            </>
          )}
        </Stack>
      </Box>
      <Stack sx={{ alignItems: "center" }} spacing={3}>
        {type === "edit" && (
          <Card
            sx={{ width: "500px", padding: "20px", backgroundColor: "#DEDEDE" }}
          >
            <SubTitle>Edited data</SubTitle>
            <Typography>Quantity:</Typography>
            <Typography>
              <s>{`${details.quantity}kg`}</s> {editQuantity}kg
            </Typography>
          </Card>
        )}
        {type === "reject" && (
          <Card
            sx={{ width: "500px", padding: "20px", backgroundColor: "#DEDEDE" }}
          >
            <SubTitle>Reject Reason</SubTitle>
            <Typography sx={{ fontWeight: "bold" }}>Other</Typography>
            <Typography>{rejectReason}</Typography>
          </Card>
        )}
        <Card sx={{ width: "500px", padding: "20px" }}>
          <Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Identification
            </Typography>
            <Stack direction="row">
              <Typography sx={{ width: "200px" }}>Purpose</Typography>
              <Typography>{PURPOSE_TYPES[details.purpose]}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ width: "200px" }}>Date</Typography>
              <Typography>
                {getDateStringFromNum(details.date, orgData.timezone)}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ width: "200px" }}>Origin</Typography>
              <Typography>{details.origin_name}</Typography>
            </Stack>
          </Stack>
        </Card>
        <Card sx={{ width: "500px", padding: "20px" }}>
          <Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Collection Details
            </Typography>
            <Stack direction="row">
              <Typography sx={{ width: "200px" }}>Raw Waste</Typography>
              <Typography>{details.raw_waste_name}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ width: "200px" }}>Quantity</Typography>
              <Typography>{details.quantity} kg</Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ width: "200px", minWidth: "200px" }}>
                Operation Code
              </Typography>
              <Typography>
                {details.operation_code} - {details.operation_code_description}
              </Typography>
            </Stack>
          </Stack>
        </Card>
        <Card sx={{ width: "500px", padding: "20px" }}>
          <Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Transport
            </Typography>
            <Grid container>
              <Stack direction="row">
                <Typography sx={{ width: "200px" }}>
                  Vehicle license plate
                </Typography>
                <Typography>{details.vehicle_reg_plate}</Typography>
              </Stack>
              <Stack direction="row">
                <Typography sx={{ width: "200px" }}>VAT Number</Typography>
                <Typography>{details.vehicle_vat_num}</Typography>
              </Stack>
              <Stack direction="row">
                <Typography sx={{ width: "200px" }}>Driver</Typography>
                <Typography>{details.vehicle_driver_name}</Typography>
              </Stack>
            </Grid>
          </Stack>
        </Card>
        <Card sx={{ width: "500px", padding: "20px" }}>
          <Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Destination
            </Typography>
            <Stack direction="row">
              <Typography sx={{ width: "200px" }}>Destination</Typography>
              <Typography>{details.destination_warehouse_name}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ width: "200px" }}>VAT Number</Typography>
              <Typography>{details.destination_warehouse_vat_num}</Typography>
            </Stack>
          </Stack>
        </Card>
        <Card sx={{ width: "500px", padding: "20px" }}>
          <Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Waybill
            </Typography>
            <Typography
              sx={{ color: "grey", cursor: "pointer" }}
              onClick={() => navigate(`/wtn-detail/${details._id}`)}
            >
              {details.waybill_number}
            </Typography>
          </Stack>
        </Card>
        <Card sx={{ width: "500px", padding: "20px" }}>
          <Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Notes
            </Typography>
            <Typography sx={{ color: "grey" }}>
              {details.notes || "No Notes"}
            </Typography>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

export default TransferDetailPage;
