import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Skeleton, Stack } from "@mui/material";

import { PageTitle } from "../../../components/Typography";
import { CardPrimary } from "../../../components/Card";
import { SearchBar } from "../../../components/CustomInput";
import { EditDetail, StateSpan } from "../../../components/StateSpan";
import { DataTable } from "../../../components/Table";
import FleetService from "../../../services/FleetService";
import { setCurrentFleet } from "../../../slices/fleetSlice";
import { classes } from "../../../utils/class";
import { REMOVE_ID, STATE_ACTIVE, STATE_INACTIVE } from "../../../utils/type";
import {
  BACKOFFICE,
  DRIVER,
  OWNER,
  SALES,
  SALES_MANAGER,
  WAREHOUSE_MANAGER,
} from "../../../utils/constants";
import { showNotification } from "../../../utils/helpers";

const VehicleListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [vehicleRows, setVehicleRows] = useState([]);
  const [availableVehicles, setAvailableVehicles] = useState(0);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [isLoaded, setIsLoaded] = useState(false);

  const role = useSelector((state) => state.user.role);

  const loadList = async () => {
    const result = await FleetService.getListOfVehicle();
    let rows = [];
    let cnt = 0;
    result.forEach((data, index) => {
      if (data.status === STATE_ACTIVE) {
        cnt = cnt + 1;
      }
      rows.push({
        id: index,
        ...data,
        onClick: () => {},
      });
    });
    setAvailableVehicles(cnt);
    setVehicleRows(rows);
    setIsLoaded(true);
  };

  useEffect(() => {
    setIsLoaded(false);
    loadList();
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "reg_plate",
      headerName: "Plate",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "year",
      headerName: "Year",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "count_of_raw_wastes",
      headerName: "Nº Wastes",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => {
        return <span className="mui-ellipsis">{props.value}</span>;
      },
    },
    {
      field: "driver",
      headerName: "Driver",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "warehouse",
      headerName: "Warehouse",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (props) => {
        let editOptions = [];
        if (role === BACKOFFICE || role === OWNER) {
          if (props.row.status === "active") {
            editOptions = [
              {
                label: "Inactive",
                value: STATE_INACTIVE,
              },
              {
                label: "Remove",
                value: REMOVE_ID,
              },
            ];
          } else {
            if (props.row.archived) {
              editOptions = [
                {
                  label: "Unarchive",
                  value: "unarchive",
                },
                {
                  label: "Remove",
                  value: REMOVE_ID,
                },
              ];
            } else {
              editOptions = [
                {
                  label: "Active",
                  value: STATE_ACTIVE,
                },
                {
                  label: "Archive",
                  value: "archive",
                },
                {
                  label: "Remove",
                  value: REMOVE_ID,
                },
              ];
            }
          }
        }
        if (role === WAREHOUSE_MANAGER || role === DRIVER) {
          if (props.row.status === "active") {
            editOptions = [
              {
                label: "Inactive",
                value: STATE_INACTIVE,
              },
            ];
          } else {
            if (props.row.archived) {
            } else {
              editOptions = [
                {
                  label: "Active",
                  value: STATE_ACTIVE,
                },
              ];
            }
          }
        }
        return (
          <>
            <StateSpan status={props.value} />
            <EditDetail
              props={props}
              options={editOptions}
              onStateChangeHandler={async (status) => {
                const id = vehicleRows[props.id]._id;
                if (status !== vehicleRows[props.id].status) {
                  if (status === STATE_ACTIVE) {
                    if (
                      role === OWNER ||
                      role === BACKOFFICE ||
                      role === DRIVER
                    ) {
                      await FleetService.activateVehicle(id);
                    } else
                      showNotification("You don't have permission", "info");
                  } else if (status === STATE_INACTIVE) {
                    if (
                      role === OWNER ||
                      role === BACKOFFICE ||
                      role === DRIVER
                    ) {
                      await FleetService.deactivateVehicle(id);
                    } else
                      showNotification("You don't have permission", "info");
                  } else if (status === "archive") {
                    await FleetService.archiveVehicle(id);
                  } else if (status === "unarchive") {
                    await FleetService.unarchiveVehicle(id);
                  }
                  await loadList();
                }
                if (status === REMOVE_ID) {
                  if (role === OWNER || role === BACKOFFICE) {
                    await FleetService.removeVehicle(id);
                    await loadList();
                  } else {
                    showNotification("You don't have permission", "info");
                  }
                }
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "23px",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            paddingX: "60px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <PageTitle>Fleet</PageTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={() => {
                navigate("/add-vehicle");
              }}
              disabled={
                role === SALES_MANAGER || role === SALES || role === DRIVER
              }
            >
              Add a vehicle
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            paddingX: "60px",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            {isLoaded ? (
              <CardPrimary
                type={1}
                title="Nº Vehicles"
                content={vehicleRows.length}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"86px"} />
            )}
            {isLoaded ? (
              <CardPrimary
                type={2}
                title="Nº available vehicles"
                content={availableVehicles}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"86px"} />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            paddingLeft: "60px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {isLoaded ? (
            <SearchBar
              label="Search Vehicle"
              onSearchChange={(value) => {
                setFilterModel({
                  items: [
                    {
                      id: 1,
                      field: "name",
                      operator: "contains",
                      value: value,
                    },
                  ],
                });
              }}
            />
          ) : (
            <Skeleton variant="rounded" height={"40px"} width={"275px"} />
          )}
        </Box>
        <Stack paddingX="60px">
          {isLoaded ? (
            <DataTable
              fit="100%"
              rows={vehicleRows}
              columns={columns}
              sx={classes.grid}
              onRowClick={(params, event) => {
                dispatch(setCurrentFleet(params.id));
                navigate(`/vehicle-detail`);
              }}
              filterModel={filterModel}
            />
          ) : (
            <Skeleton width={"100%"} height={"200px"} />
          )}
        </Stack>
      </Box>
    </>
  );
};

export default VehicleListPage;
