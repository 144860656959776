import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { CardPrimary } from "../../../../components/Card";
import { DataTable } from "../../../../components/Table";
import { Euro, Percentage } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { PURPOSE_TYPES, getDateStringFromNum } from "../../../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { setSelectedVisitId } from "../../../../slices/visitsSlice";
const VisitPage = ({ visitList, extraInfo, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orgData = useSelector((state) => state.organizations.profile);

  const cols = [
    {
      flex: 1,
      field: "date",
      headerName: "Date",
      renderCell: (props) => {
        return (
          <Typography sx={{ fontWeight: 700 }} className="mui-ellipsis">
            {getDateStringFromNum(
              new Date(props.value).getTime(),
              orgData.timezone
            )}
          </Typography>
        );
      },
    },
    {
      field: "purpose",
      headerName: "Purpose",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{PURPOSE_TYPES[props.row.purpose]}</span>
      ),
    },
    {
      flex: 1,
      field: "zones",
      headerName: "Zone",
      renderCell: (props) => {
        return props.value.map((d, index) => {
          return (
            <Box key={index} className="mui-ellipsis">
              {d.name}
              {index < props.value.length - 1 && <>,&nbsp;</>}
            </Box>
          );
        });
      },
    },
    {
      field: "transfer",
      headerName: "Transfer",
      flex: 2,
      renderCell: (props) => {
        if (!props.row.quantity) return "";
        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: "60px" }}>
              {props.row.quantity} kg
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "member_name",
      headerName: "Member",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      flex: 1,
      field: "notes",
      headerName: "Notes ",
      renderCell: (props) => {
        return (
          <>
            {props.value !== "" && (
              <Tooltip
                slotProps={{
                  tooltip: {
                    sx: {
                      background: "black",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "black",
                    },
                  },
                }}
                title={props.row.notes}
                placement="top"
              >
                <CommentOutlinedIcon />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Stack gap={2}>
      <Stack direction={"row"} gap={1}>
        <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          <CardPrimary
            type={1}
            title={"Balance"}
            content={extraInfo?.balance || 0}
          />
          <CardPrimary
            type={2}
            title={"Next collection"}
            content={`in ${extraInfo?.next_picking || 0} days`}
          />
          <CardPrimary
            type={3}
            title={"Expiring documents"}
            content={extraInfo?.count_expiring_documents || 0}
          />
        </Box>
      </Stack>
      <Stack>
        <DataTable
          fit="100%"
          breakpoint="1600px"
          rows={visitList.map((visit) => {
            return { ...visit, id: visit._id };
          })}
          columns={cols}
          onRowClick={(e) => {
            dispatch(setSelectedVisitId(e.row.id));
            navigate("/visits-detail");
          }}
          // initialState={{
          //   pinnedColumns: { right: ["actions"] },
          // }}
        />
      </Stack>
    </Stack>
  );
};

export default VisitPage;
