import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  InputTitle,
  PageTitle,
  SubTitle,
} from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

import {
  CExpand,
  CRadioSelect,
  CTextInput,
} from "../../../../components/CustomInput";
import { useState } from "react";
import { useEffect } from "react";
const CreateTeamDialog = ({
  open = false,
  handleClose = function () {},
  handleContinue = function () {},
  team = null,
}) => {
  const [currentTeam, setCurrentTeam] = useState(team);
  const [teamType, setTeamType] = useState("internal");
  useEffect(() => {
    setCurrentTeam(team);
  }, [team]);
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            Width: "420px",
            minWidth: "420px",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} padding={5}>
            <PageTitle>Create team</PageTitle>
            <CTextInput
              title="Team name"
              required
              placeholder="Recoil team viseu"
              value={currentTeam}
              onChange={(value) => {
                setCurrentTeam(value);
              }}
            />
            {/* <Stack>
              <InputTitle required> Team definition</InputTitle>
              <InputTitle disabled>
                Below is an explanation of the teams you can add to your
                organization. Please select the one that most suits your needs
              </InputTitle>
            </Stack> */}
            {/* <CRadioSelect
              sx={{
                width: "100%",
                gap: "10px",
                "& .MuiFormControlLabel-label": {
                  width: "100%",
                },
              }}
              checkedSx={{
                backgroundColor: "text.accent",
                width: "100%",
              }}
              unCheckedSx={{
                backgroundColor: "background.light",
                width: "100%",
              }}
              radioRawDirection={false}
              defaultValue={"internal"}
              options={[
                {
                  value: "internal",
                  data: (
                    <CExpand
                      title={"Internal Team"}
                      expandContent={
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac venenatis orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac venenatis orci."
                      }
                      content={
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac venenatis orci."
                      }
                    />
                  ),
                },
                {
                  value: "external",
                  data: (
                    <CExpand
                      title={"External Team"}
                      expandContent={
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac venenatis orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac venenatis orci."
                      }
                      content={
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac venenatis orci."
                      }
                    />
                  ),
                },
              ]}
              onChange={(val) => {
                setTeamType(val);
              }}
            /> */}
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <Button sx={classes.buttonCancel} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                sx={classes.buttonSave}
                onClick={() => {
                  handleClose();
                  handleContinue({
                    name: currentTeam,
                    type: teamType,
                  });
                }}
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default CreateTeamDialog;
