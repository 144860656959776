import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Box, Button, Menu, MenuItem, Skeleton } from "@mui/material";
import { MoreVertOutlined } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { setCurrentZone } from "../../../slices/zoneSlice";
import { Item, ItemList } from "../../../components/ItemList";
import { PageTitle } from "../../../components/Typography";
import { StateSpan } from "../../../components/StateSpan";
import {
  Tab,
  TabBody,
  TabHeader,
  TabHeaderItem,
  Tabs,
} from "../../../components/Tab";
import PickupList from "./Tab/PickupPoint";
import Profile from "./Tab/Profile";
import Transfers from "./Tab/Transfers";
import VisitPage from "./Tab/VisitPage";
import RemoveZoneDialog from "./Dialog/RemoveZoneDialog";
import {
  activateZone,
  deactivateZone,
  fetchZoneDetail,
  fetchZoneList,
  fetchZonesPickup,
  fetchTransferList,
  removeZone,
} from "../../../actions/zone";
import { classes } from "../../../utils/class";
import { BACKOFFICE, OWNER } from "../../../utils/constants";

const ZoneDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);

  const {
    transferList,
    zoneList,
    extraInfo,
    currentZone,
    currentZoneData,
    currentZonePickup,
  } = useSelector((state) => {
    return state.zone;
  });

  const [currentTab, setCurrentTab] = useState(3);
  const [anchorEL, setAnchorEL] = useState(null);
  const [open, setOpen] = useState(false);
  const [removeZoneDialog, setRemoveZone] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const onZoneChange = (zone) => {
    dispatch(setCurrentZone(zoneList[zone]._id));
    setIsLoaded(false);
  };

  const tabChange = (value) => {
    localStorage.setItem("zoneTab", value);
    setCurrentTab(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getZoneIndex = (id) => {
    for (let i = 0; i < zoneList.length; i++) {
      if (zoneList[i]._id === id) {
        return i;
      }
    }
    return 0;
  };

  useEffect(() => {
    const t = localStorage.getItem("zoneTab");
    if (t) setCurrentTab(Number(t));
  }, []);

  useEffect(() => {
    dispatch(fetchZoneList());
  }, [dispatch]);

  useEffect(() => {
    if (zoneList.length > 0) {
      let id = zoneList[0]._id;
      if (currentZone !== 0) {
        id = currentZone;
      }
      if (currentTab === 0 || currentTab === 3)
        dispatch(
          fetchZoneDetail(id, () => {
            setIsLoaded(true);
          })
        );
      if (currentTab === 1)
        dispatch(
          fetchZonesPickup(id, () => {
            setIsLoaded(true);
          })
        );
      if (currentTab === 2)
        dispatch(
          fetchTransferList(id, () => {
            setIsLoaded(true);
          })
        );
    }
  }, [currentZone, currentTab, dispatch, zoneList]);

  return (
    <Stack>
      <Stack minHeight={"100vh"} height={"100%"} direction={"row"}>
        <Stack sx={{ backgroundColor: "#F3F1EB", paddingTop: "32px" }}>
          <Box
            sx={{
              ...classes.buttonBack,
              marginLeft: "24px",
              marginBottom: "30px",
            }}
            onClick={() => {
              navigate("/zones");
            }}
          >
            <ArrowBackIcon fontSize="" sx={{ marginRight: "8px" }} /> Back to
            list
          </Box>
          <ItemList
            value={getZoneIndex(currentZone)}
            onSelectChange={onZoneChange}
            label="Search Zones"
          >
            {zoneList.map((item, index) => {
              return <Item key={index} title={item.name} />;
            })}
          </ItemList>
        </Stack>
        <Stack
          paddingTop={"50px"}
          paddingX={"17px"}
          width={"calc(100% - 250px);"}
          sx={{
            backgroundColor: "white",
            minHeight: "100vh",
          }}
          gap={1}
        >
          <Stack gap={1}>
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <PageTitle>{currentZoneData?.name}</PageTitle>
                <StateSpan
                  disabled={role !== BACKOFFICE && role !== OWNER}
                  status={currentZoneData?.status}
                  options={[
                    {
                      label: "Active",
                      value: "active",
                    },
                    {
                      label: "Inactive",
                      value: "inactive",
                    },
                  ]}
                  onStateChangeHandler={(state) => {
                    if (state === "active") {
                      dispatch(activateZone(currentZoneData?._id));
                    } else {
                      dispatch(deactivateZone(currentZoneData?._id));
                    }
                  }}
                />
              </Stack>
              <Stack mr={"17px"}>
                <Button
                  variant="outlined"
                  sx={classes.buttonMoreOption}
                  onClick={(e) => {
                    setAnchorEL(e.currentTarget);
                    setOpen(!open);
                  }}
                >
                  Options
                  <MoreVertOutlined />
                </Button>
                <Menu
                  sx={classes.optionMenu}
                  id="basic-menu"
                  open={open}
                  anchorEl={anchorEL}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    horizontal: "center",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {
                    <>
                      <MenuItem
                        sx={{
                          minWidth: "228px",
                        }}
                        onClick={() => {
                          navigate(
                            `/manage-collection/${currentZoneData?._id}`
                          );
                        }}
                      >
                        Manage collection point
                      </MenuItem>
                      <MenuItem
                        sx={{
                          minWidth: "228px",
                        }}
                        onClick={() => {
                          setRemoveZone(true);
                        }}
                        disabled={role !== BACKOFFICE && role !== OWNER}
                      >
                        Remove zone
                      </MenuItem>
                    </>
                  }
                </Menu>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Tabs onTabChangeHandler={tabChange} tab={currentTab}>
              <TabHeader>
                <TabHeaderItem>Profile</TabHeaderItem>
                <TabHeaderItem>Collection points</TabHeaderItem>
                <TabHeaderItem>Transfers</TabHeaderItem>
                <TabHeaderItem>Visit</TabHeaderItem>
              </TabHeader>
              <></>
              <TabBody>
                {isLoaded ? (
                  <Tab>
                    <Profile props={currentZoneData} extraInfo={extraInfo} />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                    <Stack
                      flexDirection={"row"}
                      gap={2}
                      mt={2}
                      flexWrap={"wrap"}
                    >
                      <Skeleton
                        variant="rounded"
                        width={"300px"}
                        height={"230px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"300px"}
                        height={"303px"}
                      />
                    </Stack>
                  </Tab>
                )}
                {isLoaded ? (
                  <Tab>
                    <PickupList
                      props={currentZonePickup}
                      extraInfo={extraInfo}
                      id={currentZoneData?._id}
                    />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                    <Stack flexDirection={"row"} mt={2}>
                      <Skeleton
                        variant="rounded"
                        width={"80%"}
                        height={"100px"}
                      />
                    </Stack>
                  </Tab>
                )}
                {isLoaded ? (
                  <Tab>
                    <Transfers
                      props={transferList}
                      extraInfo={extraInfo}
                      id={currentZoneData?._id}
                    />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                    <Stack flexDirection={"row"} mt={2}>
                      <Skeleton
                        variant="rounded"
                        width={"80%"}
                        height={"100px"}
                      />
                    </Stack>
                  </Tab>
                )}
                {isLoaded ? (
                  <Tab>
                    <VisitPage
                      props={currentZoneData}
                      extraInfo={extraInfo}
                      id={currentZoneData?._id}
                    />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                    <Stack flexDirection={"row"} mt={2}>
                      <Skeleton
                        variant="rounded"
                        width={"320px"}
                        height={"42px"}
                      />
                    </Stack>
                    <Stack flexDirection={"row"} mt={2}>
                      <Skeleton
                        variant="rounded"
                        width={"80%"}
                        height={"100px"}
                      />
                    </Stack>
                  </Tab>
                )}
              </TabBody>
            </Tabs>
          </Stack>
        </Stack>
      </Stack>
      <RemoveZoneDialog
        open={removeZoneDialog}
        handleClose={() => {
          setRemoveZone(false);
        }}
        handleContinue={() => {
          dispatch(removeZone(currentZoneData._id));
        }}
      />
    </Stack>
  );
};

export default ZoneDetailPage;
