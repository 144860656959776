import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import { PageTitle } from "../../../components/Typography";
import { CardPrimary } from "../../../components/Card";
import { classes } from "../../../utils/class";
import { useNavigate } from "react-router-dom";
import { SearchBar } from "../../../components/CustomInput";
import { useEffect, useState } from "react";
import { EditDetail, Badge, StateSpan } from "../../../components/StateSpan";
import { DataTable } from "../../../components/Table";
import {
  ACTIVATE_ITEM,
  ADD_STOCK,
  ARCHIVE_ITEM,
  BADGE_A_TO_CLIENT,
  BADGE_A_TO_DRIVER,
  DEACTIVATE_ITEM,
  DELETE_STOCK,
  EDIT_ITEM_CATEGORY,
  PRICE_HISTORY,
  STATE_ACTIVE,
  STATE_ARCHIVE,
  STATE_AVAILABLE,
  STATE_UNAVAILABLE,
  UNARCHIVE_ITEM,
} from "../../../utils/type";
import SubInventory from "./SubInventoryDatagrid";
import AddStockDialog from "./AddStockDialog";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  activateInventory,
  archiveInventory,
  deactivateInventory,
  fetchInventory,
  fetchInventoryCategory,
  unarchiveInventory,
} from "../../../actions/inventory";
import FiltersDialog from "./dialog/FiltersDialog";
const InventoryListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inventories = useSelector((state) => state.inventory.inventories);
  const categories = useSelector((state) => state.inventory.categories);
  const [addDialog, setDialog] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [filter, setFilter] = useState({
    category: {},
    availableTo: {},
    status: {},
  });
  const [rows, setRows] = useState([]);
  const [currentCategory, setCurrentCategory] = useState();
  const [filterModal, setFilterModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [inventoryNumber, setInventoryNumber] = useState({
    all: 0,
    available: 0,
  });
  const handleCloseAddDialog = () => {
    setDialog(false);
  };

  const columns = [
    {
      field: "category",
      headerName: "Category",
      width: 500,
      // valueGetter: (params) => {
      //   return `${params.row.category.category} ${params.row.name.name}`;
      // },
      renderCell: (props) => {
        return (
          <>
            <Stack
              position={"relative"}
              direction={"row"}
              alignItems={"center"}
              spacing={8}
              paddingLeft={4}
              width={"100%"}
            >
              <Stack width={"50%"}>
                <Typography> {props.row.category.category} </Typography>
              </Stack>
              <Stack
                width={"50%"}
                direction={"row"}
                justifyContent={"flex-start"}
              >
                <Badge status={props.row.category.status} />
              </Stack>
            </Stack>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 400,
      renderCell: (props) => {
        return (
          <>
            <Stack spacing={2} direction={"row"} alignItems={"center"}>
              <Typography> {props.value.name} </Typography>
              {props.value.states.map((state, index) => {
                return <Badge status={state} key={index} />;
              })}
            </Stack>
          </>
        );
      },
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 300,
      renderCell: (props) => {
        return (
          <>
            <Typography>
              {props.value} {props.row.unit}
            </Typography>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (props) => {
        return (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <StateSpan
              fixed
              status={props.row.archived ? STATE_ARCHIVE : props.value}
            />
            <EditDetail
              direction="left"
              props={props}
              onStateChangeHandler={(status) => {
                if (status === ADD_STOCK) {
                  setCurrentCategory({
                    name: props.row.name.name,
                    id: props.row.id,
                  });
                  setDialog(true);
                } else if (status === PRICE_HISTORY)
                  navigate(
                    `/inventory-price-history/${props.row.id}?name=${props.row.name.name}`
                  );
                else if (status === DELETE_STOCK) {
                } else if (status === DEACTIVATE_ITEM) {
                  dispatch(deactivateInventory(props.row.id));
                } else if (status === ACTIVATE_ITEM) {
                  dispatch(activateInventory(props.row.id));
                } else if (status === ARCHIVE_ITEM) {
                  dispatch(archiveInventory(props.row.id));
                } else if (status === UNARCHIVE_ITEM) {
                  dispatch(unarchiveInventory(props.row.id));
                }
              }}
              options={[
                { label: "Add Stock", value: ADD_STOCK },
                { label: "Remove Stock", value: DELETE_STOCK },
                // { label: "Edit item category", value: EDIT_ITEM_CATEGORY },
                { label: "Price History", value: PRICE_HISTORY },
                {
                  label:
                    props.row.status === STATE_AVAILABLE
                      ? "Deactivate Item"
                      : "Activate Item",
                  value:
                    props.row.status === STATE_AVAILABLE
                      ? DEACTIVATE_ITEM
                      : ACTIVATE_ITEM,
                },
                {
                  label: props.row.archived ? "Unarchive Item" : "Archive Item",
                  value: props.row.archived ? UNARCHIVE_ITEM : ARCHIVE_ITEM,
                },
              ]}
            />
          </Stack>
        );
      },
    },
  ];

  const filterInventory = (inventory) => {
    // availalbeTo
    let selectedList = [];
    for (let category of categories) {
      if (filter.category[category.name] === true)
        selectedList.push(category.name);
    }

    if (
      selectedList.length > 0 &&
      selectedList.indexOf(inventory.category_name) === -1
    )
      return false;

    if (
      filter.availableTo["Available to Driver"] &&
      filter.availableTo["Available to Client"] &&
      inventory.available_to_client === false &&
      inventory.available_to_driver === false
    )
      return false;
    else if (
      filter.availableTo["Available to Driver"] &&
      !filter.availableTo["Available to Client"] &&
      inventory.available_to_driver === false
    ) {
      return false;
    } else if (
      !filter.availableTo["Available to Driver"] &&
      filter.availableTo["Available to Client"] &&
      inventory.available_to_client === false
    ) {
      return false;
    }
    //status
    if (
      filter.status["Available"] &&
      !filter.status["Unavailable"] &&
      inventory.status === "unavailable"
    ) {
      return false;
    } else if (
      !filter.status["Available"] &&
      filter.status["Unavailable"] &&
      inventory.status === "available"
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    dispatch(
      fetchInventory(false, () => {
        setIsLoaded(true);
      })
    );
    dispatch(fetchInventoryCategory());
  }, []);

  useEffect(() => {
    let tempRow = [];
    let availableCnt = 0;
    for (let i = 0; i < inventories.length; i++) {
      let tempDetail = [];
      let badges = [];
      for (let sub of inventories[i].stocks) {
        tempDetail.push({
          ...sub,
          id: sub._id,
        });
      }

      if (inventories[i].available_to_driver) badges.push(BADGE_A_TO_DRIVER);
      if (inventories[i].available_to_client) badges.push(BADGE_A_TO_CLIENT);
      tempRow.push({
        ...inventories[i],
        id: inventories[i]._id,
        category: {
          category: inventories[i].category_name,
          status: inventories[i].is_deposit ? "deposit" : "offer",
        },
        name: { name: inventories[i].name, states: badges },
        totalAmount: inventories[i].total_amount,
        detailPanel: tempDetail,
        unit: inventories[i].unit,
        status:
          inventories[i].status === STATE_ACTIVE
            ? STATE_AVAILABLE
            : STATE_UNAVAILABLE,
      });
      if (inventories[i].status === STATE_ACTIVE) availableCnt++;
    }
    setRows(tempRow);
    setInventoryNumber({
      available: availableCnt,
      all: inventories.length,
    });
  }, [inventories]);

  const detailContent = (params) => {
    return (
      <>
        <Stack
          sx={{
            backgroundColor: "#F7F6F3",
          }}
        >
          <SubInventory
            inventoryId={params.row.id}
            data={params.row.detailPanel}
          />
        </Stack>
      </>
    );
  };
  if (rows.length > 0)
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "23px",
            gap: "8px",
            paddingX: "60px",
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <PageTitle>Inventory List</PageTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                sx={classes.buttonPrimary}
                onClick={() => {
                  navigate("/add-inventory");
                }}
              >
                Add inventory
              </Button>
            </Box>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "8px" }} paddingY={"20px"}>
              {isLoaded ? (
                <CardPrimary
                  type={1}
                  title="Nº Inventory"
                  content={inventoryNumber.all}
                />
              ) : (
                <Skeleton variant="rounded" width={"162px"} height={"86px"} />
              )}

              {isLoaded ? (
                <CardPrimary
                  type={2}
                  title="Nº available inventories"
                  content={inventoryNumber.available}
                />
              ) : (
                <Skeleton variant="rounded" width={"162px"} height={"86px"} />
              )}
            </Box>
          </Box>
          <Stack direction={"row"} justifyContent={"space-between"}>
            {isLoaded ? (
              <SearchBar
                placeholder="Search Inventory"
                onSearchChange={(value) => {
                  setFilterModel({
                    items: [
                      {
                        field: "category",
                        operator: "contains",
                        value: value,
                      },
                    ],
                  });
                }}
              />
            ) : (
              <Skeleton variant="rounded" height={"30px"} width={"275px"} />
            )}

            <Button
              sx={classes.buttonPrimary}
              onClick={() => {
                setFilterModal(true);
              }}
            >
              <FilterAltOutlinedIcon />
              Filter
            </Button>
          </Stack>
          <Stack>
            {isLoaded ? (
              <DataTable
                fit="100%"
                breakpoint="1600"
                // expandable
                columns={columns}
                rows={rows.filter((row, index) => filterInventory(row))}
                detailContent={detailContent}
                filterModel={filterModel}
              />
            ) : (
              <Skeleton width={"100%"} height={"200px"} />
            )}
          </Stack>
        </Box>
        <AddStockDialog
          id={currentCategory?.id}
          name={currentCategory?.name}
          open={addDialog}
          handleClose={handleCloseAddDialog}
        />
        <FiltersDialog
          open={filterModal}
          handleClose={() => {
            setFilterModal(false);
          }}
          handleContinue={(data) => {
            setFilter({
              ...data,
            });
          }}
          selectedOptions={filter}
        />
      </>
    );
  else {
    return (
      <>
        <Box
          sx={{
            minHeight: "100vh",
            height: "100%",
            paddingX: "60px",
            paddingY: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <PageTitle>Inventory</PageTitle>
            <Button
              sx={classes.buttonPrimary}
              onClick={() => {
                navigate("/add-inventory");
              }}
            >
              Add inventory
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="54"
              height="66"
              viewBox="0 0 54 66"
              fill="none"
            >
              <path
                d="M52.5 59.25C52.5 59.6147 52.1844 60.2437 50.8855 61.0186C49.651 61.7551 47.798 62.4534 45.4204 63.0534C40.6806 64.2494 34.0791 65 26.75 65C19.4209 65 12.8194 64.2494 8.07956 63.0534C5.70197 62.4534 3.84901 61.7551 2.61449 61.0186C1.31563 60.2437 1 59.6147 1 59.25C1 58.8853 1.31563 58.2563 2.61449 57.4814C3.84901 56.7449 5.70197 56.0466 8.07956 55.4466C12.8194 54.2506 19.4209 53.5 26.75 53.5C34.0791 53.5 40.6806 54.2506 45.4204 55.4466C47.798 56.0466 49.651 56.7449 50.8855 57.4814C52.1844 58.2563 52.5 58.8853 52.5 59.25Z"
                stroke="#DBDBDB"
                strokeWidth="2"
              />
              <path
                d="M1.25 58.75V7.5C1.25 7.5 9.25 12.75 26.875 12.75C44.5 12.75 52.5 7.5 52.5 7.5V58.75C52.5 58.75 51.25 65 26.875 65C2.5 65 1.25 58.75 1.25 58.75Z"
                fill="#F5F5F5"
                stroke="#DBDBDB"
                strokeWidth="2"
              />
              <path
                d="M1.25 27.5C1.25 27.5 9.25 32.75 26.875 32.75C44.5 32.75 52.5 27.5 52.5 27.5"
                stroke="#DBDBDB"
                strokeWidth="2"
              />
              <path
                d="M1.25 42.5C1.25 42.5 9.25 47.75 26.875 47.75C44.5 47.75 52.5 42.5 52.5 42.5"
                stroke="#DBDBDB"
                strokeWidth="2"
              />
              <path
                d="M52.5 6.75C52.5 7.11472 52.1844 7.74374 50.8855 8.51862C49.651 9.25513 47.798 9.95341 45.4204 10.5534C40.6806 11.7494 34.0791 12.5 26.75 12.5C19.4209 12.5 12.8194 11.7494 8.07956 10.5534C5.70197 9.95341 3.84901 9.25513 2.61449 8.51862C1.31563 7.74374 1 7.11472 1 6.75C1 6.38528 1.31563 5.75627 2.61449 4.98138C3.84901 4.24487 5.70197 3.54659 8.07956 2.94664C12.8194 1.75059 19.4209 1 26.75 1C34.0791 1 40.6806 1.75059 45.4204 2.94664C47.798 3.54659 49.651 4.24487 50.8855 4.98138C52.1844 5.75627 52.5 6.38528 52.5 6.75Z"
                stroke="#DBDBDB"
                strokeWidth="2"
              />
              <ellipse
                cx="39.25"
                cy="6.75"
                rx="2.25"
                ry="0.75"
                fill="#DBDBDB"
              />
            </svg>
            {/* <NoteAddOutlinedIcon
              sx={{ color: "text.tertiary", fontSize: 100 }}
            /> */}
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Manrope",
                fontSize: "10px",
                color: "text.primary",
                marginBottom: "30px",
              }}
            >
              There are no records listed
            </Typography>
            <Button
              size="large"
              sx={classes.buttonPrimary}
              onClick={() => {
                navigate("/add-inventory");
              }}
            >
              Add inventory
            </Button>
          </Box>
        </Box>
      </>
    );
  }
};
export default InventoryListPage;
