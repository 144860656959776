import { useEffect, useState } from "react";
import { Box, Modal, Stack, Button } from "@mui/material";
import moment from "moment-timezone";

import { classes } from "../../../../utils/class";
import { getGoogleMapImage, getPlaceDetails } from "../../../../utils/map";
import { DAYS_COLORS, ROUTE_COLORS } from "../../../../utils/constants";
import { SubTitle } from "../../../../components/Typography";
import { WarehouseIcon } from "../../../../components/icons/menu_icons/icons";
import PickupService from "../../../../services/PickupService";

const CircledLocation = ({
  children,
  urgency = 0,
  selected = false,
  assigned = false,
  size = "28px",
}) => {
  const color = DAYS_COLORS[urgency];

  return (
    <>
      <Box
        sx={{
          borderRadius: "50%",
          minWidth: size,
          minHeight: size,
          backgroundColor: color,
          color: "white",
          border: "2px solid",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 700,
          position: "relative",
          fontFamily: "Manrope",
          fontSize: "12px",
          borderColor: selected ? "#2CB53B" : "white",
        }}
      >
        {children}
        {assigned && (
          <Box
            position={"absolute"}
            bottom={0}
            right={0}
            sx={{
              transform: "translate(50%,50%)",
            }}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="1" y="1" width="12" height="12" rx="6" fill="#2CB53B" />
              <rect
                x="1"
                y="1"
                width="12"
                height="12"
                rx="6"
                stroke="white"
                strokeWidth="2"
              />
              <path
                d="M5 7.33333L6.33333 8.66797L9 5.33203"
                stroke="#ECFEEE"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
        )}
      </Box>
    </>
  );
};

const VectorItem = ({ children, color, selected = false, sx }) => {
  return (
    <>
      <Box
        position={"relative"}
        sx={{
          width: "59px",
          height: "69px",
          fontWeight: 700,
          marginTop: "-55px",
          marginLeft: "-30px",
          ...sx,
        }}
      >
        <svg
          width="59"
          height="69"
          viewBox="0 0 59 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_8501_10374)">
            <path
              d="M46 26.3077C46 33.7322 41.8171 40.4237 37.499 45.3349C35.3526 47.7761 33.2036 49.7424 31.5909 51.0981C30.7852 51.7754 30.1153 52.2988 29.649 52.6513C29.5967 52.6907 29.547 52.7281 29.5 52.7633C29.453 52.7281 29.4033 52.6907 29.351 52.6513C28.8847 52.2988 28.2148 51.7754 27.4091 51.0981C25.7964 49.7424 23.6474 47.7761 21.501 45.3349C17.1829 40.4237 13 33.7322 13 26.3077C13 21.9862 14.7357 17.8395 17.8288 14.7803C20.9223 11.7208 25.1204 10 29.5 10C33.8796 10 38.0777 11.7208 41.1712 14.7803C44.2643 17.8395 46 21.9862 46 26.3077Z"
              fill={color}
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_8501_10374"
              x="0"
              y="0"
              width="59"
              height="69"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="6" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_8501_10374"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_8501_10374"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
        <Box position={"absolute"}></Box>
        {selected && (
          <Box
            position={"absolute"}
            bottom={25}
            right={18}
            sx={{
              transform: "translate(50%,50%)",
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="1" y="1" width="18" height="18" rx="9" fill="#2CB53B" />
              <rect
                x="1"
                y="1"
                width="18"
                height="18"
                rx="9"
                stroke="white"
                strokeWidth="2"
              />
              <path
                d="M7 10.5L9 12.502L13 7.49805"
                stroke="#ECFEEE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
        )}
        <Stack
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={"80%"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            color: "white",
          }}
        >
          {children}
        </Stack>
      </Box>
    </>
  );
};

const LocationItem = ({ type = 0, number = null, ...props }) => {
  if (props.lat && props.lng)
    return (
      <>
        {type === 0 && <CircledLocation {...props}>{number}</CircledLocation>}
        {type === 1 && <VectorItem {...props}>{number}</VectorItem>}
        {type === 2 && <CircledLocation {...props} size="10px" />}
      </>
    );
  else return null;
};

const PlacesItem = ({ data, onClick = () => {} }) => {
  const [open, setOpen] = useState(false);
  const [photo_url, setPhotoUrl] = useState(null);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (data && open) {
      if (data?.photos?.length > 0) {
        setPhotoUrl(getGoogleMapImage(data.photos[0].photo_reference));
        // getGoogleMapImage(data.photos[0].photo_reference)
        //   .then((url) => {
        //     console.log("url test", url);
        //     setPhotoUrl(url);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      }
      getPlaceDetails(data.place_id).then((data) => {
        setDetails(data);
      });
    }
  }, [data, open]);

  return (
    <>
      <Box
        sx={classes.placeItem}
        onClick={() => {
          setOpen(true);
        }}
      >
        <VectorItem color="#DCE212" />
      </Box>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={classes.placeModal} gap={1}>
          <Stack
            flexDirection={"row"}
            gap={5}
            alignItems={"center"}
            flexWrap={"wrap"}
            justifyContent={"center"}
          >
            {photo_url && (
              <Box>
                <img src={photo_url} alt="photo_url" />
              </Box>
            )}
            <Box width={"300px"}>
              <SubTitle>{data.name}</SubTitle>
              <Box
                sx={{
                  fontSize: "18px",
                  fontFamily: "Manrope",
                  fontWeight: 600,
                }}
              >
                <strong>Address:</strong> {details?.formatted_address}
              </Box>
            </Box>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
            }}
          >
            <Button
              sx={classes.buttonCancel}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              sx={classes.buttonSave}
              onClick={() => {
                onClick();
                setOpen(false);
              }}
            >
              Select
            </Button>
          </Box>
        </Stack>
      </Modal>
    </>
  );
};

const ZonePoint = ({
  urgency = 0,
  color = "#FFFFFF",
  selectedColor = "#00FF00",
  selected = false,
  inZone = false,
  ...props
}) => {
  const backgroundColor = ROUTE_COLORS[urgency % 7];
  if (props.lat && props.lng)
    return (
      <Box
        position={"relative"}
        {...props}
        sx={{
          display: "relative",
          "& > svg": {
            transform: "translate(-50%, -100%)",
            position: "absolute",
          },
          "& .hover": {
            display: "none",
          },
          "&:hover": {
            cursor: "pointer",
            "& .tooltip": {
              display: "flex",
            },
            "& .default": {
              display: "none",
            },
            "& .hover": {
              display: "initial",
            },
          },
          "& .tooltip": {
            display: "none",
          },
        }}
      >
        {selected ? (
          <svg
            className="selected"
            width="52"
            height="49"
            viewBox="0 0 52 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_1543_31069)">
              <rect
                x="14"
                y="11"
                width="24"
                height="24"
                rx="12"
                fill={backgroundColor}
                shape-rendering="crispEdges"
              />
              <rect
                x="13"
                y="10"
                width="26"
                height="26"
                rx="13"
                stroke="#2CB53B"
                strokeWidth="2"
                shape-rendering="crispEdges"
              />
              <path
                d="M26 36.5V46.5"
                stroke="#2CB53B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_1543_31069"
                x="0"
                y="0"
                width="52"
                height="62.5"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="6" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_1543_31069"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_1543_31069"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        ) : (
          <>
            {inZone ? (
              <svg
                className="default"
                width="28"
                height="32"
                viewBox="0 0 28 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_1541_19176)">
                  <rect
                    x="7"
                    y="5"
                    width="14"
                    height="14"
                    rx="7"
                    fill={backgroundColor}
                  />
                  <rect
                    x="6"
                    y="4"
                    width="16"
                    height="16"
                    rx="8"
                    stroke="white"
                    strokeWidth="2"
                  />
                </g>
                <path
                  d="M14 20.5V30.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <filter
                    id="filter0_d_1541_19176"
                    x="0.2"
                    y="0.6"
                    width="27.6"
                    height="27.6"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2.4" />
                    <feGaussianBlur stdDeviation="2.4" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1541_19176"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1541_19176"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            ) : (
              <svg
                width="22"
                height="26"
                viewBox="0 0 22 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="default"
              >
                <g filter="url(#filter0_d_1539_17129)">
                  <rect
                    x="7"
                    y="5"
                    width="8"
                    height="8"
                    rx="4"
                    fill={backgroundColor}
                  />
                  <rect
                    x="6"
                    y="4"
                    width="10"
                    height="10"
                    rx="5"
                    stroke="white"
                    strokeWidth="2"
                  />
                </g>
                <path
                  d="M11 14.5V24.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <filter
                    id="filter0_d_1539_17129"
                    x="0.2"
                    y="0.6"
                    width="21.6"
                    height="21.6"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2.4" />
                    <feGaussianBlur stdDeviation="2.4" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1539_17129"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1539_17129"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            )}
            <svg
              className="hover"
              width="52"
              height="48"
              viewBox="0 0 52 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_1543_31068)">
                <rect
                  x="14"
                  y="11"
                  width="24"
                  height="24"
                  rx="12"
                  fill={backgroundColor}
                  shape-rendering="crispEdges"
                />
                <rect
                  x="13"
                  y="10"
                  width="26"
                  height="26"
                  rx="13"
                  stroke="white"
                  strokeWidth="2"
                  shape-rendering="crispEdges"
                />
                <path
                  d="M26 36.5V46.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_1543_31068"
                  x="0"
                  y="0"
                  width="52"
                  height="62.5"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="3" />
                  <feGaussianBlur stdDeviation="6" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1543_31068"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1543_31068"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </>
        )}

        <Box
          className="tooltip"
          position={"absolute"}
          top={0}
          left={0}
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "16px",
            transform: "translate(-50%, -150%);",
            fontFamily: "Manrope",
            display: "flex",
          }}
          zIndex={9999}
        >
          <Stack minWidth={"274px"} gap={1}>
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <Box
                  sx={{
                    color: "#464A53",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Untile, Ida
                </Box>
                <Box
                  sx={{
                    color: "#36354680",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  PT50239181
                </Box>
              </Box>
              <WarehouseIcon />
            </Stack>
            <Stack gap={0.5}>
              <Stack flexDirection={"row"} alignItems={"center"}>
                <Box
                  sx={{
                    color: "#464A53",
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                >
                  Previous visit:
                </Box>
                <Box
                  sx={{
                    color: "##464A53",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  25 dec 2023
                </Box>
              </Stack>
              <Stack flexDirection={"row"} alignItems={"center"}>
                <Box
                  sx={{
                    color: "#464A53",
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                >
                  Next suggested visit:
                </Box>
                <Box
                  sx={{
                    color: "##464A53",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  14 mar 2024
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  else return null;
};

const ZoneCollectionPoint = ({
  pickupId,
  color = "#FFFFFF",
  selectedColor = "#00FF00",
  selected = false,
  inZone = false,
  assigned = false,
  number = null,
  onSelect = () => {},
  showNumber,
  timezone = "Europe/Madrid",
  ...props
}) => {
  const getUrgency = (day) => {
    if (day === null && day === undefined) {
      return 0;
    } else {
      if (day <= 0) {
        return 6;
      } else if (day > 0 && day <= 1) {
        return 5;
      } else if (day > 1 && day <= 3) {
        return 4;
      } else if (day > 3 && day < 15) {
        return 3;
      } else if (day >= 15 && day < 30) {
        return 2;
      } else if (day >= 30) {
        return 1;
      }
    }
  };

  const [data, setData] = useState(null);
  const [isOverdue, setIsOverdue] = useState(false);

  useEffect(() => {
    PickupService.getPickupPointRouteDetails(pickupId)
      .then((res) => {
        setData(res.data.data);
        if (
          res.data.data.next_picking_date !== null &&
          moment().tz(timezone).valueOf() >= res.data.data.next_picking_date
        ) {
          setIsOverdue(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pickupId]);

  let backgroundColor = "";
  if (data?.remain_days || data?.remain_days === 0) {
    backgroundColor = DAYS_COLORS[getUrgency(data.remain_days)];
  } else {
    backgroundColor = DAYS_COLORS[0];
  }

  const getWidth = () => {
    if (showNumber) return 24;
    if (selected) return 24;
    if (assigned) {
      if (inZone) return 24;
      return 14;
    } else {
      if (inZone) return 14;
      return 10;
    }
  };

  const width = getWidth();

  return (
    <>
      {props.lat && props.lng ? (
        <Box
          position={"relative"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          onClick={onSelect}
          sx={{
            cursor: "pointer",
            zIndex: selected ? 9999 : 0,
            "& .tooltip": {
              display: "none",
            },
            "&:hover": {
              "& .icon": {
                minWidth: "24px",
                minHeight: "24px",
              },
              zIndex: 9999,
              "& .tooltip": {
                display: "flex",
              },
            },
            transform: "translate(-50%, -100%)",
          }}
          {...props}
        >
          <Box
            className="icon"
            sx={{
              transition: "all 0.2s",
              borderRadius: "50%",
              border: "2px solid",
              borderColor: selected ? "#2CB53B" : "white",
              minWidth: `${width}px`,
              minHeight: `${width}px`,
              backgroundColor: backgroundColor,
              fontFamily: "Manrope",
              fontSize: "12px",
              fontWeight: 700,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
            }}
            position={"relative"}
          >
            {showNumber ? number : null}
            {assigned && (
              <Box
                sx={{
                  bottom: "0px",
                  right: "0px",
                  transform: "translate(50%, 50%)",
                }}
                position={"absolute"}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1"
                    y="1"
                    width="12"
                    height="12"
                    rx="6"
                    fill="#2CB53B"
                  />
                  <rect
                    x="1"
                    y="1"
                    width="12"
                    height="12"
                    rx="6"
                    stroke="white"
                    strokeWidth="2"
                  />
                  <path
                    d="M5 7.33333L6.33333 8.66797L9 5.33203"
                    stroke="#ECFEEE"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              minHeight: "10px",
              minWidth: "2px",
              backgroundColor: selected ? "#2CB53B" : "white",
            }}
          ></Box>

          <Box
            className="tooltip"
            position={"absolute"}
            top={0}
            left={0}
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "16px",
              transform: "translate(-50%, -110%);",
              fontFamily: "Manrope",
              display: "flex",
            }}
            zIndex={9999}
          >
            <Stack minWidth={"274px"} gap={1}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>
                  <Box
                    sx={{
                      color: "#464A53",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    {data?.name}
                  </Box>
                  <Box
                    sx={{
                      color: "#36354680",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {data?.id}
                  </Box>
                </Box>
                <WarehouseIcon />
              </Stack>
              <Stack gap={0.5}>
                <Stack flexDirection={"row"} alignItems={"center"}>
                  <Box
                    sx={{
                      color: "#464A53",
                      fontSize: "12px",
                      fontWeight: 700,
                    }}
                  >
                    Previous visit:
                  </Box>
                  <Box
                    sx={{
                      color: "##464A53",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                  >
                    {data?.last_picking_date
                      ? new Date(data?.last_picking_date).toLocaleDateString(
                          "en-GB"
                        )
                      : "No visit"}
                  </Box>
                </Stack>
                <Stack flexDirection={"row"} alignItems={"center"}>
                  <Box
                    sx={{
                      color: "#464A53",
                      fontSize: "12px",
                      fontWeight: 700,
                    }}
                  >
                    {isOverdue ? "Overdue visit:" : "Next suggested visit:"}
                  </Box>
                  <Box
                    sx={{
                      color: "##464A53",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                  >
                    {data?.next_picking_date
                      ? new Date(data?.next_picking_date).toLocaleDateString(
                          "en-GB"
                        )
                      : "No visit"}
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
export {
  CircledLocation,
  ZoneCollectionPoint,
  VectorItem,
  LocationItem,
  PlacesItem,
};
