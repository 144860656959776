import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { CardPrimary } from "../../../../components/Card";
import { DataTable } from "../../../../components/Table";
import { classes } from "../../../../utils/class";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { useEffect, useState } from "react";
import { EditDetail, StateSpan } from "../../../../components/StateSpan";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DELETE_ROW, EDIT_ROW } from "../../../../utils/type";
import DateDialog from "../../visits/DateDialog";
import AssignVehicleDialog from "../Dialog/AssignVehicleDialog";
import { useDispatch, useSelector } from "react-redux";
import { checkVisit } from "../../../../actions/zone";
import {
  PURPOSE_TYPES,
  getDateStringFromNum,
  getServerTimestamp,
} from "../../../../utils/helpers";
import { strToDate } from "../../../../utils/date";
import {
  deleteScheduleVisit,
  reschedulePickupVisit,
} from "../../../../actions/pickup";
import ScheduleDelete from "../Dialog/ScheduleDelete";
import { useNavigate } from "react-router-dom";
import { setSelectedVisitId } from "../../../../slices/visitsSlice";
import { setCurrentScheduled } from "../../../../slices/visitsSlice";
const VisitPage = ({ scheduledVisitList, visitList, extraInfo, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dateAnchor, setDateAnchor] = useState(null);
  const [vehicleAnchor, setVehicleAnchor] = useState(null);
  const [dateDialog, setDateDialog] = useState(false);
  const [vehicleDialog, setVehicleDialog] = useState(false);
  const [deleteSchedule, setDeleteSchedule] = useState(false);
  const [showScheduledVisits, setShowScheduledVisits] = useState(false);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [current, setCurrent] = useState(null);

  const orgData = useSelector((state) => state.organizations.profile);

  const onClickDate = (date) => {
    dispatch(
      reschedulePickupVisit(id, current._id, {
        date: getServerTimestamp(
          strToDate(`${date.$y}-${date.$M + 1}-${date.$D}`).getTime(),
          orgData.timezone
        ),
      })
    );
  };

  const columns_visit = [
    {
      width: 150,
      flex: 1,
      field: "date",
      headerName: "Date",
      renderCell: (props) => {
        return (
          <Typography sx={{ fontWeight: 700 }} className="mui-ellipsis">
            {getDateStringFromNum(
              new Date(props.value).getTime(),
              orgData.timezone
            )}
          </Typography>
        );
      },
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{PURPOSE_TYPES[props.row.purpose]}</span>
      ),
    },
    {
      width: 150,
      flex: 1,
      field: "zone_names",
      headerName: "Zone",
      renderCell: (props) => {
        // return props.value.map((data, index) => {
        //   return <Box key={index}>{data}</Box>;
        // });
      },
    },
    {
      field: "transfer",
      headerName: "Transfer",
      width: 300,
      flex: 2,
      renderCell: (props) => {
        if (!props.row.quantity) return "";
        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: "60px" }}>
              {props.row.quantity} kg
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "member_name",
      headerName: "Member",
      width: 200,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      width: 100,
      field: "notes",
      headerName: "Notes ",
      renderCell: (props) => {
        return (
          <>
            {props.value !== "" && (
              <Tooltip
                slotProps={{
                  tooltip: {
                    sx: {
                      background: "black",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "black",
                    },
                  },
                }}
                title={props.row.notes}
                placement="top"
              >
                <CommentOutlinedIcon />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];
  const columns_scheduled = [
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (props) => {
        return <StateSpan status={props.row.status} />;
      },
    },
    {
      width: 150,
      field: "date",
      headerName: "Date",
      renderCell: (props) => {
        return (
          <Typography sx={{ fontWeight: 700 }} className="mui-ellipsis">
            {getDateStringFromNum(
              new Date(props.value).getTime(),
              orgData.timezone
            )}
          </Typography>
        );
      },
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">{PURPOSE_TYPES[props.row.purpose]}</span>
      ),
    },
    {
      field: "zone",
      headerName: "Zone",
      width: 150,
      renderCell: (props) => {
        if (props.row?.zones?.length)
          return (
            <span className="mui-ellipsis">
              {props.row.zones[0]?.name || ""}
            </span>
          );
      },
    },
    {
      field: "transfer",
      headerName: "Expected Transfer",
      width: 300,
      renderCell: (props) => {
        if (!props.row.quantity) return "";
        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: "60px" }}>
              {props.row.quantity} kg
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "vehicles",
      headerName: "Vehicle",
      renderCell: (props) => {
        return props.value.map((vehicle, index) => {
          return <Typography key={index}>{vehicle.reg_plate}</Typography>;
        });
      },
    },

    {
      width: 100,
      field: "notes",
      headerName: "Notes ",
      renderCell: (props) => {
        return (
          <>
            {props.value !== "" && (
              <Tooltip
                slotProps={{
                  tooltip: {
                    sx: {
                      background: "black",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "black",
                    },
                  },
                }}
                title={props.row.notes}
                placement="top"
                className="mui-ellipsis"
              >
                <CommentOutlinedIcon />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      width: 150,
      field: "actions",
      headerName: "Actions ",
      renderCell: (props) => {
        return (
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <IconButton
              disabled={props.row.status !== "suggestion"}
              onClick={() => {
                dispatch(checkVisit(id, props.row.id, { status: "planned" }));
              }}
            >
              <CheckOutlinedIcon />
            </IconButton>
            <IconButton
              // disabled={props.row.status !== "overdue"}
              onClick={(e) => {
                setCurrent(props.row);
                setDateDialog(true);
                setDateAnchor(e.currentTarget);
              }}
            >
              <DateRangeIcon />
            </IconButton>
            <EditDetail
              props={props}
              options={[
                // { label: "Edit", value: EDIT_ROW },
                // {
                //   label: "Edit waste collection date",
                //   value: "EDIT_DATE",
                // },
                {
                  label: "Delete",
                  value: DELETE_ROW,
                },
              ]}
              onStateChangeHandler={(status) => {
                if (status === DELETE_ROW) {
                  setDeleteSchedule(true);
                  setCurrent(props.row);
                }
                if (status === EDIT_ROW) {
                  // dispatch(setSelectedVisitId(props.row.id));
                  // navigate(`/visits-detail`);
                }
              }}
            />
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (showScheduledVisits) {
      setCols(columns_scheduled);
    } else {
      setCols(columns_visit);
    }
  }, [showScheduledVisits]);
  useEffect(() => {
    if (showScheduledVisits) {
      setRows(
        scheduledVisitList.map((visit, index) => {
          return {
            ...visit,
            id: visit._id,
          };
        })
      );
    } else {
      setRows(
        visitList.map((visit, index) => {
          return {
            ...visit,
            id: visit._id,
          };
        })
      );
    }
  }, [showScheduledVisits, visitList, scheduledVisitList]);

  return (
    <Stack gap={2}>
      <Stack direction={"row"} gap={1}>
        <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          <CardPrimary
            type={1}
            title={"Balance"}
            content={extraInfo?.balance || 0}
          />
          <CardPrimary
            type={2}
            title={"Next collection"}
            content={`in ${extraInfo?.next_picking || 0} days`}
          />
          <CardPrimary
            type={3}
            title={"Expiring documents"}
            content={extraInfo?.count_expiring_documents || 0}
          />
        </Box>
      </Stack>
      <Stack direction="row">
        <Button
          sx={[
            showScheduledVisits ? classes.buttonCancel : classes.buttonSave,
            {
              borderRadius: 0,
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
            },
          ]}
          onClick={() => setShowScheduledVisits(false)}
        >
          Visits
        </Button>
        <Button
          sx={[
            showScheduledVisits ? classes.buttonSave : classes.buttonCancel,
            {
              borderRadius: 0,
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
            },
          ]}
          onClick={() => setShowScheduledVisits(true)}
        >
          Scheduled visits
        </Button>
      </Stack>
      <Stack>
        <DataTable
          fit="100%"
          breakpoint="1600px"
          rows={rows}
          columns={cols}
          onRowClick={(e) => {
            if (!showScheduledVisits) {
              dispatch(setSelectedVisitId(e.row.id));
              navigate("/visits-detail");
            } else {
              dispatch(setCurrentScheduled(e.row));
              navigate("/edit-scheduled-visit");
            }
          }}
          // initialState={{
          //   pinnedColumns: { right: ["actions"] },
          // }}
        />
      </Stack>
      <DateDialog
        defaultValue={current?.date}
        open={dateDialog}
        handleClose={() => {
          setDateDialog(false);
        }}
        anchorEl={dateAnchor}
        onClickDate={onClickDate}
      />
      <AssignVehicleDialog
        open={vehicleDialog}
        handleClose={() => {
          setVehicleDialog(false);
        }}
        anchorEl={vehicleAnchor}
      />
      <ScheduleDelete
        open={deleteSchedule}
        handleContinue={() => {
          dispatch(deleteScheduleVisit(id, current._id));
        }}
        handleClose={() => {
          setDeleteSchedule(false);
        }}
      />
    </Stack>
  );
};

export default VisitPage;
