import React, { useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { COfficialIdInput, CTextInput } from "../../../components/CustomInput";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createWarehouse } from "../../../actions/warehouse";
import { PageTitle } from "../../../components/Typography";
import { classes } from "../../../utils/class";
import { useNavigate } from "react-router-dom";
import { fetchUserProfile } from "../../../actions/user";
import { fetchOrganizationInfo } from "../../../actions/organizations";

const AddWarehousePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [hasId, setHasId] = useState(false);
  const [country, setCountry] = useState("");

  const profile = useSelector((state) => state.organizations.profile);

  const handleSave = () => {
    if (!hasId) dispatch(createWarehouse({ name: name }, navigate));
    else
      dispatch(
        createWarehouse(
          { name: name, official_id: number, official_id_country: country },
          navigate
        )
      );
  };

  useEffect(() => {
    setCountry(profile.country || "GB");
  }, [profile]);
  useEffect(() => {
    dispatch(fetchOrganizationInfo());
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <PageTitle>Add Warehouse</PageTitle>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            sx={classes.buttonPrimary}
            onClick={() => navigate("/warehouse")}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={classes.buttonPrimary}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "500px",
        }}
      >
        <Box sx={{ alignItems: "center", width: "400px", marginTop: "200px" }}>
          <CTextInput
            title="Warehouse name"
            required
            placeholder="Warehouse name"
            value={name}
            onChange={(v) => setName(v)}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked
                  checked={hasId}
                  onChange={() => setHasId(!hasId)}
                />
              }
              label="This warehouse has Official ID"
            />
          </FormGroup>
          {hasId && (
            <COfficialIdInput
              label="Official ID"
              countryCode={country}
              placeholder="Number"
              value={number}
              onCountryChange={(e, data) => setCountry(data.code)}
              onChange={(e) => setNumber(e.target.value)}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
export default AddWarehousePage;
