import React, { useState } from "react";

// material-ui
import { Box } from "@mui/material";
import { useEffect } from "react";

const Tabs = ({ children, onTabChangeHandler, tab = 0 }) => {
  const [selectedTab, setSelectedTab] = useState(tab);
  const childrens = React.Children.toArray(children);
  const onTabChange = (tabId) => {
    setSelectedTab(tabId);
    onTabChangeHandler(tabId);
  };
  useEffect(() => {
    setSelectedTab(tab);
  }, [tab]);
  return (
    <>
      <Box
        sx={{
          paddingRight: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <TabHeader onTabChangeHanlder={onTabChange} selectedTab={selectedTab}>
            {childrens[0].props.children}
          </TabHeader>
          {childrens[1].props.children}
        </Box>
        <TabBody selectedTab={selectedTab}>
          {childrens[2].props.children}
        </TabBody>
      </Box>
    </>
  );
};

const TabHeader = ({ children, onTabChangeHanlder, selectedTab }) => {
  const onTabChange = (tabId) => {
    onTabChangeHanlder(tabId);
  };
  return (
    <>
      <Box
        overflow={"auto"}
        sx={{
          overflowY: "hidden",
          width: "100%",
          transform: "rotateX(180deg);",
        }}
      >
        <Box
          sx={{
            display: "flex",
            transform: "rotateX(180deg);",
          }}
        >
          {React.Children.map(children, (item, index) => {
            return (
              <TabHeaderItem
                selected={index === selectedTab}
                tabId={index}
                key={index}
                onTabChangeHanlder={onTabChange}
              >
                {item.props.children}
              </TabHeaderItem>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

const TabHeaderItem = ({ tabId, children, selected, onTabChangeHanlder }) => {
  return (
    <>
      <Box
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          color: selected ? "#23222D" : "#6D6D6D",
          padding: "8px 40px 0px 0px",
          textAlign: "center",
          fontFamily: "Manrope",
          "&:hover": {
            cursor: "pointer",
            color: "#6D6D6D",
            "& > .tab-border": {
              backgroundColor: "#6D6D6D",
            },
          },
          "&:active": {
            cursor: "pointer",
            color: "background.primary",
          },
        }}
        onClick={() => {
          onTabChangeHanlder(tabId);
        }}
      >
        <Box
          sx={{
            paddingBottom: "10px",
            whiteSpace: "nowrap",
          }}
        >
          {children}
        </Box>
        {selected && (
          <Box
            className="tab-border"
            sx={{
              backgroundColor: "#23222D",
              height: "2px",
              width: "100%",
              transform: "translate(0,50%)",
            }}
          ></Box>
        )}
      </Box>
    </>
  );
};

const TabBody = ({ children, selectedTab }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {React.Children.map(children, (item, index) => {
          return (
            <Tab key={index} hidden={selectedTab !== index}>
              {item.props.children}
            </Tab>
          );
        })}
      </Box>
    </>
  );
};
const Tab = ({ children, hidden = false }) => {
  return (
    <>
      <Box
        sx={{
          paddingTop: "20px",
          position: "relative",
          width: "100%",
          display: hidden ? "none" : "block",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export { Tab, Tabs, TabHeader, TabHeaderItem, TabBody };
