import { Button, Stack } from "@mui/material";
import { classes } from "../../utils/class";

const EmptyWizard = ({ Icon, title, content, buttonText, onAdd }) => {
  return (
    <Stack alignItems={"center"} justifyContent={"center"} gap={1}>
      <Stack sx={classes.wizardEmpty}>
        {Icon}
        <Stack sx={classes.wizardEmptyTitle}>{title}</Stack>
        <Stack>{content}</Stack>
        <Stack mt={"32px"} alignItems={"flex-end"}>
          <Button sx={classes.buttonSave} onClick={onAdd}>
            {buttonText}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

const NormalWizard = ({
  Icon,
  title,
  content,
  buttonText,
  onAdd,
  active = false,
}) => {
  return (
    <Stack alignItems={"center"} justifyContent={"center"} gap={2}>
      <Stack sx={active ? classes.normalWizardActive : classes.normalWizard}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {Icon}
          {active ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <rect width="20" height="20" rx="10" fill="green" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2222 7.84843L13.3528 6.00018L9 10.3038L6.86942 8.22241L5 10.0707L9 14.0002L15.2222 7.84843Z"
                fill="white"
              />
            </svg>
          ) : (
            <Button sx={classes.buttonCancel} onClick={onAdd}>
              {buttonText}
            </Button>
          )}
        </Stack>
        <Stack sx={classes.normalWizardTitle}>{title}</Stack>
        <Stack sx={classes.normalWizardContent}>{content}</Stack>
      </Stack>
    </Stack>
  );
};

export { EmptyWizard, NormalWizard };
