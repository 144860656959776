import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { Box, LinearProgress, Stack, Tooltip, Typography } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

// project imports
import { CardPrimary } from "../../../../components/Card";
import { EditDetail, StateSpan } from "../../../../components/StateSpan";
import { DataTable } from "../../../../components/Table";

// sx
import { classes } from "../../../../utils/class";
import { Percentage } from "../../../../components/Typography";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { useNavigate } from "react-router-dom";

// action import
const Transfers = ({ props, extraInfo, id }) => {
  const navigate = useNavigate();
  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "origin_name",
      headerName: "Origin",
      flex: 1,
      renderCell: (props) => {
        return (
          <>
            <Typography sx={{ fontWeight: 400 }} className="mui-ellipsis">
              {props.value}
            </Typography>
          </>
        );
      },
    },
    {
      field: "vehicle_name",
      headerName: "Vehicle",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "destination_warehouse_name",
      headerName: "Destination",
      flex: 1,
      renderCell: (props) => {
        return (
          <>
            <Typography sx={{ fontWeight: 400 }} className="mui-ellipsis">
              {props.value}
            </Typography>
          </>
        );
      },
    },
    {
      headerName: "Transfer",
      field: "transfer",
      minWidth: 350,
      flex: 3,
      renderCell: (props) => {
        return (
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Typography>{props.row.quantity} kg</Typography>
            <Percentage value={props.row.ratio} />
            <Typography>{props.row.raw_waste_name}</Typography>
            <Tooltip
              slotProps={{
                tooltip: {
                  sx: {
                    background: "black",
                  },
                },
                arrow: {
                  sx: {
                    color: "black",
                  },
                },
              }}
              title={props.row.notes}
              placement="top"
            >
              <CommentOutlinedIcon
                sx={{
                  color: "text.light",
                }}
              />
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      headerName: "Driver",
      field: "vehicle_driver_name",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
  ];

  return (
    <>
      <Stack gap={2}>
        <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
          <CardPrimary
            type={1}
            title={"Balance"}
            content={extraInfo?.balance || 0}
          />
          <CardPrimary
            type={2}
            title={"Nº Collection Points"}
            content={extraInfo?.count_of_pickup_points || 0}
          />
          <CardPrimary
            type={3}
            title={"Next collection"}
            content={`In ${extraInfo?.next_picking || 0} days`}
          />
        </Stack>
        <Stack>
          <DataTable
            fit="fit-screen"
            autoSize
            rows={props.map((prop, index) => {
              return {
                ...prop,
                id: prop._id,
                date: new Date(prop.date).toDateString(),
              };
            })}
            columns={columns}
            sx={classes.grid}
            onRowClick={(params, events, details) => {
              navigate(`/transfer-detail/${params.id}/view`);
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default Transfers;
