import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import GoogleMapReact from "google-map-react";

import { DEFAULT_ZOOM, DETAIL_ZOOM } from "../utils/type";
import {
  core,
  getFilteredPlaces,
  updateLatitudeAndLongitude,
} from "../utils/map";
import { SmallTitle } from "./Typography";
import {
  Android12Switch,
  CAutoComplete,
  CTextInput,
  SearchBar,
} from "./CustomInput";
import {
  LocationItem,
  PlacesItem,
} from "../views/pages/routes/component/Location";

const CustomMap = ({
  data,
  onChange,
  description,
  direction = "row",
  mapHeight = undefined,
  extraComponent = null,
  secondComponent = null,
  pickupFlag = false,
}) => {
  const { profile } = useSelector((state) => state.user);
  const [zoom, setZoom] = useState(DETAIL_ZOOM);
  const [isEditable, setIsEditable] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const [addr_street, setStreet] = useState(null);
  const [addr_city, setCity] = useState(null);
  const [addr_country, setCountry] = useState(null);
  const [addr_postcode, setPostcode] = useState(null);
  const [addr_apartment, setApartment] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [place, setPlace] = useState("");
  const [flag, setFlag] = useState(false);

  const [places, setPlaces] = useState([]);
  const [search, setSearch] = useState("");

  const onClickMap = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
    core(lat, lng).then((res) => {
      onChange({
        latitude: lat,
        longitude: lng,
        addr_country: res.country,
        addr_city: res.city,
        addr_apartment: res.apartment,
        addr_postcode: res.postcode,
        addr_street: res.street,
      });
      setCountry(res.country);
      setApartment(res.apartment);
      setCity(res.city);
      setPostcode(res.postcode);
      setStreet(res.street);
    });
  };

  const onInputChange = (street, apartment, city, postcode, country) => {
    updateLatitudeAndLongitude(street, apartment, city, postcode, country).then(
      (res) => {
        if (res.status === true) {
          setZoom(DETAIL_ZOOM);
          onChange({
            latitude: res.latitude,
            longitude: res.longitude,
            addr_street: street,
            addr_apartment: apartment,
            addr_city: city,
            addr_postcode: postcode,
            addr_country: country,
          });
        } else {
          setZoom(DEFAULT_ZOOM);
        }
      }
    );
  };

  const onSearch = (value) => {
    updateLatitudeAndLongitude("", "", search, "", "").then((res) => {
      if (res.status === true) {
        setZoom(DEFAULT_ZOOM);
        onChange({
          latitude: res.latitude,
          longitude: res.longitude,
          addr_city: search,
        });
      } else {
        setZoom(DEFAULT_ZOOM);
      }
    });

    getFilteredPlaces(value, latitude, longitude).then((data) => {
      setPlaces(data);
    });
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    if (pickupFlag === true) {
      updateLatitudeAndLongitude("", "", "", "", "").then((res) => {
        if (res.status === true) {
          setZoom(DEFAULT_ZOOM);
          onChange({
            latitude: res.latitude,
            longitude: res.longitude,
            addr_city: "",
          });
        } else {
          setZoom(DEFAULT_ZOOM);
        }
      });

      getFilteredPlaces("", latitude, longitude).then((data) => {
        setPlaces(data);
      });
    }
  }, [flag, onChange, pickupFlag, latitude, longitude]);

  useEffect(() => {
    if (mapLoaded) {
      if (
        data &&
        data.latitude &&
        data.longitude &&
        data.latitude !== undefined &&
        data.longitude !== undefined
      ) {
        setLatitude(data?.latitude || profile.latitude);
        setLongitude(data?.longitude || profile.longitude);
        if (isEditable) {
        } else {
          setStreet(data?.addr_street);
          setCity(data?.addr_city);
          setApartment(data?.addr_apartment);
          setPostcode(data?.addr_postcode);
          setCountry(data?.addr_country);
        }
      } else {
        onClickMap(profile.latitude, profile.longitude);
      }
    }
  }, [data, isEditable, mapLoaded]);

  useEffect(() => {
    if (pickupFlag === true) {
      if (place) {
        onClickMap(
          place?.geometry?.location?.lat,
          place?.geometry?.location?.lng
        );
      }
    }
  }, [place, pickupFlag]);

  return (
    <Stack direction={direction} gap={4} justifyContent={"flex-start"}>
      <Stack>
        {extraComponent}
        <Stack
          gap={1}
          flexDirection={"row"}
          sx={{ paddingTop: "24px", paddingBottom: "12px" }}
        >
          <Stack flex={1}>
            <CTextInput
              title="Latitude"
              placeholder="Ex: 41.345"
              value={latitude}
              disabled
              sx={{ gap: 0 }}
            />
          </Stack>
          <Stack flex={1}>
            <CTextInput
              title="Longitude"
              placeholder="Ex: 41.345"
              value={longitude}
              disabled
              sx={{ gap: 0, paddingBottom: "12px" }}
            />
          </Stack>
        </Stack>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <SmallTitle>Address</SmallTitle>
            {secondComponent}
          </Stack>
          <CTextInput
            disabled={!isEditable}
            title=""
            placeholder="Street"
            value={addr_street}
            onChange={(val) => {
              setStreet(val);
              onInputChange(
                val,
                addr_apartment,
                addr_city,
                addr_postcode,
                addr_country
              );
            }}
            sx={{ paddingTop: "12px", gap: "12px" }}
          />
        </Stack>
        <Stack direction={"row"} gap={1}>
          <Stack flex={3}>
            <CTextInput
              disabled={!isEditable}
              title=""
              placeholder="Apartment, etc"
              value={addr_apartment}
              onChange={(val) => {
                setApartment(val);
                onInputChange(
                  addr_street,
                  val,
                  addr_city,
                  addr_postcode,
                  addr_country
                );
              }}
              sx={{ gap: "12px" }}
            />
          </Stack>
          <Stack flex={2}>
            <CTextInput
              disabled={!isEditable}
              title=""
              placeholder="Postcode"
              value={addr_postcode}
              onChange={(val) => {
                setPostcode(val);
                onInputChange(
                  addr_street,
                  addr_apartment,
                  addr_city,
                  val,
                  addr_country
                );
              }}
              sx={{ gap: "12px" }}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} gap={1}>
          <Stack flex={2}>
            <CTextInput
              disabled={!isEditable}
              title=""
              placeholder="City"
              value={addr_city}
              onChange={(val) => {
                setCity(val);
                onInputChange(
                  addr_street,
                  addr_apartment,
                  val,
                  addr_postcode,
                  addr_country
                );
              }}
              sx={{ gap: "12px" }}
            />
          </Stack>
          <Stack flex={3}>
            <CTextInput
              title=""
              disabled={!isEditable}
              placeholder="Country"
              value={addr_country}
              onChange={(val) => {
                setCountry(val);
                onInputChange(
                  addr_street,
                  addr_apartment,
                  addr_city,
                  addr_postcode,
                  val
                );
              }}
              sx={{ gap: "12px" }}
            />
          </Stack>
        </Stack>
        <Stack
          gap={2}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          marginTop={"12px"}
        >
          <Stack
            style={{
              fontWeight: "500",
              fontFamily: "Manrope",
              fontSize: "16px",
            }}
          >
            Manually input address
          </Stack>
          <Android12Switch
            title="Manually input address"
            checked={isEditable}
            onChange={(e) => {
              setIsEditable(e.target.checked);
            }}
          />
        </Stack>
      </Stack>
      <Stack width={"100%"} height={mapHeight} position={"relative"}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API }}
          center={{
            lat: latitude,
            lng: longitude,
          }}
          zoom={zoom}
          onGoogleApiLoaded={() => {
            setMapLoaded(true);
          }}
          onClick={(e) => {
            onClickMap(e.lat, e.lng);
          }}
          yesIWantToUseGoogleMapApiInternals={true}
        >
          {places.map((place, index) => {
            return (
              <PlacesItem
                key={index}
                data={place}
                {...place.geometry.location}
                onClick={() => {
                  onClickMap(
                    place.geometry.location.lat,
                    place.geometry.location.lng
                  );
                }}
              />
            );
          })}
          <LocationItem
            color="#BF1A2F"
            type={1}
            lat={latitude}
            lng={longitude}
          />
        </GoogleMapReact>
        <Stack
          position={"absolute"}
          top={10}
          left={10}
          width={pickupFlag ? 400 : "auto"}
        >
          {pickupFlag ? (
            <CAutoComplete
              fixed
              value={place}
              onChange={(value) => setPlace(value)}
              onInput={() => {
                setFlag(true);
              }}
              options={places.map((item) => {
                return {
                  ...item,
                  name: item.formatted_address,
                  value: item.formatted_address,
                };
              })}
              placeholder="Select ..."
            />
          ) : (
            <SearchBar
              onEnter={onSearch}
              onSearchChange={onSearchChange}
              placeholder="Select ..."
            />
          )}
        </Stack>
        <Typography sx={{ fontSize: "10px" }}>
          Select a point on the map to add the {description} location
        </Typography>
      </Stack>
    </Stack>
  );
};
export { CustomMap };
