import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import dayjs from "dayjs";

import {
  InputTitle,
  PageTitle,
  SubTitle,
} from "../../../components/Typography";
import {
  CAutoComplete,
  CDateInput,
  COfficialIdInput,
  CRadioSelect,
  CTextInput,
} from "../../../components/CustomInput";
import { showNotification, getServerTimestamp } from "../../../utils/helpers";
import { SUCCESS } from "../../../utils/type";
import { classes } from "../../../utils/class";
import { ERROR } from "../../../utils/constants";
import WasteService from "../../../services/WasteService";
import PickupService from "../../../services/PickupService";
import { setPickupDetail } from "../../../slices/pickupSlice";
import {
  fetchPickupPoints,
  fetchPickupRawWastes,
} from "../../../actions/pickup";
import {
  fetchAllUsers,
  fetchOrganizationInfo,
} from "../../../actions/organizations";
import { fetchAllDrivers, fetchAllVehicles } from "../../../actions/fleet";
import { createVisit } from "../../../actions/visit";
import { fetchWarehouseList } from "../../../actions/warehouse";
import { fetchOperationCodes } from "../../../actions/waste";

const AddVisitPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(1);
  const [reason, setReason] = useState("");
  const [purpose, setPurpose] = useState("Waste pickup");
  const [errorDlgOpen, setErrorDlgOpen] = useState(false);
  const [target, setTarget] = useState(null);
  const [member, setMember] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [date, setDate] = useState(dayjs(new Date()));
  const [origin, setOrigin] = useState(null);
  const [transportVat, setTransportVAT] = useState("");
  const [destinationVat, setDestinationVAT] = useState("");
  const [thisNotes, setThisNotes] = useState("");
  const [nextNotes, setNextNotes] = useState("");
  const [officialID, setOfficialID] = useState(null);
  const [rawWaste, setRawWaste] = useState("");
  const [driver, setDriver] = useState("");
  const [opCode, setOpCode] = useState("");
  const [vatNumCountry, setVatNumCountry] = useState(null);
  const [destNumCountry, setDestNumCountry] = useState(null);
  const pickup_points = useSelector((state) => state.pickup.pickupList);
  const raw_wastes = useSelector((state) => state.pickup.rawWastes);
  const vehicles = useSelector((state) => state.fleet.vehicles);
  const users = useSelector((state) => state.organizations.users);
  const warehouses = useSelector((state) => state.warehouse.warehouses);
  const operation_codes = useSelector((state) => state.waste.operation_codes);
  const drivers = useSelector((state) => state.fleet.drivers);
  const current_scheduled = useSelector(
    (state) => state.visits.current_scheduled
  );
  const profile = useSelector((state) => state.organizations.profile);
  const orgData = useSelector((state) => state.organizations.profile);
  const currentPickup = useSelector((state) => state.pickup.currentPickup);
  const [errors, setErrors] = useState([]);
  const [daysOff, setDaysOff] = useState([]);

  useEffect(() => {
    dispatch(fetchPickupPoints());
    dispatch(fetchAllVehicles());
    dispatch(fetchAllUsers());
    dispatch(fetchWarehouseList());
    dispatch(fetchAllDrivers());
    dispatch(fetchOperationCodes());
    dispatch(fetchOrganizationInfo());
  }, [dispatch]);

  useEffect(() => {
    if (current_scheduled) return;
    setDate(null);
    setTarget(null);
    setOrigin(null);
    setMember(null);
    setVehicle(null);
  }, [purpose, current_scheduled]);

  useEffect(() => {
    if (currentPickup) {
      setOrigin(
        pickup_points.find((pickup) => {
          return pickup._id === currentPickup;
        })
      );
    }
  }, [currentPickup, pickup_points]);

  useEffect(() => {
    if (!origin || origin.length === 0) {
      setPickupDetail({});
      return;
    }
    const data = pickup_points.find(
      (pp) => pp.name === (origin.name || origin)
    );
    if (data) {
      PickupService.fetchPickupPointDetail(data._id).then((res) => {
        if (!current_scheduled?.raw_waste_name) {
          setRawWaste(res.data.data.raw_waste_names[0] || "");
        }
        const opCode = operation_codes.find(
          (code) => code._id === res.data.data.operation_code
        );
        if (opCode) setOpCode(opCode.code);
        setDaysOff(res.data.data?.days_off || []);
      });
      dispatch(fetchPickupRawWastes(data._id));
    }
  }, [
    current_scheduled?.raw_waste_name,
    dispatch,
    operation_codes,
    origin,
    pickup_points,
  ]);

  useEffect(() => {
    if (profile?.country) {
      if (!current_scheduled?.vehicles[0]?.vat_number_country) {
        setVatNumCountry(profile.country);
      }

      if (!current_scheduled?.destination_warehouse_vat_num_country) {
        setDestNumCountry(profile.country);
      }
    }
  }, [profile, current_scheduled]);

  useEffect(() => {
    if (current_scheduled) {
      setDate(dayjs(new Date(current_scheduled.date)));
      setThisNotes(current_scheduled.notes);
      setPurpose(
        current_scheduled.purpose === "waste_pickup"
          ? "Waste pickup"
          : "Commercial visit"
      );
      setQuantity(current_scheduled.quantity);
      // setVehicle(current_scheduled?.vehicles[0]?.reg_plate || "");
      setVehicle(current_scheduled?.vehicles[0] || null);
      setVatNumCountry(
        current_scheduled?.vehicles[0]?.vat_number_country || ""
      );
      setTransportVAT(current_scheduled?.vehicles[0]?.vat_number || "");
      setOrigin(current_scheduled.pickup_point_name);
      setTarget(current_scheduled.pickup_point_name);
      setRawWaste(current_scheduled.raw_waste_name);
      setDriver(current_scheduled.driver_name);
      setMember(current_scheduled.driver_name);
      setOfficialID(current_scheduled.destination_warehouse_id);
      setDestNumCountry(
        current_scheduled.destination_warehouse_vat_num_country
      );
      setDestinationVAT(current_scheduled.destination_warehouse_vat_num);
    }
  }, [current_scheduled]);

  useEffect(() => {
    if (officialID) {
      setDestNumCountry(officialID?.data?.vat_number_country);
      setDestinationVAT(officialID?.data?.vat_number);
    }
  }, [officialID]);

  useEffect(() => {
    if (!rawWaste || !quantity || !officialID) return;

    if (warehouses.length > 0) {
      const wr = warehouses.find((w) => w._id === officialID?.data?._id)?._id;
      const rw = raw_wastes.find((w) => w.name === rawWaste)?._id;
      if (wr && rw) {
        WasteService.checkWarehouseAvailable(
          warehouses.find((w) => w._id === officialID?.data?._id)?._id,
          raw_wastes.find((w) => w.name === rawWaste)?._id,
          { quantity }
        )
          .then((res) => {
            if (res.data.message === SUCCESS) {
            }
          })
          .catch((err) => {
            showNotification(
              err.response ? ERROR[err.response.data.message] : err.message,
              "error"
            );
          });
      }
    }
  }, [rawWaste, raw_wastes, quantity, officialID, warehouses]);

  useEffect(() => {
    if (vehicle) {
      setVatNumCountry(vehicle?.data?.vat_number_country);
      setTransportVAT(vehicle?.data?.vat_number);
    }
  }, [vehicle]);

  useEffect(() => {
    if (!rawWaste || !vehicle || current_scheduled) return;
    WasteService.checkVehicleAvailable(
      vehicles.find((v) => v.reg_plate === vehicle.name)?._id,
      raw_wastes.find((w) => w.name === rawWaste)?._id,
      {
        quantity: quantity,
      }
    )
      .then((res) => {
        if (res.data.message === SUCCESS) {
        }
      })
      .catch((err) => {
        showNotification(
          err.response ? ERROR[err.response.data.message] : err.message,
          "error"
        );
      });
  }, [rawWaste, vehicle, raw_wastes, vehicles, quantity, current_scheduled]);

  const save = () => {
    try {
      if (purpose === "Waste pickup") {
        const error = [];
        if (!date) error.push("date");
        if (!origin) error.push("origin");
        if (!rawWaste) error.push("rawWaste");
        if (!opCode) error.push("opCode");
        if (!quantity) error.push("quantity");
        if (!vehicle) error.push("vehicle");
        if (!driver) error.push("driver");
        if (!officialID) error.push("officialId");
        setErrors(error);
        if (error.length === 0) {
          dispatch(
            createVisit(
              Object.assign(
                {
                  purpose: "waste_pickup",
                  date: getServerTimestamp(
                    new Date(date).getTime(),
                    orgData.timezone
                  ),
                  origin: pickup_points.find(
                    (pp) => pp.name === (origin.name || origin)
                  )._id,
                  quantity: parseInt(quantity),
                  operation_code: operation_codes.find(
                    (op) => op.code === opCode
                  )._id,
                  notes: thisNotes,
                  notes_for_next: nextNotes,
                  raw_waste: raw_wastes.find((w) => w.name === rawWaste)._id,
                },
                current_scheduled && {
                  visit_scheduled_id: current_scheduled._id,
                },
                quantity === 0 && { reason_description: reason },
                quantity !== 0 &&
                  vehicle && {
                    vehicle: vehicles.find(
                      (v) => v.reg_plate === vehicle || vehicle?.value
                    )._id,
                  },
                quantity !== 0 &&
                  transportVat && { vehicle_vat_num: transportVat },
                quantity !== 0 &&
                  vatNumCountry && { vehicle_vat_num_country: vatNumCountry },
                quantity !== 0 &&
                  driver && {
                    vehicle_driver: drivers.find((d) => d.name === driver)._id,
                  },
                quantity !== 0 &&
                  officialID && {
                    destination_warehouse: warehouses.find(
                      (w) => w.id === officialID?.data?.id
                    )._id,
                  },
                quantity !== 0 &&
                  destNumCountry && {
                    destination_warehouse_vat_num_country: destNumCountry,
                  },
                quantity !== 0 && {
                  destination_warehouse_vat_num: destinationVat,
                }
              ),
              () => navigate(-1)
            )
          );
        } else {
          showNotification("Fill the required fields!", "error");
        }
      } else {
        const error = [];
        if (!date) error.push("date");
        if (!target) error.push("target");
        if (!member) error.push("member");
        if (!vehicle) error.push("vehicle");
        setErrors(error);
        if (error.length === 0)
          dispatch(
            createVisit(
              Object.assign(
                {
                  purpose: "commercial_visit",
                  date: getServerTimestamp(
                    new Date(date).getTime(),
                    orgData.timezone
                  ),
                  target: pickup_points.find((pp) => pp.name === target)?._id,
                  member: users.find((user) => user.name === member?.name)?._id,
                  vehicle: vehicles.find((v) => v.reg_plate === vehicle?.value)
                    ?._id,
                },
                current_scheduled && {
                  visit_scheduled_id: current_scheduled._id,
                }
              ),
              () => navigate(-1)
            )
          );
      }
    } catch (err) {
      showNotification(
        "Something went wrong! Please check information",
        "error"
      );
      console.log(err);
    }
  };
  const cancel = () => {
    navigate(-1);
  };

  return (
    <>
      <Stack gap={10}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <PageTitle>Add log</PageTitle>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={cancel}
            >
              Cancel
            </Button>
            <Button variant="outlined" sx={classes.buttonSave} onClick={save}>
              Save
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "36px",
              minWidth: "422px",
              justifyContent: "center",
              marginBottom: "50px",
            }}
          >
            <SubTitle>Identification</SubTitle>
            <CRadioSelect
              title="Purpose"
              required
              direction="column"
              radioRawDirection="true"
              value={purpose}
              onChange={(v) => {
                setErrors([]);
                setPurpose(v);
              }}
              options={["Waste pickup", "Commercial visit"]}
            />

            {purpose === "Waste pickup" ? (
              <>
                <CAutoComplete
                  error={errors.indexOf("origin") !== -1}
                  title="Origin"
                  required
                  placeholder="Select Origin"
                  value={origin}
                  onChange={(v) => {
                    setOrigin(v);
                    setRawWaste("");
                    setDate(null);
                  }}
                  options={pickup_points.map((pp) => {
                    return {
                      value: pp.name,
                      name: pp.name,
                      component: (
                        <Stack>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {pp.name}
                          </Typography>
                          <Typography sx={{ color: "grey", fontSize: "12px" }}>
                            Producer: {pp.producer}
                          </Typography>
                        </Stack>
                      ),
                    };
                  })}
                  hasComponent
                  fixed
                />
                <CDateInput
                  title="Select date"
                  value={date}
                  onChange={(v) => setDate(v)}
                  required
                  daysOff={daysOff}
                  isVisit
                  error={errors.indexOf("date") !== -1}
                />
                <SubTitle>Collection details</SubTitle>
                <CAutoComplete
                  title="Raw waste"
                  required
                  placeholder="Select Raw Waste"
                  options={raw_wastes.map((waste) => {
                    return {
                      value: waste.rawWasteId,
                      name: waste.name,
                      component: (
                        <Stack>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {waste.code}
                          </Typography>
                          <Typography sx={{ color: "grey", fontSize: "12px" }}>
                            {waste.name}
                          </Typography>
                        </Stack>
                      ),
                    };
                  })}
                  value={rawWaste}
                  onChange={(v) => setRawWaste(v.name)}
                  fixed
                  error={errors.indexOf("rawWaste") !== -1}
                />

                <CAutoComplete
                  title="Operation Code"
                  required
                  options={operation_codes.map((op) => op.code)}
                  placeholder="Select Operation code"
                  value={opCode}
                  onChange={(v) => setOpCode(v)}
                  fixed
                  tooltip={true}
                  error={errors.indexOf("opCode") !== -1}
                />
                <CTextInput
                  title="Quantity"
                  required
                  type="number"
                  value={quantity}
                  error={errors.indexOf("quantity") !== -1}
                  onChange={(v) => setQuantity(v)}
                  placeholder="Insert quantity"
                  endIcon={"kg"}
                />

                {quantity !== 0 ? (
                  <>
                    <SubTitle>Transport</SubTitle>
                    <CAutoComplete
                      title="Vehicle license plate"
                      value={vehicle}
                      onChange={(v) => {
                        setVehicle(v);
                      }}
                      error={errors.indexOf("vehicle") !== -1}
                      placeholder="Insert license plate"
                      options={vehicles.map((vehicle) => {
                        return {
                          value: vehicle.reg_plate,
                          name: vehicle.reg_plate,
                          data: vehicle,
                          component: (
                            <Stack>
                              <Typography
                                sx={{ fontSize: "16px", fontWeight: "bold" }}
                              >
                                {vehicle.name}
                              </Typography>
                              <Typography
                                sx={{ color: "grey", fontSize: "14px" }}
                              >
                                Plate: {vehicle.reg_plate}
                              </Typography>
                            </Stack>
                          ),
                        };
                      })}
                      hasComponent
                      fixed
                    />
                    <Stack>
                      <COfficialIdInput
                        label="VAT number"
                        countryCode={vatNumCountry}
                        onCountryChange={(e, v) => setVatNumCountry(v.code)}
                        content="VAT"
                        value={transportVat}
                        onChange={(e) => setTransportVAT(e.target.value)}
                        placeholder="Vat Number"
                        disabled
                      />
                    </Stack>
                    <CAutoComplete
                      title="Driver"
                      placeholder="Insert driver name"
                      options={drivers.map((d) => d.name)}
                      value={driver}
                      onChange={(v) => setDriver(v)}
                      error={errors.indexOf("driver") !== -1}
                      fixed
                    />
                    <SubTitle>Destination</SubTitle>
                    <Stack>
                      <CAutoComplete
                        title="Destination Warehouse"
                        placeholder="Warehouse"
                        value={officialID}
                        onChange={(v) => setOfficialID(v)}
                        error={errors.indexOf("officialId") !== -1}
                        options={warehouses.map((wr) => {
                          return {
                            value: wr.id,
                            name: wr.id,
                            data: wr,
                            component: (
                              <Stack>
                                <Typography
                                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                                >
                                  {wr.id}
                                </Typography>
                                <Typography
                                  sx={{ color: "grey", fontSize: "12px" }}
                                >
                                  Name: {wr.name}
                                </Typography>
                              </Stack>
                            ),
                          };
                        })}
                        hasComponent
                        fixed
                      />
                    </Stack>
                    <Stack>
                      <InputTitle>Waste destination VAT</InputTitle>
                      <COfficialIdInput
                        label=""
                        countryCode={destNumCountry}
                        onCountryChange={(e, v) => setDestNumCountry(v.code)}
                        content="VAT"
                        value={destinationVat}
                        onChange={(e) => setDestinationVAT(e.target.value)}
                        placeholder="Vat Number"
                        disabled
                      />
                    </Stack>
                  </>
                ) : (
                  <CTextInput
                    rows={4}
                    title="Reason"
                    value={reason}
                    onChange={(v) => setReason(v)}
                    placeholder="Describe reason"
                  />
                )}

                <SubTitle>Notes</SubTitle>
                <CTextInput
                  rows={3}
                  placeholder="Insert your notes"
                  title="Notes for this waste pickup"
                  value={thisNotes}
                  onChange={(v) => setThisNotes(v)}
                />
                <CTextInput
                  rows={3}
                  placeholder="Insert your notes"
                  title="Notes for next waste pickups"
                  value={nextNotes}
                  onChange={(v) => setNextNotes(v)}
                />
              </>
            ) : (
              <>
                <CAutoComplete
                  title="Target"
                  options={pickup_points.map((pp) => pp.name)}
                  value={target?.name}
                  placeholder="Select Target"
                  onChange={(v) => {
                    setTarget(v);
                    setDate(null);
                  }}
                  required
                  fixed
                  error={errors.indexOf("target") !== -1}
                />
                <CDateInput
                  title="Select date"
                  value={date}
                  onChange={(v) => setDate(v)}
                  required
                  daysOff={daysOff}
                  isVisit
                  error={errors.indexOf("date") !== -1}
                />
                <CAutoComplete
                  title="Member"
                  required
                  options={users}
                  placeholder="Select Member"
                  value={member}
                  onChange={(v) => setMember(v)}
                  fixed
                  error={errors.indexOf("member") !== -1}
                />
                <CAutoComplete
                  title="Vehicle"
                  value={vehicle?.name}
                  required
                  onChange={(v) => setVehicle(v)}
                  placeholder="Select vehicle"
                  options={vehicles.map((vehicle) => {
                    return {
                      value: vehicle.reg_plate,
                      name: vehicle.reg_plate,
                      component: (
                        <Stack>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {vehicle.reg_plate}
                          </Typography>
                          <Typography sx={{ color: "grey", fontSize: "14px" }}>
                            {vehicle.name}
                          </Typography>
                        </Stack>
                      ),
                    };
                  })}
                  hasComponent
                  fixed
                  error={errors.indexOf("vehicle") !== -1}
                />
              </>
            )}
          </Box>
        </Box>
        <Dialog
          open={errorDlgOpen}
          sx={{ padding: "30px" }}
          onClose={() => setErrorDlgOpen(false)}
        >
          <DialogContent>
            <Stack spacing={3} sx={{ minWidth: "450px" }}>
              <Stack
                direction="row"
                sx={{
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "25px", fontWeight: "bold" }}>
                  Cannot receive the waste pickup
                </Typography>
                <IconButton onClick={() => setErrorDlgOpen(false)}>
                  <Close />
                </IconButton>
              </Stack>
              <Typography>
                The destination warehouse cannot receive the waste that is being
                collected either because the deposit does not exist or because
                it is inactive or inoperable
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              sx={[classes.buttonSave, { margin: "20px", width: "100%" }]}
            >
              Ok, understand
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
};
export default AddVisitPage;
