import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { PageTitle } from "../../../components/Typography";
import {
  Android12Switch,
  CAutoComplete,
  COfficialIdInput,
  CTextInput,
} from "../../../components/CustomInput";
import { CModalChange } from "../../../components/CustomModal";
import { CustomMap } from "../../../components/CustomMap";
import { showNotification } from "../../../utils/helpers";
import { validateInput } from "../../../utils/validation";
import { classes } from "../../../utils/class";
import { fetchProducers } from "../../../actions/producers";
import { fetchOperationCodes } from "../../../actions/waste";
import { createPickupPoint } from "../../../actions/pickup";
import { fetchRawWastes } from "../../../actions/organizations";

const AddPickupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.user);
  const orgData = useSelector((state) => state.organizations.profile);

  const [changeModal, setChangeModal] = useState(false);
  const [hasOfficialId, setHasOfficialId] = useState(false);
  const [producer, setProducer] = useState("");
  const [name, setName] = useState("");
  const [rawWaste, setRawWaste] = useState("");
  const [opCode, setOpCode] = useState("");
  const [description, setDescription] = useState("");
  const [saveClicked, setSaveClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [official_id, setOfficialId] = useState("");
  const [official_id_country, setOfficialIdCountry] = useState(
    profile.countryCode
  );
  const [step, setStep] = useState(0);
  const [usingCompanyAddress, setUsingCompanyAddress] = useState(false);
  const [location_data, setLocationData] = useState(null);
  const producers = useSelector((state) => state.producer.producerData);
  const currentProducerId = useSelector(
    (state) => state.producer.currentProducer
  );
  const [isSaving, setIsSaving] = useState(false);
  // const wastes = useSelector((state) => state.organizations.rawWastes);
  // const operationCodes = useSelector((state) => state.waste.operation_codes);

  const handleCloseChangeModal = () => {
    setChangeModal(false);
    navigate("/collection");
  };

  const handleSave = () => {
    setSaveClicked(true);
    setChangeModal(false);
  };

  const checkValidation = useCallback(() => {
    let res = null;
    if (!hasOfficialId)
      res = validateInput({
        producer,
        name,
        // rawWaste,
        // opCode,
      });
    else
      res = validateInput({
        producer,
        name,
        official_id,
      });
    let errs = {};

    for (let err of res) {
      errs[err] = true;
    }

    setErrors(errs);
    return res.length === 0;
  }, [name, producer, official_id, hasOfficialId]);

  const save = () => {
    setSaveClicked(true);
    if (checkValidation() === true) {
      if (!hasOfficialId) {
        setIsSaving(true);
        dispatch(
          createPickupPoint(
            {
              producer: producer.value,
              name: name,
              raw_waste: rawWaste.value,
              operation_code: opCode.value,
              description: description,
              latitude: location_data?.latitude,
              longitude: location_data?.longitude,
              addr_street: location_data?.addr_street,
              addr_apartment: location_data?.addr_apartment,
              addr_postcode: location_data?.addr_postcode,
              addr_city: location_data?.addr_city,
              addr_country: location_data?.addr_country,
            },
            () => {
              setIsSaving(false);
              navigate("/collection");
            }
          )
        );
      } else {
        setIsSaving(true);
        dispatch(
          createPickupPoint(
            {
              producer: producer.value,
              name: name,
              raw_waste: rawWaste.value,
              operation_code: opCode.value,
              description: description,
              official_id: official_id,
              official_id_country: official_id_country,
              latitude: location_data?.latitude,
              longitude: location_data?.longitude,
              addr_street: location_data?.addr_street,
              addr_apartment: location_data?.addr_apartment,
              addr_postcode: location_data?.addr_postcode,
              addr_city: location_data?.addr_city,
              addr_country: location_data?.addr_country,
            },
            () => {
              setIsSaving(false);
              navigate("/collection");
            }
          )
        );
      }
    }
  };

  const next = () => {
    if (checkValidation() === true) {
      setStep(1);
    }
  };

  const cancel = () => {
    setChangeModal(true);
  };

  useEffect(() => {
    if (
      saveClicked &&
      validateInput({ producer, name, rawWaste, opCode, description })
        .length !== 0
    )
      checkValidation();
  }, [
    producer,
    name,
    rawWaste,
    opCode,
    description,
    saveClicked,
    checkValidation,
  ]);

  useEffect(() => {
    dispatch(fetchProducers());
    dispatch(fetchRawWastes());
    dispatch(fetchOperationCodes());
  }, [dispatch]);

  useEffect(() => {
    for (let pro of producers) {
      if (pro._id === producer.value) {
        setOfficialIdCountry(pro.vat_number_country);
        break;
      }
    }
  }, [producer, producers]);

  useEffect(() => {
    for (let pro of producers) {
      if (pro._id === currentProducerId) {
        setProducer({
          name: pro.name,
          value: pro._id,
        });
        break;
      }
    }
  }, [currentProducerId, producers]);

  useEffect(() => {
    if (usingCompanyAddress) {
      if (orgData.latitude && orgData.longitude) {
        setLocationData({
          latitude: orgData.latitude,
          longitude: orgData.longitude,
          addr_country: orgData?.addr_country,
          addr_city: orgData?.addr_city,
          addr_apartment: orgData?.addr_apartment,
          addr_postcode: orgData?.addr_postcode,
          addr_street: orgData?.addr_street,
        });
      } else {
        showNotification("Company address is not defined", "info");
        setUsingCompanyAddress(false);
      }
    }
  }, [usingCompanyAddress, orgData]);

  if (step === 0) {
    return (
      <>
        <Stack sx={{ minHeight: "100vh", height: "100%" }} gap={10}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <PageTitle>Add collection point</PageTitle>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                sx={classes.buttonCancel}
                onClick={cancel}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                sx={classes.buttonPrimary}
                onClick={next}
              >
                Next
              </Button>
            </Stack>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "36px",
                minWidth: "422px",
                justifyContent: "center",
                marginBottom: 50,
              }}
            >
              <Stack>
                <CAutoComplete
                  error={errors.producer}
                  disableDropdown
                  required
                  title="Producer"
                  hasComponent
                  fixed
                  placeholder="Search producer by Name or NIF"
                  options={producers.map((producer, index) => {
                    return {
                      value: producer._id,
                      name: producer.name,
                      component: (
                        <Stack>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {producer.name}
                          </Typography>
                          <Typography sx={{ color: "grey", fontSize: "14px" }}>
                            {producer.id}
                          </Typography>
                        </Stack>
                      ),
                    };
                  })}
                  onChange={(value) => {
                    setProducer(value);
                  }}
                  value={producer}
                />
              </Stack>
              <Stack>
                <CTextInput
                  error={errors.name}
                  required
                  title="Name"
                  placeholder="Collection Point Name"
                  onChange={(value) => {
                    setName(value);
                  }}
                />
              </Stack>
              {/* <Stack>
              <CAutoComplete
                error={errors.rawWaste}
                disableDropdown
                required
                fixed
                title="Raw waste"
                placeholder="Search raw waste by type or code"
                options={wastes.map((waste, index) => {
                  return {
                    name: waste.name,
                    value: waste.rawWasteId,
                  };
                })}
                onChange={(value) => {
                  console.log(value);
                  setRawWaste(value);
                }}
              />
            </Stack>
            <Stack>
              <CAutoComplete
                error={errors.opCode}
                disableDropdown
                required
                title="Operation code"
                placeholder="Select operation code "
                onChange={(value) => {
                  setOpCode(value);
                }}
                options={operationCodes.map((operationCode, index) => {
                  return { name: operationCode.code, value: operationCode._id };
                })}
                fixed
              />
            </Stack> */}
              <Stack direction={"row"} alignItems={"center"} gap={4}>
                <Android12Switch
                  onChange={(e) => {
                    setHasOfficialId(e.target.checked);
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  This collection point has Official ID
                </Typography>
              </Stack>
              <Stack>
                {hasOfficialId && (
                  <COfficialIdInput
                    error={errors.official_id}
                    value={official_id}
                    onChange={(e) => {
                      setOfficialId(e.target.value);
                    }}
                    countryCode={official_id_country}
                    onCountryChange={(e, data) => {
                      setOfficialIdCountry(data.code);
                    }}
                  />
                )}
              </Stack>
              <Stack>
                <CTextInput
                  error={errors.description}
                  rows={3}
                  placeholder="Insert Collection Point description here"
                  title="Description"
                  onChange={(value) => {
                    setDescription(value);
                  }}
                />
              </Stack>
            </Box>
          </Box>
        </Stack>

        <CModalChange
          open={changeModal}
          handleClose={() => {
            setChangeModal(false);
          }}
          handleCancel={handleCloseChangeModal}
          handleSave={handleSave}
        />
      </>
    );
  }

  if (step === 1) {
    return (
      <Stack px={"20px"} py={"20px"}>
        <Stack justifyContent={"flex-end"} flexDirection={"row"} gap={1}>
          <Button
            variant="outlined"
            sx={classes.buttonCancel}
            onClick={() => {
              setStep(0);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="outlined"
            sx={classes.buttonSave}
            onClick={save}
            loading={isSaving}
          >
            {!isSaving && "Save"}
          </LoadingButton>
        </Stack>

        <Stack pt="100px" px={5}>
          <CustomMap
            extraComponent={
              <Stack>
                <PageTitle>Coordinates</PageTitle>
                <Typography sx={{ fontSize: "12px" }}>
                  Select a point on the map to add the Collection Point location
                  or enter the address
                </Typography>
              </Stack>
            }
            secondComponent={
              <Box
                sx={{
                  alignItems: "flex-start",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked
                        checked={usingCompanyAddress}
                        onChange={() =>
                          setUsingCompanyAddress(!usingCompanyAddress)
                        }
                      />
                    }
                    labelPlacement="start"
                    label="Use company address"
                  />
                </FormGroup>
              </Box>
            }
            description={"producer"}
            data={location_data}
            onChange={(d) => {
              setLocationData(d);
            }}
          />
        </Stack>
      </Stack>
    );
  }
};
export default AddPickupPage;
