import { fleet_axios } from "../utils/axios";
import { FAILED, SUCCESS } from "../utils/type";

//get

const getDrivers = async () => {
  return fleet_axios.get("manage/list-drivers");
};

const getMaintenanceRecord = async (vehicleId) => {
  try {
    const res = await fleet_axios.get(
      `maintenance/list-maintenance-periods/${vehicleId}`
    );
    if (res.data.message === SUCCESS) return res.data.data;
    else return [];
  } catch (err) {
    return [];
  }
};

const getTransfer = (vehicleId) => {
  return fleet_axios.get(`manage/list-transfers/${vehicleId}`);
};

const getVehicleDetail = async (vehicleId) => {
  try {
    const res = await fleet_axios.get(`manage/get-vehicle-info/${vehicleId}`);
    if (res.data.message === SUCCESS) {
      return res.data.data;
    } else return null;
  } catch (err) {
    return null;
  }
};
const getTravelledList = async (vehicleId) => {
  try {
    const res = await fleet_axios.get(
      `maintenance/list-travelled-kms/${vehicleId}`
    );
    if (res.data.message === SUCCESS) return res.data.data;
    return [];
  } catch (err) {
    return [];
  }
};

const getRawWasteTypes = (vehicleId) => {
  return fleet_axios.get(`waste/list/${vehicleId}`);
};

const getListOfVehicle = async () => {
  try {
    const res = await fleet_axios.get("manage/list-vehicles");
    if (res.data.message === SUCCESS) return res.data.data;
    else return [];
  } catch (err) {
    return [];
  }
};

//create
const createVehicle = async (data) => {
  try {
    const res = await fleet_axios.post("manage/create-vehicle", data);
    if (res.data.message === SUCCESS) return SUCCESS;
    else return FAILED;
  } catch (err) {
    return err;
  }
};

//put
const activateVehicle = async (vehicleId) => {
  try {
    const res = await fleet_axios.put(`manage/activate-vehicle/${vehicleId}`);
    if (res.data.message === SUCCESS) return SUCCESS;
    return FAILED;
  } catch (err) {
    return FAILED;
  }
};

const deactivateVehicle = async (vehicleId) => {
  try {
    const res = await fleet_axios.put(`manage/deactivate-vehicle/${vehicleId}`);
    if (res.data.message === SUCCESS) return SUCCESS;
    return FAILED;
  } catch (err) {
    return FAILED;
  }
};

const archiveVehicle = async (vehicleId) => {
  try {
    const res = await fleet_axios.put(`manage/archive-vehicle/${vehicleId}`);
    if (res.data.message === SUCCESS) return SUCCESS;
    return FAILED;
  } catch (err) {
    return FAILED;
  }
};

const unarchiveVehicle = async (vehicleId) => {
  try {
    const res = await fleet_axios.put(`manage/unarchive-vehicle/${vehicleId}`);
    if (res.data.message === SUCCESS) return SUCCESS;
    return FAILED;
  } catch (err) {
    return FAILED;
  }
};

const updateVehicleDetail = async (vehicleId, data) => {
  return await fleet_axios.put(
    `manage/update-vehicle-details/${vehicleId}`,
    data
  );
};

const updateVehicleIdentification = (vehicleId, data) => {
  return fleet_axios.put(
    `manage/update-vehicle-identification/${vehicleId}`,
    data
  );
};

const addMaintenanceRecord = async (vehicleId, data) => {
  try {
    const res = await fleet_axios.post(
      `maintenance/add-maintenance-period/${vehicleId}`,
      data
    );
    if (res.data.message === SUCCESS) return SUCCESS;
    return FAILED;
  } catch (err) {
    return FAILED;
  }
};

const editMaintenanceRecord = async (vehicleId, data) => {
  return fleet_axios.put(
    `maintenance/edit-maintenance-period/${vehicleId}`,
    data
  );
};

const deleteMaintenanceRecord = (vehicleId) => {
  return fleet_axios.delete(
    `maintenance/remove-maintenance-period/${vehicleId}`
  );
};

const addTravelledKilo = async (vehicleId, data) => {
  try {
    const res = await fleet_axios.post(
      `maintenance/add-travelled-km/${vehicleId}`,
      data
    );
    if (res.data.message === SUCCESS) return SUCCESS;
  } catch (err) {
    return err;
  }
};

const editTravelledKilo = async (vehicleId, data) => {};

const deleteTravelledKm = async (travelledId) => {
  try {
    const res = await fleet_axios.delete(
      `maintenance/delete-travelled-km/${travelledId}`
    );
    return res.data.message;
  } catch (err) {
    return FAILED;
  }
};

const editOdometerAndTravelledKm = async (travelledId, data) => {
  try {
    const res = await fleet_axios.put(
      `maintenance/edit-travelled-km/${travelledId}`,
      data
    );
    return res.data.message;
  } catch (err) {
    return FAILED;
  }
};

const createFleetWizard = (data) => {
  return fleet_axios.post("/manage/create-vehicle-setup-wizard", data);
};

const assignRawWaste = (id, data) => {
  return fleet_axios.post(`waste/assign/${id}`, data);
};
const editRawWaste = (id, data) => {
  return fleet_axios.put(`waste/edit-capacity/${id}`, data);
};
const unassignRawWaste = (id, data) => {
  return fleet_axios.delete(`waste/detach/${id}/${data.waste}`);
};

const archiveRawWaste = (id) => {
  return fleet_axios.put(`waste/archive/${id}`);
};
const unarchiveRawWaste = (id) => {
  return fleet_axios.put(`waste/unarchive/${id}`);
};
const activateRawWaste = (id) => {
  return fleet_axios.put(`waste/activate/${id}`);
};
const deactivateRawWaste = (id) => {
  return fleet_axios.put(`waste/deactivate/${id}`);
};

const removeVehicle = (id) => {
  return fleet_axios.delete(`manage/remove-vehicle-setup-wizard/${id}`);
};

const fetchVehicleRouteList = () => {
  return fleet_axios.get("manage/list-route-vehicles");
};
const fetchVehicleRoutePickup = (vehicleId, date) => {
  if (vehicleId) {
    return fleet_axios.post("manage/list-route-pickup-points", {
      id: vehicleId,
      date,
    });
  } else {
    return fleet_axios.post("manage/list-route-pickup-points", {
      date,
    });
  }
};
const FleetService = {
  fetchVehicleRoutePickup,
  fetchVehicleRouteList,
  removeVehicle,
  activateRawWaste,
  deactivateRawWaste,
  archiveRawWaste,
  unarchiveRawWaste,
  createFleetWizard,
  unassignRawWaste,
  assignRawWaste,
  editRawWaste,
  getDrivers,
  editOdometerAndTravelledKm,
  getTransfer,
  getListOfVehicle,
  getRawWasteTypes,
  createVehicle,
  getVehicleDetail,
  updateVehicleDetail,
  updateVehicleIdentification,
  activateVehicle,
  deactivateVehicle,
  archiveVehicle,
  unarchiveVehicle,
  getMaintenanceRecord,
  addMaintenanceRecord,
  deleteMaintenanceRecord,
  getTravelledList,
  addTravelledKilo,
  editTravelledKilo,
  deleteTravelledKm,
  editMaintenanceRecord,
};
export default FleetService;
