import { useEffect, useState } from "react";
import {
  Stack,
  Button,
  Typography,
  Box,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { GridLogicOperator } from "@mui/x-data-grid";

import { classes } from "../../../utils/class";
import {
  BACKOFFICE,
  DRIVER,
  OWNER,
  SALES,
  SALES_MANAGER,
  WAREHOUSE_MANAGER,
} from "../../../utils/constants";
import { PageTitle } from "../../../components/Typography";
import { StateSpan } from "../../../components/StateSpan";
import { CardPrimary } from "../../../components/Card";
import { SearchBar } from "../../../components/CustomInput";
import { DataTable } from "../../../components/Table";
import { setCurrentProducer } from "../../../slices/producerSlice";
import ChangeStatusArchive from "./Dialog/ProducerStatusChangeUnarchiveDialog";
import {
  activateProducer,
  archiveProducer,
  deactivateProducer,
  fetchProducers,
  unarchiveProducer,
} from "../../../actions/producers";

const ProducerListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const rows = useSelector((state) => state.producer.producerData);
  const extraInfo = useSelector((state) => state.producer.extraInfo);
  const role = useSelector((state) => state.user.role);

  const columns = [
    {
      field: "name",
      flex: 2,
      minWidth: 150,
      headerName: "Name",
      renderCell: (props) => {
        return (
          <>
            <Stack>
              <Stack>
                <Stack
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {props.row.name}
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                gap={1}
                sx={{
                  fontSize: "12px",
                  fontWeight: "medium",
                  color: "#9F9E9E",
                }}
              >
                <Stack>{props.row.id}</Stack>
              </Stack>
            </Stack>
          </>
        );
      },
    },
    {
      field: "manager",
      headerName: "Manager",
      flex: 2,
      minWidth: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "count_pickup_points",
      headerName: "Nº C. Points",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "count_pickups",
      headerName: "Nº Collections",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "count_zones",
      headerName: "Nº Zones",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "vat_number",
      headerName: "TAX ID",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      flex: 1,
      field: "alert",
      headerName: "Alert",
      renderCell: (props) => {
        return (
          <Tooltip
            slotProps={{
              tooltip: {
                sx: {
                  background: "black",
                },
              },
              arrow: {
                sx: {
                  color: "black",
                },
              },
            }}
            title={`${props.row.count_expiring_documents} docs expiring`}
            placement="top"
          >
            {props.row.alert_expiration && (
              <ReportProblemOutlinedIcon sx={{ color: "red" }} />
            )}
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (props) => {
        let options = [];

        if (role === OWNER || role === BACKOFFICE) {
          if (props.row.status === "active") {
            options = [
              {
                label: "Inactive",
                value: "inactive",
              },
            ];
          } else {
            if (props.row.archived) {
              options = [
                {
                  label: "Unarchive",
                  value: "unarchive",
                },
              ];
            } else {
              options = [
                {
                  label: "Active",
                  value: "active",
                },
                {
                  label: "Archive",
                  value: "archive",
                },
              ];
            }
          }
        }
        if (role === WAREHOUSE_MANAGER || role === SALES || role === DRIVER) {
          options = [];
        }
        if (role === SALES_MANAGER) {
          if (props.row.status === "active") {
            options = [
              {
                label: "Inactive",
                value: "inactive",
              },
            ];
          } else {
            options = [
              {
                label: "Active",
                value: "active",
              },
            ];
          }
        }

        return (
          <>
            <StateSpan
              status={props.row.archived ? "archive" : props.value}
              options={options}
              onStateChangeHandler={(state) => {
                if (state === "active") {
                  dispatch(activateProducer(props.row._id));
                } else if (state === "inactive") {
                  dispatch(deactivateProducer(props.row._id));
                } else if (state === "archive") {
                  dispatch(archiveProducer(props.row._id));
                } else if (state === "unarchive") {
                  dispatch(unarchiveProducer(props.row._id));
                }
              }}
            />
            {/* <EditDetail
              options={[
                { label: "Active", value: STATE_ACTIVE },
                { label: "Inactive", value: STATE_INACTIVE },
                { label: "Archive", value: STATE_ARCHIVE },
              ]}
              onStateChangeHandler={(value) => {
                if (value === STATE_ARCHIVE) setStatus(true);
              }}
            /> */}
          </>
        );
      },
    },
  ];

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    dispatch(
      fetchProducers(() => {
        setIsLoaded(true);
      })
    );
  }, [dispatch]);

  if (rows.length === 0 && isLoaded === true)
    return (
      <>
        <Box sx={{ minHeight: "100vh", height: "80vh" }} mt={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "60px",
            }}
          >
            <PageTitle>Producers</PageTitle>
            <Button
              sx={classes.buttonPrimary}
              onClick={() => {
                navigate("/add-producer");
              }}
              disabled={
                role !== BACKOFFICE && role !== OWNER && role !== SALES_MANAGER
              }
            >
              Add producer
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80%",
            }}
          >
            <PersonOutlineIcon sx={{ color: "text.tertiary", fontSize: 100 }} />
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Manrope",
                fontSize: "10px",
                color: "text.primary",
                marginBottom: "30px",
              }}
            >
              There are no Producers listed
            </Typography>
            <Button
              size="large"
              sx={classes.buttonPrimary}
              onClick={() => {
                navigate("/add-producer");
              }}
              disabled={
                role !== BACKOFFICE && role !== OWNER && role !== SALES_MANAGER
              }
            >
              Add producer
            </Button>
          </Box>
        </Box>
      </>
    );
  else
    return (
      <Stack direction={"column"} marginTop={3} gap={2} paddingX="60px">
        <Stack direction={"row"} justifyContent={"space-between"}>
          <PageTitle>Producers</PageTitle>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={() => {
                navigate("/add-producer");
              }}
              disabled={
                role !== BACKOFFICE && role !== OWNER && role !== SALES_MANAGER
              }
            >
              Add producer
            </Button>
          </Stack>
        </Stack>
        <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
          {isLoaded ? (
            <CardPrimary
              type={1}
              title={"Without manager"}
              content={extraInfo?.count_of_missing_manager || 0}
            />
          ) : (
            <Skeleton variant="rounded" width={"162px"} height={"86px"} />
          )}
          {isLoaded ? (
            <CardPrimary
              type={2}
              title={"Expiring documents"}
              content={extraInfo?.count_expiring_documents || 0}
            />
          ) : (
            <Skeleton variant="rounded" width={"162px"} height={"86px"} />
          )}
          {isLoaded ? (
            <CardPrimary
              type={3}
              title={"Total collected"}
              content={`${extraInfo?.total_collected}t`}
            />
          ) : (
            <Skeleton variant="rounded" width={"162px"} height={"86px"} />
          )}
        </Stack>
        <Stack justifyContent={"flex-start"}>
          {isLoaded ? (
            <SearchBar
              onSearchChange={(value) => {
                setFilterModel({
                  items: [
                    {
                      id: 1,
                      field: "name",
                      operator: "contains",
                      value: value,
                    },
                    // {
                    //   id: 2,
                    //   field: "manager_name",
                    //   operator: "contains",
                    //   value: value,
                    // },
                  ],
                  logicOperator: GridLogicOperator.Or,
                });
              }}
              placeholder="Search producer"
              sx={{ width: "275px" }}
            />
          ) : (
            <Skeleton variant="rounded" height={"30px"} width={"275px"} />
          )}
        </Stack>
        {isLoaded ? (
          <DataTable
            breakpoint="1600"
            filterModel={filterModel}
            rows={rows}
            columns={columns}
            rowHeight={60}
            onRowClick={(e) => {
              dispatch(setCurrentProducer(e.row._id));
              localStorage.setItem("producerTab", 0);
              navigate("/producers-detail");
            }}
          />
        ) : (
          <Skeleton width={"100%"} height={"200px"} />
        )}
        <ChangeStatusArchive
          open={status}
          handleClose={() => {
            setStatus(false);
          }}
          handleContinue={() => {
            setStatus(false);
          }}
        />
      </Stack>
    );
};
export default ProducerListPage;
