import WarehouseService from "../services/WarehouseService";
import {
  setInventories,
  setOffers,
  setWarehouseDeposits,
  setWarehouseFleet,
  setWarehouseList,
  setWarehousePickupPoints,
  setWarehouseProfile,
  setWarehouseTransfers,
  setWarehouseVisits,
} from "../slices/warehouseSlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";

export const fetchInventoryByWarehouse =
  (isOffer, warehouse_id, callBack = () => {}) =>
  (dispatch) => {
    WarehouseService.fetchInventoryListByWarehouse(isOffer, warehouse_id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          const data = res.data.data.map((dat) => {
            return { id: dat._id, ...dat };
          });
          if (isOffer) dispatch(setOffers(data));
          else dispatch(setInventories(data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response ? err.response.data.message : err.message,
          "error"
        );
      });
  };

export const createWarehouse = (data, navigate) => (dispatch) => {
  WarehouseService.createWarehouse(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        navigate(-1);
      }
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const fetchWarehouseList =
  (callBack = () => {}) =>
  (dispatch) => {
    WarehouseService.getWarehouseList()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          const dat = res.data.data.map((r) => {
            return { id: r._id, ...r };
          });
          dispatch(setWarehouseList(dat));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          ERROR[err.response.data.message] || err.response.data.message,
          "error"
        );
      });
  };

export const fetchWarehouseDetail =
  (id, callBack = () => {}) =>
  (dispatch) => {
    WarehouseService.fetchWarehouseDetail(id)
      .then((res) => {
        dispatch(setWarehouseProfile(res.data.data));
        callBack();
      })
      .catch((err) => {
        showNotification(
          ERROR[err.response.data.message] || err.response.data.message,
          "error"
        );
      });
  };

export const fetchPickupPoints =
  (id, callBack = () => {}) =>
  (dispatch) => {
    WarehouseService.fetchWarehousePickupPoints(id)
      .then((res) => {
        dispatch(setWarehousePickupPoints(res.data.data));
        callBack();
      })
      .catch((err) => {
        showNotification(
          ERROR[err.response.data.message] || err.response.data.message,
          "error"
        );
      });
  };
export const fetchWarehouseVisits =
  (id, callBack = () => {}) =>
  (dispatch) => {
    WarehouseService.fetchWarehouseVisits(id)
      .then((res) => {
        dispatch(
          setWarehouseVisits(
            res.data.data.data.map((row) => ({ ...row, id: row._id }))
          )
        );
        callBack();
      })
      .catch((err) => {
        showNotification(
          ERROR[err.response.data.message] || err.response.data.message,
          "error"
        );
      });
  };

export const fetchTransfers =
  (id, callBack = () => {}) =>
  (dispatch) => {
    WarehouseService.fetchWarehouseTransfers(id)
      .then((res) => {
        const data = res.data.data.map((r) => {
          return { id: r._id, ...r };
        });
        dispatch(setWarehouseTransfers(data));
        callBack();
      })
      .catch((err) => {
        showNotification(
          ERROR[err.response.data.message] || err.response.data.message,
          "error"
        );
      });
  };

export const fetchFleet =
  (id, callBack = () => {}) =>
  (dispatch) => {
    WarehouseService.fetchWarehouseFleet(id)
      .then((res) => {
        const data = res.data.data.map((r) => {
          return { id: r._id, ...r };
        });
        dispatch(setWarehouseFleet(data));
        callBack();
      })
      .catch((err) => {
        showNotification(
          ERROR[err.response.data.message] || err.response.data.message,
          "error"
        );
      });
  };

export const updateWarehouseInfo =
  (id, data, fallback = () => {}) =>
  (dispatch) => {
    WarehouseService.updateWarehouseInfo(id, data)
      .then((res) => {
        dispatch(fetchWarehouseDetail(id));
        showNotification("Successfully updated!", "success");
        fallback();
      })
      .catch((err) => {
        showNotification(
          ERROR[err.response.data.message] || err.response.data.message,
          "error"
        );
      });
  };

export const fetchDeposits =
  (id, callBack = () => {}) =>
  (dispatch) => {
    WarehouseService.fetchWarehouseDeposits(id)
      .then((res) => {
        const data = res.data.data.map((r) => {
          return { id: r._id, ...r };
        });
        dispatch(setWarehouseDeposits(data));
        callBack();
      })
      .catch((err) => {
        showNotification(
          ERROR[err.response.data.message] || err.response.data.message,
          "error"
        );
      });
  };

export const addDeposit = (id, data) => (dispatch) => {
  WarehouseService.addDeposit(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchDeposits(id));
        showNotification("Added successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const editDeposit = (id, wh_id, data) => (dispatch) => {
  WarehouseService.editDeposit(id, data)
    .then((res) => {
      dispatch(fetchDeposits(wh_id));
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const deleteWarehouse = (id) => (dispatch) => {
  WarehouseService.deleteWarehouse(id)
    .then((res) => {
      dispatch(fetchWarehouseList());
      showNotification("Warehouse is removed", "success");
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const deleteDeposit = (id, wh_id) => (dispatch) => {
  WarehouseService.deleteDeposit(id)
    .then((res) => {
      dispatch(fetchDeposits(wh_id));
      showNotification("Capacity was removed", "success");
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const archiveWarehouse = (id) => (dispatch) => {
  WarehouseService.archiveWarehouse(id)
    .then((res) => {
      dispatch(fetchWarehouseDetail(id));
      dispatch(fetchWarehouseList());
      showNotification("Archived Successfully", "success");
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const unarchiveWarehouse = (id) => (dispatch) => {
  WarehouseService.unarchiveWarehouse(id)
    .then((res) => {
      dispatch(fetchWarehouseDetail(id));
      dispatch(fetchWarehouseList());
      showNotification("Unarchived Successfully", "success");
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const activateWarehouse = (id) => (dispatch) => {
  WarehouseService.activateWarehouse(id)
    .then((res) => {
      dispatch(fetchWarehouseList());
      dispatch(fetchWarehouseDetail(id));
      showNotification("Activation Successful", "success");
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const deactivateWarehouse = (id) => (dispatch) => {
  WarehouseService.deactivateWarehouse(id)
    .then((res) => {
      dispatch(fetchWarehouseList());
      dispatch(fetchWarehouseDetail(id));
      showNotification("Deactivation Successful", "success");
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const activateDeposit = (id, wh_id) => (dispatch) => {
  WarehouseService.activateDeposit(id)
    .then((res) => {
      showNotification("Activation successful", "success");
      dispatch(fetchDeposits(wh_id));
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const deactivateDeposit = (id, wh_id) => (dispatch) => {
  WarehouseService.deactivateDeposit(id)
    .then((res) => {
      showNotification("Deactivation successful", "success");
      dispatch(fetchDeposits(wh_id));
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const archiveDeposit = (id, wh_id) => (dispatch) => {
  WarehouseService.archiveDeposit(id)
    .then((res) => {
      dispatch(fetchDeposits(wh_id));
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const unarchiveDeposit = (id, wh_id) => (dispatch) => {
  WarehouseService.unarchiveDeposit(id)
    .then((res) => {
      dispatch(fetchDeposits(wh_id));
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};
