import { Modal, Stack, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { classes } from "../../utils/class";
import { PageTitle } from "../Typography";
import { CTimeInput, CheckBoxes } from "../CustomInput";
import { useEffect, useState } from "react";

export const OperatingScheduleModal = ({
  open,
  title,
  data = {},
  handleCancel,
  handleClose,
  handleSave,
}) => {
  const [daysOff, setDaysOff] = useState([]);
  const [openingTime, setOpeningTime] = useState();
  const [closingTime, setClosingTime] = useState();
  const [allowedFrom, setAllowedFrom] = useState();
  const [allowedTo, setAllowedTo] = useState();

  useEffect(() => {
    setOpeningTime(data?.openingTime);
    setClosingTime(data?.closingTime);
    setAllowedFrom(data?.allowedFrom);
    setAllowedTo(data?.allowedTo);
    setDaysOff(data?.daysOff);
  }, [open]);

  const onConfirmSave = () => {
    let data = {};
    if (openingTime && closingTime && allowedFrom && allowedTo) {
      data = {
        openingTime,
        closingTime,
        allowedFrom,
        allowedTo,
        daysOff,
      };
      handleSave(data);
    } else {
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Stack sx={classes.popup}>
          <Stack>
            <PageTitle>{title}</PageTitle>
          </Stack>
          <IconButton
            onClick={() => {
              handleClose();
            }}
            sx={{
              position: "absolute",
              right: "42px",
              top: "34px",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            sx={{
              fontFamily: "Manrope",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "27px",
            }}
          >
            Working hours
          </Typography>

          <Stack direction={"row"} gap={4}>
            <Stack flex={1}>
              <CTimeInput
                title="Opening time"
                required
                value={data?.openingTime}
                onChange={(value) => {
                  setOpeningTime(value);
                }}
              />
            </Stack>
            <Stack flex={1}>
              <CTimeInput
                title="Closing time"
                required
                value={data?.closingTime}
                onChange={(value) => {
                  setClosingTime(value);
                }}
              />
            </Stack>
          </Stack>

          <Typography
            sx={{
              fontFamily: "Manrope",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "27px",
            }}
          >
            Collection allowed
          </Typography>

          <Stack direction={"row"} gap={4}>
            <Stack flex={1}>
              <CTimeInput
                title="Allowed from"
                required
                value={data?.allowedFrom}
                onChange={(value) => {
                  setAllowedFrom(value);
                }}
              />
            </Stack>
            <Stack flex={1}>
              <CTimeInput
                title="Allowed to"
                required
                value={data?.allowedTo}
                onChange={(value) => {
                  setAllowedTo(value);
                }}
              />
            </Stack>
          </Stack>

          <Typography
            sx={{
              fontFamily: "Manrope",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "27px",
            }}
          >
            Days off
          </Typography>

          <CheckBoxes
            title="Select days off"
            selectedOptions={daysOff}
            onChange={(option, checked) => {
              if (checked) {
                setDaysOff([...daysOff, option]);
              } else {
                const d = [...daysOff];
                const index = daysOff.indexOf(option);
                d.splice(index, 1);
                setDaysOff(d);
              }
            }}
            options={[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ]}
          />

          <Stack gap={2} direction={"row"} justifyContent={"flex-end"}>
            <Button
              sx={classes.buttonCancel}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button sx={classes.buttonSave} onClick={onConfirmSave}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
