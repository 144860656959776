import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Button } from "@mui/material";

import {
  InputTitle,
  PageTitle,
  SubTitle,
} from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

const ScheduleDelete = ({
  open = false,
  handleClose = function () {},
  handleContinue = function () {},
}) => {
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            maxWidth: "450px",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} padding={3}>
            <PageTitle>Delete schedule visit</PageTitle>
            <InputTitle>
              Deleting this schedule visit will re-scheduling the next visits.
            </InputTitle>
            <InputTitle> Are you sure you want to continue?</InputTitle>
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <Button sx={classes.buttonCancel} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                sx={classes.buttonSave}
                onClick={() => {
                  handleContinue();
                  handleClose();
                }}
              >
                Delete visit
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default ScheduleDelete;
