import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Divider, Stack, Typography, Chip } from "@mui/material";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

import { classes } from "../../../../utils/class";
import { showNotification, snackbarRef } from "../../../../utils/helpers";
import {
  AccentTitle,
  MediumTitle,
  SuperTitle,
} from "../../../../components/Typography";
import { EmptyWizard } from "../../../../components/wizard/Card";
import { CModalChange, CModalDelete } from "../../../../components/CustomModal";
import {
  CollectionPointIdIcon,
  ContactIcon,
  OperatingScheduleIcon,
  ProducerIcon,
  RawWastesIcon,
} from "../../../../components/icons/menu_icons/icons";
import { CollectionPointIdModal } from "../../../../components/pickup/IdentificationModal";
import { CoordinatesModal } from "../../../../components/pickup/CoordinatesModal";
import { ContactResponsibleModal } from "../../../../components/pickup/ContactResponsibleModal";
import { RawWastesModal } from "../../../../components/pickup/RawWastesModal";
import { OperatingScheduleModal } from "../../../../components/pickup/OperatingScheduleModal";
import { CollectionPointIdWizard } from "../../../../components/wizard/CollectionPointIdWizard";
import { ContactResponsibleWizard } from "../../../../components/wizard/ContactResponsibleWizard";
import { RawWastesWizard } from "../../../../components/wizard/RawWastesWizard";
import { OperatingScheduleWizard } from "../../../../components/wizard/OperatingScheduleWizard";
import { DeleteCollectionPointModal } from "../../../../components/pickup/DeleteCollectionPointModal";

const AddCollectionPoint = ({ setStep }) => {
  const [identificationModalOpen, setIdentificationModalOpen] = useState(false);
  const [coordinatesModalOpen, setCoordinatesModalOpen] = useState(false);
  const [collectionPointId, setCollectionPointId] = useState(false);
  const [collectionPointIdData, setCollectionPointIdData] = useState();
  const [collectionPointIdTempData, setCollectionPointIdTempData] = useState();
  const [
    collectionPointIdDeleteModalOpen,
    setCollectionPointIdDeleteModalOpen,
  ] = useState(false);
  const [contactResponsible, setContactResponsible] = useState(false);
  const [contactResponsibleData, setContactResponsibleData] = useState([]);
  const [contactResponsibleModalOpen, setContactResponsibleModalOpen] =
    useState(false);
  const [
    contactResponsibleDeleteModalOpen,
    setContactResponsibleDeleteModalOpen,
  ] = useState(false);
  const [rawWastes, setRawWastes] = useState(false);
  const [rawWasteIdsData, setRawWasteIdsData] = useState([]);
  const [rawWastesData, setRawWastesData] = useState([]);
  const [rawWastesModalOpen, setRawWastesModalOpen] = useState(false);
  const [rawWastesDeleteModalOpen, setRawWastesDeleteModalOpen] =
    useState(false);
  const [operatingSchedule, setOperatingSchedule] = useState(false);
  const [operatingScheduleData, setOperatingScheduleData] = useState({
    openingTime: dayjs().hour(0).minute(0),
    closingTime: dayjs().hour(0).minute(0),
    allowedFrom: dayjs().hour(0).minute(0),
    allowedTo: dayjs().hour(0).minute(0),
    daysOff: [],
  });
  const [operatingScheduleModalOpen, setOperatingScheduleModalOpen] =
    useState(false);
  const [
    operatingScheduleDeleteModalOpen,
    setOperatingScheduleDeleteModalOpen,
  ] = useState(false);
  const [unsavedOpen, setUnsavedOpen] = useState(false);

  const onCancelConfirm = () => {
    setUnsavedOpen(false);
    setStep(0);
  };

  const onCloseConfirm = () => {
    setUnsavedOpen(false);
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
      >
        <Stack maxWidth={"420px"} minWidth={"420px"}>
          <Stack
            sx={{ backgroundColor: "#F7F6F3" }}
            py={"96px"}
            px={"48px"}
            gap={"16px"}
          >
            <SuperTitle> Adding a collection point</SuperTitle>
          </Stack>
          <Stack
            sx={{
              padding: "48px",
              fontFamily: "Manrope",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "500",
            }}
          >
            At this stage of configuring the collection point you can define and
            manage the key aspects of each collection point to ensure efficient
            waste pickup and handling.
          </Stack>
        </Stack>
        <Stack
          width={"calc(100% - 420px);"}
          gap="24px"
          padding={5}
          justifyContent={"flex-start"}
          textAlign={"flex-start"}
          overflow={"auto"}
          sx={{
            backgroundColor: "white",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={() => {
                setUnsavedOpen(true);
              }}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={() => {
                showNotification(
                  "You must complete all the steps before moving on",
                  "error"
                );
              }}
            >
              {collectionPointId &&
              contactResponsible &&
              rawWastes &&
              operatingSchedule
                ? "Done"
                : "Pending"}
            </Button>
          </Stack>
          <Stack
            justifyContent={"flex-start"}
            height={"100%"}
            sx={{ marginTop: "64px" }}
            gap={2}
          >
            <CollectionPointIdWizard
              title="Collection Point ID"
              content="Identify each collection point with a unique name and location information. Complete this section to help us understand where your waste is collected from."
              Icon={<CollectionPointIdIcon size="32px" fill="#000000" />}
              buttonText="Add"
              active={collectionPointId}
              data={collectionPointIdData}
              onAdd={() => {
                setCollectionPointIdTempData();
                setCollectionPointIdData();
                setIdentificationModalOpen(true);
              }}
              handleDelete={() => {
                setCollectionPointIdDeleteModalOpen(true);
              }}
              handleEdit={() => {
                setIdentificationModalOpen(true);
              }}
            />
            <ContactResponsibleWizard
              title="Contact/Responsible"
              content="Assign a responsible person or yourself as the main contact for each collection point. This ensures there's always someone we can reach out to regarding collections."
              Icon={<ContactIcon size="32px" fill="#000000" />}
              buttonText="Add"
              active={contactResponsible}
              data={contactResponsibleData}
              onAdd={() => {
                setContactResponsibleModalOpen(true);
              }}
              handleDelete={() => {
                setContactResponsibleDeleteModalOpen(true);
              }}
              handleEdit={() => {
                setContactResponsibleModalOpen(true);
              }}
            />
            <RawWastesWizard
              title="Raw Wastes"
              content="Specify the types of waste each collection point will handle. Proper identification aids in precise waste management and compliance."
              Icon={<RawWastesIcon size="32px" fill="#000000" />}
              buttonText="Add"
              active={rawWastes}
              data={rawWastesData}
              onAdd={() => {
                setRawWasteIdsData([]);
                setRawWastesData([]);
                setRawWastesModalOpen(true);
              }}
              handleDelete={() => {
                setRawWastesDeleteModalOpen(true);
              }}
              handleEdit={() => {
                setRawWastesModalOpen(true);
              }}
            />
            <OperatingScheduleWizard
              title="Operating Schedule"
              content="Set the operating hours and special schedule considerations for each collection point to align with waste pickup times."
              Icon={<OperatingScheduleIcon size="32px" fill="#000000" />}
              buttonText="Add"
              active={operatingSchedule}
              data={operatingScheduleData}
              onAdd={() => {
                setOperatingScheduleModalOpen(true);
              }}
              handleDelete={() => {
                setOperatingScheduleData({
                  openingTime: dayjs().hour(0).minute(0),
                  closingTime: dayjs().hour(0).minute(0),
                  allowedFrom: dayjs().hour(0).minute(0),
                  allowedTo: dayjs().hour(0).minute(0),
                  daysOff: [],
                });
                setOperatingScheduleDeleteModalOpen(true);
              }}
              handleEdit={() => {
                setOperatingScheduleModalOpen(true);
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <CollectionPointIdModal
        open={identificationModalOpen}
        title="Identification"
        data={collectionPointIdData}
        handleClose={() => setIdentificationModalOpen(false)}
        handleCancel={() => setIdentificationModalOpen(false)}
        handleSave={(data) => {
          setCollectionPointIdTempData(data);
          setIdentificationModalOpen(false);
          setCoordinatesModalOpen(true);
        }}
      />

      <CoordinatesModal
        open={coordinatesModalOpen}
        title="Coordinates"
        data={collectionPointIdData}
        handleClose={() => {
          setCoordinatesModalOpen(false);
        }}
        handleCancel={() => {
          setCoordinatesModalOpen(false);
        }}
        handleSave={(data) => {
          setCollectionPointIdData({ ...collectionPointIdTempData, ...data });
          setCollectionPointId(true);
          setCoordinatesModalOpen(false);
        }}
      />

      <ContactResponsibleModal
        open={contactResponsibleModalOpen}
        title="Contact/Responsible"
        handleClose={() => {
          setContactResponsibleModalOpen(false);
        }}
        handleCancel={() => {
          setContactResponsibleModalOpen(false);
        }}
        handleSave={(result) => {
          if (result.length > 0) {
            showNotification("The session was saved successfully.", "success");
            setContactResponsibleData(result);
            setContactResponsibleModalOpen(false);
            setContactResponsible(true);
          } else {
          }
        }}
      />

      <RawWastesModal
        open={rawWastesModalOpen}
        title="Raw Wastes"
        data={rawWasteIdsData}
        handleClose={() => {
          setRawWastesModalOpen(false);
        }}
        handleCancel={() => {
          setRawWastesModalOpen(false);
        }}
        handleSave={(data, result) => {
          showNotification("The session was saved successfully.", "success");
          setRawWasteIdsData(data);
          setRawWastesData(result);
          setRawWastes(true);
          setRawWastesModalOpen(false);
        }}
      />

      <OperatingScheduleModal
        open={operatingScheduleModalOpen}
        title="Operating Schedule"
        data={operatingScheduleData}
        handleClose={() => {
          setOperatingScheduleModalOpen(false);
        }}
        handleCancel={() => {
          setOperatingScheduleModalOpen(false);
        }}
        handleSave={(data) => {
          showNotification("The session was saved successfully.", "success");
          setOperatingScheduleData(data);
          setOperatingScheduleModalOpen(false);
          setOperatingSchedule(true);
        }}
      />
      <CModalChange
        open={unsavedOpen}
        confirmValue="Continue editing"
        handleCancel={onCancelConfirm}
        handleClose={onCloseConfirm}
      />
      <CModalDelete
        open={collectionPointIdDeleteModalOpen}
        title="Delete"
        confirmValue="Delete"
        closeValue="Cancel"
        contentValue="Deleting these records is irreversible and will permanently delete all related data."
        handleCancel={() => {
          setCollectionPointIdDeleteModalOpen(false);
        }}
        handleClose={() => {
          setCollectionPointIdDeleteModalOpen(false);
        }}
        handleDelete={() => {
          setCollectionPointId(false);
          setCollectionPointIdDeleteModalOpen(false);
        }}
      />
      <CModalDelete
        open={rawWastesDeleteModalOpen}
        title="Delete"
        confirmValue="Delete"
        closeValue="Cancel"
        contentValue="Deleting these records is irreversible and will permanently delete all related data."
        handleCancel={() => {
          setRawWastesDeleteModalOpen(false);
        }}
        handleClose={() => {
          setRawWastesDeleteModalOpen(false);
        }}
        handleDelete={() => {
          setRawWastes(false);
          setRawWastesDeleteModalOpen(false);
        }}
      />
      <CModalDelete
        open={contactResponsibleDeleteModalOpen}
        title="Delete"
        confirmValue="Delete"
        closeValue="Cancel"
        contentValue="Deleting these records is irreversible and will permanently delete all related data."
        handleCancel={() => {
          setContactResponsibleDeleteModalOpen(false);
        }}
        handleClose={() => {
          setContactResponsibleDeleteModalOpen(false);
        }}
        handleDelete={() => {
          setContactResponsible(false);
          setContactResponsibleDeleteModalOpen(false);
        }}
      />
      <CModalDelete
        open={operatingScheduleDeleteModalOpen}
        title="Delete"
        confirmValue="Delete"
        closeValue="Cancel"
        contentValue="Deleting these records is irreversible and will permanently delete all related data."
        handleCancel={() => {
          setOperatingScheduleDeleteModalOpen(false);
        }}
        handleClose={() => {
          setOperatingScheduleDeleteModalOpen(false);
        }}
        handleDelete={() => {
          setOperatingSchedule(false);
          setOperatingScheduleDeleteModalOpen(false);
        }}
      />
    </>
  );
};

const CollectionPoint = () => {
  const [deleteCollectionPointModalOpen, setDeleteCollectionPointModalOpen] =
    useState(false);

  return (
    <>
      <Stack
        sx={{
          backgroundColor: "#FAFAF8",
          width: "690px",
          maxWidth: "690px",
          minWidth: "690px",
          padding: "16px",
          borderRadius: "8px",
        }}
      >
        <Stack
          sx={{
            backgroundColor: "#FFFFFF",
            height: "100%",
          }}
          direction={"row"}
        >
          <Stack
            flex={1}
            gap="16px"
            sx={{
              px: "24px",
              py: "16px",
            }}
          >
            {/* Identification */}
            <Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <MediumTitle sx={{ fontWeight: 700 }}>
                  Identification
                </MediumTitle>
                <Typography
                  sx={{
                    cursor: "pointer",
                    fontFamily: "Manrope",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "12px",
                    color: "#6293BC",
                  }}
                >
                  Edit
                </Typography>
              </Stack>
              <Stack gap="16px">
                <Stack
                  gap="8px"
                  sx={{
                    paddingTop: "16px",
                  }}
                >
                  <AccentTitle>NAME</AccentTitle>
                  <MediumTitle>OGR Waste Portugal</MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <AccentTitle>COORDINATES</AccentTitle>
                  <MediumTitle>41.5614521, -8.62114</MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <AccentTitle>OFFICIAL ID</AccentTitle>
                  <MediumTitle>PT09655764</MediumTitle>
                </Stack>
                <Stack
                  gap="8px"
                  sx={{
                    width: "264px",
                  }}
                >
                  <AccentTitle>DESCRIPTION</AccentTitle>
                  <MediumTitle>
                    This collection point is located near the Dom Duarte road.
                    Call before your visit.
                  </MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <AccentTitle>ADDRESS</AccentTitle>
                  <MediumTitle>
                    Porto <br />
                    Estrada de Alcolomba, 30
                  </MediumTitle>
                </Stack>
                <a
                  onClick={() => {}}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "12px",
                      color: "#6293BC",
                      paddingBottom: "8px",
                    }}
                  >
                    View on the map
                  </Typography>
                </a>
                <Divider />
              </Stack>
            </Stack>
            {/* Contact/Responsible */}
            <Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <MediumTitle sx={{ fontWeight: 700 }}>
                  Contact/Responsible
                </MediumTitle>
                <Typography
                  sx={{
                    cursor: "pointer",
                    fontFamily: "Manrope",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "12px",
                    color: "#6293BC",
                  }}
                >
                  Edit
                </Typography>
              </Stack>
              <Stack gap="16px">
                <Stack
                  gap="8px"
                  sx={{
                    paddingTop: "16px",
                  }}
                >
                  <AccentTitle>NAME</AccentTitle>
                  <MediumTitle>Otto Miranda</MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <AccentTitle>EMAIL</AccentTitle>
                  <MediumTitle>ottomiranda@untile.pt</MediumTitle>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            flex={1}
            gap="16px"
            sx={{
              px: "24px",
              py: "16px",
            }}
          >
            {/* Raw Wastes Type */}
            <Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <MediumTitle sx={{ fontWeight: 700 }}>
                  Raw Wastes Type
                </MediumTitle>
                <Typography
                  sx={{
                    cursor: "pointer",
                    fontFamily: "Manrope",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "12px",
                    color: "#6293BC",
                  }}
                >
                  Edit
                </Typography>
              </Stack>
              <Stack gap="16px">
                <Stack
                  gap="8px"
                  sx={{
                    paddingTop: "16px",
                  }}
                >
                  <MediumTitle>Waste cooking oil</MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <MediumTitle>Waste industrial oil</MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <MediumTitle>Waste extra-virgin oil</MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <MediumTitle>Waste cooking oil</MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <MediumTitle>Waste extra-virgin</MediumTitle>
                </Stack>
                <a
                  onClick={() => {}}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "12px",
                      color: "#6293BC",
                      paddingBottom: "8px",
                    }}
                  >
                    View all
                  </Typography>
                </a>
                <Divider />
              </Stack>
            </Stack>
            {/* Operating Schedule */}
            <Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <MediumTitle sx={{ fontWeight: 700 }}>
                  Operating Schedule
                </MediumTitle>
                <Typography
                  sx={{
                    cursor: "pointer",
                    fontFamily: "Manrope",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "12px",
                    color: "#6293BC",
                  }}
                >
                  Edit
                </Typography>
              </Stack>
              <Stack gap="16px">
                <Stack
                  gap="8px"
                  sx={{
                    paddingTop: "16px",
                  }}
                >
                  <AccentTitle>OPENING TIME</AccentTitle>
                  <MediumTitle>02:00</MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <AccentTitle>CLOSING TIME</AccentTitle>
                  <MediumTitle>03:05</MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <AccentTitle>COLLECTION ALLOWED FROM</AccentTitle>
                  <MediumTitle>02:00</MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <AccentTitle>COLLECTION ALLOWED TO</AccentTitle>
                  <MediumTitle>02:30</MediumTitle>
                </Stack>
                <Stack gap="8px">
                  <AccentTitle>DAYS OFF</AccentTitle>
                  <Chip
                    label="MON"
                    variant="outlined"
                    size="small"
                    sx={{
                      width: "44px",
                      borderRadius: "4px",
                      borderColor: "#9F9E9E",
                      color: "#23222D",
                      fontSize: "12px",
                      fontWeight: 600,
                      fontFamily: "Manrope",
                      lineHeight: "12px",
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Typography
              onClick={() => {
                setDeleteCollectionPointModalOpen(true);
              }}
              sx={{
                cursor: "pointer",
                fontFamily: "Manrope",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "12px",
                color: "#D64C56",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              Delete Collection Point
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <DeleteCollectionPointModal
        open={deleteCollectionPointModalOpen}
        handleCancel={() => {
          setDeleteCollectionPointModalOpen(false);
        }}
        handleClose={() => {
          setDeleteCollectionPointModalOpen(false);
        }}
      />
    </>
  );
};

const SetupCollectionPointsInProducer = () => {
  snackbarRef.current = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [unsavedOpen, setUnsavedOpen] = useState(false);

  const onCancelConfirm = () => {
    setUnsavedOpen(false);
    window.history.back();
  };

  const onCloseConfirm = () => {
    setUnsavedOpen(false);
  };

  const onSaveConfirm = () => {
    setUnsavedOpen(false);
  };

  const [producerDetails, setProducerDetails] = useState([]);

  return (
    <>
      {step === 0 && (
        <Stack
          flexDirection={"row"}
          sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
        >
          <Stack maxWidth={"420px"} minWidth={"420px"}>
            <Stack
              sx={{ backgroundColor: "#F7F6F3" }}
              pt={"48px"}
              px={"48px"}
              pb={"16px"}
              gap={"16px"}
            >
              <SuperTitle> Collection Points</SuperTitle>
              <Stack
                maxWidth={"296px"}
                sx={{
                  fontSize: "16px",
                  fontFamily: "Manrope",
                  lineHeight: "24px",
                  fontWeight: "500",
                }}
                gap={"16px"}
              >
                <Box>
                  After identifying your establishment's waste types, proceed to
                  set up Collection Points for waste pickup and transfer.
                </Box>
                <Box>
                  Once you have completed your onboarding, you can manage
                  additional information about your Collection Points from the
                  main menu under{" "}
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "32px",
                      fontFamily: "Roboto",
                    }}
                  >
                    Collection Points.
                  </span>
                </Box>
              </Stack>
            </Stack>
            <Stack
              sx={{
                padding: "48px",
                fontFamily: "Manrope",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "500",
              }}
            >
              <Box
                sx={{
                  fontWeight: "700",
                  fontFamily: "Manrope",
                  fontSize: "18px",
                  lineHeight: "27px",
                  letterSpacing: "-1%",
                  paddingBottom: "8px",
                }}
              >
                Why is this important?
              </Box>
              Collection Points streamline waste pickup and scheduling,
              essential for effective logistics and creating accurate Waste
              Transfer Notes, ensuring compliance and traceability of waste from
              collection to final destination.
            </Stack>
          </Stack>
          <Stack
            width={"calc(100% - 420px);"}
            gap={2}
            padding={5}
            justifyContent={"flex-start"}
            textAlign={"flex-start"}
            overflow={"auto"}
            sx={{
              backgroundColor: "white",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                variant="outlined"
                sx={classes.buttonCancel}
                onClick={() => {
                  setUnsavedOpen(true);
                }}
              >
                Back
              </Button>
              <Button
                variant="outlined"
                // onClick={confirmProducers}
                onClick={() => {
                  showNotification(
                    "You must complete all the steps before moving on",
                    "error"
                  );
                }}
                sx={classes.buttonPrimary}
              >
                Pending
              </Button>
            </Stack>
            <Stack justifyContent={"flex-start"} height={"100%"} mt="104px">
              {producerDetails.length === 0 ? (
                <>
                  <EmptyWizard
                    title="No collection points have been added to this producer yet"
                    content="Add the first collection point to your organization to continue. You can manage your collection point at any time."
                    Icon={<ProducerIcon size="32px" fill="#33343F" />}
                    buttonText={"Add Collection Point"}
                    onAdd={() => {
                      setStep(1);
                    }}
                  />
                </>
              ) : (
                <>
                  <Stack direction={"row"} gap="16px">
                    <Button
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        width: "260px",
                        maxWidth: "260px",
                        minWidth: "260px",
                        borderRadius: "8px",
                        backgroundColor: "#FAFAF8",
                        height: "80vh",
                      }}
                    >
                      <Stack alignItems={"center"} justifyContent={"center"}>
                        <Stack>
                          <svg
                            width="28"
                            height="29"
                            viewBox="0 0 28 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 0.5C10.3009 0.544883 6.76597 2.03429 4.15013 4.65013C1.53429 7.26597 0.0448832 10.8009 0 14.5C0.0448832 18.1991 1.53429 21.734 4.15013 24.3499C6.76597 26.9657 10.3009 28.4551 14 28.5C17.6991 28.4551 21.234 26.9657 23.8499 24.3499C26.4657 21.734 27.9551 18.1991 28 14.5C27.9551 10.8009 26.4657 7.26597 23.8499 4.65013C21.234 2.03429 17.6991 0.544883 14 0.5ZM22 15.5H15V22.5H13V15.5H6V13.5H13V6.5H15V13.5H22V15.5Z"
                              fill="#6293BC"
                            />
                          </svg>
                        </Stack>
                        <Typography
                          sx={{
                            fontFamily: "Manrope",
                            fontWeight: 500,
                            fontSize: "12px",
                            lineHeight: "12px",
                            paddingTop: "12px",
                            color: "#6293BC",
                          }}
                        >
                          Add Collection Point
                        </Typography>
                      </Stack>
                    </Button>
                    <CollectionPoint />
                    <CollectionPoint />
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
      {step === 1 && (
        <>
          <AddCollectionPoint setStep={setStep} />
        </>
      )}
      <CModalChange
        open={unsavedOpen}
        confirmValue="Continue editing"
        handleCancel={onCancelConfirm}
        handleClose={onCloseConfirm}
        handleSave={onSaveConfirm}
      />
    </>
  );
};

export default SetupCollectionPointsInProducer;
