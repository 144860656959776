import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Stack, Typography } from "@mui/material";
import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { CAutoComplete, CTextInput } from "../../../../components/CustomInput";
import {
  addPickupPointDeposit,
  fetchAvailableRawWaste,
} from "../../../../actions/pickup";
import { setAddDepositStep } from "../../../../slices/pickupSlice";
import { showNotification } from "../../../../utils/helpers";
import { DRIVER, WAREHOUSE_MANAGER } from "../../../../utils/constants";

const AddDepositDialog = ({ open, onClose, id, producerId }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState();
  const rawWasteList = useSelector((state) => state.pickup.rawWasteList);
  const role = useSelector((state) => state.user.role);
  const [rawWaste, setRawWaste] = useState("");
  const [capacity, setCapacity] = useState("");

  useEffect(() => {
    if (producerId && role !== WAREHOUSE_MANAGER && role !== DRIVER)
      dispatch(fetchAvailableRawWaste(producerId));
  }, [producerId]);

  useEffect(() => {
    const data = rawWasteList.map((rw, idx) => {
      return {
        value: rw.rawWasteId,
        name: rw.name,
        component: (
          <Stack>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              {rw.code}
            </Typography>
            <Typography sx={{ color: "grey", fontSize: "12px" }}>
              {rw.name}
            </Typography>
          </Stack>
        ),
      };
    });
    setItems(data);
  }, [rawWasteList]);

  useEffect(() => {
    setRawWaste(null);
    setCapacity(null);
  }, [open]);
  const onSave = () => {
    if (rawWaste) {
      dispatch(
        addPickupPointDeposit(id, {
          raw_waste: rawWasteList.find((rw) => rw.name === rawWaste.name)
            .rawWasteId,
          capacity,
        })
      );
      onClose();
      dispatch(setAddDepositStep(false));
    } else {
      showNotification("Select a raw waste", "erorr");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        padding={"34px 42px"}
        gap={3}
        sx={{
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "520px",
          borderRadius: "8px",
        }}
      >
        <PageTitle>Add Capacity</PageTitle>
        <CAutoComplete
          title="Raw waste type selection"
          placeholder="Select Raw waste"
          required
          options={items}
          fixed
          hasComponent
          value={rawWaste}
          onChange={(v) => {
            setRawWaste(v);
          }}
        />
        <CTextInput
          title="Capacity"
          required
          endIcon="kg"
          value={capacity}
          placeholder="0"
          onChange={(v) => setCapacity(v)}
        />
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={onClose}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={onSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AddDepositDialog;
