import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { CPhoneInput } from "../../../../components/CustomInput";
import { useEffect, useRef, useState } from "react";
import {
  disable2FA,
  enable2FA,
  fetchSecurityInfo,
} from "../../../../actions/user";
import AuthCode from "react-auth-code-input";
import { Link } from "react-router-dom";
import AuthenticationService from "../../../../services/AuthenticationService";
import { showNotification } from "../../../../utils/helpers";
import { STATE_DISABLED, STATE_ENABLED, SUCCESS } from "../../../../utils/type";
import { StateSpan } from "../../../../components/StateSpan";

const TwoFactorView = ({ setStage }) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [authMethod, setAuthMethod] = useState("SMS");
  const [open, setOpen] = useState(false);
  const profile = useSelector((state) => state.user.profile);
  const two_fa = useSelector((state) => state.user.security.twofa_enabled);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    p: 4,
  };
  useEffect(() => {
    setPhone(profile.mobile_phone);
  }, [profile]);
  const [authCode, setAuthCode] = useState("");
  const authRef = useRef(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onClickConfirm = () => {
    if (two_fa === false) {
      dispatch(enable2FA({ phone }));
      handleOpen();
    } else {
      dispatch(disable2FA());
    }
  };
  const handleAuthCodeOnChange = (res) => {
    setAuthCode(res);
  };
  const handleResendCode = () => {
    setAuthMethod("SMS");
    authRef.current.clear();
    AuthenticationService.resend2faCode({
      email: profile?.email,
    })
      .then((res) => {
        showNotification("2FA code sent, check your email", "success");
      })
      .catch((err) => {
        showNotification("Error", "error");
      });
  };
  const handleConfirm2fa = () => {
    AuthenticationService.confirmEnable2fa({
      email: profile?.email,
      code: authCode,
    })
      .then((res) => {
        showNotification("2fa setting updated", "success");
        dispatch(fetchSecurityInfo());
        handleClose();
        // dispatch(log());
        // navigate("/auth/signin-select-org");
      })
      .catch((err) => {
        showNotification("Wrong 2FA code, Try again", "error");
      });
  };
  return (
    <Box>
      <PageTitle>Security</PageTitle>
      <Stack spacing={2} sx={{ marginLeft: "30%", maxWidth: "500px" }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "30px !important",
          }}
        >
          Two factor authentication
        </Typography>
        <Typography sx={{ fontSize: "12", fontWeight: "bold" }}>
          Current Status
        </Typography>
        <StateSpan status={two_fa ? STATE_ENABLED : STATE_DISABLED} />
        <CPhoneInput
          label="Phone"
          countryCode={profile.nationality}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Typography sx={{ color: "grey", fontSize: "14px" }}>
          This is your default number. If you want to proceed with this number
          you only to have to click on activate 2fa. If you want to change the
          number, please insert the number in the field above. Note, if you
          change the number here, this modification it will be reflected in your
          profile contacts.
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{ justifyContent: "space-between", marginTop: "20px" }}
        >
          <Button
            variant="outlined"
            sx={classes.buttonCancel}
            onClick={() => setStage(0)}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={classes.buttonSave}
            onClick={onClickConfirm}
          >
            {two_fa ? "Disable" : "Enable"}
          </Button>
        </Stack>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="tfaModal" sx={style}>
          <Typography variant="h4">Two factor authentication</Typography>
          <Typography sx={{ mt: 2 }}>
            An {authMethod} was sent with a confirmation code. Enter the code to
            validate your registration.
          </Typography>
          <Typography variant="body2" sx={{ mt: 3, mb: 2.5 }}>
            Insert Code:
          </Typography>
          <AuthCode onChange={handleAuthCodeOnChange} ref={authRef} />
          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button variant="outlined" onClick={handleResendCode}>
              Re-send code
            </Button>
            <Button variant="contained" onClick={handleConfirm2fa}>
              Confirm
            </Button>
          </Stack>
          <Link
            style={{ display: "block", marginTop: "1.5rem" }}
            onClick={() => {
              setAuthMethod("Email");
              AuthenticationService.send2faMail({
                email: profile?.email,
              })
                .then((res) => {
                  if (res.data.message === SUCCESS)
                    showNotification(
                      "Verification code sent to email",
                      "success"
                    );
                })
                .catch((err) => {});
            }}
          >
            Send security code by email
          </Link>
          <Typography variant="body1" sx={{ mt: 1.5 }}>
            Need help? help@uco.network
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default TwoFactorView;
