// material ui

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";

// proj import
import { classes } from "../../../../utils/class";

const UnsavedDialog = ({ open, handleLeave, handleSave, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Stack spacing={3} sx={{ minWidth: "450px" }}>
          <Typography>Unsaved changes</Typography>
          <Typography>
            You have unsaved changes. Are you sure you want to leave anyway?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ margin: "20px" }}>
          <Button
            variant="outlined"
            sx={classes.buttonCancel}
            onClick={handleLeave}
          >
            Leave without saving
          </Button>
          <Button
            variant="contained"
            sx={classes.buttonSave}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedDialog;
