import moment from "moment-timezone";

export const formatDate = (data, timezone) => {
  try {
    const res = moment(data).tz(timezone).format("YYYY-MM-DD");
    return res;
  } catch (err) {
    return new Date(data).toLocaleDateString();
  }
};

export const getFormatedTime = (data, timezone) => {
  try {
    const res = moment(data).tz(timezone).getTime();
    return res;
  } catch (err) {
    return new Date(data).getTime();
  }
};
export const formatHours = (data) => {
  const date = new Date(data);
  const hour = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");
  const formattedHours = `${hour}:${min}`;
  return formattedHours;
};

export const strToDate = (date) => {
  const data = date.split("-");
  return new Date(parseInt(data[0]), parseInt(data[1]) - 1, parseInt(data[2]));
};

export const hmToDate = (timeString, timezone) => {
  if (timeString) {
    const [hours, minutes] = timeString.split(":");
    const dateObj = new Date();
    dateObj.setHours(hours);
    dateObj.setMinutes(minutes);
    try {
      const res = moment(dateObj.getTime()).tz(timezone, true).getTime();
      return res;
    } catch (err) {
      return timeString;
    }
  } else return null;
};

export const getDays = (timezone, d) => {
  if (timezone !== undefined) {
    const now = Date.now();
    let data = [];
    for (let i = 0; i < d; i++) {
      data.push(formatDate(now + i * 86400000, timezone));
    }
    return data;
  }
  return [];
};

export const getDaysFromTo = (from, timezone) => {
  const beginDate = strToDate(from).getTime();
  const endDate = Date.now() + 7 * 86400000;
  let data = [];
  for (let i = beginDate; i <= endDate; i += 86400000) {
    data.push(formatDate(i, timezone));
  }
  return data;
};

export const convertDate = (date) => {
  const Months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  let t = date.split("-");
  return t[2] + " " + Months[~~t[1] - 1];
};
