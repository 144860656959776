import React from "react";

// material-ui
import { Box, Button, Container, Stack, Typography } from "@mui/material";

// project imports
import { PageTitle } from "../../../components/Typography";

// sx
import { classes } from "../../../utils/class";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../../components/Table";
import { useSelector } from "react-redux";
import { getDateStringFromNum } from "../../../utils/helpers";
import { useSnackbar } from "notistack";

const DistributeResumePage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const rowData = useSelector((state) => state.transfer.resume);
  const orgData = useSelector((state) => state.organizations.profile);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (props) =>
        getDateStringFromNum(props.row.date, orgData.timezone),
    },
    { field: "origin_name", headerName: "Origin", width: 150 },
    { field: "vehicle_name", headerName: "Vehicle", width: 200 },
    {
      field: "destination_warehouse_name",
      headerName: "Destination",
      width: 200,
    },
    {
      field: "transfer",
      headerName: "Transfer",
      width: 200,
      renderCell: (props) => {
        const { quantity, ratio } = props.row;
        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: "60px" }}>{quantity} kg</Typography>
            <Typography sx={{ color: ratio >= 0 ? "green" : "red" }}>
              {ratio > 0 && "+"}
              {ratio}%
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
    { field: "vehicle_driver_name", headerName: "Driver", width: 100 },
  ];

  const onClickConfirm = () => {
    enqueueSnackbar("Not implemented yet", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      autoHideDuration: 2000,
    });
    navigate(-1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "23px",
        gap: "8px",
        padding: "20px",
        paddingX: "60px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
          paddingX: "60px",
        }}
      >
        <PageTitle>Resume</PageTitle>
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            sx={classes.buttonCancel}
            onClick={() => navigate("/transfer")}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={classes.buttonSave}
            onClick={onClickConfirm}
          >
            Confirm
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ padding: "20px" }}>
          <DataTable
            fit="100%"
            breakpoint="xl"
            rows={rowData}
            sx={classes.grid}
            columns={columns}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DistributeResumePage;
