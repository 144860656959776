import {
  Modal,
  Stack,
  Typography,
  IconButton,
  Button,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { classes } from "../../utils/class";
import { PageTitle } from "../Typography";
import { showNotification } from "../../utils/helpers";
import { SearchBar } from "../CustomInput";
import { DataTable } from "../Table";
import { useEffect, useState } from "react";
import { GridLogicOperator } from "@mui/x-data-grid";
import { useSelector } from "react-redux";

export const RawWastesModal = ({
  open,
  title,
  data = [],
  handleCancel,
  handleClose,
  handleSave,
}) => {
  const { raw_wastes } = useSelector((state) => {
    return state.waste;
  });
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [appliedFilterModal, setAppliedFilterModal] = useState({ items: [] });
  const [allCheck, setAllCheck] = useState(false);
  const [itemsChecked, setItemsChecked] = useState([]);
  const [wastesChecked, setWastesChecked] = useState([]);

  useEffect(() => {
    setAllCheck(false);
    setItemsChecked(data);
    const result = raw_wastes.filter((item) => data.includes(item._id));
    const temp = result.map((item, index) => item.name);
    setWastesChecked(temp);
  }, [open]);

  useEffect(() => {
    const result = raw_wastes.filter((item) => itemsChecked.includes(item._id));
    const temp = result.map((item, index) => item.name);
    setWastesChecked(temp);
  }, [itemsChecked]);

  useEffect(() => {
    if (filterModel.items.length > 0) {
      const handler = setTimeout(() => {
        setAppliedFilterModal({
          items: [...filterModel.items],
        });
      }, 300);

      return () => {
        clearInterval(handler);
      };
    } else {
      setAppliedFilterModal({
        items: [...filterModel.items],
      });
    }
  }, [filterModel]);

  const columns = [
    {
      field: "checkbox",
      hideSortIcons: true,
      disableColumnMenu: true,
      width: 100,
      headerName: (
        <Checkbox
          sx={classes.checkBox}
          checked={allCheck}
          onClick={(e) => {
            e.stopPropagation();
            if (allCheck) {
              setAllCheck(false);
              setItemsChecked([]);
            } else {
              setAllCheck(true);
              setItemsChecked(raw_wastes.map((t) => t._id));
            }
          }}
        />
      ),
      renderCell: (props) => (
        <Checkbox
          sx={classes.checkBox}
          checked={itemsChecked.indexOf(props.row._id) !== -1}
          onClick={(e) => {
            e.stopPropagation();
            const id = itemsChecked.indexOf(props.row._id);
            const temp = [...itemsChecked];
            if (id !== -1) {
              temp.splice(id, 1);
            } else {
              temp.push(props.row._id);
            }
            setItemsChecked(temp);
          }}
        />
      ),
    },
    {
      field: "code",
      headerName: "LER Code",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
    },
    {
      field: "capacity",
      headerName: "C.racio(l-kg)",
      width: 100,
    },
  ];

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Stack sx={classes.popup}>
          <Stack>
            <PageTitle>{title}</PageTitle>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "18px",
                letterSpacing: "-1%",
                paddingTop: "8px",
              }}
            >
              Select the types of waste that this collection point will handle
            </Typography>
          </Stack>
          <IconButton
            onClick={() => {
              handleClose();
            }}
            sx={{
              position: "absolute",
              right: "42px",
              top: "34px",
            }}
          >
            <CloseIcon />
          </IconButton>

          <SearchBar
            placeholder="Search raw waste"
            onSearchChange={(value) => {
              setFilterModel({
                items: [
                  {
                    id: 1,
                    field: "name",
                    operator: "contains",
                    value: value,
                  },
                  // {
                  //   id: 2,
                  //   field: "code",
                  //   operator: "contains",
                  //   value: value,
                  // },
                ],
                logicOperator: GridLogicOperator.Or,
              });
            }}
          />
          <DataTable
            fit="100%"
            filterModel={appliedFilterModal}
            setFilterModel={setAppliedFilterModal}
            columns={columns}
            rows={raw_wastes}
            autoHeight={true}
          />

          <Stack gap={2} direction={"row"} justifyContent={"flex-end"}>
            <Button
              sx={classes.buttonCancel}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              sx={classes.buttonSave}
              onClick={() => {
                showNotification(
                  "The session was saved successfully.",
                  "success"
                );
                handleSave(itemsChecked, wastesChecked);
              }}
            >
              Save and next
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
