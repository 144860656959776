import { useState } from "react";
import { DataTable } from "../../../components/Table";
import { CModalSave } from "../../../components/CustomModal";
import { Box, Stack } from "@mui/material";
import { setCurrentWarehouse } from "../../../slices/inventorySlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  updateInStockSubTotal,
  updateSubTotal,
} from "../../../actions/inventory";
const SubInventory = ({ inventoryId, data }) => {
  const [rows, setRows] = useState([...data]);
  const [newRow, setNewRow] = useState(null);
  const [currentRow, setAnchorEL] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);

  const dispatch = useDispatch();

  const popupClose = () => {
    setPopupOpen(false);
  };

  const processRowUpdate = (newRowUpdated) => {
    setPopupOpen(true);
    setNewRow(newRowUpdated);
    const updatedRow = { ...newRowUpdated, isNew: false };
    return updatedRow;
  };

  const saveUpdatedResult = () => {
    const updatedRow = { ...newRow, isNew: false };
    let in_stock = null;
    let subtotal = null;
    for (let row of rows) {
      if (row.id === newRow.id) {
        if (row.in_stock !== newRow.in_stock) {
          in_stock = newRow.in_stock;
          break;
        }
        if (row.subtotal !== newRow.subtotal) {
          subtotal = newRow.subtotal;
          break;
        }
      }
    }
    if (in_stock !== null) {
      dispatch(updateInStockSubTotal(inventoryId, newRow.id, { in_stock }));
    } else if (subtotal !== null) {
      dispatch(updateSubTotal(inventoryId, newRow.id, { subtotal }));
    }
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
  };
  const cancelUpdatedResult = () => {
    setRows(rows.map((row) => row));
  };
  const onCellEditStart = (params, event) => {
    setAnchorEL(event.currentTarget);
  };
  const columns = [
    {
      field: "name",
      headerName: "Location",
      width: 300,
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center", paddingLeft: "20px" }}>
            {params.row.name}
          </div>
        );
      },
    },
    {
      field: "in_stock",
      headerName: "In stock",
      width: 200,
      // editable: true,
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      width: 200,
      //editable: true,
    },
    {
      field: "action",
      headerName: "",
      width: 400,
      renderCell: (params) => {
        return (
          <>
            <Stack
              onClick={() => {
                dispatch(setCurrentWarehouse(params.row.warehouse));
              }}
            >
              <Link to={`/inventory-sublist/${params.row.id}`}> List all </Link>
            </Stack>
          </>
        );
      },
    },
  ];

  return (
    <Box paddingLeft={10}>
      <DataTable
        editable="cell"
        rows={rows}
        columns={columns}
        pagination={false}
        processRowUpdate={processRowUpdate}
        onCellEditStart={onCellEditStart}
      />
      <CModalSave
        anchorEl={currentRow}
        open={popupOpen}
        handleClose={popupClose}
        onSave={saveUpdatedResult}
        onCancel={cancelUpdatedResult}
      />
    </Box>
  );
};
export default SubInventory;
