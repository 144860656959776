import VisitService from "../services/VisitService";
import {
  setCurrentScheduled,
  setScheduledVisitsList,
  setSelectedVisit,
  setTotalCollected,
  setVisitsList,
} from "../slices/visitsSlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";

export const fetchVisitsList =
  (scheduled, callBack = () => {}) =>
  (dispatch) => {
    VisitService.fetchVisitsList(scheduled)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          if (scheduled) {
            const data = res.data.data.map((row) => {
              return { id: row._id, ...row };
            });
            dispatch(setScheduledVisitsList(data));
          } else {
            const data = res.data.data.data.map((row) => {
              return { id: row._id, ...row };
            });
            dispatch(setVisitsList(data));
            dispatch(setTotalCollected(res.data.data.total_collected));
          }
          callBack();
        }
      })
      .catch((err) => {});
  };

export const fetchVisitDetail = (id) => (dispatch) => {
  VisitService.fetchVisitDetail(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setSelectedVisit(res.data.data));
      }
    })
    .catch((err) => {});
};

export const createVisit = (data, fallback) => (dispatch) => {
  VisitService.createVisit(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchVisitsList(true), dispatch);
        dispatch(setCurrentScheduled(null));
        showNotification("Created successfully", "success");
        fallback();
      }
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const editVisitScheduled = (id, data, fallback) => (dispatch) => {
  VisitService.editVisitScheduled(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchVisitsList(true), dispatch);
        dispatch(setCurrentScheduled(null));
        showNotification("Updated successfully", "success");
        fallback();
      }
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const updateVisitStatus = (id, data) => (dispatch) => {
  VisitService.updateVisitStatus(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchVisitsList(true));
      }
    })
    .catch((err) => {
      showNotification(ERROR[err.message] || err.message, "error");
    });
};

export const rescheduleVisit = (id, data) => (dispatch) => {
  VisitService.rescheduleVisit(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchVisitsList(true));
      }
    })
    .catch((err) => {
      showNotification(err.message, "error");
    });
};
