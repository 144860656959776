import { Button, IconButton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { InputTitle, PageTitle } from "./Typography";
import { CPhoneInput, CTextInput, CTimeInput, CheckBoxes } from "./CustomInput";
import { classes } from "../utils/class";

import { formatHours, hmToDate } from "../utils/date";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { CustomMap } from "./CustomMap";

export const LocationModal = ({
  title,
  data,
  description,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [location_data, setLocationData] = useState({
    addr_street: data?.addr_street,
    addr_apartment: data?.addr_apartment,
    addr_city: data?.addr_city,
    addr_postcode: data?.addr_postcode,
    addr_country: data?.addr_country,
    latitude: data?.latitude,
    longitude: data?.longitude,
  });

  useEffect(() => {
    if (data) {
      setLocationData({
        addr_street: data?.addr_street,
        addr_apartment: data?.addr_apartment,
        addr_city: data?.addr_city,
        addr_postcode: data?.addr_postcode,
        addr_country: data?.addr_country,
        latitude: data?.latitude,
        longitude: data?.longitude,
      });
    }
  }, [data]);

  return (
    <Stack sx={classes.locationPopup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <CustomMap
        description={description}
        data={location_data}
        onChange={(d) => {
          setLocationData(d);
        }}
      />
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler(location_data);
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const ScheduleModal = ({
  title,
  extraData,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [opening_time, setOpeningTime] = useState(dayjs("09:00", "HH:mm"));
  const [closing_time, setClosingTime] = useState(dayjs("17:00", "HH:mm"));
  const [restrictions_from_time, setRestrictionsFrom] = useState(
    dayjs("00:00", "HH:mm")
  );
  const [restrictions_to_time, setRestrictionsTo] = useState(
    dayjs("00:00", "HH:mm")
  );
  const [days_off, setDaysOff] = useState([]);
  const orgData = useSelector((state) => state.organizations.profile);
  useEffect(() => {
    if (extraData?.opening_time)
      setOpeningTime(
        dayjs(hmToDate(extraData?.opening_time, orgData.timezone))
      );
    if (extraData?.closing_time)
      setClosingTime(
        dayjs(hmToDate(extraData?.closing_time, orgData.timezone))
      );
    if (extraData?.restrictions_from_time)
      setRestrictionsFrom(
        dayjs(hmToDate(extraData?.restrictions_from_time, orgData.timezone))
      );
    if (extraData?.restrictions_to_time)
      setRestrictionsTo(
        dayjs(hmToDate(extraData?.restrictions_to_time, orgData.timezone))
      );
    setDaysOff(extraData?.days_off || []);
  }, [extraData, orgData.timezone]);
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack gap={1}>
        <InputTitle sx={{ fontSize: "20px" }}>Working hours</InputTitle>
        <Stack direction={"row"} gap={1}>
          <CTimeInput
            title="Opening time"
            required
            value={opening_time}
            onChange={(value) => {
              setOpeningTime(value);
            }}
          />
          <CTimeInput
            title="Closing time"
            required
            value={closing_time}
            onChange={(value) => {
              setClosingTime(value);
            }}
          />
        </Stack>
      </Stack>

      <Stack gap={1}>
        <InputTitle sx={{ fontSize: "20px" }}>
          Collection restrictions
        </InputTitle>
        <Stack direction={"row"} gap={1}>
          <CTimeInput
            title="Opening time"
            required
            value={restrictions_from_time}
            onChange={(value) => {
              setRestrictionsFrom(value);
            }}
          />
          <CTimeInput
            title="Closing time"
            required
            value={restrictions_to_time}
            onChange={(value) => {
              setRestrictionsTo(value);
            }}
          />
        </Stack>
      </Stack>
      <Stack gap={1}>
        <InputTitle sx={{ fontSize: "20px" }}>Days off</InputTitle>
        <CheckBoxes
          title="Select days off"
          selectedOptions={days_off}
          onChange={(option, checked) => {
            if (checked) {
              setDaysOff([...days_off, option]);
            } else {
              const d = [...days_off];
              const index = days_off.indexOf(option);
              d.splice(index, 1);
              setDaysOff(d);
            }
          }}
          options={["mon", "tue", "wed", "thu", "fri", "sat", "sun"]}
        />
      </Stack>
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({
              opening_time: formatHours(opening_time),
              closing_time: formatHours(closing_time),
              restrictions_from_time: formatHours(restrictions_from_time),
              restrictions_to_time: formatHours(restrictions_to_time),
              days_off: days_off,
            });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const ContactsModal = ({
  title,
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [phone, setPhone] = useState();
  const [mobile_phone, setMobilePhone] = useState();
  const [contact, setContact] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    setPhone(data?.phone);
    setMobilePhone(data?.mobile_phone);
    setContact(data?.contact);
    setEmail(data?.email);
  }, [data]);

  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>

      <CPhoneInput
        fullWidth
        label="Phone"
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
      />
      <CPhoneInput
        fullWidth
        label="Mobile Phone"
        onChange={(e) => {
          setMobilePhone(e.target.value);
        }}
        value={mobile_phone}
      />
      <CTextInput
        title="NAME"
        placeholder="Name"
        value={contact}
        onChange={(value) => {
          setContact(value);
        }}
      />
      <CTextInput
        title="Email"
        placeholder="mspot@email.com"
        value={email}
        onChange={(value) => {
          setEmail(value);
        }}
      />
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({ phone, mobile_phone, contact, email });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
