import ZoneSerivce from "../services/ZoneService";

const core = (latitude, longitude) => {
  return new Promise(async (resolve, reject) => {
    try {
      let country = "";
      let city = "";
      let apartment = "";
      let postcode = "";
      let street = "";
      const res = await ZoneSerivce.getMap(
        `geocode/json?latlng=${latitude},${longitude}&sensor=false`
      );
      const data = res.data.data;

      if (!data?.results) return;
      const dat = data.results[0]?.address_components;
      let route = null;
      let street_number = null;
      if (!dat) return;
      dat.forEach((info) => {
        if (info.types.indexOf("country") !== -1) country = info.long_name;
        if (info.types.indexOf("postal_code") !== -1) postcode = info.long_name;

        if (info.types.indexOf("locality") !== -1) city = info.long_name;
        if (info.types.indexOf("subpremise") !== -1) apartment = info.long_name;
        if (info.types.indexOf("route") !== -1) route = info.long_name;
        if (info.types.indexOf("street_number") !== -1)
          street_number = info.long_name;
      });
      if (street_number && route) street = street_number + " " + route;
      else if (route) street = route;
      resolve({
        country,
        city,
        apartment,
        postcode,
        street,
      });
    } catch (err) {
      reject(err);
    }
  });
};

const findPlacesByText = (keyword) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await ZoneSerivce.getMap(
        `place/findplacefromtext/json?fields=geometry&input=${keyword}&inputtype=textquery`
      );
      const data = res.data.data;
      if (data.candidates.length > 0) {
        const location = data.candidates[0].geometry.location;
        const lat = location.lat;
        const lng = location.lng;
        return resolve({
          lat,
          lng,
        });
      }
      return reject();
    } catch (err) {
      return reject();
    }
  });
};

const updateLatitudeAndLongitude = (
  street = "",
  apartment = "",
  city = "",
  postcode = "",
  country = ""
) => {
  const address =
    street + ", " + apartment + ", " + city + ", " + postcode + ", " + country;

  return new Promise(async (resolve, reject) => {
    try {
      const res = await ZoneSerivce.getMap(
        `geocode/json?address=${encodeURIComponent(address)}&key=${
          process.env.REACT_APP_GMAP_API
        }&sensor=false`
      );
      const data = res.data.data;
      if (data.results.length > 0) {
        const location = data.results[0].geometry.location;
        const lat = location.lat;
        const lng = location.lng;
        resolve({
          status: true,
          latitude: lat,
          longitude: lng,
        });
      } else {
        resolve({ status: false });
      }
    } catch (err) {
      reject(err);
    }
  });
};

const getFilteredPlaces = (
  keyword,
  latitude,
  longitude,
  radius = 5000,
  type = "restaurant|cafe|bakery|lodging|neighborhood|street_address|city|country"
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await ZoneSerivce.getMap(
        `place/textsearch/json?query=${keyword}&location=${latitude},${longitude}&radius=${radius}&type=${type}`
      );
      if (res.data.data.results !== undefined) resolve(res.data.data.results);
      else resolve([]);
    } catch (err) {
      reject(err);
    }
  });
};

const getGoogleMapImage = (reference, maxWidth = 1000, maxHeight = 200) => {
  const query = `place/photo?maxheight=${maxHeight}&photo_reference=${reference}`;
  return `https://maps.googleapis.com/maps/api/${query}&key=${process.env.REACT_APP_GMAP_API}`;
};

// const getGoogleMapImage = (reference, maxWidth = 400) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       // const res = await ZoneSerivce.getMap(
//       //   `place/photo?maxwidth=${maxWidth}&photo_reference=${reference}`
//       // );
//       // const base64Data = Buffer.from(res.data.data, "binary").toString(
//       //   "base64"
//       // );
//       // resolve(`data:image/png;base64, ${base64Data}`);
//       const query = `place/photo?maxwidth=${maxWidth}&photo_reference=${reference}`;
//       resolve(
//         `https://maps.googleapis.com/maps/api/${query}&key=${process.env.REACT_APP_GMAP_API}`
//       );
//     } catch (err) {
//       reject(err);
//     }
//   });
// };
const getPlaceDetails = (place_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await ZoneSerivce.getMap(
        `place/details/json?place_id=${place_id}`
      );
      resolve(res.data.data.result);
    } catch (err) {
      reject(err);
    }
  });
};
function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371e3; // Earth's radius in meters
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance;
}
function calculateZoomLevel(distance, screenPixelDistance) {
  const zoomLevel = Math.log2((156543.03392 * screenPixelDistance) / distance);
  return zoomLevel;
}
const getCenterFromPoints = (points) => {
  if (points.length === 0) return null;
  else if (points.length === 1)
    return {
      center: points[0],
      zoom: 20,
    };
  else {
    let point = points[0];
    for (let pt of points) {
      point = {
        latitude: (point.latitude + pt.latitude) / 2,
        longitude: (point.longitude + pt.longitude) / 2,
      };
    }
    let distance = 0;
    for (let pt of points) {
      let d = calculateDistance(
        pt.latitude,
        pt.longitude,
        point.latitude,
        point.longitude
      );
      distance = d > distance ? d : distance;
    }

    return {
      center: point,
      distance: distance,
    };
  }
};

export {
  findPlacesByText,
  calculateZoomLevel,
  getCenterFromPoints,
  core,
  getGoogleMapImage,
  getPlaceDetails,
  updateLatitudeAndLongitude,
  getFilteredPlaces,
};
