import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, IconButton, Modal, Stack } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import {
  CAutoComplete,
  CPhoneInput,
  CTextInput,
} from "../../../../components/CustomInput";
import CloseIcon from "@mui/icons-material/Close";
import { classes } from "../../../../utils/class";
import { updateWarehouseInfo } from "../../../../actions/warehouse";
import {
  fetchAllUsers,
  fetchOrganizationInfo,
} from "../../../../actions/organizations";
import OrganizationService from "../../../../services/OrganizationService";
import { SUCCESS } from "../../../../utils/type";

const ResponsibleDialog = ({ open, onClose, info }) => {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.warehouse.current.id);
  const users = useSelector((state) => state.organizations.users);
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [validation, setValidataion] = useState({
    email: false,
  });
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [responsible, setResponsible] = useState("");

  const checkValidation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setValidataion({ email: true });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!checkValidation()) return false;
    dispatch(
      updateWarehouseInfo(id, {
        responsible: selectedUser?.name || responsible,
        resp_phone: phone,
        resp_mobile_phone: mobile,
        resp_email: email,
      })
    );
    onClose();
  };

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, []);
  useEffect(() => {
    setUserData(
      users.map((user) => {
        return { name: user.name, value: user.name };
      })
    );
  }, [users]);
  useEffect(() => {
    setPhone(info.resp_phone);
    setMobile(info.resp_mobile_phone);
    setEmail(info.resp_email);
    setSelectedUser(info.responsible);
  }, [info]);

  const onChangeEmail = (v) => {
    setValidataion({ email: false });
    setEmail(v);
  };

  const onResponsibleUserChange = (v) => {
    setSelectedUser(v);
    const user = users.find((user) => user.name === v.name);
    OrganizationService.getMemberInfo(user._id).then((res) => {
      if (res.data.message === SUCCESS) {
        setPhone(res.data.data.phone || "");
        setEmail(res.data.data.email || "");
        setMobile(res.data.data.mobile_phone || "");
      }
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack padding={"34px 42px"} gap={"22px"} sx={classes.popup}>
        <PageTitle>Responsible</PageTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "42px",
            top: "34px",
          }}
        >
          <CloseIcon />
        </IconButton>
        <CAutoComplete
          title="Responsible"
          options={userData}
          value={selectedUser}
          placeholder="Select Responsible"
          onInput={(e) => {
            setSelectedUser(null);
            setResponsible(e.target.value);
          }}
          onChange={onResponsibleUserChange}
          fixed
        />
        <CPhoneInput
          label="Phone"
          value={phone}
          fullWidth
          onChange={(e) => setPhone(e.target.value)}
        />
        <CPhoneInput
          label="Mobile Phone"
          value={mobile}
          fullWidth
          onChange={(e) => setMobile(e.target.value)}
        />
        <CTextInput
          error={validation.email}
          title="Email"
          value={email}
          onChange={onChangeEmail}
        />
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={onClose}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ResponsibleDialog;
