import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";

import { EditDetail, StateSpan } from "../../../../components/StateSpan";
import { DataTable } from "../../../../components/Table";
import { CardPrimary } from "../../../../components/Card";
import { CRatio } from "../../../../components/CustomInput";
import AddDepositDialog from "../Dialog/AddDepositDialog";
import EditDepositDialog from "../Dialog/EditDepositDialog";
import RemoveDepositDialog from "../Dialog/RemoveDepositDialog";
import ChangeStatusDialog from "../Dialog/ChangeStatusDialog";
import { classes } from "../../../../utils/class";
import { WAREHOUSE_MANAGER } from "../../../../utils/constants";
import {
  STATE_ACTIVE,
  STATE_ARCHIVE,
  STATE_INACTIVE,
} from "../../../../utils/type";
import { setAddDepositStep } from "../../../../slices/pickupSlice";
import { setCurrentWaste } from "../../../../slices/visitsSlice";
import {
  activateDeposit,
  archiveDeposit,
  deactivateDeposit,
  unarchiveDeposit,
} from "../../../../actions/pickup";

const Deposits = ({ id, deposits, extraInfo }) => {
  const [addDlgOpen, setAddDlgOpen] = useState(false);
  const [editDlgOpen, setEditDlgOpen] = useState(false);
  const [removeDlgOpen, setRemoveDlgOpen] = useState(false);
  const [current, setCurrent] = useState(null);
  const [changeDlgOpen, setChangeDlgOpen] = useState(false);
  const addDepositStep = useSelector((state) => state.pickup.add_deposit);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);

  const columns = [
    {
      field: "raw_waste_name",
      headerName: "Raw Waste",
      width: 300,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 350,
      renderCell: (props) => {
        return (
          <Stack direction="row" sx={{ alignItems: "center" }} gap={2}>
            <Typography sx={{ width: "80px" }}>
              {props.row.capacity} kg
            </Typography>
            <CRatio percent={props.row.ratio} />
            <Typography
              sx={{ color: props.row.ratio === 100 ? "red" : "black" }}
            >
              {Number(props.row.ratio).toFixed(2)}%
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "frequency",
      headerName: "Frequency",
      renderCell: (props) => {
        return <>{props.value ? props.value + " days" : "-"} </>;
      },
    },
    {
      field: "average",
      headerName: "Average Purity",
      width: 150,
      renderCell: (props) => {
        return <>{"-"} </>;
      },
    },
    {
      field: "next_picking_date",
      headerName: "Next Collection",
      width: 150,
      renderCell: (props) => {
        return <>{props.value ? props.value + " days" : "-"} </>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (props) => {
        let options = [];
        if (role !== WAREHOUSE_MANAGER) {
          if (props?.row?.status === "active") {
            options = [
              {
                label: "Inactive",
                value: "inactive",
              },
            ];
          } else if (props?.row?.status === "inactive") {
            if (props?.row?.archived) {
              options = [
                {
                  label: "Unarchive",
                  value: "unarchive",
                },
              ];
            } else {
              options = [
                {
                  label: "Active",
                  value: "active",
                },
                {
                  label: "Archive",
                  value: "archive",
                },
              ];
            }
          }
        }
        return (
          <>
            <StateSpan
              disabled={role === WAREHOUSE_MANAGER}
              status={props.row.archived ? STATE_ARCHIVE : props.row.status}
              options={options}
              onStateChangeHandler={(status) => {
                if (
                  status === "active" &&
                  props.row.status === STATE_INACTIVE
                ) {
                  dispatch(activateDeposit(props.row.id, id));
                } else if (
                  status === "inactive" &&
                  props.row.status === STATE_ACTIVE
                ) {
                  setCurrent(props.row.id);
                  setChangeDlgOpen(true);
                } else if (status === "archive")
                  dispatch(archiveDeposit(props.row.id, id));
                else if (status === "unarchive")
                  dispatch(unarchiveDeposit(props.row.id, id));
              }}
            />
            <EditDetail
              options={[
                { label: "Edit capacity", value: "edit" },
                { label: "Remove capacity", value: "remove" },
                { label: "Add visit", value: "add" },
              ]}
              props={props}
              onStateChangeHandler={async (status) => {
                setCurrent(props.row.id);
                if (status === "edit") setEditDlgOpen(true);
                if (status === "remove") setRemoveDlgOpen(true);
                if (status === "add") {
                  dispatch(setCurrentWaste(props.row.raw_waste));
                  navigate("/add-visit-log");
                }
              }}
              disabled={role === WAREHOUSE_MANAGER ? true : false}
            />
          </>
        );
      },
    },
  ];

  const deactivateDepositConfirm = () => {
    dispatch(deactivateDeposit(current, id));
    setChangeDlgOpen(false);
  };

  useEffect(() => {
    if (addDepositStep) setAddDlgOpen(true);
  }, [addDepositStep]);

  return (
    <Stack>
      {deposits.length === 0 ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              marginTop: "200px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "400px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="54"
              height="66"
              viewBox="0 0 54 66"
              fill="none"
            >
              <path
                d="M52.5 59.25C52.5 59.6147 52.1844 60.2437 50.8855 61.0186C49.651 61.7551 47.798 62.4534 45.4204 63.0534C40.6806 64.2494 34.0791 65 26.75 65C19.4209 65 12.8194 64.2494 8.07956 63.0534C5.70197 62.4534 3.84901 61.7551 2.61449 61.0186C1.31563 60.2437 1 59.6147 1 59.25C1 58.8853 1.31563 58.2563 2.61449 57.4814C3.84901 56.7449 5.70197 56.0466 8.07956 55.4466C12.8194 54.2506 19.4209 53.5 26.75 53.5C34.0791 53.5 40.6806 54.2506 45.4204 55.4466C47.798 56.0466 49.651 56.7449 50.8855 57.4814C52.1844 58.2563 52.5 58.8853 52.5 59.25Z"
                stroke="#DBDBDB"
                strokeWidth="2"
              />
              <path
                d="M1.25 58.75V7.5C1.25 7.5 9.25 12.75 26.875 12.75C44.5 12.75 52.5 7.5 52.5 7.5V58.75C52.5 58.75 51.25 65 26.875 65C2.5 65 1.25 58.75 1.25 58.75Z"
                fill="#F5F5F5"
                stroke="#DBDBDB"
                strokeWidth="2"
              />
              <path
                d="M1.25 27.5C1.25 27.5 9.25 32.75 26.875 32.75C44.5 32.75 52.5 27.5 52.5 27.5"
                stroke="#DBDBDB"
                strokeWidth="2"
              />
              <path
                d="M1.25 42.5C1.25 42.5 9.25 47.75 26.875 47.75C44.5 47.75 52.5 42.5 52.5 42.5"
                stroke="#DBDBDB"
                strokeWidth="2"
              />
              <path
                d="M52.5 6.75C52.5 7.11472 52.1844 7.74374 50.8855 8.51862C49.651 9.25513 47.798 9.95341 45.4204 10.5534C40.6806 11.7494 34.0791 12.5 26.75 12.5C19.4209 12.5 12.8194 11.7494 8.07956 10.5534C5.70197 9.95341 3.84901 9.25513 2.61449 8.51862C1.31563 7.74374 1 7.11472 1 6.75C1 6.38528 1.31563 5.75627 2.61449 4.98138C3.84901 4.24487 5.70197 3.54659 8.07956 2.94664C12.8194 1.75059 19.4209 1 26.75 1C34.0791 1 40.6806 1.75059 45.4204 2.94664C47.798 3.54659 49.651 4.24487 50.8855 4.98138C52.1844 5.75627 52.5 6.38528 52.5 6.75Z"
                stroke="#DBDBDB"
                strokeWidth="2"
              />
              <ellipse
                cx="39.25"
                cy="6.75"
                rx="2.25"
                ry="0.75"
                fill="#DBDBDB"
              />
            </svg>
            <Typography
              sx={{
                paddingY: 1,
                textAlign: "center",
                fontFamily: "Manrope",
                fontSize: "10px",
                color: "text.primary",
              }}
            >
              There are no capacities listed
            </Typography>

            <Button
              onClick={() => setAddDlgOpen(true)}
              sx={classes.buttonPrimary}
            >
              Add capacity
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
            <CardPrimary type={1} title={"Balance"} content={0} />
            <CardPrimary
              type={2}
              title={"Next collection"}
              content={`In ${extraInfo?.next_picking || 0} days`}
            />
            <CardPrimary
              type={3}
              title={"Expiring documents"}
              content={extraInfo?.count_expiring_documents || 0}
            />
          </Stack>
          <Box sx={{ padding: "20px" }}>
            <DataTable
              rows={deposits.map((deposit) => {
                return {
                  ...deposit,
                  id: deposit._id,
                };
              })}
              breakpoint="xl"
              fit="100%"
              columns={columns}
              sx={classes.grid}
              onRowClick={(params, events, details) => {}}
            />
          </Box>
        </>
      )}

      <EditDepositDialog
        producerId={extraInfo?.producer}
        id={id}
        open={editDlgOpen}
        onClose={() => setEditDlgOpen(false)}
        deposit={deposits.find((d) => d._id === current)}
      />
      <RemoveDepositDialog
        open={removeDlgOpen}
        pickupId={id}
        id={current}
        onClose={() => setRemoveDlgOpen(false)}
      />
      <ChangeStatusDialog
        open={changeDlgOpen}
        onClose={() => setChangeDlgOpen(false)}
        onConfirm={deactivateDepositConfirm}
        type="raw waste"
      />
      <AddDepositDialog
        producerId={extraInfo?.producer}
        id={id}
        open={addDlgOpen}
        onClose={() => {
          setAddDlgOpen(false);
          dispatch(setAddDepositStep(false));
        }}
      />
    </Stack>
  );
};

export default Deposits;
