import { createTheme } from "@mui/material/styles";

const palette = {
  palette: {
    text: {
      primary: "#23222D",
      secondary: "#282828",
      // light: "#36354680",
      light: "#363546E0",
      lighter: "#363546",
      contrast: "#ffffff",
      brown: "#23222D",
      tertiary: "#DBDBDB",
      accent: "#AFAFAF",
      orgheader: "#131313",
    },
    background: {
      primary: "#ABABAB",
      secondary: "#6D6D6D",

      light: "#C9C9C9",
      bright: "#DBE6EF",
      stone: "#A8A19F",
      lightblue: "#E3EDF5",
      lighterblue: "#E0F0F9",
      brown: "#23222D",
      gray: "#F0F0F0",
      lighter: "#F0F0F0",
    },
    warnning: {
      primary: "#F8C71D",
      secondary: "#D64C56",
      third: "#A62A36",
    },
    success: {
      main: "#2CB53B",
      primary: "#1AA52B",
    },
    border: {
      primary: "#E1E1E1",
      secondary: "#CFCBC9",
      light: "#F0F0F0",
    },
    status: {
      active: {
        bgcolor: "#2CB53B1A",
        textcolor: "#2CB53B",
      },
      inactive: {
        bgcolor: "#D64C561A",
        textcolor: "#D64C56",
      },
      archived: {
        bgcolor: "#1169DC1A",
        textcolor: "#1169DC",
      },
      ongoing: {
        bgcolor: "#E08C0E1A",
        textcolor: "#E08C0E",
      },
      discarded: {
        bgcolor: "#2828281A",
        textcolor: "#282828",
      },
    },
    badge: {
      offer: "#1169DC",
      deposit: "#6D6D6D",
      driver: "#AC890B",
      client: "#23465F",
      generic: "#F8C71D",
      unique: "#D64C56",
      loaned: "#005609",
      warehouse: "#A78407",
    },
    list: {
      primary: "#F7F6F3",
      selected: "#FFFFFF",
    },
    card: {
      primary1: "#23222D",
      primary2: "#00195A",
      primary3: "#C0D4E4",
      primary4: "#E7E6E3",
    },
  },
};

const theme = createTheme({
  ...palette,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#23222D",
          color: "white",
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#363546",
            borderLeft: "4px solid #EBF155",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },
});

export default theme;
