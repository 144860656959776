import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
  Checkbox,
} from "@mui/material";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import { useSnackbar } from "notistack";

import {
  BigTitle,
  PageTitle,
  SubTitle,
} from "../../../../components/Typography";
import {
  CAutoComplete,
  COfficialIdInput,
  CPhoneInput,
  CTextInput,
  SearchBar,
} from "../../../../components/CustomInput";
import { DataTable } from "../../../../components/Table";
import { EditDetail } from "../../../../components/StateSpan";
import { EmptyWizard } from "../../../../components/wizard/Card";
import {
  OrganizationIcon,
  PickupIcon,
} from "../../../../components/icons/menu_icons/icons";
import { CustomMap } from "../../../../components/CustomMap";
import {
  showNotification,
  snackbarRef,
  validateEmail,
} from "../../../../utils/helpers";
import { classes } from "../../../../utils/class";
import { SUCCESS } from "../../../../utils/type";
import { summaryString } from "../../../../utils/string";
import { ERROR } from "../../../../utils/constants";
import { fetchRawWastes } from "../../../../actions/organizations";
import { fetchProducers } from "../../../../actions/producers";
import { createZone, fetchZoneList } from "../../../../actions/zone";
import ProducerService from "../../../../services/ProducerService";
import PickupService from "../../../../services/PickupService";
import AddDepositDialog from "./AddDepositDialog";
import RemoveDepositDialog from "./RemoveDepositDialog";
import EditDepositDialog from "./EditDepositDialog";

const ListPickups = ({
  pickups,
  onDeleteHandler = () => {},
  onEditHandler = () => {},
}) => {
  return (
    <Stack
      width={"100%"}
      flexDirection={"row"}
      overflow={"auto"}
      gap={2}
      justifyContent={"flex-start"}
      py={2}
    >
      {pickups.map((pickup, index) => {
        return (
          <Stack key={index} sx={classes.wizardCard}>
            <Stack
              mb={"20px"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "14px",
                }}
              >
                {summaryString(pickup.name, 15)}
              </Typography>
              <Stack flexDirection={"row"}>
                <Button
                  sx={classes.buttonInCard}
                  onClick={() => {
                    onDeleteHandler(pickup.name);
                  }}
                >
                  Delete
                </Button>
                <Button
                  sx={classes.buttonInCard}
                  onClick={() => {
                    onEditHandler(index);
                  }}
                >
                  Edit
                </Button>
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Stack sx={{ lineHeight: "26px" }} gap={1}>
                <Typography sx={classes.wizardTitle}>Coordinates</Typography>
                <Box>
                  <span style={{ fontWeight: "600", paddingRight: "8px" }}>
                    Latitude:
                  </span>
                  {pickup.latitude || 0}
                </Box>
                <Box>
                  <span style={{ fontWeight: "600", paddingRight: "8px" }}>
                    Longitude:
                  </span>
                  {pickup.longitude || 0}
                </Box>
                <Divider sx={{ mt: "16px" }} />
              </Stack>
              <Stack sx={{ lineHeight: "26px" }} gap={1}>
                <Typography sx={classes.wizardTitle}>Address</Typography>
                {pickup.addr_apartment +
                  "," +
                  pickup.addr_street +
                  "," +
                  pickup.addr_city +
                  "," +
                  pickup.addr_country}
                <Divider sx={{ mt: "16px" }} />
              </Stack>
              <Stack sx={{ lineHeight: "26px" }} gap={1}>
                <Typography sx={classes.wizardTitle}>Raw waste type</Typography>
                {pickup.capacities.map((cap, index) => {
                  return (
                    <Typography key={index}>
                      {summaryString(cap.name)}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

const ListProducers = ({
  producers,
  onEditHandler = () => {},
  onDeleteHandler = () => {},
}) => {
  return (
    <>
      <Stack
        flexDirection={"row"}
        gap={2}
        overflow={"auto"}
        justifyContent={"flex-start"}
        py={2}
      >
        {producers.map((producer, index) => {
          return (
            <Stack key={index} sx={classes.wizardCard}>
              <Stack
                mb={"20px"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "14px",
                  }}
                >
                  {summaryString(producer.name, 15)}
                </Typography>
                <Stack flexDirection={"row"}>
                  <Button
                    sx={classes.buttonInCard}
                    onClick={() => {
                      onDeleteHandler(producer.name);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    sx={classes.buttonInCard}
                    onClick={() => {
                      onEditHandler(index);
                    }}
                  >
                    Edit
                  </Button>
                </Stack>
              </Stack>
              <Stack gap={2}>
                <Stack sx={{ lineHeight: "26px" }} gap={1}>
                  <Typography sx={classes.wizardTitle}>
                    Identification
                  </Typography>
                  <Box>
                    <span style={{ fontWeight: "600", paddingRight: "8px" }}>
                      Company Tax name:
                    </span>
                    {producer?.tax_name}
                  </Box>
                  <Box>
                    <span style={{ fontWeight: "600", paddingRight: "8px" }}>
                      VAT number:
                    </span>
                    {producer.vat_number || 0}
                  </Box>
                  <Divider sx={{ mt: "16px" }} />
                </Stack>
                <Stack sx={{ lineHeight: "26px" }} gap={1}>
                  <Typography sx={classes.wizardTitle}>Contacts</Typography>
                  <Box>
                    <span style={{ fontWeight: "600", paddingRight: "8px" }}>
                      Phone:
                    </span>
                    {producer?.phone}
                  </Box>
                  <Box>
                    <span style={{ fontWeight: "600", paddingRight: "8px" }}>
                      Mobile:
                    </span>
                    {producer.mobile_phone}
                  </Box>
                  <Box>
                    <span style={{ fontWeight: "600", paddingRight: "8px" }}>
                      Email:
                    </span>
                    {producer.email}
                  </Box>
                  <Divider sx={{ mt: "16px" }} />
                </Stack>
                <Stack sx={{ lineHeight: "26px" }} gap={1}>
                  <Typography sx={classes.wizardTitle}>
                    Collection Points
                  </Typography>
                  {producer.pickupPoints.map((pickup, index) => {
                    return <Box key={index}>{pickup.name}</Box>;
                  })}
                </Stack>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};

const AddPickupPoint = ({
  onCancelHandler = () => {},
  onSaveHandler = () => {},
  onEditHandler = () => {},
  editMode = false,
  data = null,
  rawWastes = [],
  rawWasteCount = 0,
}) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [hasId, setHasId] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [officialId_country, setOfficialIdCountry] = useState(
    profile.countryCode
  );
  const [location_data, setLocationData] = useState(null);

  const columns = [
    {
      field: "name",
      width: "300",
      headerName: "Raw Waste",
    },
    {
      field: "capacity",
      width: "150",
      headerName: "capacity",
      renderCell: (props) => {
        return <>{props.value} kg</>;
      },
    },
    {
      field: "action",
      headerName: "",
      renderCell: (props) => {
        return (
          <EditDetail
            options={[
              { label: "Edit capacity", value: "edit" },
              { label: "Remove capacity", value: "remove" },
            ]}
            props={props}
            onStateChangeHandler={async (status) => {
              setCurrent(props.row);
              if (status === "edit") setEditDlgOpen(true);
              if (status === "remove") setRemoveDlgOpen(true);
            }}
          />
        );
      },
    },
  ];

  const orgData = useSelector((state) => state.organizations.profile);
  const [capacities, setCapacity] = useState([]);
  const [addCapacityDialog, setDialog] = useState(false);
  const [step, setStep] = useState(0);
  const [currentRawWaste, setCurrent] = useState(null);
  const [editDlgOpen, setEditDlgOpen] = useState(false);
  const [removeDlgOpen, setRemoveDlgOpen] = useState(false);
  const [zone, setZone] = useState(null);
  const [validation, setValidataion] = useState(false);
  const zones = useSelector((state) => {
    return state.zone.zoneList;
  });
  const [usingCompanyAddress, setUsingCompanyAddress] = useState(false);

  useEffect(() => {
    if (usingCompanyAddress) {
      if (orgData.latitude && orgData.longitude) {
        setLocationData({
          latitude: orgData.latitude,
          longitude: orgData.longitude,
          addr_country: orgData?.addr_country,
          addr_city: orgData?.addr_city,
          addr_apartment: orgData?.addr_apartment,
          addr_postcode: orgData?.addr_postcode,
          addr_street: orgData?.addr_street,
        });
      } else {
        showNotification("Company address is not defined", "info");
        setUsingCompanyAddress(false);
      }
    }
  }, [usingCompanyAddress, orgData]);

  useEffect(() => {
    dispatch(fetchZoneList());
  }, [dispatch]);

  useEffect(() => {
    if (editMode && data) {
      setHasId(data.hasId);
      setName(data.name);
      setNumber(data.official_id);

      setZone(data.zone);
      setCapacity(data.capacities);
    }
  }, [editMode, data]);

  const save = () => {
    const data = {
      name,
      official_id_country: officialId_country,
      official_id: number,
      ...location_data,
      zone,
      capacities,
    };
    if (editMode) {
      onEditHandler(data);
    } else {
      onSaveHandler(data);
    }
  };

  return (
    <>
      {step === 0 && (
        <Stack px={"60px"} py={"20px"}>
          <Stack justifyContent={"flex-end"} flexDirection={"row"} gap={1}>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={onCancelHandler}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonSave}
              onClick={() => {
                setValidataion(true);
                if (!name || name === "");
                else if (zone === null);
                else if (hasId && (!number || number === ""));
                else setStep(1);
              }}
            >
              Next
            </Button>
          </Stack>
          <Stack>
            <PageTitle>Identification</PageTitle>
          </Stack>
          <Stack>
            <CustomMap
              pickupFlag={true}
              extraComponent={
                <Box
                  mt={10}
                  sx={{
                    alignItems: "flex-start",
                    width: "320px",
                  }}
                >
                  <CTextInput
                    title="Name"
                    required
                    validation={validation}
                    placeholder="Collection Point Name"
                    value={name}
                    onChange={(v) => setName(v)}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          checked={hasId}
                          onChange={() => setHasId(!hasId)}
                        />
                      }
                      label="Collection point has Official ID"
                    />
                  </FormGroup>
                  {hasId && (
                    <COfficialIdInput
                      label="Official ID"
                      countryCode={officialId_country}
                      content=""
                      placeholder="Number"
                      value={number}
                      onCountryChange={(e, data) =>
                        setOfficialIdCountry(data.code)
                      }
                      required
                      validation={validation}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  )}
                  <CAutoComplete
                    value={zone}
                    onChange={(value) => setZone(value)}
                    options={zones.map((zone) => {
                      return {
                        ...zone,
                        name: zone.name,
                        value: zone._id,
                      };
                    })}
                    title="Zone"
                    placeholder="Select a Zone"
                    onAddNewItem={(value) => {
                      dispatch(
                        createZone({
                          name: value,
                        })
                      );
                    }}
                    required
                    validation={validation}
                  />
                  <SubTitle>Location</SubTitle>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          checked={usingCompanyAddress}
                          onChange={() =>
                            setUsingCompanyAddress(!usingCompanyAddress)
                          }
                        />
                      }
                      label="Use producer address details"
                    />
                  </FormGroup>
                </Box>
              }
              description={"producer"}
              data={location_data}
              onChange={(d) => {
                setLocationData(d);
              }}
            />
          </Stack>
        </Stack>
      )}
      {step === 1 && (
        <Stack
          flexDirection={"row"}
          sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
        >
          <Stack minWidth={"420px"} maxWidth={"420px"}>
            <Stack
              sx={{ backgroundColor: "#F7F6F3" }}
              pt={"72px"}
              px={"48px"}
              pb={"101px"}
              gap={"21px"}
            >
              <BigTitle> {name} - Waste capacity </BigTitle>
            </Stack>
            <Stack padding={"48px"} gap={"10px"}>
              We now need to add the waste types and capacity that this pickup
              point produces.
            </Stack>
          </Stack>
          <Stack
            width={"calc(100% - 420px);"}
            gap={2}
            padding={5}
            justifyContent={"flex-start"}
            textAlign={"flex-start"}
            overflow={"auto"}
            sx={{
              backgroundColor: "white",
            }}
          >
            <Stack justifyContent={"flex-end"} flexDirection={"row"} gap={1}>
              <Button
                variant="outlined"
                sx={classes.buttonPrimary}
                onClick={() => {
                  setStep(0);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                sx={classes.buttonSave}
                onClick={() => {
                  save();
                }}
              >
                Save
              </Button>
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
              gap={1}
            >
              {capacities.length === 0 ? (
                <>
                  <EmptyWizard
                    title={
                      "No waste types have been added to this collection point yet"
                    }
                    content={
                      "Add the first waste type to this collection point to continue. You can manage your collection point at anytime"
                    }
                    buttonText={"Add waste Type"}
                    onAdd={() => {
                      setDialog(true);
                    }}
                    Icon={
                      <WaterDropOutlinedIcon
                        sx={{ color: "text.tertiary", fontSize: 100 }}
                      />
                    }
                  />
                </>
              ) : (
                <>
                  <DataTable
                    rows={capacities.map((item) => {
                      return {
                        ...item,
                        id: item.raw_waste,
                      };
                    })}
                    columns={columns}
                  ></DataTable>
                  <Button
                    sx={classes.buttonSave}
                    onClick={() => {
                      setDialog(true);
                    }}
                  >
                    Add capacity
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
          <RemoveDepositDialog
            open={removeDlgOpen}
            onClose={() => {
              setRemoveDlgOpen(false);
            }}
            onRemove={() => {
              let caps = capacities.filter((capacity) => {
                return capacity.name !== currentRawWaste.name;
              });
              setCapacity(caps);
            }}
          />
          <EditDepositDialog
            open={editDlgOpen}
            onContinue={(capacity) => {
              let caps = [...capacities];
              for (let cap of caps) {
                if (cap.name === currentRawWaste.name) {
                  cap.capacity = capacity;
                  break;
                }
              }
              setCapacity(caps);
            }}
            onClose={() => {
              setEditDlgOpen(false);
            }}
            rawWaste={currentRawWaste}
          />
          <AddDepositDialog
            rawWastes={rawWastes}
            open={addCapacityDialog}
            onContinue={(id, name, capacity) => {
              let caps = [...capacities];
              let flag = false;
              for (let cap of caps) {
                if (cap.raw_waste === id) {
                  cap.capacity =
                    Number(cap.capacity || 0) + Number(capacity || 0);
                  flag = true;
                  break;
                }
              }
              if (flag === false) caps.push({ raw_waste: id, name, capacity });
              setCapacity(caps);
            }}
            onClose={() => {
              setDialog(false);
            }}
          />
        </Stack>
      )}
    </>
  );
};

const AddProducer = ({
  onCancelHandler = () => {},
  onSaveHandler = () => {},
  onEditHandler = () => {},
  editMode = false,
  data = null,
}) => {
  const { profile } = useSelector((state) => state.user);
  const [vat_number, setVatNumber] = useState();
  const [vat_number_country, setVatNumberCountry] = useState(
    profile.countryCode
  );
  const [tax_name, setTaxName] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [step, setStep] = useState(0);
  const [pickupPoints, setPickupPoints] = useState([]);
  const dispatch = useDispatch();
  const [itemsChecked, setItemsChecked] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });

  const raw_wastes = useSelector((state) => state.organizations.rawWastes);

  const [pickupEditMode, setEditMode] = useState(false);
  const [currentPickupIndex, setCurrentPickupIndex] = useState(null);
  const [currentPickup, setCurrentPickup] = useState(null);
  const [validation, setValidataion] = useState(false);
  const [emailValidation, setEmailvalidation] = useState(false);
  const columns = [
    {
      field: "checkbox",
      hideSortIcons: true,
      disableColumnMenu: true,
      width: 100,
      headerName: (
        <Checkbox
          sx={classes.checkBox}
          checked={allCheck}
          onClick={(e) => {
            e.stopPropagation();
            if (allCheck) {
              setAllCheck(false);
              setItemsChecked([]);
            } else {
              setAllCheck(true);
              setItemsChecked(raw_wastes.map((t) => t.rawWasteId));
            }
          }}
        />
      ),
      renderCell: (props) => (
        <Checkbox
          sx={classes.checkBox}
          checked={itemsChecked.indexOf(props.row.rawWasteId) !== -1}
          onClick={(e) => {
            e.stopPropagation();
            const id = itemsChecked.indexOf(props.row.rawWasteId);
            const temp = [...itemsChecked];
            if (id !== -1) {
              temp.splice(id, 1);
            } else {
              temp.push(props.row.rawWasteId);
            }
            setItemsChecked(temp);
          }}
        />
      ),
    },
    {
      field: "code",
      headerName: "LER Code",
      width: 200,
    },
    {
      field: "name",
      headerName: "Name",
      width: 500,
    },
    {
      field: "capacity",
      headerName: "C.racio(l-kg)",
      width: 100,
    },
  ];
  useEffect(() => {
    dispatch(fetchRawWastes());
  }, [dispatch]);

  useEffect(() => {
    if (editMode && data) {
      setVatNumber(data.vat_number);
      setVatNumberCountry(data.vat_number_country);
      setName(data.name);
      setTaxName(data.tax_name);
      setEmail(data.email);
      setPhone(data.phone);
      setPickupPoints(data.pickupPoints);
      setItemsChecked(data.rawWastes.map((rw) => rw.rawWasteId));
    }
  }, [editMode, data]);
  const saveProducer = () => {
    setStep(2);
  };
  const save = () => {
    const rawWastes = raw_wastes.filter((rw) => {
      return itemsChecked.indexOf(rw.rawWasteId) >= 0;
    });
    const data = {
      vat_number,
      vat_number_country,
      name,
      tax_name,
      email,

      phone,
      pickupPoints,
      rawWastes,
    };
    if (editMode) {
      onEditHandler(data);
    } else {
      onSaveHandler(data);
    }
  };
  return (
    <>
      {step === 0 && (
        <Stack px={"60px"} py={"20px"}>
          <Stack justifyContent={"flex-end"} flexDirection={"row"} gap={1}>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={onCancelHandler}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonSave}
              onClick={() => {
                setValidataion(true);
                if (!vat_number || vat_number === "");
                else if (!name || name === "");
                else if (!validateEmail(email)) {
                  showNotification("Input valid email", "error");
                  setEmailvalidation(true);
                } else {
                  setEmailvalidation(false);
                  setStep(1);
                }
              }}
            >
              Next
            </Button>
          </Stack>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Box width={"400px"}>
              <PageTitle>Identification</PageTitle>
              <COfficialIdInput
                label="VAT number"
                content=""
                required
                validation={validation}
                countryCode={vat_number_country}
                onCountryChange={(e, data) => {
                  setVatNumberCountry(data.code);
                }}
                value={vat_number}
                onChange={(e) => {
                  setVatNumber(e.target.value);
                }}
              />
              <CTextInput
                value={name}
                placeholder="Add producer name"
                required
                validation={validation}
                title="Producer name"
                onChange={(value) => {
                  setName(value);
                }}
              />

              <CTextInput
                value={tax_name}
                placeholder="Olive Marpickings, Lda"
                title="Company Tax name"
                onChange={(value) => {
                  setTaxName(value);
                }}
              />
              <PageTitle>Contacts</PageTitle>
              <CTextInput
                value={email}
                required
                validation={validation}
                error={emailValidation}
                errorMessage="Input valid email"
                placeholder="Add company email"
                title="Email"
                onChange={(value) => {
                  setEmail(value);
                }}
              />
              <CPhoneInput
                fullWidth
                label={"Phone"}
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </Box>
          </Stack>
        </Stack>
      )}
      {step === 1 && (
        <Stack
          flexDirection={"row"}
          sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
        >
          <Stack maxWidth={"420px"} minWidth={"420px"}>
            <Stack
              sx={{ backgroundColor: "#F7F6F3" }}
              pt={"72px"}
              px={"48px"}
              pb={"21px"}
              gap={"101px"}
            >
              <BigTitle>Waste Type</BigTitle>
              <Stack
                maxWidth={"472px"}
                sx={{ fontSize: "18px", fontFamily: "Manrope" }}
                gap={4}
              >
                <Box>
                  Add each of the waste types that the collection point
                  produces.
                </Box>
              </Stack>
            </Stack>
            <Stack padding={"48px"} gap={"10px"}>
              <Box sx={{ fontWeight: "bold" }}>Why is this important?</Box>
              Defining the raw waste will allow you to allocate waste types to
              warehouses and vehicles which in turn enable you to collect waste
              from producers.
            </Stack>
          </Stack>
          <Stack
            sx={{ backgroundColor: "white", width: "calc(100vw - 420px);" }}
            gap={2}
            px={5}
            py={5}
          >
            <Stack
              justifyContent={"space-between"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={1}
            >
              <SubTitle>Add raw waste</SubTitle>
              <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={2}>
                <Button
                  variant="outlined"
                  sx={classes.buttonPrimary}
                  onClick={onCancelHandler}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  sx={classes.buttonSave}
                  onClick={() => {
                    saveProducer();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Stack alignItems={"flex-start"}>
                <SearchBar
                  placeholder="Search raw waste"
                  onSearchChange={(value) => {
                    setFilterModel({
                      items: [
                        { field: "name", operator: "contains", value: value },
                      ],
                    });
                  }}
                />
              </Stack>
              <DataTable
                fit="calc(100vw - 510px);"
                filterModel={filterModel}
                columns={columns}
                rows={raw_wastes}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
      {step === 2 && (
        <Stack
          flexDirection={"row"}
          sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
        >
          <Stack minWidth={"420px"} maxWidth={"420px"}>
            <Stack
              sx={{ backgroundColor: "#F7F6F3" }}
              pt={"72px"}
              px={"48px"}
              pb={"21px"}
              gap={"21px"}
            >
              <BigTitle> {name} - Collection points </BigTitle>
            </Stack>
            <Stack padding={"48px"} gap={"10px"}>
              Producers will have one or more collection points that you wil
              collect the raw waste from, here you can add each of the
              collection points. However, if you wish to import a list of
              collection points for this producer you can do so by populating
              and uploading a CSV file, this file can be downloaded here.
            </Stack>
          </Stack>
          <Stack
            width={"calc(100% - 420px);"}
            gap={2}
            padding={5}
            justifyContent={"flex-start"}
            textAlign={"flex-start"}
            overflow={"auto"}
            sx={{
              backgroundColor: "white",
            }}
          >
            <Stack justifyContent={"flex-end"} flexDirection={"row"} gap={1}>
              <Button
                variant="outlined"
                sx={classes.buttonPrimary}
                onClick={onCancelHandler}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                sx={classes.buttonSave}
                onClick={() => {
                  save();
                }}
              >
                Save
              </Button>
            </Stack>
            <Stack justifyContent={"center"} height={"100%"}>
              {pickupPoints.length === 0 ? (
                <>
                  <EmptyWizard
                    title={
                      "No collection points have been added to this producer yet"
                    }
                    buttonText={"Add Collection Point"}
                    content={
                      "Add the first collection point to this producer  to continue. You can manage your collection points at any time."
                    }
                    onAdd={() => {
                      setStep(3);
                    }}
                    Icon={<PickupIcon size="32px" fill="#33343F" />}
                  />
                </>
              ) : (
                <>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    overflow={"auto"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    py={2}
                  >
                    <ListPickups
                      pickups={pickupPoints}
                      onDeleteHandler={(name) => {
                        const temp = pickupPoints.filter((pickup) => {
                          return pickup.name !== name;
                        });
                        setPickupPoints(temp);
                      }}
                      onEditHandler={(index) => {
                        setCurrentPickup(pickupPoints[index]);
                        setCurrentPickupIndex(index);
                        setEditMode(true);
                        setStep(3);
                      }}
                    />
                  </Stack>
                  <Stack alignItems={"flex-start"}>
                    <Button
                      sx={classes.buttonSave}
                      onClick={() => {
                        setStep(3);
                      }}
                    >
                      Add collection point
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
      {step === 3 && (
        <Stack>
          <Stack>
            <AddPickupPoint
              rawWastes={raw_wastes.filter((rw) => {
                return itemsChecked.indexOf(rw.rawWasteId) >= 0;
              })}
              rawWasteCount={itemsChecked.length}
              editMode={pickupEditMode}
              data={currentPickup}
              onCancelHandler={() => {
                onCancelHandler();
              }}
              onSaveHandler={(data) => {
                setPickupPoints([...pickupPoints, data]);
                setStep(2);
              }}
              onEditHandler={(data) => {
                const temp = [...pickupPoints];
                temp[currentPickupIndex] = data;
                setPickupPoints(temp);
                setEditMode(false);
                setStep(2);
              }}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

const SetupProducer = () => {
  snackbarRef.current = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const confirmProducers = async () => {
    for (let producer of producerDetails) {
      const {
        vat_number,
        vat_number_country,
        name,
        tax_name,
        email,
        phone,
        pickupPoints,
      } = producer;
      try {
        const res = await ProducerService.createProducerWizard({
          vat_number,
          vat_number_country,
          name,
          tax_name,
          email,
          phone,
        });
        if (res.data.message === SUCCESS) {
          const producer_id = res.data.producer_id;
          const wastes = producer.rawWastes;
          try {
            await ProducerService.addProducerRawWaste(producer_id, {
              raw_wastes: wastes.map((waste) => {
                return waste.rawWasteId;
              }),
            });
          } catch (err) {
            console.log("err", err);
          }
          for (let pickup of pickupPoints) {
            const {
              name,
              official_id_country,
              official_id,
              latitude,
              longitude,
              addr_street,
              addr_apartment,
              addr_postcode,
              addr_city,
              addr_country,
              zone,
              capacities,
            } = pickup;
            try {
              const res = await PickupService.createPickupPointWizard({
                producer: producer_id,
                name,
                official_id_country,
                official_id,
                latitude,
                longitude,
                addr_country,
                addr_street,
                addr_apartment,
                addr_postcode,
                addr_city,
                zones: [zone.value],
              });
              if (res.data.message === SUCCESS) {
                const pickupId = res.data.pickup_id;
                try {
                  for (let cap of capacities) {
                    await PickupService.addPickupPointDeposit(pickupId, {
                      raw_waste: cap.raw_waste,
                      capacity: cap.capacity,
                    });
                  }
                } catch (err) {
                  console.log("err", err);
                }
              }
            } catch (err) {
              showNotification(
                `Error was occured while creating a collection point(${pickup.name}) ` +
                  err.response
                  ? ERROR[err.response.data.message] || "Network Error"
                  : err.message,
                "error"
              );
            }
          }
        }
      } catch (err) {
        showNotification(
          `Error was occured while creating a producer(${producer.name}) ` +
            err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      }
    }
    showNotification(
      "Thank you for completing the onbaording wizard, we are in the process of reviewing your organisation and will enable the Organisation as soon as the review is completed",
      "success"
    );
    // navigate("/auth/signin-select-org");
  };
  // const producers = useSelector((state) => state.producer.producerData);

  const [producerDetails, setProducerDetails] = useState([]);
  const [currentProducer, setCurrentProducer] = useState([]);
  const [currentProducerIndex, setCurrentProducerIndex] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    dispatch(fetchProducers());
  }, [dispatch]);

  // useEffect(() => {
  //   if (producers.length > 0) {
  //     showNotification("You already setup a producer", "success");
  //     navigate("/auth/reviewing");
  //   }
  // }, [producers, navigate]);

  return (
    <>
      {step === 0 && (
        <Stack
          flexDirection={"row"}
          sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
        >
          <Stack maxWidth={"420px"} minWidth={"420px"}>
            <Stack
              sx={{ backgroundColor: "#F7F6F3" }}
              pt={"72px"}
              px={"48px"}
              pb={"21px"}
              gap={"21px"}
            >
              <BigTitle> Producers and Collection Points</BigTitle>
              <Stack
                maxWidth={"472px"}
                sx={{ fontSize: "18px", fontFamily: "Manrope" }}
                gap={4}
              >
                <Box>
                  You now have your organisation in good order! Its time to
                  start adding the producers that you work with and their pickup
                  points, a Producer is a company that you are contracted to
                  collect oil from, and collection points are the individual
                  locations that your drivers visit to collect raw waste.
                </Box>
                <Box>
                  Once you have completed your onboarding you can manage
                  additional information and add additional producers and
                  collection points from the main menu under the{" "}
                  <span style={{ fontWeight: "bold" }}>Organisations</span> and{" "}
                  <span style={{ fontWeight: "bold" }}>Collection Points</span>{" "}
                  respectively.
                </Box>
              </Stack>
            </Stack>
            <Stack padding={"48px"} gap={"10px"}>
              <Box sx={{ fontWeight: "bold" }}>Why is this important?</Box>
              Producers and Collection Points are the final piece of the puzzle,
              generating the raw waste and allowing the platform to create a
              schedule of raw waste collection creating smart routes using our
              AI technology.
            </Stack>
          </Stack>
          <Stack
            width={"calc(100% - 420px);"}
            gap={2}
            padding={5}
            justifyContent={"flex-start"}
            textAlign={"flex-start"}
            overflow={"auto"}
            sx={{
              backgroundColor: "white",
            }}
          >
            <Stack alignItems={"flex-end"}>
              <Button
                variant="outlined"
                onClick={confirmProducers}
                sx={classes.buttonPrimary}
              >
                Confirm Producers
              </Button>
            </Stack>
            <Stack justifyContent={"flex-start"} height={"100%"} mt={"25vh"}>
              {producerDetails.length === 0 ? (
                <>
                  <EmptyWizard
                    title="No producers have been added yet"
                    content="Add the first producer to continue. You can manage your producers at any time."
                    Icon={<OrganizationIcon size="32px" fill="#33343F" />}
                    buttonText={"Add Producer"}
                    onAdd={() => {
                      setStep(1);
                    }}
                  />
                </>
              ) : (
                <>
                  <ListProducers
                    producers={producerDetails}
                    onDeleteHandler={(name) => {
                      let temp = producerDetails.filter((producer) => {
                        return producer.name !== name;
                      });
                      setProducerDetails(temp);
                    }}
                    onEditHandler={(index) => {
                      setCurrentProducerIndex(index);
                      setCurrentProducer(producerDetails[index]);
                      setStep(1);
                      setEditMode(true);
                    }}
                  />
                  <Stack alignItems={"flex-start"}>
                    <Button
                      sx={classes.buttonSave}
                      onClick={() => {
                        setStep(1);
                      }}
                    >
                      Add producer
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
      {step === 1 && (
        <AddProducer
          onCancelHandler={() => {
            setStep(0);
          }}
          onEditHandler={(data) => {
            let temp = [...producerDetails];
            temp[currentProducerIndex] = data;
            setProducerDetails(temp);
            setEditMode(false);
            setStep(0);
          }}
          onSaveHandler={(data) => {
            setProducerDetails([...producerDetails, data]);
            setStep(0);
          }}
          editMode={editMode}
          data={currentProducer}
        />
      )}
    </>
  );
};
export default SetupProducer;
