import TicketService from "../services/TicketService";
import { setTicketsList, setTicketsSummaryInfo } from "../slices/ticketsSlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";

export const fetchTickets =
  (callBack = () => {}) =>
  (dispatch) => {
    TicketService.fetchTicketsList()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setTicketsList(res.data.data.data));
          const {
            count_expired_documents,
            count_expiring_documents,
            count_without_manager,
            count_zero_collected,
          } = res.data.data;
          dispatch(
            setTicketsSummaryInfo({
              count_expired_documents,
              count_expiring_documents,
              count_without_manager,
              count_zero_collected,
            })
          );
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          ERROR[err.response.data.message] || err.response.data.message,
          "error"
        );
      });
  };

export const addNewTicket = (data, fallback) => (dispatch) => {
  TicketService.createTicket(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        fallback();
      }
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const updateTicket = (id, data) => (dispatch) => {
  TicketService.updateTicket(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchTickets());
      }
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};

export const updateTicketStatus = (id, data) => (dispatch) => {
  TicketService.updateTicketStatus(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchTickets());
      }
    })
    .catch((err) => {
      showNotification(
        ERROR[err.response.data.message] || err.response.data.message,
        "error"
      );
    });
};
