import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import { PageTitle } from "../../../components/Typography";
import { CardPrimary } from "../../../components/Card";
import { SearchBar } from "../../../components/CustomInput";
import { DataTable } from "../../../components/Table";
import { EditDetail, StateSpan } from "../../../components/StateSpan";
import { classes } from "../../../utils/class";
import { setCurrentZone } from "../../../slices/zoneSlice";
import {
  activateZone,
  deactivateZone,
  fetchZoneList,
  removeZone,
} from "../../../actions/zone";
import { EDIT_ROW, REMOVE_ID } from "../../../utils/type";
import { BACKOFFICE, DRIVER, OWNER, SALES } from "../../../utils/constants";
import { showNotification } from "../../../utils/helpers";

const ZonesListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const role = useSelector((state) => state.user.role);

  const zones = useSelector((state) => {
    return state.zone.zoneList;
  });
  const total_collected = useSelector((state) => {
    return state.zone.totalCollected;
  });
  const [rows, setRows] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const columns = [
    {
      flex: 2,
      field: "name",
      headerName: "Name",
      renderCell: (props) => {
        return (
          <Typography
            sx={{ fontWeight: 700, paddingLeft: 2 }}
            className="mui-ellipsis"
          >
            {props.value}
          </Typography>
        );
      },
    },
    {
      flex: 1,
      field: "count_of_producers",
      headerName: "Nº Producers",
    },
    {
      flex: 1,
      field: "count_of_pickup_points",
      headerName: "Nº P.Points ",
    },
    {
      flex: 1,
      field: "total_collected",
      headerName: "Total collected",
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      width: 100,
      field: "status",
      headerName: "Status",
      renderCell: (props) => {
        let options = [];
        if (props?.value === "active") {
          options = [
            {
              label: "Inactive",
              value: "inactive",
            },
          ];
        } else if (props?.value === "inactive") {
          options = [
            {
              label: "Active",
              value: "active",
            },
          ];
        }
        return (
          <StateSpan
            disabled={role !== BACKOFFICE && role !== OWNER}
            status={props.value}
            options={options}
            onStateChangeHandler={(state) => {
              if (state === "active") {
                dispatch(activateZone(props.row._id));
              } else {
                dispatch(deactivateZone(props.row._id));
              }
            }}
          />
        );
      },
    },
    {
      width: 10,
      field: "action",
      headerName: "",
      renderCell: (props) => {
        return (
          <EditDetail
            options={[
              { label: "Edit zone", value: EDIT_ROW },
              {
                label: "Remove zone",
                value: REMOVE_ID,
              },
            ]}
            onStateChangeHandler={(state) => {
              if (state === EDIT_ROW) {
                dispatch(setCurrentZone(props.row.id));
                navigate("/zones-detail");
              } else if (state === REMOVE_ID) {
                if (role !== BACKOFFICE && role !== OWNER)
                  showNotification("You don't have permission", "info");
                else dispatch(removeZone(props.row.id));
              }
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setRows(
      zones.map((zone, index) => {
        return {
          ...zone,
          id: zone._id,
          index: index,
        };
      })
    );
  }, [zones]);
  useEffect(() => {
    dispatch(
      fetchZoneList(() => {
        setIsLoaded(true);
      })
    );
  }, [dispatch]);
  if (rows.length === 0)
    return (
      <>
        <Box sx={{ minHeight: "100vh", height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "60px",
              paddingY: "20px",
            }}
          >
            <PageTitle>Zones</PageTitle>
            <Button
              sx={classes.buttonPrimary}
              onClick={() => {
                navigate("/add-zone");
              }}
              disabled={role !== BACKOFFICE && role !== OWNER}
            >
              Add zone
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80%",
              height: "80vh",
            }}
          >
            <HomeOutlinedIcon sx={{ color: "text.tertiary", fontSize: 100 }} />
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Manrope",
                fontSize: "10px",
                color: "text.primary",
                marginBottom: "30px",
              }}
            >
              There are no zones listed
            </Typography>
            <Button
              size="large"
              sx={classes.buttonPrimary}
              onClick={() => {
                navigate("/add-zone");
              }}
              disabled={role !== BACKOFFICE && role !== OWNER}
            >
              Add zone
            </Button>
          </Box>
        </Box>
      </>
    );

  return (
    <Stack direction={"column"} marginTop={3} gap={2} paddingX={"60px"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <PageTitle>Zone List</PageTitle>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Button
            variant="outlined"
            sx={classes.buttonPrimary}
            onClick={() => {
              navigate("/add-zone");
            }}
            disabled={role !== BACKOFFICE && role !== OWNER}
          >
            Add zone
          </Button>
        </Stack>
      </Stack>
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        {isLoaded ? (
          <CardPrimary type={1} title={"Nº Zones"} content={rows.length} />
        ) : (
          <Skeleton variant="rounded" width={"162px"} height={"86px"} />
        )}
        {isLoaded ? (
          <CardPrimary
            type={2}
            title={"Total collected"}
            content={`${total_collected}t`}
          />
        ) : (
          <Skeleton variant="rounded" width={"162px"} height={"86px"} />
        )}
      </Stack>
      <Stack justifyContent={"flex-start"}>
        {isLoaded ? (
          <SearchBar
            placeholder="Search zones"
            sx={{ width: "275px" }}
            onSearchChange={(value) => {
              setFilterModel({
                items: [
                  {
                    field: "name",
                    operator: "contains",
                    value: value,
                  },
                ],
              });
            }}
          />
        ) : (
          <Skeleton variant="rounded" height={"30px"} width={"275px"} />
        )}
      </Stack>
      <Box>
        {isLoaded ? (
          <DataTable
            filterModel={filterModel}
            rows={rows}
            columns={columns}
            onRowClick={(e) => {
              localStorage.setItem("zoneTab", 0);
              dispatch(setCurrentZone(e.row.id));
              navigate("/zones-detail");
            }}
          />
        ) : (
          <Skeleton variant="rounded" width={"100%"} height={"200px"} />
        )}
      </Box>
    </Stack>
  );
};

export default ZonesListPage;
