import React, { useRef, useState } from "react";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
// material-ui
import {
  Box,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// type import
import {
  STATE_ACTIVE,
  STATE_INACTIVE,
  BADGE_A_TO_CLIENT,
  BADGE_A_TO_DRIVER,
  BADGE_DEPOSIT,
  BADGE_UNIQUE,
  BADGE_OFFER,
  STATE_AVAILABLE,
  STATE_UNAVAILABLE,
  BADGE_LOANED,
  BADGE_IN_WAREHOUSE,
  BADGE_INCOMPLETE,
  STATE_PENDING,
  STATE_REJECTED,
  STATE_IN_PROGRESS,
  STATE_CLOSED,
  STATE_ARCHIVE,
  STATE_OVERDUE,
  STATE_PLANNED,
  STATE_UPCOMING,
  STATE_VALID,
  STATE_REVOKED,
  STATE_EXPIRED,
  BADGE_DEFAULT,
  STATE_COMPLETED,
  STATE_SUGGESTION,
  STATE_ONGOING,
  STATE_DISCARDED,
  STATE_VALIDATED,
  STATE_DISCARD,
  STATE_PROGRESS,
  BADGE_GENERIC,
  STATE_ENABLED,
  STATE_DISABLED,
} from "../utils/type";
import { capitalizeFirstLetter } from "../utils/string";
import { Stack } from "@mui/system";
import { classes } from "../utils/class";
import { RadioIcon } from "./icons/menu_icons/icons";
import { useSelector } from "react-redux";
import {
  DRIVER,
  SALES,
  SALES_MANAGER,
  WAREHOUSE_MANAGER,
} from "../utils/constants";

const Badge = ({ status, fixed = false }) => {
  if (status === null || status === "") return <></>;
  const info = {
    [BADGE_DEFAULT]: {
      bgcolor: "background.light",
      color: "text.primary",
    },
    [BADGE_INCOMPLETE]: {
      bgcolor: "background.light",
      color: "text.primary",
    },
    [BADGE_A_TO_CLIENT]: {
      bgcolor: "badge.client",
      color: "text.contrast",
    },
    [BADGE_A_TO_DRIVER]: {
      bgcolor: "badge.driver",
      color: "text.contrast",
    },
    [BADGE_UNIQUE]: {
      bgcolor: "badge.unique",
      color: "text.contrast",
    },
    [BADGE_DEPOSIT]: {
      bgcolor: "badge.deposit",
      color: "text.contrast",
    },
    [BADGE_OFFER]: {
      bgcolor: "badge.offer",
      color: "text.contrast",
    },
    [BADGE_LOANED]: {
      bgcolor: "badge.loaned",
      color: "text.contrast",
    },
    [BADGE_IN_WAREHOUSE]: {
      bgcolor: "badge.warehouse",
      color: "text.contrast",
    },
    [BADGE_GENERIC]: {
      bgcolor: "badge.warehouse",
      color: "text.contrast",
    },
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: info[status]?.bgcolor,
          color: info[status]?.color,
          borderRadius: "20px",
          display: "flex",
          padding: "5px 10px",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "24px",
          width: fixed ? "120px" : "fit",
        }}
      >
        <Typography
          sx={{
            fontSize: "10px",
            fontWeight: 400,
            fontFamily: "Manrope",
          }}
        >
          {capitalizeFirstLetter(status)}
        </Typography>
      </Box>
    </>
  );
};

const StateSpan = ({
  status,
  options = [],
  onStateChangeHandler = () => {},
  showCircle = false,
  enablePropagation = false,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const spanRef = useRef(null);
  const handleClose = () => {
    setOpen(false);
  };

  const info = {
    [STATE_ACTIVE]: {
      text: "Active",
      bgcolor: "status.active.bgcolor",
      textcolor: "status.active.textcolor",
    },
    [STATE_AVAILABLE]: {
      text: "Availalbe",
      bgcolor: "status.active.bgcolor",
      textcolor: "status.active.textcolor",
    },
    [STATE_INACTIVE]: {
      text: "Inactive",
      bgcolor: "status.inactive.bgcolor",
      textcolor: "status.inactive.textcolor",
    },
    [STATE_ENABLED]: {
      text: "Enabled",
      bgcolor: "status.active.bgcolor",
      textcolor: "status.active.textcolor",
    },
    [STATE_DISABLED]: {
      text: "Disabled",
      bgcolor: "status.inactive.bgcolor",
      textcolor: "status.inactive.textcolor",
    },
    [STATE_UNAVAILABLE]: {
      text: "Unavailable",
      bgcolor: "status.inactive.bgcolor",
      textcolor: "status.inactive.textcolor",
    },
    [STATE_VALIDATED]: {
      text: "Validated",
      bgcolor: "status.active.bgcolor",
      textcolor: "status.active.textcolor",
    },
    [STATE_REJECTED]: {
      text: "Rejected",
      bgcolor: "status.inactive.bgcolor",
      textcolor: "status.inactive.textcolor",
      borderWidth: "1px",
    },
    [STATE_DISCARD]: {
      text: "Discarded",
      bgcolor: "status.discarded.bgcolor",
      textcolor: "status.discarded.textcolor",
    },
    [STATE_DISCARDED]: {
      text: "Discarded",
      bgcolor: "status.discarded.bgcolor",
      textcolor: "status.discarded.textcolor",
    },
    [STATE_PENDING]: {
      text: "Pending",
      bgcolor: "status.ongoing.bgcolor",
      textcolor: "status.ongoing.textcolor",
    },
    [STATE_IN_PROGRESS]: {
      text: "In Progress",
      bgcolor: "status.active.bgcolor",
      textcolor: "status.active.textcolor",
    },
    [STATE_PROGRESS]: {
      text: "In Progress",
      bgcolor: "status.active.bgcolor",
      textcolor: "status.active.textcolor",
    },
    [STATE_CLOSED]: {
      text: "Closed",
      bgcolor: "status.inactive.bgcolor",
      textcolor: "status.inactive.textcolor",
    },
    [STATE_ARCHIVE]: {
      text: "Archived",
      bgcolor: "status.archived.bgcolor",
      textcolor: "status.archived.textcolor",
    },
    [STATE_PLANNED]: {
      text: "Planned",
      bgcolor: "status.active.bgcolor",
      textcolor: "status.active.textcolor",
    },
    [STATE_OVERDUE]: {
      text: "Overdue",
      bgcolor: "status.inactive.bgcolor",
      textcolor: "status.inactive.textcolor",
      borderWidth: "1px",
    },
    [STATE_COMPLETED]: {
      text: "Completed",
      bgcolor: "status.active.bgcolor",
      textcolor: "status.active.textcolor",
    },
    [STATE_ONGOING]: {
      text: "Ongoing",
      bgcolor: "status.ongoing.bgcolor",
      textcolor: "status.ongoing.textcolor",
    },
    [STATE_UPCOMING]: {
      text: "Upcoming",
      bgcolor: "status.ongoing.bgcolor",
      textcolor: "status.ongoing.textcolor",
    },
    [STATE_VALID]: {
      text: "Valid",
      bgcolor: "status.active.bgcolor",
      textcolor: "status.active.textcolor",
    },
    [STATE_REVOKED]: {
      text: "Revoked",
      bgcolor: "status.discarded.bgcolor",
      textcolor: "status.discarded.textcolor",
      borderWidth: "1px",
    },
    [STATE_EXPIRED]: {
      text: "Expired",
      bgcolor: "status.inactive.bgcolor",
      textcolor: "status.inactive.textcolor",
    },
    [STATE_SUGGESTION]: {
      text: "Suggestion",
      bgcolor: "status.archived.bgcolor",
      textcolor: "status.archived.textcolor",
    },
  };

  const onClick = (e) => {
    if (enablePropagation === false) {
      setOpen(true);

      e.stopPropagation();
    }
  };

  if (status === null || status === "") return <></>;
  return (
    <>
      <Box
        sx={{
          backgroundColor: info[status]?.bgcolor,
          color: info[status]?.textcolor,
          borderRadius: "8px",
          display: "flex !important",
          padding: "5px 10px",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "80px",
          minHeight: "24px",
          "&:hover": {
            cursor: "pointer",
          },
          width: "fit-content",
          borderWidth: info[status]?.borderWidth || 0,
          borderStyle: "solid",
          borderColor: info[status]?.textcolor,
        }}
        ref={spanRef}
        onClick={onClick}
      >
        <Typography
          sx={{
            fontSize: "10px",
            fontWeight: 600,
            lineHeight: "20px",
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            fontFamily: "Manrope",
          }}
        >
          {info[status]?.text}
        </Typography>
      </Box>

      {options.length > 0 && disabled === false && (
        <Menu
          id="basic-menu"
          open={open}
          anchorEl={spanRef.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            horizontal: "center",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={classes.optionMenu}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  setOpen(false);
                  onStateChangeHandler(option.value);
                }}
                sx={{ minWidth: "126px", padding: "15px" }}
              >
                {showCircle === true && (
                  <>
                    <RadioIcon checked={option.value === status} />
                    &nbsp;
                  </>
                )}
                {option.label}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

const EditDetail = ({
  props,
  onStateChangeHandler = function () {},
  options = [],
  direction = "right",
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const handleClose = () => {
    setOpen(false);
  };
  const role = useSelector((state) => state.user.role);
  return (
    <Box
      sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
    >
      <IconButton
        sx={{
          "&:hover": {
            backgroundColor: "#EBF1554D",
          },
          "&:active svg": {
            fill: "black",
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        color="primary"
        ref={buttonRef}
        aria-label="view the warehouse detail"
        disabled={disabled}
      >
        <MoreVertIcon
          sx={{
            color: "text.accent",
          }}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        open={open}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          horizontal: direction,
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiList-root": {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
        }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                setOpen(false);
                onStateChangeHandler(option.value);
              }}
              sx={{
                minWidth: "126px",
                padding: "15px",
                "&:active": {
                  backgroundColor: "#F8F8F8",
                },
                "&:hover": {
                  backgroundColor: "#EBF15566",
                },
                display: "flex",
              }}
              disabled={
                (option.label === "Remove warehouse" &&
                  option.value === "action remove" &&
                  (role === WAREHOUSE_MANAGER ||
                    role === SALES_MANAGER ||
                    role === SALES ||
                    role === DRIVER)) ||
                (option.label === "Activate Collection Point" &&
                  option.value === "activate item" &&
                  (role === WAREHOUSE_MANAGER || role === DRIVER)) ||
                (option.label === "Deactivate Collection Point" &&
                  option.value === "deactivate item" &&
                  (role === WAREHOUSE_MANAGER || role === DRIVER)) ||
                (option.label === "Archive Collection Point" &&
                  option.value === "archive item" &&
                  (role === WAREHOUSE_MANAGER ||
                    role === DRIVER ||
                    role === SALES ||
                    role === SALES_MANAGER)) ||
                (option.label === "Unarchive Collection Point" &&
                  option.value === "unarchive item" &&
                  (role === WAREHOUSE_MANAGER ||
                    role === DRIVER ||
                    role === SALES ||
                    role === SALES_MANAGER)) ||
                (option.label === "Remove" &&
                  option.value === "remove id" &&
                  (role === WAREHOUSE_MANAGER ||
                    role === DRIVER ||
                    role === SALES ||
                    role === SALES_MANAGER)) ||
                (option.label === "Activate" &&
                  option.value === "active" &&
                  (role === SALES || role === SALES_MANAGER)) ||
                (option.label === "Deactive" &&
                  option.value === "inactive" &&
                  (role === SALES || role === SALES_MANAGER)) ||
                (option.label === "Edit zone" &&
                  option.value === "action edit" &&
                  (role === DRIVER || role === SALES)) ||
                (option.label === "Remove zone" &&
                  option.value === "remove id" &&
                  (role === WAREHOUSE_MANAGER ||
                    role === DRIVER ||
                    role === SALES_MANAGER ||
                    role === SALES)) ||
                (option.label === "Change member role" &&
                  option.value === "change role" &&
                  (role === WAREHOUSE_MANAGER ||
                    role === DRIVER ||
                    role === SALES ||
                    role === SALES_MANAGER)) ||
                (option.label === "Move member to" &&
                  option.value === "move member" &&
                  (role === WAREHOUSE_MANAGER ||
                    role === DRIVER ||
                    role === SALES ||
                    role === SALES_MANAGER)) ||
                (option.label === "Archive member" &&
                  option.value === "archive item" &&
                  (role === WAREHOUSE_MANAGER ||
                    role === DRIVER ||
                    role === SALES ||
                    role === SALES_MANAGER)) ||
                (option.label === "Remove Waste Type" &&
                  option.value === "action delete" &&
                  (role === DRIVER || role === SALES)) ||
                (option.label === "Activate collection point" &&
                  option.value === "activate item" &&
                  role === DRIVER) ||
                (option.label === "Deactivate collection point" &&
                  option.value === "deactivate item" &&
                  role === DRIVER) ||
                (option.label === "Edit warehouse" &&
                  option.value === "action edit" &&
                  role === DRIVER) ||
                (option.label === "Activate warehouse" &&
                  option.value === "activate" &&
                  (role === SALES ||
                    role === SALES_MANAGER ||
                    role === DRIVER)) ||
                (option.label === "Deactivate warehouse" &&
                  option.value === "activate" &&
                  (role === SALES ||
                    role === SALES_MANAGER ||
                    role === DRIVER)) ||
                (option.label === "Remove warehouse" &&
                  option.value === "action remove" &&
                  role === DRIVER) ||
                (option.label === "Revoked" &&
                  option.value === "revoked" &&
                  role === DRIVER) ||
                (option.label === "Valid" &&
                  option.value === "valid" &&
                  role === DRIVER)
                  ? true
                  : false
              }
            >
              {option.label}
            </MenuItem>
          );
        })}
        {/* <RadioGroup defaultValue={props.value} sx={{ padding: "0px" }} onChange={(e) => {
          onStateChangeHandler(e.target.value);
        }}>
          <FormControlLabel value="active" label="Active" control={<Radio />} sx={{
            width: "162px",
            padding: "6px 16px",
            margin: "0px",
            "&:hover": {
              backgroundColor: "background.light",
              cursor: "pointer"
            }
          }} />
          <FormControlLabel value="inactive" label="Inactive" control={<Radio />} sx={{
            width: "162px",
            padding: "6px 16px",
            margin: "0px",
            "&:hover": {
              backgroundColor: "background.light",
              cursor: "pointer"
            }
          }} />
          <FormControlLabel value="archive" label="Archive" control={<Radio />} sx={{
            width: "162px",
            padding: "6px 16px",
            margin: "0px",
            "&:hover": {
              backgroundColor: "background.light",
              cursor: "pointer"
            }
          }} />
        </RadioGroup> */}
      </Menu>
    </Box>
  );
};

const CTag = ({ title = "", value = "", sx = {} }) => {
  return (
    <>
      <Box
        sx={{
          borderRadius: "44px",
          backgroundColor: "text.tertiary",
          fontSize: "10px",
          fontWeight: 400,
          lineHeight: "16px",
          padding: "2px 10px",
          textAlign: "center",
          ...sx,
        }}
      >
        {title}:{value}
      </Box>
    </>
  );
};

const CProducerItem = ({
  title = "",
  content = "",
  filterText = "",
  titleProps = {},
  contentProps = {},
}) => {
  const matchesTitle = match(title, filterText, { insideWords: true });
  const matchesContent = match(content, filterText, { insideWords: true });

  const partsTitle = parse(title, matchesTitle);
  const partsContent = parse(content, matchesContent);
  return (
    <Stack gap={1}>
      <Stack direction={"row"}>
        {partsTitle.map((part, index) => {
          return (
            <Typography
              key={index}
              sx={{
                fontWeight: 600,
                color: part.highlight ? "text.secondary" : "text.accent",
                fontSize: "14px",
                whiteSpace: "pre",
                fontFamily: "Manrope",
              }}
            >
              {part.text}
            </Typography>
          );
        })}
      </Stack>
      <Stack direction={"row"}>
        {partsContent.map((part, index) => {
          return (
            <Typography
              key={index}
              sx={{
                fontWeight: 400,
                color: part.highlight ? "text.secondary" : "text.accent",
                fontSize: "12px",
                whiteSpace: "pre",
                fontFamily: "Manrope",
              }}
            >
              {part.text}
            </Typography>
          );
        })}
      </Stack>
    </Stack>
  );
};

export { EditDetail, StateSpan, CTag, CProducerItem, Badge };
