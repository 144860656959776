// search key in text and return true if exist
export const findString = (key, text) => {
  if (text.toUpperCase().indexOf(key.toUpperCase()) >= 0) return true;
  return false;
};

// captialize the first letter
export const capitalizeFirstLetter = (string) => {
  if (string) return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
  else return string;
};

export const summaryString = (string, len = 30, limit = 10) => {
  if (string?.length > len + limit) {
    return string.substring(0, len) + "...";
  }
  return string;
};

export const get2Names = (string) => {
  if (string.length >= 2) {
    const strs = string.toUpperCase().split(" ");
    if (strs.length >= 2) return strs[0][0] + strs[1][0];
    else return strs[0][0] + strs[0][1];
  } else return "NA";
};
