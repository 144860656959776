import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  raw_wastes: [],
  operation_codes: [],
};

export const wasteSlice = createSlice({
  name: "waste",
  initialState,
  reducers: {
    setRawWastes: (state, action) => {
      state.raw_wastes = action.payload;
    },
    setOperationCodes: (state, action) => {
      state.operation_codes = action.payload;
    },
  },
});

export const { setRawWastes, setOperationCodes } = wasteSlice.actions;

export default wasteSlice.reducer;
