import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Modal,
  Stack,
  Button,
  Typography,
  Divider,
} from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

import { CheckBoxes } from "../../../../components/CustomInput";
import { CExpandDetail } from "../../../../components/CustomModal";
import { useState } from "react";
import { useEffect } from "react";
const FiltersDialog = ({
  open = false,
  selectedOptions = {
    member: {},
    team: {},
    role: {},
  },
  handleClose = function () {},
  handleContinue = function () {},
}) => {
  const [filterModel, setFilterModel] = useState({
    ...selectedOptions,
  });

  useEffect(() => {
    setFilterModel(selectedOptions);
  }, [selectedOptions]);
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            Width: "520px",
            maxWidth: "520px",
            minWidth: "520px",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} padding={5}>
            <PageTitle>Filters</PageTitle>
            <CExpandDetail
              title="Member"
              expanded={true}
              component={
                <>
                  <Stack>
                    <CheckBoxes
                      options={[
                        "Status On",
                        "Status Off",
                        "Archived",
                        "Pending Invite",
                      ]}
                      selectedOptions={filterModel.member}
                      onChange={(value, checked) => {
                        let t = { ...filterModel.member };
                        t[value] = checked;
                        setFilterModel({
                          ...filterModel,
                          member: t,
                        });
                      }}
                    />
                  </Stack>
                </>
              }
            />
            <Divider />
            <CExpandDetail
              title="Team"
              expanded={true}
              component={
                <>
                  <Stack>
                    <CheckBoxes
                      selectedOptions={filterModel.team}
                      options={[
                        "Status On",
                        "Status Off",
                        "Archived",
                        "Empty teams",
                        "Internal Team",
                        "External Team",
                      ]}
                      onChange={(value, checked) => {
                        let t = { ...filterModel.team };
                        t[value] = checked;
                        setFilterModel({
                          ...filterModel,
                          team: t,
                        });
                      }}
                    />
                  </Stack>
                </>
              }
            />
            <Divider />
            <CExpandDetail
              title="Member role"
              expanded={true}
              component={
                <>
                  <Stack>
                    <CheckBoxes
                      selectedOptions={filterModel.role}
                      options={[
                        "Back Office Admin",
                        "Sales Manager",
                        "Sales Person",
                        "Warehouse Manager",
                        "Driver",
                        "Team Manager",
                      ]}
                      onChange={(value, checked) => {
                        let t = { ...filterModel.role };
                        t[value] = checked;
                        setFilterModel({
                          ...filterModel,
                          role: t,
                        });
                      }}
                    />
                  </Stack>
                </>
              }
            />
            <Divider />
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <Button sx={classes.buttonCancel} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                sx={classes.buttonSave}
                onClick={() => {
                  handleContinue(filterModel);
                  handleClose();
                }}
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default FiltersDialog;
