import { Box, Stack } from "@mui/material";
import {
  CardPrimary,
  CardProfile,
  CardTypes,
} from "../../../../components/Card";
import {
  PICKUP_CONTACTS_MODAL,
  PICKUP_DETAIL_MODAL,
  PICKUP_DOCUMENTS_MODAL,
  PICKUP_EXPECTED_MODAL,
  PICKUP_IDENTFICATION_MODAL,
  PICKUP_LOCATION_MODAL,
  PICKUP_MANAGEMENT_MODAL,
  PICKUP_RELATIONSHIP_MODAL,
  PICKUP_SCHEDULE_MODAL,
  PICKUP_WASTE_PRICE_MODAL,
  SUCCESS,
} from "../../../../utils/type";
import { useEffect, useState } from "react";
import PriceHistoryDialog from "../Dialog/PriceUpdateHistoryDialog";
import { addDocument, editPickup } from "../../../../actions/pickup";
import { useDispatch, useSelector } from "react-redux";
import PickupService from "../../../../services/PickupService";
import { capitalizeFirstLetter } from "../../../../utils/string";
import {
  CheckIconInCircle,
  CloseIconInCircle,
} from "../../../../components/icons/cardIcons";
import { classes } from "../../../../utils/class";
import CustomHidden from "../../../../components/CustomHidden";
import { WAREHOUSE_MANAGER } from "../../../../utils/constants";

const Profile = ({ props, activation = false }) => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);

  const [priceUpdateHistory, setPriceUpdateHistory] = useState(false);
  const [priceData, setPriceData] = useState([]);
  const getDocumentStatus = (document) => {
    const documents = props?.documents || [];
    for (let d of documents) {
      if (d.type.toLowerCase() === document) return true;
    }
    return false;
  };

  useEffect(() => {
    if (props?._id)
      PickupService.fetchWastePriceHistoryList(props?._id)
        .then((res) => {
          if (res.data.message === SUCCESS) setPriceData(res.data.data);
        })
        .catch((err) => {});
  }, [props]);
  return (
    <>
      <Stack gap={2}>
        <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
          <CardPrimary type={1} title={"Balance"} content={0} />
          <CardPrimary
            type={2}
            title={"Next collection"}
            content={`In ${props?.next_picking || 0} days`}
          />
          <CardPrimary
            type={3}
            title={"Expiring documents"}
            content={props?.count_expiring_documents || 0}
          />
        </Stack>
        <Stack
          marginBottom={4}
          gap={2}
          direction={"row"}
          justifyContent={"flex-start"}
        >
          <CustomHidden min={1760}>
            <Stack gap={2}>
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Identification"}
                data={{
                  name: props?.name,
                  "official id": props?.official_id,
                  "UCO ID": props?.id,
                  producer: props?.producer_name,
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PICKUP_IDENTFICATION_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              <Stack position={"relative"}>
                <CardProfile
                  disabled={role === WAREHOUSE_MANAGER}
                  title={"Waste price"}
                  data={{
                    "Price per kg": props.price_per_kg,
                  }}
                  extraData={props}
                  align={[1]}
                  type={PICKUP_WASTE_PRICE_MODAL}
                  link="View history updates"
                  linkPos={0}
                  onLinkClick={() => {
                    setPriceUpdateHistory(true);
                  }}
                  onSave={(data, callBack = () => {}) => {
                    dispatch(editPickup(props?._id, data, callBack));
                  }}
                />
                <Stack
                  position={"absolute"}
                  flexDirection={"column"}
                  top={0}
                  left={0}
                  width={"100%"}
                  height={"100%"}
                  sx={{
                    backgroundColor: "white",
                    filter: "blur(10px)",
                    opacity: 0.8,
                  }}
                ></Stack>
                <Stack
                  position={"absolute"}
                  flexDirection={"column"}
                  top={0}
                  left={0}
                  width={"100%"}
                  height={"100%"}
                  zIndex={1}
                  justifyContent={"center"}
                  textAlign={"center"}
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Coming Soon
                </Stack>
              </Stack>
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Collection Hours"}
                extraData={props}
                data={{
                  "Opening time": props?.opening_time,
                  "Closing time": props?.closing_time,
                  "Collection Allowed From": props?.restrictions_from_time,
                  "Collection Allowed To": props?.restrictions_to_time,
                  "Days off": (
                    <Stack flexDirection={"row"} gap={1}>
                      {props?.days_off?.map((days_off, index) => {
                        return (
                          <Box sx={classes.dayOffdays}>
                            {capitalizeFirstLetter(days_off)}
                          </Box>
                        );
                      })}
                    </Stack>
                  ),
                }}
                align={[1, 1, 1, 1, 1]}
                type={PICKUP_SCHEDULE_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
            </Stack>
            <Stack gap={2}>
              <CardTypes
                width="293px"
                title="Raw waste types"
                item="Raw waste"
                data={props?.raw_waste_names || []}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Location"
                activation={activation && !props?.latitude}
                data={{
                  Coordinates: props?.latitude
                    ? `${props.latitude.toFixed(5)}, ${props.longitude.toFixed(
                        5
                      )}`
                    : "",

                  Address: props?.addr_country
                    ? props?.addr_apartment +
                      " " +
                      props?.addr_street +
                      " " +
                      props?.addr_postcode +
                      " " +
                      props?.addr_city +
                      " " +
                      props?.addr_country
                    : "",
                }}
                align={[1, 1]}
                type={PICKUP_LOCATION_MODAL}
                link="View on the map"
                linkPos={0}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
                extraData={props}
              />
            </Stack>
            <Stack gap={2}>
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Collection Contacts"}
                data={{
                  Responsible: props?.responsible_name,
                  Phone: props?.phone,
                  "Mobile phone": props?.mobile_phone,
                  // Fax: props?.fax,
                  Email: props?.email,
                  Notes: props.contact_notes || "No notes",
                }}
                align={[1, 1, 1, 1, 1]}
                type={PICKUP_CONTACTS_MODAL}
                extraData={props}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              {/* <CardProfile
              disabled={role===WAREHOUSE_MANAGER}
                title={"Expected pickup"}
                data={{
                  Frequency: `${props.expect_frequency || 0} days`,
                  Quantity: `${props.expect_quantity || 0} kg`,
                  "Impurity percentage": `${props.expect_impurity || 0} %`,
                }}
                extraData={props}
                align={[1, 1, 1]}
                type={PICKUP_EXPECTED_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              /> */}
            </Stack>
            <Stack gap={2}>
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Relationship Manager"
                extraData={props}
                data={{
                  Name: props?.pickup_point_manager_name,
                  Phone: props?.pickup_point_manager_phone,
                  "Mobile Phone": props?.pickup_point_manager_mobile_phone,
                  Email: props?.pickup_point_manager_email,
                }}
                align={[1, 1, 1, 1]}
                type={PICKUP_RELATIONSHIP_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Collection details"
                data={{
                  "Waste destination Warehouse": props?.warehouse_name,
                  Zone: props?.zone_names?.map((zone) => {
                    return <span>{zone} &nbsp;</span>;
                  }),
                }}
                extraData={props}
                align={[1, 1]}
                type={PICKUP_DETAIL_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Documents"}
                activation={activation && props?.documents.length === 0}
                data={{
                  GDRP: getDocumentStatus("gdrp") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  "Auto declaration": getDocumentStatus("declaration") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  Certification: getDocumentStatus("certification") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  Contract: getDocumentStatus("contract") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PICKUP_DOCUMENTS_MODAL}
                direction="row"
                onSave={(data, callBack = () => {}) => {
                  dispatch(addDocument(props?._id, data, callBack));
                }}
              />
            </Stack>
          </CustomHidden>
          <CustomHidden min={1440} max={1759}>
            <Stack gap={2}>
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Identification"}
                data={{
                  name: props?.name,
                  "official id": props?.official_id,
                  "UCO ID": props?.id,
                  producer: props?.producer_name,
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PICKUP_IDENTFICATION_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Collection details"
                data={{
                  "Waste destination Warehouse": props?.warehouse_name,
                  Zone: props?.zone_names?.map((zone) => {
                    return <span>{zone} &nbsp;</span>;
                  }),
                }}
                extraData={props}
                align={[1, 1]}
                type={PICKUP_DETAIL_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              {/* <CardProfile
              disabled={role===WAREHOUSE_MANAGER}
                title={"Expected pickup"}
                data={{
                  Frequency: `${props.expect_frequency || 0} days`,
                  Quantity: `${props.expect_quantity || 0} kg`,
                  "Impurity percentage": `${props.expect_impurity || 0} %`,
                }}
                extraData={props}
                align={[1, 1, 1]}
                type={PICKUP_EXPECTED_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              /> */}
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Collection Hours"}
                extraData={props}
                data={{
                  "Opening time": props?.opening_time,
                  "Closing time": props?.closing_time,
                  "Collection Allowed From": props?.restrictions_from_time,
                  "Collection Allowed To": props?.restrictions_to_time,
                  "Days off": (
                    <Stack flexDirection={"row"} gap={1}>
                      {props?.days_off?.map((days_off, index) => {
                        return (
                          <Box sx={classes.dayOffdays}>
                            {capitalizeFirstLetter(days_off)}
                          </Box>
                        );
                      })}
                    </Stack>
                  ),
                }}
                align={[1, 1, 1, 1, 1]}
                type={PICKUP_SCHEDULE_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
            </Stack>
            <Stack gap={2}>
              <CardTypes
                width="293px"
                title="Raw waste types"
                item="Raw waste"
                data={props?.raw_waste_names || []}
              />
              <Stack position={"relative"}>
                <CardProfile
                  disabled={role === WAREHOUSE_MANAGER}
                  title={"Waste price"}
                  data={{
                    "Price per kg": props.price_per_kg,
                  }}
                  extraData={props}
                  align={[1]}
                  type={PICKUP_WASTE_PRICE_MODAL}
                  link="View history updates"
                  linkPos={0}
                  onLinkClick={() => {
                    setPriceUpdateHistory(true);
                  }}
                  onSave={(data, callBack = () => {}) => {
                    dispatch(editPickup(props?._id, data, callBack));
                  }}
                />
                <Stack
                  position={"absolute"}
                  flexDirection={"column"}
                  top={0}
                  left={0}
                  width={"100%"}
                  height={"100%"}
                  sx={{
                    backgroundColor: "white",
                    filter: "blur(10px)",
                    opacity: 0.8,
                  }}
                ></Stack>
                <Stack
                  position={"absolute"}
                  flexDirection={"column"}
                  top={0}
                  left={0}
                  width={"100%"}
                  height={"100%"}
                  zIndex={1}
                  justifyContent={"center"}
                  textAlign={"center"}
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Coming Soon
                </Stack>
              </Stack>
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Location"
                activation={activation && !props?.latitude}
                data={{
                  Coordinates: props?.latitude
                    ? `${props.latitude.toFixed(5)}, ${props.longitude.toFixed(
                        5
                      )}`
                    : "",

                  Address: props?.addr_country
                    ? props?.addr_apartment +
                      " " +
                      props?.addr_street +
                      " " +
                      props?.addr_postcode +
                      " " +
                      props?.addr_city +
                      " " +
                      props?.addr_country
                    : "",
                }}
                align={[1, 1]}
                type={PICKUP_LOCATION_MODAL}
                link="View on the map"
                linkPos={0}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
                extraData={props}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Relationship Manager"
                extraData={props}
                data={{
                  Name: props?.pickup_point_manager_name,
                  Phone: props?.pickup_point_manager_phone,
                  "Mobile Phone": props?.pickup_point_manager_mobile_phone,
                  Email: props?.pickup_point_manager_email,
                }}
                align={[1, 1, 1, 1]}
                type={PICKUP_RELATIONSHIP_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
            </Stack>
            <Stack gap={2}>
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Collection Contacts"}
                data={{
                  Responsible: props?.responsible_name,
                  Phone: props?.phone,
                  "Mobile phone": props?.mobile_phone,
                  // Fax: props?.fax,
                  Email: props?.email,
                  Notes: props.contact_notes || "No notes",
                }}
                align={[1, 1, 1, 1, 1]}
                type={PICKUP_CONTACTS_MODAL}
                extraData={props}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Documents"}
                activation={activation && props?.documents.length === 0}
                data={{
                  GDRP: getDocumentStatus("gdrp") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  "Auto declaration": getDocumentStatus("declaration") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  Certification: getDocumentStatus("certification") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  Contract: getDocumentStatus("contract") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PICKUP_DOCUMENTS_MODAL}
                direction="row"
                onSave={(data, callBack = () => {}) => {
                  dispatch(addDocument(props?._id, data, callBack));
                }}
              />
            </Stack>
          </CustomHidden>
          <CustomHidden min={1110} max={1439}>
            <Stack gap={2}>
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Identification"}
                data={{
                  name: props?.name,
                  "official id": props?.official_id,
                  "UCO ID": props?.id,
                  producer: props?.producer_name,
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PICKUP_IDENTFICATION_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Collection details"
                data={{
                  "Waste destination Warehouse": props?.warehouse_name,
                  Zone: props?.zone_names?.map((zone) => {
                    return <span>{zone} &nbsp;</span>;
                  }),
                }}
                extraData={props}
                align={[1, 1]}
                type={PICKUP_DETAIL_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              {/* <CardProfile
              disabled={role===WAREHOUSE_MANAGER}
                title={"Expected pickup"}
                data={{
                  Frequency: `${props.expect_frequency || 0} days`,
                  Quantity: `${props.expect_quantity || 0} kg`,
                  "Impurity percentage": `${props.expect_impurity || 0} %`,
                }}
                extraData={props}
                align={[1, 1, 1]}
                type={PICKUP_EXPECTED_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              /> */}
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Collection Hours"}
                extraData={props}
                data={{
                  "Opening time": props?.opening_time,
                  "Closing time": props?.closing_time,
                  "Collection Allowed From": props?.restrictions_from_time,
                  "Collection Allowed To": props?.restrictions_to_time,
                  "Days off": (
                    <Stack flexDirection={"row"} gap={1}>
                      {props?.days_off?.map((days_off, index) => {
                        return (
                          <Box sx={classes.dayOffdays}>
                            {capitalizeFirstLetter(days_off)}
                          </Box>
                        );
                      })}
                    </Stack>
                  ),
                }}
                align={[1, 1, 1, 1, 1]}
                type={PICKUP_SCHEDULE_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Collection Contacts"}
                data={{
                  Responsible: props?.responsible_name,
                  Phone: props?.phone,
                  "Mobile phone": props?.mobile_phone,
                  // Fax: props?.fax,
                  Email: props?.email,
                  Notes: props.contact_notes || "No notes",
                }}
                align={[1, 1, 1, 1, 1]}
                type={PICKUP_CONTACTS_MODAL}
                extraData={props}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
            </Stack>
            <Stack gap={2}>
              <CardTypes
                width="293px"
                title="Raw waste types"
                item="Raw waste"
                data={props?.raw_waste_names || []}
              />
              <Stack position={"relative"}>
                <CardProfile
                  disabled={role === WAREHOUSE_MANAGER}
                  title={"Waste price"}
                  data={{
                    "Price per kg": props.price_per_kg,
                  }}
                  extraData={props}
                  align={[1]}
                  type={PICKUP_WASTE_PRICE_MODAL}
                  link="View history updates"
                  linkPos={0}
                  onLinkClick={() => {
                    setPriceUpdateHistory(true);
                  }}
                  onSave={(data, callBack = () => {}) => {
                    dispatch(editPickup(props?._id, data, callBack));
                  }}
                />
                <Stack
                  position={"absolute"}
                  flexDirection={"column"}
                  top={0}
                  left={0}
                  width={"100%"}
                  height={"100%"}
                  sx={{
                    backgroundColor: "white",
                    filter: "blur(10px)",
                    opacity: 0.8,
                  }}
                ></Stack>
                <Stack
                  position={"absolute"}
                  flexDirection={"column"}
                  top={0}
                  left={0}
                  width={"100%"}
                  height={"100%"}
                  zIndex={1}
                  justifyContent={"center"}
                  textAlign={"center"}
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Coming Soon
                </Stack>
                <CardProfile
                  disabled={role === WAREHOUSE_MANAGER}
                  title={"Collection Contacts"}
                  data={{
                    Responsible: props?.responsible_name,
                    Phone: props?.phone,
                    "Mobile phone": props?.mobile_phone,
                    // Fax: props?.fax,
                    Email: props?.email,
                    Notes: props.contact_notes || "No notes",
                  }}
                  align={[1, 1, 1, 1, 1]}
                  type={PICKUP_CONTACTS_MODAL}
                  extraData={props}
                  onSave={(data, callBack = () => {}) => {
                    dispatch(editPickup(props?._id, data, callBack));
                  }}
                />
              </Stack>
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Location"
                activation={activation && !props?.latitude}
                data={{
                  Coordinates: props?.latitude
                    ? `${props.latitude.toFixed(5)}, ${props.longitude.toFixed(
                        5
                      )}`
                    : "",

                  Address: props?.addr_country
                    ? props?.addr_apartment +
                      " " +
                      props?.addr_street +
                      " " +
                      props?.addr_postcode +
                      " " +
                      props?.addr_city +
                      " " +
                      props?.addr_country
                    : "",
                }}
                align={[1, 1]}
                type={PICKUP_LOCATION_MODAL}
                link="View on the map"
                linkPos={0}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
                extraData={props}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Relationship Manager"
                extraData={props}
                data={{
                  Name: props?.pickup_point_manager_name,
                  Phone: props?.pickup_point_manager_phone,
                  "Mobile Phone": props?.pickup_point_manager_mobile_phone,
                  Email: props?.pickup_point_manager_email,
                }}
                align={[1, 1, 1, 1]}
                type={PICKUP_RELATIONSHIP_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Documents"}
                activation={activation && props?.documents.length === 0}
                data={{
                  GDRP: getDocumentStatus("gdrp") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  "Auto declaration": getDocumentStatus("declaration") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  Certification: getDocumentStatus("certification") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  Contract: getDocumentStatus("contract") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PICKUP_DOCUMENTS_MODAL}
                direction="row"
                onSave={(data, callBack = () => {}) => {
                  dispatch(addDocument(props?._id, data, callBack));
                }}
              />
            </Stack>
          </CustomHidden>
          <CustomHidden min={0} max={1109}>
            <Stack gap={2}>
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Identification"}
                data={{
                  name: props?.name,
                  "official id": props?.official_id,
                  "UCO ID": props?.id,
                  producer: props?.producer_name,
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PICKUP_IDENTFICATION_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Collection details"
                data={{
                  "Waste destination Warehouse": props?.warehouse_name,
                  Zone: props?.zone_names?.map((zone) => {
                    return <span>{zone} &nbsp;</span>;
                  }),
                }}
                extraData={props}
                align={[1, 1]}
                type={PICKUP_DETAIL_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              {/* <CardProfile
              disabled={role===WAREHOUSE_MANAGER}
                title={"Expected pickup"}
                data={{
                  Frequency: `${props.expect_frequency || 0} days`,
                  Quantity: `${props.expect_quantity || 0} kg`,
                  "Impurity percentage": `${props.expect_impurity || 0} %`,
                }}
                extraData={props}
                align={[1, 1, 1]}
                type={PICKUP_EXPECTED_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              /> */}
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Collection Hours"}
                extraData={props}
                data={{
                  "Opening time": props?.opening_time,
                  "Closing time": props?.closing_time,
                  "Collection Allowed From": props?.restrictions_from_time,
                  "Collection Allowed To": props?.restrictions_to_time,
                  "Days off": (
                    <Stack flexDirection={"row"} gap={1}>
                      {props?.days_off?.map((days_off, index) => {
                        return (
                          <Box sx={classes.dayOffdays}>
                            {capitalizeFirstLetter(days_off)}
                          </Box>
                        );
                      })}
                    </Stack>
                  ),
                }}
                align={[1, 1, 1, 1, 1]}
                type={PICKUP_SCHEDULE_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Collection Contacts"}
                data={{
                  Responsible: props?.responsible_name,
                  Phone: props?.phone,
                  "Mobile phone": props?.mobile_phone,
                  // Fax: props?.fax,
                  Email: props?.email,
                  Notes: props.contact_notes || "No notes",
                }}
                align={[1, 1, 1, 1, 1]}
                type={PICKUP_CONTACTS_MODAL}
                extraData={props}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />

              <CardTypes
                width="293px"
                title="Raw waste types"
                item="Raw waste"
                data={props?.raw_waste_names || []}
              />
              <Stack position={"relative"}>
                <CardProfile
                  disabled={role === WAREHOUSE_MANAGER}
                  title={"Waste price"}
                  data={{
                    "Price per kg": props.price_per_kg,
                  }}
                  extraData={props}
                  align={[1]}
                  type={PICKUP_WASTE_PRICE_MODAL}
                  link="View history updates"
                  linkPos={0}
                  onLinkClick={() => {
                    setPriceUpdateHistory(true);
                  }}
                  onSave={(data, callBack = () => {}) => {
                    dispatch(editPickup(props?._id, data, callBack));
                  }}
                />
                <Stack
                  position={"absolute"}
                  flexDirection={"column"}
                  top={0}
                  left={0}
                  width={"100%"}
                  height={"100%"}
                  sx={{
                    backgroundColor: "white",
                    filter: "blur(10px)",
                    opacity: 0.8,
                  }}
                ></Stack>
                <Stack
                  position={"absolute"}
                  flexDirection={"column"}
                  top={0}
                  left={0}
                  width={"100%"}
                  height={"100%"}
                  zIndex={1}
                  justifyContent={"center"}
                  textAlign={"center"}
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Coming Soon
                </Stack>
                <CardProfile
                  disabled={role === WAREHOUSE_MANAGER}
                  title={"Collection Contacts"}
                  data={{
                    Responsible: props?.responsible_name,
                    Phone: props?.phone,
                    "Mobile phone": props?.mobile_phone,
                    // Fax: props?.fax,
                    Email: props?.email,
                    Notes: props.contact_notes || "No notes",
                  }}
                  align={[1, 1, 1, 1, 1]}
                  type={PICKUP_CONTACTS_MODAL}
                  extraData={props}
                  onSave={(data, callBack = () => {}) => {
                    dispatch(editPickup(props?._id, data, callBack));
                  }}
                />
              </Stack>
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Location"
                activation={activation && !props?.latitude}
                data={{
                  Coordinates: props?.latitude
                    ? `${props.latitude.toFixed(5)}, ${props.longitude.toFixed(
                        5
                      )}`
                    : "",

                  Address: props?.addr_country
                    ? props?.addr_apartment +
                      " " +
                      props?.addr_street +
                      " " +
                      props?.addr_postcode +
                      " " +
                      props?.addr_city +
                      " " +
                      props?.addr_country
                    : "",
                }}
                align={[1, 1]}
                type={PICKUP_LOCATION_MODAL}
                link="View on the map"
                linkPos={0}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
                extraData={props}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title="Relationship Manager"
                extraData={props}
                data={{
                  Name: props?.pickup_point_manager_name,
                  Phone: props?.pickup_point_manager_phone,
                  "Mobile Phone": props?.pickup_point_manager_mobile_phone,
                  Email: props?.pickup_point_manager_email,
                }}
                align={[1, 1, 1, 1]}
                type={PICKUP_RELATIONSHIP_MODAL}
                onSave={(data, callBack = () => {}) => {
                  dispatch(editPickup(props?._id, data, callBack));
                }}
              />
              <CardProfile
                disabled={role === WAREHOUSE_MANAGER}
                title={"Documents"}
                activation={activation && props?.documents.length === 0}
                data={{
                  GDRP: getDocumentStatus("gdrp") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  "Auto declaration": getDocumentStatus("declaration") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  Certification: getDocumentStatus("certification") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                  Contract: getDocumentStatus("contract") ? (
                    <CheckIconInCircle />
                  ) : (
                    <CloseIconInCircle />
                  ),
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PICKUP_DOCUMENTS_MODAL}
                direction="row"
                onSave={(data, callBack = () => {}) => {
                  dispatch(addDocument(props?._id, data, callBack));
                }}
              />
            </Stack>
          </CustomHidden>
        </Stack>
      </Stack>
      <PriceHistoryDialog
        data={priceData}
        open={priceUpdateHistory}
        handleClose={() => {
          setPriceUpdateHistory(false);
        }}
      />
    </>
  );
};
export default Profile;
