import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material ui
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

// proj imports
import { InputTitle, PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import {
  CAutoComplete,
  COfficialIdInput,
  CPhoneInput,
  CTextInput,
} from "../../../../components/CustomInput";
import CountrySelect from "../../../../components/CountrySelect";
import { COUNTRIES_INFO } from "../../../../utils/constants";
import UnsavedDialog from "../dialogs/UnsavedDialog";
import { updateOrganizationInfo } from "../../../../actions/organizations";
import moment from "moment-timezone";

const IdentificationView = ({ setStage }) => {
  const dispatch = useDispatch();
  const [country, setCountry] = useState({
    code: "PT",
    label: "Portugal",
    phone: "351",
  });

  const company = useSelector((state) => state.organizations.profile);

  const [name, setName] = useState(company.company_name);
  const [unsavedOpen, setUnsavedOpen] = useState(false);
  const [vat, setVAT] = useState(company.vat_number);
  const [nId, setNId] = useState(company.id);
  const [hasOId, setHasOId] = useState(false);
  const [oId, setOId] = useState("");
  const [vatCountry, setVatCountry] = useState(null);
  const [timezone, setTimezone] = useState(null);

  useEffect(() => {
    setName(company.company_name);
    setVAT(company.vat_number);
    setNId("UCO 59787");
    setCountry(COUNTRIES_INFO.find((info) => info.code === company.country));
    setVatCountry(company.vat_number_country);
    setNId(company.id);
    setTimezone(company.timezone);
    if (company.official_id) {
      setHasOId(true);
      setOId(company.official_id);
    }
  }, [company]);

  const onChangeCountry = (e, v) => {
    setCountry(v);
  };

  const onClickCancel = () => {
    if (
      name === company.company_name &&
      country.code === company.country &&
      vat === company.vat_number
    ) {
      setStage(0);
      return;
    }
    setUnsavedOpen(true);
  };

  const handleSave = () => {
    if (name.length === 0 || vat.length === 0) {
      return;
    }
    dispatch(
      updateOrganizationInfo(
        {
          company_name: name,
          vat_number: vat,
          country: country.code,
          vat_number_country: vatCountry,
          official_id: oId,
          official_id_country: country.code,
          timezone: timezone,
        },
        () => setStage(0)
      )
    );
  };

  return (
    <Box>
      <PageTitle>Company</PageTitle>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          display: "flex",
          minHeight: "80%",
          marginTop: "40px",
        }}
      >
        <Box sx={{ width: "40%", minWidth: "300px" }}></Box>
        <Stack sx={{ width: "100%", maxWidth: "500px" }} spacing={2}>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
          >
            Identification
          </Typography>
          <CTextInput
            title="Company tax name"
            required={true}
            placeholder="Input name here"
            value={name}
            onChange={(val) => setName(val)}
          />
          <Box>
            <InputTitle>Country</InputTitle>
            <CountrySelect
              label=""
              required={true}
              value={country}
              showNumber={false}
              sx={{ width: "100%" }}
              onChange={onChangeCountry}
            />
          </Box>
          <CAutoComplete
            title="Time zone"
            placeholder="Select time zone"
            fixed
            required
            value={timezone}
            onChange={(value) => setTimezone(value)}
            options={moment.tz.zonesForCountry(country.code)}
          />
          <COfficialIdInput
            fullWidth
            label="VAT Number"
            countryCode={vatCountry}
            required={true}
            onCountryChange={(e, v) => setVatCountry(v.code)}
            value={vat}
            showStartAdornment={false}
            onChange={(e) => setVAT(e.target.value)}
          />
          <CTextInput
            title="UCO.network ID"
            required={true}
            disabled={true}
            value={nId}
          />
          <FormControlLabel
            value="end"
            control={
              <Switch
                color="primary"
                checked={hasOId}
                onChange={() => setHasOId(!hasOId)}
              />
            }
            label="This company has Official ID"
            labelPlacement="end"
          />
          {hasOId && (
            <CTextInput
              title="Official ID"
              required={true}
              value={oId}
              onChange={(v) => setOId(v)}
            />
          )}
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "space-between", marginTop: "20px" }}
          >
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={onClickCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonSave}
              onClick={handleSave}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <UnsavedDialog
        open={unsavedOpen}
        handleLeave={() => setStage(0)}
        handleClose={() => setUnsavedOpen(false)}
        handleSave={handleSave}
      />
    </Box>
  );
};

export default IdentificationView;
