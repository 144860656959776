import { Button, IconButton, Modal, Stack, Typography } from "@mui/material";
import { classes } from "../../utils/class";
import CloseIcon from "@mui/icons-material/Close";
import { Android12Switch, COfficialIdInput, CTextInput } from "../CustomInput";
import { PageTitle, InputTitle, SubTitle } from "../Typography";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { showNotification } from "../../utils/helpers";

export const CollectionPointIdModal = ({
  open,
  title,
  data = {},
  handleCancel,
  handleClose,
  handleSave,
}) => {
  const { profile } = useSelector((state) => state.user);
  const [officialIdCountry, setOfficialIdCountry] = useState(
    profile.countryCode
  );
  const [hasOfficialId, setHasOfficialId] = useState(false);
  const [collectionPointName, setCollectionPointName] = useState("");
  const [description, setDescription] = useState("");
  const [officialId, setOfficialId] = useState("");
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    setHasOfficialId(false);
    setCollectionPointName(data?.collectionPointName ?? "");
    setDescription(data?.description ?? "");
    setOfficialId(data?.officialId ?? "");
    setOfficialIdCountry(
      data?.officialIdCountry ? data.officialIdCountry : profile.countryCode
    );
    setValidation(false);
  }, [open]);

  const onConfirmSave = () => {
    let data = {};
    if (hasOfficialId) {
      if (
        collectionPointName &&
        description &&
        officialIdCountry &&
        officialId
      ) {
        data = {
          collectionPointName,
          description,
          officialIdCountry,
          officialId,
        };
        handleSave(data);
      } else {
        setValidation(true);
      }
    } else {
      if (collectionPointName && description) {
        data = {
          collectionPointName,
          description,
        };
        handleSave(data);
        setValidation(false);
      } else {
        setValidation(true);
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setHasOfficialId(false);
          handleClose();
        }}
      >
        <Stack sx={classes.popup}>
          <PageTitle>{title}</PageTitle>
          <IconButton
            onClick={() => {
              setHasOfficialId(false);
              handleClose();
            }}
            sx={{
              position: "absolute",
              right: "42px",
              top: "34px",
            }}
          >
            <CloseIcon />
          </IconButton>
          <CTextInput
            required
            title="Collection point name"
            placeholder="Enter your company name"
            validation={validation}
            value={collectionPointName}
            onChange={(val) => {
              setCollectionPointName(val);
            }}
          />
          <CTextInput
            rows={3}
            required
            title="Description"
            placeholder="Enter Collection Point description here"
            validation={validation}
            value={description}
            onChange={(val) => {
              setDescription(val);
            }}
          />
          <Stack direction={"row"} alignItems={"center"} gap={4}>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              This Collection point has Official ID
            </Typography>
            <Android12Switch
              onChange={(e) => {
                setHasOfficialId(e.target.checked);
              }}
            />
          </Stack>
          <Stack>
            {hasOfficialId && (
              <COfficialIdInput
                required
                label="Official Id Number"
                countryCode={officialIdCountry}
                placeholder="Enter certificate number"
                value={officialId}
                validation={validation}
                onCountryChange={(e, data) => {
                  setOfficialIdCountry(data.code);
                }}
                onChange={(e) => {
                  setOfficialId(e.target.value);
                }}
              />
            )}
          </Stack>
          <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
            <Button
              sx={classes.buttonCancel}
              onClick={() => {
                setHasOfficialId(false);
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button sx={classes.buttonSave} onClick={onConfirmSave}>
              Save and next
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
