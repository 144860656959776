import { waste_axios } from "../utils/axios";

const fetchAllRawWastes = () => {
  return waste_axios.get(`/list-wastes/`);
};
const fetchOperationCodes = () => {
  return waste_axios.get(`/list-operation-codes/`);
};
const checkWarehouseAvailable = (warehouseId, wasteId, data) => {
  return waste_axios.post(
    `/check-warehouse-deposit-available/${warehouseId}/${wasteId}`,
    data
  );
};
const checkVehicleAvailable = (vehicleId, wasteId, data) => {
  return waste_axios.post(
    `/check-vehicle-available/${vehicleId}/${wasteId}`,
    data
  );
};
const assignWastesToVehicle = (data) => {
  return waste_axios.post("/assign-route-waste", data);
};
const WasteService = {
  fetchAllRawWastes,
  fetchOperationCodes,
  checkWarehouseAvailable,
  checkVehicleAvailable,
  assignWastesToVehicle,
};

export default WasteService;
