import { Box, Stack } from "@mui/material";
import { BalanceHistoryIcon } from "../../../components/icons/menu_icons/icons";
import { classes } from "../../../utils/class";

const Balance = () => {
  return (
    <Stack
      flexDirection={"row"}
      justifyContent={"space-between"}
      sx={{ position: "relative" }}
      minHeight={"100vh"}
    >
      {(process.env.REACT_APP_ENV === "stage" ||
        process.env.REACT_APP_ENV === "prod") && (
        <>
          <Stack sx={classes.comingSoonModal} gap={4}>
            <Stack
              flexDirection={"row"}
              sx={{
                fontSize: "32px",
                fontWeight: 700,
                alignItems: "center",
                fontFamily: "Manrope",
              }}
              gap={2}
            >
              <BalanceHistoryIcon fill="black" /> <Box>Balances </Box>
            </Stack>
            <Stack
              sx={{
                fontSize: "14px",
                fontFamily: "Manrope",
                lineHeight: "20px",
              }}
            >
              The new balances feature will work closely with other features
              within the UCO Network. Balances enables WMOs WMOs and Producers
              the ability to transact with one another
            </Stack>
            <Stack
              flexDirection={"row"}
              sx={{
                fontSize: "14px",
                fontFamily: "Manrope",
                lineHeight: "20px",
              }}
              gap={6}
            >
              <Stack sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                MANAGE BALANCES
              </Stack>
              <Stack>
                Add and manage balance of credits, credits are used to initiate
                collections and WTN generation.
              </Stack>
            </Stack>
            <Stack
              flexDirection={"row"}
              sx={{
                fontSize: "14px",
                fontFamily: "Manrope",
                lineHeight: "20px",
              }}
              gap={6}
            >
              <Stack sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                WASTE PRICES
              </Stack>
              <Stack>
                Assign waste prices for collections, this can be set as a fee or
                price to collect.
              </Stack>
            </Stack>
            <Stack
              flexDirection={"row"}
              sx={{
                fontSize: "14px",
                fontFamily: "Manrope",
                lineHeight: "20px",
              }}
              gap={6}
            >
              <Stack sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                UCO PAYMENTS
              </Stack>
              <Stack>Make and receive payments for UCO collections.</Stack>
            </Stack>
            <Stack
              flexDirection={"row"}
              sx={{
                fontSize: "14px",
                fontFamily: "Manrope",
                lineHeight: "20px",
              }}
              gap={6}
            >
              <Stack sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                Offers
              </Stack>
              <Stack>Charge and pay for offers supplied by WMOs.</Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1,
              backgroundColor: "rgba(217, 217, 217, 0.75)",
            }}
          ></Stack>
        </>
      )}
    </Stack>
  );
};
export default Balance;
