import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import { useSnackbar } from "notistack";

import {
  BigTitle,
  PageTitle,
  SubTitle,
} from "../../../../components/Typography";
import {
  COfficialIdInput,
  CTextInput,
} from "../../../../components/CustomInput";
import { WarehouseIcon } from "../../../../components/icons/menu_icons/icons";
import { EmptyWizard } from "../../../../components/wizard/Card";
import { CustomMap } from "../../../../components/CustomMap";
import { DataTable } from "../../../../components/Table";
import { EditDetail } from "../../../../components/StateSpan";
import { showNotification, snackbarRef } from "../../../../utils/helpers";
import { classes } from "../../../../utils/class";
import { SUCCESS } from "../../../../utils/type";
import { summaryString } from "../../../../utils/string";
import { ERROR } from "../../../../utils/constants";
import { fetchWarehouseList } from "../../../../actions/warehouse";
import WarehouseService from "../../../../services/WarehouseService";
import AddDepositDialog from "./AddDepositDialog";
import EditDepositDialog from "./EditDepositDialog";
import RemoveDepositDialog from "./RemoveDepositDialog";

const AddWarehouse = ({
  onCancelHandler = () => {},
  onSaveHandler = () => {},
  onEditHandler = () => {},
  data = null,
  editMode = false,
}) => {
  const [usingCompanyAddress, setUsingCompanyAddress] = useState(false);
  const { profile } = useSelector((state) => state.user);
  const orgData = useSelector((state) => state.organizations.profile);
  const [hasId, setHasId] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [location_data, setLocationData] = useState(null);
  const [officialId_country, setOfficialIdCountry] = useState(
    profile.countryCode
  );
  const [currentRawWaste, setCurrent] = useState(null);
  const [editDlgOpen, setEditDlgOpen] = useState(false);
  const [removeDlgOpen, setRemoveDlgOpen] = useState(false);

  const columns = [
    {
      field: "name",
      width: "300",
      flex: 2,
      headerName: "Raw Waste",
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "capacity",
      width: "150",
      flex: 1,
      headerName: "capacity",
      renderCell: (props) => {
        return (
          <span className="mui-ellipsis">
            {parseFloat(props.value).toLocaleString()} kg
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      renderCell: (props) => {
        return (
          <EditDetail
            options={[
              { label: "Edit capacity", value: "edit" },
              { label: "Remove capacity", value: "remove" },
            ]}
            props={props}
            onStateChangeHandler={async (status) => {
              setCurrent(props.row);
              if (status === "edit") setEditDlgOpen(true);
              if (status === "remove") setRemoveDlgOpen(true);
            }}
          />
        );
      },
    },
  ];

  const [capacities, setCapacity] = useState([]);
  const [addCapacityDialog, setDialog] = useState(false);
  const [step, setStep] = useState(0);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    if (usingCompanyAddress) {
      if (orgData.latitude && orgData.longitude) {
        console.log(orgData);
        setLocationData({
          latitude: orgData.latitude,
          longitude: orgData.longitude,
          addr_country: orgData?.addr_country,
          addr_city: orgData?.addr_city,
          addr_apartment: orgData?.addr_apartment,
          addr_postcode: orgData?.addr_postcode,
          addr_street: orgData?.addr_street,
        });
      } else {
        showNotification("Company address is not defined", "info");
        setUsingCompanyAddress(false);
      }
    }
  }, [usingCompanyAddress, orgData]);

  useEffect(() => {
    if (editMode && data) {
      setHasId(data.hasId);
      setName(data.name);
      setOfficialIdCountry(data.official_id_country);
      setNumber(data.official_id);
      setCapacity(data.capacities);
    }
  }, [editMode, data]);

  const save = () => {
    const data = {
      name,
      official_id_country: officialId_country,
      official_id: number,
      ...location_data,
      capacities,
      hasId,
    };
    if (editMode) {
      onEditHandler(data);
    } else {
      onSaveHandler(data);
    }
  };

  return (
    <>
      {step === 0 && (
        <Stack px={"60px"} py={"20px"} gap={5}>
          <Stack justifyContent={"flex-end"} flexDirection={"row"} gap={1}>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={onCancelHandler}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonSave}
              onClick={() => {
                if (
                  name &&
                  name.length > 0 &&
                  ((hasId && number && number !== "") || !hasId)
                )
                  setStep(1);
                else {
                  setValidation(true);
                }
              }}
            >
              Next
            </Button>
          </Stack>
          <Stack>
            <PageTitle>Identification</PageTitle>
          </Stack>

          <CustomMap
            extraComponent={
              <Box
                sx={{
                  alignItems: "flex-start",
                }}
              >
                <CTextInput
                  title="Warehouse name"
                  required
                  validation={validation}
                  placeholder="Warehouse name"
                  value={name}
                  onChange={(v) => setName(v)}
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked
                        checked={hasId}
                        onChange={() => setHasId(!hasId)}
                      />
                    }
                    label="This warehouse has Official ID"
                  />
                </FormGroup>
                {hasId && (
                  <COfficialIdInput
                    required
                    validation={validation}
                    label="Official ID"
                    countryCode={officialId_country}
                    content=""
                    placeholder="Number"
                    value={number}
                    onCountryChange={(e, data) =>
                      setOfficialIdCountry(data.code)
                    }
                    onChange={(e) => setNumber(e.target.value)}
                  />
                )}
                <SubTitle>Location</SubTitle>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked
                        checked={usingCompanyAddress}
                        onChange={() =>
                          setUsingCompanyAddress(!usingCompanyAddress)
                        }
                      />
                    }
                    label="Use company address details"
                  />
                </FormGroup>
              </Box>
            }
            description={"warehouse"}
            data={location_data}
            onChange={(d) => {
              setLocationData(d);
            }}
          />
        </Stack>
      )}
      {step === 1 && (
        <Stack
          flexDirection={"row"}
          sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
        >
          <Stack minWidth={"420px"} maxWidth={"420px"}>
            <Stack
              sx={{ backgroundColor: "#F7F6F3" }}
              pt={"72px"}
              px={"48px"}
              pb={"101px"}
              gap={"21px"}
            >
              <BigTitle> {name} - Waste capacity </BigTitle>
            </Stack>
            <Stack padding={"48px"} gap={"10px"}>
              We now need to add the waste types and capacity that this
              warehouse is able to store, you can add more than one waste type.
            </Stack>
          </Stack>
          <Stack
            width={"calc(100% - 420px);"}
            gap={2}
            padding={5}
            justifyContent={"flex-start"}
            textAlign={"flex-start"}
            overflow={"auto"}
            sx={{
              backgroundColor: "white",
            }}
          >
            <Stack justifyContent={"flex-end"} flexDirection={"row"} gap={1}>
              <Button
                variant="outlined"
                sx={classes.buttonPrimary}
                onClick={() => {
                  setStep(0);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                sx={classes.buttonSave}
                onClick={() => {
                  save();
                }}
              >
                Save
              </Button>
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
              gap={1}
            >
              {capacities.length === 0 ? (
                <>
                  <EmptyWizard
                    title={
                      "No waste types have been added to this warehouse yet"
                    }
                    content={
                      "Add the first waste type to this warehouse to continue. You can manage your warehouse at anytime"
                    }
                    buttonText={"Add waste Type"}
                    onAdd={() => {
                      setDialog(true);
                    }}
                    Icon={
                      <WaterDropOutlinedIcon
                        sx={{ color: "text.tertiary", fontSize: 100 }}
                      />
                    }
                  />
                </>
              ) : (
                <>
                  <DataTable
                    rows={capacities.map((item) => {
                      return {
                        ...item,
                        id: item.raw_waste,
                      };
                    })}
                    columns={columns}
                  ></DataTable>
                  <Button
                    sx={classes.buttonSave}
                    onClick={() => {
                      setDialog(true);
                    }}
                  >
                    Add capacity
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
          <RemoveDepositDialog
            open={removeDlgOpen}
            onClose={() => {
              setRemoveDlgOpen(false);
            }}
            onRemove={() => {
              let caps = capacities.filter((capacity) => {
                return capacity.name !== currentRawWaste.name;
              });
              setCapacity(caps);
            }}
          />
          <EditDepositDialog
            open={editDlgOpen}
            onContinue={(capacity) => {
              let caps = [...capacities];
              for (let cap of caps) {
                if (cap.name === currentRawWaste.name) {
                  cap.capacity = capacity;
                  break;
                }
              }
              setCapacity(caps);
            }}
            onClose={() => {
              setEditDlgOpen(false);
            }}
            rawWaste={currentRawWaste}
          />
          <AddDepositDialog
            open={addCapacityDialog}
            onContinue={(id, name, capacity) => {
              let caps = [...capacities];
              let flag = false;
              for (let cap of caps) {
                if (cap.raw_waste === id) {
                  cap.capacity = Number(cap.capacity) + Number(capacity);
                  flag = true;
                  break;
                }
              }
              if (flag === false) caps.push({ raw_waste: id, name, capacity });
              setCapacity(caps);
            }}
            onClose={() => {
              setDialog(false);
            }}
          />
        </Stack>
      )}
    </>
  );
};

const SetupWarehouse = () => {
  const dispatch = useDispatch();
  // const warehouses = useSelector((state) => state.warehouse.warehouses);

  snackbarRef.current = useSnackbar();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [warehouseDetails, setWarehouseDetails] = useState([]);
  const [currentWarehouse, setCurrentWarehouse] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [currentWarehouseIndex, setCurrentWarehouseIndex] = useState(null);
  const confirmWarehouses = async () => {
    for (let warehouse of warehouseDetails) {
      const {
        name,
        official_id,
        officialId_country,
        latitude,
        longitude,
        addr_street,
        addr_apartment,
        addr_postcode,
        addr_city,
        addr_country,
      } = warehouse;
      try {
        const res = await WarehouseService.createWarehouseWizard({
          name,
          official_id,
          officialId_country,
          latitude,
          longitude,
          addr_street,
          addr_apartment,
          addr_postcode,
          addr_city,
          addr_country,
        });
        if (res.data.message === SUCCESS) {
          const warehouseId = res.data.warehouse_id;
          try {
            for (let cap of warehouse.capacities) {
              await WarehouseService.addDeposit(warehouseId, {
                raw_waste: cap.raw_waste,
                capacity: cap.capacity,
              });
            }
            showNotification("New Warehouse has been added", "success");
          } catch (err) {}
        }
      } catch (err) {
        showNotification(
          `While creating the warehouse(${warehouse.name}) error is occured. ` +
            err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      }
    }
    navigate("/auth/reviewing");
  };

  const deleteWarehouse = (name) => {
    let temp = warehouseDetails.filter((wh) => {
      return wh.name !== name;
    });
    setWarehouseDetails(temp);
  };

  useEffect(() => {
    dispatch(fetchWarehouseList());
  }, [dispatch]);

  // useEffect(() => {
  //   if (warehouses.length > 0) {
  //     showNotification(
  //       "You already setup a warehouse. It's the time to setup your fleet",
  //       "success"
  //     );
  //     navigate("/auth/reviewing");
  //   }
  // }, [warehouses, navigate]);

  return (
    <>
      {step === 0 && (
        <Stack
          flexDirection={"row"}
          sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
        >
          <Stack minWidth={"420px"} maxWidth={"420px"}>
            <Stack
              sx={{ backgroundColor: "#F7F6F3" }}
              pt={"72px"}
              px={"48px"}
              pb={"21px"}
              gap={"21px"}
            >
              <BigTitle> Warehouse </BigTitle>
              <Stack sx={{ fontSize: "18px", fontFamily: "Manrope" }} gap={4}>
                <Box>
                  Now that you have set up the waste types your organization
                  works with, you can create a warehouse to store the waste
                  collected from your producers.
                </Box>
                <Box>
                  After completing your onboarding, you can manage additional
                  information for your warehouses from the main menu under&nbsp;
                  <b>Warehouses</b>
                </Box>
              </Stack>
            </Stack>
            <Stack padding={"48px"} gap={"10px"}>
              <Box sx={{ fontWeight: "bold" }}>Why is this important?</Box>
              Warehouses play a crucial role in ensuring that the waste
              collected from producers has a designated storage space. By
              configuring warehouses, we enable the system to assign a
              destination for the waste and record this information in the Waste
              Transfer Note (WTN).
            </Stack>
          </Stack>
          <Stack
            width={"calc(100% - 420px);"}
            gap={2}
            padding={5}
            justifyContent={"flex-start"}
            textAlign={"flex-start"}
            overflow={"auto"}
            sx={{
              backgroundColor: "white",
            }}
          >
            <Stack alignItems={"flex-end"}>
              <Button
                variant="outlined"
                onClick={confirmWarehouses}
                sx={classes.orgCreateButton}
              >
                Confirm Warehouses
              </Button>
            </Stack>
            <Stack
              justifyContent={"flex-start"}
              height={"100%"}
              sx={{
                paddingTop: "30px",
              }}
            >
              {warehouseDetails.length === 0 ? (
                <>
                  <EmptyWizard
                    title="No warehouses have been added yet"
                    content="Add the first warehouse to your organisation to continue. You can manage your warehouses at any time."
                    Icon={<WarehouseIcon size="32px" fill="#33343F" />}
                    buttonText={"Add Warehouse"}
                    onAdd={() => {
                      setStep(1);
                    }}
                  />
                </>
              ) : (
                <>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    overflow={"auto"}
                    justifyContent={"flex-start"}
                    alignItems={"baseline"}
                    py={2}
                  >
                    {warehouseDetails.map((warehouse, index) => {
                      return (
                        <Stack key={index} sx={classes.wizardCard}>
                          <Stack
                            mb={"20px"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "14px",
                              }}
                            >
                              {summaryString(warehouse.name, 15)}
                            </Typography>
                            <Stack flexDirection={"row"}>
                              <Button
                                sx={classes.buttonInCard}
                                onClick={() => {
                                  deleteWarehouse(warehouse.name);
                                }}
                              >
                                Delete
                              </Button>
                              <Button
                                sx={classes.buttonInCard}
                                onClick={() => {
                                  setCurrentWarehouseIndex(index);
                                  setCurrentWarehouse(warehouse);
                                  setEditMode(true);
                                  setStep(1);
                                }}
                              >
                                Edit
                              </Button>
                            </Stack>
                          </Stack>

                          <Stack gap={2}>
                            <Stack sx={{ lineHeight: "26px" }} gap={1}>
                              <Typography sx={classes.wizardTitle}>
                                Coordinates
                              </Typography>
                              <Box>
                                <span
                                  style={{
                                    fontWeight: "600",
                                    paddingRight: "8px",
                                  }}
                                >
                                  Latitude:
                                </span>
                                {warehouse.latitude.toFixed(4) || 0}
                              </Box>
                              <Box>
                                <span
                                  style={{
                                    fontWeight: "600",
                                    paddingRight: "8px",
                                  }}
                                >
                                  Longitude:
                                </span>
                                {warehouse.longitude.toFixed(4) || 0}
                              </Box>
                              <Divider sx={{ mt: "16px" }} />
                            </Stack>
                            <Stack sx={{ lineHeight: "26px" }} gap={1}>
                              <Typography sx={classes.wizardTitle}>
                                Address
                              </Typography>
                              {warehouse.addr_apartment +
                                "," +
                                warehouse.addr_street +
                                "," +
                                warehouse.addr_city +
                                "," +
                                warehouse.addr_country}
                              <Divider sx={{ mt: "16px" }} />
                            </Stack>
                            <Stack sx={{ lineHeight: "26px" }} gap={1}>
                              <Typography sx={classes.wizardTitle}>
                                Raw waste type
                              </Typography>
                              {warehouse.capacities.map((cap, index) => {
                                return (
                                  <Typography key={index}>
                                    {summaryString(cap.name)}
                                  </Typography>
                                );
                              })}
                            </Stack>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                  <Stack alignItems={"flex-start"}>
                    <Button
                      sx={classes.buttonSave}
                      onClick={() => {
                        setStep(1);
                      }}
                    >
                      Add warehouse
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
      {step === 1 && (
        <AddWarehouse
          onCancelHandler={() => {
            setStep(0);
          }}
          onSaveHandler={(data) => {
            setWarehouseDetails([...warehouseDetails, data]);
            setStep(0);
          }}
          onEditHandler={(data) => {
            let temp = [...warehouseDetails];
            temp[currentWarehouseIndex] = data;
            setStep(0);
            setEditMode(false);
            setWarehouseDetails(temp);
          }}
          editMode={editMode}
          data={currentWarehouse}
        />
      )}
    </>
  );
};
export default SetupWarehouse;
