import MemberService from "../services/MemberService";
import {
  setAvailableUsers,
  setCurrentTeam,
  setDaysOff,
  setMembers,
  setRoleList,
  setTeamList,
} from "../slices/memberSlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";

export const createTeam = (data) => (dispatch) => {
  MemberService.createTeam(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchTeamList());
        showNotification("Team created successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

//put

export const changeTeamName = (id, data) => (dispatch) => {
  MemberService.changeTeamName(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchTeamList());
        dispatch(fetchMemberList());
        showNotification("Team Name changed successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};
export const archiveMember = (userId) => (dispatch) => {
  MemberService.archiveMember(userId)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchMemberList());
        showNotification("Member archived successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};
export const changeRole = (userId, role) => (dispatch) => {
  MemberService.changeMemberRole(userId, role)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchMemberList());
        showNotification("Member role changed successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};
export const moveMember = (userId, team) => (dispatch) => {
  MemberService.moveMemberTeam(userId, team)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchMemberList());
        showNotification("Member team changed successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};
// fetch

export const fetchRoleList = () => (dispatch) => {
  MemberService.fetchRoleList()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setRoleList(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const fetchTeamList = () => (dispatch) => {
  MemberService.fetchTeamList()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setTeamList(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const fetchMemberList = () => (dispatch) => {
  MemberService.fetchMemberList()
    .then((res) => {
      if (res.data.message === SUCCESS) dispatch(setMembers(res.data.data));
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const fetchUserList = () => (dispatch) => {
  MemberService.fetchUserList()
    .then((res) => {
      if (res.data.message === SUCCESS)
        dispatch(setAvailableUsers(res.data.data));
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const allowTeam = (teamId) => (dispatch) => {
  MemberService.allowTeam(teamId)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchTeamList());
        showNotification("Team allowed successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const disallowTeam = (teamId) => (dispatch) => {
  MemberService.disallowTeam(teamId)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchTeamList());
        showNotification("Team disallowed successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const cancelInvite =
  (id, callBack = () => {}) =>
  (dispatch) => {
    MemberService.cancelInvite(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Invitation rejected", "success");
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response ? err.response.data.message : err.message,
          "error"
        );
      });
  };

export const inviteMember = (data, navigate) => (dispatch) => {
  MemberService.inviteMember(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchMemberList());
        navigate();
        showNotification(res.data.message, "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data?.message] || "Network Error"
          : "message",
        "error"
      );
    });
};

export const allowMember = (id) => (dispatch) => {
  MemberService.allowMember(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Member Allowed", "success");
        dispatch(fetchMemberList());
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const disallowMember = (id) => (dispatch) => {
  MemberService.disallowMember(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Member Disallowed", "error");
        dispatch(fetchMemberList());
      }
    })
    .catch((err) => {
      dispatch(fetchMemberList());
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : "message",
        "error"
      );
    });
};

export const archiveTeam = (id) => (dispatch) => {
  MemberService.archiveTeam(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Team archived!", "success");
        dispatch(fetchTeamList());
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};
export const unarchiveTeam = (id) => (dispatch) => {
  MemberService.unarchiveTeam(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Team unarchived!", "success");

        dispatch(fetchTeamList());
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const setCurrentTeamData = (data) => (dispatch) => {
  dispatch(setCurrentTeam(data));
};

export const fetchDaysOff = (id) => (dispatch) => {
  MemberService.fetchDaysOff(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setDaysOff(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const addDaysOff =
  (id, data, callBack = () => {}) =>
  (dispatch) => {
    MemberService.addDaysOff(id, data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("new off days added", "success");
          dispatch(fetchDaysOff(id));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const deleteDayOff = (userId, id) => (dispatch) => {
  MemberService.deleteDayOff(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Off days removed", "success");

        dispatch(fetchDaysOff(userId));
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const editDayOff = (userId, id, data) => (dispatch) => {
  MemberService.editDayOff(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Off days edited", "success");

        dispatch(fetchDaysOff(userId));
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};
