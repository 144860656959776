import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Stack, Typography } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { CAutoComplete, CTextInput } from "../../../../components/CustomInput";
import { fetchRawWastes } from "../../../../actions/organizations";
import { showNotification } from "../../../../utils/helpers";
import { classes } from "../../../../utils/class";

const AddDepositDialog = ({
  open,
  onClose,
  onContinue,
  maxCapacity = null,
  rawWastes = null,
}) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState();
  const rawWasteList = useSelector((state) => state.organizations.rawWastes);
  const [validation, setValidataion] = useState(false);
  const [rawWaste, setRawWaste] = useState(null);
  const [capacity, setCapacity] = useState(0);

  useEffect(() => {
    if (rawWastes !== null) {
      const data = rawWastes.map((rw, idx) => {
        return {
          value: rw.rawWasteId,
          name: rw.name,
          component: (
            <Stack>
              <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                {rw.code}
              </Typography>
              <Typography sx={{ color: "grey", fontSize: "12px" }}>
                {rw.name}
              </Typography>
            </Stack>
          ),
        };
      });
      setItems(data);
    } else dispatch(fetchRawWastes());
  }, [rawWastes, dispatch]);

  useEffect(() => {
    setRawWaste(null);
    setCapacity(null);
    setValidataion(false);
  }, [open]);

  useEffect(() => {
    if (rawWastes === null) {
      const data = rawWasteList.map((rw, idx) => {
        return {
          value: rw.rawWasteId,
          name: rw.name,
          component: (
            <Stack>
              <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                {rw.code}
              </Typography>
              <Typography sx={{ color: "grey", fontSize: "12px" }}>
                {rw.name}
              </Typography>
            </Stack>
          ),
        };
      });
      setItems(data);
    }
  }, [rawWasteList, rawWastes]);

  const onSave = () => {
    setValidataion(true);
    if (rawWaste && capacity) {
      if (maxCapacity !== null && Number(maxCapacity) < Number(capacity)) {
        showNotification(
          "Your capacity is bigger than maximum capacity",
          "error"
        );
      } else if (
        Number(maxCapacity) >= Number(capacity) ||
        maxCapacity === null
      ) {
        onContinue(rawWaste?.value, rawWaste?.name, capacity);
        onClose();
      }
    } else showNotification("Input field correctly", "error");
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        padding={"34px 42px"}
        gap={3}
        sx={{
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "520px",
          borderRadius: "8px",
        }}
      >
        <PageTitle>Add Capacity</PageTitle>
        <CAutoComplete
          title="Raw waste type selection"
          placeholder="Select Raw waste"
          required
          validation={validation}
          options={items}
          value={rawWaste}
          onChange={(v) => {
            setRawWaste(v);
          }}
          hasComponent
          fixed
        />
        <CTextInput
          type="number"
          title="Capacity"
          required
          validation={validation}
          endIcon="kg"
          value={capacity}
          placeholder="0"
          onChange={(v) => setCapacity(v)}
        />
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={onClose}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={onSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AddDepositDialog;
