import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, IconButton, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { classes } from "../../utils/class";
import { showNotification } from "../../utils/helpers";
import { PageTitle, InputTitle, SubTitle, SmallTitle } from "../Typography";
import { Android12Switch, COfficialIdInput, CTextInput } from "../CustomInput";
import { CustomMap } from "../CustomMap";
import { CoordinatesMap } from "../CoordinatesMap";

export const DeleteCollectionPointModal = ({
  open,
  title,
  handleCancel,
  handleClose,
  handleSave,
}) => {
  const { profile } = useSelector((state) => state.user);
  const [officialIdCountry, setOfficialIdCountry] = useState(
    profile.countryCode
  );
  const [isEditable, setIsEditable] = useState(true);
  const [hasOfficialId, setHasOfficialId] = useState(false);
  const [collectionPointName, setCollectionPointName] = useState("");
  const [description, setDescription] = useState("");
  const [officialId, setOfficialId] = useState("");

  const [locationData, setLocationData] = useState(null);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setHasOfficialId(false);
          handleClose();
        }}
      >
        <Stack
          sx={{
            ...classes.popup,
            width: {},
          }}
        >
          <Stack>
            <PageTitle>Delete Collection Point</PageTitle>
          </Stack>
          <IconButton
            onClick={() => {
              setHasOfficialId(false);
              handleClose();
            }}
            sx={{
              position: "absolute",
              right: "42px",
              top: "34px",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            sx={{
              fontFamily: "Manrope",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              width: "419px",
            }}
          >
            Deleting this collection point is irreversible and will permanently
            erase all related data and settings, including scheduled pickups. If
            this collection point needs to be managed in the future, it will
            need to be added again as a new entry.
          </Typography>

          <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
            <Button
              sx={classes.buttonCancel}
              onClick={() => {
                setHasOfficialId(false);
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              sx={classes.buttonSave}
              onClick={() => {
                showNotification(
                  "The session was saved successfully.",
                  "success"
                );
                handleClose();
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
