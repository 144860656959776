import { useEffect, useState } from "react";
import { Button, Modal, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { CAutoComplete, CTextInput } from "../../../../components/CustomInput";
import { fetchRawWastes } from "../../../../actions/organizations";
import { editDeposit } from "../../../../actions/warehouse";
import UnsavedDialog from "../../settings/dialogs/UnsavedDialog";

const EditDepositDialog = ({ open, onClose, deposit }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState();
  const rawWastes = useSelector((state) => state.organizations.rawWastes);
  const [rawWaste, setRawWaste] = useState("");
  const [capacity, setCapacity] = useState("");
  const [unsavedOpen, setUnsavedOpen] = useState(false);
  const wh_id = useSelector((state) => state.warehouse.current.id);

  useEffect(() => {
    dispatch(fetchRawWastes());
  }, [dispatch]);
  useEffect(() => {
    if (!deposit) return;
    let raw = rawWastes.find((rw) => rw.name === deposit.raw_waste_name);
    setRawWaste({
      name: raw.name,
      value: raw.name,
    });
    setCapacity(deposit.capacity);
  }, [deposit, rawWastes]);
  useEffect(() => {
    const data = rawWastes.map((rw, idx) => {
      return { name: rw.name, value: rw.name };
    });
    setItems(data);
  }, [rawWastes]);

  const onSave = () => {
    dispatch(
      editDeposit(deposit.id, wh_id, {
        raw_waste: rawWastes.find((rw) => rw.name === rawWaste.name).rawWasteId,
        capacity,
      })
    );
    onClose();
  };

  const handleClose = () => {
    if (deposit.raw_waste === rawWaste && deposit.capacity === capacity) {
      onClose();
      return;
    }
    setUnsavedOpen(true);
  };

  const onLeaveConfirm = () => {
    setUnsavedOpen(false);
    onClose();
  };

  const onSaveConfirm = () => {
    setUnsavedOpen(false);
    onSave();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          padding={"34px 42px"}
          gap={"22px"}
          sx={{
            backgroundColor: "text.contrast",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "520px",
          }}
        >
          <PageTitle>Edit Waste Capacity</PageTitle>
          <CAutoComplete
            title="Raw waste type selection"
            required
            disabled
            options={items}
            value={rawWaste}
            onChange={(v) => {
              setRawWaste(v);
            }}
          />
          <CTextInput
            title="Capacity"
            required
            endIcon="kg"
            value={capacity}
            onChange={(v) => setCapacity(v)}
          />
          <Stack gap={2} direction="row" justifyContent="space-between">
            <Button sx={classes.buttonCancel} onClick={handleClose}>
              Cancel
            </Button>
            <Button sx={classes.buttonSave} onClick={onSave}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <UnsavedDialog
        open={unsavedOpen}
        handleClose={onLeaveConfirm}
        handleLeave={onLeaveConfirm}
        handleSave={onSaveConfirm}
      />
    </>
  );
};

export default EditDepositDialog;
