import { useSelector } from "react-redux";
import { Box, Button, Card, Stack, Typography } from "@mui/material";

import { PageTitle, StatusTypography } from "../../../../components/Typography";
import { InfoCard, renderTwoColumnsInfo } from "../../../../components/Card";
import { getDateStringFromNum } from "../../../../utils/helpers";
import { StateSpan } from "../../../../components/StateSpan";
import { STATE_DISABLED, STATE_ENABLED } from "../../../../utils/type";
import CustomHidden from "../../../../components/CustomHidden";

const SecurityMainView = ({ setStage }) => {
  const security = useSelector((state) => state.user.security);
  const orgData = useSelector((state) => state.organizations.profile);
  return (
    <Box>
      <PageTitle>Security</PageTitle>
      <Stack direction="row" sx={{ marginTop: "20px" }} spacing={2}>
        <CustomHidden min={0} max={1109}>
          <Stack spacing={3}>
            <InfoCard title="My Password" onClickEdit={() => setStage(1)}>
              {renderTwoColumnsInfo(
                "Password",
                `Last changed ${getDateStringFromNum(
                  new Date(security.last_password_changed).getTime(),
                  orgData.timezone
                )}`
              )}
            </InfoCard>
            <InfoCard
              title="Two factor authentication"
              onClickEdit={() => setStage(2)}
              isFullHeight
            >
              <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Status
                </Typography>
                <StateSpan
                  status={
                    security.twofa_enabled ? STATE_ENABLED : STATE_DISABLED
                  }
                />
              </Stack>
            </InfoCard>
          </Stack>
        </CustomHidden>
        <CustomHidden min={1110}>
          <InfoCard title="My Password" onClickEdit={() => setStage(1)}>
            {renderTwoColumnsInfo(
              "Password",
              `Last changed ${getDateStringFromNum(
                new Date(security.last_password_changed).getTime(),
                orgData.timezone
              )}`
            )}
          </InfoCard>
          <InfoCard
            title="Two factor authentication"
            onClickEdit={() => setStage(2)}
            isFullHeight
          >
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                Status
              </Typography>
              <StateSpan
                status={security.twofa_enabled ? STATE_ENABLED : STATE_DISABLED}
              />
            </Stack>
          </InfoCard>
        </CustomHidden>
      </Stack>
    </Box>
  );
};

export default SecurityMainView;
