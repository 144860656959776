import React from "react";

// material-ui
import { Box, Stack } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

// project imports
import { DataTable } from "../../../../components/Table";

// sx
import { classes } from "../../../../utils/class";

import { CardPrimary } from "../../../../components/Card";
import { Badge, StateSpan } from "../../../../components/StateSpan";
import { BADGE_A_TO_CLIENT, BADGE_A_TO_DRIVER } from "../../../../utils/type";

// action import
const Inventory = ({ id, inventories, extraInfo }) => {
  const columns = [
    { field: "category_name", headerName: "Category", width: 150 },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      renderCell: (props) => {
        return (
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <span>{props.row.name}</span>
            <Stack direction="column" gap={0.5}>
              {props.row.available_to_client && (
                <Badge status={BADGE_A_TO_CLIENT} />
              )}
              {props.row.available_to_driver && (
                <Badge status={BADGE_A_TO_DRIVER} />
              )}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "total_amount",
      headerName: "Total amount",
      width: 150,
      renderCell: (props) => `${props.row.total_amount} units`,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (props) => {
        const { status } = props.row;
        return <StateSpan status={status} />;
      },
    },
  ];

  return (
    <Stack>
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        <CardPrimary
          type={1}
          title={"Balance"}
          content={extraInfo?.balance || 0}
        />
        <CardPrimary
          type={2}
          title={"Next collection"}
          content={`in ${extraInfo?.next_picking || 0} days`}
        />
        <CardPrimary
          type={3}
          title={"Expiring documents"}
          content={extraInfo?.count_expiring_documents || 0}
        />
      </Stack>
      <Box sx={{ padding: "20px" }}>
        <DataTable
          rows={inventories.map((inventory) => {
            return {
              ...inventory,
              id: inventory._id,
            };
          })}
          columns={columns}
          sx={classes.grid}
          onRowClick={(params, events, details) => {}}
        />
      </Box>
    </Stack>
  );
};

export default Inventory;
