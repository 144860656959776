import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";

import { PageTitle } from "../../../components/Typography";
import { CardPrimary } from "../../../components/Card";
import { SearchBar } from "../../../components/CustomInput";
import { EditDetail, StateSpan } from "../../../components/StateSpan";
import { DataTable } from "../../../components/Table";
import { classes } from "../../../utils/class";
import { getDateStringFromNum } from "../../../utils/helpers";
import {
  STATE_CLOSED,
  STATE_PROGRESS,
  STATE_PENDING,
} from "../../../utils/type";
import TicketDetailDialog from "./TicketDetailDialog";
import { fetchTickets, updateTicketStatus } from "../../../actions/ticket";

const TicketsListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ticketsData = useSelector((state) => state.tickets.tickets);
  const [ticketTableRows, setTicketTableRows] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [showClosedTickets, setShowClosedTickets] = useState(false);
  const [tkDialogInfo, setTkDialogInfo] = useState({
    open: false,
    ticket: ticketsData[0] || {},
  });
  const summary = useSelector((state) => state.tickets.summary);
  const orgData = useSelector((state) => state.organizations.profile);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTicketTableRows(
      ticketsData.filter((tk) =>
        showClosedTickets
          ? tk.status === STATE_CLOSED
          : tk.status !== STATE_CLOSED
      )
    );
  }, [showClosedTickets, ticketsData]);

  useEffect(() => {
    dispatch(
      fetchTickets(() => {
        setIsLoaded(true);
      })
    );
  }, [dispatch]);

  const onAddTicket = (e) => {
    navigate("/add-tickets");
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {getDateStringFromNum(
            props.row.date || props.row.closed_at,
            orgData.timezone
          )}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "id",
      headerName: "Ticket ID",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "purpose",
      headerName: "Ticket purpose",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "pickup_point_name",
      headerName: "Collection Point",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "responsible_name",
      headerName: "Responsible",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "author_name",
      headerName: "Author",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      flex: 1,
      renderCell: (props) => {
        let options = [];
        if (props?.row?.status === "progress") {
          options = [
            {
              label: "Pending",
              value: STATE_PENDING,
            },
          ];
        } else if (props?.row?.status === "pending") {
          options = [
            {
              label: "In Progress",
              value: STATE_PROGRESS,
            },
          ];
        }
        if (!showClosedTickets)
          options[2] = { label: "Close", value: STATE_CLOSED };

        return (
          <>
            <StateSpan status={props.row.status} />
            {!showClosedTickets && (
              <EditDetail
                options={options}
                props={props}
                onStateChangeHandler={async (status) => {
                  dispatch(
                    updateTicketStatus(props.row._id, { status: status })
                  );
                }}
              />
            )}
          </>
        );
      },
    },
  ];

  if (!ticketsData.length)
    return (
      <>
        <Box sx={{ minHeight: "100vh", height: "100%", padding: "30px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <PageTitle>Add tickets</PageTitle>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80%",
              marginTop: "100px",
            }}
          >
            <NoteAddOutlinedIcon
              sx={{ color: "text.tertiary", fontSize: 100 }}
            />
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Manrope",
                fontSize: "10px",
                color: "text.primary",
                marginBottom: "30px",
              }}
            >
              There are no records listed
            </Typography>
            <Button
              size="large"
              sx={classes.buttonPrimary}
              onClick={() => navigate("/add-tickets")}
            >
              Add ticket
            </Button>
          </Box>
        </Box>
      </>
    );

  return (
    <>
      <Stack direction={"column"} marginTop={3} gap={2} paddingX="60px">
        <Stack direction={"row"} justifyContent={"space-between"}>
          <PageTitle>Tickets</PageTitle>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={onAddTicket}
            >
              Add Ticket
            </Button>
          </Stack>
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            {isLoaded ? (
              <CardPrimary
                type={1}
                title="Without manager"
                content={summary.count_without_manager}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"82px"} />
            )}
            {isLoaded ? (
              <CardPrimary
                type={2}
                title="Expiring documents"
                content={summary.count_expiring_documents}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"82px"} />
            )}
            {isLoaded ? (
              <CardPrimary
                type={2}
                title="Expired documents"
                content={summary.count_expired_documents}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"82px"} />
            )}
            {isLoaded ? (
              <CardPrimary
                type={2}
                title="With 0 waste collected"
                content={summary.count_zero_collected}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"82px"} />
            )}
          </Box>
        </Box>
        {isLoaded ? (
          <Stack direction="row">
            <Button
              variant="outlined"
              sx={[
                showClosedTickets ? classes.buttonCancel : classes.buttonSave,
                {
                  borderRadius: 0,
                  borderTopLeftRadius: "8px",
                  borderBottomLeftRadius: "8px",
                },
              ]}
              onClick={() => setShowClosedTickets(false)}
            >
              Tickets
            </Button>
            <Button
              variant="outlined"
              sx={[
                showClosedTickets ? classes.buttonSave : classes.buttonCancel,
                {
                  borderRadius: 0,
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "8px",
                },
              ]}
              onClick={() => setShowClosedTickets(true)}
            >
              Closed tickets
            </Button>
          </Stack>
        ) : (
          <Skeleton variant="rounded" width={"200px"} height={"46px"} />
        )}
        <Box sx={{ width: "300px" }}>
          {isLoaded ? (
            <SearchBar
              label="Search ticket"
              onSearchChange={(value) => {
                setFilterModel({
                  items: [
                    {
                      id: 1,
                      field: "name",
                      operator: "contains",
                      value: value,
                    },
                  ],
                });
              }}
            />
          ) : (
            <Skeleton width={"100%"} />
          )}
        </Box>

        <Box>
          {isLoaded ? (
            <DataTable
              fit="100%"
              breakpoint="xl"
              rows={ticketTableRows}
              columns={columns}
              sx={classes.grid}
              filterModel={filterModel}
              onRowClick={(params, events, details) => {
                setTkDialogInfo({ open: true, ticket: params.row });
              }}
            />
          ) : (
            <Skeleton width={"100%"} height={"200px"} />
          )}
        </Box>
        <TicketDetailDialog
          open={tkDialogInfo.open}
          ticket={tkDialogInfo.ticket}
          handleClose={() => setTkDialogInfo({ ...tkDialogInfo, open: false })}
        />
      </Stack>
    </>
  );
};

export default TicketsListPage;
