import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthCode from "react-auth-code-input";
import {
  Typography,
  Box,
  Button,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Modal,
  Stack,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import AuthenticationService from "../../../services/AuthenticationService";
import ucoNetworkAuthenticationBg from "../../../assets/images/uco-network-authentication-bg.png";
import { fetchSecurityInfo } from "../../../actions/user";
import { showNotification } from "../../../utils/helpers";
import { SUCCESS } from "../../../utils/type";
import { classes } from "../../../utils/class";

const ForgotPassword = () => {
  const authRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [authCode, setAuthCode] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [authMethod, setAuthMethod] = useState("SMS");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    p: 4,
  };
  const handleResendCode = () => {
    setAuthMethod("SMS");
    authRef.current.clear();
    AuthenticationService.resend2faCode({
      email: email,
    })
      .then((res) => {
        showNotification("2FA code sent, check your email", "success");
      })
      .catch((err) => {
        showNotification("Error", "error");
      });
  };
  const handleConfirm2fa = () => {
    AuthenticationService.confirmResetPassword({
      email: email,
      code: authCode,
    })
      .then((res) => {
        AuthenticationService.resetPasswordRequest({ email: email }).then(
          (res) => {
            showNotification(
              "A password recovery email has been sent. Check your inbox and follow the instructions",
              "success"
            );
            handleClose();
          }
        );
      })
      .catch((err) => {
        showNotification("Wrong 2FA code, Try again", "error");
      });
  };

  const handleAuthCodeOnChange = (res) => {
    setAuthCode(res);
  };
  useEffect(() => {
    dispatch(fetchSecurityInfo());
  }, [dispatch]);
  return (
    <div style={{ display: "flex", height: "100vh", minHeight: "810px" }}>
      <div style={{ flex: "0 0 41.6667%", maxWidth: "41.6667%" }}>
        <img
          src={ucoNetworkAuthenticationBg}
          alt="uco network authentication background"
          style={{
            display: "block",
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
        />
      </div>

      <div
        style={{
          position: "relative",
          minHeight: "1px",
          flex: "0 0 58.3333%",
          maxWidth: "58.3333%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: "10px",
            right: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        ></div>

        <div style={{ width: "100%", maxWidth: "343px" }}>
          <Typography variant="h3">Reset Your Password</Typography>
          <Typography variant="body1" sx={{ mt: "0.5rem" }}>
            Enter your email to send password recovery instructions.
          </Typography>

          <Formik
            initialValues={{
              email: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              setEmail(values.email);

              const data = {
                email: values.email,
              };
              AuthenticationService.resetPasswordRequest(data)
                .then(async (res) => {
                  const result = await AuthenticationService.checkIf2faEnabled(
                    values.email
                  );
                  const twofa_enabled = result.data.data.enabled;
                  if (twofa_enabled) {
                    handleOpen();
                  } else {
                    showNotification(
                      "A password recovery email has been sent. Check your inbox and follow the instructions",
                      "success"
                    );
                  }
                })
                .catch((err) => {
                  enqueueSnackbar("Something went wrong.", {
                    variant: "error",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                  });
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                >
                  {/* <InputLabel htmlFor="outlined-adornment-email-login">
                    Email Address / Username
                  </InputLabel> */}
                  <Typography variant="body1" sx={{ mt: "2rem" }}>
                    Email
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // label="Email Address / Username"
                    placeholder="Insert your email"
                    inputProps={{}}
                    size="small"
                    sx={{ mt: "0.5rem" }}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>

                <Box sx={{ mt: "1.5rem" }}>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="outlined"
                    sx={classes.buttonSave}
                  >
                    Continue
                  </Button>
                </Box>

                <Typography
                  variant="subtitle1"
                  component={Link}
                  to="/auth/signin"
                  sx={{ display: "block", mt: "1.5rem" }}
                >
                  Back to Log in
                </Typography>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="tfaModal" sx={style}>
          <Typography variant="h4">Two factor authentication</Typography>
          <Typography sx={{ mt: 2 }}>
            An {authMethod} was sent with a confirmation code. Enter the code to
            reset your password
          </Typography>
          <Typography variant="body2" sx={{ mt: 3, mb: 2.5 }}>
            Insert Code:
          </Typography>
          <AuthCode onChange={handleAuthCodeOnChange} ref={authRef} />
          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button variant="outlined" onClick={handleResendCode}>
              Re-send code
            </Button>
            <Button variant="contained" onClick={handleConfirm2fa}>
              Confirm
            </Button>
          </Stack>
          <Link
            style={{ display: "block", marginTop: "1.5rem" }}
            onClick={() => {
              setAuthMethod("Email");
              AuthenticationService.send2faMail({
                email: email,
              })
                .then((res) => {
                  if (res.data.message === SUCCESS)
                    showNotification(
                      "Verification code sent to email",
                      "success"
                    );
                })
                .catch((err) => {});
            }}
          >
            Send security code by email
          </Link>
          <Typography variant="body1" sx={{ mt: 1.5 }}>
            Need help? help@uco.network
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
