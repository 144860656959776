import TransferService from "../services/TransferService";
import {
  setTransferDetails,
  setTransferTotalCollected,
  setTransfersList,
  setWTNDetails,
} from "../slices/transferSlice";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";

export const fetchTransfersList =
  (callBack = () => {}) =>
  (dispatch) => {
    TransferService.fetchTransfersList()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          const data = res.data.data.data.map((row) => {
            return { id: row._id, ...row };
          });
          dispatch(setTransfersList(data));
          dispatch(setTransferTotalCollected(res.data.data.total_collected));
          callBack();
        }
      })
      .catch((err) => {});
  };

export const fetchTransferDetails = (id) => (dispatch) => {
  TransferService.fetchTransferDetails(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setTransferDetails(res.data.data));
      }
    })
    .catch((err) => {});
};

export const editTransferWaybill = (data) => (dispatch) => {
  TransferService.editTransferWaybill(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Waybill has been edited", "success");
      }
    })
    .catch((err) => {});
};

export const validateTransfers =
  (data, navigate = null) =>
  (dispatch) => {
    TransferService.validateTransfers(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Waybill has been validated", "success");
          if (navigate) navigate(-1);
          else dispatch(fetchTransfersList());
        }
      })
      .catch((err) => {});
  };
export const rejectTransfers =
  (data, navigate = null) =>
  (dispatch) => {
    TransferService.rejectTransfers(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Waybill has been rejected", "error");
          if (navigate) navigate(-1);
          else dispatch(fetchTransfersList());
        }
      })
      .catch((err) => {
        showNotification(err.response.data.message, "error");
      });
  };
export const createTransfer = (data, navigate) => (dispatch) => {
  TransferService.createTransfer(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchTransfersList(), dispatch);
      }
      navigate(-1);
    })
    .catch((err) => {
      showNotification(err.message, "error");
    });
};
export const fetchWTNDetails = (id) => (dispatch) => {
  TransferService.getWTNDetails(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setWTNDetails(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(err.message, "error");
    });
};
