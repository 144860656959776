import { Stack } from "@mui/material";
import { CardPrimary, CardProfile } from "../../../../components/Card";
import {
  PRODUCER_LOCATION_MODAL,
  PRODUCER_IDENTIFICATION_MODAL,
  PRODUCER_CONTACTS_MODAL,
  PRODUCER_RESPONSIBLE_MODAL,
  SUCCESS,
} from "../../../../utils/type";
import { useState } from "react";
import PriceHistoryDialog from "../../pickup/Dialog/PriceUpdateHistoryDialog";
import ProducerService from "../../../../services/ProducerService";
import { showNotification } from "../../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducerDetail,
  fetchProducers,
} from "../../../../actions/producers";
import {
  BACKOFFICE,
  ERROR,
  OWNER,
  SALES,
  SALES_MANAGER,
} from "../../../../utils/constants";
import CustomHidden from "../../../../components/CustomHidden";

const Profile = ({ props, activation, extraInfo }) => {
  const role = useSelector((state) => state.user.role);

  const dispatch = useDispatch();
  const [priceUpdateHistory, setPriceUpdateHistory] = useState(false);

  return (
    <>
      <Stack gap={2}>
        <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
          <CardPrimary
            type={1}
            title={"Balance"}
            content={extraInfo?.balance || 0}
          />
          <CardPrimary
            type={2}
            title={"Nº Collection Points"}
            content={extraInfo?.count_of_pickup_points || 0}
          />
          <CardPrimary
            type={3}
            title={"Next collection"}
            content={`In ${extraInfo?.next_picking || 0} days`}
          />
        </Stack>
        <CustomHidden min={1760}>
          <Stack
            marginBottom={4}
            gap={2}
            direction={"row"}
            justifyContent={"flex-start"}
          >
            <CardProfile
              disabled={
                role !== BACKOFFICE && role !== OWNER && role !== SALES_MANAGER
              }
              title={"Identification"}
              data={{
                name: props?.name,
                "UCO ID": props?.id,
                "Company Tax name": props?.tax_name,
                "VAT number": props?.vat_number,
              }}
              extraData={props}
              align={[1, 1, 1, 1]}
              type={PRODUCER_IDENTIFICATION_MODAL}
              onSave={(data) => {
                return new Promise((resolve, rej) => {
                  ProducerService.editProducer(props?._id, data)
                    .then((res) => {
                      if (res.data.message === SUCCESS) {
                        showNotification(
                          "Identification updated successfully",
                          "success"
                        );
                        dispatch(fetchProducerDetail(props?._id));
                        dispatch(fetchProducers());
                        resolve(true);
                      } else {
                        rej(false);
                      }
                    })
                    .catch((err) => {
                      showNotification(
                        err.response
                          ? ERROR[err.response.data?.message] || "Network Error"
                          : err.message,
                        "error"
                      );
                      rej(false);
                    });
                });
              }}
            />
            <CardProfile
              disabled={
                role !== BACKOFFICE && role !== SALES_MANAGER && role !== OWNER
              }
              title="Location"
              activation={activation}
              extraData={props}
              data={{
                Coordinates: props?.latitude
                  ? `${props.latitude.toFixed(5)}, ${props.longitude.toFixed(
                      5
                    )}`
                  : "",

                Address: props?.addr_country
                  ? props?.addr_apartment +
                    " " +
                    props?.addr_street +
                    " " +
                    props?.addr_postcode +
                    " " +
                    props?.addr_city +
                    " " +
                    props?.addr_country
                  : "",
              }}
              align={[1, 1]}
              type={PRODUCER_LOCATION_MODAL}
              link="View on the map"
              linkPos={0}
              onSave={(data) => {
                return new Promise((resolve, rej) => {
                  ProducerService.editProducer(props?._id, data)
                    .then((res) => {
                      if (res.data.message === SUCCESS) {
                        showNotification(
                          "Location updated successfully",
                          "success"
                        );
                        dispatch(fetchProducerDetail(props?._id));
                        dispatch(fetchProducers());
                        resolve(true);
                      } else {
                        rej(false);
                      }
                    })
                    .catch((err) => {
                      showNotification(
                        err.response
                          ? ERROR[err.response.data?.message] || "Network Error"
                          : err.message,
                        "error"
                      );
                      rej(false);
                    });
                });
              }}
            />

            <CardProfile
              disabled={
                role !== BACKOFFICE && role !== SALES_MANAGER && role !== OWNER
              }
              title={"Org Contacts"}
              data={{
                Phone: props?.phone,
                "Mobile phone": props?.mobile_phone,
                Name: props?.contact,
                Email: props?.email,
              }}
              extraData={props}
              align={[1, 1, 1, 1]}
              type={PRODUCER_CONTACTS_MODAL}
              onSave={(data) => {
                return new Promise((resolve, rej) => {
                  ProducerService.editProducer(props?._id, data)
                    .then((res) => {
                      if (res.data.message === SUCCESS) {
                        showNotification(
                          "Contacts updated successfully",
                          "success"
                        );
                        dispatch(fetchProducerDetail(props?._id));
                        dispatch(fetchProducers());
                        resolve(true);
                      } else {
                        rej(false);
                      }
                    })
                    .catch((err) => {
                      showNotification(
                        err.response
                          ? ERROR[err.response.data?.message] || "Network Error"
                          : err.message,
                        "error"
                      );
                      rej(false);
                    });
                });
              }}
            />
            <CardProfile
              disabled={
                role !== BACKOFFICE && role !== SALES_MANAGER && role !== OWNER
              }
              title={"Relationship Manager"}
              extraData={props}
              data={{
                Name: props?.responsible_name,
                Phone: props?.resp_phone,
                "Mobile Phone": props?.resp_mobile_phone,
                Email: props?.resp_email,
              }}
              align={[1, 1, 1, 1]}
              type={PRODUCER_RESPONSIBLE_MODAL}
              onSave={(data) => {
                return new Promise((resolve, rej) => {
                  ProducerService.editProducer(props?._id, data)
                    .then((res) => {
                      if (res.data.message === SUCCESS) {
                        showNotification(
                          "Responsible updated successfully",
                          "success"
                        );
                        dispatch(fetchProducerDetail(props?._id));
                        dispatch(fetchProducers());
                        resolve(true);
                      } else {
                        rej(false);
                      }
                    })
                    .catch((err) => {
                      showNotification(
                        err.response
                          ? ERROR[err.response.data?.message] || "Network Error"
                          : err.message,
                        "error"
                      );
                      rej(false);
                    });
                });
              }}
            />
          </Stack>
        </CustomHidden>
        <CustomHidden min={1440} max={1759}>
          <Stack
            marginBottom={4}
            gap={2}
            direction={"row"}
            justifyContent={"flex-start"}
          >
            <Stack gap={2}>
              <CardProfile
                disabled={
                  role !== BACKOFFICE && role !== SALES && role !== OWNER
                }
                title={"Identification"}
                data={{
                  name: props?.name,
                  "UCO ID": props?.id,
                  "Company Tax name": props?.tax_name,
                  "VAT number": props?.vat_number,
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PRODUCER_IDENTIFICATION_MODAL}
                onSave={(data) => {
                  return new Promise((resolve, rej) => {
                    ProducerService.editProducer(props?._id, data)
                      .then((res) => {
                        if (res.data.message === SUCCESS) {
                          showNotification(
                            "Identification updated successfully",
                            "success"
                          );
                          dispatch(fetchProducerDetail(props?._id));
                          dispatch(fetchProducers());
                          resolve(true);
                        } else {
                          rej(false);
                        }
                      })
                      .catch((err) => {
                        showNotification(
                          err.response
                            ? ERROR[err.response.data?.message] ||
                                "Network Error"
                            : err.message,
                          "error"
                        );
                        rej(false);
                      });
                  });
                }}
              />
              <CardProfile
                disabled={
                  role !== BACKOFFICE && role !== SALES && role !== OWNER
                }
                title={"Relationship Manager"}
                extraData={props}
                data={{
                  Name: props?.responsible_name,
                  Phone: props?.resp_phone,
                  "Mobile Phone": props?.resp_mobile_phone,
                  Email: props?.resp_email,
                }}
                align={[1, 1, 1, 1]}
                type={PRODUCER_RESPONSIBLE_MODAL}
                onSave={(data) => {
                  return new Promise((resolve, rej) => {
                    ProducerService.editProducer(props?._id, data)
                      .then((res) => {
                        if (res.data.message === SUCCESS) {
                          showNotification(
                            "Responsible updated successfully",
                            "success"
                          );
                          dispatch(fetchProducerDetail(props?._id));
                          dispatch(fetchProducers());
                          resolve(true);
                        } else {
                          rej(false);
                        }
                      })
                      .catch((err) => {
                        showNotification(
                          err.response
                            ? ERROR[err.response.data?.message] ||
                                "Network Error"
                            : err.message,
                          "error"
                        );
                        rej(false);
                      });
                  });
                }}
              />
            </Stack>

            <CardProfile
              disabled={role !== BACKOFFICE && role !== SALES && role !== OWNER}
              title="Location"
              activation={activation}
              extraData={props}
              data={{
                Coordinates: props?.latitude
                  ? `${props.latitude.toFixed(5)}, ${props.longitude.toFixed(
                      5
                    )}`
                  : "",

                Address: props?.addr_country
                  ? props?.addr_apartment +
                    " " +
                    props?.addr_street +
                    " " +
                    props?.addr_postcode +
                    " " +
                    props?.addr_city +
                    " " +
                    props?.addr_country
                  : "",
              }}
              align={[1, 1]}
              type={PRODUCER_LOCATION_MODAL}
              link="View on the map"
              linkPos={0}
              onSave={(data) => {
                return new Promise((resolve, rej) => {
                  ProducerService.editProducer(props?._id, data)
                    .then((res) => {
                      if (res.data.message === SUCCESS) {
                        showNotification(
                          "Location updated successfully",
                          "success"
                        );
                        dispatch(fetchProducerDetail(props?._id));
                        dispatch(fetchProducers());
                        resolve(true);
                      } else {
                        rej(false);
                      }
                    })
                    .catch((err) => {
                      showNotification(
                        err.response
                          ? ERROR[err.response.data?.message] || "Network Error"
                          : err.message,
                        "error"
                      );
                      rej(false);
                    });
                });
              }}
            />

            <CardProfile
              disabled={role !== BACKOFFICE && role !== SALES && role !== OWNER}
              title={"Org Contacts"}
              data={{
                Phone: props?.phone,
                "Mobile phone": props?.mobile_phone,
                Name: props?.contact,
                Email: props?.email,
              }}
              extraData={props}
              align={[1, 1, 1, 1]}
              type={PRODUCER_CONTACTS_MODAL}
              onSave={(data) => {
                return new Promise((resolve, rej) => {
                  ProducerService.editProducer(props?._id, data)
                    .then((res) => {
                      if (res.data.message === SUCCESS) {
                        showNotification(
                          "Contacts updated successfully",
                          "success"
                        );
                        dispatch(fetchProducerDetail(props?._id));
                        dispatch(fetchProducers());
                        resolve(true);
                      } else {
                        rej(false);
                      }
                    })
                    .catch((err) => {
                      showNotification(
                        err.response
                          ? ERROR[err.response.data?.message] || "Network Error"
                          : err.message,
                        "error"
                      );
                      rej(false);
                    });
                });
              }}
            />
          </Stack>
        </CustomHidden>
        <CustomHidden min={1110} max={1439}>
          <Stack
            marginBottom={4}
            gap={2}
            direction={"row"}
            justifyContent={"flex-start"}
          >
            <Stack gap={2}>
              <CardProfile
                disabled={
                  role !== BACKOFFICE && role !== SALES && role !== OWNER
                }
                title={"Identification"}
                data={{
                  name: props?.name,
                  "UCO ID": props?.id,
                  "Company Tax name": props?.tax_name,
                  "VAT number": props?.vat_number,
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PRODUCER_IDENTIFICATION_MODAL}
                onSave={(data) => {
                  return new Promise((resolve, rej) => {
                    ProducerService.editProducer(props?._id, data)
                      .then((res) => {
                        if (res.data.message === SUCCESS) {
                          showNotification(
                            "Identification updated successfully",
                            "success"
                          );
                          dispatch(fetchProducerDetail(props?._id));
                          dispatch(fetchProducers());
                          resolve(true);
                        } else {
                          rej(false);
                        }
                      })
                      .catch((err) => {
                        showNotification(
                          err.response
                            ? ERROR[err.response.data?.message] ||
                                "Network Error"
                            : err.message,
                          "error"
                        );
                        rej(false);
                      });
                  });
                }}
              />
              <CardProfile
                disabled={
                  role !== BACKOFFICE && role !== SALES && role !== OWNER
                }
                title={"Relationship Manager"}
                extraData={props}
                data={{
                  Name: props?.responsible_name,
                  Phone: props?.resp_phone,
                  "Mobile Phone": props?.resp_mobile_phone,
                  Email: props?.resp_email,
                }}
                align={[1, 1, 1, 1]}
                type={PRODUCER_RESPONSIBLE_MODAL}
                onSave={(data) => {
                  return new Promise((resolve, rej) => {
                    ProducerService.editProducer(props?._id, data)
                      .then((res) => {
                        if (res.data.message === SUCCESS) {
                          showNotification(
                            "Responsible updated successfully",
                            "success"
                          );
                          dispatch(fetchProducerDetail(props?._id));
                          dispatch(fetchProducers());
                          resolve(true);
                        } else {
                          rej(false);
                        }
                      })
                      .catch((err) => {
                        showNotification(
                          err.response
                            ? ERROR[err.response.data?.message] ||
                                "Network Error"
                            : err.message,
                          "error"
                        );
                        rej(false);
                      });
                  });
                }}
              />
            </Stack>
            <Stack gap={2}>
              <CardProfile
                disabled={
                  role !== BACKOFFICE && role !== SALES && role !== OWNER
                }
                title="Location"
                activation={activation}
                extraData={props}
                data={{
                  Coordinates: props?.latitude
                    ? `${props.latitude.toFixed(5)}, ${props.longitude.toFixed(
                        5
                      )}`
                    : "",

                  Address: props?.addr_country
                    ? props?.addr_apartment +
                      " " +
                      props?.addr_street +
                      " " +
                      props?.addr_postcode +
                      " " +
                      props?.addr_city +
                      " " +
                      props?.addr_country
                    : "",
                }}
                align={[1, 1]}
                type={PRODUCER_LOCATION_MODAL}
                link="View on the map"
                linkPos={0}
                onSave={(data) => {
                  return new Promise((resolve, rej) => {
                    ProducerService.editProducer(props?._id, data)
                      .then((res) => {
                        if (res.data.message === SUCCESS) {
                          showNotification(
                            "Location updated successfully",
                            "success"
                          );
                          dispatch(fetchProducerDetail(props?._id));
                          dispatch(fetchProducers());
                          resolve(true);
                        } else {
                          rej(false);
                        }
                      })
                      .catch((err) => {
                        showNotification(
                          err.response
                            ? ERROR[err.response.data?.message] ||
                                "Network Error"
                            : err.message,
                          "error"
                        );
                        rej(false);
                      });
                  });
                }}
              />
              <CardProfile
                disabled={
                  role !== BACKOFFICE && role !== SALES && role !== OWNER
                }
                title={"Org Contacts"}
                data={{
                  Phone: props?.phone,
                  "Mobile phone": props?.mobile_phone,
                  Name: props?.contact,
                  Email: props?.email,
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PRODUCER_CONTACTS_MODAL}
                onSave={(data) => {
                  return new Promise((resolve, rej) => {
                    ProducerService.editProducer(props?._id, data)
                      .then((res) => {
                        if (res.data.message === SUCCESS) {
                          showNotification(
                            "Contacts updated successfully",
                            "success"
                          );
                          dispatch(fetchProducerDetail(props?._id));
                          dispatch(fetchProducers());
                          resolve(true);
                        } else {
                          rej(false);
                        }
                      })
                      .catch((err) => {
                        showNotification(
                          err.response
                            ? ERROR[err.response.data?.message] ||
                                "Network Error"
                            : err.message,
                          "error"
                        );
                        rej(false);
                      });
                  });
                }}
              />
            </Stack>
          </Stack>
        </CustomHidden>
        <CustomHidden min={0} max={1109}>
          <Stack
            marginBottom={4}
            gap={2}
            direction={"row"}
            justifyContent={"flex-start"}
          >
            <Stack gap={2}>
              <CardProfile
                disabled={
                  role !== BACKOFFICE && role !== SALES && role !== OWNER
                }
                title={"Identification"}
                data={{
                  name: props?.name,
                  "UCO ID": props?.id,
                  "Company Tax name": props?.tax_name,
                  "VAT number": props?.vat_number,
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PRODUCER_IDENTIFICATION_MODAL}
                onSave={(data) => {
                  return new Promise((resolve, rej) => {
                    ProducerService.editProducer(props?._id, data)
                      .then((res) => {
                        if (res.data.message === SUCCESS) {
                          showNotification(
                            "Identification updated successfully",
                            "success"
                          );
                          dispatch(fetchProducerDetail(props?._id));
                          dispatch(fetchProducers());
                          resolve(true);
                        } else {
                          rej(false);
                        }
                      })
                      .catch((err) => {
                        showNotification(
                          err.response
                            ? ERROR[err.response.data?.message] ||
                                "Network Error"
                            : err.message,
                          "error"
                        );
                        rej(false);
                      });
                  });
                }}
              />
              <CardProfile
                disabled={
                  role !== BACKOFFICE && role !== SALES && role !== OWNER
                }
                title={"Relationship Manager"}
                extraData={props}
                data={{
                  Name: props?.responsible_name,
                  Phone: props?.resp_phone,
                  "Mobile Phone": props?.resp_mobile_phone,
                  Email: props?.resp_email,
                }}
                align={[1, 1, 1, 1]}
                type={PRODUCER_RESPONSIBLE_MODAL}
                onSave={(data) => {
                  return new Promise((resolve, rej) => {
                    ProducerService.editProducer(props?._id, data)
                      .then((res) => {
                        if (res.data.message === SUCCESS) {
                          showNotification(
                            "Responsible updated successfully",
                            "success"
                          );
                          dispatch(fetchProducerDetail(props?._id));
                          dispatch(fetchProducers());
                          resolve(true);
                        } else {
                          rej(false);
                        }
                      })
                      .catch((err) => {
                        showNotification(
                          err.response
                            ? ERROR[err.response.data?.message] ||
                                "Network Error"
                            : err.message,
                          "error"
                        );
                        rej(false);
                      });
                  });
                }}
              />
              <CardProfile
                disabled={
                  role !== BACKOFFICE && role !== SALES && role !== OWNER
                }
                title="Location"
                activation={activation}
                extraData={props}
                data={{
                  Coordinates: props?.latitude
                    ? `${props.latitude.toFixed(5)}, ${props.longitude.toFixed(
                        5
                      )}`
                    : "",

                  Address: props?.addr_country
                    ? props?.addr_apartment +
                      " " +
                      props?.addr_street +
                      " " +
                      props?.addr_postcode +
                      " " +
                      props?.addr_city +
                      " " +
                      props?.addr_country
                    : "",
                }}
                align={[1, 1]}
                type={PRODUCER_LOCATION_MODAL}
                link="View on the map"
                linkPos={0}
                onSave={(data) => {
                  return new Promise((resolve, rej) => {
                    ProducerService.editProducer(props?._id, data)
                      .then((res) => {
                        if (res.data.message === SUCCESS) {
                          showNotification(
                            "Location updated successfully",
                            "success"
                          );
                          dispatch(fetchProducerDetail(props?._id));
                          dispatch(fetchProducers());
                          resolve(true);
                        } else {
                          rej(false);
                        }
                      })
                      .catch((err) => {
                        showNotification(
                          err.response
                            ? ERROR[err.response.data?.message] ||
                                "Network Error"
                            : err.message,
                          "error"
                        );
                        rej(false);
                      });
                  });
                }}
              />
              <CardProfile
                disabled={
                  role !== BACKOFFICE && role !== SALES && role !== OWNER
                }
                title={"Org Contacts"}
                data={{
                  Phone: props?.phone,
                  "Mobile phone": props?.mobile_phone,
                  Name: props?.contact,
                  Email: props?.email,
                }}
                extraData={props}
                align={[1, 1, 1, 1]}
                type={PRODUCER_CONTACTS_MODAL}
                onSave={(data) => {
                  return new Promise((resolve, rej) => {
                    ProducerService.editProducer(props?._id, data)
                      .then((res) => {
                        if (res.data.message === SUCCESS) {
                          showNotification(
                            "Contacts updated successfully",
                            "success"
                          );
                          dispatch(fetchProducerDetail(props?._id));
                          dispatch(fetchProducers());
                          resolve(true);
                        } else {
                          rej(false);
                        }
                      })
                      .catch((err) => {
                        showNotification(
                          err.response
                            ? ERROR[err.response.data?.message] ||
                                "Network Error"
                            : err.message,
                          "error"
                        );
                        rej(false);
                      });
                  });
                }}
              />
            </Stack>
          </Stack>
        </CustomHidden>
      </Stack>
      <PriceHistoryDialog
        open={priceUpdateHistory}
        handleClose={() => {
          setPriceUpdateHistory(false);
        }}
      />
    </>
  );
};
export default Profile;
