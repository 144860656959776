import { Box, Stack, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as d3 from "d3";
import { getDateStringFromNum } from "../utils/helpers";
const PriceChart = ({ data = [], toolTips = [], gap = 100, height = 500 }) => {
  const orgData = useSelector((state) => state.organizations.profile);

  const padding = 10;
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const getPercent = (value) => {
    if (max - min !== 0) return (value - min) / (max - min);
    return 1;
  };
  useEffect(() => {
    let mn = 0,
      mx = 0;
    for (let d of data) {
      if (d < mn) mn = d;
      if (d >= mx) mx = d;
    }
    setMax(mx + (mx - mn) * 0.1);
    setMin(mn - (mx - mn) * 0.1);
  }, [data]);
  const getSplinePaths = (data) => {
    const points = data.map((d, index) => {
      return {
        x: index * gap + padding,
        y: height - getPercent(d) * height,
      };
    });
    const lineGenerator = d3
      .line()
      .x((d) => d.x)
      .y((d) => d.y)
      .curve(d3.curveNatural);

    if (points.length > 1) {
      const path = lineGenerator(points);
      return path;
    } else if (points.length === 1) {
      const newPoints = [
        points[0],
        {
          ...points[0],
          x: points[0].x + 100,
        },
      ];
      const path = lineGenerator(newPoints);
      return path;
    }
    return [];
  };
  const paths = getSplinePaths(data);
  return (
    <>
      <Stack
        position={"relative"}
        width={"100%"}
        height={`${height}px`}
        sx={{
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        {data.length === 0 && (
          <Box
            position={"absolute"}
            left={"50%"}
            top={"50%"}
            sx={{
              transform: "translate(-50%,-50%)",
            }}
          >
            No Price History Records
          </Box>
        )}
        <svg width={"100%"} height={`${height}px`} overflow={"visible"}>
          <path
            d={paths}
            strokeWidth="5"
            strokeDasharray="5,5"
            stroke="#6D6D6D"
            fill="none"
          ></path>
        </svg>
        {data.map((d, index) => {
          return (
            <Tooltip
              slotProps={{
                tooltip: {
                  sx: {
                    background: "black",
                  },
                },
                arrow: {
                  sx: {
                    color: "black",
                  },
                },
              }}
              arrow
              title={
                <Stack
                  padding={1}
                  gap={1}
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    {toolTips[index].price + "€"}
                  </Box>
                  {getDateStringFromNum(toolTips[index].date, orgData.timezone)}
                </Stack>
              }
              placement="top"
            >
              <Box
                position={"absolute"}
                left={gap * index + "px"}
                bottom={getPercent(d) * height + "px"}
                width={"10px"}
                height={"10px"}
                borderRadius={"50%"}
                sx={{
                  backgroundColor: "black",
                  "&:hover": {
                    cursor: "pointer",
                  },
                  transform: "translate(5px, 5px)",
                }}
              ></Box>
            </Tooltip>
          );
        })}
      </Stack>
    </>
  );
};
export default PriceChart;
