import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

// project imports
import {
  InputTitle,
  PageTitle,
  SubTitle,
} from "../../../components/Typography";

// third party

//sx class definition
import { classes } from "../../../utils/class";
import {
  CAutoComplete,
  CDateInput,
  COfficialIdInput,
  CRadioSelect,
  CTextInput,
} from "../../../components/CustomInput";
import {
  fetchPickupPoints,
  fetchPickupRawWastes,
} from "../../../actions/pickup";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsers } from "../../../actions/organizations";
import { fetchAllDrivers, fetchAllVehicles } from "../../../actions/fleet";
import { fetchDeposits, fetchWarehouseList } from "../../../actions/warehouse";
import { showNotification, getServerTimestamp } from "../../../utils/helpers";
import { fetchOperationCodes } from "../../../actions/waste";
import dayjs from "dayjs";
import { setPickupDetail } from "../../../slices/pickupSlice";
import PickupService from "../../../services/PickupService";
import { createTransfer } from "../../../actions/transfer";
import WarehouseService from "../../../services/WarehouseService";
import { SUCCESS } from "../../../utils/type";
import { ERROR } from "../../../utils/constants";

const AddVisitPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(1);
  const [reason, setReason] = useState("");
  const [purpose, setPurpose] = useState("Waste pickup");
  const [errorDlgOpen, setErrorDlgOpen] = useState(false);
  const [target, setTarget] = useState(null);
  const [member, setMember] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [date, setDate] = useState(dayjs(new Date()));
  const [origin, setOrigin] = useState(null);
  const [transportVat, setTransportVAT] = useState("");
  const [destinationVat, setDestinationVAT] = useState("");
  const [thisNotes, setThisNotes] = useState("");
  const [nextNotes, setNextNotes] = useState("");
  const [officialID, setOfficialID] = useState(null);
  const [rawWaste, setRawWaste] = useState(null);
  const [driver, setDriver] = useState("");
  const [opCode, setOpCode] = useState("");
  const [vatNumCountry, setVatNumCountry] = useState(null);
  const [destNumCountry, setDestNumCountry] = useState(null);
  const [rawWastes, setRawWastes] = useState([]);
  const pickup_points = useSelector((state) => state.pickup.pickupList);
  const vehicles = useSelector((state) => state.fleet.vehicles);
  const users = useSelector((state) => state.organizations.users);
  const orgData = useSelector((state) => state.organizations.profile);
  const warehouses = useSelector((state) => state.warehouse.warehouses);
  const operation_codes = useSelector((state) => state.waste.operation_codes);
  const drivers = useSelector((state) => state.fleet.drivers);
  const current_scheduled = useSelector(
    (state) => state.visits.current_scheduled
  );
  const user = useSelector((state) => state.user.profile);

  useEffect(() => {
    dispatch(fetchPickupPoints());
    dispatch(fetchAllVehicles());
    dispatch(fetchAllUsers());
    dispatch(fetchWarehouseList());
    dispatch(fetchAllDrivers());
    dispatch(fetchOperationCodes());
    dispatch(fetchWarehouseList());
  }, []);

  useEffect(() => {
    setDate(dayjs(new Date()));
    setTarget("");
    setOrigin("");
    setMember("");
    setVehicle("");
  }, [purpose]);
  useEffect(() => {
    if (!origin || origin.length === 0) {
      setPickupDetail({});
      return;
    }
    const data = pickup_points.find(
      (pp) => pp.name === (origin.name || origin)
    );
    if (data) {
      PickupService.fetchPickupPointDetail(data._id).then((res) => {
        setRawWaste(res.data.data.raw_waste_names[0] || "");
        const opCode = operation_codes.find(
          (code) => code._id === res.data.data.operation_code
        );
        if (opCode) setOpCode(opCode.code);
      });
      PickupService.fetchPickupRawWastes(data._id)
        .then((res) => {
          if (res.data.message === SUCCESS) {
            setRawWastes(res.data.data);
          }
        })
        .catch((err) => {
          showNotification(
            err.response
              ? ERROR[err.response.data.message] || "Network Error"
              : err.message,
            "error"
          );
        });
    } else {
      WarehouseService.fetchWarehouseDeposits(origin.value)
        .then((res) => {
          const data = res.data.data.map((r) => {
            return { id: r._id, ...r };
          });
          setRawWastes(data);
        })
        .catch((err) => {});
    }
  }, [origin, pickup_points, warehouses]);

  useEffect(() => {
    if (officialID) {
      setDestNumCountry(officialID.data.vat_number_country);
      setDestinationVAT(officialID.data.vat_number);
    }
  }, [officialID]);

  useEffect(() => {
    if (vehicle) {
      setTransportVAT(vehicle.data.vat_number);
      setVatNumCountry(vehicle.data.vat_number_country);
    }
  }, [vehicle]);

  useEffect(() => {
    if (current_scheduled) {
      setDate(dayjs(new Date(current_scheduled.date)));
      setThisNotes(current_scheduled.notes);
      setPurpose("Waste pickup");
      setQuantity(current_scheduled.quantity);
      setVehicle(current_scheduled.vehicles[0]?.reg_plate || "");
      setOrigin(current_scheduled.pickup_point_name);
      setTarget(current_scheduled.pickup_point_name);
      setRawWaste(current_scheduled.raw_waste_name);
    }
  }, [current_scheduled]);

  const save = () => {
    try {
      if (!date || !origin || !rawWaste || !opCode) {
        showNotification("Please input mandatory fields", "error");
        return;
      }
      if (quantity !== 0 && (!vehicle || !driver || !officialID)) {
        showNotification("Please input mandatory fields", "error");
        return;
      }
      const id = pickup_points.findIndex((pp) => pp._id === origin.value);
      dispatch(
        createTransfer(
          Object.assign(
            {
              date: getServerTimestamp(
                new Date(date).getTime(),
                orgData.timezone
              ),
              origin: origin.value,
              origin_type: id >= 0 ? "pickup_point" : "warehouse",
              quantity: parseInt(quantity),
              operation_code: operation_codes.find((op) => op.code === opCode)
                ._id,
              notes: thisNotes,
              notes_for_next: nextNotes,
              raw_waste: rawWastes.find(
                (w) => (w.name || w.raw_waste_name) === rawWaste
              )._id,
            },
            current_scheduled && { visit_scheduled_id: current_scheduled._id },
            quantity === 0 && { reason_description: reason },
            quantity !== 0 && {
              vehicle: vehicles.find((v) => v.reg_plate === vehicle.value)._id,
              vehicle_vat_num: transportVat,
              vehicle_vat_num_country: vatNumCountry,
              vehicle_driver: drivers.find((d) => d.name === driver)._id,
              destination_warehouse: warehouses.find(
                (w) => w.id === officialID.value
              )?._id,
              destination_warehouse_vat_num_country: destNumCountry,
              destination_warehouse_vat_num: destinationVat,
            }
          ),
          () => navigate(-1)
        )
      );
    } catch (err) {
      showNotification(
        "Something went wrong! Please check information",
        "error"
      );
    }
  };
  const cancel = () => {
    navigate(-1);
  };

  return (
    <>
      <Stack gap={10}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <PageTitle>Add log</PageTitle>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={cancel}
            >
              Cancel
            </Button>
            <Button variant="outlined" sx={classes.buttonSave} onClick={save}>
              Save
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "36px",
              minWidth: "422px",
              justifyContent: "center",
              marginBottom: "50px",
            }}
          >
            <SubTitle>Identification</SubTitle>
            <CRadioSelect
              title="Purpose"
              required
              direction="column"
              radioRawDirection="true"
              value={purpose}
              onChange={(v) => setPurpose(v)}
              options={["Waste pickup"]}
            />
            <CDateInput
              title="Select date"
              value={date}
              onChange={(v) => setDate(v)}
              required
            />

            {purpose === "Waste pickup" ? (
              <>
                <CAutoComplete
                  title="Origin"
                  required
                  placeholder="Select Origin"
                  value={origin}
                  onChange={(v) => {
                    setOrigin(v);
                    setRawWaste("");
                  }}
                  options={[...pickup_points, ...warehouses].map((pp) => {
                    return {
                      value: pp._id,
                      name: pp.name,
                      component: (
                        <Stack>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {pp.name}
                          </Typography>
                          {pp.producer ? (
                            <Typography
                              sx={{ color: "grey", fontSize: "12px" }}
                            >
                              Producer: {pp.producer}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ color: "grey", fontSize: "12px" }}
                            >
                              OID: {pp.official_id || "-"}
                            </Typography>
                          )}
                        </Stack>
                      ),
                    };
                  })}
                  hasComponent
                  fixed
                />
                <SubTitle>Collection details</SubTitle>
                <CAutoComplete
                  title="Raw waste"
                  required
                  placeholder="Select Raw Waste"
                  options={rawWastes.map((waste) => {
                    return {
                      value: waste.rawWasteId,
                      name: waste.name,
                      component: (
                        <Stack>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {waste.code}
                          </Typography>
                          <Typography sx={{ color: "grey", fontSize: "12px" }}>
                            {waste.name}
                          </Typography>
                        </Stack>
                      ),
                    };
                  })}
                  hasComponent
                  fixed
                  value={rawWaste}
                  onChange={(v) => setRawWaste(v)}
                />

                <CAutoComplete
                  title="Operation Code"
                  required
                  options={operation_codes.map((op) => op.code)}
                  placeholder="Select Operation code"
                  value={opCode}
                  onChange={(v) => setOpCode(v)}
                />
                <CTextInput
                  title="Quantity"
                  required
                  type="number"
                  value={quantity}
                  onChange={(v) => setQuantity(v)}
                  placeholder="Insert quantity"
                  endIcon={"kg"}
                />

                {quantity !== 0 ? (
                  <>
                    <SubTitle>Transport</SubTitle>
                    <CAutoComplete
                      title="Vehicle license plate"
                      required
                      value={vehicle}
                      onChange={(v) => setVehicle(v)}
                      placeholder="Insert license plate"
                      options={vehicles.map((vehicle) => {
                        return {
                          value: vehicle.reg_plate,
                          name: vehicle.reg_plate,
                          data: vehicle,
                          component: (
                            <Stack>
                              <Typography
                                sx={{ fontSize: "16px", fontWeight: "bold" }}
                              >
                                {vehicle.name}
                              </Typography>
                              <Typography
                                sx={{ color: "grey", fontSize: "14px" }}
                              >
                                Plate: {vehicle.reg_plate}
                              </Typography>
                            </Stack>
                          ),
                        };
                      })}
                      hasComponent
                      fixed
                    />
                    <Stack>
                      <COfficialIdInput
                        label="VAT number"
                        countryCode={vatNumCountry}
                        disabled
                        onCountryChange={(e, v) => setVatNumCountry(v.code)}
                        content="VAT"
                        value={transportVat}
                        onChange={(e) => setTransportVAT(e.target.value)}
                        placeholder="number"
                        required
                      />
                    </Stack>
                    <CAutoComplete
                      title="Driver"
                      placeholder="Insert driver name"
                      options={drivers.map((d) => d.name)}
                      value={driver}
                      onChange={(v) => setDriver(v)}
                    />
                    <SubTitle>Destination</SubTitle>
                    <Stack>
                      <CAutoComplete
                        title="Destination Warehouse"
                        placeholder="Warehouse"
                        value={officialID}
                        onChange={(v) => setOfficialID(v)}
                        options={warehouses.map((wr) => {
                          return {
                            value: wr.id,
                            name: wr.id,
                            data: wr,
                            component: (
                              <Stack>
                                <Typography
                                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                                >
                                  {wr.id}
                                </Typography>
                                <Typography
                                  sx={{ color: "grey", fontSize: "12px" }}
                                >
                                  Name: {wr.name}
                                </Typography>
                              </Stack>
                            ),
                          };
                        })}
                        hasComponent
                        fixed
                      />
                    </Stack>
                    <Stack>
                      <COfficialIdInput
                        disabled
                        label="Waste destination VAT"
                        countryCode={destNumCountry}
                        onCountryChange={(e, v) => setDestNumCountry(v.code)}
                        content="VAT"
                        value={destinationVat}
                        onChange={(e) => setDestinationVAT(e.target.value)}
                        placeholder="number"
                      />
                    </Stack>
                  </>
                ) : (
                  <CTextInput
                    rows={4}
                    title="Reason"
                    value={reason}
                    onChange={(v) => setReason(v)}
                    placeholder="Describe reason"
                  />
                )}

                <SubTitle>Notes</SubTitle>
                <CTextInput
                  rows={3}
                  placeholder="Insert your notes"
                  title="Notes for this waste pickup"
                  value={thisNotes}
                  onChange={(v) => setThisNotes(v)}
                />
                <CTextInput
                  rows={3}
                  placeholder="Insert your notes"
                  title="Notes for next waste pickups"
                  value={nextNotes}
                  onChange={(v) => setNextNotes(v)}
                />
              </>
            ) : (
              <>
                <CAutoComplete
                  title="Target"
                  options={pickup_points.map((pp) => pp.name)}
                  value={target}
                  placeholder="Select Target"
                  onChange={(v) => setTarget(v)}
                  required
                />
                <CAutoComplete
                  title="Member"
                  options={users}
                  placeholder="Select Member"
                  value={member}
                  onChange={(v) => setMember(v)}
                  required
                />
                <CAutoComplete
                  title="Vehicle"
                  required
                  value={vehicle}
                  onChange={(v) => setVehicle(v)}
                  placeholder="Select vehicle"
                  options={vehicles.map((vehicle) => {
                    return {
                      value: vehicle.reg_plate,
                      name: vehicle.reg_plate,
                      component: (
                        <Stack>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {vehicle.reg_plate}
                          </Typography>
                          <Typography sx={{ color: "grey", fontSize: "14px" }}>
                            {vehicle.name}
                          </Typography>
                        </Stack>
                      ),
                    };
                  })}
                  hasComponent
                  fixed
                />
              </>
            )}
          </Box>
        </Box>
        <Dialog
          open={errorDlgOpen}
          sx={{ padding: "30px" }}
          onClose={() => setErrorDlgOpen(false)}
        >
          <DialogContent>
            <Stack spacing={3} sx={{ minWidth: "450px" }}>
              <Stack
                direction="row"
                sx={{
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "25px", fontWeight: "bold" }}>
                  Cannot receive the waste pickup
                </Typography>
                <IconButton onClick={() => setErrorDlgOpen(false)}>
                  <Close />
                </IconButton>
              </Stack>
              <Typography>
                The destination warehouse cannot receive the waste that is being
                collected either because the deposit does not exist or because
                it is inactive or inoperable
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              sx={[classes.buttonPrimary, { margin: "20px", width: "100%" }]}
            >
              Ok, understand
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
};
export default AddVisitPage;
