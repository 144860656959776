import { Button, Divider, Popover, Stack, Typography } from "@mui/material";
import { classes } from "../../../../utils/class";

const EditDaysOffPop = ({
  open = false,
  anchorEl = null,
  handleClose = () => {},
  onEdit = () => {},
  onRemove = () => {},
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={handleClose}
    >
      <Stack justifyContent={"flex-start"} width={"150px"}>
        <Stack
          padding={1}
          sx={{
            color: "text.primary",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "background.light",
            },
          }}
          onClick={onEdit}
        >
          Edit day off
        </Stack>
        <Divider />
        <Stack
          padding={1}
          sx={{
            color: "text.primary",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "background.light",
            },
          }}
          onClick={onRemove}
        >
          Remove day off
        </Stack>
      </Stack>
    </Popover>
  );
};

export default EditDaysOffPop;
