import AuthenticationService from "../services/AuthenticationService";
import UserService from "../services/UserService";
import {
  logIn,
  logOut,
  setSecurityInfo,
  setUserProProfile,
  setUserProfile,
  setUserRole,
} from "../slices/userSlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";
import { fetchMemberList } from "./member";

export const fetchUserProfile =
  (callBack = () => {}) =>
  (dispatch) => {
    UserService.fetchProfile()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          UserService.getLocationInfo().then((result) => {
            const data = {
              ...res.data.data,
              latitude: result.data.data.lat,
              longitude: result.data.data.lon,
              countryCode: result.data.data.countryCode,
              country: result.data.data.country,
              mapCenter: {
                lat: result.data.data.lat,
                lng: result.data.data.lon,
              },
            };
            dispatch(setUserProfile(data));
            dispatch(logIn());
            callBack();
          });
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };
export const fetchUserProProfile = () => (dispatch) => {
  UserService.fetchProProfile()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setUserProProfile(JSON.parse(res.data.data)));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const editUserIdentification =
  (data, fallBack = () => {}) =>
  (dispatch) => {
    UserService.editIdentification(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(fetchUserProfile());
          showNotification("Update Successful", "success");
          fallBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const editUserIdentificationById =
  (id, data, fallBack = () => {}) =>
  (dispatch) => {
    UserService.editIdentificationById(id, data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(fetchMemberList());
          showNotification("Update Successful", "success");
          fallBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };
export const editUserContactsById =
  (id, data, fallback = () => {}) =>
  (dispatch) => {
    UserService.editContactsById(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(fetchMemberList());
          showNotification("Update Successful", "success");
          fallback();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const editUserContacts =
  (data, fallback = () => {}) =>
  (dispatch) => {
    UserService.editContacts(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(fetchUserProfile());
          showNotification("Update Successful", "success");
          fallback();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const editUserProProfileName = (data) => (dispatch) => {
  UserService.editProProfileName(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchUserProProfile());
        showNotification("Update Successful", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const fetchSecurityInfo = () => (dispatch) => {
  UserService.fetchSecurityInfo()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setSecurityInfo(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const editUserProProfileContacts = (data) => (dispatch) => {
  UserService.editProProfileContacts(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchUserProProfile());
        showNotification("Update Successful", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const resetUserPassword = () => (dispatch) => {
  UserService.resetUserPassword()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Update Password Successful", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const enable2FA = (data) => (dispatch) => {
  UserService.enable2FA(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchSecurityInfo());
        showNotification("Confirmation Code for Enable 2FA Sent", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const disable2FA = (data) => (dispatch) => {
  UserService.disable2FA(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchSecurityInfo());
        showNotification("Confirmation Code for Disable 2FA Sent", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const signOut = () => (dispatch) => {
  AuthenticationService.signout()
    .then((res) => {
      dispatch(logOut());
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const deleteAccount =
  (callBack = () => {}) =>
  (dispatch) => {
    UserService.deleteAccount()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Your account has been deleted", "success");
          dispatch(signOut());
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };
