import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSnackbar } from "notistack";

import { classes } from "../../../utils/class";
import { getDateStringFromNum, showNotification } from "../../../utils/helpers";
import { DRIVER, WAREHOUSE_MANAGER } from "../../../utils/constants";
import { PageTitle } from "../../../components/Typography";
import { DataTable } from "../../../components/Table";
import { SearchBar } from "../../../components/CustomInput";
import { CardPrimary } from "../../../components/Card";
import { EditDetail, StateSpan } from "../../../components/StateSpan";
import IssueCertificatesDialog from "./dialogs/IssueCertificatesDialog";
import {
  createCertificate,
  fetchCertificatesList,
  issueCertificatesInBulk,
  revokeCertificate,
  validateCertificate,
} from "../../../actions/certificates";

const CertificatesListPage = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [itemsChecked, setItemsChecked] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const orgData = useSelector((state) => state.organizations.profile);
  const role = useSelector((state) => state.user.role);
  const certificatesData = useSelector(
    (state) => state.certificates.certificates
  );

  const [issueDlgOpen, setIssueDlgOpen] = useState(false);
  const [bulkIssueDlgOpen, setBulkIssueDlgOpen] = useState(false);
  const [anchorEL, setAnchorEL] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const csvLink = useRef();

  useEffect(() => {
    dispatch(
      fetchCertificatesList(() => {
        setIsLoaded(true);
      })
    );
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const onBulkActionItemClicked = () => {
    enqueueSnackbar("Not implemented yet", {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      autoHideDuration: 2000,
    });
    setOpen(false);
  };

  const handleIssueCertificate = (data) => {
    dispatch(createCertificate(data, () => setIssueDlgOpen(false)));
  };

  const handleBulkIssueCertificate = (data) => {
    dispatch(
      issueCertificatesInBulk(
        {
          ...data,
          ids: itemsChecked,
        },
        () => {
          setBulkIssueDlgOpen(false);
          setOpen(false);
        }
      )
    );
  };

  const columns = [
    {
      field: "check",
      headerName: (
        <Checkbox
          sx={classes.checkBox}
          checked={allChecked}
          onClick={() => {
            if (allChecked) setItemsChecked([]);
            else {
              const temp = certificatesData.map((cer) => cer._id);
              setItemsChecked(temp);
            }
            setAllChecked(!allChecked);
          }}
        />
      ),
      width: 100,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (props) => {
        return (
          <Checkbox
            sx={classes.checkBox}
            checked={itemsChecked.indexOf(props.row._id) !== -1}
            onClick={(e) => {
              e.stopPropagation();
              const id = itemsChecked.indexOf(props.row._id);
              const temp = [...itemsChecked];
              if (id === -1) temp.push(props.row._id);
              else temp.splice(id, 1);
              setItemsChecked(temp);
            }}
          />
        );
      },
    },
    {
      field: "id",
      headerName: "Certificate ID",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "pickup_point_name",
      headerName: "Collection Point",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "producer_name",
      headerName: "Producer",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "issued_date",
      headerName: "Issued Date",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {getDateStringFromNum(props.row.issue_date, orgData.timezone)}
        </span>
      ),
    },
    {
      field: "expiration_date",
      headerName: "Expiration Date",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {getDateStringFromNum(props.row.expiration_date, orgData.timezone)}
        </span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (props) => {
        let editOptions = [];
        if (props?.row?.status === "revoked") {
          editOptions = [
            {
              label: "Valid",
              value: "valid",
            },
          ];
        } else if (props?.row?.status === "valid") {
          editOptions = [
            {
              label: "Revoked",
              value: "revoked",
            },
          ];
        }
        return (
          <Stack direction="row">
            <StateSpan status={props.row.status} />
            <EditDetail
              props={props}
              options={editOptions}
              onStateChangeHandler={async (status) => {
                if (status === "revoked") {
                  if (role === DRIVER) {
                    showNotification("You don't have permission");
                  } else dispatch(revokeCertificate(props.row._id));
                } else {
                  dispatch(validateCertificate(props.row._id));
                }
              }}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: 3,
        gap: "8px",
        paddingX: "60px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <PageTitle>Certificates</PageTitle>

        <Stack direction="row">
          <Button
            variant="outlined"
            sx={classes.buttonSave}
            onClick={() => setIssueDlgOpen(true)}
            disabled={role === DRIVER}
          >
            Issue Certificate
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            {isLoaded ? (
              <CardPrimary
                type={1}
                title="Nº certificates"
                content={certificatesData.length}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"86px"} />
            )}
            {isLoaded ? (
              <CardPrimary
                type={2}
                title="Expiring in 1 month"
                content={
                  certificatesData.filter((cer) => {
                    const date = new Date(),
                      date1 = new Date(cer.expiration_date);
                    const diffMonths =
                      (date1.getFullYear() - date.getFullYear()) * 12 +
                      date1.getMonth() -
                      date.getMonth();
                    return diffMonths <= 1 && diffMonths >= 0;
                  }).length
                }
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"86px"} />
            )}
            {isLoaded ? (
              <CardPrimary
                type={2}
                title="Nº expired certificates"
                content={
                  certificatesData.filter((cer) => {
                    const date = new Date(),
                      date1 = new Date(cer.expiration_date);
                    return date >= date1;
                  }).length
                }
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"86px"} />
            )}
          </Box>
        </Box>
        <Box sx={{ marginTop: "20px", width: "300px" }}>
          {isLoaded ? (
            <SearchBar
              placeholder="Search certificate"
              onSearchChange={(value) => {
                setFilterModel({
                  items: [
                    {
                      id: 1,
                      field: "pickup_point_name",
                      operator: "contains",
                      value: value,
                    },
                  ],
                });
              }}
              sx={{ width: "275px" }}
            />
          ) : (
            <Skeleton variant="rounded" height={"30px"} width={"275px"} />
          )}
        </Box>
      </Box>

      <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
        {isLoaded ? (
          <Button
            variant="outlined"
            sx={classes.buttonMoreOption}
            onClick={(e) => {
              setAnchorEL(e.currentTarget);
              setOpen(!open);
            }}
          >
            Bulk actions
            <KeyboardArrowDownIcon />
          </Button>
        ) : (
          <Skeleton variant="rounded" width={"162px"} height={"46px"} />
        )}

        {itemsChecked.length ? (
          <Typography>{itemsChecked.length} selected certificates</Typography>
        ) : (
          ""
        )}
        <Menu
          id="basic-menu"
          open={open}
          anchorEl={anchorEL}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            horizontal: "center",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            sx={{
              minWidth: "228px",
            }}
            disabled={role === DRIVER || role === WAREHOUSE_MANAGER}
            onClick={() => {
              if (itemsChecked.length) csvLink.current.link.click();
              else showNotification("No Certificate is selected", "error");
              setOpen(false);
            }}
          >
            Download certificates
          </MenuItem>
          <MenuItem
            sx={{
              minWidth: "228px",
            }}
            onClick={onBulkActionItemClicked}
            disabled={role === DRIVER || role === WAREHOUSE_MANAGER}
          >
            Print certificates
          </MenuItem>
          <MenuItem
            sx={{
              minWidth: "228px",
            }}
            onClick={onBulkActionItemClicked}
            disabled={role === DRIVER || role === WAREHOUSE_MANAGER}
          >
            Send certificates by email
          </MenuItem>
          <MenuItem
            sx={{
              minWidth: "228px",
            }}
            disabled={role === DRIVER}
            onClick={() => {
              if (itemsChecked.length) setBulkIssueDlgOpen(true);
              else {
                showNotification("Please select certificate", "error");
              }
            }}
          >
            Issue new certificates
          </MenuItem>
        </Menu>
      </Stack>
      {isLoaded ? (
        <DataTable
          breakpoint="xl"
          fit="100%"
          rows={certificatesData}
          columns={columns}
          sx={classes.grid}
          filterModel={filterModel}
          onRowClick={(params, events, details) => {}}
          flex
        />
      ) : (
        <Skeleton width={"100%"} height={"200px"} />
      )}
      <IssueCertificatesDialog
        open={issueDlgOpen}
        isBulk={false}
        handleClose={() => setIssueDlgOpen(false)}
        handleLeave={() => setIssueDlgOpen(false)}
        handleConfirm={handleIssueCertificate}
      />
      <IssueCertificatesDialog
        open={bulkIssueDlgOpen}
        isBulk={true}
        itemsChecked={itemsChecked}
        handleClose={() => setBulkIssueDlgOpen(false)}
        handleLeave={() => setBulkIssueDlgOpen(false)}
        handleConfirm={handleBulkIssueCertificate}
      />
      <CSVLink
        data={certificatesData
          .filter((cer) => itemsChecked.indexOf(cer._id) !== -1)
          .map((cer) => ({
            id: cer.id,
            pickup_point_name: cer.pickup_point_name,
            producer_name: cer.producer_name,
            issue_date: getDateStringFromNum(cer.issue_date, orgData.timezone),
            expiration_date: getDateStringFromNum(
              cer.expiration_date,
              orgData.timezone
            ),
            status: cer.status,
          }))}
        filename="certificates.csv"
        headers={[
          { label: "ID", key: "id" },
          { label: "Collection Point", key: "pickup_point_name" },
          { label: "Producer", key: "producer_name" },
          { label: "Issue Date", key: "issue_date" },
          { label: "Expiration Date", key: "expiration_date" },
          { label: "Status", key: "status" },
        ]}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </Box>
  );
};

export default CertificatesListPage;
