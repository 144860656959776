import { Button, Stack, Typography, Box } from "@mui/material";
import dayjs from "dayjs";

import { classes } from "../../utils/class";
import { SmallContent } from "../Typography";

const OperatingScheduleWizard = ({
  Icon,
  title,
  content,
  buttonText,
  onAdd,
  active = false,
  data = {},
  handleDelete,
  handleEdit,
}) => {
  return (
    <Stack alignItems={"center"} justifyContent={"center"} gap={2}>
      <Stack sx={active ? classes.normalWizardActive : classes.normalWizard}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {Icon}
          {active ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <rect width="20" height="20" rx="10" fill="green" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2222 7.84843L13.3528 6.00018L9 10.3038L6.86942 8.22241L5 10.0707L9 14.0002L15.2222 7.84843Z"
                fill="white"
              />
            </svg>
          ) : (
            <Button sx={classes.buttonCancel} onClick={onAdd}>
              {buttonText}
            </Button>
          )}
        </Stack>
        <Stack sx={classes.normalWizardTitle}>{title}</Stack>
        <Stack sx={classes.normalWizardContent}>{content}</Stack>
        {active && (
          <Stack
            sx={{
              marginTop: "32px",
              backgroundColor: "#FFFFFF",
              padding: "24px",
            }}
            gap="24px"
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack>
                <Typography
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "27px",
                  }}
                >
                  Schedule
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap="16px">
                <Button
                  variant="outlined"
                  sx={classes.buttonPrimary}
                  onClick={handleEdit}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  sx={classes.buttonCancel}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Stack>
            </Stack>
            <Stack gap="8px">
              <Stack gap="4px" width="232px" direction={"row"}>
                <SmallContent sx={{ fontWeight: 700 }}>
                  Opening time:
                </SmallContent>
                <SmallContent sx={{ fontWeight: 500 }}>
                  {dayjs(data?.openingTime).format("HH:mm")}
                </SmallContent>
              </Stack>
              <Stack gap="4px" width="232px" direction={"row"}>
                <SmallContent sx={{ fontWeight: 700 }}>
                  Closing time:
                </SmallContent>
                <SmallContent sx={{ fontWeight: 500 }}>
                  {dayjs(data?.closingTime).format("HH:mm")}
                </SmallContent>
              </Stack>
              <Stack gap="4px" width="232px" direction={"row"}>
                <SmallContent sx={{ fontWeight: 700 }}>
                  Collection allowed from:
                </SmallContent>
                <SmallContent sx={{ fontWeight: 500 }}>
                  {dayjs(data?.allowedFrom).format("HH:mm")}
                </SmallContent>
              </Stack>
              <Stack gap="4px" width="232px" direction={"row"}>
                <SmallContent sx={{ fontWeight: 700 }}>
                  Collection allowed to:
                </SmallContent>
                <SmallContent sx={{ fontWeight: 500 }}>
                  {dayjs(data?.allowedTo).format("HH:mm")}
                </SmallContent>
              </Stack>
              <Stack gap="4px" width="232px" direction={"row"}>
                <SmallContent sx={{ fontWeight: 700 }}>Days off:</SmallContent>
                {data?.daysOff.map((day, index) => (
                  <SmallContent key={index} sx={{ fontWeight: 500 }}>
                    {day}
                  </SmallContent>
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export { OperatingScheduleWizard };
