import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";

export default function ScrollPlayground() {
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100,
  });

  return (
    <Box sx={{ width: "100%", height: 400 }}>
      <DataGrid hideFooter {...data} />
    </Box>
  );
}
