import React from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Button, Divider } from "@mui/material";
import { getDateStringFromNum } from "../../../../utils/helpers";

import {
  InputTitle,
  PageTitle,
  SubTitle,
} from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

const PriceHistoryDialog = ({
  open = false,
  data = [],
  handleClose = function () {},
  handleContinue = function () {},
}) => {
  const orgData = useSelector((state) => state.organizations.profile);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            minWidth: "520px",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={3} padding={5}>
            <PageTitle>Price update history</PageTitle>
            <table style={{ fontSize: "12px", fontColor: "text.accent" }}>
              <tr style={{ fontSize: "14px", fontWeight: 700 }}>
                <td flex={0.3}>Price per kg</td>
                <td flex={0.3}>Member</td>
                <td flex={0.4}>Updated</td>
              </tr>
              {data.map((d, index) => {
                const updated_at = new Date(d.updated_at);
                return (
                  <tr key={index} direction={"row"}>
                    <td>{d.price}€</td>
                    <td>{d.user}</td>
                    <td>
                      {getDateStringFromNum(
                        updated_at.getTime(),
                        orgData.timezone
                      )}{" "}
                      @{" "}
                      {getDateStringFromNum(
                        updated_at.getTime(),
                        orgData.timezone
                      )}
                    </td>
                    <Divider />
                  </tr>
                );
              })}
            </table>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default PriceHistoryDialog;
