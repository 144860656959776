import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Stack, Box } from "@mui/material";

import { classes } from "../../../utils/class";
import { signOut } from "../../../actions/user";
import {
  FleetIcon,
  PickupIcon,
  UcoIcon,
  WarehouseIcon,
} from "../../../components/icons/menu_icons/icons";

const CreateOrganizationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.user);
  return (
    <Stack
      flexDirection={"row"}
      minHeight={"100vh"}
      width={"100%"}
      justifyContent={"space-between"}
    >
      <Stack
        sx={{
          display: { xs: "none", sm: "none", md: "none", lg: "block" },
        }}
        justifyContent={"space-between"}
        position={"relative"}
      >
        <Box
          position={"fixed"}
          height={"100vh"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          width={"40%"}
          zIndex={0}
          sx={{
            backgroundColor: "#C0D4E4",
          }}
        >
          <Box position={"absolute"} bottom={0} left={0} width={"100%"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                width: "100%",
              }}
              viewBox="0 0 640 490"
              fill="none"
            >
              <path
                opacity="0.1"
                d="M667.166 314.81C661.716 263.159 615.288 225.82 563.658 231.168C511.977 236.615 474.518 282.968 479.968 334.619C480.563 340.413 480.91 346.306 480.91 352.149C480.91 442.427 407.428 515.868 317.099 515.868C226.77 515.868 153.288 442.427 153.288 352.149C153.288 346.306 153.586 340.462 154.18 334.718C154.18 334.52 154.18 334.321 154.18 334.123C162.653 250.927 232.964 187.688 316.653 187.985C368.531 188.183 410.797 146.337 411.045 94.4884C411.293 42.5898 369.423 0.298362 317.495 0.00123245C268.59 -0.246375 228.257 36.8453 223.45 84.485C214.978 167.731 144.815 231.118 61.1262 230.821C13.2614 230.524 -27.9639 266.477 -33.0675 315.058C-34.3558 327.289 -35 339.818 -35 352.199C-35 546.175 122.915 704 317 704C511.085 704 669 546.175 669 352.199C669 339.769 668.356 327.19 667.018 314.859L667.166 314.81Z"
                fill="#7783A1"
              />
            </svg>
          </Box>
          <Box px={5} pt={4}>
            <UcoIcon fill="black" />
          </Box>
          <Stack px={5} flex={0.5}>
            <Stack flexDirection={"column"} gap={2} maxWidth={"400px"}>
              <Typography
                sx={{ fontSize: "64px", color: "black", lineHeight: "normal" }}
              >
                Create organization
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Please select the organization type that matches your business
                model.
              </Typography>
            </Stack>
          </Stack>
          <Stack
            pb={4}
            px={5}
            sx={{
              color: "#AFAFAF",
              fontSize: "12px",
            }}
          >
            © 2022 Uco software v2.0.23.0
          </Stack>
        </Box>
      </Stack>
      <Stack
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "60%" },
        }}
        zIndex={1}
      >
        <Stack
          sx={{
            backgroundColor: "white",
            minHeight: "100vh",
          }}
          alignItems={"center"}
          gap={2}
          px={5}
          py={4}
        >
          <Stack
            width={"100%"}
            flexDirection="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={1}
            mb={20}
          >
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </Button>
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
              gap={1}
            >
              <Typography variant="body1">Not {`${profile.name}`}?</Typography>
              <Button
                sx={classes.buttonCancel}
                onClick={() => {
                  dispatch(signOut());
                }}
              >
                Sign out
              </Button>
            </Box>
          </Stack>
          <Stack gap={1}>
            <Stack sx={classes.selectOrganization}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <WarehouseIcon fill="#33343F" />
                <Button
                  sx={classes.orgCreateButton}
                  onClick={() => {
                    navigate("/auth/create-organization-detail");
                  }}
                >
                  Select
                </Button>
              </Stack>
              <Stack
                sx={{
                  fontSize: "32px",
                  fontWeight: 500,
                }}
              >
                WMO
              </Stack>
              <Stack
                sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 500 }}
                maxWidth={"420px"}
                mr={10}
              >
                WMOs or Waste Management Organisations who deal with the
                collection and processing of waste from waste producers.
              </Stack>
            </Stack>
            <Stack sx={classes.selectOrganization}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <PickupIcon fill="#33343F" />
                <Button
                  sx={classes.orgCreateButton}
                  onClick={() => {
                    navigate("/auth/reviewing-producer");
                  }}
                >
                  Select
                </Button>
              </Stack>
              <Stack
                sx={{
                  fontSize: "32px",
                  fontWeight: 500,
                }}
              >
                Producer
              </Stack>
              <Stack
                sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 500 }}
                maxWidth={"420px"}
                mr={10}
              >
                Organisations who are a primary producer of raw waste such as
                individual or chains of restaurants, hotels, or other private or
                public entities.
              </Stack>
            </Stack>
            <Stack sx={classes.selectOrganization}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <FleetIcon fill="#33343F" />
                <Button
                  disabled
                  sx={classes.orgCreateButton}
                  onClick={() => {
                    navigate("/auth/create-organization-detail");
                  }}
                >
                  Select
                </Button>
              </Stack>
              <Stack
                sx={{
                  fontSize: "32px",
                  fontWeight: 500,
                }}
              >
                Transporter
              </Stack>
              <Stack
                sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 500 }}
                maxWidth={"420px"}
                mr={10}
              >
                Organisations that work with Waste Management Organisations or
                other entities and specialise in the collection and
                transportation of raw waste.
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CreateOrganizationPage;
