import { Stack, Typography } from "@mui/material";
import {
  CardPrimary,
  InfoCard,
  renderTwoColumnsInfo,
} from "../../../../components/Card";
import { useState } from "react";
import { useSelector } from "react-redux";

import IdentificationDialog from "../Dialog/IdentificationDialog";
import ContactsDialog from "../Dialog/ContactsDialog";
import LocationDialog from "../Dialog/LocationDialog";
import ResponsibleDialog from "../Dialog/ResponsibleDialog";
import CustomHidden from "../../../../components/CustomHidden";
import { DRIVER, SALES, SALES_MANAGER } from "../../../../utils/constants";

const Profile = () => {
  const profile = useSelector((state) => state.warehouse.current.profile);
  const [idDlgOpen, setIdDlgOpen] = useState(false);
  const [contactsDlgOpen, setContactsDlgOpen] = useState(false);
  const [locationDlgOpen, setLocationDlgOpen] = useState(false);
  const [respDlgOpen, setRespDlgOpen] = useState(false);
  const role = useSelector((state) => state.user.role);

  return (
    <>
      <Stack gap={2}>
        <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
          <CardPrimary
            type={2}
            title={"Nº Wastes"}
            content={profile.count_of_deposits}
          />
          <CardPrimary
            type={2}
            title={"Nº Vehicles"}
            content={profile.count_of_vehicles}
          />
          <CardPrimary
            type={2}
            title={"Nº Collection Points"}
            content={profile.count_of_pickup_points}
          />
        </Stack>
        <Stack
          marginBottom={4}
          gap={2}
          direction={"row"}
          justifyContent={"flex-start"}
        >
          <CustomHidden min={1760}>
            <Stack gap={2}>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Identification"
                onClickEdit={() => setIdDlgOpen(true)}
              >
                {renderTwoColumnsInfo("Name", profile.name)}
                {renderTwoColumnsInfo("UCO.Network ID:", profile.id)}
                {renderTwoColumnsInfo(
                  "Official ID:",
                  `${profile.official_id_country || ""} ${
                    profile.official_id || ""
                  }`
                )}
              </InfoCard>
            </Stack>
            <Stack gap={2}>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Contacts"
                onClickEdit={() => setContactsDlgOpen(true)}
              >
                {renderTwoColumnsInfo(
                  "Phone",
                  profile.phone ? `+${profile.phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile Phone",
                  profile.mobile_phone ? `+${profile.mobile_phone}` : "-"
                )}
                {renderTwoColumnsInfo("Fax", profile.fax)}
                {renderTwoColumnsInfo("Email", profile.email)}
              </InfoCard>
            </Stack>
            <Stack gap={2}>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Location"
                onClickEdit={() => setLocationDlgOpen(true)}
              >
                {renderTwoColumnsInfo(
                  "COORDINATES",
                  profile?.latitude
                    ? `${profile.latitude.toFixed(
                        5
                      )}, ${profile.longitude.toFixed(5)}`
                    : ""
                )}
                <a onClick={() => setLocationDlgOpen(true)}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    View on the map
                  </Typography>
                </a>
                <Typography
                  sx={{
                    color: "text.accent",
                    fontSize: "12px",
                    fontWeight: 800,
                    fontFamily: "Manrope",
                    textTransform: "uppercase",
                  }}
                >
                  Address
                </Typography>
                <Typography
                  sx={{
                    color: "text.lighter",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {profile.addr_city}
                  <br />
                  {profile.addr_street}
                </Typography>
              </InfoCard>
            </Stack>
            <Stack gap={2}>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Responsible"
                onClickEdit={() => setRespDlgOpen(true)}
              >
                <Typography
                  sx={{
                    color: "text.light",
                    fontSize: "15px",
                    fontWeight: 800,
                    fontFamily: "Manrope",
                  }}
                >
                  {profile.responsible}
                </Typography>
                {renderTwoColumnsInfo(
                  "Phone",
                  profile.resp_phone ? `+${profile.resp_phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile phone",
                  profile.resp_mobile_phone
                    ? `+${profile.resp_mobile_phone}`
                    : "-"
                )}
                {renderTwoColumnsInfo("Email", profile.resp_email)}
              </InfoCard>
            </Stack>
          </CustomHidden>
          <CustomHidden min={1440} max={1759}>
            <Stack gap={2}>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Identification"
                onClickEdit={() => setIdDlgOpen(true)}
              >
                {renderTwoColumnsInfo("Name", profile.name)}
                {renderTwoColumnsInfo("UCO.Network ID:", profile.id)}
                {renderTwoColumnsInfo(
                  "Official ID:",
                  `${profile.official_id_country || ""} ${
                    profile.official_id || ""
                  }`
                )}
              </InfoCard>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Responsible"
                onClickEdit={() => setRespDlgOpen(true)}
              >
                <Typography
                  sx={{
                    color: "text.light",
                    fontSize: "15px",
                    fontWeight: 800,
                    fontFamily: "Manrope",
                  }}
                >
                  {profile.responsible}
                </Typography>
                {renderTwoColumnsInfo(
                  "Phone",
                  profile.resp_phone ? `+${profile.resp_phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile phone",
                  profile.resp_mobile_phone
                    ? `+${profile.resp_mobile_phone}`
                    : "-"
                )}
                {renderTwoColumnsInfo("Email", profile.resp_email)}
              </InfoCard>
            </Stack>
            <Stack gap={2}>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Contacts"
                onClickEdit={() => setContactsDlgOpen(true)}
              >
                {renderTwoColumnsInfo(
                  "Phone",
                  profile.phone ? `+${profile.phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile Phone",
                  profile.mobile_phone ? `+${profile.mobile_phone}` : "-"
                )}
                {renderTwoColumnsInfo("Fax", profile.fax)}
                {renderTwoColumnsInfo("Email", profile.email)}
              </InfoCard>
            </Stack>
            <Stack gap={2}>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Location"
                onClickEdit={() => setLocationDlgOpen(true)}
              >
                {renderTwoColumnsInfo(
                  "COORDINATES",
                  profile?.latitude
                    ? `${profile.latitude.toFixed(
                        5
                      )}, ${profile.longitude.toFixed(5)}`
                    : ""
                )}
                <a onClick={() => setLocationDlgOpen(true)}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    View on the map
                  </Typography>
                </a>
                <Typography
                  sx={{
                    color: "text.accent",
                    fontSize: "12px",
                    fontWeight: 800,
                    fontFamily: "Manrope",
                    textTransform: "uppercase",
                  }}
                >
                  Address
                </Typography>
                <Typography
                  sx={{
                    color: "text.lighter",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {profile.addr_city}
                  <br />
                  {profile.addr_street}
                </Typography>
              </InfoCard>
            </Stack>
          </CustomHidden>
          <CustomHidden min={1110} max={1439}>
            <Stack gap={2}>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Identification"
                onClickEdit={() => setIdDlgOpen(true)}
              >
                {renderTwoColumnsInfo("Name", profile.name)}
                {renderTwoColumnsInfo("UCO.Network ID:", profile.id)}
                {renderTwoColumnsInfo(
                  "Official ID:",
                  `${profile.official_id_country || ""} ${
                    profile.official_id || ""
                  }`
                )}
              </InfoCard>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Responsible"
                onClickEdit={() => setRespDlgOpen(true)}
              >
                <Typography
                  sx={{
                    color: "text.light",
                    fontSize: "15px",
                    fontWeight: 800,
                    fontFamily: "Manrope",
                  }}
                >
                  {profile.responsible}
                </Typography>
                {renderTwoColumnsInfo(
                  "Phone",
                  profile.resp_phone ? `+${profile.resp_phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile phone",
                  profile.resp_mobile_phone
                    ? `+${profile.resp_mobile_phone}`
                    : "-"
                )}
                {renderTwoColumnsInfo("Email", profile.resp_email)}
              </InfoCard>
            </Stack>
            <Stack gap={2}>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Contacts"
                onClickEdit={() => setContactsDlgOpen(true)}
              >
                {renderTwoColumnsInfo(
                  "Phone",
                  profile.phone ? `+${profile.phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile Phone",
                  profile.mobile_phone ? `+${profile.mobile_phone}` : "-"
                )}
                {renderTwoColumnsInfo("Fax", profile.fax)}
                {renderTwoColumnsInfo("Email", profile.email)}
              </InfoCard>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Location"
                onClickEdit={() => setLocationDlgOpen(true)}
              >
                {renderTwoColumnsInfo(
                  "COORDINATES",
                  profile?.latitude
                    ? `${profile.latitude.toFixed(
                        5
                      )}, ${profile.longitude.toFixed(5)}`
                    : ""
                )}
                <a onClick={() => setLocationDlgOpen(true)}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    View on the map
                  </Typography>
                </a>
                <Typography
                  sx={{
                    color: "text.accent",
                    fontSize: "12px",
                    fontWeight: 800,
                    fontFamily: "Manrope",
                    textTransform: "uppercase",
                  }}
                >
                  Address
                </Typography>
                <Typography
                  sx={{
                    color: "text.lighter",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {profile.addr_city}
                  <br />
                  {profile.addr_street}
                </Typography>
              </InfoCard>
            </Stack>
          </CustomHidden>
          <CustomHidden min={0} max={1109}>
            <Stack gap={2}>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Identification"
                onClickEdit={() => setIdDlgOpen(true)}
              >
                {renderTwoColumnsInfo("Name", profile.name)}
                {renderTwoColumnsInfo("UCO.Network ID:", profile.id)}
                {renderTwoColumnsInfo(
                  "Official ID:",
                  `${profile.official_id_country || ""} ${
                    profile.official_id || ""
                  }`
                )}
              </InfoCard>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Responsible"
                onClickEdit={() => setRespDlgOpen(true)}
              >
                <Typography
                  sx={{
                    color: "text.light",
                    fontSize: "15px",
                    fontWeight: 800,
                    fontFamily: "Manrope",
                  }}
                >
                  {profile.responsible}
                </Typography>
                {renderTwoColumnsInfo(
                  "Phone",
                  profile.resp_phone ? `+${profile.resp_phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile phone",
                  profile.resp_mobile_phone
                    ? `+${profile.resp_mobile_phone}`
                    : "-"
                )}
                {renderTwoColumnsInfo("Email", profile.resp_email)}
              </InfoCard>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Contacts"
                onClickEdit={() => setContactsDlgOpen(true)}
              >
                {renderTwoColumnsInfo(
                  "Phone",
                  profile.phone ? `+${profile.phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile Phone",
                  profile.mobile_phone ? `+${profile.mobile_phone}` : "-"
                )}
                {renderTwoColumnsInfo("Fax", profile.fax)}
                {renderTwoColumnsInfo("Email", profile.email)}
              </InfoCard>
              <InfoCard
                disable={
                  role === SALES || role === SALES_MANAGER || role === DRIVER
                }
                title="Location"
                onClickEdit={() => setLocationDlgOpen(true)}
              >
                {renderTwoColumnsInfo(
                  "COORDINATES",
                  profile?.latitude
                    ? `${profile.latitude.toFixed(
                        5
                      )}, ${profile.longitude.toFixed(5)}`
                    : ""
                )}
                <a onClick={() => setLocationDlgOpen(true)}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    View on the map
                  </Typography>
                </a>
                <Typography
                  sx={{
                    color: "text.accent",
                    fontSize: "12px",
                    fontWeight: 800,
                    fontFamily: "Manrope",
                    textTransform: "uppercase",
                  }}
                >
                  Address
                </Typography>
                <Typography
                  sx={{
                    color: "text.lighter",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {profile.addr_city}
                  <br />
                  {profile.addr_street}
                </Typography>
              </InfoCard>
            </Stack>
          </CustomHidden>
        </Stack>
      </Stack>
      <IdentificationDialog
        open={idDlgOpen}
        info={profile}
        onClose={() => setIdDlgOpen(false)}
      />
      <ContactsDialog
        open={contactsDlgOpen}
        info={profile}
        onClose={() => setContactsDlgOpen(false)}
      />
      <LocationDialog
        open={locationDlgOpen}
        info={profile}
        onClose={() => setLocationDlgOpen(false)}
      />
      <ResponsibleDialog
        open={respDlgOpen}
        info={profile}
        onClose={() => setRespDlgOpen(false)}
      />
    </>
  );
};
export default Profile;
