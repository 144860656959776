import { Button, Divider, Stack, Typography } from "@mui/material";
import { classes } from "../../utils/class";
import { MediumTitle, SmallContent } from "../Typography";

const RawWastesWizard = ({
  Icon,
  title,
  content,
  buttonText,
  onAdd,
  active = false,
  data = {},
  handleDelete,
  handleEdit,
}) => {
  return (
    <Stack alignItems={"center"} justifyContent={"center"} gap={2}>
      <Stack sx={active ? classes.normalWizardActive : classes.normalWizard}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {Icon}
          {active ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <rect width="20" height="20" rx="10" fill="green" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2222 7.84843L13.3528 6.00018L9 10.3038L6.86942 8.22241L5 10.0707L9 14.0002L15.2222 7.84843Z"
                fill="white"
              />
            </svg>
          ) : (
            <Button sx={classes.buttonCancel} onClick={onAdd}>
              {buttonText}
            </Button>
          )}
        </Stack>
        <Stack sx={classes.normalWizardTitle}>{title}</Stack>
        <Stack sx={classes.normalWizardContent}>{content}</Stack>
        {active && (
          <Stack
            sx={{
              marginTop: "32px",
              backgroundColor: "#FFFFFF",
              padding: "24px",
            }}
            gap="24px"
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack>
                <Typography
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "27px",
                  }}
                >
                  Raw waste types
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap="16px">
                <Button
                  variant="outlined"
                  sx={classes.buttonPrimary}
                  onClick={handleEdit}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  sx={classes.buttonCancel}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Stack>
            </Stack>
            {data.map((item, index) => (
              <Stack gap="8px" width="232px" key={index}>
                <SmallContent>{item}</SmallContent>
                <Divider />
              </Stack>
            ))}
            <Typography
              sx={{
                cursor: "pointer",
                fontFamily: "Manrope",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "12px",
                color: "#6293BC",
              }}
            >
              View all
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export { RawWastesWizard };
