import { transfer_axios } from "../utils/axios";

const fetchTransfersList = () => {
  return transfer_axios.get("/list-transfers");
};
const fetchTransferDetails = (id) => {
  return transfer_axios.get(`/get-transfer-details/${id}`);
};
const validateTransfers = (data) => {
  return transfer_axios.put(`/validate-transfers`, data);
}
const rejectTransfers = (data) => {
  return transfer_axios.put(`/reject-transfers`, data);
}
const editTransferWaybill = (data) => {
  return transfer_axios.put(`/edit-waybills`, data);
}
const createTransfer = (data) => {
  return transfer_axios.post(`/create-transfer`, data);
}
const getWTNDetails = (id) => {
  return transfer_axios.get(`/get-wtn-details/${id}`);
}
const TransferService = {
  fetchTransfersList,
  fetchTransferDetails,
  validateTransfers,
  rejectTransfers,
  editTransferWaybill,
  createTransfer,
  getWTNDetails
};

export default TransferService;