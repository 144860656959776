import { useState } from "react";
import CompanyProfileView from "./company/CompanyProfileView";
import IdentificationView from "./company/IdentificationView";
import ApaAccessView from "./company/ApaAccessView";
import LocationView from "./company/LocationView";
import ContactsView from "./company/ContactsView";
import ProfilePictureView from "./company/ProfilePictureView";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchOrganizationInfo } from "../../../actions/organizations";

const CompanyProfilePage = () => {
  const dispatch = useDispatch();
  const [stage, setStage] = useState(0);

  useEffect(() => {
    dispatch(fetchOrganizationInfo());
  }, []);

  if (stage === 0) return <CompanyProfileView setStage={setStage} />;
  if (stage === 1) return <IdentificationView setStage={setStage} />;
  if (stage === 2) return <ApaAccessView setStage={setStage} />;
  if (stage === 3) return <LocationView setStage={setStage} />;
  if (stage === 4) return <ContactsView setStage={setStage} />;
  return <ProfilePictureView setStage={setStage} />;
};

export default CompanyProfilePage;
