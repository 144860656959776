import {
  Button,
  Popover,
  Stack,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import { classes } from "../utils/class";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
const CModalSave = ({
  open = false,
  anchorEl = null,
  handleClose = () => {},
  onSave = () => {},
  onCancel = () => {},
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Stack
        sx={{
          padding: "16px",
        }}
        spacing={2}
      >
        <Stack>
          <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
            Confirm?
          </Typography>
        </Stack>
        <Stack spacing={2} direction={"row"} paddingLeft={2}>
          <Button
            sx={classes.buttonSave}
            onClick={() => {
              handleClose();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={classes.buttonCancel}
            onClick={() => {
              handleClose();
              onSave();
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Popover>
  );
};
const CModalDelete = ({
  open = false,
  title = "Delete",
  confirmValue = "Delete",
  closeValue = "Cancel",
  contentValue = "Deleting these recordsis irreversible and will permanently delete all related data.",
  handleCancel = () => {},
  handleDelete = () => {},
  handleClose = () => {},
}) => {
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            maxWidth: "470px",
            width: "470px",
            borderRadius: "8px",
            padding: "24px",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              padding: "16px 24px 24px 24px",
              gap: "16px",
            }}
          >
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 700,
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              {contentValue}
            </Typography>
            <Stack
              gap={2}
              direction={"row"}
              justifyContent={"space-between"}
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "14px",
                paddingTop: "8px",
              }}
            >
              <Button
                sx={{
                  ...classes.buttonCancel,
                  height: 40,
                  borderRadius: "8px",
                }}
                onClick={handleCancel}
              >
                {closeValue}
              </Button>
              <Button
                sx={{
                  ...classes.buttonSave,
                  height: 40,
                  borderRadius: "8px",
                }}
                onClick={handleDelete}
              >
                {confirmValue}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
const CModalChange = ({
  open = false,
  title = "Unsaved Changes",
  confirmValue = "Save",
  closeValue = "Leave without saving",
  contentValue = "You have unsaved changes. Are you sure you want to leave anyway?",
  handleCancel = () => {},
  handleSave = () => {},
  handleClose = () => {},
}) => {
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            maxWidth: "470px",
            borderRadius: "8px",
            padding: "24px",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              padding: "16px 24px 24px 24px",
              gap: "16px",
            }}
          >
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 700,
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              {contentValue}
            </Typography>
            <Stack
              gap={2}
              direction={"row"}
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "14px",
              }}
            >
              <Button
                sx={{
                  ...classes.buttonCancel,
                  height: 32,
                  borderRadius: "8px",
                }}
                onClick={handleCancel}
                fullWidth
              >
                {closeValue}
              </Button>
              <Button
                sx={{
                  ...classes.buttonSave,
                  height: 32,
                  borderRadius: "8px",
                }}
                onClick={handleClose}
                fullWidth
              >
                {confirmValue}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
const CExpandDetail = ({
  expanded = false,
  title = "Member",
  component = null,
  onChange = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  return (
    <>
      <Stack width={"100%"}>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <IconButton
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {!isExpanded ? <AddIcon /> : <RemoveIcon />}
          </IconButton>
          <Typography>{title}</Typography>
        </Stack>
        <Stack paddingLeft={6}>{isExpanded && component}</Stack>
      </Stack>
    </>
  );
};
export { CModalSave, CModalDelete, CModalChange, CExpandDetail };
