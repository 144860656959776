import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// material ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";

// proj import
import { classes } from "../../../../utils/class";
import { CSelect, CTextInput } from "../../../../components/CustomInput";
import { setRejectReason } from "../../../../slices/transferSlice";

const RejectWaybillDialog = ({ open, handleClose, handleConfirm }) => {
  const [description, setDescription] = useState("Described");
  const [reason, setReason] = useState("Other");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Stack spacing={3} sx={{ minWidth: "450px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            Reject waybill
          </Typography>
          <CSelect title="Reason" value={reason} options={["Other"]} />
          <CTextInput
            title="Description"
            required
            rows={4}
            value={description}
            onChange={(v) => setDescription(v)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ margin: "20px" }}>
          <Button
            variant="outlined"
            sx={classes.buttonCancel}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={classes.buttonSave}
            onClick={() => handleConfirm(description)}
          >
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default RejectWaybillDialog;
