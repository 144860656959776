import VisitService from "../services/VisitService";
import ZoneSerivce from "../services/ZoneService";
import {
  setCurrentZone,
  setScheduledVisitHistory,
  setTotalCollected,
  setTransferList,
  setVisitHistory,
  setZoneDetail,
  setZoneExtraInfo,
  setZoneList,
  setZonePickup,
  setZoneRoutes,
  setZoneScheduledVisitList,
  setZoneVisitList,
} from "../slices/zoneSlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";
import { fetchPickupPoints } from "./pickup";

export const fetchVisitHistory =
  (id, callBack = () => {}) =>
  (dispatch) => {
    ZoneSerivce.fetchZoneVisitHistory(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setVisitHistory(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const fetchScheduledVisitHistory =
  (id, callBack = () => {}) =>
  (dispatch) => {
    ZoneSerivce.fetchZoneScheduledVisitHistory(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setScheduledVisitHistory(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const fetchTransferList =
  (id, callBack = () => {}) =>
  (dispatch) => {
    ZoneSerivce.fetchZoneTransfer(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setTransferList(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const fetchZoneScheduledVisits = (id) => (dispatch) => {
  ZoneSerivce.fetchZoneScheduledVisits(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setZoneScheduledVisitList(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const fetchZoneVisits = (id) => (dispatch) => {
  ZoneSerivce.fetchZoneVisits(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setZoneVisitList(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const fetchZoneList =
  (callBack = () => {}) =>
  (dispatch) => {
    ZoneSerivce.fetchZones()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setZoneList(res.data.data.data));
          dispatch(setTotalCollected(res.data.data.total_collected));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response ? err.response.data.message : err.message,
          "error"
        );
      });
  };

export const fetchZonesPickup =
  (id, callBack = () => {}) =>
  (dispatch) => {
    ZoneSerivce.fetchZonesPickup(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setZonePickup(res.data.data.data));
          dispatch(
            setZoneExtraInfo({
              count_of_producers: res.data.data.count_of_producers,
              count_of_pickup_points: res.data.data.count_of_pickup_points,
              total_collected: res.data.data.total_collected,
            })
          );
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response ? err.response.data.message : err.message,
          "error"
        );
      });
  };

export const fetchZoneDetail =
  (id, callBack = () => {}) =>
  (dispatch) => {
    ZoneSerivce.fetchZoneDetail(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setZoneDetail(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response ? err.response.data.message : err.message,
          "error"
        );
      });
  };

// update
export const updateZone = (id, data) => (dispatch) => {
  ZoneSerivce.updateZone(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Zone updated successfully", "success");
        dispatch(fetchZoneDetail(id));
        dispatch(fetchZoneList());
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const activateZone = (id) => (dispatch) => {
  ZoneSerivce.activateZone(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Zone activated successfully", "success");
        dispatch(fetchZoneDetail(id));
        dispatch(fetchZoneList());
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const deactivateZone = (id) => (dispatch) => {
  ZoneSerivce.deactivateZone(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Zone deactivated successfully", "success");
        dispatch(fetchZoneDetail(id));
        dispatch(fetchZoneList());
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

//Create

export const createZone =
  (data, callBack = () => {}, errorCallBack = () => {}) =>
  (dispatch) => {
    ZoneSerivce.createZone(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Zone created successfully", "success");
          dispatch(fetchZoneList());
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
        errorCallBack();
      });
  };

export const addPickupPointToZone = (id, data) => (dispatch) => {
  ZoneSerivce.addPickupPointToZone(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Collection Point added successfully", "success");
        dispatch(fetchZoneDetail(id));
        dispatch(fetchZonesPickup(id));
        dispatch(fetchPickupPoints());
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

// delete

export const removeZone = (id) => (dispatch) => {
  ZoneSerivce.removeZone(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Zone removed successfully", "success");
        dispatch(setCurrentZone(0));
        dispatch(fetchZoneList());
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const removeZonePickup = (id, data) => (dispatch) => {
  ZoneSerivce.removeZonePickup(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Collection detached successfully", "success");
        dispatch(fetchZoneDetail(id));
        dispatch(fetchZonesPickup(id));
        dispatch(fetchPickupPoints());
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const checkVisit = (zoneId, id, data) => (dispatch) => {
  VisitService.updateVisitStatus(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchZoneScheduledVisits(zoneId));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const rescheduleZoneVisit = (zoneId, id, data) => (dispatch) => {
  VisitService.rescheduleVisit(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchZoneScheduledVisits(zoneId));
      }
    })
    .catch((err) => {
      showNotification(err.message, "error");
    });
};

export const listZoneRoutes = () => (dispatch) => {
  ZoneSerivce.listZoneRoutes()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setZoneRoutes(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
