import React from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import { Box, Button, Stack } from "@mui/material";

// project imports
import { PageTitle } from "../../../components/Typography";
// service imports

// third party

//sx class definition
import { classes } from "../../../utils/class";
import { CTextInput } from "../../../components/CustomInput";
import { useDispatch } from "react-redux";
import { createZone } from "../../../actions/zone";
import { useState } from "react";

const AddZonePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentZone, setCurrentZone] = useState("");
  const save = () => {
    dispatch(
      createZone(
        {
          name: currentZone,
        },
        () => {
          navigate("/zones");
        }
      )
    );
  };
  const cancel = () => {
    window.history.back();
  };

  return (
    <>
      <Stack sx={{ minHeight: "100vh", height: "100%" }} gap={10}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <PageTitle>Add zone</PageTitle>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={cancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={save}
            >
              Save
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "36px",
              minWidth: "422px",
              justifyContent: "center",
              marginBottom: 50,
            }}
          >
            <Stack>
              <CTextInput
                placeholder="Add zone name"
                title="Name"
                required
                onChange={(value) => {
                  setCurrentZone(value);
                }}
              />
            </Stack>
          </Box>
        </Box>
      </Stack>
    </>
  );
};
export default AddZonePage;
