// material ui

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";

// proj import
import { classes } from "../../../../utils/class";
import { CTextInput } from "../../../../components/CustomInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const DistributeQuantityDialog = ({ open, handleClose, handleConfirm }) => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(700);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Stack spacing={3} sx={{ minWidth: "450px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            Distribute Quantity
          </Typography>
          <CTextInput
            title="Quantity"
            required
            value={quantity}
            onChange={(v) => setQuantity(v)}
            bottomTips="The inserted value will be distributed equally among the selected items according to the weight (%) of each."
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ margin: "20px" }}>
          <Button
            variant="outlined"
            sx={classes.buttonCancel}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={classes.buttonSave}
            onClick={() => handleConfirm(quantity)}
          >
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default DistributeQuantityDialog;
