import { useState } from "react";
import { Box, Button, Checkbox } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { DataTable } from "../../../../components/Table";
import { Android12Switch, SearchBar } from "../../../../components/CustomInput";
import { EditDetail } from "../../../../components/StateSpan";
import { useDispatch, useSelector } from "react-redux";
import {
  activeRawWaste,
  inactiveRawWaste,
} from "../../../../actions/organizations";
import { setCurRawWaste } from "../../../../slices/orgSlice";
import { GridLogicOperator } from "@mui/x-data-grid";
import { BACKOFFICE, OWNER } from "../../../../utils/constants";

const RawWasteListView = ({ setStage }) => {
  const dispatch = useDispatch();
  const [filterModel, setFilterModel] = useState({ items: [] });
  const rawWastes = useSelector((state) => state.organizations.rawWastes);
  const role = useSelector((state) => state.user.role);

  const columns = [
    {
      field: "code",
      headerName: "LER Code",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 3,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "capacity",
      headerName: "C. racio (l - kg)",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (props) => {
        return (
          <>
            <Android12Switch
              onClick={(e) => {
                e.stopPropagation();
              }}
              checked={props.row.status === "active"}
              onChange={(e) => {
                if (props.row.status === "active")
                  dispatch(inactiveRawWaste(props.row.id));
                else dispatch(activeRawWaste(props.row.id));
              }}
            />
            {/* {role === BACKOFFICE || role === OWNER ? (
              <EditDetail
                options={[{ label: "Edit Raw Waste", value: "Edit Raw Waste" }]}
                props={props}
                onStateChangeHandler={async (status) => {
                  dispatch(setCurRawWaste(props.row));
                  setStage(3);
                }}
              />
            ) : (
              <></>
            )} */}
          </>
        );
      },
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <PageTitle>Raw waste</PageTitle>
        <Button
          variant="outlined"
          sx={classes.buttonPrimary}
          onClick={() => setStage(2)}
          disabled={role !== BACKOFFICE && role !== OWNER}
        >
          Manage raw waste
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "80%",
        }}
      >
        <Box sx={{ marginTop: "20px", width: "300px" }}>
          <SearchBar
            label="Search raw waste"
            onSearchChange={(value) => {
              setFilterModel({
                items: [
                  { id: 1, field: "name", operator: "contains", value: value },
                  // { id: 2, field: "code", operator: "contains", value: value },
                ],
                logicOperator: GridLogicOperator.Or,
              });
            }}
          />
        </Box>

        <DataTable
          fit="100%"
          breakpoint="xl"
          rows={rawWastes}
          columns={columns}
          sx={classes.grid}
          filterModel={filterModel}
          onRowClick={(params, events, details) => {
            if (role === BACKOFFICE || role === OWNER) {
              dispatch(setCurRawWaste(params.row));
              setStage(3);
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default RawWasteListView;
