import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUploading: false,
  isLoading: false,
};

export const spinnerSlice = createSlice({
  name: "spinner",
  initialState,
  reducers: {
    setUploadStatus: (state, action) => {
      state.isUploading = action.payload;
    },
    setLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setUploadStatus, setLoadingStatus } = spinnerSlice.actions;

export default spinnerSlice.reducer;
