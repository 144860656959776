import { useState } from "react";
import SecurityMainView from "./security/SecurityMainView";
import PasswordView from "./security/PasswordView";
import TwoFactorView from "./security/TwoFactorView";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchSecurityInfo } from "../../../actions/user";

const SecurityPage = () => {
  const dispatch = useDispatch();
  const [stage, setStage] = useState(0);

  useEffect(() => {
    dispatch(fetchSecurityInfo());
  }, []); 

  if (stage === 0) return <SecurityMainView setStage={setStage} />;
  if (stage === 1) return <PasswordView setStage={setStage} />;
  if (stage === 2) return <TwoFactorView setStage={setStage} />;
};

export default SecurityPage;
