import axios from "axios";

axios.defaults.withCredentials = true;

export const fleet_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/fleet/`,
  headers: {
    "Content-Type": "application/json",
  },
});

//warehouse axios
export const warehouse_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/warehouses/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const inventory_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/inventory/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const ticket_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/tickets/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const visit_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/visits/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const waste_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/wastes/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const transfer_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/transfers/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const pickup_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/pickup-points/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const org_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/orgs/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const team_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const route_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const auth_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const producer_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/producers/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const certificate_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/certificates/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const file_axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/files`,
});
