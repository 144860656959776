import { useState } from "react";
// material-ui import
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// project import
import { classes } from "../utils/class";
import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import { useRef } from "react";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";

const pageRange = [25, 50, 100, 200];

const MyPagination = () => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const pageStart = useGridSelector(apiRef, gridPageSelector) * pageSize;
  const pageEnd = Math.min(pageStart + pageSize, rowCount);
  const [range, setRange] = useState(pageRange[0]);
  useEffect(() => {
    const savedRange = localStorage.getItem("table-page-range");
    if (savedRange) {
      setRange(savedRange);
      apiRef.current.setPageSize(savedRange);
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Box sx={classes.pagination}>{`${
          pageEnd !== 0 ? pageStart + 1 : 0
        }-${pageEnd} of ${rowCount} Records`}</Box>
        <Box>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={range}
              label=""
              onChange={(e) => {
                apiRef.current.setPageSize(e.target.value);
                setRange(e.target.value);
                localStorage.setItem("table-page-range", e.target.value);
              }}
              size="small"
              sx={{
                ...classes.pagination,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              {pageRange.map((range, index) => {
                return (
                  <MenuItem sx={classes.pagination} value={range} key={index}>
                    {range} per page
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Pagination
          color="primary"
          count={pageCount}
          page={Math.floor(pageStart / pageSize) + 1}
          onChange={(event, newPage) => {
            apiRef.current.setPage(newPage - 1);
          }}
          sx={{
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "text.primary",
              ...classes.pagination,
              color: "text.contrast",
            },
          }}
        />
      </Box>
    </>
  );
};

export const DataTable = ({
  rows,
  columns,
  onRowClick,
  editable = "row",
  rowModesModel,
  handleRowModesModelChange,
  handleRowEditStop,
  processRowUpdate,
  onCellEditStart,
  filterModel = { items: [] },
  setFilterModel = {},
  detailContent = null,
  pagination = true,
  rowHeight = 60,
  initialState = {},
  sx = {},
  rowClass = false,
  expandable = false,
  flex = false,
  fit = "fit-content",
  width = "100%",
  autoSize = false,
  breakpoint = "lg",
  autoHeight = false,
}) => {
  const [ids, setIds] = useState([]);
  const [prevIds, setPrevIds] = useState([]);
  const apiRef = useRef({});
  const tableRef = useRef({});

  useEffect(() => {
    setIds([]);
    setPrevIds([...ids]);
  }, [rows]);

  useEffect(() => {
    setIds([...prevIds]);
  }, [prevIds]);

  useEffect(() => {
    if (autoSize === true && apiRef.current && apiRef.current.autosizeColumns) {
      apiRef.current.autosizeColumns({
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
        outlierFactor: 3,
      });
    }
  }, [apiRef]);

  const theme = useTheme();
  return (
    <Box
      // width={fit}
      width="100%"
      sx={{
        overflowX: expandable ? "visible" : "auto",
        [theme.breakpoints.down(breakpoint)]: {
          width: width,
        },
      }}
      className="dataGrid"
    >
      <DataGrid
        ref={tableRef}
        apiRef={apiRef}
        rowHeight={rowHeight}
        rows={rows}
        sx={{
          ...classes.grid,
          ...sx,
        }}
        columns={[...columns]}
        onRowClick={onRowClick}
        initialState={{
          ...initialState,
        }}
        pageSizeOptions={pageRange}
        components={{
          ColumnSortedAscendingIcon: ArrowDropUpIcon,
          ColumnSortedDescendingIcon: ArrowDropDownIcon,
          NoRowsOverlay: () => {
            <Stack height="100%" alignItems="center" justifyContent="center">
              No rows in DataGrid
            </Stack>;
          },
          NoResultsOverlay: () => {
            <Stack height="100%" alignItems="center" justifyContent="center">
              Local filter returns no result
            </Stack>;
          },
        }}
        autoHeight={autoHeight}
        editMode={editable}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          pagination: () => <MyPagination />,
          detailPanelExpandIcon: AddCircleOutlineIcon,
          detailPanelCollapseIcon: HighlightOffIcon,
        }}
        filterModel={filterModel}
        onFilterModelChange={(model) => setFilterModel(model)}
        disableColumnFilter={true}
        getDetailPanelContent={detailContent}
        columnBuffer={10}
        pagination={pagination}
        onCellEditStart={onCellEditStart}
        getRowClassName={(params) => {
          if (rowClass) {
            if (params.row.status === "discarded") return "discardedText";
            else return;
          }
        }}
      />
    </Box>
  );
};
