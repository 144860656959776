import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  warehouses: [],
  current: {
    id: null,
    profile: {},
    pickup: [],
    transfers: [],
    fleet: [],
    deposits: [],
    add_deposit: false,
    inventories: [],
    offers: [],
    visits: [],
  },
};

export const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    setWarehouseList: (state, action) => {
      state.warehouses = action.payload;
    },
    setCurrentWarehouseId: (state, action) => {
      state.current.id = action.payload;
    },
    setWarehouseProfile: (state, action) => {
      state.current.profile = action.payload;
    },
    setWarehouseVisits: (state, action) => {
      state.current.visits = action.payload;
    },
    setWarehousePickupPoints: (state, action) => {
      state.current.pickup = action.payload;
    },
    setWarehouseTransfers: (state, action) => {
      state.current.transfers = action.payload;
    },
    setWarehouseFleet: (state, action) => {
      state.current.fleet = action.payload;
    },
    setWarehouseDeposits: (state, action) => {
      state.current.deposits = action.payload;
    },
    setAddDepositStep: (state, action) => {
      state.current.add_deposit = action.payload;
    },
    setInventories: (state, action) => {
      state.current.inventories = action.payload;
    },
    setOffers: (state, action) => {
      state.current.offers = action.payload;
    },
  },
});

export const {
  setWarehouseList,
  setCurrentWarehouseId,
  setWarehouseProfile,
  setWarehousePickupPoints,
  setWarehouseTransfers,
  setWarehouseFleet,
  setWarehouseDeposits,
  setAddDepositStep,
  setInventories,
  setOffers,
  setWarehouseVisits,
} = warehouseSlice.actions;

export default warehouseSlice.reducer;
