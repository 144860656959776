import { route_axios } from "../utils/axios";

// GET

const fetchZoneVisitHistory = (id) => {
  return route_axios.get(`/zones/get-visits-history/${id}`);
};

const fetchZoneScheduledVisitHistory = (id) => {
  return route_axios.get(`/zones/get-scheduled-visits-history/${id}`);
};

const fetchZoneTransfer = (id) => {
  return route_axios.get(`/zones/list-transfers/${id}`);
};

const fetchZonesPickup = (id) => {
  return route_axios.get(`/zones/list-pickup-points/${id}`);
};

const fetchZones = () => {
  return route_axios.get(`/zones/list-zones`);
};

const fetchZoneDetail = (id) => {
  return route_axios.get(`/zones/get-zone-details/${id}`);
};

const fetchZoneVisits = (id) => {
  return route_axios.get(`/zones/list-visits/${id}`);
};

const fetchZoneScheduledVisits = (id) => {
  return route_axios.get(`/zones/list-scheduled-visits/${id}`);
};
// PUT

const updateZone = (id, data) => {
  return route_axios.put(`/zones/edit-zone/${id}`, data);
};

const activateZone = (id) => {
  return route_axios.put(`/zones/activate-zone/${id}`);
};

const deactivateZone = (id) => {
  return route_axios.put(`/zones/deactivate-zone/${id}`);
};
// POST
const createZone = (data) => {
  return route_axios.post("/zones/create-zone", data);
};
const addPickupPointToZone = (id, data) => {
  return route_axios.post(`/zones/attach-pickup-point/${id}`, data);
};

//DELETE

const removeZone = (id) => {
  return route_axios.delete(`/zones/remove-zone/${id}`);
};

const removeZonePickup = (id, data) => {
  return route_axios.delete(
    `/zones/detach-pickup-point/${id}/${data.pickup_point}`
  );
};

//g map
const getMap = (query) => {
  return route_axios.post(`/zones/google-map`, { query });
};

const listZoneRoutes = () => {
  return route_axios.get("/zones/list-route-zones");
};

const listPickupRoutes = (zone_id, date) => {
  if (zone_id) {
    return route_axios.post("zones/list-route-pickup-points", {
      id: zone_id,
      date,
    });
  } else {
    return route_axios.post("zones/list-route-pickup-points", {
      date,
    });
  }
};
const ZoneSerivce = {
  listPickupRoutes,
  getMap,
  fetchZoneDetail,
  fetchZones,
  fetchZoneTransfer,
  fetchZonesPickup,
  fetchZoneVisits,
  fetchZoneVisitHistory,
  fetchZoneScheduledVisitHistory,
  fetchZoneScheduledVisits,
  listZoneRoutes,
  updateZone,
  activateZone,
  deactivateZone,
  createZone,
  addPickupPointToZone,
  removeZone,
  removeZonePickup,
};
export default ZoneSerivce;
