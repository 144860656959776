import CertificateService from "../services/CertificateService";
import { setCertificatesList } from "../slices/certificatesSlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";

export const fetchCertificatesList =
  (callBack = () => {}) =>
  (dispatch) => {
    CertificateService.fetchCertificatesList()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setCertificatesList(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const revokeCertificate = (id) => (dispatch) => {
  CertificateService.revokeCertificate(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchCertificatesList());
        showNotification("Revoke successful", "error");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const validateCertificate = (id) => (dispatch) => {
  CertificateService.validateCertificate(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchCertificatesList());
        showNotification("Validation successful", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const createCertificate = (data, fallback) => (dispatch) => {
  CertificateService.createCertificate(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchCertificatesList());
        showNotification("Issued successfully", "success");
        fallback();
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const issueCertificatesInBulk = (data, fallback) => (dispatch) => {
  CertificateService.issueCertificatesInBulk(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchCertificatesList());
        showNotification("Updated successfully", "success");
        fallback();
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const sendDocumentsByEmail = (data) => (dispatch) => {
  CertificateService.sendDocumentsByEmail(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Documents were sent by email", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
