import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  Grid,
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import InvalidLink from "../InvalidLink/InvalidLink";
import ucoNetworkAuthenticationBg from "../../../../assets/images/uco-network-authentication-bg.png";
import AuthenticationService from "../../../../services/AuthenticationService";
import { SUCCESS } from "../../../../utils/type";
import { showNotification, snackbarRef } from "../../../../utils/helpers";
import { ERROR } from "../../../../utils/constants";
import { classes } from "../../../../utils/class";
import {
  strengthColor,
  strengthIndicator,
} from "../../../../utils/password-strength";

const SetNewPasswordPage = () => {
  snackbarRef.current = useSnackbar();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const id = urlParams.get("id");
  const navigate = useNavigate();
  const [isValidLink, setIsValidLink] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState();

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    AuthenticationService.verifyPasswordResetLink({ email, id })
      .then((res) => {
        if (res.data.message === SUCCESS) {
          setIsValidLink(true);
        }
      })
      .catch((err) => {
        setIsValidLink(false);
      });
  }, []);
  return (
    <>
      {isValidLink ? (
        <div style={{ display: "flex", height: "100vh", minHeight: "810px" }}>
          <div style={{ flex: "0 0 41.6667%", maxWidth: "41.6667%" }}>
            <img
              src={ucoNetworkAuthenticationBg}
              alt="uco network authentication background"
              style={{
                display: "block",
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
            />
          </div>

          <div
            style={{
              position: "relative",
              minHeight: "1px",
              flex: "0 0 58.3333%",
              maxWidth: "58.3333%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                top: "10px",
                right: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            ></div>

            <div style={{ width: "100%", maxWidth: "343px" }}>
              <Typography variant="h3">Set a new password</Typography>
              <Typography variant="body1" sx={{ mt: "0.5rem" }}>
                Create a new, strong password that you don’t use for other
                websites
              </Typography>

              <Formik
                initialValues={{
                  password: "",
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string()
                    .max(255)
                    .required("Password is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  const password = values.password;
                  AuthenticationService.resetPassword({
                    email,
                    password,
                    id,
                  })
                    .then((res) => {
                      if (res.data.message === SUCCESS) {
                        showNotification(
                          "Password changed successfully!",
                          "success"
                        );
                        navigate("/auth/signin");
                      }
                    })
                    .catch((err) => {
                      showNotification(
                        err.response
                          ? ERROR[err.response.data.message] || "Network Error"
                          : err.message,
                        "error"
                      );
                    });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                    >
                      {/* <InputLabel htmlFor="outlined-adornment-password-register">
                  Password
                </InputLabel> */}
                      <Typography variant="body1" sx={{ mt: "2rem" }}>
                        Password
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <OutlinedInput
                        id="outlined-adornment-password-register"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        name="password"
                        // label="Password"
                        placeholder="Insert your password"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          changePassword(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{}}
                        size="small"
                        sx={{ mt: "0.5rem" }}
                      />
                      {touched.password && errors.password && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-password-register"
                        >
                          {errors.password}
                        </FormHelperText>
                      )}
                    </FormControl>

                    {strength !== 0 && (
                      <FormControl fullWidth>
                        <Box sx={{ mb: 2 }}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <Box
                                style={{ backgroundColor: level?.color }}
                                sx={{
                                  width: 85,
                                  height: 8,
                                  borderRadius: "7px",
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="subtitle1"
                                fontSize="0.75rem"
                              >
                                {level?.label}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </FormControl>
                    )}

                    <Box sx={{ mt: "1.5rem" }}>
                      <Button
                        disableElevation
                        disabled={isSubmitting}
                        fullWidth
                        size="medium"
                        type="submit"
                        variant="outlined"
                        sx={classes.buttonSave}
                      >
                        Change password
                      </Button>
                    </Box>

                    <Typography
                      variant="subtitle1"
                      component={Link}
                      to="/auth/signin"
                      sx={{ display: "block", mt: "1.5rem" }}
                    >
                      Back to Log in
                    </Typography>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      ) : (
        isValidLink === false && (
          <InvalidLink
            resendEmail={() => {
              return AuthenticationService.resendResetPasswordRequest({
                email,
              });
            }}
            emailNotification={
              "A password recovery email has been sent. Check your inbox and follow the instructions"
            }
          ></InvalidLink>
        )
      )}
    </>
  );
};

export default SetNewPasswordPage;
