import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

// material ui
import { Box, Button, Stack, Typography } from "@mui/material";

// proj import
import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { CTextInput } from "../../../../components/CustomInput";
import CountrySelect from "../../../../components/CountrySelect";
import { COUNTRIES_INFO } from "../../../../utils/constants";
import UnsavedDialog from "../dialogs/UnsavedDialog";
import { showNotification } from "../../../../utils/helpers";
import {
  editUserIdentification,
  editUserIdentificationById,
} from "../../../../actions/user";

const IdentificationView = ({ setStage, user = null }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.profile);
  const profile = user === null ? data : user;
  const [country, setCountry] = useState(null);
  const [name, setName] = useState(profile.name);
  const [unsavedOpen, setUnsavedOpen] = useState(false);

  useEffect(() => {
    setCountry(
      COUNTRIES_INFO.find((info) => info.code === profile.nationality)
    );
  }, [profile]);

  const onChangeCountry = (e, v) => {
    setCountry(v);
  };

  const onClickCancel = () => {
    if (name === profile.name && country.code === profile.nationality) {
      setStage(0);
      return;
    }
    setUnsavedOpen(true);
  };

  const handleSave = () => {
    if (name.length === 0) {
      showNotification("The field is empty. Name is required.", "error");
      return;
    }
    if (user === null)
      dispatch(
        editUserIdentification({
          name,
          nationality: country?.code,
        })
      );
    else {
      dispatch(
        editUserIdentificationById(user.user_id, {
          name,
          nationality: country?.code,
        })
      );
    }
    setStage(0);
  };

  return (
    <Box>
      <PageTitle>Profile</PageTitle>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          display: "flex",
          minHeight: "80%",
          marginTop: "40px",
        }}
      >
        <Box sx={{ width: "40%", minWidth: "300px" }}>
          <Typography sx={{ maxWidth: "300px" }}>
            Your personal data is safe with us.
          </Typography>
        </Box>
        <Stack sx={{ width: "100%", maxWidth: "500px" }}>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
          >
            Identification
          </Typography>
          <CTextInput
            title="Name"
            required={true}
            placeholder="Input name here"
            value={name}
            onChange={(val) => setName(val)}
          />
          <CTextInput
            title="Email"
            required={true}
            value={profile.email}
            disabled={true}
            bottomTips="For security reasons share your email only with those you trust or choose to use your username."
          />
          <CTextInput
            title="Username"
            value={profile.username}
            disabled={true}
            bottomTips="You can share your username to be invited to join organizations. Avoid sharing your email address."
          />
          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
            Nationality
          </Typography>
          <CountrySelect
            label=""
            value={country}
            showNumber={false}
            sx={{ width: "100%" }}
            onChange={onChangeCountry}
          />
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "space-between", marginTop: "20px" }}
          >
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={onClickCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonSave}
              onClick={handleSave}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <UnsavedDialog
        open={unsavedOpen}
        handleLeave={() => setStage(0)}
        handleClose={() => setUnsavedOpen(false)}
        handleSave={handleSave}
      />
    </Box>
  );
};

export default IdentificationView;
