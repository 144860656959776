import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import GoogleMapReact from "google-map-react";

import { CTextInput, Android12Switch, SearchBar } from "./CustomInput";
import { SmallTitle } from "./Typography";
import {
  PlacesItem,
  LocationItem,
} from "../views/pages/routes/component/Location";
import {
  core,
  getFilteredPlaces,
  updateLatitudeAndLongitude,
} from "../utils/map";
import { DETAIL_ZOOM, DEFAULT_ZOOM } from "../utils/type";

const CoordinatesMap = ({
  data,
  onChange,
  description,
  direction = "row",
  mapHeight = undefined,
  extraComponent = null,
}) => {
  const { profile } = useSelector((state) => state.user);
  const [isEditable, setIsEditable] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [zoom, setZoom] = useState(DETAIL_ZOOM);
  const [places, setPlaces] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [country, setCountry] = useState(null);
  const [apartment, setApartment] = useState(null);
  const [city, setCity] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [street, setStreet] = useState(null);
  const [unit, setUnit] = useState(null);

  const onClickMap = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
    core(lat, lng).then((res) => {
      onChange({
        latitude: lat,
        longitude: lng,
        addr_country: res.country,
        addr_city: res.city,
        addr_apartment: res.apartment,
        addr_postcode: res.postcode,
        addr_street: res.street,
      });
      setCountry(res.country);
      setApartment(res.apartment);
      setCity(res.city);
      setPostcode(res.postcode);
      setStreet(res.street);
    });
  };

  const onInputChange = (street, apartment, city, postcode, country) => {
    updateLatitudeAndLongitude(street, apartment, city, postcode, country).then(
      (res) => {
        if (res.status === true) {
          setZoom(DETAIL_ZOOM);
          onChange({
            latitude: res.latitude,
            longitude: res.longitude,
            addr_street: street,
            addr_apartment: apartment,
            addr_city: city,
            addr_postcode: postcode,
            addr_country: country,
          });
        } else {
          setZoom(DEFAULT_ZOOM);
        }
      }
    );
  };

  const onSearch = (value) => {
    getFilteredPlaces(value, latitude, longitude).then((data) => {
      setPlaces(data);
    });
  };

  useEffect(() => {
    if (mapLoaded) {
      if (
        data &&
        data.latitude &&
        data.longitude &&
        data.latitude !== undefined &&
        data.longitude !== undefined
      ) {
        setLatitude(data?.latitude || profile.latitude);
        setLongitude(data?.longitude || profile.longitude);
        if (isEditable) {
        } else {
          setStreet(data?.addr_street);
          setCity(data?.addr_city);
          setApartment(data?.addr_apartment);
          setPostcode(data?.addr_postcode);
          setCountry(data?.addr_country);
        }
      } else {
        onClickMap(profile.latitude, profile.longitude);
      }
    }
  }, [data, isEditable, mapLoaded]);

  return (
    <>
      <Stack direction={"row"} gap={4}>
        <Stack flex={1}>
          <Stack>
            <Stack gap={1} flexDirection={"row"}>
              <Stack flex={1}>
                <CTextInput
                  title="Latitude"
                  placeholder="Ex: 41.345"
                  disabled
                  value={latitude}
                  onChange={(val) => {
                    setLatitude(val);
                    onInputChange(val, apartment, city, postcode, country);
                  }}
                />
              </Stack>
              <Stack flex={1}>
                <CTextInput
                  title="Longitude"
                  placeholder="Ex: 41.345"
                  disabled
                  value={longitude}
                  onChange={(val) => {
                    setLongitude(val);
                    onInputChange(val, apartment, city, postcode, country);
                  }}
                />
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              py="18px"
            >
              <SmallTitle>Address</SmallTitle>
              <Stack direction={"row"} alignItems={"center"} gap="16px">
                <Typography
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Use company address
                </Typography>
                <Android12Switch
                  onChange={(e) => {
                    setIsEditable(e.target.checked);
                  }}
                />
              </Stack>
            </Stack>
            <CTextInput
              disabled={!isEditable}
              title=""
              placeholder="Street"
              value={street}
              onChange={(val) => {
                setStreet(val);
                onInputChange(val, apartment, city, postcode, country);
              }}
              sx={{
                paddingBottom: "12px",
              }}
            />
          </Stack>
          <Stack direction={"row"} gap={1}>
            <Stack flex={3}>
              <CTextInput
                disabled={!isEditable}
                title=""
                placeholder="Unit"
                value={unit}
                onChange={(val) => {
                  setUnit(val);
                  onInputChange(val, apartment, city, postcode, country);
                }}
                sx={{
                  paddingBottom: "12px",
                }}
              />
            </Stack>
            <Stack flex={2}>
              <CTextInput
                disabled={!isEditable}
                title=""
                placeholder="Postcode"
                value={postcode}
                onChange={(val) => {
                  setPostcode(val);
                  onInputChange(val, apartment, city, postcode, country);
                }}
                sx={{
                  paddingBottom: "12px",
                }}
              />
            </Stack>
          </Stack>
          <Stack direction={"row"} gap={1}>
            <Stack flex={2}>
              <CTextInput
                disabled={!isEditable}
                title=""
                placeholder="City"
                value={city}
                onChange={(val) => {
                  setCity(val);
                  onInputChange(val, apartment, city, postcode, country);
                }}
              />
            </Stack>
            <Stack flex={3}>
              <CTextInput
                title=""
                disabled={!isEditable}
                placeholder="Country"
                value={country}
                onChange={(val) => {
                  setCountry(val);
                  onInputChange(val, apartment, city, postcode, country);
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack flex={1}>
          <Stack width="100%" height="100%" position={"relative"}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API }}
              center={{
                lat: latitude,
                lng: longitude,
              }}
              zoom={zoom}
              onGoogleApiLoaded={() => {
                setMapLoaded(true);
              }}
              onClick={(e) => {
                onClickMap(e.lat, e.lng);
              }}
              yesIWantToUseGoogleMapApiInternals={true}
            >
              {places.map((place, index) => {
                return (
                  <PlacesItem
                    key={index}
                    data={place}
                    {...place.geometry.location}
                    onClick={() => {
                      onClickMap(
                        place.geometry.location.lat,
                        place.geometry.location.lng
                      );
                    }}
                  />
                );
              })}
              <LocationItem
                color="#BF1A2F"
                type={1}
                lat={latitude}
                lng={longitude}
              />
            </GoogleMapReact>
            <Stack position={"absolute"} top={10} left={10}>
              <SearchBar onEnter={onSearch} placeholder="Select ..." />
            </Stack>
            <Typography sx={{ fontSize: "10px" }}>
              Select a point on the map to add the {description} location
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export { CoordinatesMap };
