import { classes } from "../../../utils/class";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Item, ItemList } from "../../../components/ItemList";
import { useState } from "react";
import { PageTitle } from "../../../components/Typography";
import { StateSpan } from "../../../components/StateSpan";
import {
  Tab,
  TabBody,
  TabHeader,
  TabHeaderItem,
  Tabs,
} from "../../../components/Tab";
import { Menu, MenuItem, Button, Stack, Box, Skeleton } from "@mui/material";
import Profile from "./Tab/Profile";
import Fleet from "./Tab/Fleet";
import VisitPage from "./Tab/VisitPage";
import Deposits from "./Tab/Deposits";
import PickupPoints from "./Tab/PickupPoints";
import Transfers from "./Tab/Transfers";
import {
  activateWarehouse,
  deactivateWarehouse,
  fetchDeposits,
  fetchFleet,
  fetchInventoryByWarehouse,
  fetchPickupPoints,
  fetchTransfers,
  fetchWarehouseDetail,
  fetchWarehouseList,
  fetchWarehouseVisits,
} from "../../../actions/warehouse";
import { useEffect } from "react";
import { setAddDepositStep } from "../../../slices/warehouseSlice";
import { STATE_ACTIVE, STATE_INACTIVE } from "../../../utils/type";
import ChangeStatusDialog from "./Dialog/ChangeStatusDialog";
import RemoveWarehouseDialog from "./Dialog/RemoveWarehouseDialog";
import Inventory from "./Tab/Inventory";
import Offer from "./Tab/Offer";
import { MoreVertOutlined } from "@mui/icons-material";
import {
  BACKOFFICE,
  DRIVER,
  OWNER,
  SALES,
  SALES_MANAGER,
  WAREHOUSE_MANAGER,
} from "../../../utils/constants";

const WarehouseProfilePage = () => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);

  const { current, warehouses } = useSelector((state) => state.warehouse);
  const [currentTab, setCurrentTab] = useState(0);
  const [anchorEL, setAnchorEL] = useState(null);
  const [open, setOpen] = useState(false);
  const [activation, setActivation] = useState(false);
  const navigate = useNavigate();
  const [statusChangeDlgInfo, setStatusChangeDlgInfo] = useState({
    open: false,
    id: null,
  });
  const [rmvDlgInfo, setRmvDlgInfo] = useState({
    open: false,
    id: null,
  });

  const [isLoaded, setIsLoaded] = useState(false);

  const current_warehouse = useSelector((state) => state.warehouse.current);
  const [current_id, setCurrentId] = useState(current_warehouse.id);
  useEffect(() => {
    dispatch(fetchWarehouseList());
  }, []);

  useEffect(() => {
    if (current.id !== null) dispatch(fetchWarehouseDetail(current.id));
    else {
      if (warehouses.length > 0) {
        dispatch(fetchWarehouseDetail(warehouses[0]._id));
        setCurrentId(warehouses[0]._id);
      }
    }
  }, [warehouses]);

  useEffect(() => {
    setIsLoaded(false);
    if (currentTab === 0)
      dispatch(
        fetchWarehouseDetail(current_id, () => {
          setIsLoaded(true);
        })
      );
    if (currentTab === 1)
      dispatch(
        fetchDeposits(current_id, () => {
          setIsLoaded(true);
        })
      );
    if (currentTab === 2)
      dispatch(
        fetchFleet(current_id, () => {
          setIsLoaded(true);
        })
      );
    if (currentTab === 3)
      dispatch(
        fetchPickupPoints(current_id, () => {
          setIsLoaded(true);
        })
      );
    if (currentTab === 4)
      dispatch(
        fetchWarehouseVisits(current_id, () => {
          setIsLoaded(true);
        })
      );
    if (currentTab === 5)
      dispatch(
        fetchTransfers(current_id, () => {
          setIsLoaded(true);
        })
      );
    if (currentTab === 6)
      dispatch(
        fetchInventoryByWarehouse(false, current_id, () => {
          setIsLoaded(true);
        })
      );
    if (currentTab === 7)
      dispatch(
        fetchInventoryByWarehouse(true, current_id, () => {
          setIsLoaded(true);
        })
      );
  }, [currentTab, current_id]);

  const handleClose = () => {
    setOpen(false);
  };
  const onCurrentChange = (index) => {
    setIsLoaded(false);
    setCurrentId(warehouses[index]._id);
  };

  const tabChange = (value) => {
    setCurrentTab(value);
  };

  const onConfirmChangeStatus = () => {
    dispatch(deactivateWarehouse(statusChangeDlgInfo.id));
    setStatusChangeDlgInfo({
      open: false,
    });
  };

  const handleAddDeposit = () => {
    setCurrentTab(1);
    dispatch(setAddDepositStep(true));
  };

  let options = [];
  if (role === OWNER || role === BACKOFFICE || role === WAREHOUSE_MANAGER) {
    if (current?.profile?.status === "active") {
      options = [
        {
          label: "Inactive",
          value: "inactive",
        },
      ];
    } else if (current?.profile?.status === "inactive") {
      options = [
        {
          label: "Active",
          value: "active",
        },
      ];
    }
  }
  return (
    <>
      <Stack minHeight={"100vh"} height={"100%"} direction={"row"}>
        <Box
          sx={{
            backgroundColor: "#F3F1EB",
            paddingTop: "32px",
            maxWidth: "250px",
          }}
        >
          <Box
            sx={{
              ...classes.buttonBack,
              marginLeft: "24px",
              marginBottom: "30px",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon fontSize="" sx={{ marginRight: "8px" }} /> Back to
            list
          </Box>
          <ItemList
            value={current}
            onSelectChange={onCurrentChange}
            label="Search Warehouse"
          >
            {warehouses.map((item, index) => {
              return (
                <Item
                  key={index}
                  title={item.name}
                  content={item.official_id}
                  selected={current_id === item._id}
                />
              );
            })}
          </ItemList>
        </Box>
        <Stack
          paddingTop={"50px"}
          paddingX={"17px"}
          width={"calc(100% - 250px);"}
          sx={{
            backgroundColor: "white",
            height: "100%",
            minHeight: "100vh",
          }}
          gap={1}
        >
          <Stack gap={1}>
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <PageTitle>{current.profile.name}</PageTitle>

                <StateSpan
                  status={current.profile.status}
                  options={options}
                  onStateChangeHandler={(status) => {
                    if (
                      status === STATE_ACTIVE &&
                      current.profile.status === STATE_INACTIVE
                    ) {
                      dispatch(activateWarehouse(current.id));
                    } else if (
                      status === STATE_INACTIVE &&
                      current.profile.status === STATE_ACTIVE
                    ) {
                      setStatusChangeDlgInfo({ open: true, id: current.id });
                    }
                  }}
                />
              </Stack>
              <Stack pr={"17px"}>
                <Button
                  variant="outlined"
                  sx={classes.buttonMoreOption}
                  onClick={(e) => {
                    setAnchorEL(e.currentTarget);
                    setOpen(!open);
                  }}
                >
                  Options
                  <MoreVertOutlined />
                </Button>
                <Menu
                  sx={classes.optionMenu}
                  id="basic-menu"
                  open={open}
                  anchorEl={anchorEL}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    horizontal: "center",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    sx={{
                      minWidth: "228px",
                    }}
                    onClick={() => setCurrentTab(0)}
                    disabled={role === DRIVER}
                  >
                    Edit Warehouse
                  </MenuItem>
                  <MenuItem
                    sx={{
                      minWidth: "228px",
                    }}
                    onClick={handleAddDeposit}
                    disabled={
                      role === SALES_MANAGER ||
                      role === SALES ||
                      role === DRIVER
                    }
                  >
                    Add Capacity
                  </MenuItem>
                  <MenuItem
                    sx={{
                      minWidth: "228px",
                    }}
                    onClick={() =>
                      setRmvDlgInfo({ open: true, id: current.id })
                    }
                    disabled={
                      role === SALES_MANAGER ||
                      role === SALES ||
                      role === DRIVER ||
                      role === WAREHOUSE_MANAGER
                    }
                  >
                    Remove Warehouse
                  </MenuItem>
                </Menu>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Tabs onTabChangeHandler={tabChange} tab={currentTab}>
              <TabHeader>
                <TabHeaderItem>Profile</TabHeaderItem>
                <TabHeaderItem>Waste Capacity</TabHeaderItem>
                <TabHeaderItem>Fleet</TabHeaderItem>
                <TabHeaderItem>Collection Points</TabHeaderItem>
                <TabHeaderItem>Visits</TabHeaderItem>
                <TabHeaderItem>Transfers</TabHeaderItem>
                <TabHeaderItem>Inventory</TabHeaderItem>
                <TabHeaderItem>Offers</TabHeaderItem>
              </TabHeader>
              <></>
              <TabBody>
                {isLoaded ? (
                  <Tab>
                    <Profile activation={activation} />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                    <Stack
                      flexDirection={"row"}
                      gap={2}
                      mt={2}
                      flexWrap={"wrap"}
                    >
                      <Skeleton
                        variant="rounded"
                        width={"300px"}
                        height={"300px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"300px"}
                        height={"300px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"300px"}
                        height={"200px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"300px"}
                        height={"300px"}
                      />
                    </Stack>
                  </Tab>
                )}

                {isLoaded ? (
                  <Tab>
                    <Deposits />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                    <Stack flexDirection={"row"} mt={2}>
                      <Skeleton
                        variant="rounded"
                        width={"80%"}
                        height={"100px"}
                      />
                    </Stack>
                  </Tab>
                )}

                {isLoaded ? (
                  <Tab>
                    <Fleet />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                    <Stack flexDirection={"row"} mt={2}>
                      <Skeleton
                        variant="rounded"
                        width={"80%"}
                        height={"100px"}
                      />
                    </Stack>
                  </Tab>
                )}

                {isLoaded ? (
                  <Tab>
                    <PickupPoints />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                    <Stack flexDirection={"row"} mt={2}>
                      <Skeleton
                        variant="rounded"
                        width={"80%"}
                        height={"100px"}
                      />
                    </Stack>
                  </Tab>
                )}

                {isLoaded ? (
                  <Tab>
                    <VisitPage />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                    <Stack flexDirection={"row"} mt={2}>
                      <Skeleton
                        variant="rounded"
                        width={"80%"}
                        height={"100px"}
                      />
                    </Stack>
                  </Tab>
                )}

                {isLoaded ? (
                  <Tab>
                    <Transfers />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                    <Stack flexDirection={"row"} mt={2}>
                      <Skeleton
                        variant="rounded"
                        width={"80%"}
                        height={"100px"}
                      />
                    </Stack>
                  </Tab>
                )}

                {isLoaded ? (
                  <Tab>
                    <Inventory />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                    <Stack flexDirection={"row"} mt={2}>
                      <Skeleton
                        variant="rounded"
                        width={"80%"}
                        height={"100px"}
                      />
                    </Stack>
                  </Tab>
                )}

                {isLoaded ? (
                  <Tab>
                    <Offer />
                  </Tab>
                ) : (
                  <Tab>
                    <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={"162px"}
                        height={"86px"}
                      />
                    </Stack>
                  </Tab>
                )}
              </TabBody>
            </Tabs>
          </Stack>
        </Stack>
      </Stack>
      <ChangeStatusDialog
        open={statusChangeDlgInfo.open}
        onClose={() => setStatusChangeDlgInfo(false)}
        onConfirm={onConfirmChangeStatus}
      />
      <RemoveWarehouseDialog
        open={rmvDlgInfo.open}
        id={rmvDlgInfo.id}
        onClose={() => setRmvDlgInfo({ open: false })}
      />
    </>
  );
};
export default WarehouseProfilePage;
