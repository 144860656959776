import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Button } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

import { CTextInput } from "../../../../components/CustomInput";
import { useState } from "react";
import { useEffect } from "react";
const ChangeNameDialog = ({
  open = false,
  teamName = "",
  handleClose = function () {},
  handleContinue = function () {},
}) => {
  const [newName, setNewName] = useState();
  useEffect(() => {
    setNewName(teamName);
  }, [teamName]);
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} padding={5} width={"420px"}>
            <PageTitle>Edit Team Name</PageTitle>
            <CTextInput
              title="New Team Name"
              value={newName}
              onChange={(val) => {
                setNewName(val);
              }}
            />
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <Button sx={classes.buttonCancel} onClick={handleClose}>
                Discard changes
              </Button>
              <Button
                sx={classes.buttonSave}
                onClick={() => {
                  handleClose();
                  handleContinue(newName);
                }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ChangeNameDialog;
