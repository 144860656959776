import { Button, Modal, Stack, Typography } from "@mui/material";
import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

const ChangeStatusDialog = ({
  open,
  onClose,
  onConfirm,
  type = "warehouse",
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        padding={"34px 42px"}
        gap={"22px"}
        sx={{
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "520px",
        }}
      >
        <PageTitle>Change status</PageTitle>
        <Typography>
          Changing the {type} status to inactive, all the guides issued to this
          warehouse as a destination will be automatic deactivated. <br /> Are
          you sure you want to continue?
        </Typography>
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={onClose}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={onConfirm}>
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ChangeStatusDialog;
