import { Button, Modal, Stack } from "@mui/material";
import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { CAutoComplete, CTextInput } from "../../../../components/CustomInput";
import { useEffect, useState } from "react";
import { showNotification } from "../../../../utils/helpers";

const EditDepositDialog = ({
  open,
  onClose,
  onContinue,
  rawWaste = null,
  maxCapacity = null,
}) => {
  const [capacity, setCapacity] = useState(0);
  const onSave = () => {
    if (Number(maxCapacity) >= Number(capacity) || maxCapacity === null) {
      onContinue(capacity);
      onClose();
    } else {
      showNotification(
        "Your capacity is bigger than maximum capacity",
        "error"
      );
    }
  };

  useEffect(() => {
    if (rawWaste) setCapacity(rawWaste.capacity);
  }, [rawWaste]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        padding={"34px 42px"}
        gap={3}
        sx={{
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "520px",
        }}
      >
        <PageTitle>Edit Capacity</PageTitle>
        <CAutoComplete
          title="Raw waste type selection"
          placeholder={rawWaste?.name}
          required
          disabled
          fixed
        />
        <CTextInput
          type="number"
          title="Capacity"
          required
          endIcon="kg"
          value={capacity}
          placeholder="0"
          onChange={(v) => setCapacity(v)}
        />
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={onClose}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={onSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default EditDepositDialog;
