import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import AuthCode from "react-auth-code-input";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  Modal,
  Dialog,
  DialogContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import AuthenticationService from "../../../services/AuthenticationService";
import { logIn } from "../../../slices/userSlice";
import { fetchUserProfile } from "../../../actions/user";
import { showNotification, snackbarRef } from "../../../utils/helpers";
import { SUCCESS } from "../../../utils/type";
import { classes } from "../../../utils/class";
import { ERROR } from "../../../utils/constants";
import { UcoIcon, UcoSvg } from "../../../components/icons/menu_icons/icons";

const SigninPage = () => {
  const location = useLocation();
  const email_link = new URLSearchParams(location.search).get("email");
  const redirect = new URLSearchParams(location.search).get("redirect");
  const [checked, setChecked] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [email, setEmail] = useState("");
  const [authMethod, setAuthMethod] = useState("SMS");
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const authRef = useRef(null);
  const theme = useTheme();
  snackbarRef.current = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAuthCodeOnChange = (res) => {
    setAuthCode(res);
  };

  const handleResendCode = () => {
    setAuthMethod("SMS");
    authRef.current.clear();
    AuthenticationService.resend2faCode({
      email: email,
    })
      .then((res) => {
        showNotification("2FA code sent, check your email", "success");
      })
      .catch((err) => {
        showNotification("Error", "error");
      });
  };

  const handleConfirm2fa = () => {
    AuthenticationService.confirm2faSignin({
      email: email,
      code: authCode,
      remember_me: checked,
    })
      .then(() => {
        dispatch(logIn());
        dispatch(
          fetchUserProfile(() => {
            if (redirect) navigate(`${redirect}`);
            else navigate("/auth/signin-select-org");
          })
        );
      })
      .catch((err) => {
        showNotification("Wrong 2FA code, Try again", "error");
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (email_link) {
      setEmail(email_link);
    }
  }, [email_link]);

  useEffect(() => {
    if (redirect === "/settings/profile") {
      setDeleteOpen(true);
    }
  }, [redirect]);

  return (
    <Stack
      flexDirection={"row"}
      minHeight={"100vh"}
      width={"100%"}
      justifyContent={"space-between"}
    >
      <Stack
        sx={{
          display: { xs: "none", sm: "none", md: "none", lg: "block" },
        }}
        justifyContent={"space-between"}
        position={"relative"}
      >
        <Box
          position={"fixed"}
          height={"100vh"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          width={"40%"}
          zIndex={0}
          sx={{
            backgroundColor: "#C0D4E4",
          }}
        >
          <Box position={"absolute"} bottom={0} left={0} width={"100%"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                width: "100%",
              }}
              viewBox="0 0 640 490"
              fill="none"
            >
              <path
                opacity="0.1"
                d="M667.166 314.81C661.716 263.159 615.288 225.82 563.658 231.168C511.977 236.615 474.518 282.968 479.968 334.619C480.563 340.413 480.91 346.306 480.91 352.149C480.91 442.427 407.428 515.868 317.099 515.868C226.77 515.868 153.288 442.427 153.288 352.149C153.288 346.306 153.586 340.462 154.18 334.718C154.18 334.52 154.18 334.321 154.18 334.123C162.653 250.927 232.964 187.688 316.653 187.985C368.531 188.183 410.797 146.337 411.045 94.4884C411.293 42.5898 369.423 0.298362 317.495 0.00123245C268.59 -0.246375 228.257 36.8453 223.45 84.485C214.978 167.731 144.815 231.118 61.1262 230.821C13.2614 230.524 -27.9639 266.477 -33.0675 315.058C-34.3558 327.289 -35 339.818 -35 352.199C-35 546.175 122.915 704 317 704C511.085 704 669 546.175 669 352.199C669 339.769 668.356 327.19 667.018 314.859L667.166 314.81Z"
                fill="#7783A1"
              />
            </svg>
          </Box>
          <Box px={5} pt={4}>
            <UcoIcon fill="black" />
          </Box>
          <Stack px={5} flex={0.5}>
            <Stack flexDirection={"column"}>
              <UcoSvg width="100%" height="100%" fill="black" />
            </Stack>
          </Stack>
          <Stack
            pb={4}
            px={5}
            sx={{
              color: "#AFAFAF",
              fontSize: "12px",
            }}
          >
            © 2022 Uco software v2.0.23.0
          </Stack>
        </Box>
      </Stack>
      <Stack
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "60%" },
        }}
        zIndex={1}
      >
        <Stack
          style={{
            position: "relative",
            minHeight: "1px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: "2.688rem",
              right: "2.188rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
              <Typography variant="body2">
                Do not have a UCO Network Account?
              </Typography>
              <Button
                href="/auth/signup"
                variant="outlined"
                sx={classes.buttonCancel}
              >
                Sign up
              </Button>
            </Stack>
          </div>

          <div style={{ width: "100%", maxWidth: "343px" }}>
            <Typography variant="h4">Sign in</Typography>
            <Typography variant="body1" sx={{ mt: "0.5rem" }}>
              Welcome back! Log in with your email{" "}
            </Typography>
            <Formik
              initialValues={{
                email: email_link ?? "",
                password: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                password: Yup.string()
                  .max(255)
                  .required("Password is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                setLoading(true);
                const data = {
                  email: values.email,
                  password: values.password,
                  remember_me: checked,
                };
                AuthenticationService.signin(data)
                  .then(async (res) => {
                    showNotification(
                      "You have logged in with success",
                      "success"
                    );
                    setEmail(values.email);
                    try {
                      const result =
                        await AuthenticationService.checkIf2faEnabled(
                          values.email
                        );
                      const twofa_enabled = result.data.data.enabled;
                      if (twofa_enabled) handleOpen();
                      else {
                        dispatch(logIn());
                        dispatch(
                          fetchUserProfile(() => {
                            if (redirect) navigate(`${redirect}`);
                            else navigate("/auth/signin-select-org");
                          })
                        );
                      }
                    } catch (err) {}
                  })
                  .catch((err) => {
                    showNotification(
                      err.response
                        ? ERROR[err.response.data.message] || "Network Error"
                        : err.message,
                      "error"
                    );
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <Typography variant="body1" sx={{ mt: "2rem" }}>
                      Email
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <OutlinedInput
                      id="outlined-adornment-email-login"
                      type="email"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      placeholder="Insert your email"
                      inputProps={{}}
                      size="small"
                      disabled={email_link}
                      sx={{ mt: "0.5rem" }}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.email}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    {/* <InputLabel htmlFor="outlined-adornment-password-login">
                    Password
                  </InputLabel> */}
                    <Typography variant="body1" sx={{ mt: "1rem" }}>
                      Password
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <OutlinedInput
                      id="outlined-adornment-password-login"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      // label="Password"
                      placeholder="Insert your password"
                      inputProps={{}}
                      size="small"
                      sx={{ mt: "0.5rem" }}
                    />
                    {touched.password && errors.password && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.password}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Box sx={{ mt: "1.5rem" }}>
                    <LoadingButton
                      loading={loading}
                      disableElevation
                      fullWidth
                      size="medium"
                      type="submit"
                      sx={classes.buttonSave}
                    >
                      {!loading && "Sign in"}
                    </LoadingButton>
                  </Box>

                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={classes.checkBox}
                        checked={checked}
                        onChange={(event) => setChecked(event.target.checked)}
                        name="checked"
                        color="success"
                      />
                    }
                    label="Remember me"
                    sx={{ mt: "1rem" }}
                  />
                  <Box sx={{ mt: "1.5rem" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      component={Link}
                      to="/auth/forgot-password"
                    >
                      Forgot your password?
                    </Typography>
                  </Box>
                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </Stack>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="tfaModal" sx={style}>
          <Typography variant="h4">Two factor authentication</Typography>
          <Typography sx={{ mt: 2 }}>
            An {authMethod} was sent with a confirmation code. Enter the code to
            complete the authentication
          </Typography>
          <Typography variant="body2" sx={{ mt: 3, mb: 2.5 }}>
            Insert Code:
          </Typography>
          <AuthCode onChange={handleAuthCodeOnChange} ref={authRef} />
          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button variant="outlined" onClick={handleResendCode}>
              Re-send code
            </Button>
            <Button variant="contained" onClick={handleConfirm2fa}>
              Confirm
            </Button>
          </Stack>
          <Link
            style={{ display: "block", marginTop: "1.5rem" }}
            onClick={() => {
              setAuthMethod("Email");
              AuthenticationService.send2faMail({
                email: email,
              })
                .then((res) => {
                  if (res.data.message === SUCCESS)
                    showNotification(
                      "Verification code sent to email",
                      "success"
                    );
                })
                .catch((err) => {});
            }}
          >
            Send security code by email
          </Link>
          <Typography variant="body1" sx={{ mt: 1.5 }}>
            Need help? help@uco.network
          </Typography>
        </Box>
      </Modal>
      <Dialog
        open={deleteOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Stack maxWidth={"450px"} gap={2}>
            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
              <svg
                width="75"
                height="76"
                viewBox="0 0 75 76"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1277_4626)">
                  <path
                    d="M0 38C0 10.7273 10.2273 0.5 37.5 0.5C64.7727 0.5 75 10.7273 75 38C75 65.2727 64.7727 75.5 37.5 75.5C10.2273 75.5 0 65.2727 0 38Z"
                    fill="#C4D4E3"
                  />
                  <path
                    d="M62.9808 35.2959C62.5842 31.5369 59.2057 28.8195 55.4486 29.2087C51.6879 29.6052 48.962 32.9785 49.3586 36.7375C49.4019 37.1591 49.4271 37.588 49.4271 38.0133C49.4271 44.5833 44.0799 49.928 37.5067 49.928C30.9336 49.928 25.5863 44.5833 25.5863 38.0133C25.5863 37.588 25.608 37.1627 25.6513 36.7447C25.6513 36.7302 25.6513 36.7158 25.6513 36.7014C26.2678 30.6467 31.3843 26.0444 37.4743 26.0661C41.2494 26.0805 44.3251 23.0351 44.3431 19.2617C44.3611 15.4848 41.3143 12.407 37.5356 12.3853C33.9768 12.3673 31.0417 15.0667 30.692 18.5337C30.0754 24.592 24.9698 29.2051 18.8798 29.1835C15.3967 29.1619 12.3968 31.7784 12.0254 35.3139C11.9316 36.2041 11.8848 37.1159 11.8848 38.0169C11.8848 52.1337 23.3761 63.6196 37.4995 63.6196C51.623 63.6196 63.1143 52.1337 63.1143 38.0169C63.1143 37.1123 63.0674 36.1969 62.97 35.2995L62.9808 35.2959Z"
                    fill="#23222D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1277_4626">
                    <rect
                      width="75"
                      height="75"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <Stack sx={{ fontSize: "20px", fontWeight: 700 }}>
                Do you want to delete your account?
              </Stack>
            </Stack>
            You can delete your UCO Collect app account by logging in below and
            clicking “Account Delation” under your “Profile” within “Settings”
            <Button
              sx={classes.buttonDelete}
              onClick={() => {
                setDeleteOpen(false);
              }}
            >
              Continue to delete
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default SigninPage;
