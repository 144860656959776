import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// third party
import { useSnackbar } from "notistack";

// services
import AuthenticationService from "../../../../services/AuthenticationService";
import { showNotification, snackbarRef } from "../../../../utils/helpers";
import InvalidLink from "../InvalidLink/InvalidLink";
import { SUCCESS } from "../../../../utils/type";
import ProfileDialog from "./ProfileDialog";
import UserService from "../../../../services/UserService";
import { ERROR } from "../../../../utils/constants";

const EmailVerificationPage = () => {
  snackbarRef.current = useSnackbar();
  const location = useLocation();
  const email = new URLSearchParams(location.search).get("email");
  const id = new URLSearchParams(location.search).get("id");
  const navigate = useNavigate();
  const [isValidLink, setIsValidLink] = useState(null);

  useEffect(() => {
    const data = {
      id: id,
      email: email,
    };
    AuthenticationService.verifyUserEmail(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          setIsValidLink(true);
        }
      })
      .catch((err) => {
        setIsValidLink(false);
      });
  }, []);

  return (
    <>
      {isValidLink === false && (
        <InvalidLink
          resendEmail={() => {
            return AuthenticationService.resendConfirmationEmail({ email });
          }}
          emailNotification={"An email has been sent to validate"}
        />
      )}
      {isValidLink && (
        <ProfileDialog
          open={true}
          onClose={() => {}}
          onUpdate={(data) => {
            UserService.editProfile({
              ...data,
              email,
            })
              .then(() => {
                navigate("/auth/signin-select-org");
              })
              .catch((err) => {
                showNotification(
                  err.response
                    ? ERROR[err.response.data.message] || "Network Error"
                    : err.message,
                  "error"
                );
              });
          }}
        />
      )}
    </>
  );
};

export default EmailVerificationPage;
