import InventoryService from "../services/InventoryService";
import {
  setCategories,
  setCurrentInventory,
  setInventories,
  setNames,
  setOffers,
} from "../slices/inventorySlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";

export const fetchInventory =
  (isOffer = false, callBack = () => {}) =>
  (dispatch) => {
    InventoryService.fetchInventoryList(isOffer)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          const data = res.data.data.map((dat) => {
            return { id: dat._id, ...dat };
          });
          if (!isOffer) dispatch(setInventories(data));
          else dispatch(setOffers(data));
          callBack();
        }
      })
      .catch((err) => {});
  };

export const fetchInventorySubList =
  (id, callBack = () => {}) =>
  (dispatch) => {
    InventoryService.fetchInventorySubList(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setCurrentInventory(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        dispatch(setCurrentInventory([]));
        //
      });
  };

export const fetchInventoryName = (id) => (dispatch) => {
  InventoryService.fetchInventoryName(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setNames(res.data.data));
      }
    })
    .catch((err) => {});
};

export const fetchInventoryCategory = () => (dispatch) => {
  InventoryService.fetchInventoryCategory()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(
          setCategories(
            res.data.data.map((row) => {
              return { ...row, id: row._id };
            })
          )
        );
      }
    })
    .catch((err) => {});
};

export const addNewCategory =
  (data, fallback = () => {}) =>
  (dispatch) => {
    InventoryService.createCategory(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(fetchInventoryCategory());
          showNotification("New category added successfully", "success");
          fallback();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const addNewName = (data) => (dispatch) => {
  InventoryService.createName(data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchInventoryName(data.category));
        showNotification("New Name added", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const createInventory =
  (data, callBack = () => {}) =>
  (dispatch) => {
    InventoryService.createInventory(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(fetchInventory(true));
          dispatch(fetchInventory(false));
          showNotification("Created successfully", "success");
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const deactivateInventory = (id) => (dispatch) => {
  InventoryService.deactivateItem(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Deactivated successfully", "success");
        dispatch(fetchInventory());
        dispatch(fetchInventory(true));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const activateInventory = (id) => (dispatch) => {
  InventoryService.activateItem(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Dectivated successfully", "success");
        dispatch(fetchInventory());
        dispatch(fetchInventory(true));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const unarchiveInventory = (id) => (dispatch) => {
  InventoryService.unarchiveItem(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Unarchived successfully", "success");
        dispatch(fetchInventory());
        dispatch(fetchInventory(true));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const archiveInventory = (id) => (dispatch) => {
  InventoryService.archiveItem(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Archived successfully", "success");
        dispatch(fetchInventory());
        dispatch(fetchInventory(true));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const makeGeneric = (inventoryId, itemId) => (dispatch) => {
  InventoryService.makeGeneric(itemId)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchInventorySubList(inventoryId));
        showNotification("Make Generic Succeed!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const updateStockItemId =
  (id, stockId, data, callBack = () => {}) =>
  (dispatch) => {
    InventoryService.updateStockItemId(stockId, data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Item Ids Updated", "success");
          dispatch(fetchInventorySubList(id));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };
export const makeUnique =
  (id, stockId, data, callBack = () => {}) =>
  (dispatch) => {
    InventoryService.makeUnique(stockId, data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Make unique succeed!", "success");
          dispatch(fetchInventorySubList(id));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const updateInStockSubTotal = (id, stockId, data) => (dispatch) => {
  InventoryService.updateInStockSubTotal(stockId, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchInventorySubList(stockId));
        showNotification("stock detail updated successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const updateSubTotal = (id, stockId, data) => (dispatch) => {
  InventoryService.updateSubTotal(stockId, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchInventorySubList(stockId));
        showNotification("stock detail updated successfully", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const removeStock = (id, stockId) => (dispatch) => {
  InventoryService.removeStock(stockId)
    .then((res) => {
      if (res.data.message === SUCCESS)
        dispatch(fetchInventorySubList(stockId));
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const removeCategory = (id) => (dispatch) => {
  InventoryService.removeCategory(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchInventoryCategory());
        showNotification("Successfully removed!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const editCategory =
  (id, data, fallback = () => {}) =>
  (dispatch) => {
    InventoryService.editCategory(id, data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(fetchInventoryCategory());
          fallback();
          showNotification("Update successful!", "success");
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };
export const archiveCategory = (id) => (dispatch) => {
  InventoryService.archiveCategory(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchInventoryCategory());
        showNotification("Archive is successful!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const unarchiveCategory = (id) => (dispatch) => {
  InventoryService.unarchiveCategory(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchInventoryCategory());
        showNotification("Unarchive is successful!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const activateCategory = (id) => (dispatch) => {
  InventoryService.activateCategory(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchInventoryCategory());
        showNotification("Activation is successful!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const deactivateCategory = (id) => (dispatch) => {
  InventoryService.deactivateCategory(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchInventoryCategory());
        showNotification("Deactivation is successful!", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const editOffer = (id, data, fallback) => (dispatch) => {
  InventoryService.editOffer(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchInventory(true));
        showNotification("Update successful!", "success");
        fallback();
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
