import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import "./index.css";

if (process.env === "stage" || process.env === "dev") {
  Sentry.init({
    dsn: "https://0d3d394def949bf98078aaedf7bf820a@o4506734757478400.ingest.sentry.io/4506746060865536",
    environment: process.env.REACT_APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/dev\.uco\.network\.com/,
      /^https:\/\/stage\.uco\.network\.com/,
      /^https:\/\/app\.uco\.network\.com/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
