import { Button, Stack, Typography } from "@mui/material";
import { classes } from "../../../../utils/class";
import { Link } from "react-router-dom";
import { showNotification } from "../../../../utils/helpers";
import { ERROR } from "../../../../utils/constants";

const InvalidLink = ({
  resendEmail = () => {},
  emailNotification,
  noResend = false,
}) => {
  return (
    <>
      <Stack alignItems={"center"} justifyContent={"center"} mt={20}>
        <Stack textAlign={"left"} alignItems={"flex-start"}>
          <Typography variant="h3" mb={10} fontWeight={"bold"}>
            UCO.NETWORK
          </Typography>
          <Typography variant="h3" mb={1} fontWeight={"bold"}>
            The link is invalid or <br /> does not exists
          </Typography>
          <Typography>
            Something's wrong. The link is invalid or does not exists anymore.
          </Typography>
          <Typography mb={5}>
            Please resend email to validate your email address
          </Typography>
          {!resendEmail && (
            <Button
              onClick={() => {
                resendEmail()
                  .then((res) => {
                    showNotification(emailNotification, "success");
                  })
                  .catch((err) => {
                    showNotification(
                      err.response
                        ? ERROR[err.response.data.message] || "Network Error"
                        : err.message,
                      "error"
                    );
                  });
              }}
              sx={classes.buttonPrimary}
            >
              Resend Email
            </Button>
          )}
          <Typography mt={5} maxWidth={"431px"}>
            If you are having any problems feel free to contact our services
            following the email &nbsp;
            <Link
              onClick={() => {
                window.open("mailto:help@uco.network", "_blank");
              }}
              style={{
                textDecoration: "underline",
              }}
            >
              help@uco.network
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default InvalidLink;
