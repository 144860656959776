import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import { Box, Button, Stack, OutlinedInput, Typography } from "@mui/material";

// project imports
import { InputTitle, PageTitle } from "../../../components/Typography";

// service imports
import FleetService from "../../../services/FleetService";
import { SUCCESS } from "../../../utils/type";

// third party
import { useSnackbar } from "notistack";

//sx class definition
import { classes } from "../../../utils/class";
import { showNotification } from "../../../utils/helpers";
import { CTextInput } from "../../../components/CustomInput";

const AddVehiclePage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState("");
  const [regPlate, setRegPlate] = useState("");
  const navigate = useNavigate();

  const addVehicle = async () => {
    if (name.length * regPlate.length) {
      const res = await FleetService.createVehicle({
        name: name,
        reg_plate: regPlate,
      });
      if (res === SUCCESS) {
        navigate("/vehicle");
      } else {
        let msg = res.response ? res.response.data.message : res.message;
        if (msg === "duplicated_reg_plate_error")
          msg = "You have entered a duplicate registration plate number.";
        showNotification(msg, "error");
      }
    } else {
      enqueueSnackbar(
        "You must input the name and registration plate for a vehicle",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
    }
  };
  return (
    <>
      <Box sx={{ minHeight: "100vh", height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <PageTitle>Add a vehicle</PageTitle>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={addVehicle}
            >
              Add a vehicle
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "13px",
              minWidth: "422px",
              justifyContent: "center",
            }}
          >
            <CTextInput
              title="Vehicle name"
              required
              value={name}
              placeholder="Enter vehicle name"
              onChange={(v) => {
                setName(v.target.value);
              }}
            />
            <CTextInput
              title="Vehicle registration plate"
              required
              value={regPlate}
              placeholder="Ex: 23-HY-78"
              onChange={(v) => {
                setRegPlate(v.target.value);
              }}
              caps
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default AddVehiclePage;
