import React from "react";
import { useSelector } from "react-redux";

// material-ui
import { IconButton, Stack } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

// project imports
import { DataTable } from "../../../../components/Table";

// sx
import { classes } from "../../../../utils/class";

import { StateSpan } from "../../../../components/StateSpan";
import { CardPrimary } from "../../../../components/Card";

// action import
const Fleet = () => {
  const { fleet, profile } = useSelector((state) => state.warehouse.current);

  const columns = [
    {
      field: "model",
      headerName: "Model",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 100,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "reg_plate",
      headerName: "Reg Plates",
      width: 100,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "year",
      headerName: "Year",
      width: 100,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "count_raw_wastes",
      headerName: "Quantity",
      width: 100,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "driver_name",
      headerName: "Driver",
      width: 200,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      flex: 1,
      renderCell: (props) => {
        return (
          <>
            <StateSpan status={props.row.status} />
            <Stack direction="row">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <CloseOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <DoneOutlinedIcon />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
  ];

  return (
    <Stack>
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        <CardPrimary
          type={2}
          title={"Nº Wastes"}
          content={profile.count_of_deposits}
        />
        <CardPrimary
          type={2}
          title={"Nº Vehicles"}
          content={profile.count_of_vehicles}
        />
        <CardPrimary
          type={2}
          title={"Nº Collection Points"}
          content={profile.count_of_pickup_points}
        />
      </Stack>

      <DataTable
        fit="100%"
        breakpoint="xl"
        rows={fleet}
        columns={columns}
        sx={classes.grid}
        onRowClick={(params, events, details) => {}}
      />
    </Stack>
  );
};

export default Fleet;
