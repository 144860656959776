import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchWTNDetails } from "../../../actions/transfer";
import { Box, Card, Stack, Typography } from "@mui/material";
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";

const WTNPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [pdfInfo, setPDFInfo] = useState(null);

  const wtnDetails = useSelector(state => state.transfer.wtn_details);
  const { detail } = useSelector(state => state.visits.selected);

  useEffect(() => {
    dispatch(fetchWTNDetails(id));
  }, [id]);

  useEffect(() => {
    if(!wtnDetails) return;

    const core = async () => {
      const pdfDoc = await PDFDocument.create()
      pdfDoc.registerFontkit(fontkit);
    
      const url = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf'
      const fontBytes = await fetch(url).then((res) => res.arrayBuffer())
      const ManRopeBoldFont = await pdfDoc.embedFont(fontBytes)

      const pngImageBytes = await fetch(`${window.location.protocol}//${window.location.host}/pdf_bg.png`).then(res => res.arrayBuffer());
      const pngImage = await pdfDoc.embedPng(pngImageBytes);
      const pngDims = pngImage.scale(1);
      const rectImageBytes = await fetch(`${window.location.protocol}//${window.location.host}/logo.png`).then(res => res.arrayBuffer());
      const rectImage = await pdfDoc.embedPng(rectImageBytes);
      const rectDims = rectImage.scale(1);

      const page = pdfDoc.addPage()
      const { width, height } = page.getSize()
      
      page.drawImage(pngImage, {
        x: width / 2 - pngDims.width / 4,
        y: height / 4 - pngDims.height / 2,
        width: pngDims.width,
        height: pngDims.height
      });
      const rectHeight = rectDims.height * (width - 20) / rectDims.width;
      page.drawImage(rectImage, {
        x: 10,
        y: height - rectHeight - 10,
        width: width - 20,
        height: rectHeight
      });

      page.drawText('Current holder of the waste - Transferor', { x: 50, y: height - 150, size: 14, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Company Number', { x: 70, y: height - 168, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Company name', { x: 70, y: height - 186, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Organization name / ID', { x: 70, y: height - 204, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Address', { x: 70, y: height - 222, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Region', { x: 70, y: height - 240, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Postal Code', { x: 70, y: height - 258, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Council', { x: 70, y: height - 276, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Validation Note', { x: 70, y: height - 294, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.origin_number || "-", { x: 250, y: height - 168, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.origin_name || "-", { x: 250, y: height - 186, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.origin_id || "-", { x: 250, y: height - 204, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.origin_address || "-", { x: 250, y: height - 222, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.origin_region || "-", { x: 250, y: height - 240, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.origin_postal_code || "-", { x: 250, y: height - 258, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.origin_council || "-", { x: 250, y: height - 276, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.origin_validation_note || "-", { x: 250, y: height - 294, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });

      page.drawText('Description of the waste', { x: 50, y: height - 330, size: 14, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Waste Code Name', { x: 70, y: height - 366, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Quantity', { x: 70, y: height - 384, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Waste Code (LER)', { x: 70, y: height - 402, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Operation Code', { x: 70, y: height - 420, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Original Data', { x: 250, y: height - 348, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Final Data', { x: 400, y: height - 348, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(`${wtnDetails.origin_waste_name.slice(0, 20)}${wtnDetails.origin_waste_name.length > 20 && '...'}`, { x: 250, y: height - 366, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(`${wtnDetails.origin_waste_quantity || "-"}`, { x: 250, y: height - 384, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.origin_waste_code || "-", { x: 250, y: height - 402, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.origin_waste_operation_code || "-", { x: 250, y: height - 420, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(`${wtnDetails.final_waste_name.slice(0, 20)}${wtnDetails.final_waste_name.length > 20 && '...'}`, { x: 400, y: height - 366, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(`${wtnDetails.final_waste_quantity || "-"}`, { x: 400, y: height - 384, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.final_waste_code || "-", { x: 400, y: height - 402, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.final_waste_operation_code || "-", { x: 400, y: height - 420, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      
      page.drawText('Transporter of the Waste - Transfer', { x: 50, y: height - 456, size: 14, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Number of Vehicles', { x: 70, y: height - 474, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Driver Name', { x: 70, y: height - 492, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Transport Company Name', { x: 70, y: height - 510, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Transport Company ID', { x: 70, y: height - 528, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Plate Number', { x: 70, y: height - 546, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Date of Transport', { x: 70, y: height - 564, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Time of Transport', { x: 70, y: height - 582, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Expiration', { x: 70, y: height - 600, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('1', { x: 250, y: height - 474, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.driver_name || "-", { x: 250, y: height - 492, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.transport_company_name || "-", { x: 250, y: height - 510, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.transport_company_id || "-", { x: 250, y: height - 528, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.plate_number || "-", { x: 250, y: height - 546, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.date_of_transport || "-", { x: 250, y: height - 564, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.time_of_transport || "-", { x: 250, y: height - 582, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.expiration || "-", { x: 250, y: height - 600, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });

      page.drawText('Receiver of the Waste - Transfer', { x: 50, y: height - 636, size: 14, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Company number', { x: 70, y: height - 654, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Company Name', { x: 70, y: height - 672, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Organization name / ID', { x: 70, y: height - 690, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Address', { x: 70, y: height - 708, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Region', { x: 70, y: height - 726, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Postal Code', { x: 70, y: height - 744, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Council', { x: 70, y: height - 762, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText('Validation Note', { x: 70, y: height - 780, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.receiver_number || "-", { x: 250, y: height - 654, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.receiver_company_name || "-", { x: 250, y: height - 672, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.receiver_id || "-", { x: 250, y: height - 690, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.receiver_address || "-", { x: 250, y: height - 708, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.receiver_region || "-", { x: 250, y: height - 726, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.receiver_postal_code || "-", { x: 250, y: height - 744, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.receiver_council || "-", { x: 250, y: height - 762, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });
      page.drawText(wtnDetails.receiver_validation_note || "-", { x: 250, y: height - 780, size: 12, font: ManRopeBoldFont, color: rgb(0, 0, 0), });

      page.drawText('Waste Transfer Note', { x: 30, y: height - 95, size: 25, font: ManRopeBoldFont, color: rgb(1, 1, 1), });
      page.drawText(`Waste Transfer NO. ${detail.waybill_number || "-"}`, { x: 30, y: height - 115, size: 15, font: ManRopeBoldFont, color: rgb(0.9, 0.9, 0.9), });
      const date = new Date(wtnDetails.date);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;

      page.drawText(`Date ${formattedDate}`, { x: width / 2, y: height - 40, size: 15, font: ManRopeBoldFont, color: rgb(1, 1, 1), })

      const pdfBytes = await pdfDoc.save()
      
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPDFInfo(pdfUrl);
    };

    core();

  }, [wtnDetails]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "23px",
        gap: "8px",
        padding: "20px",
      }}
    >
      <iframe height={800} width="100%" src={pdfInfo} type="application/pdf" />
    </Box>
  );
}

export default WTNPage;