import React from "react";
import {
  Box,
  TextField,
  Autocomplete,
  Stack,
  InputAdornment,
} from "@mui/material";
import { COUNTRIES_INFO } from "../utils/constants";
import { classes } from "../utils/class";

const CountrySelect = ({
  label = "Choose a country",
  showLabel = true,
  showNumber = true,
  value = null,
  onChange,
  required = false,
  disabled = false,
  error = false,
  ...props
}) => {
  return (
    <Autocomplete
      id="country-select-demo"
      sx={[
        { width: showLabel ? 300 : 150 },
        props.sx,
        {
          background: "white",
          paddingY: "0px !important",
        },
        {
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            paddingY: "0px !important",
            "&.Mui-disabled": {
              backgroundColor: "#EEECE780",
              "& input": {
                WebkitTextFillColor: "#23222D",
              },
            },
          },
        },
        classes.textInput,
      ]}
      ListboxProps={{
        sx: {
          "& li": {
            "&:hover": {
              backgroundColor: "#EBF15566 !important",
              color: "",
            },
          },
        },
      }}
      options={COUNTRIES_INFO}
      onChange={onChange}
      value={value}
      autoHighlight
      disabled={disabled}
      getOptionLabel={(option) => (showLabel ? option.label : option.code)}
      disableClearable={true}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{
            "& > img": { mr: 2, flexShrink: 0 },
          }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {showLabel
            ? `${option.label} ${
                showNumber ? `${option.code} +${option.phone}` : ""
              }`
            : `${option.code}`}
        </Box>
      )}
      renderInput={(params) => {
        let val = params.inputProps.value;
        if (showLabel)
          val = COUNTRIES_INFO.find((info) => info.label === val)?.code;
        return (
          <TextField
            {...params}
            label={label}
            error={error}
            InputProps={{
              ...params.InputProps,
              startAdornment: val && (
                <InputAdornment position="start">
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${val.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${val.toLowerCase()}.png 2x`}
                    alt=""
                  />
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default CountrySelect;
