import { auth_axios } from "../utils/axios";

const fetchProfile = () => {
  return auth_axios.get("/users/get-profile");
};
const fetchRole = () => {
  return auth_axios.get("/users/get-user-role");
};
const fetchProProfile = () => {
  return auth_axios.get("/users/get-professional-profile");
};
const editIdentification = (data) => {
  return auth_axios.put("/users/edit-identification", data);
};
const editIdentificationById = (id, data) => {
  return auth_axios.put(`/users/edit-identification-by-id/${id}`, data);
};
const editContactsById = (id, data) => {
  return auth_axios.put(`/users/edit-contacts-by-id/${id}`, data);
};
const editContacts = (data) => {
  return auth_axios.put("/users/edit-contacts", data);
};
const editProfile = (data) => {
  return auth_axios.put("/auth/edit-profile", data);
};
const editProProfileName = (data) => {
  return auth_axios.put("/users/edit-professional-profile-name", data);
};
const editProProfileContacts = (data) => {
  return auth_axios.put("/users/edit-professional-profile-contacts", data);
};
const resetUserPassword = () => {
  return auth_axios.put("/users/change-password", { password: "ABCabc123!@#" });
};
const enable2FA = (data) => {
  return auth_axios.put("/2fa/enable", data);
};
const disable2FA = () => {
  return auth_axios.put("/2fa/disable");
};
const fetchSecurityInfo = () => {
  return auth_axios.get("/users/get-security-info");
};
const getLocationInfo = () => {
  return auth_axios.get("/users/get-location-info");
};

const deleteAccount = () => {
  return auth_axios.delete("/users/delete-account");
};

const UserService = {
  deleteAccount,
  fetchProfile,
  fetchRole,
  editIdentification,
  editContacts,
  editIdentificationById,
  editContactsById,
  editProfile,
  fetchProProfile,
  editProProfileName,
  editProProfileContacts,
  resetUserPassword,
  enable2FA,
  disable2FA,
  fetchSecurityInfo,
  getLocationInfo,
};

export default UserService;
