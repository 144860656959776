import { warehouse_axios } from "../utils/axios";

const getWarehouseList = async () => {
  return warehouse_axios.get("list-warehouses");
};

const getTransferList = (vehicleId) => {
  return warehouse_axios.get(`list-transfers/${vehicleId}`);
};

const createWarehouse = (data) => {
  return warehouse_axios.post("/create-warehouse", data);
};

const createWarehouseWizard = (data) => {
  return warehouse_axios.post("/create-warehouse-setup-wizard", data);
};
const fetchWarehouseDetail = (id) => {
  return warehouse_axios.get(`/get-warehouse-details/${id}`);
};

const fetchWarehousePickupPoints = (id) => {
  return warehouse_axios.get(`/list-pickup-points/${id}`);
};

const fetchWarehouseTransfers = (id) => {
  return warehouse_axios.get(`/list-transfers/${id}`);
};

const fetchWarehouseFleet = (id) => {
  return warehouse_axios.get(`/list-vehicles/${id}`);
};

const updateWarehouseInfo = (id, data) => {
  return warehouse_axios.put(`/edit-warehouse/${id}`, data);
};

const fetchWarehouseDeposits = (id) => {
  return warehouse_axios.get(`/list-deposits/${id}`);
};

const fetchWarehouseVisits = (id) => {
  return warehouse_axios.get(`/list-visits/${id}`);
};

const deleteWarehouse = (id) => {
  return warehouse_axios.delete(`/remove-warehouse/${id}`);
};

const activateWarehouse = (id) => {
  return warehouse_axios.put(`/activate-warehouse/${id}`);
};

const deactivateWarehouse = (id) => {
  return warehouse_axios.put(`/deactivate-warehouse/${id}`);
};

const archiveWarehouse = (id) => {
  return warehouse_axios.put(`/archive-warehouse/${id}`);
};

const unarchiveWarehouse = (id) => {
  return warehouse_axios.put(`/unarchive-warehouse/${id}`);
};

const addDeposit = (id, data) => {
  return warehouse_axios.post(`/add-deposit/${id}`, data);
};

const editDeposit = (id, data) => {
  return warehouse_axios.put(`/edit-deposit/${id}`, data);
};

const deleteDeposit = (id) => {
  return warehouse_axios.delete(`/remove-deposit/${id}`);
};

const activateDeposit = (id) => {
  return warehouse_axios.put(`/activate-deposit/${id}`);
};

const deactivateDeposit = (id) => {
  return warehouse_axios.put(`/deactivate-deposit/${id}`);
};

const archiveDeposit = (id) => {
  return warehouse_axios.put(`/archive-deposit/${id}`);
};

const unarchiveDeposit = (id) => {
  return warehouse_axios.put(`/unarchive-deposit/${id}`);
};

const fetchInventoryListByWarehouse = (isOffer, wid) => {
  return warehouse_axios.get(`/list-inventories/${isOffer}/${wid}`);
};
const WarehouseService = {
  getWarehouseList,
  fetchInventoryListByWarehouse,
  getTransferList,
  createWarehouse,
  fetchWarehouseDetail,
  fetchWarehousePickupPoints,
  fetchWarehouseTransfers,
  fetchWarehouseFleet,
  updateWarehouseInfo,
  fetchWarehouseDeposits,
  deleteWarehouse,
  activateWarehouse,
  deactivateWarehouse,
  archiveWarehouse,
  unarchiveWarehouse,
  addDeposit,
  editDeposit,
  deleteDeposit,
  activateDeposit,
  deactivateDeposit,
  archiveDeposit,
  unarchiveDeposit,
  createWarehouseWizard,
  fetchWarehouseVisits,
};
export default WarehouseService;
