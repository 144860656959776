import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";

import { CardPrimary } from "../../../../components/Card";
import { DataTable } from "../../../../components/Table";
import { CustomGraph } from "../../../../components/Graph";
import { StateSpan } from "../../../../components/StateSpan";
import DateDialog from "../../visits/DateDialog";
import AssignVehicleDialog from "../Dialog/AssignVehicleDialog";
import {
  checkVisit,
  fetchScheduledVisitHistory,
  fetchVisitHistory,
  fetchZoneScheduledVisits,
  fetchZoneVisits,
  rescheduleZoneVisit,
} from "../../../../actions/zone";
import {
  PURPOSE_TYPES,
  getDateStringFromNum,
  getServerTimestamp,
} from "../../../../utils/helpers";
import { strToDate } from "../../../../utils/date";
import { classes } from "../../../../utils/class";
import { setSelectedVisitId } from "../../../../slices/visitsSlice";
import { setCurrentScheduled } from "../../../../slices/visitsSlice";

const VisitPage = ({ extraInfo, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dateAnchor, setDateAnchor] = useState(null);
  const [vehicleAnchor, setVehicleAnchor] = useState(null);
  const [dateDialog, setDateDialog] = useState(false);
  const [vehicleDialog, setVehicleDialog] = useState(false);
  const [showScheduledVisits, setShowScheduledVisits] = useState(false);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [current, setCurrent] = useState(null);
  const onClickDate = (date) => {
    dispatch(
      rescheduleZoneVisit(id, current._id, {
        date: getServerTimestamp(
          strToDate(`${date.$y}-${date.$M + 1}-${date.$D}`).getTime(),
          orgData.timezone
        ),
      })
    );
  };

  const columns_visit = [
    {
      width: 150,
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (props) => {
        return (
          <Typography sx={{ fontWeight: 700 }}>
            {getDateStringFromNum(
              new Date(props.value).getTime(),
              orgData.timezone
            )}
          </Typography>
        );
      },
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{PURPOSE_TYPES[props.row.purpose]}</span>
      ),
    },
    {
      width: 150,
      flex: 1,
      field: "pickup_point_name",
      headerName: "Collection Point",
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "transfer",
      headerName: "Transfer",
      width: 300,
      flex: 2,
      renderCell: (props) => {
        if (!props.row.quantity) return "";
        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: "60px" }}>
              {props.row.quantity} kg
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "member_name",
      headerName: "Member",
      width: 200,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      width: 100,
      flex: 1,
      field: "notes",
      headerName: "Notes ",
      renderCell: (props) => {
        return (
          <>
            {props.value !== "" && (
              <Tooltip
                slotProps={{
                  tooltip: {
                    sx: {
                      background: "black",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "black",
                    },
                  },
                }}
                title={props.row.notes}
                placement="top"
              >
                <CommentOutlinedIcon />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];
  const columns_scheduled = [
    {
      field: "status",
      headerName: "Status",
      width: 150,
      flex: 1,
      renderCell: (props) => {
        return <StateSpan status={props.row.status} />;
      },
    },
    {
      width: 150,
      field: "date",
      flex: 1,
      headerName: "Date",
      renderCell: (props) => {
        return (
          <Typography sx={{ fontWeight: 500 }}>
            {getDateStringFromNum(
              new Date(props.value).getTime(),
              orgData.timezone
            )}
          </Typography>
        );
      },
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 150,
      flex: 1,
      renderCell: (props) => <span>{PURPOSE_TYPES[props.row.purpose]}</span>,
    },
    {
      width: 150,
      field: "pickup_point_name",
      headerName: "Collection Point",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "transfer",
      headerName: "Expected Transfer",
      width: 300,
      flex: 2,
      renderCell: (props) => {
        if (!props.row.quantity) return "";
        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: "80px" }}>
              {props.row.quantity} kg
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
    {
      width: 150,
      field: "zones",
      headerName: "Zone",
      flex: 1,
      renderCell: (props) => {
        return (
          <Typography sx={{ fontWeight: 500 }} className="mui-ellipsis">
            {props.value.map((z, index) => {
              return <div key={index}>{z.name}</div>;
            })}
          </Typography>
        );
      },
    },
    {
      width: 100,
      flex: 1,
      field: "notes",
      headerName: "Notes ",
      renderCell: (props) => {
        return (
          <>
            {props.value !== "" && (
              <Tooltip
                slotProps={{
                  tooltip: {
                    sx: {
                      background: "black",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "black",
                    },
                  },
                }}
                title={props.row.notes}
                placement="top"
              >
                <CommentOutlinedIcon />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      width: 150,
      flex: 1,
      field: "actions",
      headerName: "Actions ",
      renderCell: (props) => {
        return (
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <IconButton
              disabled={props.row.status !== "suggestion"}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(checkVisit(id, props.row.id, { status: "planned" }));
              }}
            >
              <CheckOutlinedIcon />
            </IconButton>
            <IconButton
              disabled={props.row.status !== "overdue"}
              onClick={(e) => {
                e.stopPropagation();
                setCurrent(props.row);
                setDateDialog(true);
                setDateAnchor(e.currentTarget);
              }}
            >
              <DateRangeIcon />
            </IconButton>

            {/* <EditDetail
              onStateChangeHandler={(state) => {
                if (state === DELETE_ROW) {
                  setDeleteSchedule(true);
                }
              }}
              options={[
                { label: "Edit", value: EDIT_ROW },
                { label: "Edit waste pickup date", value: EDIT_STOCK },
                { label: "Delete ", value: DELETE_ROW },
              ]}
            /> */}
          </Stack>
        );
      },
    },
  ];
  const { visitList, scheduledVisitList, visitHistory, scheduledVisitHistory } =
    useSelector((state) => state.zone);
  const orgData = useSelector((state) => state.organizations.profile);
  const [graph_data, setGraphData] = useState([]);
  const [pickup_per_day, setPickupPerDay] = useState(0);
  const [expected_waste_perday, setExpectedWastePerDay] = useState(0);
  const [max_pickup, setMaxPickup] = useState(0);

  const [isLoaded, setIsLoaded] = useState(0);
  useEffect(() => {
    if (graph_data.length > 0) {
      let waste_sum = 0;
      let max_pick = 0;
      let pick_sum = 0;
      for (let d of graph_data) {
        waste_sum += d.estimatedAmount;
        pick_sum += d.pickedPoints;
        if (max_pick > d.pickedPoints) max_pick = d.pickedPoints;
      }
      setPickupPerDay(pick_sum / graph_data.length);
      setExpectedWastePerDay(waste_sum / graph_data.length);
      setMaxPickup(max_pick);
    }
  }, [graph_data]);
  useEffect(() => {
    if (id) {
      setIsLoaded(0);
      dispatch(fetchZoneVisits(id));
      dispatch(fetchZoneScheduledVisits(id));
      dispatch(
        fetchVisitHistory(id, () => {
          setIsLoaded((loaded) => loaded + 1);
        })
      );
      dispatch(
        fetchScheduledVisitHistory(id, () => {
          setIsLoaded((loaded) => loaded + 1);
        })
      );
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (showScheduledVisits) {
      setCols(columns_scheduled);
    } else {
      setCols(columns_visit);
    }
  }, [showScheduledVisits]);

  useEffect(() => {
    if (showScheduledVisits) {
      setRows(
        scheduledVisitList.map((visit, index) => {
          return {
            ...visit,
            id: visit._id,
          };
        })
      );
    } else {
      setRows(
        visitList.map((visit, index) => {
          return {
            ...visit,
            id: visit._id,
          };
        })
      );
    }
  }, [visitList, scheduledVisitList, showScheduledVisits]);

  useEffect(() => {
    if (showScheduledVisits) {
      setGraphData(
        scheduledVisitHistory.map((history, index) => {
          return {
            label: history.date,
            estimatedAmount: history.suggested_amount,
            realAmount: history.planned_amount || 0,
            pickedPoints: history.pickup_points,
          };
        })
      );
    } else {
      setGraphData(
        visitHistory.map((history, index) => {
          return {
            label: history.date,
            estimatedAmount: history.collected_amount,
            realAmount: history.collected_amount,
            pickedPoints: history.visited_pickup_points,
          };
        })
      );
    }
  }, [showScheduledVisits, visitHistory, scheduledVisitHistory]);

  return (
    <Stack gap={2}>
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        <CardPrimary
          type={1}
          title={"Nº Producers"}
          content={extraInfo?.count_of_producers}
        />
        <CardPrimary
          type={2}
          title={"Nº Collection Points"}
          content={extraInfo?.count_of_pickup_points}
        />
        <CardPrimary
          type={3}
          title={"Total collected"}
          content={extraInfo?.total_collected}
        />
        {graph_data.length > 0 && (
          <Stack gap={1} justifyContent={"flex-end"} flexDirection={"row"}>
            {isLoaded === 2 ? (
              <CustomGraph
                data={graph_data}
                height={80}
                expected_waste_perday={expected_waste_perday}
                pickup_per_day={pickup_per_day}
                max_pickup={max_pickup}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"86px"} />
            )}
            <Tooltip
              slotProps={{
                tooltip: {
                  sx: {
                    background: "black",
                  },
                },
                arrow: {
                  sx: {
                    color: "black",
                  },
                },
              }}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              title={
                <>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <BarChartOutlinedIcon />
                    Waste Collect
                  </Stack>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <TimelineOutlinedIcon /> Collection Points
                  </Stack>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <HorizontalRuleOutlinedIcon />
                    Min. expected waste per day:{expected_waste_perday}kg
                  </Stack>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="2"
                      viewBox="0 0 15 2"
                      fill="none"
                    >
                      <path
                        d="M0 1H15"
                        stroke="white"
                        strokeWidth="2"
                        stroke-dasharray="3 3"
                      />
                    </svg>
                    Max. collection pts. per day:{pickup_per_day}
                  </Stack>
                </>
              }
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </Stack>
        )}
      </Stack>
      <Stack direction="row">
        <Button
          variant="outlined"
          sx={[
            showScheduledVisits ? classes.buttonCancel : classes.buttonSave,
            {
              borderRadius: 0,
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
            },
          ]}
          onClick={() => setShowScheduledVisits(false)}
        >
          Visits
        </Button>
        <Button
          variant="outlined"
          sx={[
            showScheduledVisits ? classes.buttonSave : classes.buttonCancel,
            {
              borderRadius: 0,
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
            },
          ]}
          onClick={() => setShowScheduledVisits(true)}
        >
          Scheduled visits
        </Button>
      </Stack>
      <Stack>
        <DataTable
          rows={rows}
          columns={cols}
          onRowClick={(e) => {
            if (!showScheduledVisits) {
              dispatch(setSelectedVisitId(e.row.id));
              navigate("/visits-detail");
            } else {
              dispatch(setCurrentScheduled(e.row));
              navigate("/edit-scheduled-visit");
            }
          }}
        />
      </Stack>
      <DateDialog
        defaultValue={current?.date}
        open={dateDialog}
        handleClose={() => {
          setDateDialog(false);
        }}
        anchorEl={dateAnchor}
        onClickDate={onClickDate}
      />
      <AssignVehicleDialog
        open={vehicleDialog}
        handleClose={() => {
          setVehicleDialog(false);
        }}
        anchorEl={vehicleAnchor}
      />
    </Stack>
  );
};

export default VisitPage;
