import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  organizations: [],
  rawWastes: [],
  availableRawWastes: [],
  users: [],
  disposal_reasons: [],
  curRawWaste: {},
  invites: [],
  visitSettings: {},
  profile: {
    type: "wmo",
    company_name: "The Big Waste Company",
    country: "PT",
    vat_number: "123123",
    red_2_identification: false,
    raw_wastes: ["waste1"],
    official_id: "123854",
    latitude: 42.96296210929664,
    longitude: 12.703508127383216,
    email: "ogrporatugal@email.com",
    mobile_phone: "(44)7771222212",
    phone: "(224)77712",
    red_2_password:
      "$2a$10$EkO2Pnrs5G2zDqy9yWEKqurJTV/ZrtxQ56maOGHm6oWJ72pd1B7h.",
    red_2_username: "zhang123",
    addr_apartment: "Provincia di Perugia",
    addr_city: "Umbria",
    addr_country: "Italy",
    addr_postcode: "06034",
    addr_street: "Foligno",
    vat_number_country: "PT",
    official_id_country: "PT",
    profile_url:
      "https://uco-asset-files.s3.eu-central-1.amazonaws.com/64a43fca159ba627f31c30ad/770b3fac-82b9-4da1-b087-e1217598e74f.png",
    id: "39214",
    isLoaded: false,
  },
  dashboard: {
    percentage_pickup_points_per_zone: [],
    producer_history: [],
    pickup_point_history: [],
    collected_expected_wastes_history: [],
    total_visit_history: [],
    scheduled_visits_in_3_days: [],
    total_waste_transfer: [],
  },
};

export const orgSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setInvites: (state, action) => {
      state.invites = action.payload;
    },
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    setRawWastes: (state, action) => {
      state.rawWastes = action.payload;
    },
    setAvailableRawWastes: (state, action) => {
      state.availableRawWastes = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setDisposalReasons: (state, action) => {
      state.disposal_reasons = action.payload;
    },
    setOrganizationProfile: (state, action) => {
      state.profile = {
        ...action.payload,
        isLoaded: true,
      };
    },
    setCurRawWaste: (state, action) => {
      state.curRawWaste = action.payload;
    },
    setDashboardInfo: (state, action) => {
      state.dashboard = action.payload;
    },
    setVisitSettings: (state, action) => {
      state.visitSettings = action.payload;
    },
  },
});

export const {
  setOrganizations,
  setRawWastes,
  setUsers,
  setDisposalReasons,
  setOrganizationProfile,
  setCurRawWaste,
  setAvailableRawWastes,
  setInvites,
  setDashboardInfo,
  setVisitSettings,
} = orgSlice.actions;

export default orgSlice.reducer;
