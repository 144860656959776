import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// recaptcha

// material-ui
import {
  Typography,
  Button,
  Stack,
  Checkbox,
  Grid,
  FormControl,
  FormControlLabel,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  // InputLabel,
  Dialog,
  DialogContent,
  IconButton,
  Box,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

// project imports
import {
  strengthColor,
  strengthIndicator,
} from "../../../utils/password-strength";

// services
import AuthenticationService from "../../../services/AuthenticationService";

// assets
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { classes } from "../../../utils/class";
import { UcoIcon, UcoSvg } from "../../../components/icons/menu_icons/icons";
import ToughAccept from "../../../components/terms/ToughAccept";
import { CDivider } from "../../../components/CustomInput";
import FileService from "../../../services/FileService";
import { load } from "recaptcha-v3";

const SignupPage = () => {
  const location = useLocation();
  const email_link = new URLSearchParams(location.search).get("email");
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState();
  const [termsStep, setTermsStep] = useState(0);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [alertTerms, setAlertTerms] = useState(false);

  const [policy, setPolicy] = useState([]);
  const [recaptcha_response, setRecaptchaResponse] = useState(null);

  const getCaptchaToken = async () => {
    const recaptcha = await load(process.env.REACT_APP_CAPTCHA);
    const token = await recaptcha.execute("submit");
    setRecaptchaResponse(token);
  };
  useEffect(() => {
    getCaptchaToken();
    const accepted = localStorage.getItem("termsAccepted");
    if (accepted === true) {
      setChecked(true);
      setTermsAccepted(true);
    } else {
      FileService.retrievePolicy().then((res) => {
        console.log(res.data.data);
        setPolicy(res.data.data);
      });
    }
  }, []);
  const getStatusOfTerms = (currentStep) => {
    if (termsStep === currentStep) return "checking";
    if (termsStep < currentStep) return "tocheck";
    else return "checked";
  };

  const nextStep = () => {
    if (termsStep === 2) {
      setTermsAccepted(true);
      localStorage.setItem("termsAccepted", true);
    }
    setTermsStep((prev) => prev + 1);
  };
  const deciline = () => {
    setAlertTerms(true);
  };
  return (
    <Stack
      flexDirection={"row"}
      minHeight={"100vh"}
      width={"100%"}
      justifyContent={"space-between"}
    >
      <Stack
        sx={{
          display: { xs: "none", sm: "none", md: "none", lg: "block" },
        }}
        justifyContent={"space-between"}
        position={"relative"}
      >
        <Box
          position={"fixed"}
          height={"100vh"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          width={"40%"}
          zIndex={0}
          sx={{
            backgroundColor: "#C0D4E4",
          }}
        >
          <Box position={"absolute"} bottom={0} left={0} width={"100%"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                width: "100%",
              }}
              viewBox="0 0 640 490"
              fill="none"
            >
              <path
                opacity="0.1"
                d="M667.166 314.81C661.716 263.159 615.288 225.82 563.658 231.168C511.977 236.615 474.518 282.968 479.968 334.619C480.563 340.413 480.91 346.306 480.91 352.149C480.91 442.427 407.428 515.868 317.099 515.868C226.77 515.868 153.288 442.427 153.288 352.149C153.288 346.306 153.586 340.462 154.18 334.718C154.18 334.52 154.18 334.321 154.18 334.123C162.653 250.927 232.964 187.688 316.653 187.985C368.531 188.183 410.797 146.337 411.045 94.4884C411.293 42.5898 369.423 0.298362 317.495 0.00123245C268.59 -0.246375 228.257 36.8453 223.45 84.485C214.978 167.731 144.815 231.118 61.1262 230.821C13.2614 230.524 -27.9639 266.477 -33.0675 315.058C-34.3558 327.289 -35 339.818 -35 352.199C-35 546.175 122.915 704 317 704C511.085 704 669 546.175 669 352.199C669 339.769 668.356 327.19 667.018 314.859L667.166 314.81Z"
                fill="#7783A1"
              />
            </svg>
          </Box>
          <Box px={5} pt={4}>
            <UcoIcon fill="black" />
          </Box>
          <Stack px={5} flex={0.5}>
            <Stack flexDirection={"column"}>
              <UcoSvg width="100%" height="100%" fill="black" />
            </Stack>
          </Stack>
          <Stack
            pb={4}
            px={5}
            sx={{
              color: "#AFAFAF",
              fontSize: "12px",
            }}
          >
            © 2022 Uco software v2.0.23.0
          </Stack>
        </Box>
      </Stack>
      <Stack
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "60%" },
        }}
        zIndex={1}
      >
        <Stack
          style={{
            position: "relative",
            minHeight: "1px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: "2.688rem",
              right: "2.188rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
              <Typography variant="body2">
                Already have a UCO Network account?
              </Typography>
              <Button
                href="/auth/signin"
                variant="outlined"
                sx={classes.buttonCancel}
              >
                Login
              </Button>
            </Stack>
          </div>

          {
            <div
              style={{
                width: "100%",
                maxWidth: "343px",
                display:
                  !checked || (checked && termsAccepted) ? "initial" : "none",
              }}
            >
              <Typography variant="h4">Sign up</Typography>
              <Formik
                initialValues={{
                  email: email_link,
                  password: "",
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                  password: Yup.string()
                    .max(255)
                    .required("Password is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  if (!termsAccepted || !checked) {
                    enqueueSnackbar(
                      "You have to accept terms and conditions to sign up",
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                      }
                    );
                  } else {
                    const data = {
                      email: values.email,
                      password: values.password,
                    };

                    AuthenticationService.signup({
                      ...data,
                      recapcha_response: recaptcha_response,
                    })
                      .then((res) => {
                        enqueueSnackbar("An email has been sent to validate.", {
                          variant: "success",
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        });
                        AuthenticationService.signin(data).then((res) => {
                          navigate("/auth/signin-select-org");
                        });
                      })
                      .catch((err) => {
                        switch (err?.response?.data?.message) {
                          case "user_already_exists_error":
                            enqueueSnackbar(
                              "This user already exists. Please sign in.",
                              {
                                variant: "error",
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                              }
                            );
                            break;

                          case "email_sent_failed":
                            enqueueSnackbar(
                              "An error occurred while sending email. Please try again.",
                              {
                                variant: "error",
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                              }
                            );
                            break;
                          case "password_length_error":
                            enqueueSnackbar("Password length is short", {
                              variant: "error",
                              anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                            });
                            break;
                          default:
                            enqueueSnackbar(
                              "Something went wrong. Please try again.",
                              {
                                variant: "error",
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                              }
                            );
                            break;
                        }
                      });
                    // try {
                    //   if (scriptedRef.current) {
                    //     setStatus({ success: true });
                    //     setSubmitting(false);
                    //   }
                    // } catch (err) {
                    //   console.error(err);
                    //   if (scriptedRef.current) {
                    //     setStatus({ success: false });
                    //     setErrors({ submit: err.message });
                    //     setSubmitting(false);
                    //   }
                    // }
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                    >
                      {/* <InputLabel htmlFor="outlined-adornment-email-register">
                    Email Address / Username
                  </InputLabel> */}
                      <Typography variant="body1" sx={{ mt: "2rem" }}>
                        Email
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <OutlinedInput
                        id="outlined-adornment-email-register"
                        type="email"
                        value={values.email}
                        name="email"
                        placeholder="Insert your email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                        size="small"
                        sx={{ mt: "0.5rem" }}
                        disabled={email_link}
                      />
                      {touched.email && errors.email && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text--register"
                        >
                          {errors.email}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                    >
                      {/* <InputLabel htmlFor="outlined-adornment-password-register">
                    Password
                  </InputLabel> */}
                      <Typography variant="body1" sx={{ mt: "1rem" }}>
                        Password
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <OutlinedInput
                        id="outlined-adornment-password-register"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        name="password"
                        // label="Password"
                        placeholder="Insert your password"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          changePassword(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{}}
                        size="small"
                        sx={{ mt: "0.5rem" }}
                      />
                      {touched.password && errors.password && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-password-register"
                        >
                          {errors.password}
                        </FormHelperText>
                      )}
                    </FormControl>

                    {strength !== 0 && (
                      <FormControl fullWidth>
                        <Box>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <Box
                                style={{ backgroundColor: level?.color }}
                                sx={{
                                  width: 85,
                                  height: 8,
                                  borderRadius: "7px",
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="subtitle1"
                                fontSize="0.75rem"
                              >
                                {level?.label}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </FormControl>
                    )}

                    <Box sx={{ mt: "1.5rem" }}>
                      <Button
                        disableElevation
                        disabled={isSubmitting}
                        fullWidth
                        size="medium"
                        type="submit"
                        sx={classes.buttonSave}
                        id="signup"
                      >
                        Sign up
                      </Button>
                    </Box>

                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mt: "1.5rem" }}
                    >
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={classes.checkBox}
                              checked={checked}
                              onChange={(event) => {
                                setChecked(event.target.checked);
                                if (event.target.checked === false) {
                                  localStorage.setItem("termsAccepted", false);
                                  setTermsAccepted(false);
                                  setTermsStep(0);
                                }
                              }}
                              name="checked"
                              color="success"
                            />
                          }
                          label={
                            <Typography variant="subtitle1">
                              I agree to the &nbsp;
                              <Typography
                                variant="subtitle1"
                                component={Link}
                                onClick={() => {
                                  setChecked(true);
                                }}
                              >
                                Terms & Conditions
                              </Typography>
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                    {errors.submit && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          }

          {checked && !termsAccepted && (
            <div
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
                marginLeft: "100px",
                marginRight: "100px",
                maxHeight: "calc(80vh - 2.688rem)",
                width: "-webkit-fill-available",
              }}
            >
              {policy.map((data, index) => {
                return (
                  <>
                    <ToughAccept
                      key={index}
                      status={getStatusOfTerms(index)}
                      title={data.name}
                      onAccept={nextStep}
                      onDeciline={deciline}
                    >
                      <div
                        style={{ width: "100%" }}
                        dangerouslySetInnerHTML={{ __html: data.content }}
                      />
                    </ToughAccept>
                    <CDivider sx={{ my: "5px", borderColor: "#6D6D6D" }} />
                  </>
                );
              })}
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#D64C56",
                  fontSize: "12px",
                  mt: 1,
                }}
              >
                Please review the whole policy before accepting
              </Typography>
            </div>
          )}
        </Stack>
      </Stack>
      <Dialog
        open={alertTerms}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Stack maxWidth={"450px"} gap={2}>
            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
              <svg
                width="75"
                height="76"
                viewBox="0 0 75 76"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1277_4626)">
                  <path
                    d="M0 38C0 10.7273 10.2273 0.5 37.5 0.5C64.7727 0.5 75 10.7273 75 38C75 65.2727 64.7727 75.5 37.5 75.5C10.2273 75.5 0 65.2727 0 38Z"
                    fill="#C4D4E3"
                  />
                  <path
                    d="M62.9808 35.2959C62.5842 31.5369 59.2057 28.8195 55.4486 29.2087C51.6879 29.6052 48.962 32.9785 49.3586 36.7375C49.4019 37.1591 49.4271 37.588 49.4271 38.0133C49.4271 44.5833 44.0799 49.928 37.5067 49.928C30.9336 49.928 25.5863 44.5833 25.5863 38.0133C25.5863 37.588 25.608 37.1627 25.6513 36.7447C25.6513 36.7302 25.6513 36.7158 25.6513 36.7014C26.2678 30.6467 31.3843 26.0444 37.4743 26.0661C41.2494 26.0805 44.3251 23.0351 44.3431 19.2617C44.3611 15.4848 41.3143 12.407 37.5356 12.3853C33.9768 12.3673 31.0417 15.0667 30.692 18.5337C30.0754 24.592 24.9698 29.2051 18.8798 29.1835C15.3967 29.1619 12.3968 31.7784 12.0254 35.3139C11.9316 36.2041 11.8848 37.1159 11.8848 38.0169C11.8848 52.1337 23.3761 63.6196 37.4995 63.6196C51.623 63.6196 63.1143 52.1337 63.1143 38.0169C63.1143 37.1123 63.0674 36.1969 62.97 35.2995L62.9808 35.2959Z"
                    fill="#23222D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1277_4626">
                    <rect
                      width="75"
                      height="75"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <Stack sx={{ fontSize: "20px", fontWeight: 700 }}>
                Do you want to decline this policy?
              </Stack>
            </Stack>
            Please note that by declining this policy, you are choosing not to
            proceed with the creation of your account. Here's what happens next:
            Your account creation process will be terminated. You will not be
            able to access or use our software or any associated services. Any
            information provided during the account creation process will not be
            saved or stored. To reconsider your decision and proceed with
            accepting the policy, you can return to the previous step and accept
            the policy.
            <Button
              sx={classes.buttonCancel}
              onClick={() => {
                setAlertTerms(false);
                setChecked(false);
                setTermsAccepted(false);
                setTermsStep(0);
                localStorage.setItem("termsAccepted", false);
              }}
            >
              Okay
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default SignupPage;
