import { useDispatch } from "react-redux";
import { Box, Button, Card, Stack, Typography } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { resetUserPassword } from "../../../../actions/user";

const PasswordView = ({ setStage }) => {
  const dispatch = useDispatch();

  const proceedToPwdReset = () => {
    dispatch(resetUserPassword());
    setStage(0);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <PageTitle>Security</PageTitle>
        <Stack direction="row" gap={1}>
          <Button
            variant="outlined"
            sx={classes.buttonPrimary}
            onClick={() => setStage(0)}
          >
            Back
          </Button>
        </Stack>
      </Box>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          display: "flex",
          minHeight: "80%",
          marginTop: "40px",
        }}
      >
        <Stack sx={{ width: "50%", minWidth: "300px" }}>
          <Typography
            sx={{ fontWeight: "bold", fontSize: "18px", marginBottom: "20px" }}
          >
            Instructions
          </Typography>
          <Typography sx={{ maxWidth: "500px", marginLeft: "5px" }}>
            <p style={{ textIndent: "-15px", paddingLeft: "15px" }}>
              1. To reset your password, you will be forwarded to recover
              password flow.
            </p>
            <p style={{ textIndent: "-15px", paddingLeft: "15px" }}>
              2. After entering your email address, you will receive an email
              with a link to change your password. Note that if you have 2FA
              enabled, you must enter the code you receive via SMS/email for
              verification.
            </p>
            <p style={{ textIndent: "-15px", paddingLeft: "15px" }}>
              3. When you set a new password it must meet the requirements of
              UCO.Network.
            </p>
            <p style={{ textIndent: "-15px", paddingLeft: "15px" }}>
              4. Once completed, a confirmation email will be sent to confirm
              the change.
            </p>
          </Typography>
        </Stack>
        <Card sx={{ width: "400px", height: "fit-content", padding: "50px" }}>
          <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
            Want to change your password?
          </Typography>
          <Button
            variant="outlined"
            sx={[
              classes.buttonPrimary,
              { borderRadius: "5px", marginTop: "10px" },
            ]}
            onClick={proceedToPwdReset}
          >
            Proceed to password reset
          </Button>
        </Card>
      </Stack>
    </Box>
  );
};

export default PasswordView;
