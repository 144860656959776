// material-ui
import { Typography, styled } from "@mui/material";
import { Stack } from "@mui/system";

// sx
import { classes } from "../utils/class";

// typography component for title of page
const AccentTitle = ({ children }) => {
  return <Typography sx={classes.accentTitle}>{children}</Typography>;
};

const PageTitle = ({ children }) => {
  return <Typography sx={classes.pageTitle}>{children}</Typography>;
};

const BigTitle = ({ children }) => {
  return <Typography sx={classes.bigTitle}>{children}</Typography>;
};

const SuperTitle = ({ children }) => {
  return <Typography sx={classes.superTitle}>{children}</Typography>;
};

const SubTitle = ({ sx, children }) => {
  return (
    <Typography sx={{ ...classes.subTitle, ...sx }}>{children}</Typography>
  );
};

const SmallTitle = ({ sx, children }) => {
  return (
    <Typography sx={{ ...classes.smallTitle, ...sx }}>{children}</Typography>
  );
};

const MediumTitle = ({ sx, children }) => {
  return (
    <Typography sx={{ ...classes.mediumTitle, ...sx }}>{children}</Typography>
  );
};

const SmallContent = ({ sx, children }) => {
  return (
    <Typography sx={{ ...classes.smallContent, ...sx }}>{children}</Typography>
  );
};

const MediumContent = ({ sx, children }) => {
  return (
    <Typography sx={{ ...classes.mediumContent, ...sx }}>{children}</Typography>
  );
};

// typography component for input description
const InputTitle = ({
  children,
  disabled,
  size = 12,
  sx,
  error = false,
  required = false,
  onClick = () => {},
}) => {
  return (
    <div onClick={onClick}>
      <Typography
        sx={{
          fontFamliy: "Manrope",
          fontSize: size,
          fontWeight: 700,
          color: disabled
            ? "text.accent"
            : error
            ? "warnning.secondary"
            : "text.secondary",
          lineHeight: "24px",
          ...sx,
          textTransform: "uppercase",
        }}
      >
        {children}
        {required && (
          <Typography component="span" sx={disabled ? {} : classes.infoText}>
            *
          </Typography>
        )}
      </Typography>
    </div>
  );
};

const Percentage = ({ value }) => {
  const color =
    value > 0
      ? "success.main"
      : value === -100
      ? "warnning.secondary"
      : "text.secondary";
  return (
    <Stack sx={{ color, fontSize: "10px" }}>
      {value >= 0 ? "+" : "-"}
      {Math.abs(value)}%
    </Stack>
  );
};
const Euro = ({ value }) => {
  const color = value < 0 ? "warnning.secondary" : "text.secondary";
  return (
    <Stack sx={{ color }}>
      {value >= 0 ? "+" : "-"}
      {Math.abs(value)}€
    </Stack>
  );
};

const StatusTypography = styled(Typography)(({ active }) => ({
  fontSize: "13px",
  padding: "2px 5px",
  backgroundColor: active ? "#21B53B" : "#D64C56",
  color: "white",
  borderRadius: "3px",
  width: "fit-content",
  height: "fit-content",
}));

export {
  AccentTitle,
  PageTitle,
  BigTitle,
  InputTitle,
  SubTitle,
  SmallTitle,
  MediumTitle,
  SuperTitle,
  SmallContent,
  MediumContent,
  Percentage,
  StatusTypography,
  Euro,
};
