import { Popover } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { CustomDatePicker } from "../../../components/CustomDatePicker";

const DateDialog = ({
  open = false,
  handleClose = () => {},
  anchorEl = null,
  onClickDate,
  defaultValue = null,
}) => {
  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <CustomDatePicker
          defaultValue={defaultValue}
          onClickDate={onClickDate}
          handleClose={handleClose}
          isVisit
        />
      </LocalizationProvider>
    </Popover>
  );
};
export default DateDialog;
