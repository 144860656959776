import React from "react";

// material-ui
import { Box, Stack, IconButton, Typography } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

// project imports
import { CardPrimary } from "../../../../components/Card";
import { DataTable } from "../../../../components/Table";

// sx
import { classes } from "../../../../utils/class";
import { Percentage } from "../../../../components/Typography";
import { Link, useNavigate } from "react-router-dom";

// action import
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const Transfers = ({ props, extraInfo, id }) => {
  const navigate = useNavigate();
  const columns = [
    { field: "date", headerName: "Date" },
    {
      field: "origin_name",
      headerName: "Origin",
      renderCell: (props) => {
        return (
          <>
            <Typography sx={{ fontWeight: 700 }}>{props.value}</Typography>
          </>
        );
      },
    },
    {
      field: "vehicle_name",
      headerName: "Vehicle",
    },
    {
      field: "destination_warehouse_name",
      headerName: "Destination",
      renderCell: (props) => {
        return (
          <>
            <Typography sx={{ fontWeight: 700 }}>{props.value}</Typography>
          </>
        );
      },
    },
    {
      headerName: "Transfer",
      field: "transfer",
      minWidth: 350,
      renderCell: (props) => {
        return (
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Typography>{props.row.quantity} kg</Typography>
            <Percentage value={props.row.ratio} />
            <Typography>{props.row.raw_waste_name}</Typography>

            <Link to={`/wtn-detail/${props.row._id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <DescriptionOutlinedIcon />
              </IconButton>
            </Link>
          </Stack>
        );
      },
    },
    {
      headerName: "Driver",
      field: "vehicle_driver_name",
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            <CardPrimary
              type={1}
              title={"Nº Producers"}
              content={extraInfo?.count_of_producers}
            />
            <CardPrimary
              type={2}
              title={"Nº Collection Points"}
              content={extraInfo?.count_of_pickup_points}
            />
            <CardPrimary
              type={3}
              title={"Total collected"}
              content={extraInfo?.total_collected}
            />
          </Box>
        </Box>
      </Box>

      <DataTable
        fit="fit-screen"
        autoSize
        rows={props.map((prop, index) => {
          return {
            ...prop,
            id: prop._id,
            date: new Date(prop.date).toDateString(),
          };
        })}
        columns={columns}
        sx={classes.grid}
        onRowClick={(params, events, details) => {
          navigate(`/transfer-detail/${params.id}/view`);
        }}
      />
    </>
  );
};

export default Transfers;
