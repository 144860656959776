import { useEffect, useCallback, useState, cloneElement } from "react";
import { useSelector } from "react-redux";
import { current } from "@reduxjs/toolkit";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Switch, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { parsePhoneNumber } from "react-phone-number-input";

// project imports
import { classes } from "../utils/class";
import { COUNTRIES_INFO, MONTHS } from "../utils/constants";
import { capitalizeFirstLetter, summaryString } from "../utils/string";
import { getDayFromStr, getServerTimestamp } from "../utils/helpers.js";
import { InputTitle } from "./Typography";
import CountrySelect from "./CountrySelect";
import { RadioIcon } from "./icons/menu_icons/icons";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const defaultOptions = [
  "Mary Spot Restaurant1",
  "Mary Spot Restaurant2",
  "Mary Spot Restaurant3",
  "Mary Spot Restaurant4",
  "Mary Spot Restaurant5",
  "Mary Spot Restaurant6",
];

const defaultRadioOptions = ["Yes", "No"];

const SearchBar = ({
  label = "",
  onSearchChange = () => {},
  onEnter = () => {},
  fullWidth,
  placeholder = "",
  sx = {},
  size = "small",
  rounded = true,
  noBorder = false,
}) => {
  return (
    <>
      <Stack>
        <TextField
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: rounded ? "8px" : "0px",
              backgroundColor: "white",
              paddingLeft: "4px",
              borderColor: "#EEECE7 !important",
              borderWidth: "1px !important",
              borderStyle: "Solid !important",
              ...sx,
              "&:focus-within": {
                borderColor: "#EBF15580 !important",
                borderWidth: "2px !important",
                borderStyle: "solid !important",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderStyle: "none",
            },
          }}
          fullWidth={fullWidth}
          type="search"
          label={label}
          size={size}
          placeholder={placeholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: {
              color: "text.light",
              fontSize: "14px",
              fontFamily: "Manrope",
              backgroundColor: "white",
              ...sx,
            },
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEnter(e.target.value);
            }
          }}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
        />
      </Stack>
    </>
  );
};

const CSelect = ({
  required,
  fullWidth,
  label = "",
  options = defaultOptions,
  placeholder = "Select category",
  sx = {},
  menuSx = classes.optionMenu,
  size = "small",
  titleSize = 14,
  enableSearch = false,
  searchLabel = "",
  value = null,
  defaultValue = null,
  borderRound = true,
  onSelectChange = function () {},
}) => {
  const [filter, setFilter] = useState("");
  const filterChange = (val) => {
    setFilter(val);
  };
  return (
    <>
      <Stack gap={1}>
        <Stack>
          <InputTitle size={titleSize} required={required}>
            {label}
          </InputTitle>
        </Stack>
        <Stack>
          <Select
            MenuProps={{
              sx: menuSx,
            }}
            IconComponent={ExpandMoreIcon}
            value={value}
            size={size}
            defaultValue={defaultValue}
            fullWidth={fullWidth}
            sx={[
              {
                backgroundColor: "text.contrast",
                color: "text.light",
                ...sx,
                ...classes.textInput,
              },
              borderRound && { borderRadius: "8px" },
            ]}
            placeholder={placeholder}
            onChange={(e) => {
              onSelectChange(e.target.value);
            }}
          >
            {enableSearch && (
              <Stack
                sx={{
                  backgroundColor: "background.gray",
                  padding: "4px",
                }}
              >
                <SearchBar
                  fullWidth
                  onSearchChange={filterChange}
                  placeholder={searchLabel}
                />
              </Stack>
            )}
            {options.map((item, index) => {
              if (item.indexOf(filter) !== -1)
                return (
                  <MenuItem key={index} value={item?.value || item}>
                    {item?.value || summaryString(item)}
                  </MenuItem>
                );
              else return <></>;
            })}
          </Select>
        </Stack>
      </Stack>
    </>
  );
};

const CAutoComplete = ({
  title = "",
  required = false,
  bottomTips = "",
  onChange = () => {},
  onInput = () => {},
  onAddNewItem = () => {},
  isOptionEqualToValue = undefined,
  placeholder = "Select",
  size = "small",
  disableDropdown,
  fixed = false,
  options = defaultOptions,
  hasComponent = false,
  error = false,
  validation = false,
  disabled = false,
  checkBox = false,
  value,
  shadow = false,
  tooltip = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const isSelectedOption = (option) => {
    for (let opt of selectedOptions) {
      if (option === opt || option.value === opt.value || option === opt.value)
        return true;
    }
    return false;
  };
  const getUniqueSelectedOptions = (options) => {
    const countMap = {};
    options.forEach((element) => {
      if (countMap[element.value]) countMap[element.value]++;
      else {
        countMap[element.value] = 1;
      }
    });
    const filtered = options.filter((element) => countMap[element.value] === 1);
    return filtered;
  };
  const handleSelection = (options) => {
    setSelectedOptions(options);
  };
  useEffect(() => {
    if (checkBox) {
      setSelectedOptions(value);
    }
  }, [value, checkBox]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <InputTitle
        required={required}
        disabled={disabled}
        error={error || (validation && !value)}
      >
        {title}
      </InputTitle>
      <Stack sx={classes.cAutoComplete}>
        <Autocomplete
          disabled={disabled}
          sx={[
            {
              backgroundColor: "text.contrast",
            },
            classes.textInput,
          ]}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onInput={onInput}
          onChange={(e, value) => {
            let data = value;
            if (checkBox && data) {
              data = getUniqueSelectedOptions(value);
              handleSelection(data);
            }
            if (data) onChange(data);
          }}
          value={value}
          isOptionEqualToValue={
            isOptionEqualToValue
              ? isOptionEqualToValue
              : (option, value) => {
                  if (typeof option === "string") {
                    return option === value;
                  } else return option.name === value;
                }
          }
          open={open}
          options={options}
          size={size}
          freeSolo
          openOnFocus
          disableCloseOnSelect={checkBox}
          multiple={checkBox}
          renderInput={(params) => {
            return (
              <Box fullWidth ref={params.InputProps.ref}>
                <TextField
                  error={error || (validation && !value)}
                  placeholder={placeholder}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <IconButton>
                        {open || disableDropdown ? (
                          <SearchIcon onClick={() => setOpen(false)} />
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => {
                              if (disabled !== true) setOpen(true);
                            }}
                          />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            );
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            } else return option.name;
          }}
          filterOptions={(options, { inputValue }) => {
            let filtered = options.filter((option) => {
              if (typeof option === "string")
                return (
                  option.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                );
              else {
                return (
                  option.name
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) !== -1
                );
              }
            });

            if (filtered.length === 0 && fixed === false) {
              filtered = [{ value: inputValue, type: "new" }, ...options];
            }
            return filtered;
          }}
          ListboxProps={{
            sx: {
              "& li": {
                "&:hover": {
                  backgroundColor: "#EBF15566 !important",
                  color: "",
                },
              },
            },
          }}
          renderOption={(props, option, { inputValue, selected }) => {
            const isSelected =
              typeof option === "string" ? selected : isSelectedOption(option);
            if (
              typeof option === "string" ||
              (hasComponent === false && option.type !== "new")
            ) {
              let text = option;
              if (typeof option !== "string") text = option.name;
              const matches = match(text, inputValue, {
                insideWords: true,
              });
              const partsTitle = parse(text, matches);
              return (
                <li {...props}>
                  <Tooltip
                    title={tooltip ? option : ""}
                    slotProps={{
                      tooltip: {
                        sx: {
                          background: "black",
                        },
                      },
                      arrow: {
                        sx: {
                          color: "black",
                        },
                      },
                    }}
                    style={{
                      ...(checkBox && {
                        display: "flex",
                        alignItems: "center",
                      }),
                    }}
                  >
                    {checkBox && (
                      <Checkbox
                        sx={classes.checkBox}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={isSelected}
                      />
                    )}
                    <Stack direction={"row"}>
                      {partsTitle.map((part, index) => {
                        return (
                          <Typography
                            key={index}
                            sx={{
                              fontWeight: 600,
                              // color:
                              //   !shadow && !part.highlight
                              //     ? "text.secondary"
                              //     : "text.accent",
                              color: "text.secondary",
                              fontSize: "16px",
                              whiteSpace: "pre",
                              fontFamily: "Manrope",
                            }}
                          >
                            {part.text}
                          </Typography>
                        );
                      })}
                    </Stack>
                  </Tooltip>
                </li>
              );
            } else if (fixed === true) {
              return (
                <li {...props}>
                  {checkBox && (
                    <Checkbox
                      sx={classes.checkBox}
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={isSelected}
                    />
                  )}
                  {hasComponent
                    ? cloneElement(option.component, {
                        filterText: inputValue,
                      })
                    : option.value}
                </li>
              );
            } else
              return (
                <li
                  {...props}
                  onClick={(e) => {
                    onAddNewItem(option.value);
                    // props.onClick(e);
                  }}
                >
                  {checkBox && (
                    <Checkbox
                      sx={classes.checkBox}
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={isSelected}
                    />
                  )}
                  <AddCircleOutlineIcon
                    sx={{ color: "background.secondary", marginRight: "4px" }}
                  />
                  {option.value}
                </li>
              );
          }}
          {...props}
        />
      </Stack>
      {error || (validation && !value) ? (
        <Typography component="span" sx={classes.bottomError}>
          {"The required fields must be filled"}
        </Typography>
      ) : (
        <Typography component="span" sx={classes.bottomTips}>
          {bottomTips}
        </Typography>
      )}
    </Box>
  );
};

const CEmailInput = ({
  title = "",
  required = false,
  bottomTips = "",
  onChange = () => {},
  onInput = () => {},
  onAddNewItem = () => {},
  placeholder = "Select",
  size = "small",
  disableDropdown,
  fixed = false,
  options = defaultOptions,
  hasComponent = false,
  error = false,
  validation = false,
  disabled = false,
  checkBox = false,
  value,
  shadow = false,
  tooltip = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputField, setInputField] = useState("");

  const isSelectedOption = (option) => {
    for (let opt of selectedOptions) {
      if (option === opt || option.value === opt.value || option === opt.value)
        return true;
    }
    return false;
  };

  const handleSelection = (options) => {
    const result = [options[options.length - 1]];
    onChange(result);
    setInputField("");
    setSelectedOptions(options);
  };

  const handleClose = (item) => {
    setInputField("");
    onChange([]);
  };

  const handleKeyPress = (e, data) => {
    if (e.key === "Enter") {
      setInputField("");
      onChange([
        {
          name: data,
          value: "",
        },
      ]);
    }
  };

  const handleIconClick = () => {
    if (open || disableDropdown) {
      setOpen(false);
    } else {
      if (disabled !== true) {
        setOpen(true);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <InputTitle
        required={required}
        disabled={disabled}
        error={error || (validation && !value)}
      >
        {title}
      </InputTitle>
      <Stack sx={classes.cAutoComplete}>
        <Autocomplete
          disabled={value.length > 0 ? true : false}
          sx={[
            {
              backgroundColor: "text.contrast",
            },
            classes.textInput,
          ]}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onInput={onInput}
          onChange={(e, value) => {
            handleSelection(value);
          }}
          value={value}
          isOptionEqualToValue={(option, value) => {
            if (typeof option === "string") {
              return option === value;
            } else return option.name === value;
          }}
          open={open}
          options={options}
          size={size}
          freeSolo
          openOnFocus
          disableCloseOnSelect={checkBox}
          multiple={true}
          renderInput={(params) => {
            return (
              <Box fullwidth="true" ref={params.InputProps.ref}>
                <TextField
                  error={error || (validation && !value)}
                  placeholder={value.length > 0 ? "" : placeholder}
                  value={inputField}
                  onChange={(e) => {
                    setInputField(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    handleKeyPress(e, params.inputProps.value);
                  }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    value: inputField,
                    // onChange: (e) => {
                    //   setInputField(e.target.value);
                    // },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <IconButton onClick={handleIconClick}>
                        {open || disableDropdown ? (
                          <SearchIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            );
          }}
          renderTags={(value, getTagProps, ownerState) => {
            return value.map((item, index) => (
              <Stack
                key={index}
                direction={"row"}
                alignItems={"center"}
                sx={{
                  border: "solid 1px #C9C9C9",
                  borderRadius: "8px",
                  px: "8px",
                  marginRight: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "12px",
                    fontFamily: "Manrope",
                    color: "#282828",
                  }}
                >
                  {item.name}
                </Typography>
                <IconButton
                  sx={{
                    paddingLeft: "8px",
                  }}
                  onClick={() => {
                    handleClose(item);
                  }}
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.528606 0.52827C0.788955 0.26792 1.21107 0.26792 1.47141 0.52827L5.00001 4.05687L8.52861 0.52827C8.78895 0.26792 9.21106 0.26792 9.47141 0.52827C9.73176 0.788619 9.73176 1.21073 9.47141 1.47108L5.94282 4.99967L9.47141 8.52827C9.73176 8.78862 9.73176 9.21073 9.47141 9.47108C9.21106 9.73143 8.78895 9.73143 8.52861 9.47108L5.00001 5.94248L1.47141 9.47108C1.21107 9.73143 0.788955 9.73143 0.528606 9.47108C0.268256 9.21073 0.268256 8.78862 0.528606 8.52827L4.0572 4.99967L0.528606 1.47108C0.268256 1.21073 0.268256 0.788619 0.528606 0.52827Z"
                      fill="#7783A1"
                    />
                  </svg>
                </IconButton>
              </Stack>
            ));
          }}
          renderOption={(props, option, { inputValue, selected }) => {
            const isSelected =
              typeof option === "string" ? selected : isSelectedOption(option);
            if (
              typeof option === "string" ||
              (hasComponent === false && option.type !== "new")
            ) {
              let text = option;
              if (typeof option !== "string") text = option.name;
              const matches = match(text, inputValue, {
                insideWords: true,
              });
              const partsTitle = parse(text, matches);
              return (
                <li {...props}>
                  <Tooltip
                    title={tooltip ? option : ""}
                    slotProps={{
                      tooltip: {
                        sx: {
                          background: "black",
                        },
                      },
                      arrow: {
                        sx: {
                          color: "black",
                        },
                      },
                    }}
                  >
                    <Stack>
                      <Stack direction={"row"}>
                        {partsTitle.map((part, index) => {
                          return (
                            <Typography
                              key={index}
                              sx={{
                                fontWeight: 600,
                                color: "text.secondary",
                                fontSize: "16px",
                                whiteSpace: "pre",
                                fontFamily: "Manrope",
                              }}
                            >
                              {part.text}
                            </Typography>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Tooltip>
                </li>
              );
            } else if (fixed === true) {
              return (
                <li {...props}>
                  {checkBox && (
                    <Checkbox
                      sx={classes.checkBox}
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={isSelected}
                    />
                  )}
                  {hasComponent
                    ? cloneElement(option.component, {
                        filterText: inputValue,
                      })
                    : option.value}
                </li>
              );
            } else
              return (
                <li
                  {...props}
                  onClick={(e) => {
                    onAddNewItem(option.value);
                    // setInputValue("");
                    setInputField("");
                    onChange([
                      {
                        name: option.value,
                        value: "",
                      },
                    ]);
                  }}
                >
                  <Stack direction={"row"}>
                    <AddCircleOutlineIcon
                      sx={{
                        color: "background.secondary",
                        paddingRight: "10px",
                      }}
                    />
                    <Stack direction={"row"}>
                      <Typography
                        sx={{
                          fontFamily: "Manrope",
                          fontWeight: "700",
                          fontSize: "14px",
                          paddingRight: "4px",
                        }}
                      >
                        Invite
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Manrope",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        "{option.value}"
                      </Typography>
                    </Stack>
                  </Stack>
                </li>
              );
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            } else if (typeof option === "object" && option.value) {
              return option.value;
            } else {
              return option.name;
            }
          }}
          filterOptions={(options, { inputValue }) => {
            let filtered = options.filter((option) => {
              if (typeof option === "string")
                return (
                  option.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                );
              else {
                return (
                  option.name
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) !== -1
                );
              }
            });

            if (filtered.length === 0 && fixed === false) {
              filtered = [{ value: inputValue, type: "new" }];
            }
            return filtered;
          }}
          ListboxProps={{
            sx: {
              "& li": {
                "&:hover": {
                  backgroundColor: "#EBF15566 !important",
                  color: "",
                },
              },
            },
          }}
          {...props}
        />
      </Stack>
      {error || (validation && !value) ? (
        <Typography component="span" sx={classes.bottomError}>
          {"The required fields must be filled"}
        </Typography>
      ) : (
        <Typography component="span" sx={classes.bottomTips}>
          {bottomTips}
        </Typography>
      )}
    </Box>
  );
};

const CTextInput = ({
  field,
  title = "",
  size = 12,
  titleProps = {},
  required = false,
  rows = 1,
  value = null,
  placeholder = "",
  endIcon = null,
  bottomTips = "",
  type = "text",
  align = "left",
  sx = {},
  disabled = false,
  error = false,
  errorMessage = "This field is required",
  validation = false,
  max = null,
  min = null,
  onChange = function () {},
  onTitleClick = () => {},
  caps = false,
  readOnly = false,
}) => {
  const [currentValue, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        ...sx,
      }}
    >
      <InputTitle
        disabled={disabled}
        size={size}
        sx={{ ...titleProps }}
        error={error || (validation && !currentValue)}
        required={required}
        onClick={onTitleClick}
      >
        {title}
      </InputTitle>
      <OutlinedInput
        name={field?.name}
        readOnly={readOnly}
        error={error || (validation && !currentValue)}
        type={type}
        placeholder={placeholder}
        size="small"
        endAdornment={
          endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>
        }
        multiline={rows > 1}
        rows={rows}
        onChange={(e) => {
          if (max !== null && min !== null && type === "number") {
            if (e.target.value >= min && e.target.value <= max) {
              field?.onChange
                ? field.onChange(e.target.value)
                : onChange(e.target.value);
              setValue(e.target.value);
            }
          } else {
            field?.onChange ? field.onChange(e) : onChange(e.target.value);
            setValue(e.target.value);
          }
        }}
        disabled={disabled}
        value={currentValue ?? ""}
        inputProps={{
          style: {
            textAlign: align,
            textTransform: caps ? "uppercase" : "none",
          },
        }}
        sx={{
          backgroundColor: "text.contrast",
          ...classes.textInput,
        }}
      />
      {error || (validation === true && !currentValue) ? (
        <Typography component="span" sx={classes.bottomError}>
          {errorMessage}
        </Typography>
      ) : (
        <Typography component="span" sx={classes.bottomTips}>
          {bottomTips}
        </Typography>
      )}
    </Box>
  );
};

const CRadioSelect = ({
  title = "",
  titleProps = {},
  options = defaultRadioOptions,
  onChange = function () {},
  direction = "row",
  size = "small",
  required = false,
  radioRawDirection = true,
  components = [],
  hasComponent = false,
  unCheckedSx = {},
  checkedSx = {},
  sx = {},
  defaultValue = null,
  value = null,
}) => {
  const [currentValue, setCurrentValue] = useState(options[0]);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  return (
    <>
      <Stack
        direction={direction}
        gap={1}
        alignItems={direction === "row" ? "center" : "flex-start"}
      >
        <InputTitle required={required}>{title}</InputTitle>
        <RadioGroup
          sx={sx}
          onChange={(e) => {
            setCurrentValue(e.target.value);
            onChange(e.target.value);
          }}
          value={currentValue}
          row={radioRawDirection}
        >
          {options.map((item, index) => {
            if (typeof item === "string")
              return (
                <div key={index}>
                  <FormControlLabel
                    value={item}
                    control={
                      <Radio
                        sx={{
                          padding: "0px",
                          "&:hover": {
                            backgroundColor: "unset",
                            "& button": {
                              backgroundColor: "#EBF1554D",
                            },
                          },
                        }}
                        size={size}
                        checkedIcon={<RadioIcon checked />}
                        icon={<RadioIcon />}
                      />
                    }
                    label={item}
                  />
                  {hasComponent && currentValue === item && components[index]}
                </div>
              );
            else {
              return (
                <>
                  <FormControlLabel
                    sx={currentValue === item.value ? checkedSx : unCheckedSx}
                    key={index}
                    value={item.value}
                    control={
                      <Radio
                        size={size}
                        checkedIcon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              x="2"
                              y="2"
                              width="20"
                              height="20"
                              rx="10"
                              fill="#6D6D6D"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M17.2222 9.84825L15.3528 8L11 12.3036L8.86942 10.2222L7 12.0705L11 16L17.2222 9.84825Z"
                              fill="white"
                            />
                          </svg>
                        }
                      />
                    }
                    label={item.data}
                  />
                  {hasComponent &&
                    currentValue === item.value &&
                    components[index]}
                </>
              );
            }
          })}
        </RadioGroup>
      </Stack>
    </>
  );
};

const IOSSwitch = styled((props) => (
  <Switch
    size="small"
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 16,
  height: 10,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(6px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-thumb": {
        backgroundImage: "url(./1.svg);",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 8,
    height: 8,
  },
  "& .MuiSwitch-track": {
    borderRadius: 10 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 0,
  width: "56px",
  height: "26px",

  "& .MuiSwitch-track": {
    borderRadius: 24,
    "&:before, &:after": {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 56,
      height: 18,
    },
    "&:before": {
      content: '"On"',
      color: "white",
      left: 10,
      fontSize: 12,
      textTransform: "uppercase",
      fontFamily: "Manrope",
    },
    "&:after": {
      content: '"Off"',
      right: -28,
      color: "white",
      fontSize: 12,
      textTransform: "uppercase",
      fontFamily: "Manrope",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    backgroundColor: "white",
    width: 20,
    height: 20,
    margin: 4,
  },
  "& .MuiSwitch-switchBase": {
    transform: "translate(-8px, -10px)",
    "&.Mui-checked": {
      transform: "translate(20px, -10px)",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
        "&:after": {
          content: "unset",
        },
        "&:before": {
          content: '"ON"',
        },
      },
    },
    "& + .MuiSwitch-track": {
      backgroundColor: "#9F9E9E",
      opacity: 1,
      border: 0,
      "&:before": {
        content: "unset",
      },
    },
    "&:hover": {
      background: "none",
    },
  },
}));

const CPhoneInput = ({
  label,
  countryCode = null,
  value = "",
  onChange = () => {},
  disabled = false,
  countryDisabled = false,
  required = false,
  showStartAdornment = true,
  fullWidth = false,
}) => {
  const { profile } = useSelector((state) => state.user);

  const [currentCode, setCurrentCode] = useState(profile.countryCode);
  const [country, setCountry] = useState(null);
  const [phone_number, setPhoneNumber] = useState(null);

  useEffect(() => {
    if (countryCode) setCurrentCode(countryCode);
  }, [countryCode]);

  useEffect(() => {
    if (currentCode)
      setCountry(COUNTRIES_INFO.find((info) => info.code === currentCode));
  }, [currentCode]);

  useEffect(() => {
    if (value) setPhoneNumber(value);
  }, [value]);

  useEffect(() => {
    if (phone_number) {
      const number = parsePhoneNumber("+" + phone_number);
      if (number?.country) setCurrentCode(number.country);
    }
  }, [phone_number]);

  return (
    <Stack gap={"8px"}>
      <InputTitle required={required}>{label}</InputTitle>
      <Stack direction="row" spacing={2}>
        <Stack>
          <CountrySelect
            label=""
            showLabel={false}
            value={country}
            disabled={countryDisabled}
            onChange={(e, data) => {
              setCurrentCode(data.code);
              setPhoneNumber(data.phone);
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingY: "1px",
              },
              backgroundColor: "white",
            }}
          />
        </Stack>
        <OutlinedInput
          fullWidth={fullWidth}
          placeholder="Enter phone number"
          value={phone_number ?? ""}
          onChange={onChange}
          size="small"
          type="number"
          disabled={disabled}
          startAdornment={
            showStartAdornment ? (
              <Typography sx={{ color: "grey", marginRight: "5px" }}>
                +
              </Typography>
            ) : (
              ""
            )
          }
          sx={{
            backgroundColor: "white",
            ...classes.textInput,
          }}
        />
      </Stack>
    </Stack>
  );
};
const CVatNumber = ({
  label,
  countryCode = null,
  content = "",
  value,
  onChange,
  onCountryChange,
  placeholder = "Enter VAT number",
  required = false,
  disabled = false,
  error = false,
  validation = false,
  bottomTips = "",
  sx = {},
}) => {
  const [currentCode, setCurrentCode] = useState(null);
  const [country, setCountry] = useState(null);
  useEffect(() => {
    setCurrentCode(countryCode);
  }, [countryCode]);

  useEffect(() => {
    setCountry(COUNTRIES_INFO.find((info) => info.code === currentCode));
  }, [currentCode]);

  return (
    <Stack gap={"6px"} sx={{ ...sx }}>
      <InputTitle disabled={disabled} required={required}>
        {label}
      </InputTitle>
      <Stack direction="row" gap={1}>
        <CountrySelect
          sx={{
            backgroundColor: "text.contrast",
            width: "40%",
            "& .MuiOutlinedInput-root": {
              paddingY: "0.5px",
            },
          }}
          error={error || (validation && !country)}
          label=""
          showLabel={false}
          value={country}
          onChange={onCountryChange}
          disabled={disabled}
          required
        />
        <OutlinedInput
          error={error || (validation && !value)}
          sx={{
            backgroundColor: "text.contrast",
            width: "60%",
            ...classes.textInput,
          }}
          type="number"
          size="small"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          startAdornment={
            <Typography
              sx={{ disabled: true, color: "grey", marginRight: "5px" }}
            >
              {content}
            </Typography>
          }
        />
      </Stack>
      {error || (validation && !value) ? (
        <Typography component="span" sx={classes.bottomError}>
          {"Input the official ID"}
        </Typography>
      ) : (
        <Typography component="span" sx={classes.bottomTips}>
          {bottomTips}
        </Typography>
      )}
    </Stack>
  );
};

const COfficialIdInput = ({
  label,
  countryCode = null,
  content = "",
  value,
  onChange,
  onCountryChange,
  placeholder = "Official ID",
  required = false,
  disabled = false,
  error = false,
  validation = false,
  bottomTips = "",
}) => {
  const [currentCode, setCurrentCode] = useState(null);
  const [country, setCountry] = useState(null);
  useEffect(() => {
    setCurrentCode(countryCode);
  }, [countryCode]);

  useEffect(() => {
    setCountry(COUNTRIES_INFO.find((info) => info.code === currentCode));
  }, [currentCode]);

  return (
    <Stack gap={"8px"}>
      <InputTitle disabled={disabled} required={required}>
        {label}
      </InputTitle>
      <Stack direction="row" gap={1}>
        <CountrySelect
          sx={{
            backgroundColor: "text.contrast",
            width: "40%",
            "& .MuiOutlinedInput-root": {
              paddingY: "0.5px",
            },
          }}
          error={error || (validation && !country)}
          label=""
          showLabel={false}
          value={country}
          onChange={onCountryChange}
          disabled={disabled}
          required
        />
        <OutlinedInput
          error={error || (validation && !value)}
          sx={{
            backgroundColor: "text.contrast",
            width: "60%",
            ...classes.textInput,
          }}
          type="number"
          size="small"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          startAdornment={
            <Typography
              sx={{ disabled: true, color: "grey", marginRight: "5px" }}
            >
              {content}
            </Typography>
          }
        />
      </Stack>
      {error || (validation && !value) ? (
        <Typography component="span" sx={classes.bottomError}>
          {"Input the official ID"}
        </Typography>
      ) : (
        <Typography component="span" sx={classes.bottomTips}>
          {bottomTips}
        </Typography>
      )}
    </Stack>
  );
};

const CustomPickerActionBar = (props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "46px",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                ...classes.buttonCancel,
                height: "30px",
                width: "98px",
              }}
              onClick={props.onCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                ...classes.buttonPrimary,
                height: "30px",
                width: "98px",
              }}
              onClick={props.onAccept}
            >
              OK
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const CustomPickerHeader = () => {
  return (
    <>
      <Box
        sx={{
          height: "41px",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
          }}
        >
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontWeight: 700,
                fontSize: "12px",
              }}
            >
              Hour
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontWeight: 700,
                fontSize: "12px",
              }}
            >
              Minutes
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const CTimeInput = ({
  title = "",
  required = false,
  value,
  onChange = () => {},
}) => {
  return (
    <Stack gap={"8px"}>
      <InputTitle required={required}>{title}</InputTitle>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <TimePicker
          value={value}
          ampm={false}
          // defaultValue={dayjs(Date.now())}
          views={["hours", "minutes"]}
          inputFormat="HH:mm"
          sx={classes.datePicker}
          onChange={onChange}
          slots={{
            actionBar: CustomPickerActionBar,
            toolbar: CustomPickerHeader,
          }}
        />
      </LocalizationProvider>
    </Stack>
  );
};
const CDateInput = ({
  title = "",
  required = false,
  value = dayjs(Date.now()),
  onChange = () => {},
  views = null,
  isVisit = false,
  error = false,
  bottomTips = "",
  daysOff = null,
}) => {
  const current_scheduled = useSelector(
    (state) => state.visits.current_scheduled
  );
  const orgData = useSelector((state) => state.organizations.profile);
  const [days_off, setDaysOff] = useState([]);
  useEffect(() => {
    if (daysOff !== null) {
      setDaysOff(daysOff);
    } else if (current_scheduled) {
      setDaysOff(current_scheduled?.pickup_point_days_off || []);
    }
  }, [daysOff, current_scheduled]);
  const checkDateInDaysOff = useCallback(
    (tempDate) => {
      const current = getServerTimestamp(tempDate.getTime(), orgData.timezone);
      for (let t of days_off) {
        if (getDayFromStr(t) === new Date(current).getDay()) {
          return true;
        }
      }
      return false;
    },
    [orgData, days_off]
  );
  return (
    <Stack gap="8px">
      <InputTitle required={required} error={error}>
        {title}
      </InputTitle>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        {isVisit ? (
          <DatePicker
            label=""
            onChange={onChange}
            value={value}
            views={views}
            slots={{
              day: (props) => {
                const { day, ...other } = props;
                return (
                  <>
                    {(() => {
                      const res = checkDateInDaysOff(
                        new Date(props["data-timestamp"])
                      );
                      return (
                        <Tooltip
                          slotProps={{
                            tooltip: {
                              sx: {
                                background: "black",
                              },
                            },
                            arrow: {
                              sx: {
                                color: "black",
                              },
                            },
                          }}
                          title="Suggested date"
                        >
                          <PickersDay
                            {...other}
                            onFocus={(e) => {
                              other.onFocus(e, e.currentTarget.value);
                            }}
                            onBlur={(e) => {
                              other.onBlur(e, day);
                            }}
                            disabled={res}
                            day={day}
                            sx={{
                              borderRadius: "20%",
                            }}
                            onClick={(e) => {
                              onChange(day);
                            }}
                          />
                        </Tooltip>
                      );
                    })()}
                  </>
                );
              },
            }}
            sx={{
              ...classes.datePicker,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: error && "#d32f2f !important",
                borderRadius: "8px",
              },
            }}
          />
        ) : (
          <DatePicker
            label=""
            onChange={onChange}
            value={value}
            views={views}
            sx={{
              ...classes.datePicker,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: error && "#d32f2f !important",
                borderRadius: "8px",
              },
            }}
          />
        )}
      </LocalizationProvider>
      {error ? (
        <Typography component="span" sx={classes.bottomError}>
          {"The required fields must be filled"}
        </Typography>
      ) : (
        <Typography component="span" sx={classes.bottomTips}>
          {bottomTips}
        </Typography>
      )}
    </Stack>
  );
};

const CheckBoxes = ({
  title = "",
  options = [],
  required,
  sx,
  selectedOptions = null,
  onChange = () => {},
}) => {
  return (
    <>
      <Stack>
        <InputTitle required={required}>{title}</InputTitle>
        <FormGroup>
          {options.map((option, index) => {
            return (
              <Stack
                key={index}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <FormControlLabel
                  sx={{
                    marginRight: 0,
                  }}
                  control={
                    <Checkbox
                      sx={classes.checkBox}
                      onChange={(e) => {
                        onChange(option, e.target.checked);
                      }}
                      checked={(() => {
                        if (Array.isArray(selectedOptions)) {
                          if (selectedOptions.indexOf(option) >= 0) return true;
                          return false;
                        } else if (typeof selectedOptions === "object") {
                          if (selectedOptions[option] === true) return true;
                          return false;
                        }
                        return false;
                      })()}
                    />
                  }
                />
                <Typography sx={sx}>{capitalizeFirstLetter(option)}</Typography>
              </Stack>
            );
          })}
        </FormGroup>
      </Stack>
    </>
  );
};

const CExpand = ({ title, content, expandContent }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Stack padding={2} gap={1} width={"100%"}>
      <InputTitle>{title}</InputTitle>
      <InputTitle>{expanded ? expandContent : content}</InputTitle>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        {!expanded && (
          <IconButton
            onClick={() => {
              setExpanded(true);
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};
const CYearSelection = ({ year = 2021, onYearChange = () => {} }) => {
  const [currentYear, setCurrentYear] = useState(year);
  return (
    <>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        Select year
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Button
            onClick={() => {
              setCurrentYear(currentYear - 1);
              onYearChange(currentYear - 1);
            }}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          {currentYear}
          <Button
            onClick={() => {
              setCurrentYear(currentYear + 1);
              onYearChange(currentYear + 1);
            }}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
const CWholeYear = ({ year = 2021, data = [], onOffDayClicked = () => {} }) => {
  const days = ["S", "M", "T", "W", "T", "F", "S"];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let monthDays = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  const [maxLength, setMaxLength] = useState(36);
  const getDay = (y, m, d) => {
    const date = new Date(y, m - 1, d);
    return Number(date.getDay());
  };
  const isLeapYear = (y) => {
    if ((y % 4 === 0 && y % 100 !== 0) || y % 400 === 0) return 1;
    return 0;
  };
  const firstD = getDay(year, 1, 1);

  const getDaysForColumn = () => {
    let columns = [];
    for (let i = 0; i < maxLength; i++) {
      const d = days[(i + firstD) % 7];
      columns.push(
        <TableCell
          sx={{
            backgroundColor:
              d === "S" ? "background.bright" : "background.light",
            fontSize: "12px",
            paddingX: "2px",
            paddingY: "10px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {d}
        </TableCell>
      );
    }
    return columns;
  };

  const checkDateInDaysOff = useCallback(
    (tempDate, orgData) => {
      const current = getServerTimestamp(tempDate.getTime(), orgData.timezone);
      for (let t of data) {
        let start = getServerTimestamp(
          new Date(
            parseInt(t.start.substring(0, 4)),
            parseInt(t.start.substring(5, 7)) - 1,
            parseInt(t.start.substring(8))
          ).getTime(),
          orgData.timezone
        );
        let end = getServerTimestamp(
          new Date(
            parseInt(t.end.substring(0, 4)),
            parseInt(t.end.substring(5, 7)) - 1,
            parseInt(t.end.substring(8))
          ).getTime(),
          orgData.timezone
        );

        if (start === end) end = start + 86400000;
        if (start <= current && current <= end)
          return {
            selected: true,
            data: t,
          };
      }
      return {
        selected: false,
      };
    },
    [data]
  );
  const getDatesPerMonth = useCallback(
    (m, orgData) => {
      const columns = [];
      const initiDay = getDay(year, m + 1, 1);
      const date = new Date(year, m, 1);
      const initTimeStamp =
        getServerTimestamp(date.getTime(), orgData.timezone) -
        86400000 * ((firstD - initiDay + 7) % 7);

      let cnt = 0;
      for (let i = 0; i < maxLength; i++) {
        const temp = new Date(initTimeStamp + i * 86400000);
        const month = temp.getMonth();
        const d = temp.getDate();
        let selectedSx = {};

        const res = checkDateInDaysOff(temp, orgData);

        if (res.selected && month === m) {
          selectedSx = {
            backgroundColor: "warnning.secondary",
            color: "text.contrast",
            borderRadius: "10px",
          };
          cnt++;
        }
        columns.push(
          <TableCell
            sx={{
              color: month === m ? "text.primary" : "text.accent",
              fontSize: "12px",
              paddingX: "2px",
              paddingY: "10px",
              justifyContent: "center",
              minWidth: "24px",
              textAlign: "center",
              ...selectedSx,
            }}
            onClick={(e) => {
              if (res.selected) {
                onOffDayClicked(e, res.data);
              }
            }}
          >
            {d}
          </TableCell>
        );
      }
      columns.unshift(
        <TableCell sx={{ fontSize: "12px", padding: "2px" }}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            {MONTHS[m]}
            <Typography sx={{ color: "warnning.secondary", fontSize: "12px" }}>
              {cnt > 0 && cnt}
            </Typography>
          </Stack>
        </TableCell>
      );
      return columns;
    },
    [checkDateInDaysOff, firstD, maxLength, onOffDayClicked, year]
  );

  const setMaximumLegnth = useCallback(() => {
    let max = 0;
    monthDays[2] = 28 + isLeapYear(year);
    for (let i = 1; i <= 12; i++) {
      let d = (firstD + 7 - getDay(year, i, 1)) % 7;
      if (d + monthDays[i] > max) max = d + monthDays[i];
    }
    setMaxLength(max);
  }, [firstD, monthDays, year]);
  const columnsOfDays = getDaysForColumn();
  useEffect(() => {
    setMaximumLegnth();
  }, [setMaximumLegnth, year]);

  const orgData = useSelector((state) => state.organizations.profile);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ fontSize: "12px", padding: "2px", maxWidth: "100px" }}
            >
              {year}
            </TableCell>
            {columnsOfDays}
          </TableRow>
        </TableHead>
        <TableBody>
          {MONTHS.map((month, index) => {
            return (
              <TableRow key={index}>
                {(() => getDatesPerMonth(index, orgData))()}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

const CDivider = styled(Divider)`
  border-color: #f0f0f0;
`;

const CRatio = ({ percent }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="8"
      viewBox="0 0 50 8"
      fill="none"
    >
      <rect width="50" height="8" rx="4" fill="#002231" />
      <rect width={percent / 2} height="8" rx="4" fill="#EBF155" />
    </svg>
  );
};
export {
  CRatio,
  CYearSelection,
  CSelect,
  CAutoComplete,
  CTextInput,
  SearchBar,
  CRadioSelect,
  CPhoneInput,
  Android12Switch,
  COfficialIdInput,
  CVatNumber,
  CTimeInput,
  CheckBoxes,
  CDateInput,
  CExpand,
  CWholeYear,
  IOSSwitch,
  CDivider,
  CEmailInput,
};
