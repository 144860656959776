import { Stack, Typography, Button } from "@mui/material";

import { classes } from "../../utils/class";
import { useEffect, useRef, useState } from "react";

const CheckedIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="24" height="24" rx="12" fill="#2CB53B" />
      <g clipPath="url(#clip0_1403_10918)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L5.79289 14.2071C5.40237 13.8166 5.40237 13.1834 5.79289 12.7929C6.18342 12.4024 6.81658 12.4024 7.20711 12.7929L10 15.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289Z"
          fill="#EBF155"
        />
      </g>
      <defs>
        <clipPath id="clip0_1403_10918">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const CheckingIcon = () => {
  return (
    <svg
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="22" height="23" rx="11" stroke="#6D6D6D" />
      <g clipPath="url(#clip0_1403_10922)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2071 8.29289C18.5976 8.68342 18.5976 9.31658 18.2071 9.70711L10.2071 17.7071C9.81658 18.0976 9.18342 18.0976 8.79289 17.7071L5.29289 14.2071C4.90237 13.8166 4.90237 13.1834 5.29289 12.7929C5.68342 12.4024 6.31658 12.4024 6.70711 12.7929L9.5 15.5858L16.7929 8.29289C17.1834 7.90237 17.8166 7.90237 18.2071 8.29289Z"
          fill="#6D6D6D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1403_10922">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(3.5 4.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const ToCheckIcon = () => {
  return (
    <svg
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="22" height="23" rx="11" stroke="#6D6D6D" />
      <defs>
        <clipPath id="clip0_1403_10931">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(3.5 4.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const ToughAccept = ({
  children,
  title,
  status = "tocheck",
  onAccept,
  onDeciline,
}) => {
  const ref = useRef(null);
  const [readAll, setReadAll] = useState(false);

  useEffect(() => {
    if (ref?.current) {
      const element = ref.current;
      const handleScroll = (e) => {
        if (
          e.target.scrollTop + e.target.clientHeight >=
          e.target.scrollHeight - 10
        ) {
          setReadAll(true);
        }
      };
      element.addEventListener("scroll", handleScroll);
      if (element.scrollHeight > element.clientHeight) {
        console.log("Element has a scroll");
      } else {
        setReadAll(true);
      }
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [ref, status]);
  return (
    <>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        gap={1}
        height={"fit-screen"}
        mb={1}
      >
        {status === "checked" && <CheckedIcon />}
        {status === "tocheck" && <ToCheckIcon />}
        {status === "checking" && <CheckingIcon />}
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
      </Stack>

      {status === "checking" && (
        <>
          <Stack
            maxHeight={"30vh"}
            sx={{
              backgroundColor: "#E7E6E3",
              overflowY: "auto",
            }}
            padding={2}
            ref={ref}
          >
            {children}
          </Stack>
          <Stack flexDirection={"row"} gap={2} mt={1}>
            <Button sx={classes.buttonCancel} onClick={onDeciline}>
              Decline
            </Button>
            <Button
              disabled={!readAll}
              sx={classes.buttonPrimary}
              onClick={onAccept}
            >
              Accept
            </Button>
          </Stack>
        </>
      )}
    </>
  );
};
export default ToughAccept;
