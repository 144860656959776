import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, IconButton, Modal, Stack } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import {
  COfficialIdInput,
  CTextInput,
} from "../../../../components/CustomInput";
import { classes } from "../../../../utils/class";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchWarehouseList,
  updateWarehouseInfo,
} from "../../../../actions/warehouse";

const IdentificationDialog = ({ open, onClose, info }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [oid, setOId] = useState("");
  const [country, setCountry] = useState("");

  const handleSave = () => {
    dispatch(
      updateWarehouseInfo(
        info._id,
        { name, official_id: oid, official_id_country: country },
        () => {
          onClose();
          dispatch(fetchWarehouseList());
        }
      )
    );
  };

  useEffect(() => {
    setName(info.name);
    setOId(info.official_id || "");
    setCountry(info.official_id_country);
  }, [info]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack padding={"34px 42px"} gap={"22px"} sx={classes.popup}>
        <PageTitle>Identification</PageTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "42px",
            top: "34px",
          }}
        >
          <CloseIcon />
        </IconButton>
        <CTextInput
          title="Warehouse name"
          required
          placeholder="Enter warehouse name"
          value={name}
          onChange={(v) => setName(v)}
        />
        <CTextInput
          title="UCO Network ID"
          placeholder={`UID ${info.id}`}
          disabled
        />
        <COfficialIdInput
          countryCode={country}
          label={"Official ID"}
          required
          placeholder="---"
          value={oid}
          onCountryChange={(e, data) => {
            setCountry(data.code);
          }}
          onChange={(v) => setOId(v.target.value)}
        />
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={onClose}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default IdentificationDialog;
