import { createSlice } from "@reduxjs/toolkit";
import { STATE_ACTIVE } from "../utils/type";

const initialState = {
  currentPickup: 0,
  extraInfo: {
    count_without_manager: 0,
    count_expiring_documents: 0,
    count_expired_documents: 0,
    count_with_zero_waste_collected: 0,
  },
  rawWastes: [],
  pickupList: [],
  pickupDetail: {},
  transferList: [],
  visitList: [],
  scheduledVisitList: [],
  documentList: [],
  inventoryList: [],
  offerList: [],
  depositList: [],
  add_deposit: false,
  rawWasteList: [],
};

export const pickupSlice = createSlice({
  name: "pickup",
  initialState,
  reducers: {
    setPickupRawWastes: (state, action) => {
      state.rawWastes = action.payload;
    },
    setRawWasteList: (state, action) => {
      state.rawWasteList = action.payload;
    },
    setAddDepositStep: (state, action) => {
      state.add_deposit = action.payload;
    },
    setPickupInventoryList: (state, action) => {
      state.inventoryList = action.payload;
    },
    setPickupOfferList: (state, action) => {
      state.offerList = action.payload;
    },
    setDepositList: (state, action) => {
      state.depositList = action.payload;
    },
    setScheduledVisitList: (state, action) => {
      state.scheduledVisitList = action.payload;
    },
    setVisitList: (state, action) => {
      state.visitList = action.payload;
    },
    setTransferList: (state, action) => {
      state.transferList = action.payload;
    },
    setDocumentList: (state, action) => {
      state.documentList = action.payload;
    },
    setCurrentPickup: (state, action) => {
      state.currentPickup = action.payload;
    },
    setPickupList: (state, action) => {
      state.pickupList = action.payload;
    },
    setPickupDetail: (state, action) => {
      state.pickupDetail = action.payload;
    },
    setExtraInfo: (state, action) => {
      state.extraInfo = action.payload;
    },
  },
});

export const {
  setAddDepositStep,
  setExtraInfo,
  setScheduledVisitList,
  setPickupDetail,
  setCurrentPickup,
  setDepositList,
  setPickupInventoryList,
  setPickupOfferList,
  setPickupList,
  setDocumentList,
  setTransferList,
  setVisitList,
  setPickupRawWastes,
  setRawWasteList,
} = pickupSlice.actions;

export default pickupSlice.reducer;
