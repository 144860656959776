import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import { Box, Button, Stack, setRef } from "@mui/material";

// project imports
import { PageTitle, SubTitle } from "../../../components/Typography";
// service imports
// third party
//sx class definition
import { classes } from "../../../utils/class";
import {
  CAutoComplete,
  CRadioSelect,
  CSelect,
  CTextInput,
} from "../../../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewCategory,
  addNewName,
  createInventory,
  fetchInventoryCategory,
  fetchInventoryName,
} from "../../../actions/inventory";

const AddInventory = () => {
  const dispatch = useDispatch();
  const { categories, names } = useSelector((state) => state.inventory);
  const [category, setCategory] = useState(null);
  const [name, setName] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentName, setCurrentName] = useState();
  const [is_deposit, setIsDeposit] = useState(false);
  const [is_offer, setIsOffer] = useState(false);
  const [available_to_client, setAtoClient] = useState(false);
  const [available_to_driver, setAtoDriver] = useState(false);
  const [reference, setReference] = useState();
  const [offer_price, setOfferPrice] = useState(0);
  const [offer_unit, setOfferUnit] = useState("EUR €");
  const [deposit_capacity, setDepositCapacity] = useState(0);
  const [deposit_unit, setDepositUnit] = useState("kg");
  const [allocatable_to_client, setAllocatable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchInventoryCategory());
  }, [dispatch]);

  useEffect(() => {
    if (currentName !== null) {
      let nameId = "";
      for (let na of names) {
        if (na.name === currentName) {
          nameId = na._id;
          break;
        }
      }
      setName(nameId);
    }
  }, [names, currentName]);
  useEffect(() => {
    if (currentCategory !== null) {
      let categoryId = "";
      for (let cate of categories) {
        if (cate.name === currentCategory) {
          categoryId = cate._id;
          break;
        }
      }
      setCategory(categoryId);
      dispatch(fetchInventoryName(categoryId));
    }
  }, [categories, currentCategory, dispatch]);
  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          height: "100%",
          paddingX: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <PageTitle>Inventory</PageTitle>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={() => {
                navigate("/inventory");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={() => {
                // window.history.back();
                const data = {
                  reference,
                  is_deposit,
                  is_offer,
                  offer_price,
                  offer_unit,
                  available_to_client,
                  available_to_driver,
                  allocatable_to_client,
                  deposit_capacity,
                  deposit_unit,
                  name,
                  category,
                };
                data.offer_unit = offer_unit.substring(0, 3);
                dispatch(
                  createInventory(data, () => {
                    navigate("/inventory");
                  })
                );
              }}
            >
              Save
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "36px",
              minWidth: "422px",
              justifyContent: "center",
              marginBottom: 50,
            }}
          >
            <Stack>
              <SubTitle>Add item </SubTitle>
            </Stack>
            <Stack>
              <CAutoComplete
                required
                fixed
                title="Category"
                options={categories.map((cate, index) => cate.name)}
                onAddNewItem={(value) => {
                  dispatch(addNewCategory({ name: value }));
                }}
                onChange={(val) => {
                  setCurrentCategory(val);
                }}
              />
            </Stack>
            <Stack>
              <CTextInput
                required
                title="Item refernce"
                onChange={(val) => {
                  setReference(val);
                }}
              />
            </Stack>
            <Stack>
              <CAutoComplete
                required
                title="Name Item"
                options={names.map((name, index) => name.name)}
                onAddNewItem={(value) => {
                  if (value)
                    dispatch(addNewName({ category: category, name: value }));
                }}
                onChange={(value) => {
                  setCurrentName(value);
                }}
                placeholder="Select Name"
              />
            </Stack>
            <Stack marginTop={2}>
              <CRadioSelect
                title="Is this a deposit?"
                onChange={(value) => {
                  if (value === "Yes") {
                    setIsDeposit(true);
                    setIsOffer(false);
                  } else setIsDeposit(false);
                }}
                direction="row"
                value={is_deposit === true ? "Yes" : "No"}
              />
              {is_deposit && (
                <Stack spacing={1} direction={"row"}>
                  <Stack width={120}>
                    <CTextInput
                      title="Capacity"
                      required
                      placeholder="0"
                      type="number"
                      size={12}
                      onChange={(value) => {
                        setDepositCapacity(Number(value));
                      }}
                    />
                  </Stack>
                  <Stack width={120}>
                    <CSelect
                      label="Unit"
                      titleSize={12}
                      required
                      value={deposit_unit}
                      options={["kg", "l"]}
                      onSelectChange={(value) => {
                        setDepositUnit(value);
                      }}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack spacing={2}>
              <CRadioSelect
                title="Is this an offer?"
                direction="row"
                defaultValue="No"
                onChange={(value) => {
                  if (value === "Yes") {
                    setIsOffer(true);
                    setIsDeposit(false);
                  } else setIsOffer(false);
                }}
                value={is_offer === true ? "Yes" : "No"}
              />
              {is_offer && (
                <>
                  <Stack spacing={1} direction={"row"}>
                    <Stack width={120}>
                      <CTextInput
                        title="Value"
                        required
                        placeholder="0"
                        type="number"
                        size={12}
                        onChange={(val) => {
                          setOfferPrice(Number(val));
                        }}
                      />
                    </Stack>
                    <Stack width={120}>
                      <CSelect
                        titleSize={12}
                        label="Unit"
                        required
                        value={offer_unit}
                        options={["EUR €", "USD $", "GBP £"]}
                        onSelectChange={(val) => {
                          setOfferUnit(val);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack>
                    <CRadioSelect
                      title="Available to clients?"
                      defaultValue="No"
                      titleProps={{
                        fontSize: "14px",
                      }}
                      onChange={(value) => {
                        setAtoClient(value === "Yes");
                      }}
                    />
                    <CRadioSelect
                      title="Available to driver?"
                      titleProps={{
                        fontSize: "14px",
                      }}
                      defaultValue="No"
                      onChange={(value) => {
                        setAtoDriver(value === "Yes");
                      }}
                    />
                  </Stack>
                </>
              )}
            </Stack>
            <Stack>
              <CRadioSelect
                title="Can it be allocated to a client?"
                direction="row"
                defaultValue={"No"}
                onChange={(value) => {
                  setAllocatable(value === "Yes");
                }}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default AddInventory;
