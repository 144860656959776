import { Stack, Box, Button, Tooltip } from "@mui/material";
import { classes } from "../../../utils/class";
import {
  PageTitle,
  InputTitle,
  SubTitle,
} from "../../../components/Typography";
import { CAutoComplete, CTextInput } from "../../../components/CustomInput";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMemberList,
  fetchRoleList,
  fetchTeamList,
  inviteMember,
  cancelInvite,
} from "../../../actions/member";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CancelDialog from "./Dialog/CancelDialog";
const ReinvitationPage = () => {
  const params = useParams();
  const id = params.id;
  const { teamList, roles, members } = useSelector((state) => state.member);
  const dispatch = useDispatch();
  const [cancelDialog, setCancelDialog] = useState(false);
  const [teamOption, setTeamOption] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [team, setTeam] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTeamList());
    dispatch(fetchRoleList());
    dispatch(fetchMemberList());
  }, [dispatch]);

  useEffect(() => {
    for (let member of members) {
      if (member.id === id) {
        setEmail(member.email);
        setRole(member.role);
        setTeam(member.team_name);
        setTeamId(member.team);
      }
    }
  }, [id, members]);
  useEffect(() => {
    const list = roles.map((role, index) => {
      return {
        ...role,
        value: role.display_name,
      };
    });
    setRoleOption(list);
  }, [roles]);
  useEffect(() => {
    const list = teamList.map((team, index) => {
      return {
        ...team,
        value: team.name,
      };
    });
    setTeamOption(list);
  }, [teamList]);
  return (
    <>
      <Stack sx={{ minHeight: "100vh", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingX: "60px",
            paddingY: "20px",
          }}
        >
          <PageTitle>Members</PageTitle>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={() => {
                dispatch(
                  inviteMember(
                    {
                      email,
                      role,
                      team: teamId,
                    },
                    () => {
                      navigate("/settings/member");
                    }
                  )
                );
              }}
            >
              Re-Invite member
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={() => {
                setCancelDialog(true);
              }}
            >
              Cancel Invitation
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "36px",
              minWidth: "422px",
              justifyContent: "center",
            }}
          >
            <SubTitle> Invite member </SubTitle>
            <CTextInput
              disabled
              title="Email or username"
              required
              placeholder="Insert email"
              value={email}
              onChange={(val) => {
                if (val) setEmail(val);
              }}
            />

            <CAutoComplete
              disabled
              placeholder="Select a role"
              title="Role"
              required
              options={roleOption}
              fixed
              onChange={(val) => {
                if (val) setRole(val.name);
              }}
              value={role}
            />
            <CAutoComplete
              disabled
              placeholder="Select a team"
              value={team}
              title={
                <Stack direction={"row"} alignItems={"center"}>
                  <InputTitle required disabled>
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            background: "black",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "black",
                          },
                        },
                      }}
                      title="Select a team"
                      placement="left"
                    >
                      Team
                    </Tooltip>
                  </InputTitle>
                  <InfoOutlined />
                </Stack>
              }
              options={teamOption}
              onChange={(value) => {
                if (value) setTeam(value._id);
              }}
            />
          </Box>
        </Box>
      </Stack>

      <CancelDialog
        open={cancelDialog}
        handleClose={() => {
          setCancelDialog(false);
        }}
        handleContinue={() => {
          dispatch(
            cancelInvite(id, () => {
              navigate("/settings/member");
            })
          );
        }}
      />
    </>
  );
};

export default ReinvitationPage;
