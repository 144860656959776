import storage from "redux-persist/lib/storage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import ticketsReducer from "./slices/ticketsSlice";
import fleetReducer from "./slices/fleetSlice";
import inventoryReducer from "./slices/inventorySlice";
import settingsReducer from "./slices/settingsSlice";
import pickupReducer from "./slices/pickupSlice";
import producerReducer from "./slices/producerSlice";
import visitsReducer from "./slices/visitsSlice";
import transferReducer from "./slices/transferSlice";
import zoneReducer from "./slices/zoneSlice";
import certificatesReducer from "./slices/certificatesSlice";
import orgReducer from "./slices/orgSlice";
import memberReducer from "./slices/memberSlice";
import warehouseReducer from "./slices/warehouseSlice";
import userReducer from "./slices/userSlice";
import wasteReducer from "./slices/wasteSlice";
import spinnerReducer from "./slices/spinnerSlice";

const rootReducer = combineReducers({
  tickets: ticketsReducer,
  fleet: fleetReducer,
  inventory: inventoryReducer,
  settings: settingsReducer,
  pickup: pickupReducer,
  producer: producerReducer,
  visits: visitsReducer,
  transfer: transferReducer,
  zone: zoneReducer,
  certificates: certificatesReducer,
  organizations: orgReducer,
  member: memberReducer,
  warehouse: warehouseReducer,
  user: userReducer,
  waste: wasteReducer,
  spinner: spinnerReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

const persistor = persistStore(store);

export { store, persistor };
