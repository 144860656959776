import {
  Stack,
  Button,
  TextField,
  Divider,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { InputTitle, PageTitle, SubTitle } from "../Typography";
import {
  CAutoComplete,
  CDateInput,
  COfficialIdInput,
  CPhoneInput,
  CRadioSelect,
  CSelect,
  CTextInput,
  CheckBoxes,
} from "../CustomInput";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import { classes } from "../../utils/class";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWarehouseList } from "../../actions/warehouse";
import { fetchZoneList } from "../../actions/zone";
import dayjs from "dayjs";
import { fetchUserList } from "../../actions/member";

import FileService from "../../services/FileService";
import { SUCCESS } from "../../utils/type";
import attachment from "../../assets/images/attachment.svg";
import remove from "../../assets/images/remove.svg";
import {
  getFileName,
  showNotification,
  getServerTimestamp,
} from "../../utils/helpers";
import { deleteDocumentForPickup } from "../../actions/pickup";
import { fetchProducerPickupList } from "../../actions/producers";
import { ERROR } from "../../utils/constants";
import { setUploadSpinnerStatus } from "../../actions/spinner";
import OrganizationService from "../../services/OrganizationService";
export const PickupIdentificationModal = ({
  title,
  data,
  extraData,
  onCancelHandler,
  onSaveHandler,
}) => {
  const { profile } = useSelector((state) => state.user);
  const [name, setName] = useState(data?.name);
  const [official_id, setOfficialId] = useState(data["official id"]);
  const [official_id_country, setOfficialIdCountry] = useState(
    profile.countryCode
  );
  const [description, setDescription] = useState(extraData?.description);
  useEffect(() => {
    if (data) setName(data.name);
  }, [data]);
  useEffect(() => {
    if (extraData.official_id && extraData.official_id !== undefined)
      setOfficialId(extraData.official_id);
    if (
      extraData.official_id_country &&
      extraData.official_id_country !== undefined
    )
      setOfficialIdCountry(extraData.official_id_country);
    if (extraData.description && extraData.description !== undefined)
      setDescription(extraData.description);
  }, [extraData]);

  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <CTextInput
        title="Name"
        required
        placeholder="Mary Spot Restaurant"
        value={name}
        onChange={(v) => {
          setName(v);
        }}
      />

      <COfficialIdInput
        label={"Official Id"}
        countryCode={official_id_country}
        value={official_id}
        onCountryChange={(e, data) => {
          setOfficialIdCountry(data.code);
        }}
        onChange={(e) => {
          setOfficialId(e.target.value);
        }}
      />
      <CTextInput
        title="Producer*"
        placeholder="Olive Marpickings, Lda"
        value={data?.producer}
        disabled
      />
      <CTextInput
        rows={3}
        title="Description"
        value={description}
        onChange={(val) => {
          setDescription(val);
        }}
        placeholder="Write your description about this collection point"
      ></CTextInput>
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler(
              { name, official_id, description, official_id_country },
              onCancelHandler
            );
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const PickupDetailModal = ({
  title,
  data,
  extraData,
  onCancelHandler,
  onSaveHandler,
}) => {
  const dispatch = useDispatch();
  const zones = useSelector((state) => state.zone.zoneList);
  const warehouses = useSelector((state) => state.warehouse.warehouses);

  const [warehouse, setWareHouse] = useState(null);
  const [zone, setZone] = useState([]);

  const [errors, setErrors] = useState({
    destination: false,
    collectionPoints: false,
  });
  useEffect(() => {
    dispatch(fetchWarehouseList());
    dispatch(fetchZoneList());
  }, [dispatch]);
  useEffect(() => {
    let temp = [];
    for (let wh of warehouses) {
      if (wh._id === extraData?.warehouse) {
        setWareHouse({
          ...wh,
          value: wh._id,
        });
        break;
      }
    }
    for (let z of zones) {
      if (extraData.zone_names.indexOf(z.name) !== -1) {
        temp.push({ name: z.name, value: z._id });
      }
    }
    setZone(temp);
  }, [extraData, warehouses, zones]);
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack>
        <CAutoComplete
          title="Waste destination warehouse"
          required
          placeholder="Select warehouse"
          hasComponent
          fixed
          value={warehouse}
          error={errors.destination}
          onChange={(value) => {
            setWareHouse(value);
          }}
          options={warehouses.map((warehouse, index) => {
            return {
              value: warehouse._id,
              name: warehouse.name,
              component: (
                <Stack>
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                    {warehouse.name}
                  </Typography>
                  <Typography sx={{ color: "grey", fontSize: "12px" }}>
                    OID: {warehouse.official_id}
                  </Typography>
                </Stack>
              ),
            };
          })}
        />
      </Stack>
      <Stack>
        <CAutoComplete
          options={zones.map((zone, index) => {
            return {
              name: zone.name,
              value: zone._id,
            };
          })}
          error={errors.collectionPoints}
          value={zone}
          onChange={(values) => {
            setZone(values);
          }}
          title="Define zones for collection point"
          required
          placeholder="Select zones"
          checkBox
        />
      </Stack>
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            const zones = zone.map((z) => {
              return z.value;
            });
            if (warehouse?.value && zones.length > 0)
              onSaveHandler({ warehouse: warehouse?.value, zones: zones });
            else {
              setErrors({
                destination: warehouse?.value ? false : true,
                collectionPoints: zones.length === 0,
              });
            }
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const PickupWasteModal = ({
  title,
  data,
  extraData,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [payment_type, setPaymentType] = useState("Company to customer");
  const [price_per_kg, setPrice] = useState(0);

  useEffect(() => {
    setPrice(extraData?.price_per_kg);
    setPaymentType(
      extraData?.payment_type === "to_customer"
        ? "Company to customer"
        : "Customer to company"
    );
  }, [extraData]);
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <Stack gap={1}>
        <CRadioSelect
          title="Payment"
          direction="column"
          required
          options={["Company to customer", "Customer to company"]}
          value={payment_type}
          onChange={(value) => {
            setPaymentType(value);
          }}
        />
      </Stack>

      <Stack gap={1}>
        <CTextInput
          value={price_per_kg}
          onChange={(value) => {
            setPrice(value);
          }}
          title="Price per kg"
          type="number"
          endIcon={"€"}
        />
      </Stack>

      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({
              price_per_kg: price_per_kg,
              payment_type:
                payment_type === "Company to customer"
                  ? "to_customer"
                  : "to_company",
            });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const PickupExpectedModal = ({
  title,
  data,
  extraData,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [expect_frequency, setFrequency] = useState(0);
  const [expect_quantity, setQuantity] = useState(0);
  const [expect_impurity, setImpurity] = useState(0);
  useEffect(() => {
    if (extraData) {
      setFrequency(extraData?.expect_frequency);
      setQuantity(extraData?.expect_quantity);
      setImpurity(extraData?.expect_impurity);
    }
  }, [extraData]);
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>

      <Stack gap={1} width={"50%"}>
        <CTextInput
          title="Frequency"
          required
          type="text"
          endIcon={"Days"}
          placeholder="00"
          readOnly
          value={expect_frequency}
          onChange={(value) => {
            setFrequency(value);
          }}
        />
      </Stack>

      <Stack gap={1} direction={"row"}>
        <CTextInput
          title="Quantity"
          required
          type="text"
          endIcon={"kg"}
          placeholder="100"
          value={expect_quantity}
          onChange={(value) => {
            setQuantity(value);
          }}
        />
        <CTextInput
          title="Impurity %"
          required
          type="text"
          endIcon={"%"}
          placeholder="100"
          value={expect_impurity}
          onChange={(value) => {
            setImpurity(value);
          }}
        />
      </Stack>

      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({
              expect_impurity,
              expect_quantity,
            });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
export const PickupManagementModal = ({
  title,
  data,
  extraData,
  onCancelHandler,
  onSaveHandler,
}) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.member);
  const [producer_manager, setProducerManager] = useState(null);
  const [pickup_point_manager, setPickupPointManager] = useState(null);
  useEffect(() => {
    dispatch(fetchUserList());
  }, [dispatch]);
  useEffect(() => {
    if (extraData.producer_manager_name) {
      setProducerManager({
        name: extraData?.producer_manager_name,
        value: extraData?.producer_manager,
      });
    }
    if (extraData.pickup_point_manager) {
      setPickupPointManager({
        name: extraData?.pickup_point_manager_name,
        value: extraData?.pickup_point_manager,
      });
    }
  }, [extraData]);
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack gap={1}>
        <CAutoComplete
          disabled
          value={producer_manager}
          onChange={(value) => {
            setProducerManager(value);
          }}
          options={users.map((user, index) => {
            return {
              name: user.name,
              value: user._id,
            };
          })}
          required
          title="Producer manager"
          placeholder={producer_manager?.name}
          disableDropdown
        />
      </Stack>
      <Stack gap={1}>
        <CAutoComplete
          value={pickup_point_manager}
          onChange={(value) => {
            setPickupPointManager(value);
          }}
          options={users.map((user, index) => {
            return {
              name: user.name,
              value: user._id,
            };
          })}
          required
          title="Collection Point manager"
          disableDropdown
          bottomTips="If empty, the collection point manager will be the same as the producer manager"
        />
      </Stack>
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({
              producer_manager: producer_manager?.value,
              pickup_point_manager: pickup_point_manager?.value,
            });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const PickupContactsModal = ({
  title,
  data,
  extraData,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [phone, setPhone] = useState(null);
  const [mobile_phone, setMobilePhone] = useState(null);
  const [fax, setFax] = useState(null);
  const [email, setEmail] = useState(null);
  const [contact_notes, setContactNotes] = useState(null);
  const dispatch = useDispatch();
  const [validation, setValidataion] = useState({
    email: false,
  });
  const checkValidation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setValidataion({ email: true });
      return false;
    }
    return true;
  };
  const [name, setName] = useState(null);

  useEffect(() => {
    if (data) {
      setPhone(extraData?.phone);
      setMobilePhone(extraData?.mobile_phone);
      setFax(extraData?.fax);
      setEmail(extraData?.email);
      setContactNotes(extraData?.contact_notes);
      setName(extraData?.responsible_name);
    }
  }, [data, extraData]);

  useEffect(() => {
    dispatch(fetchUserList());
  }, [dispatch]);

  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack gap={1}>
        <CTextInput
          title="Responsible"
          placeholder="Enter name"
          value={name}
          onChange={(value) => {
            setName(value);
          }}
        />
      </Stack>
      <Stack gap={1}>
        <SubTitle>Contacts</SubTitle>

        <CPhoneInput
          fullWidth
          label={"Phone"}
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />

        <CPhoneInput
          fullWidth
          label="Mobile Phone"
          onChange={(e) => {
            setMobilePhone(e.target.value);
          }}
          value={mobile_phone}
        />

        <Stack>
          <CTextInput
            title="Email"
            error={validation.email}
            placeholder="mspot@email.com"
            value={email}
            onChange={(value) => {
              setEmail(value);
            }}
          />
        </Stack>
        <Stack>
          <CTextInput
            title="Notes"
            placeholder="Notes"
            value={contact_notes}
            onChange={(value) => {
              setContactNotes(value);
            }}
          />
        </Stack>
      </Stack>
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            if (!checkValidation()) return false;
            onSaveHandler({
              responsible: name,
              phone,
              mobile_phone,
              fax,
              email,
              contact_notes,
            });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const PickupDocumentsModal = ({
  title,
  data,
  docType = null,
  extraData,
  onCancelHandler,
  onSaveHandler,
  enablePickup = false,
  producerId = null,
}) => {
  const [type, setType] = useState(null);
  const [url, setUrl] = useState(null);
  const [same_issue_expiration_date, setSameIssue] = useState(false);
  const [same_validity, setSameValidity] = useState(false);

  const [purpose, setPurpose] = useState([]);
  const [issue_date, setIssueDate] = useState(Date.now());
  const [validity, setValidity] = useState("1 month");
  const [file, setFile] = useState(null);

  const [gdrpLinks, setGdrpLinks] = useState(null);
  const [declarationLinks, setDeclarationLinks] = useState(null);
  const [certificationLinks, setCertificationLinks] = useState(null);
  const [contractLinks, setContractLinks] = useState(null);
  const pickupList = useSelector(
    (state) => state.producer.producerPickupPointList
  );
  const orgData = useSelector((state) => state.organizations.profile);
  const dispatch = useDispatch();
  const [currentPickup, setCurrentPickup] = useState(null);
  const resetLinks = () => {
    setGdrpLinks(null);
    setDeclarationLinks(null);
    setCertificationLinks(null);
    setContractLinks(null);
  };
  useEffect(() => {
    setType(docType);
  }, [docType]);
  useEffect(() => {
    resetLinks();
    if (!enablePickup)
      if (extraData?.documents.length > 0)
        for (let document of extraData?.documents) {
          const doc_type = document.type;
          const url = document.url;
          const id = document._id;
          switch (doc_type) {
            case "Contract":
              setContractLinks({ url, id });
              break;
            case "GDRP":
              setGdrpLinks({ url, id });
              break;
            case "Declaration":
              setDeclarationLinks({ url, id });
              break;
            case "Certification":
              setCertificationLinks({ url, id });
              break;
            default:
              break;
          }
        }
  }, [enablePickup, extraData]);
  useEffect(() => {
    if (pickupList.length > 0)
      setCurrentPickup({
        name: pickupList[0].name,
        value: pickupList[0]._id,
      });
  }, [pickupList]);

  useEffect(() => {
    if (producerId) {
      dispatch(fetchProducerPickupList(producerId));
      setCurrentPickup(null);
    }
  }, [dispatch, producerId]);
  return type !== null ? (
    <Stack sx={classes.popup}>
      <PageTitle>Add document</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack>
        {enablePickup ? (
          <CAutoComplete
            title="Collection Point"
            required
            value={currentPickup}
            onChange={(value) => {
              setCurrentPickup(value);
            }}
            options={pickupList.map((pickup, index) => {
              return {
                name: pickup.name,
                value: pickup._id,
              };
            })}
          />
        ) : (
          <CAutoComplete
            title="Collection Point"
            required
            disabled
            placeholder={extraData?.name}
          />
        )}
      </Stack>
      <Stack width={"60%"}>
        <CAutoComplete
          title="Select document"
          fixed
          required
          value={type}
          onChange={(e) => {
            setType(e);
          }}
          options={["GDRP", "Declaration", "Certification", "Contract"]}
        />
      </Stack>
      <Stack>
        <InputTitle>Upload document</InputTitle>
        <Stack direction={"row"} alignItems={"center"}>
          <Stack width={"60%"}>
            <TextField
              value={file?.name}
              placeholder="No document"
              size="small"
            />
          </Stack>
          <Stack width={"40%"} padding={1}>
            <Button component="label" sx={classes.buttonUpload}>
              <BackupOutlinedIcon sx={{ color: "text.accent" }} />
              &nbsp;Upload
              <input
                accept=".pdf"
                type="file"
                id="upload-file"
                hidden
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  dispatch(setUploadSpinnerStatus(true));
                  FileService.uploadFile(e.target.files[0])
                    .then((res) => {
                      if (res.data.message === SUCCESS) {
                        setUrl(res.data.filename);
                        showNotification("File uploaded", "success");
                        dispatch(setUploadSpinnerStatus(false));
                      }
                    })
                    .catch((err) => {
                      showNotification(
                        err.response
                          ? ERROR[err.response.data?.message] || "Network Error"
                          : err.message,
                        "error"
                      );
                      dispatch(setUploadSpinnerStatus(false));
                    });
                }}
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack>
        <CRadioSelect
          title="Issue date"
          required
          direction="column"
          value={
            same_issue_expiration_date ? "Day of expiration date" : "Set date"
          }
          onChange={(value) => {
            setSameIssue(value === "Day of expiration date");
          }}
          options={["Set date", "Day of expiration date"]}
          radioRawDirection={false}
          hasComponent
          components={[
            <CDateInput
              value={dayjs(issue_date)}
              onChange={(data) => {
                setIssueDate(
                  getServerTimestamp(new Date(data).getTime(), orgData.timezone)
                );
              }}
            />,
            <></>,
          ]}
        />
      </Stack>
      <Divider />
      <Stack>
        <CRadioSelect
          title="Validity"
          required
          direction="column"
          options={["Set period", "Same validity"]}
          onChange={(value) => {
            setSameValidity(value === "Same validity");
          }}
          value={same_validity ? "Same validity" : "Set period"}
          radioRawDirection={false}
          hasComponent
          components={[
            <CSelect
              label=""
              value={validity}
              onSelectChange={(value) => {
                setValidity(value);
              }}
              options={["1 month", "2 months", "3 months", "6 months"]}
            />,
            <></>,
          ]}
        />
      </Stack>
      <Divider />
      <Stack>
        <CheckBoxes
          title="What do you intend to do with it?"
          options={["Send by email", "Print"]}
          selectedOptions={purpose}
          onChange={(option, selected) => {
            if (selected) {
              setPurpose([...purpose, option]);
            } else {
              let temp = [...purpose];
              temp.splice(purpose.indexOf(option), 1);
              setPurpose(temp);
            }
          }}
        />
      </Stack>
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            setType(null);
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            if (enablePickup) {
              onSaveHandler(currentPickup?.value, {
                type,
                url,
                same_issue_expiration_date,
                same_validity,
                purpose,
                issue_date,
                validity,
              });
            } else
              onSaveHandler({
                type,
                url,
                same_issue_expiration_date,
                same_validity,
                purpose,
                issue_date,
                validity,
              });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Stack sx={classes.popup}>
      <PageTitle>Documents</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack>
        <InputTitle>GDRP</InputTitle>
        <Stack direction={"row"} alignItems={"center"}>
          <Stack width={"70%"}>
            <OutlinedInput
              value={getFileName(gdrpLinks?.url)}
              placeholder="No document"
              size="small"
              disabled
              endAdornment={
                gdrpLinks !== null && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        dispatch(
                          deleteDocumentForPickup(extraData?._id, gdrpLinks.id)
                        );
                      }}
                    >
                      <img src={remove} alt="attachment" />
                    </IconButton>
                  </InputAdornment>
                )
              }
              startAdornment={
                gdrpLinks !== null && (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => {
                        window.open(gdrpLinks.url, "_blank");
                      }}
                    >
                      <img src={attachment} alt="attachment" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </Stack>
          <Stack width={"30%"} padding={1}>
            <Button
              sx={classes.buttonUpload}
              onClick={() => {
                setType("GDRP");
              }}
            >
              <BackupOutlinedIcon sx={{ color: "text.accent" }} />
              &nbsp;Upload
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <InputTitle>Declaration</InputTitle>
        <Stack direction={"row"} alignItems={"center"}>
          <Stack width={"70%"}>
            <OutlinedInput
              value={getFileName(declarationLinks?.url)}
              placeholder="No document"
              size="small"
              disabled
              endAdornment={
                declarationLinks !== null && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        dispatch(
                          deleteDocumentForPickup(
                            extraData?._id,
                            declarationLinks.id
                          )
                        );
                      }}
                    >
                      <img src={remove} alt="attachment" />
                    </IconButton>
                  </InputAdornment>
                )
              }
              startAdornment={
                declarationLinks !== null && (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => {
                        window.open(declarationLinks.url, "_blank");
                      }}
                    >
                      <img src={attachment} alt="attachment" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </Stack>
          <Stack width={"30%"} padding={1}>
            <Button
              sx={classes.buttonUpload}
              onClick={() => {
                setType("Declaration");
              }}
            >
              <BackupOutlinedIcon sx={{ color: "text.accent" }} />
              &nbsp;Upload
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <InputTitle>Certification</InputTitle>
        <Stack direction={"row"} alignItems={"center"}>
          <Stack width={"70%"}>
            <OutlinedInput
              value={getFileName(certificationLinks?.url)}
              placeholder="No document"
              size="small"
              disabled
              endAdornment={
                certificationLinks !== null && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        dispatch(
                          deleteDocumentForPickup(
                            extraData?._id,
                            certificationLinks.id
                          )
                        );
                      }}
                    >
                      <img src={remove} alt="attachment" />
                    </IconButton>
                  </InputAdornment>
                )
              }
              startAdornment={
                certificationLinks !== null && (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => {
                        window.open(certificationLinks.url, "_blank");
                      }}
                    >
                      <img src={attachment} alt="attachment" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </Stack>
          <Stack width={"30%"} padding={1}>
            <Button
              sx={classes.buttonUpload}
              onClick={() => {
                setType("Certification");
              }}
            >
              <BackupOutlinedIcon sx={{ color: "text.accent" }} />
              &nbsp;Upload
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <InputTitle>Contract</InputTitle>
        <Stack direction={"row"} alignItems={"center"}>
          <Stack width={"70%"}>
            <OutlinedInput
              value={getFileName(contractLinks?.url)}
              placeholder="No document"
              size="small"
              disabled
              endAdornment={
                contractLinks !== null && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        dispatch(
                          deleteDocumentForPickup(
                            extraData?._id,
                            contractLinks.id
                          )
                        );
                      }}
                    >
                      <img src={remove} alt="attachment" />
                    </IconButton>
                  </InputAdornment>
                )
              }
              startAdornment={
                contractLinks !== null && (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => {
                        window.open(contractLinks.url, "_blank");
                      }}
                    >
                      <img src={attachment} alt="attachment" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </Stack>
          <Stack width={"30%"} padding={1}>
            <Button
              sx={classes.buttonUpload}
              onClick={() => {
                setType("Contract");
              }}
            >
              <BackupOutlinedIcon sx={{ color: "text.accent" }} />
              &nbsp;Upload
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({
              type,
              url,
              same_issue_expiration_date,
              same_validity,
              purpose,
              issue_date,
              validity,
            });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const PickupResponsibleModal = ({
  title,
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.member);

  const [resp_phone, setResphone] = useState();
  const [resp_mobile_phone, setRespMobilePhone] = useState();
  const [resp_email, setRespEmail] = useState();
  const [userData, setUserData] = useState([]);
  const [validation, setValidataion] = useState({
    email: false,
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const checkValidation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(resp_email)) {
      setValidataion({ email: true });
      return false;
    }
    return true;
  };
  const [name, setName] = useState(null);
  useEffect(() => {
    if (data) {
      setResphone(data?.pickup_point_manager_phone);
      setRespMobilePhone(data?.pickup_point_manager_mobile_phone);
      setRespEmail(data?.pickup_point_manager_email);
      setName(data?.pickup_point_manager_name);
    }
  }, [data]);
  useEffect(() => {
    setUserData(
      users.map((user) => {
        return { name: user.name, value: user._id };
      })
    );
    for (let user of users) {
      if (user.name === data.pickup_point_manager_name) {
        setSelectedUser({
          name: user.name,
          value: user._id,
        });
        break;
      }
    }
  }, [users, data]);
  useEffect(() => {
    dispatch(fetchUserList());
  }, []);

  const onResponsibleUserChange = (v) => {
    setSelectedUser(v);
    const user = users.find((user) => user.name === v.name);
    OrganizationService.getMemberInfo(user._id).then((res) => {
      if (res.data.message === SUCCESS) {
        setResphone(res.data.data.phone || "");
        setRespEmail(res.data.data.email || "");
        setRespMobilePhone(res.data.data.mobile_phone || "");
      }
    });
  };
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>

      <CAutoComplete
        title="Responsible"
        options={userData}
        value={selectedUser}
        placeholder={name}
        fixed
        onInput={(e) => {
          setSelectedUser(null);
          setName(e.target.value);
        }}
        onChange={onResponsibleUserChange}
      />
      <CPhoneInput
        fullWidth
        label="Phone"
        value={resp_phone}
        onChange={(e) => {
          setResphone(e.target.value);
        }}
      />
      <CPhoneInput
        fullWidth
        label="Mobile Phone"
        onChange={(e) => {
          setRespMobilePhone(e.target.value);
        }}
        value={resp_mobile_phone}
      />
      <CTextInput
        error={validation.email}
        title="Email"
        placeholder="mspot@email.com"
        value={resp_email}
        onChange={(value) => {
          setRespEmail(value);
        }}
      />

      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            if (!checkValidation()) return false;
            onSaveHandler({
              pickup_point_manager: selectedUser?.value || name,
              pickup_point_manager_phone: resp_phone,
              pickup_point_manager_email: resp_email,
              pickup_point_manager_mobile_phone: resp_mobile_phone,
            });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
