import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// third party
import { useSnackbar } from "notistack";

// services
import AuthenticationService from "../../../../services/AuthenticationService";
import { showNotification, snackbarRef } from "../../../../utils/helpers";
import InvalidLink from "../InvalidLink/InvalidLink";
import { SUCCESS, NOT_SIGN_IN_USER } from "../../../../utils/type";
import { ERROR } from "../../../../utils/constants";

const AcceptInvitation = () => {
  snackbarRef.current = useSnackbar();
  const location = useLocation();
  const email = new URLSearchParams(location.search).get("email");
  const id = new URLSearchParams(location.search).get("id");
  const org_id = new URLSearchParams(location.search).get("org");
  const navigate = useNavigate();
  const [isValidLink, setIsValidLink] = useState(null);

  useEffect(() => {
    const data = {
      id: id,
      email: email,
    };
    AuthenticationService.verifyAcceptInvitationEmailLink(org_id, data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          AuthenticationService.acceptInvitationFromEmail(org_id, data)
            .then((res) => {
              if (res.data.message === SUCCESS) {
                navigate(`/auth/signin-select-org?email=${email}`);
              } else if (res.data.message === NOT_SIGN_IN_USER) {
                navigate(`/auth/signup?email=${email}`);
              }
            })
            .catch((err) => {
              showNotification(
                err.response
                  ? ERROR[err.response.data.message] || "Network Error"
                  : err.message,
                "error"
              );
            });
          //navigate("/auth/signin-select-org");
        }
      })
      .catch((err) => {
        navigate(`/auth/signup?email=${email}`);
      });
  }, []);

  return (
    <>
      {/* {isValidLink === false && (
        <InvalidLink
          noResend
          resendEmail={() => {
            return AuthenticationService.resendConfirmationEmail({ email });
          }}
          emailNotification={"An email has been sent to validate"}
        />
      )} */}
    </>
  );
};

export default AcceptInvitation;
