import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: {
    name: "John Williams",
    email: "johnwilliams@email.com",
    username: "@johnwilliams-g4yh",
    nationality: "PT",
    mobile: "12354233",
    phone: "1275434",
  },
  role: null,
  pro_profile: {},
  security: {
    last_password_changed: 1697649978876,
    twofa_enabled: false,
  },
  isAuthenticated: null,
};

export const userSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.profile = action.payload;
    },
    setUserRole: (state, action) => {
      state.role = action.payload;
    },
    setUserProProfile: (state, action) => {
      state.pro_profile = action.payload;
    },
    setSecurityInfo: (state, action) => {
      state.security = action.payload;
    },
    logIn: (state, action) => {
      state.isAuthenticated = true;
    },
    logOut: (state, action) => {
      state.isAuthenticated = false;
    },
  },
});

export const {
  logIn,
  logOut,
  setUserProfile,
  setUserProProfile,
  setSecurityInfo,
  setUserRole,
} = userSlice.actions;

export default userSlice.reducer;
