import {
  Box,
  Button,
  Stack,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { PageTitle, SubTitle } from "../../../components/Typography";
import { classes } from "../../../utils/class";
import { useNavigate } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useEffect, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Android12Switch, SearchBar } from "../../../components/CustomInput";
import {
  ADD_ID,
  ARCHIVE_ITEM,
  BADGE_DEFAULT,
  CHANGE_ROLE,
  EDIT_ROW,
  MOVE_MEMBER,
  STATE_ARCHIVE,
  STATE_OFF,
  STATE_ON,
  STATE_PENDING,
  UNARCHIVE_ITEM,
} from "../../../utils/type";
import { Badge, EditDetail, StateSpan } from "../../../components/StateSpan";
import { DataTable } from "../../../components/Table";
import FiltersDialog from "./Dialog/FiltersDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  allowMember,
  allowTeam,
  archiveMember,
  archiveTeam,
  changeRole,
  changeTeamName,
  disallowMember,
  disallowTeam,
  fetchMemberList,
  fetchTeamList,
  moveMember,
  setCurrentTeamData,
  unarchiveTeam,
} from "../../../actions/member";
import ChangeNameDialog from "./Dialog/ChangeTeamNameDialog";
import ChangeRoleDialog from "./Dialog/ChangeRoleDialog";
import MoveMemberDialog from "./Dialog/MoveMemberDialog";
import {
  TabBody,
  Tab,
  Tabs,
  TabHeader,
  TabHeaderItem,
} from "../../../components/Tab";
import DaysOff from "./DaysOff";
import ProfileMainView from "../settings/profile/ProfileMainView";
import IdentificationView from "../settings/profile/IdentificationView";
import ContactsView from "../settings/profile/ContactsView";
import { BACKOFFICE, OWNER } from "../../../utils/constants";

const ViewMemberPage = ({ user, back = () => {} }) => {
  const [stage, setStage] = useState(0);

  return (
    <>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <PageTitle>{user?.name}</PageTitle>
        <Button onClick={back} sx={classes.buttonBack}>
          Back to list
        </Button>
      </Stack>
      <Tabs onTabChangeHandler={() => {}}>
        <TabHeader>
          <TabHeaderItem>Profile</TabHeaderItem>
          <TabHeaderItem>Days off</TabHeaderItem>
        </TabHeader>
        <></>
        <TabBody>
          <Tab>
            {stage === 0 && <ProfileMainView user={user} setStage={setStage} />}
            {stage === 1 && (
              <IdentificationView user={user} setStage={setStage} />
            )}
            {stage === 2 && <ContactsView user={user} setStage={setStage} />}
          </Tab>

          <Tab>
            <DaysOff currentUserId={user?.user_id} />
          </Tab>
        </TabBody>
      </Tabs>
    </>
  );
};

const MemberListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teamList, members } = useSelector((state) => {
    return state.member;
  });
  const role = useSelector((state) => state.user.role);

  const [viewMember, setViewMember] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [anchorEL, setAnchorEL] = useState(null);
  const [open, setOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [changeNameDialog, setChangeNameDialog] = useState(false);
  const [currentTeam, setCurrentTeam] = useState();
  const [currentTeamName, setCurrentTeamName] = useState();
  const [rows, setRows] = useState([]);
  const [teamMember, setTeamMember] = useState({});
  const [currentUser, setCurrentUser] = useState(null);

  const [filter, setFilter] = useState({
    member: {},
    team: {},
    role: {},
  });

  const [changeRoleDlg, setChangeRoleDlg] = useState(false);
  const [moveMemberDlg, setMoveMemberDlg] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const filterTeam = (team) => {
    if (
      (filter.team["Status On"] &&
        !filter.team["Status Off"] &&
        team.status === STATE_OFF) ||
      (!filter.team["Status On"] &&
        filter.team["Status Off"] &&
        team.status === STATE_ON)
    )
      return false;
    if (
      (filter.team["Internal Team"] &&
        !filter.team["External Team"] &&
        team.type !== "internal") ||
      (!filter.team["Internal Team"] &&
        filter.team["External Team"] &&
        team.type !== "external")
    )
      return false;
    return true;
  };
  const filtered = (row) => {
    if (
      (filter.member["Status On"] &&
        !filter.member["Status Off"] &&
        row.allowed === false) ||
      (!filter.member["Status On"] &&
        filter.member["Status Off"] &&
        row.allowed === true)
    )
      return false;
    const keys = Object.keys(filter.role);
    if (keys.length === 0) return true;
    if (filter.member["Pending Invite"] && row.status === STATE_PENDING)
      return true;
    if (filter.role["Back Office Admin"] && row.role === "backoffice_admin")
      return true;
    if (filter.role["Sales Manager"] && row.role === "sales_manager")
      return true;
    if (filter.role["Sales Person"] && row.role === "sales_person") return true;
    if (filter.role["Warehouse Manager"] && row.role === "warehouse_manager")
      return true;
    if (filter.role["Driver"] && row.role === "driver") return true;
    if (filter.role["Team Manager"] && row.role === "team_manager") return true;
    if (
      !filter.role["Back Office Admin"] &&
      !filter.role["Sales Manager"] &&
      !filter.role["Sales Person"] &&
      !filter.role["Warehouse Manager"] &&
      !filter.role["Driver"] &&
      !filter.role["Team Manager"]
    )
      return true;
    return false;
  };
  const columns = [
    {
      width: 400,
      field: "name",
      headerName: "Name",
      renderCell: (props) => {
        return (
          <>
            <Stack
              paddingLeft={2}
              flexDirection={"row"}
              width={"100%"}
              alignItems={"center"}
            >
              <Stack>
                <Stack>
                  <Stack
                    sx={{
                      fontSize: "14px",
                      // fontWeight: 700,
                    }}
                  >
                    {props.row.name || props.row.email}
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  sx={{
                    fontSize: "10px",
                    fontWeight: 400,
                    color: "text.accent",
                  }}
                >
                  {props.row.username && (
                    <Stack sx={{ textDecoration: "underline" }}>
                      @{props.row.username}
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack>
                <Button
                  onClick={(e) => {
                    if (props.row.status === "pending")
                      navigate(`/reinvitation/${props.row.id}`);
                  }}
                >
                  <StateSpan enablePropagation status={props.row.status} />
                </Button>
              </Stack>
            </Stack>
          </>
        );
      },
    },
    {
      width: 300,
      field: "role_name",
      headerName: "Role",
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      width: 300,
      field: "team",
      headerName: "Team",
      renderCell: (props) => {
        return (
          <>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Stack>
                <Stack>
                  <Stack
                    sx={{
                      fontSize: "14px",
                      // fontWeight: 500,
                    }}
                  >
                    {props.row.team_name}
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  sx={{
                    fontSize: "10px",
                    fontWeight: 400,
                    color: "text.accent",
                  }}
                >
                  <Stack>{props.row.teamDetail}</Stack>
                </Stack>
              </Stack>
              {props.row.default && <Badge status={BADGE_DEFAULT} />}
            </Stack>
          </>
        );
      },
    },
    {
      width: 100,
      field: "status",
      headerName: "Status",
      renderCell: (props) => {
        return (
          <>
            <Android12Switch
              checked={props.row.allowed}
              onChange={(e) => {
                if (e.target.checked) dispatch(allowMember(props.row.id));
                else dispatch(disallowMember(props.row.id));
              }}
            />
            <EditDetail
              onStateChangeHandler={(state) => {
                setCurrentUser(props.row);
                if (state === EDIT_ROW) {
                  // navigate("/settings/profile");
                  setViewMember(true);
                }
                if (role === OWNER || role === BACKOFFICE) {
                  if (state === CHANGE_ROLE) {
                    setChangeRoleDlg(true);
                  }
                  if (state === MOVE_MEMBER) {
                    setMoveMemberDlg(true);
                  }
                  if (state === ARCHIVE_ITEM) {
                    dispatch(archiveMember(props.row.user_id));
                  }
                }
              }}
              options={
                props.row.status === "accepted"
                  ? [
                      { label: "View member", value: EDIT_ROW },
                      { label: "Change member role", value: CHANGE_ROLE },
                      { label: "Move member to", value: MOVE_MEMBER },
                      { label: "Archive member", value: ARCHIVE_ITEM },
                    ]
                  : [
                      { label: "Change member role", value: CHANGE_ROLE },
                      { label: "Move member to", value: MOVE_MEMBER },
                    ]
              }
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const memberCnt = {};
    const tempRows = members.map((member, index) => {
      if (member.team_name) memberCnt[member.team_name] = true;
      return {
        ...member,
      };
    });
    setTeamMember(memberCnt);
    setRows(tempRows);
  }, [members, teamList]);

  useEffect(() => {
    dispatch(fetchTeamList());
    dispatch(fetchMemberList());
  }, [dispatch]);

  if (members.length === 0)
    return (
      <>
        <Box sx={{ minHeight: "100vh", height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              paddingY: "30px",
            }}
          >
            <PageTitle>Member</PageTitle>
            <Button
              sx={classes.buttonPrimary}
              onClick={() => {
                navigate("/add-member");
              }}
              disabled={role !== BACKOFFICE && role !== OWNER}
            >
              add member
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80%",
            }}
          >
            <PersonOutlineIcon sx={{ color: "text.tertiary", fontSize: 100 }} />
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Manrope",
                fontSize: "10px",
                color: "text.primary",
                marginBottom: "30px",
              }}
            >
              There are no members listed
            </Typography>
            <Button
              size="large"
              sx={classes.buttonPrimary}
              onClick={() => {
                navigate("/add-member");
              }}
              disabled={role !== BACKOFFICE && role !== OWNER}
            >
              Add member
            </Button>
          </Box>
        </Box>
      </>
    );
  else
    return (
      <>
        {viewMember ? (
          <ViewMemberPage
            user={currentUser}
            back={() => {
              setViewMember(false);
            }}
          />
        ) : (
          <Stack direction={"column"} gap={2}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <PageTitle>Members</PageTitle>
              <Stack justifyContent={"center"} alignItems={"center"}>
                <Stack>
                  <Button
                    variant="outlined"
                    sx={classes.buttonPrimary}
                    onClick={(e) => {
                      navigate("/add-member");
                    }}
                    disabled={role !== BACKOFFICE && role !== OWNER}
                  >
                    Invite Member
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              marginTop={2}
              marginBottom={2}
            >
              <SearchBar
                onSearchChange={(value) => {
                  setFilterModel({
                    items: [
                      {
                        field: "name",
                        operator: "contains",
                        value: value,
                      },
                    ],
                  });
                }}
                placeholder="Search"
                sx={{ width: "275px" }}
              />
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Button
                  sx={classes.buttonPrimary}
                  onClick={() => {
                    setFilterModal(true);
                  }}
                >
                  <FilterAltOutlinedIcon />
                  Filter
                </Button>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Switch
                    checked={filter?.team["Empty teams"] !== true}
                    onChange={(e) => {
                      const t = { ...filter.team };
                      t["Empty teams"] = !e.target.checked;
                      setFilter({
                        ...filter,
                        team: t,
                      });
                    }}
                  />
                  Hide empty teams
                </Stack>
              </Stack>
            </Stack>

            <Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                  backgroundColor: "background.light",
                }}
                padding={4}
              >
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <SubTitle>No Team</SubTitle>
                </Stack>
              </Stack>
              <Stack>
                <DataTable
                  fit="fit-screen"
                  filterModel={filterModel}
                  pagination={false}
                  rows={rows.filter((row) => {
                    if (row.team_name === undefined) {
                      return filtered(row);
                    }
                    return false;
                  })}
                  columns={columns}
                />
              </Stack>
            </Stack>
            {teamList.map((team, index) => {
              return (
                (filter.team["Empty teams"] === true ||
                  teamMember[team.name]) &&
                filterTeam(team) && (
                  <Stack key={index}>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      sx={{
                        backgroundColor: team.archived
                          ? "background.light"
                          : "background.light",
                      }}
                      padding={4}
                    >
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <SubTitle
                          sx={{
                            color: team.archived
                              ? "text.accent"
                              : "text.primary",
                          }}
                        >
                          {team.name}
                        </SubTitle>
                        {team.type === "internal" ? (
                          <Typography
                            sx={{
                              backgroundColor: team.archived
                                ? "background.stone"
                                : "text.accent",
                              color: "text.primary",
                              padding: "2px",
                            }}
                            fontSize={10}
                          >
                            {team.type}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              backgroundColor: team.archived
                                ? "text.accent"
                                : "background.secondary",
                              color: "text.contrast",
                              padding: "2px",
                            }}
                            fontSize={10}
                          >
                            {team.type}
                          </Typography>
                        )}
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        {team.archived && <StateSpan status={STATE_ARCHIVE} />}
                        <Android12Switch
                          checked={team.allowed === true}
                          onChange={(e) => {
                            if (e.target.checked === true)
                              dispatch(allowTeam(team._id));
                            else dispatch(disallowTeam(team._id));
                          }}
                        />
                        {role !== BACKOFFICE && role !== OWNER ? (
                          <></>
                        ) : (
                          <EditDetail
                            onStateChangeHandler={(state) => {
                              if (state === EDIT_ROW) {
                                setCurrentTeamName(team.name);
                                setCurrentTeam(team._id);
                                setChangeNameDialog(true);
                              } else if (state === ARCHIVE_ITEM) {
                                dispatch(archiveTeam(team._id));
                              } else if (state === UNARCHIVE_ITEM) {
                                dispatch(unarchiveTeam(team._id));
                              } else if (state === ADD_ID) {
                                dispatch(setCurrentTeamData(team));
                                navigate("/add-member");
                              }
                            }}
                            options={
                              team.archived
                                ? [
                                    {
                                      label: "Unarchive team",
                                      value: UNARCHIVE_ITEM,
                                    },
                                    {
                                      label: "Change team name",
                                      value: EDIT_ROW,
                                    },
                                  ]
                                : [
                                    {
                                      label: "Archive team",
                                      value: ARCHIVE_ITEM,
                                    },
                                    {
                                      label: "Change team name",
                                      value: EDIT_ROW,
                                    },
                                    {
                                      label: "Add member",
                                      value: ADD_ID,
                                    },
                                  ]
                            }
                          />
                        )}
                      </Stack>
                    </Stack>
                    <Stack position={"relative"}>
                      {team.archived && (
                        <Stack
                          zIndex={10}
                          position={"absolute"}
                          sx={{
                            backgroundColor: "text.contrast",
                            opacity: 0.8,
                          }}
                          width={"100%"}
                          height={"100%"}
                        />
                      )}
                      <DataTable
                        fit="fit-screen"
                        filterModel={filterModel}
                        pagination={false}
                        rows={rows.filter(
                          (row) => row.team_name === team.name && filtered(row)
                        )}
                        columns={columns}
                      />
                    </Stack>
                  </Stack>
                )
              );
            })}

            <FiltersDialog
              open={filterModal}
              handleClose={() => {
                setFilterModal(false);
              }}
              handleContinue={(data) => {
                setFilter({
                  ...data,
                });
              }}
              selectedOptions={filter}
            />
            <ChangeNameDialog
              teamName={currentTeamName}
              open={changeNameDialog}
              handleClose={() => {
                setChangeNameDialog(false);
              }}
              handleContinue={(name) => {
                dispatch(changeTeamName(currentTeam, { name }));
              }}
            />
            <ChangeRoleDialog
              role={currentUser?.role}
              roleName={currentUser?.role_name}
              open={changeRoleDlg}
              handleClose={() => {
                setChangeRoleDlg(false);
              }}
              handleContinue={(role) => {
                dispatch(changeRole(currentUser?.id, role));
              }}
            />
            <MoveMemberDialog
              currentTeam={currentUser?.team}
              currentTeamName={currentUser?.team_name}
              open={moveMemberDlg}
              handleClose={() => {
                setMoveMemberDlg(false);
              }}
              handleContinue={(team) => {
                dispatch(moveMember(currentUser?.id, team));
              }}
            />
          </Stack>
        )}
      </>
    );
};
export default MemberListPage;
