import { auth_axios } from "../utils/axios";

const signup = (data) => {
  return auth_axios.post("/auth/signup", data);
};

const signin = (data) => {
  return auth_axios.post("auth/signin", data);
};

const signout = () => {
  return auth_axios.post("auth/logout");
};

const resetPasswordRequest = (data) => {
  return auth_axios.post("/auth/reset-password-request", data);
};
const resendResetPasswordRequest = (data) => {
  return auth_axios.post("/auth/resend-reset-password-mail", data);
};

const send2faMail = (data) => {
  return auth_axios.post("/2fa/send-2fa-mail", data);
};
const resendConfirmationEmail = (data) => {
  return auth_axios.post("/auth/resend-verification-mail", data);
};
const resend2faCode = (data) => {
  return auth_axios.post("/2fa/resend", data);
};

const confirm2faSignin = (data) => {
  return auth_axios.post("/2fa/confirm-sign-in", data);
};

const verifyUserEmail = (data) => {
  return auth_axios.post("/auth/verify-email", data);
};
const verifyEmailLink = (data) => {
  return auth_axios.post("/auth/verify-email-link", data);
};
const confirmEnable2fa = (data) => {
  return auth_axios.post("/2fa/confirm-enable-twofa", data);
};

const confirmResetPassword = (data) => {
  return auth_axios.post("/2fa/confirm-reset-password", data);
};

const resetPassword = (data) => {
  return auth_axios.post("/auth/reset-password", data);
};

const checkIfUserVerified = (email) => {
  return auth_axios.post("/auth/check-user-verification", {
    email: email,
  });
};
const checkIf2faEnabled = (email) => {
  return auth_axios.post("/auth/check-2fa-enabled", {
    email: email,
  });
};

const verifyPasswordResetLink = (data) => {
  return auth_axios.post("/auth/verify-reset-password-link", data);
};

const verifyAcceptInvitationEmailLink = (org_id, data) => {
  return auth_axios.post(`/auth/verify-accept-invitation-link/${org_id}`, data);
};

const acceptInvitationFromEmail = (org_id, data) => {
  return auth_axios.put(`/auth/accept-invite/${org_id}`, data);
};

const rejectInvite = (org_id) => {
  return auth_axios.put(`/orgs/reject-invite/${org_id}`);
};
const acceptInvite = (org_id) => {
  return auth_axios.put(`/auth/accept-invite/${org_id}`);
};
const updateVisitSetting = (data) => {
  return auth_axios.put(`/orgs/update-visit-setting`, data);
};
const getVisitSetting = () => {
  return auth_axios.get(`/orgs/get-visit-setting`);
};
const blockOrganization = () => {
  return auth_axios.put(`/orgs/block-organization`);
};
const unblockOrganization = () => {
  return auth_axios.put(`/orgs/unblock-organization`);
};
const AuthenticationService = {
  verifyAcceptInvitationEmailLink,
  acceptInvitationFromEmail,
  rejectInvite,
  acceptInvite,
  verifyEmailLink,
  checkIfUserVerified,
  checkIf2faEnabled,
  confirmResetPassword,
  signup,
  signin,
  signout,
  resetPasswordRequest,
  send2faMail,
  confirm2faSignin,
  resend2faCode,
  verifyUserEmail,
  confirmEnable2fa,
  resendConfirmationEmail,
  verifyPasswordResetLink,
  resendResetPasswordRequest,
  resetPassword,
  updateVisitSetting,
  getVisitSetting,
  blockOrganization,
  unblockOrganization,
};

export default AuthenticationService;
