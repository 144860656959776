import { ticket_axios } from "../utils/axios";

const fetchTicketsList = () => {
  return ticket_axios.get("/list-tickets");
};
const createTicket = (data) => {
  return ticket_axios.post("/create-ticket", data);
};
const updateTicket = (id, data) => {
  return ticket_axios.put(`/edit-ticket/${id}`, data);
};
const updateTicketStatus = (id, data) => {
  return ticket_axios.put(`/update-status/${id}`, data);
};

const TicketService = {
  fetchTicketsList,
  createTicket,
  updateTicket,
  updateTicketStatus
};

export default TicketService;
