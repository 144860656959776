import { Button, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { classes } from "../utils/class";
import { useDispatch, useSelector } from "react-redux";
import FileService from "../services/FileService";
import { SUCCESS } from "../utils/type";
import { updateOrganizationInfo } from "../actions/organizations";

export const CImageUpload = ({ setStage }) => {
  const dispatch = useDispatch();
  const [imgPath, setImgPath] = useState(null);

  const company = useSelector((state) => state.organizations.profile);

  useEffect(() => {
    setImgPath(company.profile_url);
  }, [company.profile_url]);

  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    FileService.uploadFile(file)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          setImgPath(res.data.filename);
        }
      })
      .catch((err) => {});
  };

  const onRemovePicture = () => {
    dispatch(
      updateOrganizationInfo(
        {
          profile_url: "",
        },
        () => setStage(0)
      )
    );
  };

  const onSave = () => {
    dispatch(
      updateOrganizationInfo(
        {
          profile_url: imgPath,
        },
        () => setStage(0)
      )
    );
  };

  return (
    <Grid container direction="column" alignItems="center" gap={2}>
      <Grid item>
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #AAAAAA",
            borderRadius: "15px",
            paddingY: "20px",
            paddingX: "20px",
          }}
          gap={1}
        >
          {imgPath && <img width="60%" src={imgPath} />}
          <label htmlFor="contained-button-file">
            <Typography
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "grey",
              }}
            >
              {imgPath ? "Change profile picture" : "Add profile picture"}
              <input
                style={{ display: "none" }}
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleUploadClick}
              />
            </Typography>
          </label>
          {imgPath && (
            <Typography sx={{ cursor: "pointer" }} onClick={onRemovePicture}>
              Remove picture
            </Typography>
          )}
        </Stack>
      </Grid>
      <Stack direction="row">
        <Button
          variant="contained"
          sx={[classes.buttonSave, { marginRight: "10px" }]}
          component="span"
          onClick={onSave}
          disabled={imgPath === company.profile_url}
        >
          Save
        </Button>
        <Button
          variant="contained"
          sx={classes.buttonCancel}
          onClick={() => setStage(0)}
        >
          Cancel
        </Button>
      </Stack>
    </Grid>
  );
};
