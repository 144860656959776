import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

// project imports
import {
  InputTitle,
  PageTitle,
  SubTitle,
} from "../../../components/Typography";

// third party

//sx class definition
import { classes } from "../../../utils/class";
import {
  CAutoComplete,
  CDateInput,
  COfficialIdInput,
  CRadioSelect,
  CTextInput,
} from "../../../components/CustomInput";
import {
  fetchPickupPoints,
  fetchPickupRawWastes,
} from "../../../actions/pickup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllUsers,
  fetchOrganizationInfo,
} from "../../../actions/organizations";
import { fetchAllDrivers, fetchAllVehicles } from "../../../actions/fleet";
import { editVisitScheduled } from "../../../actions/visit";
import { fetchWarehouseList } from "../../../actions/warehouse";
import { showNotification, getServerTimestamp } from "../../../utils/helpers";
import { fetchOperationCodes } from "../../../actions/waste";
import dayjs from "dayjs";
import { setPickupDetail } from "../../../slices/pickupSlice";
import PickupService from "../../../services/PickupService";
import { SUCCESS } from "../../../utils/type";
import WasteService from "../../../services/WasteService";
import { DRIVER, ERROR } from "../../../utils/constants";

const EditScheduledVisitPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(1);
  const [purpose, setPurpose] = useState("Waste pickup");
  const [errorDlgOpen, setErrorDlgOpen] = useState(false);
  const [target, setTarget] = useState(null);
  const [member, setMember] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [date, setDate] = useState(dayjs(new Date()));
  const [origin, setOrigin] = useState(null);
  const [transportVat, setTransportVAT] = useState("");
  const [destinationVat, setDestinationVAT] = useState("");
  const [thisNotes, setThisNotes] = useState("");
  const [officialID, setOfficialID] = useState(null);
  const [rawWaste, setRawWaste] = useState(null);
  const [driver, setDriver] = useState("");
  const [vatNumCountry, setVatNumCountry] = useState(null);
  const [destNumCountry, setDestNumCountry] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  const pickup_points = useSelector((state) => state.pickup.pickupList);
  const raw_wastes = useSelector((state) => state.pickup.rawWastes);
  const vehicles = useSelector((state) => state.fleet.vehicles);
  const users = useSelector((state) => state.organizations.users);
  const warehouses = useSelector((state) => state.warehouse.warehouses);
  const drivers = useSelector((state) => state.fleet.drivers);
  const current_scheduled = useSelector(
    (state) => state.visits.current_scheduled
  );
  const profile = useSelector((state) => state.organizations.profile);
  const orgData = useSelector((state) => state.organizations.profile);
  const role = useSelector((state) => state.user.role);

  useEffect(() => {
    dispatch(fetchPickupPoints());
    dispatch(fetchAllVehicles());
    dispatch(fetchAllUsers());
    dispatch(fetchWarehouseList());
    dispatch(fetchAllDrivers());
    dispatch(fetchOperationCodes());
    dispatch(fetchOrganizationInfo());
  }, [dispatch]);

  useEffect(() => {
    const temp = warehouses.map((pp) => {
      return {
        value: pp.id,
        name: pp.id,
        component: (
          <Stack>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              {pp.id}
            </Typography>
            <Typography sx={{ color: "grey", fontSize: "12px" }}>
              Name: {pp.name}
            </Typography>
          </Stack>
        ),
      };
    });
    setWarehouseOptions(temp);
  }, [warehouses]);

  useEffect(() => {
    if (current_scheduled) return;
    setDate(null);
    setTarget("");
    setOrigin("");
    setMember("");
    setVehicle("");
  }, [purpose, current_scheduled]);

  useEffect(() => {
    if (!origin || origin.length === 0) {
      setPickupDetail({});
      return;
    }
    const data = pickup_points.find(
      (pp) => pp.name === (origin.name || origin)
    );
    if (data) {
      PickupService.fetchPickupPointDetail(data._id).then((res) => {
        if (!current_scheduled.raw_waste_name) {
          setRawWaste("");
        }
      });
      dispatch(fetchPickupRawWastes(data._id));
    }
  }, [origin, pickup_points]);

  useEffect(() => {
    if (profile?.country) {
      if (!current_scheduled?.vehicles[0]?.vat_number_country) {
        setVatNumCountry(profile.country);
      }

      if (!current_scheduled?.destination_warehouse_vat_num_country) {
        setDestNumCountry(profile.country);
      }
    }
  }, [profile, current_scheduled]);

  useEffect(() => {
    if (current_scheduled && warehouseOptions.length > 0) {
      setDate(dayjs(new Date(current_scheduled.date)));
      setThisNotes(current_scheduled.notes);
      setPurpose(
        current_scheduled.purpose === "waste_pickup"
          ? "Waste pickup"
          : "Commercial visit"
      );
      setQuantity(current_scheduled.quantity);
      setVehicle(current_scheduled?.vehicles[0]?.reg_plate || "");
      setVatNumCountry(
        current_scheduled?.vehicles[0]?.vat_number_country ?? ""
      );
      setTransportVAT(current_scheduled?.vehicles[0]?.vat_number ?? "");
      setOrigin(current_scheduled.pickup_point_name);
      setTarget(current_scheduled.pickup_point_name);
      setRawWaste(
        current_scheduled.raw_waste_name ===
          "components not otherwise specified"
          ? ""
          : current_scheduled.raw_waste_name
      );
      setDriver(current_scheduled.driver_name);
      setMember(current_scheduled.driver_name);
      setDestNumCountry(
        current_scheduled.destination_warehouse_vat_num_country
      );
      setDestinationVAT(current_scheduled.destination_warehouse_vat_num);
      setOfficialID(
        warehouseOptions.find(
          (w) => w.value === current_scheduled.destination_warehouse_id
        )
      );
    }
  }, [current_scheduled, warehouseOptions]);

  useEffect(() => {
    if (!rawWaste || !quantity || !officialID || current_scheduled) return;
    WasteService.checkWarehouseAvailable(
      warehouses.find((w) => w.id === officialID || w.id === officialID?.value)
        ._id,
      raw_wastes.find((w) => w.name === rawWaste)._id,
      { quantity }
    )
      .then((res) => {
        if (res.data.message === SUCCESS) {
        }
      })
      .catch((err) => {
        showNotification(
          err.response ? ERROR[err.response.data.message] : err.message,
          "error"
        );
      });
  }, [
    rawWaste,
    quantity,
    officialID,
    current_scheduled,
    raw_wastes,
    warehouses,
  ]);

  useEffect(() => {
    if (!rawWaste || !vehicle || current_scheduled) return;
    WasteService.checkVehicleAvailable(
      vehicles.find((v) => v.reg_plate === vehicle)?._id,
      raw_wastes.find((w) => w.name === rawWaste)?._id,
      {
        quantity: quantity,
      }
    )
      .then((res) => {
        if (res.data.message === SUCCESS) {
        }
      })
      .catch((err) => {
        showNotification(
          err.response ? ERROR[err.response.data.message] : err.message,
          "error"
        );
      });
  }, [rawWaste, vehicle, raw_wastes, vehicles, quantity, current_scheduled]);

  const save = () => {
    try {
      if (role !== DRIVER) {
        if (purpose === "Waste pickup") {
          if (!date || !origin || !rawWaste) {
            showNotification("Please input mandatory fields", "error");
            return;
          }

          dispatch(
            editVisitScheduled(
              current_scheduled._id,
              Object.assign(
                {
                  purpose: "waste_pickup",
                  date: getServerTimestamp(
                    new Date(date).getTime(),
                    orgData.timezone
                  ),
                  pickup_point: pickup_points.find(
                    (pp) => pp.name === (origin?.name || origin)
                  )._id,
                  quantity: parseInt(quantity),
                  notes: thisNotes,
                  raw_waste: raw_wastes.find((w) => w.name === rawWaste)?._id,
                },
                quantity !== 0 &&
                  vehicle && {
                    vehicle: vehicles.find((v) => v.reg_plate === vehicle)?._id,
                  },
                quantity !== 0 &&
                  transportVat && { vehicle_vat_num: transportVat },
                quantity !== 0 &&
                  vatNumCountry && { vehicle_vat_num_country: vatNumCountry },
                quantity !== 0 &&
                  driver && {
                    vehicle_driver: drivers.find((d) => d.name === driver)?._id,
                  },
                quantity !== 0 &&
                  officialID && {
                    destination_warehouse: warehouses.find(
                      (w) => w.id === officialID || w.id === officialID?.value
                    )._id,
                  },
                quantity !== 0 &&
                  destNumCountry && {
                    destination_warehouse_vat_num_country: destNumCountry,
                  },
                quantity !== 0 && {
                  destination_warehouse_vat_num: destinationVat,
                }
              ),
              () => navigate(-1)
            )
          );
        } else {
          if (!date || !target || !member || !vehicle) {
            showNotification("Please input mandatory fields", "error");
            return;
          }
          dispatch(
            editVisitScheduled(
              current_scheduled._id,
              Object.assign({
                purpose: "commercial_visit",
                date: getServerTimestamp(
                  new Date(date).getTime(),
                  orgData.timezone
                ),
                pickup_point: pickup_points.find((pp) => pp.name === target)
                  ._id,
                vehicle_driver: users.find((user) => user.name === member)?._id,
                vehicle: vehicles.find((v) => v.reg_plate === vehicle)?._id,
              }),
              () => navigate(-1)
            )
          );
        }
      }
    } catch (err) {
      showNotification(
        "Something went wrong! Please check information",
        "error"
      );
      console.log(err);
    }
  };
  const cancel = () => {
    navigate(-1);
  };

  return (
    <>
      <Stack gap={10}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <PageTitle>Edit Scheduled Visit</PageTitle>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={cancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonSave}
              onClick={save}
              disabled={role === DRIVER}
            >
              Save
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "36px",
              minWidth: "422px",
              justifyContent: "center",
              marginBottom: "50px",
            }}
          >
            <SubTitle>Identification</SubTitle>
            <CRadioSelect
              title="Purpose"
              required
              direction="column"
              radioRawDirection="true"
              value={purpose}
              onChange={(v) => setPurpose(v)}
              options={["Waste pickup", "Commercial visit"]}
            />
            <CDateInput
              title="Select date"
              value={date}
              onChange={(v) => setDate(v)}
              required
              isVisit
            />

            {purpose === "Waste pickup" ? (
              <>
                <CAutoComplete
                  title="Origin"
                  required
                  placeholder="Select Origin"
                  value={origin}
                  onChange={(v) => {
                    setOrigin(v);
                    setRawWaste("");
                  }}
                  options={pickup_points.map((pp) => {
                    return {
                      value: pp.name,
                      name: pp.name,
                      component: (
                        <Stack>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {pp.name}
                          </Typography>
                          <Typography sx={{ color: "grey", fontSize: "12px" }}>
                            Producer: {pp.producer}
                          </Typography>
                        </Stack>
                      ),
                    };
                  })}
                  hasComponent
                  fixed
                />
                <SubTitle>Collection details</SubTitle>
                <CAutoComplete
                  title="Raw waste"
                  required
                  placeholder="Select Raw Waste"
                  options={raw_wastes.map((waste) => {
                    return {
                      value: waste.rawWasteId,
                      name: waste.name,
                      component: (
                        <Stack>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {waste.code}
                          </Typography>
                          <Typography sx={{ color: "grey", fontSize: "12px" }}>
                            {waste.name}
                          </Typography>
                        </Stack>
                      ),
                    };
                  })}
                  value={rawWaste}
                  onChange={(v) => {
                    setRawWaste(v.name);
                  }}
                  fixed
                />
                <CTextInput
                  title="Quantity"
                  required
                  type="number"
                  value={quantity}
                  onChange={(v) => setQuantity(v)}
                  placeholder="Insert quantity"
                  endIcon={"kg"}
                />
                <>
                  <SubTitle>Transport</SubTitle>
                  <CAutoComplete
                    title="Vehicle license plate"
                    value={vehicle}
                    onChange={(v) => setVehicle(v.name)}
                    placeholder="Insert license plate"
                    options={vehicles.map((vehicle) => {
                      return {
                        value: vehicle.reg_plate,
                        name: vehicle.reg_plate,
                        component: (
                          <Stack>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {vehicle.name}
                            </Typography>
                            <Typography
                              sx={{ color: "grey", fontSize: "14px" }}
                            >
                              Plate: {vehicle.reg_plate}
                            </Typography>
                          </Stack>
                        ),
                      };
                    })}
                    hasComponent
                    fixed
                  />
                  <Stack>
                    <COfficialIdInput
                      label="VAT number"
                      countryCode={vatNumCountry}
                      onCountryChange={(e, v) => setVatNumCountry(v.code)}
                      content="VAT"
                      value={transportVat}
                      onChange={(e) => setTransportVAT(e.target.value)}
                      placeholder="number"
                    />
                  </Stack>
                  <CAutoComplete
                    title="Driver"
                    placeholder="Insert driver name"
                    options={drivers.map((d) => d.name)}
                    value={driver}
                    onChange={(v) => setDriver(v)}
                    fixed
                  />
                  <SubTitle>Destination</SubTitle>
                  <Stack>
                    <CAutoComplete
                      title="Destination Warehouse"
                      placeholder="Warehouse"
                      value={officialID}
                      onChange={(v) => setOfficialID(v)}
                      options={warehouseOptions}
                      isOptionEqualToValue={(option, value) => {
                        return option?.value === value?.value;
                      }}
                      hasComponent
                      fixed
                    />
                  </Stack>
                  <Stack>
                    <InputTitle>Waste destination VAT</InputTitle>
                    <COfficialIdInput
                      label=""
                      countryCode={destNumCountry}
                      onCountryChange={(e, v) => setDestNumCountry(v.code)}
                      content="VAT"
                      value={destinationVat}
                      onChange={(e) => setDestinationVAT(e.target.value)}
                      placeholder="number"
                    />
                  </Stack>
                </>
                <SubTitle>Notes</SubTitle>
                <CTextInput
                  rows={3}
                  placeholder="Insert your notes"
                  title="Notes for this waste pickup"
                  value={thisNotes}
                  onChange={(v) => setThisNotes(v)}
                />
              </>
            ) : (
              <>
                <CAutoComplete
                  title="Target"
                  options={pickup_points.map((pp) => pp.name)}
                  value={target}
                  placeholder="Select Target"
                  onChange={(v) => setTarget(v)}
                  required
                  fixed
                />
                <CAutoComplete
                  title="Member"
                  required
                  options={users}
                  placeholder="Select Member"
                  value={member}
                  onChange={(v) => {
                    setMember(v.name);
                  }}
                  fixed
                />
                <CAutoComplete
                  title="Vehicle"
                  value={vehicle}
                  required
                  onChange={(v) => setVehicle(v.name)}
                  placeholder="Select vehicle"
                  options={vehicles.map((vehicle) => {
                    return {
                      value: vehicle.reg_plate,
                      name: vehicle.reg_plate,
                      component: (
                        <Stack>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {vehicle.reg_plate}
                          </Typography>
                          <Typography sx={{ color: "grey", fontSize: "14px" }}>
                            {vehicle.name}
                          </Typography>
                        </Stack>
                      ),
                    };
                  })}
                  hasComponent
                  fixed
                />
              </>
            )}
          </Box>
        </Box>
        <Dialog
          open={errorDlgOpen}
          sx={{ padding: "30px" }}
          onClose={() => setErrorDlgOpen(false)}
        >
          <DialogContent>
            <Stack spacing={3} sx={{ minWidth: "450px" }}>
              <Stack
                direction="row"
                sx={{
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "25px", fontWeight: "bold" }}>
                  Cannot receive the waste pickup
                </Typography>
                <IconButton onClick={() => setErrorDlgOpen(false)}>
                  <Close />
                </IconButton>
              </Stack>
              <Typography>
                The destination warehouse cannot receive the waste that is being
                collected either because the deposit does not exist or because
                it is inactive or inoperable
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              sx={[classes.buttonSave, { margin: "20px", width: "100%" }]}
            >
              Ok, understand
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
};
export default EditScheduledVisitPage;
