import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { classes } from "../../../utils/class";
import { PageTitle } from "../../../components/Typography";
import { DataTable } from "../../../components/Table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import InventoryService from "../../../services/InventoryService";
import PriceChart from "../../../components/PriceChart";
import { getDateStringFromNum } from "../../../utils/helpers";

const PriceHistoryPage = () => {
  const orgData = useSelector((state) => state.organizations.profile);

  const location = useLocation();
  const currentName = new URLSearchParams(location.search).get("name");
  const params = useParams();

  const inventoryId = params.id;
  const [uData, setUdata] = useState([]);
  const [tooltips, setTooltips] = useState([]);
  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (props) => {
        return <>{getDateStringFromNum(props.value, orgData.timezone)}</>;
      },
    },
    { field: "author", headerName: "Author", width: 150 },
    { field: "price", headerName: "Value (€)", width: 150 },
    // {
    //   field: "status",
    //   headerName: "",
    //   width: 100,
    //   renderCell: (props) => {
    //     return (
    //       <IconButton color="primary" aria-label="view the warehouse detail">
    //         <MoreVertIcon />
    //       </IconButton>
    //     );
    //   },
    // },
  ];
  const [rows, setRows] = useState([]);

  useEffect(() => {
    InventoryService.fetchInventoryPriceHistory(inventoryId)
      .then((res) => {
        setRows(
          res.data.data.map((item, index) => {
            return { ...item, id: index };
          })
        );
        setTooltips(
          res.data.data.map((item) => {
            return {
              price: item.price,
              date: item.date,
            };
          })
        );
        setUdata(res.data.data.map((item) => item.price));
      })
      .catch((err) => {});
  }, [inventoryId]);
  return (
    <Stack
      paddingX={"60px"}
      paddingY={"20px"}
      sx={{
        backgroundColor: "white",
        minHeight: "100vh",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between" }}
      >
        <Button
          variant="outlined"
          sx={classes.buttonPrimary}
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </Button>
        <Stack direction="row" sx={{ alignItems: "center" }} gap={4}>
          <Button variant="outlined" sx={classes.buttonPrimary}>
            More options
          </Button>
        </Stack>
      </Stack>
      <Stack direction="row">
        <Stack sx={{ margin: "20px 100px" }} spacing={1}>
          <PageTitle>Price history</PageTitle>
          <Typography sx={{ fontWeight: "bold" }}>{currentName}</Typography>
          <Typography sx={{ width: "300px", fontSize: "16px" }}>
            On this page you can observe the price history of this item.
          </Typography>
        </Stack>

        <Stack spacing={2} width={"100%"}>
          <Stack width={"100%"}>
            <PriceChart data={uData} height="300" toolTips={tooltips} />
          </Stack>
          <Stack>
            <DataTable
              rows={rows}
              columns={columns}
              sx={classes.grid}
              pagination={false}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PriceHistoryPage;
