import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Menu,
  MenuItem,
  Button,
  Stack,
  Box,
  Modal,
  Skeleton,
} from "@mui/material";
import { MoreVertOutlined } from "@mui/icons-material";
import Profile from "./Tab/Profile";
import BalanceHistory from "./Tab/BalanceHistory";
import VisitPage from "./Tab/VisitPage";
import Transfers from "./Tab/Transfers";
import Documents from "./Tab/Documents";
import PickupList from "./Tab/PickupPoint";
import RawWaste from "./Tab/RawWaste";
import AdjustBalance from "./Dialog/AdjustBalance";
import ChangeStatus from "./Dialog/ProducerStatusChangeDialog";
import ChangeStatusArchive from "./Dialog/ProducerStatusChangeUnarchiveDialog";
import {
  Tab,
  TabBody,
  TabHeader,
  TabHeaderItem,
  Tabs,
} from "../../../components/Tab";
import { Item, ItemList } from "../../../components/ItemList";
import { setCurrentProducer } from "../../../slices/producerSlice";
import { PageTitle } from "../../../components/Typography";
import { StateSpan } from "../../../components/StateSpan";
import { CardFleetAddRawWasteModal } from "../../../components/Card";
import { ProducerDocumentsModal } from "../../../components/producer/Modal";
import {
  activateProducer,
  addProducerRawWaste,
  archiveProducer,
  deactivateProducer,
  fetchProducerDetail,
  fetchProducerDocumentList,
  fetchProducerInventoryList,
  fetchProducerOfferList,
  fetchProducerPickupList,
  fetchProducerRawWasteList,
  fetchProducerTransferList,
  fetchProducerVisitList,
  fetchProducers,
  unarchiveProducer,
} from "../../../actions/producers";
import { addDocument } from "../../../actions/pickup";
import Offer from "./Tab/Offer";
import Inventory from "./Tab/Inventory";
import { showNotification } from "../../../utils/helpers";
import { STATE_ARCHIVE } from "../../../utils/type";
import { classes } from "../../../utils/class";
import {
  BACKOFFICE,
  DRIVER,
  OWNER,
  SALES,
  SALES_MANAGER,
  WAREHOUSE_MANAGER,
} from "../../../utils/constants";

const ProducerDetailPage = () => {
  const dispatch = useDispatch();
  const {
    currentProducer,
    producerPickupPointList,
    producerTransferList,
    producerDocumentList,
    producerRawWasteList,
    producerData,
    producerDetail,
    producerVisitList,
    producerInventoryList,
    producerOfferList,
    extraInfo,
  } = useSelector((state) => state.producer);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentProducerId, setCurrentProducerId] = useState(null);
  const [anchorEL, setAnchorEL] = useState(null);
  const [open, setOpen] = useState(false);
  const [activation, setActivation] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [adjustBalance, setAdjustBalance] = useState(false);
  const [addDocumentModal, setAddDocumentModal] = useState(false);
  const [unarchiveModal, setUnarchiveModal] = useState(false);
  const [addRawWasteModal, setAddRawWasteModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const role = useSelector((state) => state.user.role);

  const navigate = useNavigate();

  const onRawWasteAdd = async (waste) => {
    setAddRawWasteModal(false);
    dispatch(
      addProducerRawWaste(currentProducerId, {
        raw_wastes: [waste],
      })
    );
  };

  const getProducerIndex = (id) => {
    for (let i = 0; i < producerData.length; i++) {
      if (producerData[i]._id === id) {
        return i;
      }
    }
    return 0;
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onProducerChange = (producer) => {
    dispatch(setCurrentProducer(producerData[producer]._id));
  };

  const tabChange = (value) => {
    setCurrentTab(value);
    localStorage.setItem("producerTab", value);
  };

  useEffect(() => {
    if (producerData.length > 0)
      setCurrentProducerId(
        producerData[getProducerIndex(currentProducer)]?._id
      );
    else {
      navigate("/producers");
    }
  }, [currentProducer, producerData]);

  useEffect(() => {
    if (currentProducerId) {
      setIsLoaded(false);

      dispatch(fetchProducerDetail(currentProducerId));
      if (currentTab === 2)
        dispatch(fetchProducerPickupList(currentProducerId));
      if (currentTab === 3)
        dispatch(fetchProducerRawWasteList(currentProducerId));
      if (currentTab === 4)
        dispatch(fetchProducerTransferList(currentProducerId));
      if (currentTab === 5) dispatch(fetchProducerVisitList(currentProducerId));
      if (currentTab === 6)
        dispatch(fetchProducerDocumentList(currentProducerId));
      if (currentTab === 7)
        dispatch(fetchProducerInventoryList(currentProducerId));
      if (currentTab === 8) dispatch(fetchProducerOfferList(currentProducerId));
    }
  }, [currentProducerId, dispatch, currentTab]);

  useEffect(() => {
    dispatch(fetchProducers());
    const t = localStorage.getItem("producerTab");
    if (t) setCurrentTab(Number(t));
  }, []);

  useEffect(() => {
    setIsLoaded(true);
  }, [
    producerPickupPointList,
    producerTransferList,
    producerDocumentList,
    producerRawWasteList,
    producerData,
    producerDetail,
    producerVisitList,
    producerInventoryList,
    producerOfferList,
  ]);

  let options = [];
  if (role === OWNER || role === BACKOFFICE) {
    if (producerData[getProducerIndex(currentProducer)]?.archived === true) {
      options = [
        {
          label: "Unarchive",
          value: "unarchive",
        },
      ];
    } else if (
      producerData[getProducerIndex(currentProducer)].status === "active"
    ) {
      options = [
        {
          label: "Inactive",
          value: "inactive",
        },
      ];
    } else if (
      producerData[getProducerIndex(currentProducer)].status === "inactive"
    ) {
      options = [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Archive",
          value: "archive",
        },
      ];
    }
  }

  if (role === WAREHOUSE_MANAGER || role === SALES || role === DRIVER) {
    options = [];
  }

  if (role === SALES_MANAGER) {
    if (producerData[getProducerIndex(currentProducer)].status === "active") {
      options = [
        {
          label: "Inactive",
          value: "inactive",
        },
      ];
    } else if (
      producerData[getProducerIndex(currentProducer)].status === "inactive"
    ) {
      options = [
        {
          label: "Active",
          value: "active",
        },
      ];
    }
  }

  return (
    <>
      <Stack>
        <Stack minHeight={"100vh"} height={"100%"} direction={"row"}>
          <Stack sx={{ backgroundColor: "#F3F1EB", paddingTop: "32px" }}>
            <Box
              sx={{
                ...classes?.buttonBack,
                marginLeft: "24px",
                marginBottom: "30px",
              }}
              onClick={() => {
                navigate("/producers");
              }}
            >
              <ArrowBackIcon fontSize="" sx={{ marginRight: "8px" }} /> Back to
              list
            </Box>
            <ItemList
              value={getProducerIndex(currentProducer)}
              onSelectChange={onProducerChange}
              label="Search Producer"
            >
              {producerData.map((item, index) => {
                return <Item key={index} title={item.name} content={item.id} />;
              })}
            </ItemList>
          </Stack>
          <Stack
            paddingTop={"50px"}
            paddingX={"17px"}
            width={"calc(100% - 250px);"}
            sx={{
              backgroundColor: "white",
              minHeight: "100vh",
            }}
            gap={1}
            flexDirection={"column"}
          >
            <Stack gap={1}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <PageTitle>
                    {producerData[getProducerIndex(currentProducer)]?.name}
                  </PageTitle>
                  <StateSpan
                    disabled={role !== BACKOFFICE && role !== OWNER}
                    options={options}
                    onStateChangeHandler={(state) => {
                      if (
                        state === "active" &&
                        producerData[getProducerIndex(currentProducer)]
                          ?.status !== "active"
                      ) {
                        dispatch(activateProducer(currentProducerId));
                      } else if (
                        state === "inactive" &&
                        producerData[getProducerIndex(currentProducer)]
                          ?.status !== "inactive"
                      ) {
                        setChangeStatusModal(true);
                      } else if (
                        producerData[getProducerIndex(currentProducer)]
                          ?.archived !== true
                      ) {
                        dispatch(archiveProducer(currentProducerId));
                      } else {
                        setUnarchiveModal(true);
                      }
                    }}
                    status={
                      producerData[getProducerIndex(currentProducer)]?.archived
                        ? STATE_ARCHIVE
                        : producerData[getProducerIndex(currentProducer)]
                            ?.status
                    }
                  />
                </Stack>
                <Stack pr={"17px"}>
                  <Button
                    variant="outlined"
                    sx={classes?.buttonMoreOption}
                    onClick={(e) => {
                      setAnchorEL(e.currentTarget);
                      setOpen(!open);
                    }}
                  >
                    Options
                    <MoreVertOutlined />
                  </Button>
                  <Menu
                    sx={classes?.optionMenu}
                    id="basic-menu"
                    open={open}
                    anchorEl={anchorEL}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      horizontal: "center",
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <>
                      {role !== WAREHOUSE_MANAGER && (
                        <MenuItem
                          sx={{
                            minWidth: "228px",
                          }}
                          onClick={() => {
                            navigate("/add-collection");
                          }}
                          disabled={role === WAREHOUSE_MANAGER}
                        >
                          Add collection point
                        </MenuItem>
                      )}
                      <MenuItem
                        sx={{
                          minWidth: "228px",
                        }}
                        onClick={() => {
                          navigate("/add-visit-log");
                        }}
                      >
                        Add visit
                      </MenuItem>
                      {(role === BACKOFFICE ||
                        role === OWNER ||
                        role === SALES_MANAGER) && (
                        <MenuItem
                          sx={{
                            minWidth: "228px",
                          }}
                          onClick={() => {
                            if (producerDetail.count_of_pickup_points !== 0)
                              setAddDocumentModal(true);
                            else {
                              showNotification(
                                "Please add a Collection Point to upload Documents",
                                "error"
                              );
                            }
                            handleClose();
                          }}
                        >
                          Add document
                        </MenuItem>
                      )}
                      {(role === BACKOFFICE ||
                        role === OWNER ||
                        role === SALES_MANAGER) && (
                        <MenuItem
                          sx={{
                            minWidth: "228px",
                          }}
                          onClick={() => {
                            setAddRawWasteModal(true);
                          }}
                        >
                          Add raw wastes
                        </MenuItem>
                      )}
                      {role === BACKOFFICE ||
                        role === OWNER ||
                        (role === SALES_MANAGER && (
                          <MenuItem
                            sx={{
                              minWidth: "228px",
                            }}
                            onClick={() => {
                              setAddRawWasteModal(true);
                            }}
                          >
                            Add Raw Waste
                          </MenuItem>
                        ))}
                      {role !== WAREHOUSE_MANAGER &&
                        role !== SALES &&
                        role !== DRIVER &&
                        producerData[getProducerIndex(currentProducer)]
                          ?.status === "active" && (
                          <MenuItem
                            sx={{
                              minWidth: "228px",
                            }}
                            onClick={() => {
                              setChangeStatusModal(true);
                            }}
                          >
                            Deactivate producer
                          </MenuItem>
                        )}
                      {role !== WAREHOUSE_MANAGER &&
                        role !== SALES &&
                        role !== DRIVER &&
                        producerData[getProducerIndex(currentProducer)]
                          ?.status === "inactive" &&
                        producerData[getProducerIndex(currentProducer)]
                          ?.archived !== true && (
                          <MenuItem
                            sx={{
                              minWidth: "228px",
                            }}
                            onClick={() => {
                              dispatch(activateProducer(currentProducerId));
                            }}
                          >
                            Activate producer
                          </MenuItem>
                        )}
                      {role !== WAREHOUSE_MANAGER &&
                        role !== SALES_MANAGER &&
                        role !== SALES &&
                        role !== DRIVER &&
                        producerData[getProducerIndex(currentProducer)]
                          ?.archived === true && (
                          <MenuItem
                            sx={{
                              minWidth: "228px",
                            }}
                            onClick={() => {
                              setUnarchiveModal(true);
                            }}
                            disabled={role !== BACKOFFICE && role !== OWNER}
                          >
                            Unarchive producer
                          </MenuItem>
                        )}
                      {role !== WAREHOUSE_MANAGER &&
                        role !== SALES_MANAGER &&
                        role !== SALES &&
                        role !== DRIVER &&
                        producerData[getProducerIndex(currentProducer)]
                          ?.status === "inactive" &&
                        producerData[getProducerIndex(currentProducer)]
                          .archived !== true && (
                          <MenuItem
                            sx={{
                              minWidth: "228px",
                            }}
                            onClick={() => {
                              dispatch(archiveProducer(currentProducerId));
                            }}
                            disabled={role !== BACKOFFICE && role !== OWNER}
                          >
                            Archive producer
                          </MenuItem>
                        )}
                    </>
                  </Menu>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Tabs onTabChangeHandler={tabChange} tab={currentTab}>
                <TabHeader>
                  <TabHeaderItem>Profile</TabHeaderItem>
                  <TabHeaderItem>Balance </TabHeaderItem>
                  <TabHeaderItem>Collection points</TabHeaderItem>
                  <TabHeaderItem>Raw wastes</TabHeaderItem>
                  <TabHeaderItem>Transfers</TabHeaderItem>
                  <TabHeaderItem>Visits</TabHeaderItem>
                  <TabHeaderItem>Documents</TabHeaderItem>
                  {/* <TabHeaderItem>Inventory</TabHeaderItem>
                  <TabHeaderItem>Offers</TabHeaderItem> */}
                </TabHeader>
                <></>

                <TabBody>
                  {isLoaded ? (
                    <Tab>
                      <Profile
                        extraInfo={producerDetail}
                        props={producerDetail}
                        activation={activation}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack
                        flexDirection={"row"}
                        gap={2}
                        mt={2}
                        flexWrap={"wrap"}
                      >
                        <Skeleton
                          variant="rounded"
                          width={"300px"}
                          height={"376px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"300px"}
                          height={"200px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"300px"}
                          height={"270px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"300px"}
                          height={"300px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <BalanceHistory
                        extraInfo={extraInfo}
                        props={producerData[getProducerIndex(currentProducer)]}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <PickupList
                        onAdd={() => {
                          navigate("/add-collection");
                        }}
                        id={currentProducerId}
                        extraInfo={producerDetail}
                        props={producerPickupPointList}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <RawWaste
                        onAdd={() => {
                          setAddRawWasteModal(true);
                        }}
                        id={currentProducerId}
                        extraInfo={producerDetail}
                        props={producerRawWasteList}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <Transfers
                        extraInfo={producerDetail}
                        id={currentProducerId}
                        props={producerTransferList}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <VisitPage
                        extraInfo={producerDetail}
                        id={currentProducerId}
                        visitList={producerVisitList}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <Documents
                        extraInfo={extraInfo}
                        props={producerDocumentList}
                        id={currentProducerId}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} my={2}>
                        <Skeleton
                          variant="rounded"
                          width={"200px"}
                          height={"60px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <Inventory
                        extraInfo={producerDetail}
                        id={currentProducerId}
                        inventories={producerInventoryList}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}

                  {isLoaded ? (
                    <Tab>
                      <Offer
                        extraInfo={producerDetail}
                        id={currentProducerId}
                        offers={producerOfferList}
                      />
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                </TabBody>
              </Tabs>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <ChangeStatusArchive
        open={unarchiveModal}
        handleClose={() => {
          setUnarchiveModal(false);
        }}
        handleContinue={() => {
          dispatch(unarchiveProducer(currentProducerId));
          setUnarchiveModal(false);
        }}
      />
      <ChangeStatus
        open={changeStatusModal}
        handleClose={() => {
          setChangeStatusModal(false);
        }}
        handleContinue={() => {
          dispatch(deactivateProducer(currentProducerId));
          setChangeStatusModal(false);
        }}
      />
      <AdjustBalance
        open={adjustBalance}
        handleClose={() => {
          setAdjustBalance(false);
        }}
        handleContinue={() => {
          setAdjustBalance(false);
        }}
      />
      <Modal
        open={addDocumentModal}
        onClose={() => {
          setAddDocumentModal(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ProducerDocumentsModal
          docType={"Contract"}
          extraData={producerDetail}
          data={producerDetail}
          producerId={currentProducerId}
          enablePickup
          onSaveHandler={(id, data) => {
            dispatch(
              addDocument(id, data, () => {
                setAddDocumentModal(false);
                dispatch(fetchProducerDocumentList(currentProducerId));
              })
            );
          }}
          onCancelHandler={() => {
            setAddDocumentModal(false);
          }}
        />
      </Modal>
      <Modal open={addRawWasteModal}>
        <CardFleetAddRawWasteModal
          isCapacity={false}
          onCancelHandler={() => {
            setAddRawWasteModal(false);
          }}
          onSaveHandler={onRawWasteAdd}
        />
      </Modal>
    </>
  );
};
export default ProducerDetailPage;
