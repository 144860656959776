import { Button, Modal, Stack, Typography } from "@mui/material";
import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

const RemoveDepositDialog = ({ open, onClose, onRemove = () => {} }) => {
  const onRemoveDeposit = () => {
    onRemove();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        padding={"34px 42px"}
        gap={3}
        sx={{
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "520px",
        }}
      >
        <PageTitle>Remove capacity</PageTitle>
        <Typography>Are you sure you want to remove this capacity?</Typography>
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={onClose}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={onRemoveDeposit}>
            Remove capacity
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default RemoveDepositDialog;
