import WasteService from "../services/WasteService";
import { setOperationCodes, setRawWastes } from "../slices/wasteSlice";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";

export const fetchAllRawWastes = () => (dispatch) => {
  WasteService.fetchAllRawWastes()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(
          setRawWastes(
            res.data.data.map((row) => {
              return { ...row, id: row._id };
            })
          )
        );
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};

export const fetchOperationCodes = () => (dispatch) => {
  WasteService.fetchOperationCodes()
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(
          setOperationCodes(
            res.data.data.map((row) => {
              return { ...row, id: row._id };
            })
          )
        );
      }
    })
    .catch((err) => {
      showNotification(
        err.response ? err.response.data.message : err.message,
        "error"
      );
    });
};
