import { Button, Stack } from "@mui/material";
import { PageTitle } from "../../../components/Typography";
import { CWholeYear, CYearSelection } from "../../../components/CustomInput";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { classes } from "../../../utils/class";
import DaysOffDialog from "./Dialog/AddDaysOffDialog";
import EditDaysOffDialog from "./Dialog/EditDaysOffDialog";
import RemoveDaysDialog from "./Dialog/RemoveDaysDialog";
import { useState, useEffect } from "react";
import EditDaysOffPop from "./Dialog/EditDaysOffPop";
import { useDispatch, useSelector } from "react-redux";
import {
  addDaysOff,
  deleteDayOff,
  editDayOff,
  fetchDaysOff,
} from "../../../actions/member";
const DaysOff = ({ currentUserId = null }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state.user.profile;
  });
  const userId = currentUserId === null ? user?.user_id : currentUserId;

  const { daysOff } = useSelector((state) => state.member);
  const [daysDialog, setDaysDialog] = useState(false);
  const [daysPop, setDaysPop] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const [removeDialog, setRemoveDialog] = useState(false);
  const [year, setCurrentYear] = useState(new Date().getFullYear());
  const [currentDayOff, setCurrentDayOff] = useState(null);

  useEffect(() => {
    if (userId) dispatch(fetchDaysOff(userId));
  }, [dispatch, userId]);

  return (
    <Stack direction={"column"} marginTop={3} gap={2}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <PageTitle>Days Off</PageTitle>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <CYearSelection
          year={year}
          onYearChange={(value) => {
            setCurrentYear(value);
          }}
        />
        <Button
          sx={classes.buttonPrimary}
          onClick={() => {
            setDaysDialog(true);
          }}
        >
          Add day off &nbsp;&nbsp;&nbsp;
          <AddCircleIcon />
        </Button>
      </Stack>
      {daysOff && (
        <CWholeYear
          year={year}
          data={daysOff}
          onOffDayClicked={(e, data) => {
            setDaysPop(true);
            setAnchor(e.currentTarget);
            setCurrentDayOff(data);
          }}
        />
      )}
      <DaysOffDialog
        open={daysDialog}
        handleClose={() => {
          setDaysDialog(false);
        }}
        handleContinue={(data) => {
          dispatch(
            addDaysOff(userId, data, () => {
              setDaysDialog(false);
            })
          );
        }}
      />
      <EditDaysOffPop
        open={daysPop}
        handleClose={() => {
          setDaysPop(false);
        }}
        onEdit={() => {
          setDaysPop(false);
          setEditDialog(true);
        }}
        onRemove={() => {
          setDaysPop(false);
          setRemoveDialog(true);
        }}
        anchorEl={anchor}
      />
      {currentDayOff && (
        <EditDaysOffDialog
          open={editDialog}
          data={currentDayOff}
          handleClose={() => {
            setEditDialog(false);
          }}
          handleContinue={(id, data) => {
            dispatch(editDayOff(userId, id, data));
          }}
        />
      )}
      <RemoveDaysDialog
        open={removeDialog}
        handleClose={() => {
          setRemoveDialog(false);
        }}
        handleContinue={() => {
          dispatch(deleteDayOff(userId, currentDayOff._id));
        }}
      />
    </Stack>
  );
};
export default DaysOff;
