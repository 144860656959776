import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Modal, Stack } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { CPhoneInput, CTextInput } from "../../../../components/CustomInput";
import { classes } from "../../../../utils/class";
import { updateWarehouseInfo } from "../../../../actions/warehouse";
import CloseIcon from "@mui/icons-material/Close";

const ContactsDialog = ({ open, onClose, info }) => {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.warehouse.current.id);
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [validation, setValidataion] = useState({
    email: false,
  });

  const checkValidation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setValidataion({ email: true });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!checkValidation()) return false;
    dispatch(
      updateWarehouseInfo(
        id,
        {
          phone,
          mobile_phone: mobile,
          fax,
          email,
        },
        () => onClose()
      )
    );
  };

  useEffect(() => {
    setPhone(info.phone);
    setMobile(info.mobile_phone);
    setFax(info.fax);
    setEmail(info.email);
  }, [info]);

  const onChangeEmail = (v) => {
    setValidataion({ email: false });
    setEmail(v);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack padding={"34px 42px"} gap={"22px"} sx={classes.popup}>
        <PageTitle>Contacts</PageTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "42px",
            top: "34px",
          }}
        >
          <CloseIcon />
        </IconButton>
        <CPhoneInput
          label="Phone"
          value={phone}
          fullWidth
          onChange={(e) => setPhone(e.target.value)}
        />
        <CPhoneInput
          label="Mobile Phone"
          fullWidth
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
        <CTextInput title="Fax" value={fax} onChange={(v) => setFax(v)} />
        <CTextInput
          error={validation.email}
          title="Email"
          value={email}
          onChange={onChangeEmail}
        />
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={onClose}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ContactsDialog;
