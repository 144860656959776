import { Box, Button, Stack, Typography } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { CTextInput } from "../../../../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { editRawWaste } from "../../../../actions/organizations";

const EditRawWasteView = ({ setStage }) => {
  const dispatch = useDispatch();
  const curRawWaste = useSelector((state) => state.organizations.curRawWaste);
  const [name, setName] = useState("");

  useEffect(() => {
    setName(curRawWaste.name);
  }, [curRawWaste]);

  const onSave = () => {
    dispatch(editRawWaste(curRawWaste.id, { name }, setStage));
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingX: "20px",
        }}
      >
        <PageTitle>Raw waste</PageTitle>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            sx={classes.buttonCancel}
            onClick={() => setStage(1)}
          >
            Cancel
          </Button>
          <Button variant="outlined" sx={classes.buttonSave} onClick={onSave}>
            Save
          </Button>
        </Stack>
      </Box>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          display: "flex",
          minHeight: "80%",
          marginTop: "40px",
        }}
      >
        <Box sx={{ width: "40%", minWidth: "300px" }}>
          <Typography sx={{ maxWidth: "300px" }}>
            For each raw waste, UCO Network will provide a default name,
            however, each organization can rename that raw waste to the term
            that best applies to their business.
          </Typography>
        </Box>
        <Stack spacing={5} sx={{ width: "100%", maxWidth: "500px" }}>
          <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
            Edit raw waste
          </Typography>
          <CTextInput
            title="Raw waste name"
            placeholder="Óleo virgem extra fula"
            bottomTips="You can edit raw waste here to full-fill your needs."
            value={name}
            onChange={(v) => setName(v)}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditRawWasteView;
