import { useState } from "react";
// material ui
import {
  Box,
  Button,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// proj imports
import {
  InputTitle,
  PageTitle,
  StatusTypography,
} from "../../../../components/Typography";
import {
  CardPrimary,
  InfoCard,
  renderTwoColumnsInfo,
} from "../../../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { getCountryLabelFromCode } from "../../../../utils/helpers";
import { classes } from "../../../../utils/class";
import { MoreVertOutlined } from "@mui/icons-material";
import { StateSpan } from "../../../../components/StateSpan";
import { STATE_ACTIVE, STATE_INACTIVE } from "../../../../utils/type";
import {
  blockOrganization,
  unblockOrganization,
} from "../../../../actions/organizations";
import CustomHidden from "../../../../components/CustomHidden";
import { BACKOFFICE, OWNER } from "../../../../utils/constants";

const CompanyProfileView = ({ setStage }) => {
  const dispatch = useDispatch();
  const [anchorEL, setAnchorEL] = useState(null);
  const [open, setOpen] = useState(false);

  const role = useSelector((state) => state.user.role);

  const company = useSelector((state) => state.organizations.profile);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
          }}
          spacing={3}
        >
          <PageTitle>{company.company_name}</PageTitle>
          <StateSpan
            status={!company.blocked ? STATE_ACTIVE : STATE_INACTIVE}
          />
        </Stack>
        <Box>
          <Button
            variant="outlined"
            sx={classes.buttonMoreOption}
            onClick={(e) => {
              setAnchorEL(e.currentTarget);
              setOpen(!open);
            }}
          >
            Options <MoreVertOutlined />
          </Button>
          <Menu
            sx={classes.optionMenu}
            id="basic-menu"
            open={open}
            anchorEl={anchorEL}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              horizontal: "center",
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              sx={{
                minWidth: "150px",
              }}
              onClick={() => dispatch(unblockOrganization())}
            >
              <Typography>Active</Typography>
            </MenuItem>
            <MenuItem
              sx={{
                minWidth: "150px",
              }}
              onClick={() => dispatch(blockOrganization())}
            >
              <Typography>Inactive</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Stack>
      <Stack direction="row" gap={2} flexWrap={"wrap"}>
        <CardPrimary
          type={1}
          title="Total Collection Points"
          content={company.count_of_pickup_points}
        />
        <CardPrimary
          type={2}
          title="Total Producers"
          content={company.count_of_producers}
        />
        <CardPrimary
          type={2}
          title="Total Vehicles"
          content={company.count_of_vehicles}
        />
        <CardPrimary
          type={2}
          title="Total Warehouses"
          content={company.count_of_warehouses}
        />
      </Stack>

      <CustomHidden min={1760}>
        <Stack direction="row" sx={{ marginTop: "20px" }} spacing={2}>
          <Stack spacing={3}>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Identification"
              onClickEdit={() => setStage(1)}
            >
              {renderTwoColumnsInfo("Company tax name", company.company_name)}
              {renderTwoColumnsInfo(
                "Country",
                getCountryLabelFromCode(company.country || "PT")
              )}
              {renderTwoColumnsInfo("Time Zone", company.timezone)}
              {renderTwoColumnsInfo("VAT number", company.vat_number)}
              {renderTwoColumnsInfo("Official ID", company.official_id)}
              {renderTwoColumnsInfo("UCO ID", company.id)}
            </InfoCard>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="RED II Access"
              onClickEdit={() => setStage(2)}
              isComingSoon
            >
              <Typography sx={{ fontSize: "14px" }}>
                This data is used to connect to APA
              </Typography>
              {renderTwoColumnsInfo("Username", company.red_2_username)}
              {renderTwoColumnsInfo(
                "ID",
                <StatusTypography active={company.red_2_identification}>
                  {company.red_2_identification ? "Active" : "Inactive"}
                </StatusTypography>
              )}
            </InfoCard>
          </Stack>
          <Stack spacing={3}>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Location"
              onClickEdit={() => setStage(3)}
            >
              <Stack gap={1}>
                {renderTwoColumnsInfo(
                  "COORDINATES",
                  company?.latitude
                    ? `${company.latitude.toFixed(
                        5
                      )}, ${company.longitude.toFixed(5)}`
                    : ""
                )}

                {renderTwoColumnsInfo(
                  "Address",
                  `${company.addr_city}  ${company.addr_street}`
                )}
              </Stack>
            </InfoCard>
          </Stack>
          <Stack spacing={3}>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Contacts"
              onClickEdit={() => setStage(4)}
            >
              <Stack gap={1}>
                {renderTwoColumnsInfo(
                  "Phone",
                  company.phone ? `+${company.phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile phone",
                  company.mobile_phone ? `+${company.mobile_phone}` : "-"
                )}
                {renderTwoColumnsInfo("Email", company.email)}
              </Stack>
            </InfoCard>
          </Stack>
          <Stack>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Profile picture"
              onClickEdit={() => setStage(5)}
            >
              <img alt="profile" src={company.profile_url} width={250} />
            </InfoCard>
          </Stack>
        </Stack>
      </CustomHidden>
      <CustomHidden min={1440} max={1759}>
        <Stack direction="row" sx={{ marginTop: "20px" }} spacing={2}>
          <Stack spacing={3}>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Identification"
              onClickEdit={() => setStage(1)}
            >
              {renderTwoColumnsInfo("Company tax name", company.company_name)}
              {renderTwoColumnsInfo(
                "Country",
                getCountryLabelFromCode(company.country || "PT")
              )}
              {renderTwoColumnsInfo("Time Zone", company.timezone)}
              {renderTwoColumnsInfo("VAT number", company.vat_number)}
              {renderTwoColumnsInfo("Official ID", company.official_id)}
              {renderTwoColumnsInfo("UCO ID", company.id)}
            </InfoCard>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="RED II Access"
              onClickEdit={() => setStage(2)}
              isComingSoon
            >
              <Typography sx={{ fontSize: "14px" }}>
                This data is used to connect to APA
              </Typography>
              {renderTwoColumnsInfo("Username", company.red_2_username)}
              {renderTwoColumnsInfo(
                "ID",
                <StatusTypography active={company.red_2_identification}>
                  {company.red_2_identification ? "Active" : "Inactive"}
                </StatusTypography>
              )}
            </InfoCard>
          </Stack>
          <Stack spacing={3}>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Location"
              onClickEdit={() => setStage(3)}
            >
              <Stack gap={1}>
                {renderTwoColumnsInfo(
                  "COORDINATES",
                  company?.latitude
                    ? `${company.latitude.toFixed(
                        5
                      )}, ${company.longitude.toFixed(5)}`
                    : ""
                )}

                {renderTwoColumnsInfo(
                  "Address",
                  `${company.addr_city}  ${company.addr_street}`
                )}
              </Stack>
            </InfoCard>
          </Stack>
          <Stack spacing={3}>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Contacts"
              onClickEdit={() => setStage(4)}
            >
              <Stack gap={1}>
                {renderTwoColumnsInfo(
                  "Phone",
                  company.phone ? `+${company.phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile phone",
                  company.mobile_phone ? `+${company.mobile_phone}` : "-"
                )}
                {renderTwoColumnsInfo("Email", company.email)}
              </Stack>
            </InfoCard>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Profile picture"
              onClickEdit={() => setStage(5)}
            >
              <img alt="profile" src={company.profile_url} width={250} />
            </InfoCard>
          </Stack>
        </Stack>
      </CustomHidden>
      <CustomHidden min={1110} max={1439}>
        <Stack direction="row" sx={{ marginTop: "20px" }} spacing={2}>
          <Stack spacing={3}>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Identification"
              onClickEdit={() => setStage(1)}
            >
              {renderTwoColumnsInfo("Company tax name", company.company_name)}
              {renderTwoColumnsInfo(
                "Country",
                getCountryLabelFromCode(company.country || "PT")
              )}
              {renderTwoColumnsInfo("Time Zone", company.timezone)}
              {renderTwoColumnsInfo("VAT number", company.vat_number)}
              {renderTwoColumnsInfo("Official ID", company.official_id)}
              {renderTwoColumnsInfo("UCO ID", company.id)}
            </InfoCard>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="RED II Access"
              onClickEdit={() => setStage(2)}
              isComingSoon
            >
              <Typography sx={{ fontSize: "14px" }}>
                This data is used to connect to APA
              </Typography>
              {renderTwoColumnsInfo("Username", company.red_2_username)}
              {renderTwoColumnsInfo(
                "ID",
                <StatusTypography active={company.red_2_identification}>
                  {company.red_2_identification ? "Active" : "Inactive"}
                </StatusTypography>
              )}
            </InfoCard>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Contacts"
              onClickEdit={() => setStage(4)}
            >
              <Stack gap={1}>
                {renderTwoColumnsInfo(
                  "Phone",
                  company.phone ? `+${company.phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile phone",
                  company.mobile_phone ? `+${company.mobile_phone}` : "-"
                )}
                {renderTwoColumnsInfo("Email", company.email)}
              </Stack>
            </InfoCard>
          </Stack>
          <Stack spacing={3}>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Location"
              onClickEdit={() => setStage(3)}
            >
              <Stack gap={1}>
                {renderTwoColumnsInfo(
                  "COORDINATES",
                  company?.latitude
                    ? `${company.latitude.toFixed(
                        5
                      )}, ${company.longitude.toFixed(5)}`
                    : ""
                )}

                {renderTwoColumnsInfo(
                  "Address",
                  `${company.addr_city}  ${company.addr_street}`
                )}
              </Stack>
            </InfoCard>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Profile picture"
              onClickEdit={() => setStage(5)}
            >
              <img alt="profile" src={company.profile_url} width={250} />
            </InfoCard>{" "}
          </Stack>
        </Stack>
      </CustomHidden>
      <CustomHidden min={0} max={1109}>
        <Stack direction="row" sx={{ marginTop: "20px" }} spacing={2}>
          <Stack spacing={3}>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Identification"
              onClickEdit={() => setStage(1)}
            >
              {renderTwoColumnsInfo("Company tax name", company.company_name)}
              {renderTwoColumnsInfo(
                "Country",
                getCountryLabelFromCode(company.country || "PT")
              )}
              {renderTwoColumnsInfo("Time Zone", company.timezone)}
              {renderTwoColumnsInfo("VAT number", company.vat_number)}
              {renderTwoColumnsInfo("Official ID", company.official_id)}
              {renderTwoColumnsInfo("UCO ID", company.id)}
            </InfoCard>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="RED II Access"
              onClickEdit={() => setStage(2)}
              isComingSoon
            >
              <Typography sx={{ fontSize: "14px" }}>
                This data is used to connect to APA
              </Typography>
              {renderTwoColumnsInfo("Username", company.red_2_username)}
              {renderTwoColumnsInfo(
                "ID",
                <StatusTypography active={company.red_2_identification}>
                  {company.red_2_identification ? "Active" : "Inactive"}
                </StatusTypography>
              )}
            </InfoCard>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Contacts"
              onClickEdit={() => setStage(4)}
            >
              <Stack gap={1}>
                {renderTwoColumnsInfo(
                  "Phone",
                  company.phone ? `+${company.phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile phone",
                  company.mobile_phone ? `+${company.mobile_phone}` : "-"
                )}
                {renderTwoColumnsInfo("Email", company.email)}
              </Stack>
            </InfoCard>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Location"
              onClickEdit={() => setStage(3)}
            >
              <Stack gap={1}>
                {renderTwoColumnsInfo(
                  "COORDINATES",
                  company?.latitude
                    ? `${company.latitude.toFixed(
                        5
                      )}, ${company.longitude.toFixed(5)}`
                    : ""
                )}

                {renderTwoColumnsInfo(
                  "Address",
                  `${company.addr_city}  ${company.addr_street}`
                )}
              </Stack>
            </InfoCard>
            <InfoCard
              disable={role !== BACKOFFICE && role !== OWNER}
              title="Profile picture"
              onClickEdit={() => setStage(5)}
            >
              <img alt="profile" src={company.profile_url} width={250} />
            </InfoCard>{" "}
          </Stack>
        </Stack>
      </CustomHidden>
    </Box>
  );
};

export default CompanyProfileView;
