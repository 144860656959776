import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Button, Tooltip } from "@mui/material";

import { InputTitle, PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

import { CAutoComplete } from "../../../../components/CustomInput";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTeam, fetchTeamList } from "../../../../actions/member";
import CreateTeamDialog from "./CreateTeamDialog";
const MoveMemberDialog = ({
  open = false,
  currentTeam = null,
  currentTeamName = null,
  handleClose = function () {},
  handleContinue = function () {},
}) => {
  const dispatch = useDispatch();
  const [teamOption, setTeamOption] = useState([]);
  const { teamList } = useSelector((state) => state.member);

  const [team, setTeam] = useState(null);
  const [createDialog, setCreateDialog] = useState(false);

  useEffect(() => {
    if (currentTeam && currentTeamName)
      setTeam({
        name: currentTeamName,
        value: currentTeam,
      });
  }, [currentTeam, currentTeamName]);
  useEffect(() => {
    dispatch(fetchTeamList());
  }, []);
  useEffect(() => {
    const list = teamList.map((team, index) => {
      return {
        ...team,
        value: team._id,
      };
    });
    setTeamOption(list);
  }, [teamList]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} padding={5} width={"420px"}>
            <PageTitle>Move member to</PageTitle>
            <CAutoComplete
              placeholder="Select a team"
              title={
                <Stack direction={"row"} alignItems={"center"}>
                  <InputTitle required>
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            background: "black",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "black",
                          },
                        },
                      }}
                      title="Select team"
                      placement="left"
                    >
                      Team
                    </Tooltip>
                  </InputTitle>
                </Stack>
              }
              onAddNewItem={(value) => {
                setTeam(value);
                setCreateDialog(true);
              }}
              options={teamOption}
              onChange={(value) => {
                setTeam(value);
              }}
              value={team}
            />
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <Button sx={classes.buttonCancel} onClick={handleClose}>
                Discard changes
              </Button>
              <Button
                sx={classes.buttonSave}
                onClick={() => {
                  handleClose();
                  handleContinue(team.value);
                }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <CreateTeamDialog
        open={createDialog}
        team={team}
        handleClose={() => {
          setCreateDialog(false);
        }}
        handleContinue={(data) => {
          dispatch(createTeam(data));
        }}
      />
    </>
  );
};

export default MoveMemberDialog;
