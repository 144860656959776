import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack, Box, IconButton } from "@mui/material";

import { SUCCESS } from "../../../utils/type";
import { showNotification } from "../../../utils/helpers";
import FleetService from "../../../services/FleetService";
import WarehouseService from "../../../services/WarehouseService";
import OrganizationService from "../../../services/OrganizationService";
import ProducerService from "../../../services/ProducerService";

const selectedStyle = {
  fontSize: "16px",
  fontWeight: 600,
  backgroundColor: "#E1ECF4",
  height: "84px",
  borderRadius: "5px",
};

const unselectedStyle = {
  fontSize: "16px",
  fontWeight: 600,
  height: "84px",
  borderRadius: "5px",
  backgroundColor: "#EEECE7",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "background.lighter",
  },
  "&:active": {
    cursor: "pointer",
    backgroundColor: "background.light",
  },
};

const Reviewing = () => {
  const navigate = useNavigate();
  const orgInfo = useSelector((state) => state.organizations.profile);
  const [fleet, setFleet] = useState(false);
  const [warehouse, setWarehouse] = useState(false);
  const [rawWaste, setRawWaste] = useState(false);
  const [producer, setProducer] = useState(false);

  useEffect(() => {
    OrganizationService.fetchWastes()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          if (res.data.data.length > 0) setRawWaste(true);
        }
      })
      .catch((err) => {});
    WarehouseService.getWarehouseList()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          if (res.data.data.length > 0) setWarehouse(true);
        }
      })
      .catch((err) => {});
    FleetService.getListOfVehicle()
      .then((res) => {
        if (res.length > 0) setFleet(true);
      })
      .catch((err) => {});

    ProducerService.fetchProducerList().then((res) => {
      if (res.data.message === SUCCESS) {
        if (res.data.data.data.length > 0) {
          setProducer(true);
        }
      }
    });
  }, [orgInfo]);

  return (
    <>
      <Stack flexDirection={"column"} mx={20} mt={10} mb={10}>
        <Box sx={{ fontSize: "65px", fontWeight: "400" }}>Welcome,</Box>
        <Box sx={{ fontSize: "65px", fontWeight: "600" }}>UCO.Network</Box>
        <Box
          sx={{ fontSize: "18px", fontWeight: "400", lineHeight: "32px" }}
          width={"472px"}
        >
          We are reviewing your data, in the meantime, we highly recommend you
          configure your Organisation. Don't worry, we can help you with this,
          just follow our short tutorial and you will also earn free credits for
          oil collection.
        </Box>

        <Stack mt={5}>
          <Stack
            flexDirection={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Stack
              sx={{
                fontSize: "24px",
                fontWeight: 700,
              }}
              justifyContent={"flex-start"}
            >
              Start your account configuration
            </Stack>
            <Stack
              flexDirection={"row"}
              alignItems={"flex-start"}
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                color: "text.accent",
              }}
            >
              EARNED:
              <Box
                sx={{
                  fontWeight: "500",
                  color: "black",
                }}
              >
                {`${
                  ((fleet & 1) +
                    (warehouse & 1) +
                    (rawWaste & 1) +
                    (producer & 1)) *
                  1000
                } Credits`}
              </Box>
            </Stack>
          </Stack>
          <Stack>
            <Box display={"flex"} flexDirection={"row"} mt={5} py={2} gap={2}>
              <Stack
                flex={1}
                gap={2}
                padding={2}
                justifyContent={"space-between"}
                sx={rawWaste ? selectedStyle : unselectedStyle}
                onClick={() => {
                  if (!rawWaste) navigate("/define/waste");
                }}
              >
                <Box>Define raw waste</Box>
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  flexWrap={"wrap"}
                >
                  <Box sx={{ fontWeight: 400, fontSize: "9px" }}>
                    EARN 1000 credits
                  </Box>
                  <IconButton>
                    {rawWaste ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <rect width="20" height="20" rx="10" fill="green" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.2222 7.84843L13.3528 6.00018L9 10.3038L6.86942 8.22241L5 10.0707L9 14.0002L15.2222 7.84843Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.25628 0.251051C7.59799 -0.0836838 8.15201 -0.0836838 8.49372 0.251051L13.7437 5.39391C13.9078 5.55466 14 5.77267 14 6C14 6.22733 13.9078 6.44535 13.7437 6.60609L8.49372 11.7489C8.15201 12.0837 7.59799 12.0837 7.25628 11.7489C6.91457 11.4142 6.91457 10.8715 7.25628 10.5368L11.0126 6.85714L0.875 6.85714C0.391751 6.85714 0 6.47339 0 6C0 5.52661 0.391751 5.14286 0.875 5.14286L11.0126 5.14286L7.25628 1.46323C6.91457 1.1285 6.91457 0.585786 7.25628 0.251051Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </IconButton>
                </Stack>
              </Stack>
              <Stack
                flex={1}
                gap={2}
                padding={2}
                justifyContent={"space-between"}
                sx={warehouse ? selectedStyle : unselectedStyle}
                onClick={() => {
                  if (!warehouse) {
                    if (rawWaste) navigate("/define/warehouse");
                    else
                      showNotification("You have to define raw waste", "error");
                  }
                }}
              >
                <Box>Set up warehouses</Box>
                <Stack
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  flexWrap={"wrap"}
                >
                  <Box sx={{ fontWeight: 400, fontSize: "9px" }}>
                    EARN 1000 credits
                  </Box>
                  <IconButton>
                    {warehouse ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <rect width="20" height="20" rx="10" fill="green" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.2222 7.84843L13.3528 6.00018L9 10.3038L6.86942 8.22241L5 10.0707L9 14.0002L15.2222 7.84843Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.25628 0.251051C7.59799 -0.0836838 8.15201 -0.0836838 8.49372 0.251051L13.7437 5.39391C13.9078 5.55466 14 5.77267 14 6C14 6.22733 13.9078 6.44535 13.7437 6.60609L8.49372 11.7489C8.15201 12.0837 7.59799 12.0837 7.25628 11.7489C6.91457 11.4142 6.91457 10.8715 7.25628 10.5368L11.0126 6.85714L0.875 6.85714C0.391751 6.85714 0 6.47339 0 6C0 5.52661 0.391751 5.14286 0.875 5.14286L11.0126 5.14286L7.25628 1.46323C6.91457 1.1285 6.91457 0.585786 7.25628 0.251051Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </IconButton>
                </Stack>
              </Stack>
              <Stack
                flex={1}
                gap={2}
                padding={2}
                justifyContent={"space-between"}
                sx={fleet ? selectedStyle : unselectedStyle}
                onClick={() => {
                  if (!fleet) {
                    if (warehouse) navigate("/define/fleet");
                    else
                      showNotification("You have to set up warehouse", "error");
                  }
                }}
              >
                <Box>Set up your fleet</Box>
                <Stack
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  flexWrap={"wrap"}
                >
                  <Box sx={{ fontWeight: 400, fontSize: "9px" }}>
                    EARN 1000 credits
                  </Box>
                  <IconButton>
                    {fleet ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <rect width="20" height="20" rx="10" fill="green" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.2222 7.84843L13.3528 6.00018L9 10.3038L6.86942 8.22241L5 10.0707L9 14.0002L15.2222 7.84843Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.25628 0.251051C7.59799 -0.0836838 8.15201 -0.0836838 8.49372 0.251051L13.7437 5.39391C13.9078 5.55466 14 5.77267 14 6C14 6.22733 13.9078 6.44535 13.7437 6.60609L8.49372 11.7489C8.15201 12.0837 7.59799 12.0837 7.25628 11.7489C6.91457 11.4142 6.91457 10.8715 7.25628 10.5368L11.0126 6.85714L0.875 6.85714C0.391751 6.85714 0 6.47339 0 6C0 5.52661 0.391751 5.14286 0.875 5.14286L11.0126 5.14286L7.25628 1.46323C6.91457 1.1285 6.91457 0.585786 7.25628 0.251051Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </IconButton>
                </Stack>
              </Stack>
              <Stack
                flex={1}
                gap={2}
                padding={2}
                justifyContent={"space-between"}
                sx={producer ? selectedStyle : unselectedStyle}
                onClick={() => {
                  if (!producer) {
                    if (fleet) navigate("/define/producer");
                    else
                      showNotification(
                        "You have to set up your fleet",
                        "error"
                      );
                  }
                }}
              >
                <Box>Set up your producers</Box>
                <Stack
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  flexWrap={"wrap"}
                >
                  <Box sx={{ fontWeight: 400, fontSize: "9px" }}>
                    EARN 1000 credits
                  </Box>
                  <IconButton>
                    {producer ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <rect width="20" height="20" rx="10" fill="green" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.2222 7.84843L13.3528 6.00018L9 10.3038L6.86942 8.22241L5 10.0707L9 14.0002L15.2222 7.84843Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.25628 0.251051C7.59799 -0.0836838 8.15201 -0.0836838 8.49372 0.251051L13.7437 5.39391C13.9078 5.55466 14 5.77267 14 6C14 6.22733 13.9078 6.44535 13.7437 6.60609L8.49372 11.7489C8.15201 12.0837 7.59799 12.0837 7.25628 11.7489C6.91457 11.4142 6.91457 10.8715 7.25628 10.5368L11.0126 6.85714L0.875 6.85714C0.391751 6.85714 0 6.47339 0 6C0 5.52661 0.391751 5.14286 0.875 5.14286L11.0126 5.14286L7.25628 1.46323C6.91457 1.1285 6.91457 0.585786 7.25628 0.251051Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
            <Box
              position={"relative"}
              height={5}
              borderRadius={5}
              width={"100%"}
              sx={{
                backgroundColor: "#EEECE7",
              }}
            >
              <Box
                height={5}
                borderRadius={5}
                width={`${
                  ((fleet & 1) +
                    (warehouse & 1) +
                    (rawWaste & 1) +
                    (producer & 1)) *
                  25
                }%`}
                sx={{
                  backgroundColor: "rgba(0, 25, 90, 1)",
                }}
              ></Box>
            </Box>
            <Stack
              alignItems={"flex-end"}
              sx={{ fontSize: "12px", lineHeight: "24px", fontWeight: "600" }}
              mt={2}
            >
              Your account is &nbsp;
              {`${
                ((fleet & 1) +
                  (warehouse & 1) +
                  (rawWaste & 1) +
                  (producer & 1)) *
                25
              }% configured`}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Reviewing;
