import React, { useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";

import { classes } from "../../../utils/class";

import {
  CRadioSelect,
  CSelect,
  CTextInput,
} from "../../../components/CustomInput";
import { useEffect } from "react";

const EditItemDialog = ({
  itemArray = [],
  genericItemCount = 0,
  open = false,
  handleClose = function () {},
  handleSave = function () {},
}) => {
  const [item_array, setItemArray] = useState([]);
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    if (itemArray.length > 0) {
      setItemArray([itemArray]);
      setQuantity(1);
    } else setQuantity(0);
  }, [itemArray]);

  const getItems = useCallback(() => {
    let result = [];
    for (let i = 0; i < quantity; i++) {
      let temp = [];
      for (let j = 0; j < item_array[i].length; j++) {
        temp.push(
          <Stack direction={"row"} width={"100%"} gap={1}>
            <Stack width={"40%"}>
              <CSelect
                fullWidth
                label={j === 0 ? `Item${i + 1}` : ""}
                value={item_array[i][j].type}
                options={["ID", "RFID", "Sensor ID"]}
                onSelectChange={(value) => {
                  let t = [...item_array];
                  t[i][j].type = value;
                  setItemArray(t);
                }}
              />
            </Stack>
            <Stack width={"60%"} flexDirection={"row"} alignItems={"center"}>
              <CTextInput
                placeholder="ex:93820102931231023"
                title={j === 0 ? "Add identifier" : null}
                titleProps={{
                  fontSize: "12px",
                  textDecorationLine: "underline",
                  textAlign: "right",
                }}
                onTitleClick={() => {
                  let t = [...item_array];
                  t[i].push({ type: "ID", value: "" });
                  setItemArray(t);
                }}
                onChange={(val) => {
                  let temp = [...item_array];
                  temp[i][j].value = val;
                  setItemArray(temp);
                }}
                value={item_array[i][j].value}
              />
              {j !== 0 && (
                <IconButton
                  onClick={() => {
                    let t = [...item_array];
                    t[i].splice(j, 1);
                    setItemArray(t);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.66536 2.66683C4.66536 1.93045 5.26232 1.3335 5.9987 1.3335H9.9987C10.7351 1.3335 11.332 1.93045 11.332 2.66683V4.00016H12.6585C12.6626 4.00012 12.6667 4.00012 12.6709 4.00016H13.9987C14.3669 4.00016 14.6654 4.29864 14.6654 4.66683C14.6654 5.03502 14.3669 5.3335 13.9987 5.3335H13.2861L12.7079 13.4285C12.6581 14.1262 12.0775 14.6668 11.378 14.6668H4.61944C3.91993 14.6668 3.33934 14.1262 3.2895 13.4285L2.71129 5.3335H1.9987C1.63051 5.3335 1.33203 5.03502 1.33203 4.66683C1.33203 4.29864 1.63051 4.00016 1.9987 4.00016H3.32653C3.33066 4.00012 3.33479 4.00012 3.33891 4.00016H4.66536V2.66683ZM5.9987 4.00016H9.9987V2.66683H5.9987V4.00016ZM4.04802 5.3335L4.61944 13.3335H11.378L11.9494 5.3335H4.04802ZM6.66536 6.66683C7.03355 6.66683 7.33203 6.96531 7.33203 7.3335V11.3335C7.33203 11.7017 7.03355 12.0002 6.66536 12.0002C6.29717 12.0002 5.9987 11.7017 5.9987 11.3335V7.3335C5.9987 6.96531 6.29717 6.66683 6.66536 6.66683ZM9.33203 6.66683C9.70022 6.66683 9.9987 6.96531 9.9987 7.3335V11.3335C9.9987 11.7017 9.70022 12.0002 9.33203 12.0002C8.96384 12.0002 8.66536 11.7017 8.66536 11.3335V7.3335C8.66536 6.96531 8.96384 6.66683 9.33203 6.66683Z"
                      fill="black"
                    />
                  </svg>
                </IconButton>
              )}
            </Stack>
          </Stack>
        );
      }
      result.push(temp);
    }

    return result;
  }, [item_array]);

  useEffect(() => {
    let arr = [];
    if (itemArray.length === 0) {
      for (let i = 0; i < quantity; i++) {
        arr.push([{ type: "ID", value: "" }]);
      }
      setItemArray(arr);
    }
  }, [quantity]);

  const items = getItems();

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            padding: "2px",
            minWidth: "530px",
            maxHeight: "80vh", // set max height to 80% of viewport height
            overflowY: "auto", // enable vertical scrolling
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack padding={5} gap={4}>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 700,
              }}
            >
              Edit item
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                textDecorationLine: "underline",
              }}
            >
              You have {genericItemCount} generic items
            </Typography>
            <Stack>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 400,
                  maxWidth: "260px",
                }}
              >
                How many items do you want to make unique?
              </Typography>
              <Stack direction={"row"} alignItems={"center"} gap={3}>
                <CTextInput
                  title="Quantity"
                  type="number"
                  placeholder={quantity}
                  align="center"
                  sx={{
                    width: "100px",
                  }}
                  value={quantity}
                  max={genericItemCount}
                  min={0}
                  onChange={(value) => {
                    setQuantity(value);
                  }}
                />
              </Stack>
            </Stack>

            <Stack>{items}</Stack>
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <Button
                sx={classes.buttonSave}
                onClick={() => {
                  let items = [];
                  for (let item of item_array) {
                    let t = {};
                    for (let subItem of item) {
                      t[subItem.type.toLowerCase()] = subItem.value;
                    }
                    items.push(t);
                  }
                  handleSave({
                    items: items,
                  });
                }}
              >
                Save
              </Button>
              <Button sx={classes.buttonCancel} onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default EditItemDialog;
