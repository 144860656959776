import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  Modal,
  Stack,
  Switch,
  Skeleton,
} from "@mui/material";
import { GridRowEditStopReasons, GridRowModes } from "@mui/x-data-grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MoreVertOutlined } from "@mui/icons-material";
import { useSnackbar } from "notistack";

import { PageTitle } from "../../../components/Typography";
import { EditDetail, StateSpan } from "../../../components/StateSpan";
import { ItemList, Item } from "../../../components/ItemList";
import {
  Tabs,
  Tab,
  TabHeader,
  TabHeaderItem,
  TabBody,
} from "../../../components/Tab";
import {
  CardFleetAddDayoffModal,
  CardFleetAddRawWasteModal,
  CardFleetEditDayoffModal,
  CardFleetEditRawWasteModal,
  CardFleetRegisterKilo,
  CardPrimary,
  CardProfile,
  CardTypes,
} from "../../../components/Card";
import { DataTable } from "../../../components/Table";
import {
  DELETE_ROW,
  EDIT_ROW,
  FLEET_IDENTIFICATION_MODAL,
  FLEET_VEHICLE_DETAIL_MODAL,
  STATE_ACTIVE,
  STATE_ARCHIVE,
  STATE_INACTIVE,
  SUCCESS,
} from "../../../utils/type";
import { classes } from "../../../utils/class";
import {
  DRIVER,
  ERROR,
  SALES,
  SALES_MANAGER,
  WAREHOUSE_MANAGER,
} from "../../../utils/constants";
import { showNotification, getDateStringFromNum } from "../../../utils/helpers";
import speedometer from "../../../assets/images/speedometer 1.svg";
import vector from "../../../assets/images/basic_settings.svg";
import FleetService from "../../../services/FleetService";
import {
  assignRawWaste,
  fetchTransferList,
  fetchCurrentRawWastes,
  unassignRawWaste,
  deleteMaintenanceRecord,
  editMaintenanceRecord,
  editRawWaste,
  archiveRawWaste,
  activateRawWaste,
  deactivateRawWaste,
} from "../../../actions/fleet";

const VehicleDetailPage = () => {
  // edit and delete rows for travelled
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const selectedId = useSelector((state) => state.fleet.selectedFleetId);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [filterMaintenance, setFilterMaintenance] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleDeleteClick = async (id) => {
    const res = await FleetService.deleteTravelledKm(id);
    if (res === SUCCESS) {
      await loadTravelledKilo();
      enqueueSnackbar("Removed Successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    await FleetService.editOdometerAndTravelledKm(newRow.id, {
      odometer: newRow.odometer,
    });
    loadTravelledKilo();
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const navigate = useNavigate();
  //For more option
  const [anchorEL, setAnchorEL] = useState(null);
  const [open, setOpen] = useState(false);

  // add raw waste modal
  const [addRawWasteModal, setAddRawWasteModal] = useState(false);
  const [editRawWasteModal, setEditRawWasteModal] = useState(false);
  const [currentRawWasteRecord, setCurrentRawWasteRecord] = useState(null);
  // current Tab
  const [currentTab, setCurrentTab] = useState(0);
  const [currentVehicle, setCurrentVehicle] = useState(selectedId);
  const [currentVehicleInfo, setCurrentVehicleInfo] = useState(null);
  const [registerKiloModal, setRegisterKiloModal] = useState(false);

  const [editMaintenanceModal, setEditMaintenanceModal] = useState(false);

  const [dayOffModal, setDayOffModal] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [maintenance, setMaintenance] = useState([]);
  const [totalKiloMeters, setTotalKiloMeters] = useState(0);
  const [currentMaintenanceRecord, setCurrentMaintenanceRecord] =
    useState(null);
  // const [rawWastes, setRawWastes] = useState([]);

  const role = useSelector((state) => state.user.role);

  //selector
  const transferList = useSelector((state) => state.fleet.currentTransferList);
  const currentRawWastes = useSelector((state) => state.fleet.currentRawWastes);
  const orgData = useSelector((state) => state.organizations.profile);
  // rows for maintenance
  const onVehicleChange = (vehicleIndex) => {
    setRows([]);
    setIsLoaded(false);
    setCurrentVehicle(vehicleIndex);
  };
  // load info
  const loadDetail = useCallback(async () => {
    const result = await FleetService.getVehicleDetail(
      vehicles[currentVehicle]._id
    );
    setIsLoaded(true);
    if (result != null) {
      setCurrentVehicleInfo({
        ...result,
        id: vehicles[currentVehicle]._id,
        ucoId: result.id,
      });
    }
  }, [currentVehicle, vehicles]);

  const loadList = async () => {
    const result = await FleetService.getListOfVehicle();
    setVehicles(result);
  };

  const loadTravelledKilo = useCallback(async () => {
    const result = await FleetService.getTravelledList(
      vehicles[currentVehicle]._id
    );
    setIsLoaded(true);
    let rows = [];
    result.data.forEach((item, index) => {
      rows.push({
        id: item._id,
        ...item,
        // hours: new Date(item.hours),
        // date: new Date(item.date),
      });
    });
    setTotalKiloMeters(result.travelledKm30DaysBefore);
    setRows(rows);
  }, [currentVehicle, vehicles]);

  const loadMaintenance = useCallback(async () => {
    const res = await FleetService.getMaintenanceRecord(
      vehicles[currentVehicle]._id
    );
    setIsLoaded(true);
    let rows = [];
    res.forEach((item, index) => {
      rows.push({
        ...item,
        id: item._id,
        period: item.from.substring(0, 10) + "~" + item.to.substring(0, 10),
        reason: item.reason,
        status: item.status,
        notes: item?.notes,
      });
    });
    setMaintenance(res);
    setRows(rows);
  }, [currentVehicle, vehicles]);

  useEffect(() => {
    let rows = [];
    currentRawWastes.forEach((item, index) => {
      rows.push({
        ...item,
        id: item._id,
        code: item.code,
        name: item.name,
        racio: item.capacity,
      });
    });
    setRows(rows);
  }, [currentRawWastes]);

  useEffect(() => {
    if (vehicles.length > 0) {
      loadDetail();
      if (currentTab === 4) loadMaintenance();
      if (currentTab === 3) loadTravelledKilo();
      if (currentTab === 1)
        dispatch(fetchCurrentRawWastes(currentVehicleInfo?.id));
      if (currentTab === 2) dispatch(fetchTransferList(currentVehicleInfo?.id));
    }
  }, [
    currentVehicle,
    vehicles,
    loadMaintenance,
    loadDetail,
    loadTravelledKilo,
    currentTab,
    dispatch,
    currentVehicleInfo?.id,
  ]);

  useEffect(() => {
    let rows = [];
    transferList.forEach((item, index) => {
      rows.push({
        ...item,
        id: item._id,
      });
    });
    setRows(rows);
  }, [transferList]);

  useEffect(() => {
    loadList();
  }, [dispatch]);

  //data for waste
  const cols_waste = [
    {
      field: "raw_waste_name",
      headerName: "Raw Waste",
      width: 300,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 350,
      flex: 1,
      renderCell: (props) => {
        return (
          <Stack direction="row" sx={{ alignItems: "center" }} gap={2}>
            <Typography sx={{ width: "80px" }}>
              {props.row.capacity} kg
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (props) => {
        let options = [];
        if (props.row.archived)
          options = [{ label: "Unarchive", value: "unarchive" }];
        if (props?.row?.status === "active") {
          options = [
            {
              label: "Inactive",
              value: STATE_INACTIVE,
            },
          ];
        } else if (props?.row?.status === "inactive") {
          if (props?.row?.archived) {
            options = [
              {
                label: "Unarchive",
                value: "unarchive",
              },
            ];
          } else {
            options = [
              {
                label: "Active",
                value: STATE_ACTIVE,
              },
              {
                label: "Archive",
                value: STATE_ARCHIVE,
              },
            ];
          }
        }
        return (
          <>
            <StateSpan
              disabled={
                role === DRIVER || role === SALES || role === SALES_MANAGER
              }
              status={props.row.archived ? STATE_ARCHIVE : props.row.status}
              options={options}
              onStateChangeHandler={(status) => {
                if (
                  status === STATE_ACTIVE &&
                  props.row.status === STATE_INACTIVE
                ) {
                  // dispatch(activateDeposit(props.row.id, wh_id));
                } else if (
                  status === STATE_INACTIVE &&
                  props.row.status === STATE_ACTIVE
                ) {
                  // setCurrent(props.row.id);
                  // setChangeDlgOpen(true);
                }
                // if (status === STATE_ARCHIVE)
                //   dispatch(archiveDeposit(props.row.id, wh_id));
                // if (status === "unarchive")
                //   dispatch(unarchiveDeposit(props.row.id, wh_id));
              }}
            />
            {role === DRIVER || role === SALES || role === SALES_MANAGER ? (
              <></>
            ) : (
              <EditDetail
                options={[
                  { label: "Edit deposit", value: "edit" },
                  {
                    label: `${
                      props.row.status === STATE_ACTIVE
                        ? "Deactivate"
                        : "Activate"
                    } deposit`,
                    value: "deactivate",
                  },
                  { label: "Remove deposit", value: "remove" },
                ]}
                props={props}
                onStateChangeHandler={async (status) => {
                  setCurrentRawWasteRecord(props.row);
                  if (status === "edit") setEditRawWasteModal(true);
                  if (status === "remove") {
                    dispatch(
                      unassignRawWaste(currentVehicleInfo?.id, {
                        waste: props.row.raw_waste,
                      })
                    );
                  }
                  if (status === "deactivate") {
                    if (props.row.status === "active") {
                      dispatch(
                        deactivateRawWaste(
                          currentVehicleInfo?.id,
                          props.row._id
                        )
                      );
                    } else {
                      dispatch(
                        activateRawWaste(currentVehicleInfo?.id, props.row._id)
                      );
                    }
                  }
                }}
              />
            )}
          </>
        );
      },
    },
  ];

  //data for register kilo
  const cols_register = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
    },
    {
      field: "hours",
      headerName: "Hours",
      width: 150,
      // editable: true,
      // type: "dateTime",
      // renderCell: (props) => {
      //   return <>{props.value}</>;
      // },
    },
    {
      field: "responsible_name",
      headerName: "Responsible",
      width: 200,
      // editable: true,
    },
    {
      field: "odometer",
      headerName: "Odometer",
      width: 200,
      editable: true,
      renderCell: (params) => {
        return <>{params.value} km</>;
      },
    },
    {
      field: "travelledKm",
      headerName: "Kilometres travelled",
      width: 200,
      // editable: true,
      renderCell: (params) => {
        return <>{params.value} km</>;
      },
    },
    {
      field: "actions",
      width: 100,
      renderCell: (props) => {
        const isInEditMode =
          rowModesModel[props.id]?.mode === GridRowModes.Edit;
        if (!isInEditMode)
          return (
            <>
              {role === SALES || role === SALES_MANAGER ? (
                <></>
              ) : (
                <EditDetail
                  props={props}
                  options={[
                    { label: "Edit", value: EDIT_ROW },
                    { label: "Delete", value: DELETE_ROW },
                  ]}
                  onStateChangeHandler={(status) => {
                    if (status === EDIT_ROW) {
                      handleEditClick(props.id);
                    } else if (status === DELETE_ROW) {
                      handleDeleteClick(props.id);
                    }
                    // const id = vehicleRows[props.id]._id;
                    // if (status != vehicleRows[props.id].status) {
                    //   if (status === STATE_ACTIVE) {
                    //     await FleetService.activateVehicle(id);
                    //   }
                    //   else if (status === STATE_INACTIVE) {
                    //     await FleetService.deactivateVehicle(id);
                    //   }
                    //   await loadList();
                    // }
                  }}
                />
              )}
            </>
          );
        else {
          return (
            <>
              <Button
                onClick={() => {
                  handleSaveClick(props.id);
                }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  handleCancelClick(props.id);
                }}
              >
                Cancel
              </Button>
            </>
          );
        }
      },
    },
  ];

  const cols_maintenance = [
    {
      field: "period",
      headerName: "Period",
      width: "200",
    },
    {
      field: "reason",
      headerName: "Reason",
      width: "200",
    },
    {
      field: "notes",
      headerName: "Notes",
      width: "300",
      renderCell: (params) => {
        return (
          <Stack justifyContent={"space-between"} direction={"row"} gap={5}>
            <Box>
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 1.77778C0 0.795938 0.779479 0 1.74102 0H15.6691C16.6307 0 17.4102 0.795938 17.4102 1.77778V11.5556C17.4102 12.5374 16.6307 13.3333 15.6691 13.3333H11.6772L9.32062 15.7397C8.98067 16.0868 8.42949 16.0868 8.08954 15.7397L5.73298 13.3333H1.74102C0.779479 13.3333 0 12.5374 0 11.5556V1.77778ZM15.6691 1.77778H1.74102V11.5556H6.09355C6.32443 11.5556 6.54585 11.6492 6.7091 11.8159L8.70508 13.854L10.7011 11.8159C10.8643 11.6492 11.0857 11.5556 11.3166 11.5556H15.6691V1.77778ZM3.48203 4.88889C3.48203 4.39797 3.87177 4 4.35254 4H13.0576C13.5384 4 13.9281 4.39797 13.9281 4.88889C13.9281 5.37981 13.5384 5.77778 13.0576 5.77778H4.35254C3.87177 5.77778 3.48203 5.37981 3.48203 4.88889ZM3.48203 8.44444C3.48203 7.95352 3.87177 7.55556 4.35254 7.55556H9.57559C10.0564 7.55556 10.4461 7.95352 10.4461 8.44444C10.4461 8.93536 10.0564 9.33333 9.57559 9.33333H4.35254C3.87177 9.33333 3.48203 8.93536 3.48203 8.44444Z"
                  fill="#6D6D6D"
                />
              </svg>
            </Box>
            <Box maxWidth={"150px"}>
              <Typography
                style={{ textDecoration: "underline" }}
                sx={{
                  color: "text.light",
                  fontSize: "10px",
                }}
              >
                {params.value}
              </Typography>
            </Box>
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (props) => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <StateSpan status={props.value} />
              {role === DRIVER || role === SALES || role === SALES_MANAGER ? (
                <></>
              ) : (
                <EditDetail
                  props={props}
                  onStateChangeHandler={(state) => {
                    if (state === DELETE_ROW) {
                      dispatch(
                        deleteMaintenanceRecord(props.row.id, loadMaintenance)
                      );
                    } else if (state === EDIT_ROW) {
                      setEditMaintenanceModal(true);
                      setCurrentMaintenanceRecord(props.row);
                    }
                  }}
                  options={[
                    { label: "Edit", value: EDIT_ROW },
                    { label: "Delete", value: DELETE_ROW },
                  ]}
                />
              )}
            </Box>
          </>
        );
      },
    },
  ];

  // for profiel update
  const identificationChange = async (data) => {
    return new Promise((resolve, rej) => {
      FleetService.updateVehicleIdentification(currentVehicleInfo.id, data)
        .then((res) => {
          if (res.data.message === SUCCESS) {
            showNotification("Profile is updated", "success");
            loadDetail();
            resolve(true);
          }
        })
        .catch((err) => {
          showNotification(
            err.response
              ? ERROR[err.response.data.message] || "Network Error"
              : err.message,
            "error"
          );
          rej(false);
        });
    });
  };

  const detailChange = async (data) => {
    return new Promise((resolve, rej) => {
      FleetService.updateVehicleDetail(currentVehicleInfo.id, data)
        .then((res) => {
          if (res.data.message === SUCCESS) {
            showNotification("Vehicle details are updated", "success");
            loadDetail();
            resolve(true);
          }
        })
        .catch((err) => {
          showNotification(
            err.response
              ? ERROR[err.response.data.message] || "Network Error"
              : err.message,
            "error"
          );
          rej(false);
        });
    });
  };

  //data for transfer
  const cols_transfer = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (params) => {
        return getDateStringFromNum(
          new Date(params.value).getTime(),
          orgData.timezone
        );
      },
    },
    { field: "origin_name", headerName: "Origin", width: 150 },
    { field: "vehicle_name", headerName: "Vehicle", width: 200 },
    {
      field: "destination_warehouse_name",
      headerName: "Destination",
      width: 150,
    },
    { field: "quantity", headerName: "Transfer", width: 400 },
    {
      field: "vehicle_driver_name",
      headerName: "Driver",
      width: 150,
    },
  ];
  const tabChange = async (tabId) => {
    setIsLoaded(false);
    setRows([]);
    setCurrentTab(tabId);
    if (tabId === 3) {
      await loadTravelledKilo();
    }
    if (tabId === 4) {
      await loadMaintenance();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const archiveVehicle = () => {
    if (currentVehicleInfo?.status === STATE_ACTIVE) {
      enqueueSnackbar(
        "You cannot do that because vehicle is active. \n You have to deactivate vehicle first",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
    }

    handleClose();
  };
  const activateVehicle = async () => {
    const res = await FleetService.activateVehicle(currentVehicleInfo.id);
    if (res === SUCCESS) {
      handleClose();
      await loadDetail();
    }
  };
  const deactivateVehicle = async () => {
    const res = await FleetService.deactivateVehicle(currentVehicleInfo.id);
    if (res === SUCCESS) {
      handleClose();
      await loadDetail();
    }
  };

  // modal show
  const addRawWaste = () => {
    setAddRawWasteModal(true);
    handleClose();
  };

  const addTravlledKm = () => {
    setRegisterKiloModal(true);
  };

  const addMaintenanceRecord = () => {
    setDayOffModal(true);
  };
  // for add modal
  const onRawWasteAdd = async (waste, capacity) => {
    dispatch(
      assignRawWaste(
        currentVehicleInfo?.id,
        {
          waste,
          capacity,
        },
        () => {
          setAddRawWasteModal(false);
        }
      )
    );
  };
  const onRawWasteCancel = () => {
    setAddRawWasteModal(false);
  };

  const onRawWasteEdit = async (capacity) => {
    dispatch(
      editRawWaste(
        currentVehicleInfo?.id,
        currentRawWasteRecord._id,
        {
          capacity,
        },
        () => {
          setEditRawWasteModal(false);
        }
      )
    );
  };
  const onRawWasteEditCancel = () => {
    setEditRawWasteModal(false);
  };
  const onRegisterSave = async (data) => {
    const res = await FleetService.addTravelledKilo(
      currentVehicleInfo.id,
      data
    );
    if (res === SUCCESS) {
      showNotification("New odometer added", "success");
      await loadTravelledKilo();
      setRegisterKiloModal(false);
    } else {
      showNotification(
        res.response
          ? ERROR[res.response.data?.message] || "Network Error"
          : "message",
        "error"
      );
    }
  };

  const onRegisterCancel = () => {
    setRegisterKiloModal(false);
  };

  const onDayOffCancel = () => {
    setDayOffModal(false);
  };

  const onDayOffSave = async (data) => {
    setDayOffModal(false);
    const res = await FleetService.addMaintenanceRecord(
      currentVehicleInfo.id,
      data
    );
    if (res === SUCCESS) await loadMaintenance();
  };

  const onEditMaintenanceCancel = () => {
    setEditMaintenanceModal(false);
  };
  const onEditMaintenanceSave = (data) => {
    dispatch(
      editMaintenanceRecord(currentMaintenanceRecord.id, data, async () => {
        try {
          await loadMaintenance();
          setEditMaintenanceModal(false);
        } catch (err) {}
      })
    );
  };
  return (
    <>
      <Stack>
        <Stack minHeight={"100vh"} height={"100%"} direction={"row"}>
          <Stack
            sx={{
              backgroundColor: "#F3F1EB",
              paddingTop: "32px",
            }}
          >
            <Box
              sx={{
                ...classes.buttonBack,
                marginLeft: "24px",
                marginBottom: "30px",
              }}
              onClick={() => {
                navigate("/vehicle");
              }}
            >
              <ArrowBackIcon fontSize="" sx={{ marginRight: "8px" }} /> Back to
              list
            </Box>
            <ItemList
              value={currentVehicle}
              onSelectChange={onVehicleChange}
              label="Search Fleet"
            >
              {vehicles.map((item, index) => {
                return (
                  <Item
                    key={index}
                    title={item.name}
                    content={item.reg_plate}
                  />
                );
              })}
            </ItemList>
          </Stack>

          <Stack
            paddingTop={"50px"}
            paddingX={"17px"}
            width={"calc(100% - 250px);"}
            sx={{
              backgroundColor: "white",
              height: "100%",
              minHeight: "100vh",
            }}
            gap={1}
          >
            <Stack gap={1}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  {/* <LocalShippingOutlinedIcon fontSize="large" /> */}
                  <PageTitle>
                    {currentVehicleInfo != null && currentVehicleInfo.name}
                  </PageTitle>

                  <StateSpan
                    status={
                      currentVehicleInfo != null
                        ? currentVehicleInfo.status
                        : ""
                    }
                  />
                </Stack>

                <Stack mr={"17px"}>
                  <Button
                    variant="outlined"
                    sx={classes.buttonMoreOption}
                    onClick={(e) => {
                      setAnchorEL(e.currentTarget);
                      setOpen(!open);
                    }}
                  >
                    Options <MoreVertOutlined />
                  </Button>
                  <Menu
                    sx={classes.optionMenu}
                    id="basic-menu"
                    open={open}
                    anchorEl={anchorEL}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      horizontal: "center",
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {currentTab === 0 && currentVehicleInfo != null && (
                      <MenuItem
                        sx={{
                          minWidth: "228px",
                        }}
                        onClick={() => {
                          if (role !== SALES_MANAGER && role !== SALES) {
                            if (currentVehicleInfo.status === STATE_ACTIVE) {
                              deactivateVehicle();
                            } else {
                              activateVehicle();
                            }
                          }
                        }}
                        disabled={role === SALES_MANAGER || role === SALES}
                      >
                        {currentVehicleInfo.status === STATE_ACTIVE
                          ? "Deactivate"
                          : "Activate"}{" "}
                        vehicle
                      </MenuItem>
                    )}
                    {currentTab === 1 && (
                      <MenuItem
                        sx={{
                          minWidth: "228px",
                        }}
                        onClick={addRawWaste}
                        disabled={
                          role === DRIVER ||
                          role === SALES ||
                          role === SALES_MANAGER
                        }
                      >
                        Add raw waste
                      </MenuItem>
                    )}
                    {currentTab === 3 && (
                      <MenuItem
                        sx={{
                          minWidth: "228px",
                        }}
                        onClick={addTravlledKm}
                        disabled={
                          role === DRIVER ||
                          role === SALES ||
                          role === SALES_MANAGER
                        }
                      >
                        Add travelled km
                      </MenuItem>
                    )}
                    {currentTab === 4 && (
                      <MenuItem
                        sx={{
                          minWidth: "228px",
                        }}
                        onClick={addMaintenanceRecord}
                        disabled={
                          role === DRIVER ||
                          role === SALES ||
                          role === SALES_MANAGER
                        }
                      >
                        Add Maintenance Period
                      </MenuItem>
                    )}
                    {currentVehicleInfo?.status === "inactive" && (
                      <MenuItem
                        onClick={archiveVehicle}
                        disabled={
                          role === WAREHOUSE_MANAGER ||
                          role === SALES_MANAGER ||
                          role === SALES ||
                          role === DRIVER
                        }
                      >
                        Archive vehicle
                      </MenuItem>
                    )}
                  </Menu>
                </Stack>
              </Stack>
            </Stack>

            <Stack>
              <Tabs onTabChangeHandler={tabChange}>
                <TabHeader>
                  <TabHeaderItem>Profile</TabHeaderItem>
                  <TabHeaderItem>Waste Capacity</TabHeaderItem>
                  <TabHeaderItem>Transfer</TabHeaderItem>
                  <TabHeaderItem>Register Kilometers</TabHeaderItem>
                  <TabHeaderItem>Maintenance</TabHeaderItem>
                </TabHeader>
                <></>
                <TabBody>
                  {isLoaded ? (
                    <Tab>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        <CardPrimary
                          type={1}
                          title="Total transfers"
                          content={currentVehicleInfo?.count_of_transfers}
                        />
                        <CardPrimary
                          type={2}
                          title="Raw Waste types"
                          content={currentRawWastes?.length}
                        />
                        <CardPrimary
                          type={3}
                          title="Next collection"
                          content={`In ${
                            currentVehicleInfo?.next_picking | 0
                          } days`}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "14px",
                          marginTop: "23px",
                          alignItems: "flex-start",
                          flexWrap: "wrap",
                        }}
                      >
                        {currentVehicleInfo != null && (
                          <>
                            <CardProfile
                              disabled={
                                role === SALES_MANAGER ||
                                role === SALES ||
                                role === DRIVER
                              }
                              title="Identification"
                              data={{
                                Plate: currentVehicleInfo.reg_plate,
                                Brand: currentVehicleInfo.brand,
                                Model: currentVehicleInfo.model,
                                "Uco Network ID": currentVehicleInfo.ucoId,
                              }}
                              extraData={currentVehicleInfo}
                              align={[1, 1, 1, 1]}
                              type={FLEET_IDENTIFICATION_MODAL}
                              onSave={identificationChange}
                            />
                            <CardProfile
                              disabled={
                                role === SALES_MANAGER ||
                                role === SALES ||
                                role === DRIVER
                              }
                              title="Vehicle details"
                              data={{
                                Year: currentVehicleInfo.year,
                                "Maximum vehicle capacity":
                                  currentVehicleInfo.capacity,
                                Driver: currentVehicleInfo.driver,
                                Warehouse: currentVehicleInfo.warehouse,
                              }}
                              align={[1, 1, 1, 1]}
                              warehouse={currentVehicleInfo.warehouseId}
                              driver={currentVehicleInfo?.driverId}
                              onSave={detailChange}
                              type={FLEET_VEHICLE_DETAIL_MODAL}
                            />
                            <CardTypes
                              title="Raw waste types"
                              item="Raw waste"
                              data={currentVehicleInfo.rawWastes}
                            />
                          </>
                        )}
                      </Box>
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack
                        flexDirection={"row"}
                        gap={2}
                        mt={2}
                        flexWrap={"wrap"}
                      >
                        <Skeleton
                          variant="rounded"
                          width={"300px"}
                          height={"370px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"300px"}
                          height={"370px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"300px"}
                          height={"200px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        <CardPrimary
                          type={1}
                          title="Total transfers"
                          content={currentVehicleInfo?.count_of_transfers}
                        />
                        <CardPrimary
                          type={2}
                          title="Raw Waste types"
                          content={currentRawWastes?.length}
                        />
                        <CardPrimary
                          type={3}
                          title="Next collection"
                          content={`In ${
                            currentVehicleInfo?.next_picking | 0
                          } days`}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "14px",
                          marginTop: "23px",
                          alignItems: "flex-start",
                        }}
                      >
                        {currentTab === 1 && (
                          <DataTable rows={rows} columns={cols_waste} />
                        )}
                      </Box>
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        <CardPrimary
                          type={1}
                          title="Total transfers"
                          content={currentVehicleInfo?.count_of_transfers}
                        />
                        <CardPrimary
                          type={2}
                          title="Raw Waste types"
                          content={currentRawWastes?.length}
                        />
                        <CardPrimary
                          type={3}
                          title="Next collection"
                          content={`In ${
                            currentVehicleInfo?.next_picking | 0
                          } days`}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "14px",
                          marginTop: "23px",
                          alignItems: "flex-start",
                        }}
                      >
                        {currentTab === 2 && (
                          <DataTable rows={rows} columns={cols_transfer} />
                        )}
                      </Box>
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      {rows.length === 0 ? (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: "30vh",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            position: "absolute",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "37px",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img src={speedometer} alt="no records" />
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontFamily: "Manrope",
                                  fontSize: "10px",
                                  color: "text.primary",
                                }}
                              >
                                There are no records listed
                              </Typography>
                            </Box>
                            <Button
                              onClick={() => {
                                setRegisterKiloModal(true);
                              }}
                              size="large"
                              sx={classes.buttonPrimary}
                            >
                              Add records
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              flexWrap: "wrap",
                            }}
                          >
                            <CardPrimary
                              type={1}
                              title="Kilometres travelled"
                              content={totalKiloMeters + " km"}
                              subTitle="last 30 days"
                            />
                            <CardPrimary
                              type={2}
                              title="Nº of records"
                              content={rows.length}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "14px",
                              marginTop: "23px",
                              alignItems: "flex-start",
                            }}
                          >
                            {currentTab === 3 && (
                              <DataTable
                                editable="true"
                                rows={rows}
                                columns={cols_register}
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={
                                  handleRowModesModelChange
                                }
                                onRowEditStop={handleRowEditStop}
                                processRowUpdate={processRowUpdate}
                              />
                            )}
                          </Box>
                        </>
                      )}
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                        <Skeleton
                          variant="rounded"
                          width={"162px"}
                          height={"86px"}
                        />
                      </Stack>
                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                  {isLoaded ? (
                    <Tab>
                      {rows.length === 0 ? (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: "30vh",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            position: "absolute",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "37px",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <img
                                width={150}
                                height={150}
                                src={vector}
                                alt="No records"
                              />
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontFamily: "Manrope",
                                  fontSize: "10px",
                                  color: "text.primary",
                                }}
                              >
                                There are no maintenance periods listed
                              </Typography>
                            </Box>
                            <Button
                              onClick={() => {
                                setDayOffModal(true);
                              }}
                              size="large"
                              sx={classes.buttonPrimary}
                            >
                              Add records
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "8px",
                            flexDirection: "column",
                          }}
                        >
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                            sx={{
                              color: "text.accent",
                            }}
                          >
                            <Switch
                              checked={filterMaintenance}
                              onClick={(e) => {
                                setFilterMaintenance(e.target.checked);
                              }}
                            />
                            Show completed maintenance records
                          </Stack>
                          {currentTab === 4 && (
                            <DataTable
                              columns={cols_maintenance}
                              rows={rows.filter((row, index) => {
                                if (filterMaintenance === true) return true;
                                else if (
                                  filterMaintenance === false &&
                                  row.status !== "completed"
                                )
                                  return true;
                                else return false;
                              })}
                            />
                          )}
                        </Box>
                      )}
                    </Tab>
                  ) : (
                    <Tab>
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        sx={{
                          color: "text.accent",
                        }}
                      >
                        <Switch
                          checked={filterMaintenance}
                          onClick={(e) => {
                            setFilterMaintenance(e.target.checked);
                          }}
                        />
                        Show completed maintenance records
                      </Stack>

                      <Stack flexDirection={"row"} mt={2}>
                        <Skeleton
                          variant="rounded"
                          width={"80%"}
                          height={"100px"}
                        />
                      </Stack>
                    </Tab>
                  )}
                </TabBody>
              </Tabs>
            </Stack>
          </Stack>
        </Stack>

        <Modal open={addRawWasteModal}>
          <CardFleetAddRawWasteModal
            onCancelHandler={onRawWasteCancel}
            onSaveHandler={onRawWasteAdd}
          />
        </Modal>

        <Modal open={editRawWasteModal}>
          <CardFleetEditRawWasteModal
            data={currentRawWasteRecord}
            onCancelHandler={onRawWasteEditCancel}
            onSaveHandler={onRawWasteEdit}
          />
        </Modal>
        <Modal open={registerKiloModal}>
          <CardFleetRegisterKilo
            onCancelHandler={onRegisterCancel}
            onSaveHandler={onRegisterSave}
          />
        </Modal>
        <Modal open={dayOffModal}>
          <CardFleetAddDayoffModal
            onCancelHandler={onDayOffCancel}
            onSaveHandler={onDayOffSave}
          />
        </Modal>
        <Modal open={editMaintenanceModal}>
          <CardFleetEditDayoffModal
            data={currentMaintenanceRecord}
            onCancelHandler={onEditMaintenanceCancel}
            onSaveHandler={onEditMaintenanceSave}
          />
        </Modal>
      </Stack>
    </>
  );
};
export default VehicleDetailPage;
