import { pickup_axios } from "../utils/axios";

//POST
const createPickupPoint = (data) => {
  return pickup_axios.post("/create-pickup-point", data);
};

const createPickupPointWizard = (data) => {
  return pickup_axios.post("/create-pickup-point-setup-wizard", data);
};
const addDocument = (id, data) => {
  return pickup_axios.post(`/add-document/${id}`, data);
};

//UPDATE put
const validateDocument = (id) => {
  return pickup_axios.put(`/validate-document/${id}`);
};
const revokeDocument = (id) => {
  return pickup_axios.put(`/revoke-document/${id}`);
};
const updatePickupPoint = (id, data) => {
  return pickup_axios.put(`/edit-pickup-point/${id}`, data);
};

const deactivatePickupPoint = (id) => {
  return pickup_axios.put(`/deactivate-pickup-point/${id}`);
};

const activatePickupPoint = (id) => {
  return pickup_axios.put(`/activate-pickup-point/${id}`);
};

const archivePickupPoint = (id) => {
  return pickup_axios.put(`/archive-pickup-point/${id}`);
};

const unarchivePickupPoint = (id) => {
  return pickup_axios.put(`/unarchive-pickup-point/${id}`);
};

//GET
const fetchPickupPoints = () => {
  return pickup_axios.get("/list-pickup-points");
};

const fetchPickupPointDetail = (id) => {
  return pickup_axios.get(`/get-pickup-point-details/${id}`);
};

const fetchWastePriceHistoryList = (id) => {
  return pickup_axios.get(`/get-pickup-point-price-history/${id}`);
};

const fetchDocumentList = (id) => {
  return pickup_axios.get(`/list-documents/${id}`);
};

const fetchPickupPointTransfers = (id) => {
  return pickup_axios.get(`/list-transfers/${id}`);
};

const fetchPickupDeposits = (id) => {
  return pickup_axios.get(`/list-deposits/${id}`);
};

const fetchPickupInventory = (id, isOffer) => {
  return pickup_axios.get(`/list-inventories/${id}/${isOffer}`);
};
const fetchPickupRawWastes = (id) => {
  return pickup_axios.get(`/list-raw-wastes/${id}`);
};

const fetchPickupPointVisits = (id) => {
  return pickup_axios.get(`/list-visits/${id}`);
};
const fetchPickupPointScheduledVisits = (id) => {
  return pickup_axios.get(`/list-scheduled-visits/${id}`);
};
const deletePickupPointDocuments = (doc_id) => {
  return pickup_axios.delete(`/remove-document/${doc_id}`);
};

const addPickupPointDeposit = (id, data) => {
  return pickup_axios.post(`/add-deposit/${id}`, data);
};

const editPickupPointDeposit = (id, data) => {
  return pickup_axios.put(`/edit-deposit/${id}`, data);
};

const removePickupPointDeposit = (id) => {
  return pickup_axios.delete(`/remove-deposit/${id}`);
};
const activatePickupPointDeposit = (id) => {
  return pickup_axios.put(`/activate-deposit/${id}`);
};
const deactivatePickupPointDeposit = (id) => {
  return pickup_axios.put(`/deactivate-deposit/${id}`);
};
const archivePickupPointDeposit = (id) => {
  return pickup_axios.put(`/archive-deposit/${id}`);
};
const unarchivePickupPointDeposit = (id) => {
  return pickup_axios.put(`/unarchive-deposit/${id}`);
};
const removePickupPoint = (id) => {
  return pickup_axios.delete(`/remove-pickup-point/${id}`);
};
const getPickupPointRouteDetails = (id) => {
  return pickup_axios.get(`get-route-pickup-point-details/${id}`);
};
const PickupService = {
  getPickupPointRouteDetails,
  archivePickupPointDeposit,
  unarchivePickupPointDeposit,
  removePickupPoint,
  addPickupPointDeposit,
  fetchPickupDeposits,
  fetchPickupInventory,
  fetchPickupPointVisits,
  fetchPickupPointScheduledVisits,
  fetchPickupPoints,
  createPickupPoint,
  addDocument,
  updatePickupPoint,
  deactivatePickupPoint,
  activatePickupPoint,
  fetchPickupPointDetail,
  fetchWastePriceHistoryList,
  fetchDocumentList,
  fetchPickupPointTransfers,
  deletePickupPointDocuments,
  archivePickupPoint,
  unarchivePickupPoint,
  validateDocument,
  activatePickupPointDeposit,
  deactivatePickupPointDeposit,
  revokeDocument,
  createPickupPointWizard,
  fetchPickupRawWastes,
  removePickupPointDeposit,
  editPickupPointDeposit,
};

export default PickupService;
