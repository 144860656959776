import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Modal, Stack } from "@mui/material";
import { PageTitle } from "../../../../components/Typography";

import { classes } from "../../../../utils/class";
import { updateWarehouseInfo } from "../../../../actions/warehouse";
import CloseIcon from "@mui/icons-material/Close";
import { CustomMap } from "../../../../components/CustomMap";

const ResponsibleDialog = ({ open, onClose, info }) => {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.warehouse.current.id);
  const [location_data, setLocationData] = useState({
    latitude: info?.latitude,
    longitude: info?.longitude,
    addr_country: info?.addr_country,
    addr_city: info?.addr_city,
    addr_postcode: info?.postcode,
    addr_apartment: info?.apartment,
    addr_street: info?.street,
  });

  const handleSave = () => {
    dispatch(updateWarehouseInfo(id, location_data));
    onClose();
  };
  useEffect(() => {
    setLocationData({
      latitude: info?.latitude,
      longitude: info?.longitude,
      addr_country: info?.addr_country,
      addr_city: info?.addr_city,
      addr_postcode: info?.postcode,
      addr_apartment: info?.apartment,
      addr_street: info?.street,
    });
  }, [info]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack padding={"34px 42px"} gap={"22px"} sx={classes.locationPopup}>
        <PageTitle>Location</PageTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "42px",
            top: "34px",
          }}
        >
          <CloseIcon />
        </IconButton>

        <CustomMap
          description={"Warehouse"}
          data={location_data}
          onChange={(d) => {
            setLocationData(d);
          }}
        />

        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={onClose}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ResponsibleDialog;
