import React from "react";

// material-ui
import { Box, Stack, IconButton, Typography } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

// project imports
import { CardPrimary } from "../../../../components/Card";
import { DataTable } from "../../../../components/Table";

// sx
import { classes } from "../../../../utils/class";
import { Percentage } from "../../../../components/Typography";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { Link, useNavigate } from "react-router-dom";
import { DescriptionOutlined } from "@mui/icons-material";

// action import
const Transfers = ({ props, extraInfo, id }) => {
  const navigate = useNavigate();
  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "origin_name",
      headerName: "Origin",
      flex: 1,
      renderCell: (props) => {
        return (
          <>
            <Typography sx={{ fontWeight: 400 }} className="mui-ellipsis">
              {props.value}
            </Typography>
          </>
        );
      },
    },
    {
      field: "vehicle_name",
      headerName: "Vehicle",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "destination_warehouse_name",
      headerName: "Destination",
      flex: 1,
      renderCell: (props) => {
        return (
          <>
            <Typography sx={{ fontWeight: 400 }} className="mui-ellipsis">
              {props.value}
            </Typography>
          </>
        );
      },
    },
    {
      headerName: "Transfer",
      field: "transfer",
      minWidth: 450,
      flex: 2,
      renderCell: (props) => {
        return (
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Typography>{props.row.quantity} kg</Typography>
            <Percentage value={props.row.ratio} />
            <Typography>{props.row.raw_waste_name}</Typography>
            <Link to={`/wtn-detail/${props.row._id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <DescriptionOutlined />
              </IconButton>
            </Link>
          </Stack>
        );
      },
    },
    {
      headerName: "Driver",
      field: "vehicle_driver_name",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            <CardPrimary
              type={1}
              title={"Balance"}
              content={extraInfo?.balance || 0}
            />
            <CardPrimary
              type={2}
              title={"Next collection"}
              content={`in ${extraInfo?.next_picking || 0} days`}
            />
            <CardPrimary
              type={3}
              title={"Expiring documents"}
              content={extraInfo?.count_expiring_documents || 0}
            />
          </Box>
        </Box>
      </Box>

      <DataTable
        fit="fit-screen"
        autoSize
        rows={props.map((prop, index) => {
          return {
            ...prop,
            id: prop._id,
            date: new Date(prop.date).toDateString(),
          };
        })}
        columns={columns}
        sx={classes.grid}
        onRowClick={(params) => {
          navigate(`/transfer-detail/${params.id}/view`);
        }}
      />
    </>
  );
};

export default Transfers;
