import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CSVLink } from "react-csv";
// project imports
import { CardPrimary } from "../../../../components/Card";
import { EditDetail, StateSpan } from "../../../../components/StateSpan";
import { DataTable } from "../../../../components/Table";

// sx
import { classes } from "../../../../utils/class";
import { updateTicketStatus } from "../../../../slices/ticketsSlice";
import {
  STATE_ACTIVE,
  STATE_ARCHIVE,
  STATE_INACTIVE,
  STATE_REVOKED,
  STATE_VALID,
} from "../../../../utils/type";
import {
  Print,
  download,
  getDateStringFromNum,
  showNotification,
} from "../../../../utils/helpers";
import {
  revokeDocument,
  validateDocument,
} from "../../../../actions/producers";
import { sendDocumentsByEmail } from "../../../../actions/certificates";
import {
  BACKOFFICE,
  OWNER,
  SALES,
  SALES_MANAGER,
} from "../../../../utils/constants";

// action import
const Documents = ({ extraInfo, props, id }) => {
  const dispatch = useDispatch();
  const csvLink = useRef();
  const rowsData = props || [];
  const [anchorEL, setAnchorEL] = useState(null);
  const [open, setOpen] = useState(false);
  const [itemsChecked, setItemsChecked] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const role = useSelector((state) => state.user.role);

  const onBulkActionItemClicked = () => {};
  const profile = useSelector((state) => {
    return state.user.profile;
  });
  const orgData = useSelector((state) => state.organizations.profile);
  const columns = [
    {
      field: "checkbox",
      hideSortIcons: true,
      disableColumnMenu: true,
      headerName: (
        <Checkbox
          sx={classes.checkBox}
          checked={allCheck}
          onClick={(e) => {
            e.stopPropagation();
            if (allCheck) {
              setAllCheck(false);
              setItemsChecked([]);
            } else {
              setAllCheck(true);
              setItemsChecked(rowsData.map((t) => t._id));
            }
          }}
        />
      ),
      width: 60,
      renderCell: (props) => (
        <Checkbox
          sx={classes.checkBox}
          checked={itemsChecked.indexOf(props.row._id) !== -1}
          onClick={(e) => {
            e.stopPropagation();
            const id = itemsChecked.indexOf(props.row._id);
            const temp = [...itemsChecked];
            if (id !== -1) {
              temp.splice(id, 1);
            } else {
              temp.push(props.row._id);
            }
            setItemsChecked(temp);
          }}
        />
      ),
    },
    {
      field: "type",
      headerName: "Doc type",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "id",
      headerName: "Doc ID",
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "issue_date",
      headerName: "Issued date",
      flex: 1,
      renderCell: (props) => {
        return (
          <span className="mui-ellipsis">
            {getDateStringFromNum(
              new Date(props.value).getTime(),
              orgData.timezone
            )}
          </span>
        );
      },
    },
    {
      field: "expiration_date",
      headerName: "Expiration date",
      flex: 1,
      renderCell: (props) => {
        return (
          <span className="mui-ellipsis">
            {getDateStringFromNum(
              new Date(props.value).getTime(),
              orgData.timezone
            )}
          </span>
        );
      },
    },
    {
      field: "url",
      headerName: "File",
      flex: 1,
      renderCell: (props) => {
        return (
          <IconButton>
            <a href={props.value} target="_blank">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.9753 3.71715C12.5171 3.21731 11.6897 3.18651 11.1381 3.73808L5.73812 9.13808C5.59847 9.27773 5.59847 9.45562 5.73812 9.59527C5.87777 9.73492 6.05566 9.73492 6.19531 9.59527L10.662 5.1286C10.9223 4.86825 11.3444 4.86825 11.6048 5.1286C11.8651 5.38895 11.8651 5.81106 11.6048 6.07141L7.13812 10.5381C6.47777 11.1984 5.45566 11.1984 4.79531 10.5381C4.13496 9.87773 4.13496 8.85562 4.79531 8.19527L10.1953 2.79527C11.2403 1.75025 12.9386 1.71624 13.9483 2.80547C14.9831 3.85108 15.0136 5.54105 13.9286 6.54755L7.60479 12.8714C6.14444 14.3318 3.85566 14.3318 2.39531 12.8714C0.934961 11.4111 0.934961 9.12228 2.39531 7.66193L7.79531 2.26193C8.05566 2.00158 8.47777 2.00158 8.73812 2.26193C8.99847 2.52228 8.99847 2.94439 8.73812 3.20474L3.33812 8.60474C2.39847 9.54439 2.39847 10.9889 3.33812 11.9286C4.27777 12.8683 5.72233 12.8683 6.66198 11.9286L12.9953 5.59527C13.0021 5.58844 13.0091 5.58176 13.0162 5.57523C13.5161 5.11705 13.5469 4.28964 12.9953 3.73808C12.9885 3.73125 12.9818 3.72427 12.9753 3.71715Z"
                  fill="#C9C9C9"
                />
              </svg>
            </a>
          </IconButton>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (props) => {
        let options = [];
        if (props?.value === "valid") {
          options = [
            {
              label: "Revoke",
              value: "revoked",
            },
          ];
        } else {
          options = [
            {
              label: "Valid",
              value: "valid",
            },
          ];
        }
        return (
          <Stack direction="row" alignItems={"center"}>
            <StateSpan status={props.value} />
            {role !== BACKOFFICE && role !== OWNER && role !== SALES_MANAGER ? (
              <></>
            ) : (
              <EditDetail
                props={props}
                options={options}
                onStateChangeHandler={async (status) => {
                  if (status === "valid")
                    dispatch(validateDocument(props.row._id, id));
                  else dispatch(revokeDocument(props.row._id, id));
                  // dispatch(
                  //   updateCertificateStatus({
                  //     id: props.row.id,
                  //     status: status === "valid" ? STATE_VALID : STATE_REVOKED,
                  //   })
                  // );
                }}
              />
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Stack gap={2}>
        <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
          <CardPrimary
            type={1}
            title={"Balance"}
            content={extraInfo?.balance || 0}
          />
          <CardPrimary
            type={2}
            title={"Next collection"}
            content={`in ${extraInfo?.next_picking || 0} days`}
          />
          <CardPrimary
            type={3}
            title={"Expiring documents"}
            content={extraInfo?.count_expiring_documents || 0}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
          <Button
            variant="outlined"
            sx={classes.buttonMoreOption}
            onClick={(e) => {
              setAnchorEL(e.currentTarget);
              setOpen(!open);
            }}
          >
            Bulk actions
            <KeyboardArrowDownIcon />
          </Button>
          {itemsChecked.length > 0 && (
            <>
              <Typography>
                {itemsChecked.length} selected certificates
              </Typography>
            </>
          )}
          <Menu
            id="basic-menu"
            open={open}
            anchorEl={anchorEL}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              horizontal: "center",
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              sx={{
                minWidth: "228px",
              }}
              onClick={() => {
                const urls = props
                  .filter((cer) => itemsChecked.indexOf(cer._id) !== -1)
                  .map((cer) => {
                    return cer.url;
                  });
                if (itemsChecked.length) download(urls);
                else showNotification("No document is selected", "error");
                setOpen(false);
              }}
            >
              Download documents
            </MenuItem>
            <MenuItem
              sx={{
                minWidth: "228px",
              }}
              onClick={() => {
                const urls = props
                  .filter((cer) => itemsChecked.indexOf(cer._id) !== -1)
                  .map((cer) => {
                    return cer.url;
                  });
                if (itemsChecked.length) {
                  showNotification("Waiting for loading documents", "info");
                  Print(urls);
                } else showNotification("No document is selected", "error");
                setOpen(false);
              }}
            >
              Print documents
            </MenuItem>
            <MenuItem
              sx={{
                minWidth: "228px",
              }}
              onClick={() => {
                const urls = props
                  .filter((cer) => itemsChecked.indexOf(cer._id) !== -1)
                  .map((cer) => {
                    return cer.url;
                  });
                dispatch(
                  sendDocumentsByEmail({
                    email: profile.email,
                    file_urls: urls,
                  })
                );
              }}
            >
              Send documents by email
            </MenuItem>
          </Menu>
        </Stack>
        <Box sx={{ width: "100%", padding: "20px" }}>
          <DataTable rows={rowsData} columns={columns} sx={classes.grid} />
        </Box>
      </Stack>

      <CSVLink
        data={props
          .filter((cer) => itemsChecked.indexOf(cer._id) !== -1)
          .map((cer) => ({
            id: cer.id,
            pickup_point_name: cer.pickup_point_name,
            producer_name: cer.producer_name,
            issue_date: getDateStringFromNum(cer.issue_date, orgData.timezone),
            expiration_date: getDateStringFromNum(
              cer.expiration_date,
              orgData.timezone
            ),
            status: cer.status,
          }))}
        filename="documents.csv"
        headers={[
          { label: "ID", key: "id" },
          { label: "Collection Point", key: "pickup_point_name" },
          { label: "Producer", key: "producer_name" },
          { label: "Issue Date", key: "issue_date" },
          { label: "Expiration Date", key: "expiration_date" },
          { label: "Status", key: "status" },
        ]}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};

export default Documents;
