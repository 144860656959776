import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visits: [],
  scheduled_visits: [],
  selected: {
    id: null,
    detail: {},
  },
  total_collected: 0,
  current_scheduled: null,
  current_waste: null,
};

export const visitsSlice = createSlice({
  name: "visits",
  initialState,
  reducers: {
    setCurrentWaste: (state, action) => {
      state.current_waste = action.payload;
    },
    setScheduledVisitsList: (state, action) => {
      state.scheduled_visits = action.payload;
    },
    setVisitsList: (state, action) => {
      state.visits = action.payload;
    },
    setSelectedVisitId: (state, action) => {
      state.selected.id = action.payload;
    },
    setSelectedVisit: (state, action) => {
      state.selected.detail = action.payload;
    },
    setTotalCollected: (state, action) => {
      state.total_collected = action.payload;
    },
    setCurrentScheduled: (state, action) => {
      state.current_scheduled = action.payload;
    },
  },
});

export const {
  setCurrentWaste,
  setVisitsList,
  setScheduledVisitsList,
  setSelectedVisit,
  setSelectedVisitId,
  setTotalCollected,
  setCurrentScheduled,
} = visitsSlice.actions;

export default visitsSlice.reducer;
