import { createSlice } from "@reduxjs/toolkit";
import { STATE_EXPIRED, STATE_REVOKED, STATE_VALID } from "../utils/type";

const initialState = {
  certificates: [],
};

export const certificatesSlice = createSlice({
  name: "certificates",
  initialState,
  reducers: {
    setCertificatesList: (state, action) => {
      state.certificates = action.payload;
    }
  },
});

export const { setCertificatesList } = certificatesSlice.actions;

export default certificatesSlice.reducer;
