// material-ui
import { Box, Button, Stack, Typography } from "@mui/material";

// proj imports
import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { CTextInput } from "../../../../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import UnsavedDialog from "../dialogs/UnsavedDialog";
import { useSnackbar } from "notistack";
import { setCompanyProfileInfo } from "../../../../slices/settingsSlice";
import { useEffect } from "react";
import { showNotification } from "../../../../utils/helpers";
import { updateOrganizationInfo } from "../../../../actions/organizations";

const ApaAccessView = ({ setStage }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const company = useSelector((state) => state.organizations.profile);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [unsavedOpen, setUnsavedOpen] = useState(false);

  useEffect(() => {
    setName(company.red_2_username);
  }, [company]);

  const onClickCancel = () => {
    if (name === company.username && password.length === 0) {
      setStage(0);
      return;
    }
    setUnsavedOpen(true);
  };

  const handleSave = () => {
    if (name.length === 0 || password.length === 0) {
      showNotification(
        "Some fields are empty. Please input required fields.",
        "error"
      );
      return;
    }
    dispatch(
      updateOrganizationInfo(
        {
          red_2_username: name,
          red_2_password: password,
        },
        setStage
      )
    );
  };

  return (
    <Box>
      <PageTitle>Company Profile</PageTitle>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          display: "flex",
          minHeight: "80%",
          marginTop: "40px",
        }}
      >
        <Box sx={{ width: "40%", minWidth: "300px" }}></Box>
        <Stack sx={{ width: "100%", maxWidth: "500px" }} spacing={2}>
          <Box sx={{ marginBottom: "20px" }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              APA Access
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              This data is used to connect to APA
            </Typography>
          </Box>
          <CTextInput
            title="Username"
            required={true}
            placeholder="Input name here"
            value={name}
            onChange={(val) => setName(val)}
          />
          <CTextInput
            title="Password"
            required={true}
            placeholder="Input password here"
            value={password}
            type="password"
            onChange={(val) => setPassword(val)}
          />
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "space-between", marginTop: "20px" }}
          >
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={onClickCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonSave}
              onClick={handleSave}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <UnsavedDialog
        open={unsavedOpen}
        handleLeave={() => setStage(0)}
        handleClose={() => setUnsavedOpen(false)}
        handleSave={handleSave}
      />
    </Box>
  );
};

export default ApaAccessView;
