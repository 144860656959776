import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  Button,
  Typography,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";

import { PageTitle } from "../Typography";
import { CAutoComplete, CTextInput, SearchBar } from "../CustomInput";
import { DataTable } from "../Table";
import { SUCCESS } from "../../utils/type";
import { classes } from "../../utils/class";
import {
  addPickupPointToZone,
  createZone,
  removeZonePickup,
} from "../../actions/zone";
import { fetchWarehouseList } from "../../actions/warehouse";
import { fetchAllDrivers, fetchAllVehicles } from "../../actions/fleet";
import FleetService from "../../services/FleetService";
import ZoneSerivce from "../../services/ZoneService";
import { showNotification } from "../../utils/helpers";

export const ZoneIdentificationModal = ({
  title,
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [name, setName] = useState(data.name);

  useEffect(() => {
    setName(data.name);
  }, [data]);
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack gap={1}>
        <CTextInput
          title="Name"
          placeholder="Norte Portugal"
          value={name}
          required
          onChange={(value) => {
            setName(value);
          }}
        />
        <CTextInput
          title="UCO Network ID"
          placeholder="UCO Network ID"
          value={data["UCO Network ID"]}
          disabled
          required
          onChange={(value) => {
            setName(value);
          }}
        />
      </Stack>
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({ name });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const ZoneDetailModal = ({
  title,
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  const dispatch = useDispatch();
  const { drivers, vehicles } = useSelector((state) => {
    return state.fleet;
  });
  const { warehouses } = useSelector((state) => {
    return state.warehouse;
  });

  const [driver, setDriver] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [warehouse, setWareHouse] = useState(null);

  useEffect(() => {
    if (data.driver && data.vehicle && data.warehouse) {
      setDriver({
        name: data.driver,
        value: data.driverId,
      });
      setVehicle({
        name: data.vehicle,
        value: data.vehicleId,
      });
      setWareHouse({
        name: data.warehouse,
        value: data.warehouseId,
      });
    }
  }, [data]);
  useEffect(() => {
    dispatch(fetchAllDrivers());
    dispatch(fetchAllVehicles());
    dispatch(fetchWarehouseList());
  }, [dispatch]);

  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack gap={1}>
        <CAutoComplete
          placeholder="Select Vehicle"
          hasComponent
          title="Vehicle"
          fixed
          options={vehicles.map((vehicle, index) => {
            return {
              name: vehicle.name,
              value: vehicle._id,
              component: (
                <Stack>
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                    {vehicle.name}
                  </Typography>
                  <Typography sx={{ color: "grey", fontSize: "12px" }}>
                    {vehicle.reg_plate}
                  </Typography>
                </Stack>
              ),
            };
          })}
          value={vehicle}
          onChange={async (data) => {
            setVehicle(data);
            const res = await FleetService.getVehicleDetail(data.value);
            setWareHouse({
              name: res.warehouse,
              value: res.warehouseId,
            });
            setDriver({
              name: res.driver,
              value: res.driverId,
            });
          }}
        />
        <CAutoComplete
          title="Driver"
          placeholder="Select Driver"
          options={drivers.map((driver, index) => {
            return { name: driver.name, value: driver._id };
          })}
          value={driver}
          onChange={(data) => {
            setDriver(data);
          }}
        />
        <CAutoComplete
          placeholder="Select Warehouse"
          title="Warehouse"
          options={warehouses.map((warehouse, index) => {
            return { name: warehouse.name, value: warehouse._id };
          })}
          value={warehouse}
          onChange={(data) => {
            setWareHouse(data);
          }}
        />
      </Stack>
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({
              vehicle: vehicle.value,
              driver: driver.value,
              warehouse: warehouse.value,
            });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const AddZoneModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const collectionPoints = useSelector((state) => state.pickup.pickupList);
  const { currentZonePickup } = useSelector((state) => state.zone);
  const [step, setStep] = useState(0);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [addedPoints, setAddedPoints] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [zoneName, setZoneName] = useState("");
  const [zoneId, setZoneId] = useState("");

  useEffect(() => {
    setStep(0);
    setAddedPoints({});
  }, [open]);

  useEffect(() => {
    ZoneSerivce.fetchZones().then((res) => {
      if (res.data.message === SUCCESS) {
        const zones = res.data.data.data;
        const zone = zones.filter((zone) => zone.name === zoneName);
        if (zone) {
          setZoneId(zone[0]?._id);
        }
      }
    });
  }, [step, zoneName]);

  useEffect(() => {
    let t = {};
    for (let pick of currentZonePickup) {
      t[pick.id] = true;
    }
    setAddedPoints(t);
  }, [currentZonePickup]);

  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
  });

  const columns = [
    {
      field: "name",
      width: 400,
      headerName: "Name",
      renderCell: (props) => {
        return (
          <>
            <Stack>
              <Stack>
                <Stack
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {props.row.name}
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                gap={1}
                sx={{
                  fontSize: "12px",
                  fontWeight: "medium",
                  color: "#9F9E9E",
                }}
              >
                <Stack>{props.row.official_id}</Stack>
                <Stack>{props.row.id}</Stack>
                <Stack>{props.row.producer}</Stack>
              </Stack>
            </Stack>
          </>
        );
      },
    },
    {
      field: "location_g",
      width: 100,
      headerName: "Action",
      renderCell: (props) => {
        return (
          <>
            <Box>
              {addedPoints[props.row.id] === true ? (
                <Button
                  sx={{
                    ...classes.buttonBack,
                    border: "none",
                    color: "#D64C56",
                    backgroundColor: "#D64C561A",
                  }}
                  onClick={() => {
                    dispatch(
                      removeZonePickup(zoneId, { pickup_point: props.row._id })
                    );
                  }}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  sx={{
                    ...classes.buttonBack,
                    border: "none",
                    color: "#2CB53B",
                    backgroundColor: "#2CB53B1A",
                  }}
                  onClick={() => {
                    dispatch(
                      addPickupPointToZone(zoneId, {
                        pickup_point: props.row._id,
                      })
                    );
                  }}
                >
                  Add
                </Button>
              )}
            </Box>
          </>
        );
      },
    },
  ];

  return (
    <Modal open={open} onClose={handleClose}>
      <Formik
        initialValues={{ name: "" }}
        validationSchema={validationSchema}
        onSubmit={async (value) => {
          setIsLoading(true);
          setZoneName(value.name);
          dispatch(
            createZone(
              {
                name: value.name,
              },
              () => {
                setStep(1);
                setIsLoading(false);
              },
              () => {
                setIsLoading(false);
              }
            )
          );
        }}
      >
        {({ errors, values, handleChange }) => (
          <Form>
            <Stack sx={classes.popup}>
              {step === 0 ? (
                <>
                  <PageTitle>Add zone</PageTitle>
                  <IconButton
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: "42px",
                      top: "34px",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>

                  <Stack sx={{ marginBottom: "20px" }}>
                    <Field
                      name="name"
                      title="Name"
                      component={CTextInput}
                      placeholder="Add zone name"
                    />
                    <ErrorMessage
                      name="name"
                      render={(msg) => (
                        <div
                          style={{
                            ...classes.bottomError,
                            color: "rgb(214, 76, 86)",
                          }}
                        >
                          {msg}
                        </div>
                      )}
                    />
                  </Stack>

                  <Stack gap={2} direction="row" justifyContent="flex-end">
                    <LoadingButton
                      loading={isLoading}
                      type="submit"
                      sx={classes.buttonSave}
                    >
                      {!isLoading && "Save"}
                    </LoadingButton>
                  </Stack>
                </>
              ) : (
                <>
                  <PageTitle>Select collection points</PageTitle>
                  <IconButton
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: "42px",
                      top: "34px",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>

                  <Box>
                    <SearchBar
                      label="Search collection points"
                      sx={{ width: "275px" }}
                      onSearchChange={(value) => {
                        setFilterModel({
                          items: [
                            {
                              field: "name",
                              operator: "contains",
                              value: value,
                            },
                          ],
                        });
                      }}
                    />
                  </Box>
                  <Box>
                    <DataTable
                      rows={collectionPoints}
                      columns={columns}
                      filterModel={filterModel}
                      autoSize={true}
                      expandable
                    />
                  </Box>
                </>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const AddVehicleModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");

  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    regPlate: yup.string().required("This field is required"),
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Formik
        initialValues={{
          name: "",
          regPlate: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (value) => {
          setIsLoading(true);
          const res = await FleetService.createVehicle({
            name: value.name,
            reg_plate: value.regPlate,
          });
          if (res === SUCCESS) {
            showNotification("Vehicle added successfully", "success");
            dispatch(fetchAllVehicles());
            handleClose();
          } else {
            let msg = res.response ? res.response.data.message : res.message;
            if (msg === "duplicated_reg_plate_error")
              msg = "You have entered a duplicate registration plate number.";
            showNotification(msg, "error");
          }
          setIsLoading(false);
        }}
      >
        {() => {
          return (
            <Form>
              <Stack sx={classes.popup}>
                <PageTitle>Add vehicle</PageTitle>
                <IconButton
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: "42px",
                    top: "34px",
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Stack>
                  <Field
                    name="name"
                    title="Vehicle name"
                    component={CTextInput}
                    placeholder="Enter vehicle name"
                  />
                  <ErrorMessage
                    name="name"
                    render={(msg) => (
                      <div
                        style={{
                          ...classes.bottomError,
                          color: "rgb(214, 76, 86)",
                        }}
                      >
                        {msg}
                      </div>
                    )}
                  />
                </Stack>

                <Stack sx={{ marginBottom: "20px" }}>
                  <Field
                    name="regPlate"
                    title="Vehicle registration plate"
                    component={CTextInput}
                    placeholder="EX: 23-HY-78"
                    caps
                  />
                  <ErrorMessage
                    name="regPlate"
                    render={(msg) => (
                      <div
                        style={{
                          ...classes.bottomError,
                          color: "rgb(214, 76, 86)",
                        }}
                      >
                        {msg}
                      </div>
                    )}
                  />
                </Stack>

                <Stack gap={2} direction="row" justifyContent="flex-end">
                  <LoadingButton
                    loading={isLoading}
                    type="submit"
                    sx={classes.buttonSave}
                  >
                    {!isLoading && "Save"}
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
