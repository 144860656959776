import { setAvailableRawWastes, setDashboardInfo } from "../slices/orgSlice";
import { setPickupList } from "../slices/pickupSlice";
import { setZoneList } from "../slices/zoneSlice";

const resetAllOrgData = () => (dispatch) => {
  dispatch(setAvailableRawWastes([]));
  dispatch(setZoneList([]));
  dispatch(setPickupList([]));
  dispatch(
    setDashboardInfo({
      percentage_pickup_points_per_zone: [],
      producer_history: [],
      pickup_point_history: [],
      collected_expected_wastes_history: [],
      total_visit_history: [],
      scheduled_visits_in_3_days: [],
      total_waste_transfer: [],
    })
  );
};

export { resetAllOrgData };
