import { org_axios } from "../utils/axios";

const fetchOrgsList = () => {
  return org_axios.get("/list-organizations");
};

const fetchWastes = () => {
  return org_axios.get("/list-assigned-rawwastes");
};
const fetchAvailableRawWastes = () => {
  return org_axios.get("/list-available-rawwastes");
};

const fetchUsers = () => {
  return org_axios.get("/list-all-users");
};

const fetchInvitation = () => {
  return org_axios.get("/list-invites/false");
};

const fecthRejectedInvitation = () => {
  return org_axios.get("/list-invites/true");
};
const createOrganization = (data) => {
  return org_axios.post("/create-organization", data);
};
const createDisposalReason = (data) => {
  return org_axios.post("/create-disposal-reason", data);
};
const listDisposalReasons = () => {
  return org_axios.get("/list-disposal-reasons");
};
const editDisposalReason = (id, data) => {
  return org_axios.put(`/edit-disposal-reason/${id}`, data);
};
const archiveDisposalReason = (id) => {
  return org_axios.put(`/archive-disposal-reason/${id}`);
};
const unarchiveDisposalReason = (id) => {
  return org_axios.put(`/unarchive-disposal-reason/${id}`);
};
const deactivateDisposalReason = (id) => {
  return org_axios.put(`/deactivate-disposal-reason/${id}`);
};
const activateDisposalReason = (id) => {
  return org_axios.put(`/activate-disposal-reason/${id}`);
};
const removeDisposalReason = (id) => {
  return org_axios.delete(`/remove-disposal-reason/${id}`);
};
const activeRawWaste = (id) => {
  return org_axios.put(`/active-assigned-rawwaste/${id}`);
};
const editRawWaste = (id, data) => {
  return org_axios.put(`/edit-assigned-rawwaste-name/${id}`, data);
};
const addRawWastes = (data) => {
  return org_axios.post(`/add-rawwastes`, data);
};
const inactiveRawWaste = (id) => {
  return org_axios.put(`/inactive-assigned-rawwaste/${id}`);
};
const fetchOrganizationInfo = () => {
  return org_axios.get(`/get-organization`);
};
const updateOrganizationInfo = (data) => {
  return org_axios.put(`/update-organization`, data);
};
const getMemberInfo = (id) => {
  return org_axios.get(`/get-member-info/${id}`);
};

const selectOrganization = (data) => {
  return org_axios.post(`/select-organization`, data);
};

const fetchDashboardInfo = (data) => {
  return org_axios.post(`/get-dashboard-info`, data);
};

const removeOrg = () => {
  return org_axios.delete("/delete-organization");
};

const OrganizationService = {
  removeOrg,
  selectOrganization,
  fetchOrgsList,
  fetchWastes,
  fetchUsers,
  fetchInvitation,
  createDisposalReason,
  listDisposalReasons,
  editDisposalReason,
  archiveDisposalReason,
  deactivateDisposalReason,
  removeDisposalReason,
  activeRawWaste,
  inactiveRawWaste,
  fetchOrganizationInfo,
  updateOrganizationInfo,
  editRawWaste,
  addRawWastes,
  unarchiveDisposalReason,
  activateDisposalReason,
  getMemberInfo,
  fetchAvailableRawWastes,
  fecthRejectedInvitation,
  createOrganization,
  fetchDashboardInfo,
};

export default OrganizationService;
