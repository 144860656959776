import { Card, Stack, Typography } from "@mui/material";

import { PageTitle } from "../../../components/Typography";
import { Android12Switch } from "../../../components/CustomInput";
import { showNotification } from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getVisitSetting,
  updateVisitSetting,
} from "../../../actions/organizations";
import {
  BACKOFFICE,
  DRIVER,
  OWNER,
  WAREHOUSE_MANAGER,
} from "../../../utils/constants";

const VisitsPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const visitSettings = useSelector(
    (state) => state.organizations.visitSettings
  );
  const role = useSelector((state) => state.user.role);

  useEffect(() => {
    if (role !== WAREHOUSE_MANAGER && role !== DRIVER)
      dispatch(getVisitSetting());
  }, []);

  const renderCard = (title, content, value, onClick) => {
    return (
      <Card
        sx={{
          width: "500px",
          padding: "20px",
        }}
      >
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", marginBottom: "10px" }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
            {title}
          </Typography>
          {role === BACKOFFICE || role === OWNER ? (
            <Android12Switch
              checked={value}
              onClick={() => {
                if (loading) {
                  showNotification("Please try again later!", "error");
                  return;
                }
                setLoading(true);
                onClick();
              }}
            />
          ) : (
            <></>
          )}
        </Stack>
        <Typography>{content}</Typography>
      </Card>
    );
  };

  return (
    <Stack spacing={3}>
      <PageTitle>Visits</PageTitle>
      <Typography>
        This page allows the user to configure the visits.
      </Typography>
      {renderCard(
        "Waste collect",
        "Define whether the signature of the responsible person is required after the collection of waste.",
        visitSettings.collect,
        () => {
          dispatch(
            updateVisitSetting(
              {
                ...visitSettings,
                collect: !visitSettings.collect,
              },
              () => setLoading(false)
            )
          );
        }
      )}
      {renderCard(
        "Offer delivery",
        "Define whether the signature of the person in charge is required when handing out offers.",
        visitSettings.deliver,
        () => {
          dispatch(
            updateVisitSetting(
              {
                ...visitSettings,
                deliver: !visitSettings.deliver,
              },
              () => setLoading(false)
            )
          );
        }
      )}
      {renderCard(
        "Disposal equipments",
        "Define whether the signature of the person in charge is required at the time of delivery, collection and disposal of equipment.",
        visitSettings.dispose,
        () => {
          dispatch(
            updateVisitSetting(
              {
                ...visitSettings,
                dispose: !visitSettings.dispose,
              },
              () => setLoading(false)
            )
          );
        }
      )}
    </Stack>
  );
};

export default VisitsPage;
