import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import GoogleMapReact from "google-map-react";
import moment from "moment-timezone";

import Collection from "./component/Collections";
import { SearchBar } from "../../../components/CustomInput";
import { ZoneCollectionPoint } from "./component/Location";
import { PickupListItem } from "./component/PickupListItem";
import { CustomDatePicker } from "../../../components/CustomDatePicker";
import { fetchZoneList, listZoneRoutes } from "../../../actions/zone";
import {
  fetchAllDrivers,
  fetchAllVehicles,
  fetchVehicleRouteList,
} from "../../../actions/fleet";
import { fetchPickupPoints } from "../../../actions/pickup";
import { getDays, getFormatedTime } from "../../../utils/date";
import {
  calculateZoomLevel,
  findPlacesByText,
  getCenterFromPoints,
} from "../../../utils/map";
import { showNotification } from "../../../utils/helpers";
import ZoneSerivce from "../../../services/ZoneService";
import FleetService from "../../../services/FleetService";
import WasteService from "../../../services/WasteService";
import { AddVehicleModal, AddZoneModal } from "../../../components/zone/Modal";

const PickupRoutes = () => {
  const dispatch = useDispatch();

  const onClickMap = () => {};
  const mapRef = useRef(null);
  const vehicleAnchor = useRef(null);

  const orgData = useSelector((state) => state.organizations.profile);
  const drivers = useSelector((state) => state.fleet.drivers);
  const pickupList = useSelector((state) => state.pickup.pickupList);
  const { zoneList, zoneRoutes } = useSelector((state) => state.zone);
  const { vehicles, vehicleRoutes } = useSelector((state) => state.fleet);

  const [assignData, setAssignData] = useState([]);
  const [currentVehicleRawWastes, setCurrentVehicleRawWastes] = useState([]);
  const [zoom, setZoom] = useState(20);
  const [zoneData, setZoneData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [vehiclePopoverVisible, setVehiclePopover] = useState(false);
  const [vehicleFilter, setVehicleFilter] = useState("");
  const [driverPopoverVisible, setDriverPopover] = useState(false);
  const [driverFilter, setDriverFilter] = useState("");
  const driverAnchor = useRef(null);
  const [datePopover, setDatePopover] = useState(false);
  const dateAnchor = useRef(null);
  const [searchWord, setSearchWord] = useState("");
  const [dateHeaderUnassigned, setDateHeaderUnassigned] = useState([]);
  const [dateHeaderAssigned, setDateHeaderAssigned] = useState([]);
  const [currentPickup, setCurrentPickup] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: null,
    lng: null,
  });
  const [currentZone, setCurrentZone] = useState(null);
  const [currentDate, setCurrentDate] = useState(Date.now());
  const [currentVehicle, setCurrentVehicle] = useState(null);
  const [assignVehicle, setAssignVehicle] = useState(null);
  const [currentDateVehicle, setCurrentDateVehicle] = useState(Date.now());
  const [currentDriver, setCurrentDriver] = useState(null);
  const [zonePickupList, setZonePickupList] = useState([]);
  const [vehiclePickupList, setVehiclePickupList] = useState([]);
  const [addZoneModalOpen, setAddZoneModalOpen] = useState(false);
  const [addVehicleModalOpen, setAddVehicleModalOpen] = useState(false);
  const [vehiclePickupListIds, setVehiclePickupListIds] = useState([]);
  const [tempPickupList, setTempPickupList] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [assignDate, setAssignDate] = useState(
    moment(new Date()).tz(orgData.timezone).valueOf()
  );
  const [isAssigning, setIsAssigning] = useState(false);

  useEffect(() => {
    for (let pickup of zonePickupList) {
      const text =
        pickup?.name ||
        "" + pickup?.addr_country ||
        "" + pickup?.addr_street ||
        "" + pickup?.addr_city ||
        "";
      if (text.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0) {
        setMapCenter({
          lat: pickup.latitude,
          lng: pickup.longitude,
        });
        return;
      }
    }
    findPlacesByText(searchWord)
      .then((res) => {
        setMapCenter(res);
      })
      .catch((err) => {});
  }, [searchWord, zonePickupList]);

  useEffect(() => {
    // set assigned date header
    let tempAssigned = moment(vehicleRoutes[0]?.date).tz(orgData.timezone);
    vehicleRoutes?.map((item, index) => {
      if (tempAssigned.isBefore(moment(item?.date).tz(orgData.timezone))) {
        tempAssigned = moment(item?.date).tz(orgData.timezone);
      }
      return true;
    });
    const daysAssigned =
      tempAssigned.diff(moment().tz(orgData.timezone), "days") + 2 > 21
        ? tempAssigned.diff(moment().tz(orgData.timezone), "days") + 2
        : 21;
    const dataAssigned = getDays(orgData.timezone, daysAssigned);
    setDateHeaderAssigned(dataAssigned);
  }, [orgData, vehicleRoutes]);

  useEffect(() => {
    // set unassigned date header
    let tempUnassigned = moment(zoneRoutes[0]?.date).tz(orgData.timezone);
    zoneRoutes?.map((item, index) => {
      if (tempUnassigned.isBefore(moment(item?.date).tz(orgData.timezone))) {
        tempUnassigned = moment(item?.date).tz(orgData.timezone);
      }
      return true;
    });
    const daysUnassigned =
      tempUnassigned.diff(moment().tz(orgData.timezone), "days") + 2 > 21
        ? tempUnassigned.diff(moment().tz(orgData.timezone), "days") + 2
        : 21;
    const dataUnassigned = getDays(orgData.timezone, daysUnassigned);
    setDateHeaderUnassigned(dataUnassigned);
  }, [orgData, zoneRoutes]);

  useEffect(() => {
    let points = zonePickupList
      .filter((pickup, _) => {
        if (pickup.latitude && pickup.longitude) return true;
        return false;
      })
      .map((pickup, _) => {
        return {
          latitude: pickup.latitude,
          longitude: pickup.longitude,
        };
      });

    const res = getCenterFromPoints(points);

    if (res) {
      setMapCenter({
        lat: res.center.latitude,
        lng: res.center.longitude,
      });
      if (mapRef.current) {
        let px = Math.min.call(
          mapRef.current.clientWidth,
          mapRef.current.clientHeight
        );
        const zoomLevel = ~~(calculateZoomLevel(res.distance, px) - 1);
        if (zoomLevel) {
          setZoom(zoomLevel);
        } else {
          setZoom(16);
        }
      }
    } else {
      const firstPoint = getCenterFromPoints(tempPickupList);
      if (firstPoint) {
        setMapCenter({
          lat: firstPoint.center.latitude,
          lng: firstPoint.center.longitude,
        });
        if (mapRef.current) {
          let px = Math.min.call(
            mapRef.current.clientWidth,
            mapRef.current.clientHeight
          );
          const zoomLevel = ~~(calculateZoomLevel(firstPoint.distance, px) - 1);
          if (zoomLevel) {
            setZoom(zoomLevel);
          } else {
            setZoom(16);
          }
        }
      }
    }
  }, [zonePickupList, mapRef, currentZone, tempPickupList]);

  useEffect(() => {
    if (assignVehicle) {
      FleetService.getVehicleDetail(assignVehicle._id)
        .then((res) => {
          setCurrentVehicleRawWastes(res.rawWastes);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [assignVehicle]);

  useEffect(() => {
    dispatch(fetchZoneList());
    dispatch(listZoneRoutes());
    dispatch(fetchAllVehicles());
    dispatch(fetchPickupPoints());
    dispatch(fetchVehicleRouteList());
    dispatch(fetchAllDrivers());
  }, [dispatch]);

  useEffect(() => {
    ZoneSerivce.listPickupRoutes(currentZone, currentDate).then((res) => {
      setZonePickupList(res.data.data);
    });
  }, [currentZone, currentDate]);

  useEffect(() => {
    FleetService.fetchVehicleRoutePickup(
      currentVehicle?._id,
      currentDateVehicle
    )
      .then((res) => {
        if (!isFirstLoad) {
          setVehiclePickupList(res.data.data);
        } else {
          setTempPickupList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentDateVehicle, currentVehicle, isFirstLoad]);

  useEffect(() => {
    for (let driver of drivers) {
      if (driver.name === currentVehicle?.driver) {
        setCurrentDriver(driver);
        return;
      }
    }
    setCurrentDriver(null);
  }, [currentVehicle, drivers]);

  useEffect(() => {
    let temp = [];
    for (let zone of zoneList) {
      let d = {};
      for (let routes of zoneRoutes) {
        if (routes.zone === zone._id) {
          d[routes.date] = {
            vehicles: routes.pickup_points,
            collectionCount: routes.pickup_points.length,
          };
        }
      }
      temp.push({
        zone: zone,
        data: d,
        name: zone.name,
      });
    }
    setZoneData(temp);

    const dResult = {};
    for (const item of zoneRoutes) {
      if (!dResult[item.date]) {
        dResult[item.date] = {
          vehicles: [],
          collectionCount: 0,
        };
      }

      dResult[item.date].vehicles.push(...item.pickup_points);
      dResult[item.date].vehicles = [...new Set(dResult[item.date].vehicles)];
      dResult[item.date].collectionCount = dResult[item.date].vehicles.length;
    }
    const tempResult = {
      zone: {},
      data: dResult,
      name: "All Zones",
    };
    temp.unshift(tempResult);
  }, [zoneList, zoneRoutes]);

  const assignToVehicle = async () => {
    let data = [];
    for (let key of Object.keys(assignData)) {
      if (assignData[key] && assignData[key] !== undefined) {
        const wastes = assignData[key] || [];
        let available_wastes = [];
        for (let waste of wastes) {
          if (
            currentVehicleRawWastes.find((ws) => ws._id === waste._id) !== -1
          ) {
            available_wastes.push(waste._id);
          }
        }
        if (available_wastes.length > 0) {
          data.push({
            date: assignDate,
            pickup_point: key,
            vehicle: assignVehicle._id,
            driver: currentDriver._id,
            wastes: available_wastes,
          });
        }
      }
    }
    if (data.length === 0) {
      showNotification(
        "You have at least 1 waste available to assign to current vehicle",
        "info"
      );
    } else {
      setIsAssigning(true);
      for (let item of data) {
        try {
          await WasteService.assignWastesToVehicle(item);
        } catch (err) {
          showNotification("Error happend in API call", "error");
          setIsAssigning(false);
        }
      }
      setIsAssigning(false);
      setIsFirstLoad(true);
      refetchData();
      showNotification("successfully assigned", "success");
    }
  };

  const refetchData = () => {
    ZoneSerivce.listPickupRoutes(currentZone, currentDate).then((res) => {
      setZonePickupList(res.data.data);
    });
    // dispatch(fetchVehicleRouteList());
    dispatch(fetchZoneList());
    dispatch(listZoneRoutes());
    dispatch(fetchAllVehicles());
    dispatch(fetchPickupPoints());
    dispatch(fetchVehicleRouteList());
    dispatch(fetchAllDrivers());
  };

  useEffect(() => {
    let temp = [];
    for (let vehicle of vehicles) {
      let d = {};
      for (let routes of vehicleRoutes) {
        if (routes.vehicle === vehicle._id) {
          d[routes.date] = {
            pickup_points: routes.pickup_points,
            collectionCount: routes.pickup_points.length,
          };
        }
      }
      temp.push({
        vehicle: vehicle,
        data: d,
        name: vehicle.name,
        plate: vehicle.reg_plate,
      });
    }
    setVehicleData(temp);

    const dResult = {};
    for (const item of vehicleRoutes) {
      if (!dResult[item.date]) {
        dResult[item.date] = {
          pickup_points: [],
          collectionCount: 0,
        };
      }

      dResult[item.date].pickup_points.push(...item.pickup_points);
      dResult[item.date].pickup_points = [
        ...new Set(dResult[item.date].pickup_points),
      ];
      dResult[item.date].collectionCount =
        dResult[item.date].pickup_points.length;
    }
    const tempResult = {
      vehicle: {},
      data: dResult,
      name: "All Vehicles",
    };
    temp.unshift(tempResult);
  }, [vehicles, vehicleRoutes]);

  const currentZoneChange = useCallback(
    (index) => {
      if (index > 0) {
        if (zoneList.length > 0) setCurrentZone(zoneList[index - 1]._id);
      } else {
        setCurrentZone(null);
      }
    },
    [zoneList]
  );

  const currentVehicleDateChange = useCallback(
    (index) => {
      setIsFirstLoad(false);
      setCurrentDateVehicle(
        getFormatedTime(Date.now(), orgData.timezone) + index * 86400 * 1000
      );
    },
    [orgData.timezone]
  );

  const currentDateZoneChange = useCallback(
    (index) => {
      setCurrentDate(
        getFormatedTime(Date.now(), orgData.timezone) + index * 86400 * 1000
      );
    },
    [orgData.timezone]
  );

  const currentVehicleChange = useCallback(
    (index) => {
      setIsFirstLoad(false);
      if (index > 0) {
        if (vehicles.length > 0) {
          setCurrentVehicle(vehicles[index - 1]);
        }
      } else {
        setCurrentVehicle(null);
      }
    },
    [vehicles]
  );

  const selectPickup = (pickup, type) => {
    if (pickup?.pickup_point) {
      const obj = {
        id: pickup.pickup_point,
        type: type,
      };
      let ind = currentPickup.find(
        (item) => item?.id === obj?.id && item?.type === obj?.type
      );
      if (!ind) {
        setMapCenter({
          lat: pickup.latitude,
          lng: pickup.longitude,
        });
        setCurrentPickup([
          ...currentPickup,
          {
            id: pickup.pickup_point,
            type: type,
          },
        ]);

        let tempAssignData = { ...assignData };
        let wastes = [];
        for (let ws of pickup.wastes) {
          const index = currentVehicleRawWastes.findIndex((_ws) => {
            return _ws._id === ws._id;
          });
          if (index !== -1) wastes.push(ws);
        }
        tempAssignData[pickup.pickup_point] = wastes;
        setAssignData(tempAssignData);
      } else {
        let temp = [];
        for (let i = 0; i < currentPickup?.length; i++) {
          if (
            currentPickup[i]?.id !== ind?.id ||
            currentPickup[i]?.type !== ind?.type
          ) {
            temp.push(currentPickup[i]);
          }
        }

        setCurrentPickup(temp);
        let tempAssignData = { ...assignData };
        tempAssignData[pickup.pickup_point] = [];
        setAssignData(tempAssignData);
      }
    } else if (pickup?._id) {
      const obj = {
        id: pickup._id,
        type: type,
      };
      let ind = currentPickup.find(
        (item) => item?.id === obj?.id && item?.type === obj?.type
      );
      if (!ind) {
        setMapCenter({
          lat: pickup.latitude,
          lng: pickup.longitude,
        });
        setCurrentPickup([
          ...currentPickup,
          {
            id: pickup._id,
            type: type,
          },
        ]);
      } else {
        let temp = [];
        for (let i = 0; i < currentPickup?.length; i++) {
          if (
            currentPickup[i]?.id !== ind?.id ||
            currentPickup[i]?.type !== ind?.type
          ) {
            temp.push(currentPickup[i]);
          }
        }

        setCurrentPickup(temp);
        let tempAssignData = { ...assignData };
        tempAssignData[pickup._id] = [];
        setAssignData(tempAssignData);
      }
    }
  };

  useEffect(() => {
    let data = {};
    if (currentPickup.length) {
      for (let pickup of vehiclePickupList) {
        if (
          currentPickup.findIndex((elem) => elem.id === pickup.pickup_point) >=
          0
        ) {
          let wastes = [];
          for (let ws of pickup.wastes) {
            const index = currentVehicleRawWastes.findIndex((_ws) => {
              return _ws === ws.name;
            });
            if (index !== -1) wastes.push(ws);
          }
          data[pickup.pickup_point] = wastes;
        }
      }
      for (let pickup of zonePickupList) {
        if (
          currentPickup.findIndex((elem) => elem.id === pickup.pickup_point) >=
          0
        ) {
          let wastes = [];
          for (let ws of pickup.wastes) {
            const index = currentVehicleRawWastes.findIndex((_ws) => {
              return _ws === ws.name;
            });
            if (index !== -1) wastes.push(ws);
          }
          data[pickup.pickup_point] = wastes;
        }
      }
      setAssignData(data);
    } else {
      setAssignData({});
    }
  }, [
    currentPickup,
    currentVehicleRawWastes,
    vehiclePickupList,
    zonePickupList,
  ]);

  const handleEnableWaste = (pickupId, waste) => {
    let temp = { ...assignData };
    if (temp[pickupId] && temp[pickupId] !== undefined) {
      const index = temp[pickupId].findIndex(
        (value) => value._id === waste._id
      );

      if (index === -1) {
        temp[pickupId].push(waste);
        setAssignData(temp);
      }
    } else {
      temp[pickupId] = [waste];
      setAssignData(temp);
    }
  };
  const handleDisableWaste = (pickupId, waste) => {
    let temp = { ...assignData };
    if (temp[pickupId] && temp[pickupId] !== undefined) {
      const index = temp[pickupId].findIndex(
        (value) => value._id === waste._id
      );
      if (index !== -1) {
        temp[pickupId].splice(index, 1);
        setAssignData(temp);
      }
    }
  };

  const handleAddZone = () => {
    setAddZoneModalOpen(true);
  };

  const handleAddVehicle = () => {
    setAddVehicleModalOpen(true);
  };

  useEffect(() => {
    let tempIds = [];
    vehiclePickupList?.map((item) => {
      return tempIds.push(item?.pickup_point);
    });
    setVehiclePickupListIds(tempIds);
  }, [vehiclePickupList]);

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        sx={{ position: "relative" }}
      >
        <Stack>
          <Collection
            id={"collection1"}
            onRowChange={currentZoneChange}
            onColChange={currentDateZoneChange}
            collectionTitle="Add Zone"
            dateHeader={dateHeaderUnassigned}
            data={zoneData}
            onClick={handleAddZone}
          />
          <Collection
            id={"collection2"}
            data={vehicleData}
            dateHeader={dateHeaderAssigned}
            color="#7EA7C8"
            collectionTitle="Add Vehicle"
            backgroundColor="#363546"
            title="Assigned Collections"
            listTitle="Vehicle Name"
            onRowChange={currentVehicleChange}
            onColChange={currentVehicleDateChange}
            onClick={handleAddVehicle}
          />
        </Stack>
        <Stack width={"500px"} py="16px" pt="20px" maxHeight={"100vh"}>
          <Stack
            sx={{
              overflowY: "auto",
            }}
          >
            <Stack
              flexDirection={"row"}
              sx={{ fontSize: "14px", fontFamily: "Manrope" }}
              alignItems={"center"}
              justifyContent={"flex-end"}
              px={2}
            >
              <p style={{ fontWeight: 700, paddingRight: "5px" }}>Sort by: </p>
              Urgency
            </Stack>
            <Stack
              sx={{
                fontSize: "14px",
                fontFamily: "Manrope",
                fontWeight: 700,
                backgroundColor: "#F0EEE9",
              }}
              px={1}
            >
              Assigned to {currentVehicle?.name}
            </Stack>
            <Divider />
            {vehiclePickupList
              .filter((pickup) => pickup.assigned)
              .sort((a, b) => {
                let s1 = a.wastes.reduce(
                  (a, b) => a.existing_ratio + b.existing_ration,
                  0
                );
                let s2 = b.wastes.reduce(
                  (a, b) => a.existing_ratio + b.existing_ration,
                  0
                );
                return s2 - s1;
              })
              .map((pickup, index) => {
                return (
                  <PickupListItem
                    comfortableWastes={currentVehicleRawWastes}
                    noVehicleSelected={currentPickup.length === 0}
                    title={pickup.pickup_point_name}
                    key={pickup.pickup_point}
                    number={index + 1}
                    assigned={true}
                    wastes={pickup.wastes}
                    location={
                      pickup.addr_street
                        ? pickup.addr_street + "," + pickup.addr_country
                        : "Address is not defined"
                    }
                    selected={
                      !!currentPickup.find(
                        (item) =>
                          item?.id === pickup.pickup_point &&
                          item?.type === "assigned"
                      )
                    }
                    id={pickup.pickup_point}
                    onEnable={handleEnableWaste}
                    onDisable={handleDisableWaste}
                    onSelect={() => {
                      selectPickup(pickup, "assigned");
                    }}
                  />
                );
              })}

            <Stack
              flexDirection={"row"}
              sx={{ fontSize: "14px", fontFamily: "Manrope" }}
              alignItems={"center"}
              justifyContent={"flex-end"}
              px={2}
            >
              <p style={{ fontWeight: 700, paddingRight: "5px" }}>Sort by: </p>
              Urgency
            </Stack>
            <Stack
              sx={{
                fontSize: "14px",
                fontFamily: "Manrope",
                fontWeight: 700,
                backgroundColor: "#F0EEE9",
              }}
              px={1}
            >
              All Collection Points
            </Stack>
            <Divider />
            {zonePickupList
              .sort((a, b) => {
                let s1 = a.wastes.reduce(
                  (a, b) => a.existing_ratio + b.existing_ration,
                  0
                );
                let s2 = b.wastes.reduce(
                  (a, b) => a.existing_ratio + b.existing_ration,
                  0
                );
                return s2 - s1;
              })
              .map((pickup, index) => {
                return (
                  <PickupListItem
                    comfortableWastes={currentVehicleRawWastes}
                    noVehicleSelected={currentPickup.length === 0}
                    title={pickup.pickup_point_name}
                    key={pickup.pickup_point}
                    wastes={pickup.wastes}
                    assigned={false}
                    location={
                      pickup.addr_street
                        ? pickup.addr_street + "," + pickup.addr_country
                        : "Address is not defined"
                    }
                    selected={
                      !!currentPickup.find(
                        (item) =>
                          item?.id === pickup.pickup_point &&
                          item?.type === "unassigned"
                      )
                    }
                    id={pickup.pickup_point}
                    onEnable={handleEnableWaste}
                    onDisable={handleDisableWaste}
                    onSelect={() => {
                      selectPickup(pickup, "unassigned");
                    }}
                  />
                );
              })}
          </Stack>
        </Stack>
        <Stack width={"100%"} position={"relative"} ref={mapRef}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API }}
            center={mapCenter}
            zoom={zoom}
            onClick={onClickMap}
          >
            {zonePickupList.map((pickup, index) => {
              return (
                <ZoneCollectionPoint
                  pickupId={pickup.pickup_point}
                  key={index}
                  lat={pickup.latitude}
                  lng={pickup.longitude}
                  inZone={true}
                  selected={
                    !!currentPickup.find(
                      (item) =>
                        item?.id === pickup.pickup_point &&
                        item?.type === "unassigned"
                    )
                  }
                  assigned={false}
                  number={index + 1}
                  showNumber={false}
                  onSelect={() => {
                    selectPickup(pickup, "unassigned");
                  }}
                  timezone={orgData?.timezone}
                />
              );
            })}

            {pickupList.map((pickup, index) => {
              const res = zonePickupList.findIndex((item, _) => {
                return item._id === pickup._id;
              });

              if (res === -1) {
                return (
                  <ZoneCollectionPoint
                    pickupId={pickup._id}
                    key={index}
                    lat={pickup.latitude}
                    lng={pickup.longitude}
                    inZone={true}
                    selected={currentPickup.find(
                      (item) =>
                        item?.id === pickup._id &&
                        item?.type ===
                          (vehiclePickupListIds.indexOf(pickup._id) === -1
                            ? "unassigned"
                            : "assigned")
                    )}
                    assigned={
                      vehiclePickupListIds.indexOf(pickup._id) === -1
                        ? false
                        : true
                    }
                    number={
                      vehiclePickupListIds.indexOf(pickup._id) === -1
                        ? null
                        : vehiclePickupListIds.indexOf(pickup._id) + 1
                    }
                    showNumber={vehiclePickupListIds.includes(pickup._id)}
                    onSelect={() => {
                      if (vehiclePickupListIds.indexOf(pickup._id) === -1) {
                        selectPickup(pickup, "unassigned");
                      } else {
                        selectPickup(pickup, "assigned");
                      }
                    }}
                    timezone={orgData?.timezone}
                  />
                );
              } else {
                return null;
              }
            })}
          </GoogleMapReact>
          <Stack
            sx={{
              position: "absolute",
              zIndex: 10,
              top: "10px",
              alignItems: "center",
              justifyContent: "center",
              transform: "translateX(-50%)",
            }}
            left={"50%"}
          >
            <SearchBar
              onSearchChange={(val) => {
                setSearchWord(val);
              }}
              sx={{
                width: "350px",
              }}
              rounded
              placeholder="Search location or collection points"
              fullWidth={true}
            />
          </Stack>
          <Stack
            padding={"16px"}
            flexDirection={"row"}
            justifyContent={"center"}
            gap={1}
            position={"relative"}
          >
            <Box
              position={"absolute"}
              left={"50%"}
              top={-80}
              alignItems={"center"}
              sx={{
                transform: "translate(-50%,-50%)",
              }}
              display={"flex"}
              flexDirection={"column"}
              gap={1}
            >
              <Box
                borderRadius={2}
                sx={{
                  backgroundColor: "white",
                  fontSize: "10px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  fontFamily: "Manrope",
                  paddingX: "10px",
                  paddingY: "2px",
                }}
              >
                {currentPickup.length > 0
                  ? `${currentPickup.length} LOCATION SELECTED`
                  : "PLEASE SELECT THE LOCATIONS"}
              </Box>
              {currentPickup.length > 0 && (
                <Stack
                  flexDirection={"row"}
                  justifyContent={"center"}
                  gap={1}
                  sx={{
                    backgroundColor: "background.brown",
                    borderRadius: "10px",
                    padding: 1,
                    color: "white",
                    fontFamily: "Manrope",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      borderRadius: "8px",
                      backgroundColor: datePopover ? "white" : "#4A4959",
                      padding: 1,
                      letterSpacing: "0.18px",
                    }}
                    maxWidth={"90px"}
                    width={"90px"}
                    onClick={() => {
                      setDatePopover(true);
                    }}
                    ref={dateAnchor}
                  >
                    <Box
                      sx={{
                        fontSize: "10px",
                        color: "#C0D4E4",
                        fontWeight: 700,
                      }}
                    >
                      DATE
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 700,
                        fontSize: "12px",
                        color: datePopover ? "#6D6D6D" : "white",
                      }}
                    >
                      {new Date(assignDate).toLocaleDateString("en-us", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      })}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      cursor: "pointer",
                      borderRadius: "8px",
                      backgroundColor: vehiclePopoverVisible
                        ? "white"
                        : "#4A4959",
                      padding: 1,
                      letterSpacing: "0.18px",
                    }}
                    maxWidth={"130px"}
                    width={"130px"}
                    ref={vehicleAnchor}
                    onClick={() => {
                      setVehiclePopover(true);
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "10px",
                        color: "#C0D4E4",
                        fontWeight: 700,
                      }}
                    >
                      VEHICLE
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 700,
                        fontSize: "12px",
                        color: vehiclePopoverVisible ? "#6D6D6D" : "white",
                        overflow: "hidden",
                      }}
                    >
                      {assignVehicle
                        ? `${assignVehicle.reg_plate} - ${assignVehicle.name}`
                        : "undefined"}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      cursor: "pointer",
                      borderRadius: "8px",
                      backgroundColor: driverPopoverVisible
                        ? "white"
                        : "#4A4959",
                      padding: 1,
                      letterSpacing: "0.18px",
                    }}
                    width={"90px"}
                    maxWidth={"90px"}
                    ref={driverAnchor}
                    onClick={() => {
                      setDriverPopover(true);
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "10px",
                        color: "#C0D4E4",
                        fontWeight: 700,
                      }}
                    >
                      DRIVER
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 700,
                        fontSize: "12px",
                        color: driverPopoverVisible ? "#6D6D6D" : "white",
                        overflow: "hidden",
                      }}
                    >
                      {currentDriver?.name || "undefined"}
                    </Box>
                  </Box>
                  <LoadingButton
                    sx={{
                      backgroundColor: "#EBF155",
                      paddingX: "12px",
                      paddingY: "10px",
                      borderRadius: "8px",
                      color: "#23222D",
                      fontSize: "16px",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                    onClick={assignToVehicle}
                    loading={isAssigning}
                  >
                    Assign
                  </LoadingButton>
                </Stack>
              )}
            </Box>
          </Stack>
          <Popover
            open={vehiclePopoverVisible}
            onClose={() => {
              setVehiclePopover(false);
            }}
            anchorEl={vehicleAnchor?.current}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Stack
              sx={{
                borderRadius: "8px",
              }}
            >
              <Stack px={1} py={0.5}>
                <SearchBar
                  placeholder="Search vehicle"
                  onSearchChange={(value) => {
                    setVehicleFilter(value);
                  }}
                />
              </Stack>

              <Stack overflow={"auto"} maxHeight={"386px"}>
                {vehicles
                  .filter((vehicle, _) => {
                    if (
                      vehicle.name
                        .toLowerCase()
                        .indexOf(vehicleFilter.toLowerCase()) >= 0 ||
                      vehicle.reg_plate
                        .toLowerCase()
                        .indexOf(vehicleFilter.toLowerCase()) >= 0
                    )
                      return true;
                    return false;
                  })
                  .map((vehicle, index) => {
                    return (
                      <Stack
                        px={2}
                        py={"10px"}
                        key={index}
                        borderTop={"1px solid #EEECE7"}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#EEECE7",
                          },
                        }}
                        onClick={() => {
                          const driver = drivers?.filter(
                            (item) => item?.name === vehicle?.driver
                          );
                          const result = driver?.length > 0 ? driver[0] : null;
                          setAssignVehicle(vehicle);
                          setVehiclePopover(false);
                          setCurrentDriver(result);
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {vehicle?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: "#9F9E9E",
                          }}
                        >
                          {vehicle?.reg_plate}
                        </Typography>
                      </Stack>
                    );
                  })}
              </Stack>
            </Stack>
          </Popover>
          <Popover
            open={driverPopoverVisible}
            onClose={() => {
              setDriverPopover(false);
            }}
            anchorEl={driverAnchor?.current}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Stack
              sx={{
                borderRadius: "8px",
              }}
            >
              <Stack px={1} py={0.5}>
                <SearchBar
                  placeholder="Search driver"
                  onSearchChange={(value) => {
                    setDriverFilter(value);
                  }}
                />
              </Stack>

              <Stack overflow={"auto"} maxHeight={"386px"}>
                {drivers
                  .filter((driver, _) => {
                    if (
                      driver.name
                        .toLowerCase()
                        .indexOf(driverFilter.toLowerCase()) >= 0
                    )
                      return true;
                    return false;
                  })
                  .map((driver, index) => {
                    return (
                      <Stack
                        px={2}
                        py={"10px"}
                        key={index}
                        borderTop={"1px solid #EEECE7"}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#EEECE7",
                          },
                        }}
                        onClick={() => {
                          setCurrentDriver(driver);
                          setDriverPopover(false);
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {driver.name}
                        </Typography>
                      </Stack>
                    );
                  })}
              </Stack>
            </Stack>
          </Popover>
          <Popover
            open={datePopover}
            onClose={() => {
              setDatePopover(false);
            }}
            anchorEl={dateAnchor?.current}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <CustomDatePicker
                onClickDate={(val) => {
                  let date = moment(new Date(val))
                    .tz(orgData.timezone)
                    .valueOf();
                  setAssignDate(date);
                  setDatePopover(false);
                }}
              />
            </LocalizationProvider>
          </Popover>
        </Stack>
        {/* {process.env.REACT_APP_ENV === "prod" && (
          <>
            <Stack sx={classes.comingSoonModal} gap={4}>
              <Stack
                flexDirection={"row"}
                sx={{
                  fontSize: "32px",
                  fontWeight: 700,
                  alignItems: "center",
                  fontFamily: "Manrope",
                }}
                gap={2}
              >
                <RouteIcon fill="black" /> <Box>Routes</Box>
              </Stack>
              <Stack
                sx={{
                  fontSize: "14px",
                  fontFamily: "Manrope",
                  lineHeight: "20px",
                }}
              >
                The new Routes feature is designed to bring together Fleets,
                Warehouses, Pickup Points and Visits into a single pane of glass
                where WMOs and Transport Organisations can efficiently plan and
                schedule optimised routes for collections.
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  fontSize: "14px",
                  fontFamily: "Manrope",
                  lineHeight: "20px",
                }}
                gap={6}
              >
                <Stack sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                  HOLISTIC OVERVIEW
                </Stack>
                <Stack>
                  Routes provide you with a single view of all the datapoints
                  you require to effectively plan you collection schedules.
                </Stack>
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  fontSize: "14px",
                  fontFamily: "Manrope",
                  lineHeight: "20px",
                }}
                gap={6}
              >
                <Stack sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                  Collection Urgency
                </Stack>
                <Stack>
                  Routes incorporates our collection urgency scaling so that you
                  can see which collection points require urgent collections
                </Stack>
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  fontSize: "14px",
                  fontFamily: "Manrope",
                  lineHeight: "20px",
                }}
                gap={6}
              >
                <Stack sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                  Optimized Routes
                </Stack>
                <Stack>
                  Using our enhanced algorithms, we can assist you in planning
                  the most optimal route for your drivers.
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                zIndex: 1,
                backgroundColor: "rgba(217, 217, 217, 0.75)",
              }}
            ></Stack>
          </>
        )} */}
      </Stack>
      <AddZoneModal
        open={addZoneModalOpen}
        handleClose={() => {
          setAddZoneModalOpen(false);
        }}
      />
      <AddVehicleModal
        open={addVehicleModalOpen}
        handleClose={() => {
          setAddVehicleModalOpen(false);
        }}
      />
    </>
  );
};

export default PickupRoutes;
