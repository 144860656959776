import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inventories: [],
  tickets: [],
  summary: {},
};

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setTicketsSummaryInfo: (state, action) => {
      state.summary = action.payload;
    },
    addTicketsInventory: (state, action) => {
      state.inventories.find((iv) => iv.id === action.payload).amount++;
    },
    updateTicketStatus: (state, action) => {
      state.tickets.find((tk) => tk.id === action.payload.id).status =
        action.payload.status;
    },
    setTicketsList: (state, action) => {
      state.tickets = action.payload;
    },
  },
});

export const {
  addTicketsInventory,
  updateTicketStatus,
  setTicketsList,
  setTicketsSummaryInfo,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
