import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentInventory: [],
  currentWarehouse: "Rocket snack inventory",
  inventories: [],
  offers: [],
  categories: [],
  names: [],
  cur_offer: {},
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setCurrentInventory: (state, action) => {
      state.currentInventory = action.payload;
    },
    setCurrentWarehouse: (state, action) => {
      state.currentWarehouse = action.payload;
    },
    setInventories: (state, action) => {
      state.inventories = action.payload;
    },
    setOffers: (state, action) => {
      state.offers = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setNames: (state, action) => {
      state.names = action.payload;
    },
    setCurOffer: (state, action) => {
      state.cur_offer = action.payload;
    },
  },
});

export const {
  setInventories,
  setCurrentInventory,
  setOffers,
  setCurrentWarehouse,
  setCategories,
  setNames,
  setCurOffer,
} = inventorySlice.actions;

export default inventorySlice.reducer;
