import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { Button, Skeleton, Stack } from "@mui/material";

// project imports
import { PageTitle } from "../../../components/Typography";

// sx
import { classes } from "../../../utils/class";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../../components/Table";
import { SearchBar } from "../../../components/CustomInput";
import { CardPrimary } from "../../../components/Card";
import {
  activateWarehouse,
  archiveWarehouse,
  deactivateWarehouse,
  fetchWarehouseDetail,
  fetchWarehouseList,
  unarchiveWarehouse,
} from "../../../actions/warehouse";
import { EditDetail, StateSpan } from "../../../components/StateSpan";
import { setCurrentWarehouseId } from "../../../slices/warehouseSlice";
import ChangeStatusDialog from "./Dialog/ChangeStatusDialog";
import RemoveWarehouseDialog from "./Dialog/RemoveWarehouseDialog";
import {
  BACKOFFICE,
  DRIVER,
  OWNER,
  SALES,
  SALES_MANAGER,
  WAREHOUSE_MANAGER,
} from "../../../utils/constants";

// action import
const WarehouseListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterModel, setFilterModel] = useState({ items: [] });
  const warehouseData = useSelector((state) => state.warehouse.warehouses);
  const [statusChangeDlgInfo, setStatusChangeDlgInfo] = useState({
    open: false,
    id: null,
  });
  const [rmvDlgInfo, setRmvDlgInfo] = useState({
    open: false,
    id: null,
  });
  const [cardInfo, setCardInfo] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const role = useSelector((state) => state.user.role);

  useEffect(() => {
    dispatch(
      fetchWarehouseList(() => {
        setIsLoaded(true);
      })
    );
  }, [dispatch]);
  useEffect(() => {
    let sum1 = 0,
      sum2 = 0;
    warehouseData.forEach((wr) => {
      sum1 += wr.count_of_raw_wastes;
      sum2 += wr.count_of_deposits;
    });
    setCardInfo({
      count: warehouseData.length,
      rwtypes: sum1,
      deposits: sum2,
    });
  }, [warehouseData]);

  const onConfirmChangeStatus = () => {
    dispatch(deactivateWarehouse(statusChangeDlgInfo.id));
    setStatusChangeDlgInfo({
      open: false,
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    { field: "count_of_raw_wastes", headerName: "RW types", flex: 1 },
    { field: "count_of_deposits", headerName: "Nº Deposits", flex: 1 },
    { field: "count_of_vehicles", headerName: "Nº Vehicles", flex: 1 },
    {
      field: "count_of_pickup_points",
      headerName: "Nº Collection Points",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (props) => {
        let stateOptions = [];
        if (role === SALES_MANAGER || role === SALES || role === DRIVER) {
          stateOptions = [];
        } else if (role === WAREHOUSE_MANAGER) {
          if (props.row.status === "active") {
            stateOptions = [
              {
                label: "Inactive",
                value: "inactive",
              },
            ];
          } else {
            stateOptions = [
              {
                label: "Active",
                value: "active",
              },
            ];
          }
        } else {
          if (props.row.status === "active") {
            stateOptions = [
              {
                label: "Inactive",
                value: "inactive",
              },
            ];
          } else {
            if (props.row.archived) {
              stateOptions = [
                {
                  label: "Unarchive",
                  value: "unarchive",
                },
              ];
            } else {
              stateOptions = [
                {
                  label: "Active",
                  value: "active",
                },
                {
                  label: "Archive",
                  value: "archive",
                },
              ];
            }
          }
        }

        let editOptions = [];
        if (role === SALES_MANAGER || role === SALES || role === DRIVER) {
          editOptions = [
            {
              label: "Edit warehouse",
              value: "action edit",
            },
          ];
        } else if (role === WAREHOUSE_MANAGER) {
          if (props.row.status === "active") {
            editOptions = [
              {
                label: "Edit warehouse",
                value: "action edit",
              },
              {
                label: "Deactive warehouse",
                value: "deactive",
              },
            ];
          } else {
            editOptions = [
              {
                label: "Edit warehouse",
                value: "action edit",
              },
              {
                label: "Active warehouse",
                value: "active",
              },
            ];
          }
        } else {
          if (props.row.status === "active") {
            editOptions = [
              {
                label: "Edit warehouse",
                value: "action edit",
              },
              {
                label: "Deactive warehouse",
                value: "deactive",
              },
              {
                label: "Remove warehouse",
                value: "action remove",
              },
            ];
          } else {
            if (props.row.archived) {
              editOptions = [
                {
                  label: "Edit warehouse",
                  value: "action edit",
                },
                {
                  label: "Unarchive warehouse",
                  value: "unarchive",
                },
                {
                  label: "Remove warehouse",
                  value: "action remove",
                },
              ];
            } else {
              editOptions = [
                {
                  label: "Edit warehouse",
                  value: "action edit",
                },
                {
                  label: "Active warehouse",
                  value: "active",
                },
                {
                  label: "Archive warehouse",
                  value: "archive",
                },
                {
                  label: "Remove warehouse",
                  value: "action remove",
                },
              ];
            }
          }
        }

        return (
          <>
            <StateSpan
              status={props.row.archived ? "archive" : props.value}
              options={stateOptions}
              onStateChangeHandler={(status) => {
                if (status === "active" && props.row.status === "inactive") {
                  dispatch(activateWarehouse(props.row._id));
                } else if (
                  status === "inactive" &&
                  props.row.status === "active"
                ) {
                  dispatch(deactivateWarehouse(props.row._id));
                } else if (status === "archive") {
                  dispatch(archiveWarehouse(props.row._id));
                } else if (status === "unarchive") {
                  dispatch(unarchiveWarehouse(props.row._id));
                }
              }}
            />
            <EditDetail
              options={editOptions}
              props={props}
              onStateChangeHandler={async (status) => {
                if (status === "action edit") {
                  dispatch(setCurrentWarehouseId(props.row._id));
                  navigate("/warehouse-profile");
                } else if (status === "action remove") {
                  if (role === BACKOFFICE || role === OWNER)
                    setRmvDlgInfo({
                      open: true,
                      id: props.row._id,
                    });
                } else if (status === "active") {
                  if (
                    role !== SALES_MANAGER &&
                    role !== SALES &&
                    role !== DRIVER
                  ) {
                    dispatch(activateWarehouse(props.row._id));
                  }
                } else if (status === "inactive") {
                  if (
                    role !== SALES_MANAGER &&
                    role !== SALES &&
                    role !== DRIVER
                  ) {
                    dispatch(deactivateWarehouse(props.row._id));
                  }
                } else if (status === "archive") {
                  if (
                    role !== SALES &&
                    role !== SALES_MANAGER &&
                    role !== DRIVER &&
                    role !== WAREHOUSE_MANAGER
                  ) {
                    dispatch(archiveWarehouse(props.row._id));
                  }
                } else if (status === "unarchive") {
                  if (
                    role !== SALES &&
                    role !== SALES_MANAGER &&
                    role !== DRIVER &&
                    role !== WAREHOUSE_MANAGER
                  ) {
                    dispatch(unarchiveWarehouse(props.row._id));
                  }
                }
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <Stack direction={"column"} marginTop={3} gap={2} paddingX="60px">
      <Stack direction={"row"} justifyContent={"space-between"}>
        <PageTitle>Warehouse</PageTitle>
        <Stack direction="row">
          <Button
            variant="outlined"
            sx={classes.buttonPrimary}
            onClick={() => navigate("/add-warehouse")}
            disabled={
              role === SALES_MANAGER ||
              role === SALES ||
              role === DRIVER ||
              role === WAREHOUSE_MANAGER
            }
          >
            Add warehouse
          </Button>
        </Stack>
      </Stack>
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        {isLoaded ? (
          <CardPrimary
            type={1}
            title="Nº Warehouses"
            content={cardInfo.count}
          />
        ) : (
          <Skeleton variant="rounded" width={"162px"} height={"86px"} />
        )}
        {isLoaded ? (
          <CardPrimary
            type={2}
            title="Nº RW types"
            content={cardInfo.rwtypes}
          />
        ) : (
          <Skeleton variant="rounded" width={"162px"} height={"86px"} />
        )}
        {isLoaded ? (
          <CardPrimary
            type={2}
            title="Nº Deposits"
            content={cardInfo.deposits}
          />
        ) : (
          <Skeleton variant="rounded" width={"162px"} height={"86px"} />
        )}
      </Stack>
      <Stack justifyContent={"flex-start"}>
        {isLoaded ? (
          <SearchBar
            placeholder="Search warehouse"
            onSearchChange={(value) => {
              setFilterModel({
                items: [
                  { id: 1, field: "name", operator: "contains", value: value },
                ],
              });
            }}
            sx={{ width: "275px" }}
          />
        ) : (
          <Skeleton variant="rounded" width={"275px"} />
        )}
      </Stack>

      {isLoaded ? (
        <DataTable
          rows={warehouseData}
          columns={columns}
          sx={classes.grid}
          filterModel={filterModel}
          onRowClick={(params) => {
            dispatch(setCurrentWarehouseId(params.row._id));
            dispatch(fetchWarehouseDetail(params.row._id));
            navigate("/warehouse-profile");
          }}
        />
      ) : (
        <Skeleton width={"100%"} height={"200px"} />
      )}

      <RemoveWarehouseDialog
        open={rmvDlgInfo.open}
        id={rmvDlgInfo.id}
        onClose={() => setRmvDlgInfo({ open: false })}
      />
      <ChangeStatusDialog
        open={statusChangeDlgInfo.open}
        onClose={() => setStatusChangeDlgInfo(false)}
        onConfirm={onConfirmChangeStatus}
      />
    </Stack>
  );
};

export default WarehouseListPage;
