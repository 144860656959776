import { Button, Modal, Stack } from "@mui/material";
import { useState } from "react";
import { classes } from "../../../../utils/class";
import { CPhoneInput, CTextInput } from "../../../../components/CustomInput";
import { SubTitle } from "../../../../components/Typography";

const ProfileDialog = ({ open, onClose, onUpdate }) => {
  const [name, setName] = useState();
  const [phone_number, setPhoneNumber] = useState();
  const [mobile_phone_number, setMobileNumber] = useState();
  const [validation, setValidation] = useState(false);
  const handleSave = () => {
    setValidation(true);
    if (
      name &&
      name !== "" &&
      mobile_phone_number &&
      mobile_phone_number !== ""
    )
      onUpdate({
        name: name,
        phone: phone_number,
        mobile_phone: mobile_phone_number,
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        padding={"34px 42px"}
        gap={3}
        sx={{
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "400px",
        }}
      >
        <SubTitle>Update Profile Information</SubTitle>
        <CTextInput
          required
          validation={validation}
          title="Name"
          value={name}
          onChange={(value) => {
            setName(value);
          }}
        />
        <CPhoneInput
          label={"Phone"}
          value={phone_number}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
          fullWidth
        />
        <CPhoneInput
          required
          validation={validation}
          label={"Mobile phone"}
          value={mobile_phone_number}
          onChange={(e) => {
            setMobileNumber(e.target.value);
          }}
          fullWidth
        />
        <Stack>
          <Button sx={classes.buttonSave} onClick={handleSave}>
            Update
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ProfileDialog;
