import { Stack, Box, Button, Tooltip } from "@mui/material";
import { classes } from "../../../utils/class";
import {
  PageTitle,
  InputTitle,
  SubTitle,
} from "../../../components/Typography";
import { CAutoComplete, CTextInput } from "../../../components/CustomInput";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useState } from "react";
import CreateTeamDialog from "./Dialog/CreateTeamDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  createTeam,
  fetchRoleList,
  fetchTeamList,
  inviteMember,
} from "../../../actions/member";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const AddMember = () => {
  const navigate = useNavigate();
  const { teamList, roles, currentTeam } = useSelector((state) => state.member);
  const dispatch = useDispatch();
  const [createDialog, setCreateDialog] = useState(false);
  const [teamOption, setTeamOption] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const [team, setTeam] = useState(null);
  const [newTeam, setNewTeam] = useState(null);
  useEffect(() => {
    if (currentTeam) {
      setTeam({
        ...currentTeam,
        value: currentTeam.name,
      });
    }
  }, [currentTeam]);
  const save = async () => {
    dispatch(
      inviteMember(
        {
          email,
          role,
          team: team._id,
        },
        () => {
          navigate("/settings/member");
        }
      )
    );
  };

  const cancel = () => {
    window.history.back();
  };

  useEffect(() => {
    dispatch(fetchTeamList());
    dispatch(fetchRoleList());
  }, [dispatch]);

  useEffect(() => {
    const list = roles.map((role, index) => {
      return {
        ...role,
        name: role.display_name,
        value: role.name,
      };
    });
    setRoleOption(list);
  }, [roles]);
  useEffect(() => {
    const list = teamList.map((team, index) => {
      return {
        ...team,
        value: team.name,
      };
    });
    setTeamOption(list);
  }, [teamList]);
  return (
    <>
      <Stack sx={{ minHeight: "100vh", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingX: "60px",
            paddingY: "20px",
          }}
        >
          <PageTitle>Members</PageTitle>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={cancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={save}
            >
              Save
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "36px",
              minWidth: "422px",
              justifyContent: "center",
            }}
          >
            <SubTitle> Invite member </SubTitle>
            <CTextInput
              title="Email or username"
              required
              placeholder="Insert email"
              onChange={(val) => {
                if (val) setEmail(val);
              }}
            />
            <CAutoComplete
              title="Role"
              placeholder="Select a role"
              required
              options={roleOption}
              fixed
              onChange={(val) => {
                if (val) {
                  setRole(val.value);
                }
              }}
            />
            <CAutoComplete
              placeholder="Select a team"
              title={
                <Stack direction={"row"} alignItems={"center"}>
                  <InputTitle required>
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            background: "black",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "black",
                          },
                        },
                      }}
                      title="Select team"
                      placement="left"
                    >
                      Team
                    </Tooltip>
                  </InputTitle>
                </Stack>
              }
              onAddNewItem={(value) => {
                setNewTeam(value);
                setCreateDialog(true);
              }}
              options={teamOption}
              onChange={(value) => {
                setTeam(value);
              }}
              value={team}
            />
          </Box>
        </Box>
      </Stack>
      <CreateTeamDialog
        open={createDialog}
        team={newTeam}
        handleClose={() => {
          setCreateDialog(false);
        }}
        handleContinue={(data) => {
          dispatch(createTeam(data));
        }}
      />
    </>
  );
};

export default AddMember;
