import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CAutoComplete, CTextInput } from "../../../components/CustomInput";
import { classes } from "../../../utils/class";
import { STATE_CLOSED } from "../../../utils/type";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";
import { updateTicket } from "../../../actions/ticket";
import { useEffect } from "react";
import {
  getDateStringFromNum,
  getTimeStringFromNum,
  showNotification,
} from "../../../utils/helpers";
import { fetchAllUsers } from "../../../actions/organizations";

const TicketDetailDialog = ({ open, handleClose, ticket }) => {
  const dispatch = useDispatch();
  const [pickupPoint, setPickupPoint] = useState("");
  const [proNotes, setProNotes] = useState("");
  const [intNotes, setIntNotes] = useState("");
  const [resNotes, setResNotes] = useState("");
  const [user, setUser] = useState("");
  const users = useSelector((state) => state.organizations.users);
  const orgData = useSelector((state) => state.organizations.profile);

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  useEffect(() => {
    setProNotes(ticket.producer_note);
    setIntNotes(ticket.internal_note);
    setResNotes(ticket.resolution_note);
    setPickupPoint(ticket.pickup_point_name);
    setUser(ticket.responsible_name);
  }, [ticket]);

  const renderInfoRow = (title, value) => {
    return (
      <Stack direction="row">
        <Typography
          sx={{ fontSize: "18px", fontWeight: "bold", width: "8rem" }}
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: "18px" }}>{value}</Typography>
      </Stack>
    );
  };

  const onClickSave = useCallback(() => {
    if (!user) {
      showNotification("Please select responsible", "error");
      return;
    }
    dispatch(
      updateTicket(ticket._id, {
        responsible: users.find((u) => u.name === user)._id,
        producer_note: proNotes,
        internal_note: intNotes,
        resolution_note: resNotes,
      })
    );
    handleClose();
  }, [proNotes, intNotes, resNotes, users, user]);

  const getInventoriesToRequest = (items) => {
    let text = "";
    items.forEach(
      (item) =>
        (text += `REF: ${item.ref} - ${item.name} - ${
          item.quantity
        } unidade(s) - ${item.quantity * item.price}${
          item.price_unit === "EUR" ? "€" : "$"
        }\n`)
    );
    return text;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Stack spacing={3} sx={{ minWidth: "500px" }}>
          <Stack
            direction="row"
            sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
          >
            <Stack>
              <Typography sx={{ fontSize: "40px", fontWeight: "bold" }}>
                {ticket.name && ticket.name.indexOf("Ticket") !== -1
                  ? ticket.name
                  : `Ticket #${ticket.name}`}
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>ID{ticket.id}</Typography>
            </Stack>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          {renderInfoRow("Author", ticket.author_name)}
          {renderInfoRow("Purpose", ticket.purpose)}
          {renderInfoRow(
            "Date",
            getDateStringFromNum(ticket.date, orgData.timezone)
          )}
          <CAutoComplete
            title="Collection Point"
            placeholder="Select Collection Point"
            options={[pickupPoint]}
            value={pickupPoint}
            disabled
          />
          <CAutoComplete
            title="Responsible"
            placeholder="Select Responsible"
            options={users.map((u) => u.name)}
            value={user}
            onChange={(v) => setUser(v)}
          />
          {ticket.purpose === "Offer Request" && (
            <CTextInput
              title="Items"
              rows={4}
              value={getInventoriesToRequest(ticket.items)}
              placeholder="Producer notes"
              disabled
            />
          )}
          <CTextInput
            title="Producer notes"
            rows={3}
            value={proNotes}
            placeholder="Producer notes"
            onChange={(v) => setProNotes(v)}
          />
          <CTextInput
            title="Internal notes"
            rows={3}
            value={intNotes}
            placeholder="Internal notes"
            onChange={(v) => setIntNotes(v)}
          />
          {ticket.status === STATE_CLOSED && (
            <>
              {renderInfoRow("Closed by", ticket.closed_by_name)}
              {renderInfoRow(
                "Previous responsible",
                ticket.prev_responsible_name || "-"
              )}
              {renderInfoRow(
                "Date",
                getDateStringFromNum(ticket.closed_at, orgData.timezone)
              )}
              {renderInfoRow(
                "Hour",
                getTimeStringFromNum(ticket.closed_at, orgData.timezone)
              )}
            </>
          )}
          <CTextInput
            title="Resolution notes"
            rows={3}
            value={resNotes}
            placeholder="Resolution notes"
            onChange={(v) => setResNotes(v)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ margin: "20px" }}>
          <Button
            variant="outlined"
            sx={classes.buttonCancel}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={classes.buttonSave}
            onClick={onClickSave}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default TicketDetailDialog;
