import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Button } from "@mui/material";

import { InputTitle, PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

import {
  CDateInput,
  CSelect,
  CTextInput,
} from "../../../../components/CustomInput";
import { useState } from "react";
import { InfoOutlined } from "@mui/icons-material";
import dayjs from "dayjs";
import { formatDate, strToDate } from "../../../../utils/date";
const EditDaysOffDialog = ({
  open = false,
  data = {},
  handleClose = function () {},
  handleContinue = function () {},
}) => {
  const [reason, setReason] = useState("Holiday");
  const [from, setFrom] = useState(dayjs(Date.now()));
  const [to, setTo] = useState(dayjs(Date.now()));
  const [note, setNote] = useState("days off");

  const orgData = useSelector((state) => state.organizations.profile);

  const fromChange = (value) => {
    if (new Date(value).getTime() <= new Date(to).getTime()) {
      setFrom(value);
    } else {
      setTo(value);
    }
  };
  const toChange = (value) => {
    if (new Date(value).getTime() >= new Date(from).getTime()) setTo(value);
    else setFrom(value);
  };

  useEffect(() => {
    if (data.start) {
      setFrom(dayjs(strToDate(data.start)));
      setTo(dayjs(strToDate(data.end)));
      setReason(data.reason);
      setNote(data.note);
    }
  }, [data]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            Width: "520px",
            maxWidth: "520px",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} padding={5}>
            <PageTitle>Edit days off</PageTitle>
            <Stack direction={"row"} gap={1}>
              <CDateInput
                title="Start date"
                changeHandler={fromChange}
                value={from}
              />
              <CDateInput
                title="End date"
                changeHandler={toChange}
                value={to}
              />
            </Stack>
            <CSelect
              title="Reason list"
              value={reason}
              onSelectChange={(v) => setReason(v)}
              options={["Holiday", "Sick", "Other"]}
            />
            {reason === "Other" && (
              <CTextInput
                rows={4}
                value={note}
                title="Description"
                placeholder="Describe reason"
                onChange={(value) => {
                  setNote(value);
                }}
              />
            )}
            <Stack direction={"row"} alignItems={"center"}>
              {data?.days_off_history && data.days_off_history?.length > 0 && (
                <InputTitle disabled>
                  {data.days_off_history[0].action} by{" "}
                  {data.days_off_history[0].user} @22{" "}
                  {new Date(data.days_off_history[0].date).toLocaleString()}
                </InputTitle>
              )}
              {/* <InfoOutlined /> */}
            </Stack>
            <Box
              padding={1}
              sx={{
                backgroundColor: "text.primary",
                color: "text.contrast",
                borderRadius: "20px",
              }}
            >
              {data?.days_off_history &&
                data.days_off_history.map((dayOff, index) => {
                  return (
                    <InputTitle disabled>
                      {dayOff.action} by {dayOff.user} @22{" "}
                      {new Date(dayOff.date).toLocaleString()}
                    </InputTitle>
                  );
                })}
            </Box>
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <Button sx={classes.buttonCancel} onClick={handleClose}>
                Discard changes
              </Button>
              <Button
                sx={classes.buttonSave}
                onClick={() => {
                  handleContinue(data._id, {
                    reason: reason,
                    start: formatDate(from.toDate(), orgData.timezone),
                    end: formatDate(to.toDate(), orgData.timezone),
                    note: note,
                  });
                  handleClose();
                }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default EditDaysOffDialog;
