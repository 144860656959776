import { Button, Stack, Typography } from "@mui/material";
import { classes } from "../../utils/class";
import { MediumTitle, SmallContent } from "../Typography";

const CollectionPointIdWizard = ({
  Icon,
  title,
  content,
  buttonText,
  onAdd,
  active = false,
  data = {},
  handleDelete,
  handleEdit,
}) => {
  return (
    <Stack alignItems={"center"} justifyContent={"center"} gap={2}>
      <Stack sx={active ? classes.normalWizardActive : classes.normalWizard}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {Icon}
          {active && data ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <rect width="20" height="20" rx="10" fill="green" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2222 7.84843L13.3528 6.00018L9 10.3038L6.86942 8.22241L5 10.0707L9 14.0002L15.2222 7.84843Z"
                fill="white"
              />
            </svg>
          ) : (
            <Button sx={classes.buttonCancel} onClick={onAdd}>
              {buttonText}
            </Button>
          )}
        </Stack>
        <Stack sx={classes.normalWizardTitle}>{title}</Stack>
        <Stack sx={classes.normalWizardContent}>{content}</Stack>
        {active && data && (
          <Stack
            sx={{
              marginTop: "32px",
              backgroundColor: "#FFFFFF",
              padding: "24px",
            }}
            gap="24px"
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack>
                <Typography
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "27px",
                  }}
                >
                  {data.collectionPointName}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap="16px">
                <Button
                  variant="outlined"
                  sx={classes.buttonPrimary}
                  onClick={handleEdit}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  sx={classes.buttonCancel}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Stack>
            </Stack>
            {data.officialId && data.officialIdCountry && (
              <Stack gap="4px">
                <MediumTitle>Official ID</MediumTitle>
                <SmallContent>
                  {data.officialIdCountry}
                  {data.officialId}
                </SmallContent>
              </Stack>
            )}
            <Stack gap="4px">
              <MediumTitle>Description</MediumTitle>
              <SmallContent>{data.description}</SmallContent>
            </Stack>
            <Stack gap="4px">
              <MediumTitle>Coordinates</MediumTitle>
              <SmallContent>
                {data.latitude} - {data.longitude}
              </SmallContent>
            </Stack>
            <Stack gap="4px">
              <MediumTitle>Address</MediumTitle>
              <SmallContent>
                {data.addr_city && data.addr_city}
                {data.addr_city && <br />}
                {data.addr_street}
                {data.addr_postcode && ","}
                {data.addr_postcode}
              </SmallContent>
              <a
                onClick={() => {}}
                style={{
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "12px",
                    color: "#6293BC",
                  }}
                >
                  View on the map
                </Typography>
              </a>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export { CollectionPointIdWizard };
