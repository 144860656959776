import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// material ui
import { Box, Stack, Typography } from "@mui/material";

// proj imports
import { PageTitle } from "../../../../components/Typography";
import UnsavedDialog from "../dialogs/UnsavedDialog";
import { setCompanyProfileInfo } from "../../../../slices/settingsSlice";
import { CImageUpload } from "../../../../components/CImageUpload";

const ProfilePictureView = ({ setStage }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const company = useSelector((state) => state.settings.company);
  const [name, setName] = useState(company.username);
  const [password, setPassword] = useState("");
  const [unsavedOpen, setUnsavedOpen] = useState(false);

  const onClickCancel = () => {
    if (name === company.username && password.length === 0) {
      setStage(0);
      return;
    }
    setUnsavedOpen(true);
  };

  const handleSave = () => {
    if (name.length === 0 || password.length === 0) {
      enqueueSnackbar("Some fields are empty. Please input required fields.", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        autoHideDuration: 2000,
      });
      return;
    }
    dispatch(
      setCompanyProfileInfo({
        username: name,
        password: password,
      })
    );
    enqueueSnackbar("Data was updated with success.", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      autoHideDuration: 2000,
    });
    setStage(0);
  };

  return (
    <Box>
      <PageTitle>Company Profile</PageTitle>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          display: "flex",
          minHeight: "80%",
          marginTop: "40px",
        }}
      >
        <Box sx={{ width: "40%", minWidth: "300px" }}></Box>
        <Stack sx={{ width: "100%", maxWidth: "500px" }} spacing={2}>
          <Box>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Profile picture
            </Typography>
          </Box>
          <CImageUpload setStage={setStage} />
        </Stack>
      </Stack>
      <UnsavedDialog
        open={unsavedOpen}
        handleLeave={() => setStage(0)}
        handleClose={() => setUnsavedOpen(false)}
        handleSave={handleSave}
      />
    </Box>
  );
};

export default ProfilePictureView;
