// material ui
import { Box, Button, Stack } from "@mui/material";

// proj imports
import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import UnsavedDialog from "../dialogs/UnsavedDialog";
import { updateOrganizationInfo } from "../../../../actions/organizations";
import { CustomMap } from "../../../../components/CustomMap";

export const AnyReactComponent = ({ text }) => <div>{text}</div>;

const LocationView = ({ setStage }) => {
  const company = useSelector((state) => state.organizations.profile);
  const [unsavedOpen, setUnsavedOpen] = useState(false);
  const { profile } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [location_data, setLocationData] = useState({
    latitude: company.latitude || profile.latitude,
    longitude: company.longitude || profile.longitude,
    addr_street: company.addr_street,
    addr_apartment: company.addr_apartment,
    addr_city: company.addr_city,
    addr_postcode: company.addr_postcode,
    addr_country: company.addr_country,
  });

  const handleSave = () => {
    dispatch(updateOrganizationInfo(location_data, () => setStage(0)));
  };

  return (
    <Box>
      <PageTitle>Company Profile</PageTitle>
      <Stack padding={"34px 42px"} gap={"22px"} sx={{ minWidth: "600px" }}>
        <PageTitle>Location</PageTitle>
        <CustomMap
          description={"company"}
          data={location_data}
          onChange={(d) => {
            setLocationData(d);
          }}
        />
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={() => setStage(0)}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Stack>
      <UnsavedDialog
        open={unsavedOpen}
        handleLeave={() => setStage(0)}
        handleClose={() => setUnsavedOpen(false)}
        handleSave={handleSave}
      />
    </Box>
  );
};

export default LocationView;
