import PickupService from "../services/PickupService";
import ProducerService from "../services/ProducerService";
import {
  setExtraInfo,
  setProducerDetail,
  setProducerDocumentList,
  setProducerInventoryList,
  setProducerList,
  setProducerOfferList,
  setProducerPickupList,
  setProducerRawWasteList,
  setProducerTransferList,
  setProducerVisitList,
} from "../slices/producerSlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";

export const fetchProducerVisitList = (id) => (dispatch) => {
  ProducerService.fetchVisitList(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setProducerVisitList(res.data.data));
      }
    })
    .catch((err) => {});
};

export const fetchProducers =
  (callBack = () => {}) =>
  (dispatch) => {
    ProducerService.fetchProducerList()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setProducerList(res.data.data.data));
          const {
            count_of_missing_manager,
            count_expiring_documents,
            total_collected,
          } = res.data.data;
          dispatch(
            setExtraInfo({
              count_of_missing_manager,
              count_expiring_documents,
              total_collected,
            })
          );
          callBack();
        }
      })
      .catch((err) => {});
  };

export const fetchProducerPickupList = (id) => (dispatch) => {
  ProducerService.fetchProducerPickupList(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setProducerPickupList(res.data.data));
      }
    })
    .catch((err) => {});
};

export const fetchProducerRawWasteList = (id) => (dispatch) => {
  ProducerService.fetchProducerRawWasteList(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setProducerRawWasteList(res.data.data));
      }
    })
    .catch((err) => {});
};

export const fetchProducerTransferList = (id) => (dispatch) => {
  ProducerService.fetchProducerTransferList(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setProducerTransferList(res.data.data));
      }
    })
    .catch((err) => {});
};

export const fetchProducerDocumentList = (id) => (dispatch) => {
  ProducerService.fetchProducerDocumentList(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setProducerDocumentList(res.data.data));
      }
    })
    .catch((err) => {});
};

export const fetchProducerInventoryList = (id) => (dispatch) => {
  ProducerService.fetchInventoryList(id, false)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setProducerInventoryList(res.data.data));
      }
    })
    .catch((err) => {});
};

export const fetchProducerOfferList = (id) => (dispatch) => {
  ProducerService.fetchInventoryList(id, true)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setProducerOfferList(res.data.data));
      }
    })
    .catch((err) => {});
};

export const removeRawWaste = (producerId, id) => (dispatch) => {
  ProducerService.removeRawWaste(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchProducerRawWasteList(producerId));
        showNotification("Raw waste removed", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const addProducerRawWaste = (id, data) => (dispatch) => {
  ProducerService.addProducerRawWaste(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchProducerRawWasteList(id));
        showNotification("New Raw waste added", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const fetchProducerDetail = (id) => (dispatch) => {
  ProducerService.fetchProducerDetail(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setProducerDetail(res.data.data));
      }
    })
    .catch((err) => {});
};

export const addProducer =
  (data, callBack = () => {}) =>
  (dispatch) => {
    ProducerService.createProducer(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("New Producer added", "success");
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const activateProducer = (id) => (dispatch) => {
  ProducerService.activateProducer(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Producer is activated", "success");
        dispatch(fetchProducers());
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const deactivateProducer = (id) => (dispatch) => {
  ProducerService.deactivateProducer(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Producer is deactivated", "success");
        dispatch(fetchProducers());
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const archiveProducer = (id) => (dispatch) => {
  ProducerService.archiveProducer(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Producer is archived", "success");
        dispatch(fetchProducers());
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const unarchiveProducer = (id) => (dispatch) => {
  ProducerService.unarchiveProducer(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Producer is unarchived", "success");
        dispatch(fetchProducers());
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const validateDocument = (id, producerId) => (dispatch) => {
  PickupService.validateDocument(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchProducerDocumentList(producerId));
        showNotification("Document has been validated", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const revokeDocument = (id, producerId) => (dispatch) => {
  PickupService.revokeDocument(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchProducerDocumentList(producerId));
        showNotification("Document has been revoked", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
