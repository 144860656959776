import { file_axios } from "../utils/axios";

const uploadFile = (file) => {
  var data = new FormData();
  data.append("file", file);
  if (file.size >= 134217728) {
    return new Promise((res, rej) => {
      rej({
        response: {
          data: {
            message: "Maximum file size is 128KB",
          },
        },
      });
    });
  } else
    return file_axios.post("/storage/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
};

const retrieveFile = (data) => {
  return file_axios.get("/retrieve/view", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const retrievePolicy = () => {
  return file_axios.get("/retrieve/list-policies");
};

const FileService = {
  retrievePolicy,
  uploadFile,
  retrieveFile,
};

export default FileService;
