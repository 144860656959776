// material ui
import { Box, Button, Stack, Typography } from "@mui/material";

// proj import
import { useDispatch, useSelector } from "react-redux";
import { COUNTRIES_INFO } from "../../../../utils/constants";
import { InfoCard, renderTwoColumnsInfo } from "../../../../components/Card";
import { classes } from "../../../../utils/class";
import { useEffect, useState } from "react";
import DeleteDialog from "../dialogs/DeleteDialog";
import CustomHidden from "../../../../components/CustomHidden";
import { removeOrg } from "../../../../actions/organizations";
import { useNavigate } from "react-router-dom";
import { deleteAccount } from "../../../../actions/user";

const ProfileMainView = ({ setStage, user = null }) => {
  const data = useSelector((state) => state.user.profile);
  const orgInfo = useSelector((state) => state.organizations.profile);
  const profile = user === null ? data : user;
  const [del_account, setDelAccount] = useState(false);
  const [del_org, setDelOrg] = useState(false);
  const [deleteOrgEnabled, setDeleteOrgEnabled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (orgInfo.isLoaded) {
      if (orgInfo.backoffice_admins.indexOf(profile.user_id) === -1) {
        setDeleteOrgEnabled(false);
      } else {
        setDeleteOrgEnabled(true);
      }
    } else {
      setDeleteOrgEnabled(false);
    }
  }, [orgInfo, profile]);
  return (
    <>
      <Box>
        <Stack direction="row" sx={{ marginTop: "20px" }} spacing={2}>
          <CustomHidden min={1110}>
            <InfoCard title="Identification" onClickEdit={() => setStage(1)}>
              {renderTwoColumnsInfo("Name", profile?.name)}
              {renderTwoColumnsInfo("Email", profile?.email)}
              {renderTwoColumnsInfo("Username", profile?.username)}
              <Box>
                <Typography
                  sx={{
                    color: "text.accent",
                    fontSize: "12px",
                    fontWeight: 800,
                    fontFamily: "Manrope",
                    textTransform: "uppercase",
                  }}
                >
                  Nationality
                </Typography>
                {profile?.nationality && (
                  <Typography sx={{ fontSize: "16px" }}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${profile?.nationality.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${profile?.nationality.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    &nbsp;
                    {
                      COUNTRIES_INFO.find(
                        (info) => info.code === profile?.nationality
                      ).label
                    }
                  </Typography>
                )}
              </Box>
            </InfoCard>
            <InfoCard title="Contacts" onClickEdit={() => setStage(2)}>
              <Stack gap={1}>
                {renderTwoColumnsInfo(
                  "Phone",
                  profile?.phone ? `+${profile?.phone}` : "-"
                )}
                {renderTwoColumnsInfo(
                  "Mobile Phone",
                  profile?.mobile_phone ? `+${profile?.mobile_phone}` : "-"
                )}
              </Stack>
            </InfoCard>
          </CustomHidden>
          <CustomHidden min={0} max={1109}>
            <Stack spacing={3}>
              <InfoCard title="Identification" onClickEdit={() => setStage(1)}>
                {renderTwoColumnsInfo("Name", profile?.name)}
                {renderTwoColumnsInfo("Email", profile?.email)}
                {renderTwoColumnsInfo("Username", profile?.username)}
                <Box>
                  <Typography
                    sx={{
                      color: "text.accent",
                      fontSize: "12px",
                      fontWeight: 800,
                      fontFamily: "Manrope",
                      textTransform: "uppercase",
                    }}
                  >
                    Nationality
                  </Typography>
                  {profile?.nationality && (
                    <Typography sx={{ fontSize: "16px" }}>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${profile?.nationality.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${profile?.nationality.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      &nbsp;
                      {
                        COUNTRIES_INFO.find(
                          (info) => info.code === profile?.nationality
                        ).label
                      }
                    </Typography>
                  )}
                </Box>
              </InfoCard>
              <InfoCard title="Contacts" onClickEdit={() => setStage(2)}>
                <Stack gap={1}>
                  {renderTwoColumnsInfo(
                    "Phone",
                    profile?.phone ? `+${profile?.phone}` : "-"
                  )}
                  {renderTwoColumnsInfo(
                    "Mobile Phone",
                    profile?.mobile_phone ? `+${profile?.mobile_phone}` : "-"
                  )}
                </Stack>
              </InfoCard>
            </Stack>
          </CustomHidden>
        </Stack>
      </Box>
      <Box sx={classes.dangerZone} my={4}>
        <Box sx={{ fontSize: "24px", fontWeight: "700" }}>Danger Zone</Box>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Stack
            sx={{
              fontSize: "14px",
              color: "black",
              fontWeight: 400,
              lineHeight: "24px",
              maxWidth: "410px",
            }}
          >
            <Box sx={{ fontWeight: 700 }}>Delete Account:</Box>
            <Box>
              This will delete your account on UCO.Network and UCO Collection
              App.
            </Box>
          </Stack>
          <Stack>
            <Button
              sx={classes.buttonDelete}
              onClick={() => {
                setDelAccount(true);
              }}
            >
              Delete Account
            </Button>
          </Stack>
        </Stack>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Stack
            sx={{
              fontSize: "14px",
              color: "black",
              fontWeight: 400,
              lineHeight: "24px",
              maxWidth: "410px",
            }}
          >
            <Box sx={{ fontWeight: 700 }}>Delete Organisation:</Box>
            <Box>
              This will delete your Organisation including all information and
              users.
            </Box>
          </Stack>
          <Stack>
            <Button
              sx={classes.buttonDelete}
              onClick={() => {
                setDelOrg(true);
              }}
              disabled={!deleteOrgEnabled}
            >
              Delete Organisation
            </Button>
          </Stack>
        </Stack>
      </Box>
      <DeleteDialog
        open={del_account}
        handleClose={() => {
          setDelAccount(false);
        }}
        title={"Delete Account"}
        content={
          "This is a irreversible action. By deleting your account, you will be deleting all your account information and will be logged out, if you wish to log in again you will need to create a new account."
        }
        handleSave={() => {
          dispatch(deleteAccount());
        }}
      />
      <DeleteDialog
        open={del_org}
        handleClose={() => {
          setDelOrg(false);
        }}
        handleSave={() => {
          dispatch(
            removeOrg(() => {
              navigate("/auth/signin-select-org");
            })
          );
        }}
        title={"Delete Organisation"}
        content={
          "This is a irreversible action. By deleting your organisation, you will be deleting all your company information, and configuration. All team members will still be able to log in but they will lose access to your organisation."
        }
      />
    </>
  );
};

export default ProfileMainView;
