import { useState } from "react";
import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { DataTable } from "../../../../components/Table";
import { SearchBar } from "../../../../components/CustomInput";
import { EditDetail } from "../../../../components/StateSpan";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAllRawWastes } from "../../../../actions/waste";
import { addRawWastes } from "../../../../actions/organizations";

const SelectRawWasteView = ({ setStage }) => {
  const dispatch = useDispatch();
  const [filterModel, setFilterModel] = useState({ items: [] });
  const allRawWastes = useSelector((state) => state.waste.raw_wastes);
  const [itemsChecked, setItemsChecked] = useState([]);
  const rawWastes = useSelector((state) => state.organizations.rawWastes);

  useEffect(() => {
    dispatch(fetchAllRawWastes());
  }, [dispatch]);

  const onSave = () => {
    dispatch(
      addRawWastes(
        {
          raw_wastes: itemsChecked,
        },
        setStage
      )
    );
  };

  useEffect(() => {
    const temp = rawWastes.map((rw) => rw.rawWasteId);
    setItemsChecked(temp);
  }, [rawWastes]);

  const columns = [
    {
      field: "check",
      headerName: "",
      flex: 0.1,
      renderCell: (props) => {
        return (
          <Checkbox
            sx={classes.checkBox}
            checked={itemsChecked.indexOf(props.row.id) !== -1}
            onClick={() => {
              const id = itemsChecked.indexOf(props.row.id);
              const temp = [...itemsChecked];
              if (id === -1) {
                temp.push(props.row.id);
                setItemsChecked(temp);
              } else {
                temp.splice(id, 1);
                setItemsChecked(temp);
              }
            }}
          />
        );
      },
    },
    { field: "code", headerName: "LER Code", flex: 0.3 },
    { field: "name", headerName: "Name", flex: 0.5 },
    { field: "capacity", headerName: "C. racio (l - kg)", flex: 0.3 },
    {
      field: "status",
      headerName: "",
      flex: 0.2,
      renderCell: (props) => {
        return (
          <>
            <EditDetail
              options={[]}
              props={props}
              onStateChangeHandler={async (status) => {}}
            />
          </>
        );
      },
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <PageTitle>Add Raw waste</PageTitle>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            sx={classes.buttonCancel}
            onClick={() => setStage(1)}
          >
            Cancel
          </Button>
          <Button variant="outlined" sx={classes.buttonSave} onClick={onSave}>
            Save
          </Button>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "80%",
        }}
      >
        <Box sx={{ marginTop: "20px", width: "300px" }}>
          <SearchBar
            label="Search raw waste"
            onSearchChange={(value) => {
              setFilterModel({
                items: [
                  { id: 1, field: "name", operator: "contains", value: value },
                ],
              });
            }}
          />
        </Box>
        <DataTable
          fit="100%"
          breakpoint="xl"
          rows={allRawWastes}
          columns={columns}
          sx={classes.grid}
          filterModel={filterModel}
          onRowClick={(params, events, details) => {}}
        />
      </Box>
    </Box>
  );
};

export default SelectRawWasteView;
