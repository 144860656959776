import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import { Box, Button, OutlinedInput, Stack, Typography } from "@mui/material";

// project imports
import { InputTitle, PageTitle } from "../../../components/Typography";

//sx class definition
import { classes } from "../../../utils/class";
import { CAutoComplete, CTextInput } from "../../../components/CustomInput";
import { CSelect } from "../../../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import TicketsInventoryPage from "./TicketsInventoryPage";
import { addNewTicket } from "../../../actions/ticket";
import { fetchPickupPoints } from "../../../actions/pickup";
import { fetchInventory } from "../../../actions/inventory";
import { showNotification } from "../../../utils/helpers";

const AddTicketsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stage, setStage] = useState(1);
  const [ticketPurpose, setTicketPurpose] = useState("Other");
  const [selPickup, setSelPickup] = useState(null);
  const [notes, setNotes] = useState("");
  const [itemsToRequest, setItemsToRequest] = useState([]);

  const pickupList = useSelector((state) => state.pickup.pickupList);

  useEffect(() => {
    dispatch(fetchPickupPoints());
    dispatch(fetchInventory(true));
  }, []);

  const getInventoriesToRequest = () => {
    let text = "";
    itemsToRequest.forEach(
      (item) =>
        (text += `REF: ${item.reference} - ${item.name} - ${
          item.amount
        } Qty(s) - ${item.amount * item.offer_price}${
          item.offer_unit === "EUR" ? "€" : "$"
        }\n`)
    );
    return text;
  };

  const onClickCreateTicket = () => {
    if (!selPickup) {
      showNotification("Please input all fields", "error");
      return;
    }
    const req = Object.assign(
      {
        pickup_point: pickupList.find((pp) => pp.name === selPickup)._id,
        purpose: ticketPurpose,
        note: notes,
      },
      ticketPurpose === "Offer Request" && {
        items: itemsToRequest.map((item) => ({
          inventory: item._id,
          quantity: item.amount,
        })),
      }
    );

    dispatch(addNewTicket(req, () => navigate("/tickets")));
  };

  if (stage === 1) {
    return (
      <Box sx={{ minHeight: "100vh", height: "100%", padding: "30px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <PageTitle>Add tickets</PageTitle>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={() => navigate("/tickets")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={classes.buttonSave}
              onClick={onClickCreateTicket}
            >
              Add ticket
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "13px",
              minWidth: "422px",
              justifyContent: "center",
            }}
          >
            <CAutoComplete
              title="Collection Point"
              placeholder="Select Collection Point"
              required
              onChange={(v) => setSelPickup(v)}
              options={pickupList.map((pickup) => pickup.name)}
            />
            <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <CSelect
                label="Ticket purpose"
                required={true}
                borderRound={false}
                options={[
                  "Sales Visit",
                  "Collection Visit",
                  "Offer Request",
                  "Support",
                ]}
                value={ticketPurpose}
                onSelectChange={(v) => setTicketPurpose(v)}
              />
              {ticketPurpose === "Offer Request" && (
                <Typography
                  sx={{
                    fontSize: "10px",
                    width: "500px",
                    marginTop: "5px",
                  }}
                >
                  To request an offer you have to go to inventory to see the
                  available offers that the organization have for you. Please
                  click on the button below to access the inventory.{" "}
                </Typography>
              )}
            </Box>
            {ticketPurpose === "Offer Request" && (
              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <InputTitle>Items to request</InputTitle>
                  <Button
                    variant="contained"
                    sx={classes.buttonPrimary}
                    onClick={() => setStage(1.5)}
                  >
                    Request an offer
                  </Button>
                </Stack>
                <OutlinedInput
                  size="small"
                  multiline
                  rows={4}
                  value={getInventoriesToRequest()}
                  disabled
                />
              </Stack>
            )}
            <CTextInput
              title="Notes"
              rows={2}
              value={notes}
              onChange={(v) => setNotes(v)}
              placeholder="Enter notes"
            />
          </Box>
        </Box>
      </Box>
    );
  }

  if (stage === 1.5) {
    return (
      <TicketsInventoryPage setStage={setStage} setItems={setItemsToRequest} />
    );
  }
};
export default AddTicketsPage;
