import React from "react";
import { useSelector } from "react-redux";

// material-ui
import { Box, Stack, Typography } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

// project imports
import { DataTable } from "../../../../components/Table";

// sx
import { classes } from "../../../../utils/class";

import { CardPrimary } from "../../../../components/Card";
import { getDateStringFromNum } from "../../../../utils/helpers";

// action import
const Transfers = () => {
  const { profile, visits } = useSelector((state) => state.warehouse.current);
  const orgData = useSelector((state) => state.organizations.profile);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {getDateStringFromNum(props.row.date, orgData.timezone)}
        </span>
      ),
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {props.row.purpose === "commercial_visit"
            ? "Commercial Visit"
            : "Waste Pickup"}
        </span>
      ),
    },
    {
      field: "pickup_point_name",
      headerName: "Collection Point",
      width: 150,
      flex: 1,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "transfer",
      headerName: "Transfer",
      width: 300,
      flex: 2,
      renderCell: (props) => {
        const { quantity, ratio } = props.row;
        if (!quantity) return;
        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: "60px" }}>{quantity} kg</Typography>
            <Typography sx={{ color: ratio >= 0 ? "green" : "red" }}>
              {ratio > 0 && "+"}
              {ratio}%
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack>
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        <CardPrimary
          type={2}
          title={"Nº Wastes"}
          content={profile.count_of_deposits}
        />
        <CardPrimary
          type={2}
          title={"Nº Vehicles"}
          content={profile.count_of_vehicles}
        />
        <CardPrimary
          type={2}
          title={"Nº Collection Points"}
          content={profile.count_of_pickup_points}
        />
      </Stack>
      <Box sx={{ padding: "20px" }}>
        <DataTable
          fit="100%"
          rows={visits}
          breakpoint="xl"
          columns={columns}
          sx={classes.grid}
          onRowClick={(params, events, details) => {}}
        />
      </Box>
    </Stack>
  );
};

export default Transfers;
