import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Checkbox, Stack } from "@mui/material";
import { GridLogicOperator } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";

import { classes } from "../../../../utils/class";
import { SUCCESS } from "../../../../utils/type";
import { ERROR } from "../../../../utils/constants";
import { showNotification, snackbarRef } from "../../../../utils/helpers";
import { BigTitle, SubTitle } from "../../../../components/Typography";
import { SearchBar } from "../../../../components/CustomInput";
import { DataTable } from "../../../../components/Table";
import { CModalChange } from "../../../../components/CustomModal";
import { fetchAllRawWastes } from "../../../../actions/waste";
import OrganizationService from "../../../../services/OrganizationService";

const SetupRawWasteInProducer = () => {
  snackbarRef.current = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { raw_wastes } = useSelector((state) => {
    return state.waste;
  });

  const [itemsChecked, setItemsChecked] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [unsavedOpen, setUnsavedOpen] = useState(false);

  const columns = [
    {
      field: "checkbox",
      hideSortIcons: true,
      disableColumnMenu: true,
      width: 100,
      headerName: (
        <Checkbox
          sx={classes.checkBox}
          checked={allCheck}
          onClick={(e) => {
            e.stopPropagation();
            if (allCheck) {
              setAllCheck(false);
              setItemsChecked([]);
            } else {
              setAllCheck(true);
              setItemsChecked(raw_wastes.map((t) => t._id));
            }
          }}
        />
      ),
      renderCell: (props) => (
        <Checkbox
          sx={classes.checkBox}
          checked={itemsChecked.indexOf(props.row._id) !== -1}
          onClick={(e) => {
            e.stopPropagation();
            const id = itemsChecked.indexOf(props.row._id);
            const temp = [...itemsChecked];
            if (id !== -1) {
              temp.splice(id, 1);
            } else {
              temp.push(props.row._id);
            }
            setItemsChecked(temp);
          }}
        />
      ),
    },
    {
      field: "code",
      headerName: "LER Code",
      width: 200,
    },
    {
      field: "name",
      headerName: "Name",
      width: 500,
    },
    {
      field: "capacity",
      headerName: "C.racio(l-kg)",
      width: 100,
    },
  ];

  const saveWaste = () => {
    // OrganizationService.addRawWastes({
    //   raw_wastes: itemsChecked,
    // })
    //   .then((res) => {
    //     if (res.data.message === SUCCESS) {
    //       showNotification("Raw wastes confirmed", "success");
    //       navigate("/auth/reviewing");
    //     }
    //   })
    //   .catch((err) => {
    //     showNotification(
    //       err.response
    //         ? ERROR[err.response.data.message] || "Network Error"
    //         : err.message,
    //       "error"
    //     );
    //   });
    showNotification("The session was saved successfully", "success");
    navigate("/auth/reviewing-producer");
  };

  const onCancelConfirm = () => {
    setUnsavedOpen(false);
    window.history.back();
  };

  const onCloseConfirm = () => {
    setUnsavedOpen(false);
  };

  const onSaveConfirm = () => {
    setUnsavedOpen(false);
    navigate("/auth/reviewing-producer");
  };

  useEffect(() => {
    dispatch(fetchAllRawWastes());
  }, [dispatch]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
      >
        <Stack maxWidth={"420px"} minWidth={"420px"}>
          <Stack
            sx={{ backgroundColor: "#F7F6F3" }}
            pt={"48px"}
            px={"48px"}
            pb={"21px"}
            gap={"21px"}
          >
            <BigTitle>Waste Type</BigTitle>
            <Stack
              maxWidth={"472px"}
              sx={{
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "Manrope",
                fontWeight: 500,
              }}
              gap={4}
            >
              <Box>
                First we need to select the types of Raw waste that you work
                with as an organsition, the list provided follows industry
                Standard naaming and can be searched by waste type and LER
                Codes.
              </Box>
              <Box>
                Once you have completed your onboarding you can manage and
                rename your waste types within <b>Settings - Raw Waste.</b>
              </Box>
            </Stack>
          </Stack>
          <Stack padding={"48px"} gap={"10px"}>
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "27px",
                letterSpacing: "-1%",
              }}
            >
              Why is this important?
            </Box>
            <Box
              sx={{
                fontFamily: "Manrope",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              Defining the raw waste will allow you to allocate waste types to
              warehouses and vehicles which in turn enable you to collect waste
              from producers.
            </Box>
          </Stack>
        </Stack>
        <Stack
          sx={{ backgroundColor: "white", width: "calc(100vw - 420px);" }}
          gap={2}
          px={5}
          py={5}
        >
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setUnsavedOpen(true);
              }}
              sx={classes.buttonCancel}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              onClick={saveWaste}
              sx={classes.orgCreateButton}
            >
              Save Waste
            </Button>
          </Stack>
          <Stack
            sx={{
              alignItems: "flex-start",
              marginTop: "24px",
            }}
          >
            <SubTitle>Add raw waste</SubTitle>
            <SearchBar
              sx={{
                marginTop: "24px",
              }}
              placeholder="Search raw waste"
              onSearchChange={(value) => {
                setFilterModel({
                  items: [
                    {
                      id: 1,
                      field: "name",
                      operator: "contains",
                      value: value,
                    },
                    // {
                    //   id: 2,
                    //   field: "code",
                    //   operator: "contains",
                    //   value: value,
                    // },
                  ],
                  logicOperator: GridLogicOperator.Or,
                });
              }}
            />
          </Stack>
          <DataTable
            fit="calc(100vw - 510px);"
            filterModel={filterModel}
            setFilterModel={setFilterModel}
            columns={columns}
            rows={raw_wastes}
          />
        </Stack>
      </Box>
      <CModalChange
        open={unsavedOpen}
        confirmValue="Continue editing"
        handleCancel={onCancelConfirm}
        handleClose={onCloseConfirm}
        handleSave={onSaveConfirm}
      />
    </>
  );
};
export default SetupRawWasteInProducer;
