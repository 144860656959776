import { certificate_axios } from "../utils/axios";

const fetchCertificatesList = () => {
  return certificate_axios.get("/list-certificates");
};
const revokeCertificate = (id) => {
  return certificate_axios.put(`/revoke-certificate/${id}`);
};
const validateCertificate = (id) => {
  return certificate_axios.put(`/validate-certificate/${id}`);
};
const createCertificate = (data) => {
  return certificate_axios.post(`/create-certificate`, data);
};
const issueCertificatesInBulk = (data) => {
  return certificate_axios.put(`/issue-certificates-in-bulk`, data);
};

const sendDocumentsByEmail = (data) => {
  return certificate_axios.post("/send-documents-by-email", data);
};

const getDocumentsForPrint = (data) => {
  return certificate_axios.post("/get-documents-for-print", data);
};
const CertificateService = {
  sendDocumentsByEmail,
  fetchCertificatesList,
  revokeCertificate,
  createCertificate,
  issueCertificatesInBulk,
  validateCertificate,
  getDocumentsForPrint,
};

export default CertificateService;
