const validateInput = (data) => {
  const errResult = [];
  const keys = Object.keys(data);
  for (let key of keys) {
    if (data[key] === "") errResult.push(key);
  }
  return errResult;
};

export { validateInput };
