export const STATE_ACTIVE = "active";
export const STATE_ENABLED = "enabled";
export const STATE_DISABLED = "disabled";
export const STATE_INACTIVE = "inactive";
export const STATE_ARCHIVE = "archive";
export const STATE_UPCOMING = "upcoming";
export const STATE_COMPLETED = "completed";
export const STATE_ONGOING = "ongoing";
export const STATE_PROGRESS = "progress";
export const STATE_IN_PROGRESS = "in progress";
export const STATE_PENDING = "pending";
export const STATE_REJECTED = "rejected";
export const STATE_CLOSED = "closed";
export const STATE_OVERDUE = "overdue";
export const STATE_PLANNED = "planned";
export const STATE_DISCARDED = "discarded";
export const STATE_DISCARD = "discard";
export const STATE_SUGGESTION = "suggestion";
export const STATE_VALID = "valid";
export const STATE_REVOKED = "revoked";
export const STATE_EXPIRED = "expired";
export const STATE_ON = "state on";
export const STATE_OFF = "state off";
export const STATE_VALIDATED = "validated";
export const STATE_AVAILABLE = "available";
export const STATE_UNAVAILABLE = "unavailable";
// type for modal
export const FLEET_IDENTIFICATION_MODAL = "fleet identification modal";
export const FLEET_VEHICLE_DETAIL_MODAL = "fleet vehicle detail modal";
export const PICKUP_IDENTFICATION_MODAL =
  "collection point identification modal";
export const PICKUP_DETAIL_MODAL = "collection detail modal";

export const PICKUP_LOCATION_MODAL = "collection location modal";
export const PICKUP_SCHEDULE_MODAL = "collection schedule modal";
export const PICKUP_WASTE_PRICE_MODAL = "collection wase price";
export const PICKUP_EXPECTED_MODAL = "collection expected modal";
export const PICKUP_MANAGEMENT_MODAL = "collection management modal";
export const PICKUP_CONTACTS_MODAL = "collection contacts modal";
export const PICKUP_DOCUMENTS_MODAL = "collection documents modal";
export const PICKUP_RELATIONSHIP_MODAL = "collection relationship modal";

export const PRODUCER_LOCATION_MODAL = "producer location modal";
export const PRODUCER_IDENTIFICATION_MODAL = "producer identification modal";
export const PRODUCER_CONTACTS_MODAL = "producer contracts modal";
export const PRODUCER_RESPONSIBLE_MODAL = "producer responsible modal";
export const PRODUCER_SCHEDULE_MODAL = "producer schedule modal";
export const PRODUCER_MANAGEMENT_MODAL = "producer management modal";

export const ZONE_IDENTIFICATION_MODAL = "zone identification modal";
export const ZONE_DETAIL_MODAL = "zone detail modal";

// success & failed
export const SUCCESS = "success";
export const FAILED = "failed";
export const WRONG_2FA = "wrong_2fa_code";
export const NOT_SIGN_IN_USER = "not_sign_in_user";
// crud
export const EDIT_ROW = "action edit";
export const VIEW_ROW = "action view";
export const REMOVE_ROW = "action remove";
export const DELETE_ROW = "action delete";
export const ADD_STOCK = "action add stock";
export const EDIT_STOCK = "action edit stock";
export const DELETE_STOCK = "action delete stock";
export const DEACTIVATE_ITEM = "deactivate item";
export const ACTIVATE_ITEM = "activate item";
export const ARCHIVE_ITEM = "archive item";
export const UNARCHIVE_ITEM = "unarchive item";
export const EDIT_ITEM_CATEGORY = "edit item category";
export const PRICE_HISTORY = "price history";
export const CHANGE_ROLE = "change role";
export const MOVE_MEMBER = "move member";
// badge
export const BADGE_INCOMPLETE = "incomplete";
export const BADGE_A_TO_CLIENT = "A. to client";
export const BADGE_A_TO_DRIVER = "A. to driver";
export const BADGE_UNIQUE = "unique";
export const BADGE_DEPOSIT = "deposit";
export const BADGE_OFFER = "offer";
export const BADGE_LOANED = "loaned";
export const BADGE_IN_WAREHOUSE = "in warehouse";
export const BADGE_DEFAULT = "default";
export const BADGE_GENERIC = "generic";
// inventory

export const MAKE_GENERIC = "make generic";
export const MAKE_UNIQUE = "make unique";
export const ADD_ID = "add id";
export const REMOVE_ID = "remove id";

export const DEFAULT_ZOOM = 13;
export const DETAIL_ZOOM = 13;
