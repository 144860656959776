import {
  Stack,
  Button,
  TextField,
  Divider,
  Input,
  IconButton,
} from "@mui/material";
import { InputTitle, PageTitle, SubTitle } from "../Typography";
import {
  CAutoComplete,
  CDateInput,
  COfficialIdInput,
  CPhoneInput,
  CRadioSelect,
  CSelect,
  CTextInput,
  CheckBoxes,
} from "../CustomInput";
import CloseIcon from "@mui/icons-material/Close";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import { classes } from "../../utils/class";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../../actions/member";
import dayjs from "dayjs";

import { fetchProducerPickupList } from "../../actions/producers";
import FileService from "../../services/FileService";
import { SUCCESS } from "../../utils/type";
import { showNotification, getServerTimestamp } from "../../utils/helpers";
import { ERROR } from "../../utils/constants";
import { setUploadSpinnerStatus } from "../../actions/spinner";

import OrganizationService from "../../services/OrganizationService";
export const ProducerIdentificationModal = ({
  title,
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [name, setName] = useState();
  const [official_id, setOfficialId] = useState();
  const [official_id_country, setOfficialIdCountry] = useState(null);
  useEffect(() => {
    setName(data?.name);
    if (data?.official_id) setOfficialId(data?.official_id);
    if (data?.official_id_country)
      setOfficialIdCountry(data?.official_id_country);
  }, [data]);
  useEffect(() => {
    if (
      data?.official_id_country === null ||
      data?.official_id_country === undefined ||
      data?.official_id_country === ""
    )
      setOfficialIdCountry(data?.vat_number_country);
  }, []);
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <CTextInput
        title="Producer Name"
        required
        placeholder="Mary Spot Restaurant"
        value={name}
        onChange={(value) => {
          setName(value);
        }}
      />

      <COfficialIdInput
        countryCode={official_id_country}
        label={"Official ID"}
        value={official_id}
        onCountryChange={(e, data) => {
          setOfficialIdCountry(data.code);
        }}
        onChange={(e) => {
          setOfficialId(e.target.value);
        }}
      />

      <CTextInput
        title="Company Tax name"
        placeholder="Olive Marpickings, Lda"
        value={data?.tax_name}
        required
        disabled
      />

      <COfficialIdInput
        content="VAT"
        label={"VAT number"}
        required
        placeholder="4349390"
        disabled
        countryCode={data?.vat_number_country}
        value={data?.vat_number}
      />

      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({
              name,
              official_id,
              official_id_country,
            });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const ProducerDetailModal = ({
  title,
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <Stack>
        <InputTitle disabled required sx={{ fontSize: "20px" }}>
          Raw waste
        </InputTitle>
        <CAutoComplete
          disabled
          title="Raw waste type selection"
          required
          placeholder="Select type"
        />
      </Stack>
      <Stack>
        <InputTitle disabled required sx={{ fontSize: "20px" }}>
          Operation code
        </InputTitle>
        <CAutoComplete
          disabled
          title="Operation code selection"
          required
          placeholder="Select type"
        />
      </Stack>
      <Stack>
        <InputTitle required sx={{ fontSize: "20px" }}>
          Warehouse
        </InputTitle>
        <CAutoComplete
          title="Waste destination warehouse"
          required
          placeholder="Select warehouse"
        />
      </Stack>
      <Stack>
        <InputTitle required sx={{ fontSize: "20px" }}>
          Zone
        </InputTitle>
        <CAutoComplete
          title="Define zones for collection point"
          required
          placeholder="Select zones"
          checkBox
        />
      </Stack>
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({});
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const ProducerWasteModal = ({
  title,
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>

      <CRadioSelect
        title="Payment"
        direction="column"
        required
        options={["Comapny to customer", "Customer to company"]}
      />

      <CTextInput title="Price per kg" type="number" endIcon={"€"} />

      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({});
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const ProducerExpectedModal = ({
  title,
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <Stack gap={1} width={"50%"}>
        <CTextInput
          title="Frequency"
          required
          type="text"
          endIcon={"Days"}
          placeholder="00"
        />
      </Stack>

      <Stack gap={1} direction={"row"}>
        <CTextInput
          title="Quantity"
          required
          type="text"
          endIcon={"kg"}
          placeholder="100"
        />
        <CTextInput
          title="Impurity %"
          required
          type="text"
          endIcon={"%"}
          placeholder="100"
        />
      </Stack>

      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({});
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
export const ProducerManagementModal = ({
  title,
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.member);
  const [manager, setManager] = useState(null);
  useEffect(() => {
    setManager({
      name: data?.manager_name,
      value: data?.manager,
    });
  }, [data]);
  useEffect(() => {
    dispatch(fetchUserList());
  }, []);
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>

      <CAutoComplete
        fixed
        options={users.map((user, index) => {
          return {
            name: user.name,
            value: user._id,
          };
        })}
        required
        title="Producer manager"
        disableDropdown
        value={manager}
        onChange={(value) => {
          setManager(value);
        }}
      />
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler({ manager: manager?.value });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const ProducerDocumentsModal = ({
  docType = null,
  onCancelHandler,
  onSaveHandler,
  producerId = null,
}) => {
  const [type, setType] = useState(null);
  const [url, setUrl] = useState(null);
  const [same_issue_expiration_date, setSameIssue] = useState(false);
  const [same_validity, setSameValidity] = useState(false);

  const [purpose, setPurpose] = useState([]);
  const [issue_date, setIssueDate] = useState(Date.now());
  const [validity, setValidity] = useState("1 month");
  const [file, setFile] = useState(null);
  const pickupList = useSelector(
    (state) => state.producer.producerPickupPointList
  );
  const orgData = useSelector((state) => state.organizations.profile);
  const dispatch = useDispatch();
  const [currentPickup, setCurrentPickup] = useState(null);
  useEffect(() => {
    setType(docType);
  }, [docType]);
  useEffect(() => {
    if (pickupList.length > 0)
      setCurrentPickup({
        name: pickupList[0].name,
        value: pickupList[0]._id,
      });
  }, [pickupList]);

  useEffect(() => {
    if (producerId) {
      dispatch(fetchProducerPickupList(producerId));
      setCurrentPickup(null);
    }
  }, [producerId]);
  return (
    <Stack sx={classes.popup}>
      <PageTitle>Add document</PageTitle>

      <Stack>
        <CAutoComplete
          title="Collection Point"
          required
          value={currentPickup}
          onChange={(value) => {
            setCurrentPickup(value);
          }}
          options={pickupList.map((pickup, index) => {
            return {
              name: pickup.name,
              value: pickup._id,
            };
          })}
        />
      </Stack>
      <Stack width={"60%"}>
        <CAutoComplete
          title="Select document"
          fixed
          required
          value={type}
          onChange={(e) => {
            setType(e);
          }}
          options={["GDRP", "Declaration", "Certification", "Contract"]}
        />
      </Stack>
      <Stack>
        <InputTitle>Upload document</InputTitle>
        <Stack direction={"row"} alignItems={"center"}>
          <Stack width={"60%"}>
            <TextField
              value={file?.name}
              placeholder="No document"
              size="small"
            />
          </Stack>
          <Stack width={"40%"} padding={1}>
            <Button component="label" sx={classes.buttonUpload}>
              <BackupOutlinedIcon sx={{ color: "text.accent" }} /> Upload
              <input
                accept=".pdf"
                type="file"
                id="upload-file"
                hidden
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  dispatch(setUploadSpinnerStatus(true));
                  FileService.uploadFile(e.target.files[0])
                    .then((res) => {
                      if (res.data.message === SUCCESS) {
                        setUrl(res.data.filename);
                        showNotification("File uploaded", "success");
                        dispatch(setUploadSpinnerStatus(false));
                      }
                    })
                    .catch((err) => {
                      showNotification(
                        err.response
                          ? ERROR[err.response.data?.message] || "Network Error"
                          : err.message,
                        "error"
                      );
                      dispatch(setUploadSpinnerStatus(false));
                    });
                }}
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack>
        <CRadioSelect
          title="Issue date"
          required
          direction="column"
          value={
            same_issue_expiration_date ? "Day of expiration date" : "Set date"
          }
          onChange={(value) => {
            setSameIssue(value === "Day of expiration date");
          }}
          options={["Set date", "Day of expiration date"]}
          radioRawDirection={false}
          hasComponent
          components={[
            <CDateInput
              value={dayjs(issue_date)}
              onChange={(data) => {
                setIssueDate(
                  getServerTimestamp(new Date(data).getTime(), orgData.timezone)
                );
              }}
            />,
            <></>,
          ]}
        />
      </Stack>
      <Divider />
      <Stack>
        <CRadioSelect
          title="Validity"
          required
          direction="column"
          options={["Set period", "Same validity"]}
          onChange={(value) => {
            setSameValidity(value === "Same validity");
          }}
          value={same_validity ? "Same validity" : "Set period"}
          radioRawDirection={false}
          hasComponent
          components={[
            <CSelect
              label=""
              value={validity}
              onSelectChange={(value) => {
                setValidity(value);
              }}
              options={["1 month", "2 months", "3 months", "6 months"]}
            />,
            <></>,
          ]}
        />
      </Stack>
      <Divider />
      <Stack>
        <CheckBoxes
          title="What do you intend to do with it?"
          options={["Send by email", "Print"]}
          selectedOptions={purpose}
          onChange={(option, selected) => {
            if (selected) {
              setPurpose([...purpose, option]);
            } else {
              let temp = [...purpose];
              temp.splice(purpose.indexOf(option), 1);
              setPurpose(temp);
            }
          }}
        />
      </Stack>
      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            onSaveHandler(currentPickup?.value, {
              type,
              url,
              same_issue_expiration_date,
              same_validity,
              purpose,
              issue_date,
              validity,
            });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const ProducerResponsibleModal = ({
  title,
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.member);

  const [resp_phone, setResphone] = useState();
  const [resp_mobile_phone, setRespMobilePhone] = useState();
  const [resp_email, setRespEmail] = useState();
  const [userData, setUserData] = useState([]);
  const [validation, setValidataion] = useState({
    email: false,
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const checkValidation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(resp_email)) {
      setValidataion({ email: true });
      return false;
    }
    return true;
  };
  const [name, setName] = useState(null);
  useEffect(() => {
    if (data) {
      setResphone(data?.resp_phone);
      setRespMobilePhone(data?.resp_mobile_phone);
      setRespEmail(data?.resp_email);
      setName(data?.responsible_name);
    }
  }, [data]);
  useEffect(() => {
    setUserData(
      users.map((user) => {
        return { name: user.name, value: user._id };
      })
    );
    for (let user of users) {
      if (user.name === data.responsible_name) {
        setSelectedUser({
          name: user.name,
          value: user._id,
        });
        break;
      }
    }
  }, [users, data]);
  useEffect(() => {
    dispatch(fetchUserList());
  }, []);

  const onResponsibleUserChange = (v) => {
    setSelectedUser(v);
    const user = users.find((user) => user.name === v.name);
    OrganizationService.getMemberInfo(user._id).then((res) => {
      if (res.data.message === SUCCESS) {
        setResphone(res.data.data.phone || "");
        setRespEmail(res.data.data.email || "");
        setRespMobilePhone(res.data.data.mobile_phone || "");
      }
    });
  };
  return (
    <Stack sx={classes.popup}>
      <PageTitle>{title}</PageTitle>
      <IconButton
        onClick={onCancelHandler}
        sx={{
          position: "absolute",
          right: "42px",
          top: "34px",
        }}
      >
        <CloseIcon />
      </IconButton>

      <CAutoComplete
        title="Responsible"
        options={userData}
        value={selectedUser}
        placeholder={name}
        fixed
        onInput={(e) => {
          setSelectedUser(null);
          setName(e.target.value);
        }}
        onChange={onResponsibleUserChange}
      />
      <CPhoneInput
        fullWidth
        label="Phone"
        value={resp_phone}
        onChange={(e) => {
          setResphone(e.target.value);
        }}
      />
      <CPhoneInput
        fullWidth
        label="Mobile Phone"
        onChange={(e) => {
          setRespMobilePhone(e.target.value);
        }}
        value={resp_mobile_phone}
      />
      <CTextInput
        error={validation.email}
        title="Email"
        placeholder="mspot@email.com"
        value={resp_email}
        onChange={(value) => {
          setRespEmail(value);
        }}
      />

      <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
        <Button
          sx={classes.buttonCancel}
          onClick={() => {
            onCancelHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={classes.buttonSave}
          onClick={() => {
            if (!checkValidation()) return false;
            onSaveHandler({
              responsible: selectedUser?.value || name,
              resp_phone: resp_phone,
              resp_email: resp_email,
              resp_mobile_phone: resp_mobile_phone,
            });
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
