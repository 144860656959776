import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Box, Button, Checkbox, Stack } from "@mui/material";
import { GridLogicOperator } from "@mui/x-data-grid";

import { BigTitle, SubTitle } from "../../../../components/Typography";
import { SearchBar } from "../../../../components/CustomInput";
import { DataTable } from "../../../../components/Table";
import { fetchAllRawWastes } from "../../../../actions/waste";
import OrganizationService from "../../../../services/OrganizationService";
import { classes } from "../../../../utils/class";
import { SUCCESS } from "../../../../utils/type";
import { showNotification, snackbarRef } from "../../../../utils/helpers";
import { ERROR } from "../../../../utils/constants";

const SetupRawWaste = () => {
  snackbarRef.current = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { raw_wastes } = useSelector((state) => {
    return state.waste;
  });
  const [itemsChecked, setItemsChecked] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const columns = [
    {
      field: "checkbox",
      hideSortIcons: true,
      disableColumnMenu: true,
      width: 100,
      headerName: (
        <Checkbox
          sx={classes.checkBox}
          checked={allCheck}
          onClick={(e) => {
            e.stopPropagation();
            if (allCheck) {
              setAllCheck(false);
              setItemsChecked([]);
            } else {
              setAllCheck(true);
              setItemsChecked(raw_wastes.map((t) => t._id));
            }
          }}
        />
      ),
      renderCell: (props) => (
        <Checkbox
          sx={classes.checkBox}
          checked={itemsChecked.indexOf(props.row._id) !== -1}
          onClick={(e) => {
            e.stopPropagation();
            const id = itemsChecked.indexOf(props.row._id);
            const temp = [...itemsChecked];
            if (id !== -1) {
              temp.splice(id, 1);
            } else {
              temp.push(props.row._id);
            }
            setItemsChecked(temp);
          }}
        />
      ),
    },
    {
      field: "code",
      headerName: "LER Code",
      width: 200,
    },
    {
      field: "name",
      headerName: "Name",
      width: 500,
    },
    {
      field: "capacity",
      headerName: "C.racio(l-kg)",
      width: 100,
    },
  ];

  const confirmWaste = () => {
    OrganizationService.addRawWastes({
      raw_wastes: itemsChecked,
    })
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Raw wastes confirmed", "success");
          navigate("/auth/reviewing");
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };
  useEffect(() => {
    dispatch(fetchAllRawWastes());
  }, [dispatch]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{ backgroundColor: "#E1ECF4", minHeight: "100vh" }}
      >
        <Stack maxWidth={"420px"} minWidth={"420px"}>
          <Stack
            sx={{ backgroundColor: "#F7F6F3" }}
            pt={"72px"}
            px={"48px"}
            pb={"21px"}
            gap={"21px"}
          >
            <BigTitle>Waste Type</BigTitle>
            <Stack
              maxWidth={"472px"}
              sx={{ fontSize: "18px", fontFamily: "Manrope" }}
              gap={4}
            >
              <Box>
                To begin, please select the types of raw waste your organization
                deals with. The provided list adheres to industry standards in
                naming and can be searched by waste type and LER codes.
              </Box>
              <Box>
                Once you have completed your onboarding, you can manage and
                rename your waste types in the <b>Settings - Raw Waste</b>{" "}
                section.
              </Box>
            </Stack>
          </Stack>
          <Stack padding={"48px"} gap={"10px"}>
            <Box sx={{ fontWeight: "bold" }}>Why is this important?</Box>
            Defining the raw waste will allow you to allocate waste types to
            warehouses and vehicles which in turn enable you to collect waste
            from producers.
          </Stack>
        </Stack>
        <Stack
          sx={{ backgroundColor: "white", width: "calc(100vw - 420px);" }}
          gap={2}
          px={5}
          py={5}
        >
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <SubTitle>Add raw waste</SubTitle>
            <Button
              variant="outlined"
              onClick={confirmWaste}
              sx={classes.orgCreateButton}
            >
              Confirm Waste
            </Button>
          </Stack>
          <Stack alignItems={"flex-start"}>
            <SearchBar
              placeholder="Search raw waste"
              onSearchChange={(value) => {
                setFilterModel({
                  items: [
                    {
                      id: 1,
                      field: "name",
                      operator: "contains",
                      value: value,
                    },
                    // {
                    //   id: 2,
                    //   field: "code",
                    //   operator: "contains",
                    //   value: value,
                    // },
                  ],
                  logicOperator: GridLogicOperator.Or,
                });
              }}
            />
          </Stack>
          <DataTable
            fit="calc(100vw - 510px);"
            filterModel={filterModel}
            columns={columns}
            rows={raw_wastes}
          />
        </Stack>
      </Box>
    </>
  );
};
export default SetupRawWaste;
