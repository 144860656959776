// material ui

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  Stack,
  Typography,
} from "@mui/material";

// proj import
import { classes } from "../../../../utils/class";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  CAutoComplete,
  CDateInput,
  CRadioSelect,
  CSelect,
  CheckBoxes,
} from "../../../../components/CustomInput";
import { fetchPickupPoints } from "../../../../actions/pickup";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  showNotification,
  getServerTimestamp,
} from "../../../../utils/helpers";

const IssueCertificatesDialog = ({
  open,
  handleLeave,
  isBulk,
  handleConfirm,
  handleClose,
  itemsChecked,
}) => {
  const dispatch = useDispatch();
  const [pickupPoint, setPickupPoint] = useState(null);
  const [issueDateCheck, setIssueDateCheck] = useState(null);
  const [validityCheck, setValidityCheck] = useState(null);
  const [date, setDate] = useState(dayjs(new Date()));
  const [period, setPeriod] = useState("6 months");
  const [checkOptions, setCheckOptions] = useState({});

  const pickupList = useSelector((state) => state.pickup.pickupList);
  const orgData = useSelector((state) => state.organizations.profile);

  useEffect(() => {
    !isBulk && dispatch(fetchPickupPoints());
  }, [isBulk]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Stack>
          <Stack
            direction="row"
            sx={{ minWidth: "450px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
              Issue certificates {isBulk && "in bulk"}
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          {isBulk && (
            <Typography sx={{ marginBottom: "20px" }}>
              {itemsChecked.length} selected certificates
            </Typography>
          )}
          {!isBulk && (
            <CAutoComplete
              options={pickupList.map((pickup) => pickup.name)}
              value={pickupPoint}
              onChange={(v) => setPickupPoint(v)}
              placeholder="Select Collection Point"
            />
          )}
          <CRadioSelect
            title="Issue date"
            required
            direction="column"
            defaultValue={issueDateCheck}
            options={["Set date", "Day of expiration date"]}
            radioRawDirection={false}
            hasComponent
            value={issueDateCheck}
            onChange={(v) => setIssueDateCheck(v)}
            components={[
              <CDateInput value={date} onChange={(v) => setDate(v)} />,
              <></>,
            ]}
          />
          <Typography sx={{ color: "grey", width: "300px", fontSize: "14px" }}>
            The issue date will be the expiration date of each selected
            certificate
          </Typography>
          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
          <CRadioSelect
            title="Validity"
            required
            defaultValue={validityCheck}
            direction="column"
            options={["Set period", "Same validity"]}
            radioRawDirection={false}
            hasComponent
            value={validityCheck}
            onChange={(v) => setValidityCheck(v)}
            components={[
              <CSelect
                options={["6 months"]}
                value={period}
                onSelectChange={(v) => setPeriod(v)}
              />,
              <></>,
            ]}
          />
          <Typography sx={{ color: "grey", width: "300px", fontSize: "14px" }}>
            The same validity as previously issued certificates
          </Typography>
          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
          <CheckBoxes
            selectedOptions={checkOptions}
            onChange={(value, checked) => {
              let t = { ...checkOptions };
              t[value] = checked;
              setCheckOptions(t);
            }}
            title="What do you intend to do with them?"
            options={["Send by email", "Print all"]}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ margin: "20px" }}>
          <Button
            variant="outlined"
            sx={classes.buttonCancel}
            onClick={handleLeave}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={classes.buttonSave}
            onClick={() => {
              if (
                (!isBulk && !pickupPoint) ||
                !issueDateCheck ||
                !validityCheck
              ) {
                showNotification("Please input all fields!", "error");
                return;
              }
              let res = {},
                purpose = [];
              if (checkOptions["Send by email"]) purpose.push("send_by_email");
              if (checkOptions["Print all"]) purpose.push("print");
              Object.assign(
                res,
                !isBulk && {
                  pickup_point: pickupList.find(
                    (pickup) => pickup.name === pickupPoint
                  )._id,
                },
                { purpose: purpose },
                !issueDateCheck
                  ? {
                      issue_date: getServerTimestamp(
                        new Date(date).getTime(),
                        orgData.timezone
                      ),
                    }
                  : { same_issue_expiration_date: true },
                !validityCheck
                  ? { validity: period === "6 months" && "6_month" }
                  : { same_validity: true }
              );
              handleConfirm(res);
            }}
          >
            Issue Certificate{isBulk ? "s" : ""}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default IssueCertificatesDialog;
