import { Tooltip } from "@mui/material";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDayFromStr, getServerTimestamp } from "../utils/helpers";

export const CustomDatePicker = ({
  defaultValue = null,
  onClickDate = () => {},
  handleClose = () => {},
}) => {
  const orgData = useSelector((state) => state.organizations.profile);

  const [value, setValue] = useState(dayjs(Date.now()));
  const current_scheduled = useSelector(
    (state) => state.visits.current_scheduled
  );

  useEffect(() => {
    if (defaultValue) setValue(dayjs(new Date(defaultValue)));
    else setValue(dayjs(Date.now()));
  }, [defaultValue]);

  const checkDateInDaysOff = useCallback(
    (tempDate) => {
      if (
        current_scheduled &&
        current_scheduled?.pickup_point_days_off !== undefined
      ) {
        const current = getServerTimestamp(
          tempDate.getTime(),
          orgData.timezone
        );
        for (let t of current_scheduled?.pickup_point_days_off) {
          if (getDayFromStr(t) === new Date(current).getDay()) {
            return true;
          }
        }
      }
      return false;
    },
    [current_scheduled, orgData]
  );
  return (
    <>
      <DateCalendar
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        slots={{
          day: (props) => {
            const { day, outsideCurrentMonth, ...other } = props;
            return (
              <>
                {(() => {
                  const res = checkDateInDaysOff(
                    new Date(props["data-timestamp"])
                  );

                  return (
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            background: "black",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "black",
                          },
                        },
                      }}
                      title="Suggested date"
                    >
                      <PickersDay
                        {...other}
                        onFocus={(e) => {
                          other.onFocus(e, e.currentTarget.value);
                        }}
                        onBlur={(e) => {
                          other.onBlur(e, day);
                        }}
                        disabled={res}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                          borderRadius: "20%",
                        }}
                        onClick={(e) => {
                          onClickDate(day);
                          handleClose();
                        }}
                      />
                    </Tooltip>
                  );
                })()}
              </>
            );
          },
        }}
      />
    </>
  );
};
