import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Stack, Typography } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { deleteDeposit } from "../../../../actions/warehouse";

const RemoveDepositDialog = ({ open, onClose, id }) => {
  const dispatch = useDispatch();
  const wh_id = useSelector((state) => state.warehouse.current.id);

  const onRemoveDeposit = () => {
    dispatch(deleteDeposit(id, wh_id));
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        padding={"34px 42px"}
        gap={"22px"}
        sx={{
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "520px",
        }}
      >
        <PageTitle>Remove deposit</PageTitle>
        <Typography>Are you sure you want to remove this deposit?</Typography>
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={onClose}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={onRemoveDeposit}>
            Remove Deposit
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default RemoveDepositDialog;
