import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Modal,
  Stack,
  Button,
  Popover,
  Typography,
} from "@mui/material";

import {
  InputTitle,
  PageTitle,
  SubTitle,
} from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { CheckBoxes } from "../../../../components/CustomInput";

const AssignVehicleDialog = ({
  open = false,
  handleClose = function () {},
  handleContinue = function () {},
  anchorEl = null,
}) => {
  return (
    <>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          maxWidth: "492px",
          minWidth: "292px",
        }}
      >
        <Stack position={"relative"}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack gap={2} padding={3} width={"292px"}>
          <SubTitle>Assign vehicle to current zone</SubTitle>

          <CheckBoxes
            options={[
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                45-RE-89
                <Typography sx={{ fontSize: "10px" }}>
                  (Current zone)
                </Typography>
              </Stack>,
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                45-RE-81
                <Typography sx={{ fontSize: "10px" }}>(Zone 2)</Typography>
              </Stack>,
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                35-RE-89
                <Typography sx={{ fontSize: "10px" }}>(Zone 3)</Typography>
              </Stack>,
            ]}
          />
          <Stack direction={"row"} gap={1} justifyContent={"space-around"}>
            <Button sx={classes.buttonCancel} fullWidth onClick={handleClose}>
              Back
            </Button>
            <Button
              sx={classes.buttonSave}
              onClick={() => {
                handleClose();
              }}
              fullWidth
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
export default AssignVehicleDialog;
