import axios from "axios";
import { COUNTRIES_INFO } from "./constants";
import { createRef } from "react";
import printJS from "print-js";
import CertificateService from "../services/CertificateService";
import moment from "moment-timezone";

export const snackbarRef = createRef();

export const getDayFromStr = (day) => {
  const days = { mon: 0, tue: 1, wed: 2, thu: 3, fri: 4, sat: 5, sun: 6 };
  return days[day];
};

export const getCountryLabelFromCode = (code) => {
  return COUNTRIES_INFO.find((info) => info.code === code)?.label || null;
};

export const getDateStringFromNum = (timestamp, timezone) => {
  if (timestamp && timezone)
    return moment(timestamp).tz(timezone).format("DD/MMM/YYYY");
  else return null;
};

export const getServerTimestamp = (timestamp, timezone) => {
  if (timestamp && timezone)
    return moment(timestamp).tz(timezone, true).toDate().getTime();
  else return Date.now();
};

export const getTimeStringFromNum = (num) => {
  const date = new Date(num || 0);
  return `${date.getHours()}:${date.getMinutes()}`;
};

export const showNotification = (string, type) => {
  snackbarRef.current.enqueueSnackbar(string, {
    variant: type,
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    autoHideDuration: 3000,
  });
  if (string === "Authentication error") {
    window.location.href = "/auth/signin";
  }
};

export const getFileName = (string) => {
  if (string) return string.split("/").pop();
  return "";
};

export const PURPOSE_TYPES = {
  waste_pickup: "Waste Pickup",
  commercial_visit: "Commercial Visit",
};

export const getLocationOfUser = async () => {
  const data = { lat: 0, lng: 0 };
  return new Promise(async (resolve, rej) => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      const ip = res.data.ip;
      const locationRes = await axios.get(`http://ip-api.com/json/${ip}`);
      data.lat = locationRes.data.lat;
      data.lng = locationRes.data.lon;
      resolve(data);
    } catch (err) {
      rej(err);
    }
  });
};
export const isImageFile = (fileName) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"]; // Add more image extensions if needed
  const extension = fileName.split(".").pop().toLowerCase();
  return imageExtensions.includes(extension);
};
export const downloadFile = (data) => {
  // const blob = new Blob([data], { type: "application/pdf" });
  // const url = window.URL.createObjectURL(blob);
  //window.open(url);
  printJS({
    printable: data,
    type: "pdf",
    base64: true,
  });
};
export const Print = async (urls) => {
  // let pdfsToMerge = urls.map((url) => {
  //   return `https://api.codetabs.com/v1/proxy?quest=${url}`;
  // });

  // const mergedPdf = await PDFDocument.create();
  // for (const pdfCopyDoc of pdfsToMerge) {
  //   const pdfBytes = await fetch(pdfCopyDoc).then((res) => res.arrayBuffer());
  //   //const pdfBytes = fs.readFileSync(pdfCopyDoc);
  //   const pdf = await PDFDocument.load(pdfBytes);
  //   const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  //   copiedPages.forEach((page) => {
  //     mergedPdf.addPage(page);
  //   });
  // }
  // const mergedPdfFile = await mergedPdf.save();
  // downloadFile(mergedPdfFile);
  try {
    const res = await CertificateService.getDocumentsForPrint({
      file_urls: urls,
    });
    downloadFile(res.data.data);
  } catch (err) {}
};

export const download = async (urls) => {
  for (let url of urls) {
    window.open(url);
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
