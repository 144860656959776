import { producer_axios } from "../utils/axios";

//add producer
const createProducer = (data) => {
  return producer_axios.post(`/create-producer`, data);
};
const createProducerWizard = (data) => {
  return producer_axios.post(`/create-producer-setup-wizard`, data);
};
//fetch

const fetchProducerList = () => {
  return producer_axios.get(`/list-producers`);
};

const fetchProducerDetail = (id) => {
  return producer_axios.get(`/get-producer/${id}`);
};

const fetchProducerTransferList = (id) => {
  return producer_axios.get(`/list-transfers/${id}`);
};

const fetchProducerDocumentList = (id) => {
  return producer_axios.get(`/list-documents/${id}`);
};

const fetchProducerPickupList = (id) => {
  return producer_axios.get(`/list-pickup-points/${id}`);
};

const fetchProducerRawWasteList = (id) => {
  return producer_axios.get(`/list-assigned-rawwastes/${id}`);
};

const fetchProducerRawWasteAvailableList = (id) => {
  return producer_axios.get(`/list-available-rawwastes/${id}`);
};

const fetchInventoryList = (id, isOffer) => {
  return producer_axios.get(`/list-inventories/${id}/${isOffer}`);
};

const fetchVisitList = (id) => {
  return producer_axios.get(`/list-visits/${id}`);
};
// Update

const addProducerRawWaste = (id, data) => {
  return producer_axios.post(`/add-rawwastes/${id}`, data);
};

const editProducerRawWaste = (id, data) => {
  return producer_axios.put(`/edit-assigned-rawwaste-name/${id}`, data);
};

const activateProducerRawWaste = (id) => {
  return producer_axios.put(`/active-assigned-rawwaste/${id}`);
};
const deactivateProducerRawWaste = (id) => {
  return producer_axios.put(`/inactive-assigned-rawwaste/${id}`);
};
const editProducer = (id, data) => {
  return producer_axios.put(`/edit-producer/${id}`, data);
};

const deactivateProducer = (id) => {
  return producer_axios.put(`/deactivate-producer/${id}`);
};

const activateProducer = (id) => {
  return producer_axios.put(`/activate-producer/${id}`);
};
const archiveProducer = (id) => {
  return producer_axios.put(`/archive-producer/${id}`);
};
const unarchiveProducer = (id) => {
  return producer_axios.put(`/unarchive-producer/${id}`);
};
const removeRawWaste = (id) => {
  return producer_axios.delete(`/remove-assigned-rawwaste/${id}`);
};
const ProducerService = {
  createProducerWizard,
  removeRawWaste,
  fetchProducerList,
  createProducer,
  unarchiveProducer,
  archiveProducer,
  activateProducer,
  deactivateProducer,
  fetchProducerDetail,
  fetchProducerTransferList,
  fetchProducerDocumentList,
  editProducer,
  fetchProducerPickupList,
  fetchProducerRawWasteList,
  fetchInventoryList,
  addProducerRawWaste,
  editProducerRawWaste,
  activateProducerRawWaste,
  deactivateProducerRawWaste,
  fetchVisitList,
  fetchProducerRawWasteAvailableList,
};
export default ProducerService;
