import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "./actions/user";
import { useSnackbar } from "notistack";
import { showNotification, snackbarRef } from "./utils/helpers";
import {
  fetchAllInvites,
  fetchOrganizationInfo,
} from "./actions/organizations";
import { useLocation, useNavigate } from "react-router-dom";
import { NO_ORG_URLS } from "./utils/constants";
import { setLoadSpinnerStatus } from "./actions/spinner";
import ProducerService from "./services/ProducerService";
const AuthProvider = ({
  children,
  noCheckOrgInfo = false,
  isWizard = false,
}) => {
  snackbarRef.current = useSnackbar();
  const { isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const email_link = new URLSearchParams(location.search).get("email");

  useEffect(() => {
    dispatch(setLoadSpinnerStatus(false));
    if (isAuthenticated === false) {
      if (email_link) return navigate(`/auth/signin?email=${email_link}`);
      else return navigate(`/auth/signin`);
    } else if (isAuthenticated !== false) {
      dispatch(fetchUserProfile());
      dispatch(fetchAllInvites());
    }
  }, [email_link, isAuthenticated, noCheckOrgInfo, location]);

  return <>{isAuthenticated && children}</>;
};

export default AuthProvider;
