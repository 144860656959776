import { useState } from "react";
import ProfileMainView from "./profile/ProfileMainView";
import IdentificationView from "./profile/IdentificationView";
import ContactsView from "./profile/ContactsView";
import {
  Tab,
  TabBody,
  TabHeader,
  TabHeaderItem,
  Tabs,
} from "../../../components/Tab";
import DaysOff from "../member/DaysOff";
import { PageTitle } from "../../../components/Typography";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";

const ProfilePage = () => {
  const user = useSelector((state) => {
    return state.user.profile;
  });
  const [stage, setStage] = useState(0);

  return (
    <>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <PageTitle>{user?.name}</PageTitle>
      </Stack>
      <Tabs onTabChangeHandler={() => {}}>
        <TabHeader>
          <TabHeaderItem>Profile</TabHeaderItem>
          <TabHeaderItem>Days off</TabHeaderItem>
        </TabHeader>
        <></>
        <TabBody>
          <Tab>
            {stage === 0 && <ProfileMainView setStage={setStage} />}
            {stage === 1 && <IdentificationView setStage={setStage} />}
            {stage === 2 && <ContactsView setStage={setStage} />}
          </Tab>

          <Tab>
            <DaysOff />
          </Tab>
        </TabBody>
      </Tabs>
    </>
  );
};

export default ProfilePage;
