import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Button } from "@mui/material";

import {
  InputTitle,
  PageTitle,
  SubTitle,
} from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import {
  CRadioSelect,
  CSelect,
  CTextInput,
} from "../../../../components/CustomInput";

const AdjustBalance = ({
  open = false,
  handleClose = function () {},
  handleContinue = function () {},
}) => {
  const [reason, setReason] = useState();
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            maxWidth: "400px",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} padding={5}>
            <PageTitle>Adjust balance</PageTitle>
            <CRadioSelect
              direction="column"
              radioRawDirection={true}
              title="Balance adjustment"
              required
              options={["Add balance", "Remove balance"]}
            />
            <CTextInput
              title="Balance"
              required
              placeholder="Insert balance"
              endIcon={"€"}
            />
            <CSelect
              label="Reason"
              required
              options={["Reason A", "Reason B", "Other"]}
              onSelectChange={(e) => {
                setReason(e.target.value);
              }}
            />
            {reason === "Other" && (
              <CTextInput
                rows={3}
                title="Description"
                placeholder="Describe reason"
              />
            )}
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <Button sx={classes.buttonCancel} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                sx={classes.buttonSave}
                onClick={() => {
                  handleClose();
                }}
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default AdjustBalance;
