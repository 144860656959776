import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

// material-ui
import { Box, Stack } from "@mui/material";

// proj imports
import { Item, ItemList } from "../../../components/ItemList";
import { SETTINGS_NAVIGATION } from "../../../utils/constants";

const MainSettingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [curNav, setCurNav] = useState(null);

  useEffect(() => {
    const keys = location.pathname.split("/");
    const nav = keys[keys.length - 1];
    setCurNav(SETTINGS_NAVIGATION.findIndex((nv) => nv.value === nav));
  }, [location]);

  const onSelectionChange = (id) => {
    setCurNav(id);
    navigate(`/settings/${SETTINGS_NAVIGATION[id].value}`);
  };

  return (
    <Stack direction="row">
      <Box
        sx={{
          width: "250px",
          py: "20px",
          minHeight: "100vh",
          backgroundColor: "#F3F1EB",
        }}
      >
        <ItemList value={curNav} onSelectChange={onSelectionChange}>
          {SETTINGS_NAVIGATION.map((nav) => (
            <Item key={nav.value} title={nav.title} />
          ))}
        </ItemList>
      </Box>
      <Box
        width={"calc(100% - 250px);"}
        paddingX={"17px"}
        sx={{
          paddingTop: "50px",
          backgroundColor: "#FFF",
        }}
      >
        <Outlet />
      </Box>
    </Stack>
  );
};

export default MainSettingsPage;
