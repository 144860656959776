import { useEffect, useState } from "react";
import { Button, Modal, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { CAutoComplete, CTextInput } from "../../../../components/CustomInput";
import { fetchRawWastes } from "../../../../actions/organizations";
import UnsavedDialog from "../../settings/dialogs/UnsavedDialog";
import {
  editDeposit,
  fetchAvailableRawWaste,
} from "../../../../actions/pickup";
import { DRIVER, WAREHOUSE_MANAGER } from "../../../../utils/constants";

const EditDepositDialog = ({ producerId, open, onClose, deposit, id }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState();
  const rawWasteList = useSelector((state) => state.pickup.rawWasteList);
  const role = useSelector((state) => state.user.role);
  const [rawWaste, setRawWaste] = useState("");
  const [capacity, setCapacity] = useState("");
  const [unsavedOpen, setUnsavedOpen] = useState(false);

  useEffect(() => {
    if (producerId && role !== WAREHOUSE_MANAGER && role !== DRIVER)
      dispatch(fetchAvailableRawWaste(producerId));
  }, [producerId]);
  useEffect(() => {
    if (!deposit) return;
    let raw = rawWasteList.find((rw) => rw.rawWasteId === deposit.raw_waste);
    setRawWaste({
      name: raw.name,
      value: raw.name,
    });
    setCapacity(deposit.capacity);
  }, [deposit, rawWasteList]);
  useEffect(() => {
    const data = rawWasteList.map((rw, idx) => {
      return { name: rw.name, value: rw.name };
    });
    setItems(data);
  }, [rawWasteList]);

  const onSave = () => {
    dispatch(
      editDeposit(deposit._id, id, {
        raw_waste: rawWasteList.find((rw) => rw.name === rawWaste.name)
          .rawWasteId,
        capacity,
      })
    );
    onClose();
  };

  const handleClose = () => {
    if (deposit.raw_waste === rawWaste && deposit.capacity === capacity) {
      onClose();
      return;
    }
    setUnsavedOpen(true);
  };

  const onLeaveConfirm = () => {
    setUnsavedOpen(false);
    onClose();
  };

  const onSaveConfirm = () => {
    setUnsavedOpen(false);
    onSave();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          padding={"34px 42px"}
          gap={3}
          sx={{
            backgroundColor: "text.contrast",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "520px",
          }}
        >
          <PageTitle>Edit capacity</PageTitle>
          <CAutoComplete
            title="Raw waste type selection"
            required
            options={items}
            value={rawWaste}
            onChange={(v) => {
              setRawWaste(v);
            }}
          />
          <CTextInput
            title="Capacity"
            required
            endIcon="kg"
            value={capacity}
            onChange={(v) => setCapacity(v)}
          />
          <Stack gap={2} direction="row" justifyContent="space-between">
            <Button sx={classes.buttonCancel} onClick={handleClose}>
              Cancel
            </Button>
            <Button sx={classes.buttonSave} onClick={onSave}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <UnsavedDialog
        open={unsavedOpen}
        handleClose={onLeaveConfirm}
        handleLeave={onLeaveConfirm}
        handleSave={onSaveConfirm}
      />
    </>
  );
};

export default EditDepositDialog;
