import React, { useEffect, useState } from "react";

// material-ui
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { InputTitle, PageTitle } from "./Typography";
import {
  FLEET_IDENTIFICATION_MODAL,
  FLEET_VEHICLE_DETAIL_MODAL,
  PICKUP_CONTACTS_MODAL,
  PICKUP_DETAIL_MODAL,
  PICKUP_DOCUMENTS_MODAL,
  PICKUP_EXPECTED_MODAL,
  PICKUP_IDENTFICATION_MODAL,
  PICKUP_LOCATION_MODAL,
  PICKUP_MANAGEMENT_MODAL,
  PICKUP_SCHEDULE_MODAL,
  PICKUP_WASTE_PRICE_MODAL,
  PRODUCER_LOCATION_MODAL,
  PRODUCER_IDENTIFICATION_MODAL,
  PRODUCER_CONTACTS_MODAL,
  PRODUCER_RESPONSIBLE_MODAL,
  PRODUCER_SCHEDULE_MODAL,
  PRODUCER_MANAGEMENT_MODAL,
  ZONE_IDENTIFICATION_MODAL,
  ZONE_DETAIL_MODAL,
  SUCCESS,
  PICKUP_RELATIONSHIP_MODAL,
} from "../utils/type";
import WarehouseService from "../services/WarehouseService";

// sx class
import { classes } from "../utils/class";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {
  PickupContactsModal,
  PickupDetailModal,
  PickupDocumentsModal,
  PickupExpectedModal,
  PickupIdentificationModal,
  PickupManagementModal,
  PickupResponsibleModal,
  PickupWasteModal,
} from "./pickup/Modal";

import {
  ProducerIdentificationModal,
  ProducerManagementModal,
  ProducerResponsibleModal,
} from "./producer/Modal";
import { ZoneDetailModal, ZoneIdentificationModal } from "./zone/Modal";
import FleetService from "../services/FleetService";
import { useDispatch, useSelector } from "react-redux";
import { fetchRawWastes } from "../actions/organizations";
import {
  CAutoComplete,
  CDateInput,
  CDivider,
  CTextInput,
  CTimeInput,
} from "./CustomInput";
import { fetchUserList } from "../actions/member";
import { formatDate, formatHours } from "../utils/date";
import { ContactsModal, LocationModal, ScheduleModal } from "./Modal";

const CardPrimary = ({ title, content, type = 1, subTitle = null }) => {
  const bgColors = [
    "card.primary1",
    "card.primary2",
    "card.primary3",
    "card.primary4",
  ];
  const colors = [
    "text.contrast",
    "text.contrast",
    "text.lighter",
    "text.lighter",
  ];
  return (
    <>
      <Box
        sx={{
          ...classes.cardPrimary,
          bgcolor: bgColors[type - 1],
          color: colors[type - 1],
        }}
      >
        <Typography
          sx={{
            fontFamily: "Manrope",
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "16px",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Manrope",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "32px",
          }}
        >
          {content}
        </Typography>
        {subTitle != null && (
          <Typography
            sx={{ fontSize: "12px", fontWeight: 700, lineHeight: "16px" }}
          >
            {subTitle}
          </Typography>
        )}
      </Box>
    </>
  );
};

const CardFleetIdentificationModal = ({
  title,
  data,
  extraData,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [reg_plate, setRegPlate] = useState(extraData?.reg_plate);
  const [brand, setBrand] = useState(extraData?.brand);
  const [model, setModel] = useState(extraData?.model);
  const [ID, setID] = useState(extraData?.ucoId);
  const [name, setName] = useState(extraData?.name);
  const regPlateChange = (e) => {
    setRegPlate(e);
  };
  const brandChange = (e) => {
    setBrand(e);
  };
  const modelChange = (e) => {
    setModel(e);
  };
  const nameChange = (e) => {
    setName(e);
  };
  return (
    <>
      <Stack sx={classes.popup} padding={"34px 42px"} gap={3}>
        <PageTitle>{title}</PageTitle>
        <IconButton
          onClick={onCancelHandler}
          sx={{
            position: "absolute",
            right: "42px",
            top: "34px",
          }}
        >
          <CloseIcon />
        </IconButton>
        <CTextInput
          title="Name"
          required
          value={name}
          placeholder="Input your name"
          onChange={nameChange}
        />
        <CTextInput
          title="Vehicle Registration Plate"
          required
          placeholder="23-HY-78"
          value={reg_plate}
          onChange={regPlateChange}
          caps
        />

        <Box
          sx={{
            display: "flex",
            gap: "12px",
            justifyContent: "space-between",
          }}
        >
          <Box width="40%">
            <CTextInput
              title="Brand"
              placeholder="Ford"
              value={brand}
              onChange={brandChange}
            />
          </Box>
          <Box width="60%">
            <CTextInput
              title="Model"
              placeholder="Transit 1.9 TDI"
              value={model}
              onChange={modelChange}
            />
          </Box>
        </Box>
        <CTextInput
          title="ID"
          disabled
          placeholder={"Your Fleet ID"}
          value={ID}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <Button
            sx={classes.buttonCancel}
            onClick={() => {
              onCancelHandler();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={classes.buttonSave}
            onClick={() => {
              onSaveHandler({
                reg_plate,
                brand,
                model,
                name,
              });
            }}
          >
            Save
          </Button>
        </Box>
      </Stack>
    </>
  );
};

const CardFleetVehicleDetailModal = ({
  title,
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [year, setYear] = useState(2023);
  const [driver, setDriver] = useState(null);
  const [capacity, setCapacity] = useState(data["Maximum vehicle capacity"]);
  const [warehouse, setWareHouse] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const loadWarehouseList = () => {
    WarehouseService.getWarehouseList().then((res) => {
      if (res.data.message === SUCCESS) {
        setWarehouses(res.data.data);
      }
    });
  };
  const loadDrivers = () => {
    FleetService.getDrivers().then((res) => {
      if (res.data.message === SUCCESS) {
        setDrivers(res.data.data);
      }
    });
  };
  useEffect(() => {
    loadWarehouseList();
    loadDrivers();
  }, []);
  useEffect(() => {
    if (data?.Year) {
      setYear(data.Year);
    } else setYear(new Date().getFullYear());
    if (data?.warehouseId) {
      setWareHouse({
        name: data?.Warehouse,
        value: data?.warehouseId,
      });
    }
    if (data?.driverId) {
      setDriver({
        value: data?.driverId,
        name: data?.Driver,
      });
    }
  }, [data, warehouses, drivers]);
  const warehouseChange = (value) => {
    setWareHouse(value);
  };
  const yearChange = (newValue) => {
    const d = new Date(newValue);
    const y = d.getFullYear();
    setYear(y);
  };
  const driverChange = (value) => {
    setDriver(value);
  };
  const capacityChange = (e) => {
    setCapacity(e.target.value);
  };
  return (
    <>
      <Stack padding={"34px 42px"} gap={3} sx={classes.popup}>
        <PageTitle>{title}</PageTitle>
        <IconButton
          onClick={onCancelHandler}
          sx={{
            position: "absolute",
            right: "42px",
            top: "34px",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Stack
          sx={{
            display: "flex",
            gap: "12px",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Stack width="35%" gap="8px">
            <InputTitle>Year</InputTitle>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label=""
                views={["year"]}
                value={dayjs().year(year)}
                onChange={yearChange}
                sx={[classes.datePicker]}
              />
            </LocalizationProvider>
          </Stack>
          <Stack width="65%" gap="8px">
            <InputTitle>Vehicle maximum capacity</InputTitle>
            <OutlinedInput
              sx={{
                backgroundColor: "text.contrast",
                ...classes.textInput,
              }}
              type="number"
              placeholder="3500"
              size="small"
              value={capacity}
              onChange={capacityChange}
              fullWidth
              endAdornment={<InputAdornment position="end">kg</InputAdornment>}
            />
          </Stack>
        </Stack>
        <CAutoComplete
          title="Driver"
          placeholder="Select a driver"
          required
          value={driver}
          options={drivers.map((d) => {
            return { name: d.name, value: d._id };
          })}
          onChange={driverChange}
        />
        <CAutoComplete
          placeholder="Select a warehouse"
          title="Warehouse"
          required
          value={warehouse}
          options={warehouses.map((wh) => {
            return { value: wh._id, name: wh.name };
          })}
          onChange={warehouseChange}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <Button
            sx={classes.buttonCancel}
            onClick={() => {
              onCancelHandler();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={classes.buttonSave}
            onClick={() => {
              onSaveHandler({
                year,
                capacity,
                driver: driver?.value,
                warehouse: warehouse?.value,
              });
            }}
          >
            Save
          </Button>
        </Box>
      </Stack>
    </>
  );
};

const CardFleetAddRawWasteModal = ({
  onCancelHandler,
  onSaveHandler,
  isCapacity = true,
}) => {
  const [rawWaste, setRawWaste] = useState(null);
  const [capacity, setCapacity] = useState("");
  const rawWasteChange = (value) => {
    setRawWaste(value);
  };
  const availableRawWastes = useSelector(
    (state) => state.organizations.rawWastes
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRawWastes());
  }, [dispatch]);
  return (
    <>
      <Box
        sx={{
          padding: "34px 42px",
          display: "flex",
          flexDirection: "column",
          gap: "28px",
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "421px",
          borderRadius: "8px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Manrope",
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "40px",
          }}
        >
          Add raw waste
        </Typography>
        <CAutoComplete
          title="Raw waste"
          placeholder="Select Raw Waste"
          value={rawWaste}
          required
          onChange={rawWasteChange}
          options={availableRawWastes.map((waste) => {
            return {
              value: waste.rawWasteId,
              name: waste.name,
              component: (
                <Stack>
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                    {waste.code}
                  </Typography>
                  <Typography sx={{ color: "grey", fontSize: "12px" }}>
                    {waste.name}
                  </Typography>
                </Stack>
              ),
            };
          })}
          fixed
          hasComponent
        />
        {isCapacity && (
          <CTextInput
            type="number"
            title="Capacity"
            required
            endIcon="kg"
            value={capacity}
            placeholder="0"
            onChange={(v) => setCapacity(v)}
          />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <Button
            sx={classes.buttonCancel}
            onClick={() => {
              onCancelHandler();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={classes.buttonSave}
            onClick={() => {
              onSaveHandler(rawWaste.value, capacity);
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};
const CardFleetEditRawWasteModal = ({
  data,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [capacity, setCapacity] = useState(null);

  useEffect(() => {
    if (data) {
      setCapacity(data.capacity);
    }
  }, [data]);
  return (
    <>
      <Box
        sx={{
          padding: "34px 42px",
          display: "flex",
          flexDirection: "column",
          gap: "28px",
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "421px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Manrope",
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "40px",
          }}
        >
          Edit Waste Capacity
        </Typography>
        <Box>
          <CAutoComplete
            placeholder={data?.raw_waste_name}
            disabled
            title="Raw Waste"
            required
          />
        </Box>
        <CTextInput
          type="number"
          title="Capacity"
          required
          endIcon="kg"
          value={capacity}
          placeholder="0"
          onChange={(v) => setCapacity(v)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <Button
            sx={classes.buttonCancel}
            onClick={() => {
              onCancelHandler();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={classes.buttonSave}
            onClick={() => {
              onSaveHandler(capacity);
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};
const CardFleetRegisterKilo = ({ onCancelHandler, onSaveHandler }) => {
  const [hours, setHour] = useState(dayjs(Date.now()));
  const [date, setDate] = useState(dayjs(Date.now()));
  const [odometer, setOdometer] = useState(0);
  const [responsible, setResponsible] = useState("");
  const { users } = useSelector((state) => state.member);
  const [memberOption, setMemberOption] = useState([]);
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.profile);
  const orgData = useSelector((state) => state.organizations.profile);
  useEffect(() => {
    dispatch(fetchUserList());
  }, [dispatch]);

  useEffect(() => {
    const data = users.map((member, index) => {
      return {
        ...member,
        value: member?._id,
        name: member?.name,
      };
    });
    for (let user of users) {
      if (user.name === userProfile?.name) {
        setResponsible({ value: user._id, name: user.name });
        break;
      }
    }

    setMemberOption(data);
  }, [users, userProfile]);
  return (
    <>
      <Box
        sx={{
          padding: "34px 42px",
          display: "flex",
          flexDirection: "column",
          gap: "28px",
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "421px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Manrope",
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "40px",
          }}
        >
          Register Kilometers
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            justifyContent: "space-between",
          }}
        >
          <CDateInput
            title="Date"
            value={date}
            onChange={(newValue) => setDate(newValue)}
          />
          <CTimeInput
            title="SET HOURS"
            value={hours}
            onChange={(newValue) => setHour(newValue)}
          />
        </Box>
        <Box>
          <CAutoComplete
            title="Responsible"
            options={memberOption}
            onChange={(data) => {
              setResponsible(data);
            }}
            value={responsible}
            placeholder="Select Responsible Person"
          />
        </Box>

        <CTextInput
          title="Odometer"
          onChange={(value) => {
            setOdometer(value);
          }}
          placeholder="111.000"
          endIcon={"km"}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <Button
            sx={classes.buttonCancel}
            onClick={() => {
              onCancelHandler();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={classes.buttonSave}
            onClick={() => {
              onSaveHandler({
                date: formatDate(date, orgData.timezone),
                hours: formatHours(hours),
                responsible: responsible.value,
                odometer: odometer,
              });
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};
const CardFleetAddDayoffModal = ({ onCancelHandler, onSaveHandler }) => {
  const [reason, setReason] = useState("car service");
  const [from, setFrom] = useState(dayjs(Date.now()));
  const [to, setTo] = useState(dayjs(Date.now()));
  const [notes, setNote] = useState(" ");
  const reasonChange = (e) => {
    setReason(e.target.value);
  };

  const fromChange = (value) => {
    if (new Date(value).getTime() <= new Date(to).getTime()) {
      setFrom(value);
    } else {
      setTo(value);
      setFrom(value);
    }
  };

  const toChange = (value) => {
    if (new Date(value).getTime() >= new Date(from).getTime()) setTo(value);
    else {
      setFrom(value);
      setTo(value);
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: "34px 42px",
          display: "flex",
          flexDirection: "column",
          gap: "28px",
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "421px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Manrope",
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "40px",
          }}
        >
          Add maintenance period
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            justifyContent: "space-between",
          }}
        >
          <CDateInput title="FROM" value={from} onChange={fromChange} />
          <CDateInput title="TO" value={to} onChange={toChange} />
        </Box>
        <CAutoComplete
          title="Reason"
          options={["car service"]}
          value={reason}
          onChange={reasonChange}
        />

        <Box>
          <CTextInput
            title="Notes"
            value={notes}
            onChange={(value) => {
              setNote(value);
            }}
            placeholder="write a note"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <Button
            sx={classes.buttonCancel}
            onClick={() => {
              onCancelHandler();
            }}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button
            sx={classes.buttonSave}
            onClick={() => {
              onSaveHandler({
                from,
                to,
                reason,
                notes,
              });
            }}
          >
            {" "}
            Save{" "}
          </Button>
        </Box>
      </Box>
    </>
  );
};
const CardFleetEditDayoffModal = ({
  data = null,
  onCancelHandler,
  onSaveHandler,
}) => {
  const [reason, setReason] = useState("car service");
  const [from, setFrom] = useState(dayjs(Date.now()));
  const [to, setTo] = useState(dayjs(Date.now()));
  const [notes, setNote] = useState(" ");

  useEffect(() => {
    if (data) {
      setFrom(dayjs(data.from));
      setTo(dayjs(data.to));
      setNote(data.notes);
    }
  }, [data]);
  const reasonChange = (e) => {
    setReason(e.target.value);
  };

  const fromChange = (value) => {
    if (new Date(value).getTime() <= new Date(to).getTime()) {
      setFrom(value);
    } else {
      setTo(value);
      setFrom(value);
    }
  };

  const toChange = (value) => {
    if (new Date(value).getTime() >= new Date(from).getTime()) setTo(value);
    else {
      setFrom(value);
      setTo(value);
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: "34px 42px",
          display: "flex",
          flexDirection: "column",
          gap: "28px",
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "421px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Manrope",
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "40px",
          }}
        >
          Edit maintenance period
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            justifyContent: "space-between",
          }}
        >
          <Box width="50%">
            <InputTitle>From</InputTitle>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label=""
                onChange={fromChange}
                sx={classes.datePicker}
                value={from}
              />
            </LocalizationProvider>
          </Box>
          <Box width="50%">
            <InputTitle>To</InputTitle>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label=""
                onChange={toChange}
                sx={classes.datePicker}
                value={to}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box>
          <InputTitle>Reason</InputTitle>
          <Select
            label="Year"
            size="small"
            onChange={reasonChange}
            value={reason}
            fullWidth
          >
            <MenuItem value={"car service"}>car service</MenuItem>
          </Select>
        </Box>
        <Box>
          <CTextInput
            title="Notes"
            value={notes}
            onChange={(value) => {
              setNote(value);
            }}
            placeholder="write a note"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <Button
            sx={classes.buttonCancel}
            onClick={() => {
              onCancelHandler();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={classes.buttonSave}
            onClick={() => {
              onSaveHandler({
                from,
                to,
                reason,
                notes,
              });
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};
const CardProfile = ({
  title = "",
  data = {},
  type = FLEET_IDENTIFICATION_MODAL,
  onSave = async () => {},
  onLinkClick = () => {},
  warehouse = null,
  driver = null,
  vehicle = null,
  align = [],
  activation = false,
  link = false,
  linkPos = 0,
  extraData = {},
  direction = "column",
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = async (data) => {
    try {
      const res = await onSave(data, handleClose);
      if (res === true) setOpen(false);
    } catch (err) {}
  };
  return (
    <>
      <Card
        sx={{
          padding: "24px 24px",
          width: "293px",
          display: "flex",
          gap: "12px",
          flexDirection: "column",
          backgroundColor: "#FAFAF8",
          borderRadius: "8px",
          height: "fit-content",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "12px",
          }}
        >
          <Typography
            sx={{
              color: "text.primary",
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "24px",
              fontFamily: "Manrope",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            {activation && (
              <ReportProblemOutlinedIcon sx={{ color: "warnning.secondary" }} />
            )}

            <Stack
              variant="text"
              sx={classes.buttonInCard}
              onClick={() => {
                if (disabled === false) setOpen(true);
              }}
            >
              Edit
            </Stack>
          </Stack>
        </Box>

        {(() => {
          let keys = Object.keys(data);
          let result = [];
          let temp = [];
          for (let i = 0; i < align.length; i++) {
            if (align[i] === 0)
              temp.push(
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: direction,
                    gap: "0px",
                    width: "50%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#9F9E9E",
                      fontSize: "12px",
                      fontWeight: 700,
                      fontFamily: "Manrope",
                    }}
                  >
                    {keys[i].toUpperCase()}
                  </Typography>
                  <Typography
                    sx={{
                      color: "text.brown",
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: "Manrope",
                      textOverflow: "ellipsis",
                      lineHeight: "26px",
                    }}
                  >
                    {data[keys[i]]}
                  </Typography>
                </Box>
              );
            else {
              if (temp.length !== 0)
                result.push(
                  <Box
                    key={align[i]}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: "border.light",
                      paddingBottom: "16px",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: direction,
                    }}
                  >
                    {temp}
                  </Box>
                );
              result.push(
                <Box
                  key={align[i]}
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: "border.light",
                    paddingBottom: "16px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: direction,
                      gap: "0px",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#9F9E9E",
                        fontSize: "12px",
                        fontWeight: 700,
                        fontFamily: "Manrope",
                      }}
                    >
                      {keys[i].toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        color: "text.brown",
                        fontSize: "16px",
                        fontWeight: 400,
                        fontFamily: "Manrope",
                        textOverflow: "ellipsis",
                        lineHeight: "26px",
                      }}
                    >
                      {data[keys[i]]}
                      {keys[i] === "Maximum vehicle capacity" && " kg"}
                    </Typography>
                  </Box>
                </Box>
              );

              temp = [];
            }
            if (link && linkPos === i) {
              result.push(
                <Typography
                  sx={classes.link}
                  onClick={() => {
                    if (
                      type === PRODUCER_LOCATION_MODAL ||
                      type === PICKUP_LOCATION_MODAL
                    ) {
                      setOpen(true);
                    } else {
                      onLinkClick();
                    }
                  }}
                >
                  {link}
                </Typography>
              );
            }
          }
          return result;
        })()}
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {(() => {
          if (type === FLEET_IDENTIFICATION_MODAL)
            return (
              <CardFleetIdentificationModal
                extraData={extraData}
                data={data}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === FLEET_VEHICLE_DETAIL_MODAL)
            return (
              <CardFleetVehicleDetailModal
                data={{ ...data, warehouseId: warehouse, driverId: driver }}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PICKUP_IDENTFICATION_MODAL)
            return (
              <PickupIdentificationModal
                extraData={extraData}
                data={data}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PICKUP_DETAIL_MODAL)
            return (
              <PickupDetailModal
                title={title}
                extraData={extraData}
                data={data}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PICKUP_RELATIONSHIP_MODAL)
            return (
              <PickupResponsibleModal
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
                data={extraData}
              />
            );
          else if (type === PICKUP_SCHEDULE_MODAL)
            return (
              <ScheduleModal
                data={data}
                extraData={extraData}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PICKUP_WASTE_PRICE_MODAL)
            return (
              <PickupWasteModal
                data={data}
                extraData={extraData}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PICKUP_EXPECTED_MODAL)
            return (
              <PickupExpectedModal
                data={data}
                extraData={extraData}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PICKUP_MANAGEMENT_MODAL)
            return (
              <PickupManagementModal
                data={data}
                extraData={extraData}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PICKUP_CONTACTS_MODAL)
            return (
              <PickupContactsModal
                extraData={extraData}
                data={extraData}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PICKUP_DOCUMENTS_MODAL)
            return (
              <PickupDocumentsModal
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
                extraData={extraData}
              />
            );
          else if (type === PICKUP_LOCATION_MODAL)
            return (
              <LocationModal
                description={"Collection Point"}
                title={title}
                data={extraData}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PRODUCER_IDENTIFICATION_MODAL)
            return (
              <ProducerIdentificationModal
                data={extraData}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PRODUCER_CONTACTS_MODAL)
            return (
              <ContactsModal
                data={extraData}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PRODUCER_SCHEDULE_MODAL)
            return (
              <ScheduleModal
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
                extraData={extraData}
              />
            );
          else if (type === PRODUCER_LOCATION_MODAL)
            return (
              <LocationModal
                title={title}
                data={extraData}
                description={"Producer"}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === PRODUCER_RESPONSIBLE_MODAL)
            return (
              <ProducerResponsibleModal
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
                data={extraData}
              />
            );
          else if (type === PRODUCER_MANAGEMENT_MODAL)
            return (
              <ProducerManagementModal
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
                data={extraData}
              />
            );
          else if (type === ZONE_IDENTIFICATION_MODAL)
            return (
              <ZoneIdentificationModal
                data={data}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          else if (type === ZONE_DETAIL_MODAL)
            return (
              <ZoneDetailModal
                data={{
                  ...data,
                  vehicleId: vehicle,
                  driverId: driver,
                  warehouseId: warehouse,
                }}
                title={title}
                onCancelHandler={handleClose}
                onSaveHandler={handleSave}
              />
            );
          return <></>;
        })()}
      </Modal>
    </>
  );
};

const CardTypes = ({ title, item, data, width = "293px" }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Box
        sx={{
          padding: "20px 20px",
          width: width,
          display: "flex",
          gap: "16px",
          flexDirection: "column",
          backgroundColor: "#FAFAF8",
          borderRadius: "8px",
        }}
      >
        <Typography
          sx={{
            color: "text.primary",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "24px",
            fontFamily: "Manrope",
          }}
        >
          {title}
        </Typography>
        <Box>
          <Typography
            sx={{
              color: "#9F9E9E",
              fontSize: "12px",
              fontWeight: 700,
              fontFamily: "Manrope",
            }}
          >
            {item}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginTop: "8px",
            }}
          >
            {data.map((value, index) => {
              if (expanded === true || (expanded === false && index < 3))
                return (
                  <Box
                    key={index}
                    width="100%"
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: "border.primary",
                      paddingBottom: "6px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "text.primary",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        fontFamily: "Manrope",
                      }}
                    >
                      {value}
                    </Typography>
                  </Box>
                );
              else return null;
            })}
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "text.primary",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              fontFamily: "Manrope",
              textDecoration: "underline",
              textAlign: "center",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {expanded ? "View less" : "View all"}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const InfoCard = ({
  children,
  title,
  onClickEdit,
  editable = true,
  isComingSoon = false,
  isFullHeight = false,
  disable = false,
}) => {
  return (
    <Card
      sx={[
        {
          padding: "24px 24px",
          width: "293px",
          display: "flex",
          gap: "12px",
          flexDirection: "column",
          backgroundColor: "#FAFAF8",
          borderRadius: "8px",
          height: "fit-content",
          position: "relative",
        },
        !isFullHeight && { height: "fit-content" },
      ]}
    >
      <Stack sx={{ position: "relative" }}>
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", marginBottom: "20px" }}
        >
          <Typography
            sx={{ fontWeight: "bold", fontSize: "20px", marginRight: "10px" }}
          >
            {title}
          </Typography>
          {editable && !isComingSoon && (
            <Button
              sx={classes.buttonInCard}
              onClick={onClickEdit}
              disabled={disable}
            >
              Edit
            </Button>
          )}
        </Stack>
        <Stack spacing={1}>{children}</Stack>
      </Stack>
      {isComingSoon && (
        <Stack
          flexDirection={"column"}
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={"100%"}
          sx={{
            backgroundColor: "white",
            filter: "blur(10px)",
            opacity: 0.8,
          }}
        ></Stack>
      )}
      {isComingSoon && (
        <Stack
          position={"absolute"}
          flexDirection={"column"}
          top={0}
          left={0}
          width={"100%"}
          height={"100%"}
          zIndex={1}
          justifyContent={"center"}
          textAlign={"center"}
          sx={{
            fontWeight: 700,
          }}
        >
          Coming Soon
        </Stack>
      )}
    </Card>
  );
};

const renderTwoColumnsInfo = (title, value) => {
  return (
    <>
      <Stack gap={1}>
        <Typography
          sx={{
            color: "text.accent",
            fontSize: "12px",
            fontWeight: 800,
            fontFamily: "Manrope",
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "text.lighter",
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Manrope",
          }}
        >
          {value}
        </Typography>
        <CDivider />
      </Stack>
    </>
  );
};

const renderOneColumnInfo = (title, value) => {
  return (
    <>
      <Stack direction="row" sx={{ alignItems: "center" }} gap={1}>
        <Typography
          sx={{
            color: "text.primary",
            fontSize: "12px",
            fontFamily: "Manrope",
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "text.lighter",
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Manrope",
          }}
        >
          {value}
        </Typography>
      </Stack>
      <CDivider sx={{ marginY: "8px" }} />
    </>
  );
};

export {
  CardPrimary,
  CardProfile,
  CardTypes,
  CardFleetAddRawWasteModal,
  CardFleetRegisterKilo,
  CardFleetAddDayoffModal,
  CardFleetEditDayoffModal,
  CardFleetEditRawWasteModal,
  InfoCard,
  renderOneColumnInfo,
  renderTwoColumnsInfo,
};
