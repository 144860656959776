import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFleetId: 0,
  currentTransferList: [],
  currentRawWastes: [],
  vehicles: [],
  drivers: [],
  vehicleRoutes: [],
};

export const fleetSlice = createSlice({
  name: "fleet",
  initialState,
  reducers: {
    setCurrentFleet: (state, action) => {
      state.selectedFleetId = action.payload;
    },
    setCurrentTransferList: (state, action) => {
      state.currentTransferList = action.payload;
    },
    setCurrentRawWastes: (state, action) => {
      state.currentRawWastes = action.payload;
    },
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
    setDrivers: (state, action) => {
      state.drivers = action.payload;
    },
    setVehicleRoutes: (state, action) => {
      state.vehicleRoutes = action.payload;
    },
  },
});

export const {
  setCurrentRawWastes,
  setCurrentFleet,
  setCurrentTransferList,
  setVehicles,
  setDrivers,
  setVehicleRoutes,
} = fleetSlice.actions;

export default fleetSlice.reducer;
