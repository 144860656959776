import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Stack, Typography } from "@mui/material";
import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import { CAutoComplete, CTextInput } from "../../../../components/CustomInput";
import {
  fetchAvailableRawWastes,
  fetchRawWastes,
} from "../../../../actions/organizations";
import { addDeposit } from "../../../../actions/warehouse";
import { setAddDepositStep } from "../../../../slices/warehouseSlice";

const AddDepositDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState();
  const rawWastes = useSelector(
    (state) => state.organizations.availableRawWastes
  );
  const id = useSelector((state) => state.warehouse.current.id);
  const [rawWaste, setRawWaste] = useState("");
  const [capacity, setCapacity] = useState(0);

  useEffect(() => {
    dispatch(fetchAvailableRawWastes());
  }, []);
  useEffect(() => {
    const data = rawWastes.map((rw, idx) => {
      return {
        value: rw.rawWasteId,
        name: rw.name,
        component: (
          <Stack>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              {rw.code}
            </Typography>
            <Typography sx={{ color: "grey", fontSize: "12px" }}>
              {rw.name}
            </Typography>
          </Stack>
        ),
      };
    });
    setItems(data);
  }, [rawWastes]);

  const onSave = () => {
    dispatch(
      addDeposit(id, {
        raw_waste: rawWastes.find((rw) => rw.name === rawWaste.name).rawWasteId,
        capacity,
      })
    );
    onClose();
    dispatch(setAddDepositStep(false));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        padding={"34px 42px"}
        gap={"22px"}
        sx={{
          backgroundColor: "text.contrast",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "520px",
          borderRadius: "8px",
        }}
      >
        <PageTitle>Add Capacity</PageTitle>
        <CAutoComplete
          title="Raw waste type selection"
          required
          placeholder="Select Raw Waste Type"
          options={items}
          value={rawWaste}
          fixed
          hasComponent
          onChange={(v) => {
            setRawWaste(v);
          }}
        />
        <CTextInput
          title="Capacity"
          required
          endIcon="kg"
          value={capacity}
          onChange={(v) => setCapacity(v)}
        />
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button sx={classes.buttonCancel} onClick={onClose}>
            Cancel
          </Button>
          <Button sx={classes.buttonSave} onClick={onSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AddDepositDialog;
