import { Box, Button, Typography } from "@mui/material";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

const AddRawWasteView = ({ setStage }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <PageTitle>Raw waste</PageTitle>
        <Button variant="outlined" sx={classes.buttonPrimary}>
          Add raw waste
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "80%",
        }}
      >
        <NoteAddOutlinedIcon sx={{ color: "text.tertiary", fontSize: 100 }} />
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Manrope",
            fontSize: "10px",
            color: "text.primary",
            marginBottom: "30px",
          }}
        >
          There are no wastes listed
        </Typography>
        <Button
          size="large"
          sx={classes.buttonPrimary}
          onClick={() => setStage(2)}
        >
          Add raw waste
        </Button>
      </Box>
    </Box>
  );
};

export default AddRawWasteView;
