import { inventory_axios } from "../utils/axios";

//read

const fetchInventoryList = (isOffer) => {
  return inventory_axios.get(`/list-inventories/${isOffer}`);
};
const fetchInventorySubList = (id) => {
  return inventory_axios.get(`/list-stock-items/${id}`);
};

const fetchInventoryCategory = () => {
  return inventory_axios.get("/list-categories");
};

const fetchInventoryName = (id) => {
  return inventory_axios.get(`/list-names/${id}`);
};

const fetchOwnerCurrentLocationList = () => {
  return inventory_axios.get(`/list-owner-current-location`);
};

const fetchInventoryPriceHistory = (id) => {
  return inventory_axios.get(`/get-offer-price-history/${id}`);
};
// Create

const createCategory = (data) => {
  return inventory_axios.post("/create-category", data);
};
const archiveCategory = (id) => {
  return inventory_axios.put(`/archive-category/${id}`);
};
const unarchiveCategory = (id) => {
  return inventory_axios.put(`/unarchive-category/${id}`);
};
const activateCategory = (id) => {
  return inventory_axios.put(`/activate-category/${id}`);
};
const deactivateCategory = (id) => {
  return inventory_axios.put(`/deactivate-category/${id}`);
};
const createName = (data) => {
  return inventory_axios.post("/create-name", data);
};

const createInventory = (data) => {
  return inventory_axios.post("/create-inventory", data);
};

const addStock = (id, data) => {
  return inventory_axios.post(`/add-stock/${id}`, data);
};

// update
const updateStockItemId = (id, data) => {
  return inventory_axios.put(`/edit-stock-item-id/${id}`, data);
};
const updateInStockSubTotal = (id, data) => {
  return inventory_axios.put(`/update-in-stock-subtotal/${id}`, data);
};
const updateSubTotal = (id, data) => {
  return inventory_axios.put(`/update-subtotal/${id}`, data);
};
const updateCategory = (id, data) => {
  return inventory_axios.put(`/edit-category/${id}`, data);
};

const archiveItem = (id) => {
  return inventory_axios.put(`/archive-item/${id}`);
};

const deactivateItem = (id) => {
  return inventory_axios.put(`/deactivate-item/${id}`);
};
const activateItem = (id) => {
  return inventory_axios.put(`/activate-item/${id}`);
};
const makeGeneric = (id) => {
  return inventory_axios.put(`/make-generic/${id}`);
};

const makeUnique = (id, data) => {
  return inventory_axios.put(`/make-unique/${id}`, data);
};

// delete

const removeCategory = (id) => {
  return inventory_axios.delete(`/remove-category/${id}`);
};

const removeStock = (id) => {
  return inventory_axios.delete(`/remove-stock/${id}`);
};
const editCategory = (id, data) => {
  return inventory_axios.put(`/edit-category/${id}`, data);
};
const editOffer = (id, data) => {
  return inventory_axios.put(`/edit-offer/${id}`, data);
};
const unarchiveItem = (id, data) => {
  return inventory_axios.put(`/unarchive-item/${id}`);
};

const InventoryService = {
  fetchInventoryCategory,
  fetchInventoryList,
  fetchInventorySubList,
  fetchInventoryName,
  fetchOwnerCurrentLocationList,
  createCategory,
  createName,
  createInventory,
  addStock,
  updateCategory,
  updateInStockSubTotal,
  archiveItem,
  deactivateItem,
  activateItem,
  makeGeneric,
  makeUnique,
  removeCategory,
  removeStock,
  updateStockItemId,
  updateSubTotal,
  archiveCategory,
  unarchiveCategory,
  activateCategory,
  deactivateCategory,
  editCategory,
  editOffer,
  unarchiveItem,
  fetchInventoryPriceHistory,
};

export default InventoryService;
