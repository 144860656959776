import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teamList: [],
  members: [],
  roles: [],
  currentMember: null,
  daysOff: [],
  users: [],
  currentTeam: null,
};

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setTeamList: (state, action) => {
      state.teamList = action.payload;
    },
    setMembers: (state, action) => {
      state.members = action.payload;
    },
    setRoleList: (state, action) => {
      state.roles = action.payload;
    },
    setDaysOff: (state, action) => {
      state.daysOff = action.payload;
    },
    setAvailableUsers: (state, action) => {
      state.users = action.payload;
    },
    setCurrentTeam: (state, action) => {
      state.currentTeam = action.payload;
    },
  },
});

export const {
  setAvailableUsers,
  setDaysOff,
  setCurrentTeam,
  setTeamList,
  setMembers,
  setRoleList,
} = memberSlice.actions;

export default memberSlice.reducer;
