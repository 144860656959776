import { visit_axios } from "../utils/axios";

const fetchVisitsList = (scheduled) => {
  if (scheduled) return visit_axios.get(`/list-scheduled-visits`);
  else return visit_axios.get(`/list-visits`);
};

const fetchVisitDetail = (id) => {
  return visit_axios.get(`/get-visit-details/${id}`);
};

const createVisit = (data) => {
  return visit_axios.post(`/create-visit`, data);
};

const editVisitScheduled = (id, data) => {
  return visit_axios.put(`/edit-scheduled-visit/${id}`, data);
};

const updateVisitStatus = (id, data) => {
  return visit_axios.put(`/update-visit-status/${id}`, data);
};

const rescheduleVisit = (id, data) => {
  return visit_axios.put(`/reschedule-visit/${id}`, data);
};

const deleteScheduledVisit = (id) => {
  return visit_axios.delete(`/remove-scheduled-visit/${id}`);
};
const VisitService = {
  fetchVisitsList,
  fetchVisitDetail,
  createVisit,
  editVisitScheduled,
  updateVisitStatus,
  rescheduleVisit,
  deleteScheduledVisit,
};

export default VisitService;
