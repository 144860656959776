import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DirectionsOutlinedIcon from "@mui/icons-material/DirectionsOutlined";

import { CardPrimary } from "../../../components/Card";
import { SearchBar } from "../../../components/CustomInput";
import { StateSpan } from "../../../components/StateSpan";
import { DataTable } from "../../../components/Table";
import {
  fetchVisitsList,
  rescheduleVisit,
  updateVisitStatus,
} from "../../../actions/visit";
import {
  PURPOSE_TYPES,
  getDateStringFromNum,
  getServerTimestamp,
} from "../../../utils/helpers";
import { DRIVER } from "../../../utils/constants";
import DateDialog from "./DateDialog";
import { setCurrentScheduled } from "../../../slices/visitsSlice";

// action import
const ScheduledVisitsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = useSelector((state) => state.user.role);

  const [filterModel, setFilterModel] = useState({ items: [] });
  const [dateDialog, setDateDialog] = useState(false);
  const [dateAnchor, setDateAnchor] = useState(null);
  const [current, setCurrent] = useState(null);

  const orgData = useSelector((state) => state.organizations.profile);
  const visitsData = useSelector((state) => state.visits.scheduled_visits);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    dispatch(
      fetchVisitsList(true, () => {
        setIsLoaded(true);
      })
    );
  }, []);

  const onClickDate = (date) => {
    dispatch(
      rescheduleVisit(current._id, {
        date: getServerTimestamp(
          new Date(`${date.$y}-${date.$M + 1}-${date.$D}`).getTime(),
          orgData.timezone
        ),
      })
    );
  };

  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (props) => {
        return <StateSpan status={props.row.status} />;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {`${getDateStringFromNum(props.row.date, orgData.timezone)}`}
        </span>
      ),
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">{PURPOSE_TYPES[props.row.purpose]}</span>
      ),
    },
    {
      field: "pickup_point_name",
      headerName: "Collection Point",
      width: 200,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "destination_warehouse_name",
      headerName: "Destination Warehouse",
      width: 200,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {props.row.destination_warehouse_name || ""}
        </span>
      ),
    },
    {
      field: "transfer",
      headerName: "Expected Transfer",
      width: 400,
      renderCell: (props) => {
        if (!props.row.quantity) return "";
        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: "100px" }}>
              {props.row.quantity} kg
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "zone",
      headerName: "Zone",
      width: 180,
      renderCell: (props) => {
        let zones = [];
        if (props.row.zones.length > 0) {
          props.row.zones.map((item, index) => {
            return zones.push(item?.name);
          });
        }
        let zoneData = zones.join(", ");
        return <span className="mui-ellipsis">{zoneData}</span>;
      },
    },
    {
      field: "vehicle_name",
      headerName: "Vehicle",
      width: 150,
      renderCell: (props) => {
        return (
          <span className="mui-ellipsis">
            {props.row.vehicles[0]?.reg_plate || ""}
          </span>
        );
      },
    },
    {
      field: "driver_name",
      headerName: "Driver",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.row.driver_name || ""}</span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (props) => {
        const status = props.row.status;
        const handle = (e, action) => {
          dispatch(setCurrentScheduled(props.row));
          e.stopPropagation();
          if (action === "go") {
            navigate("/add-visit-log");
          } else if (action !== "schedule")
            dispatch(updateVisitStatus(props.row._id, { status: action }));
          else {
            setCurrent(props.row);
            setDateDialog(true);
            setDateAnchor(e.currentTarget);
          }
        };
        return (
          <Stack direction="row">
            <IconButton
              disabled={status !== "suggestion" || role === DRIVER}
              onClick={(e) => handle(e, "planned")}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              disabled={
                (status !== "planned" && status !== "suggestion") ||
                role === DRIVER
              }
              onClick={(e) => handle(e, "discard")}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              disabled={
                (status !== "overdue" &&
                  status !== "planned" &&
                  status !== "suggestion") ||
                role === DRIVER
              }
              onClick={(e) => handle(e, "schedule")}
            >
              <DateRangeIcon />
            </IconButton>
            <IconButton
              disabled={status !== "planned"}
              onClick={(e) => handle(e, "go")}
            >
              <DirectionsOutlinedIcon />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 100,
      renderCell: (props) => {
        return (
          <>
            <div
              style={{ width: "60px", display: "flex", alignItems: "center" }}
            >
              {props.row.notes && (
                <Tooltip
                  slotProps={{
                    tooltip: {
                      sx: {
                        background: "black",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "black",
                      },
                    },
                  }}
                  title={props.row.notes}
                  placement="top"
                >
                  <SpeakerNotesOutlinedIcon />
                </Tooltip>
              )}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px 0",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px" }}>
            {isLoaded ? (
              <CardPrimary
                type={1}
                title="Nº Visits"
                content={visitsData.length}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"82px"} />
            )}
          </Box>
        </Box>
        <Box sx={{ width: "300px" }}>
          {isLoaded ? (
            <SearchBar
              label="Search visit"
              onSearchChange={(value) => {
                setFilterModel({
                  items: [
                    {
                      id: 1,
                      field: "pickup_point_name",
                      operator: "contains",
                      value: value,
                    },
                  ],
                });
              }}
            />
          ) : (
            <Skeleton variant="rounded" width={"100%"} />
          )}
        </Box>
      </Box>
      <Box>
        <Box sx={{ maxWidth: `${window.innerWidth - 400}px` }}>
          {isLoaded ? (
            <DataTable
              fit="100%"
              breakpoint="xl"
              rows={visitsData}
              columns={columns}
              filterModel={filterModel}
              onRowClick={(params, events, details) => {
                dispatch(setCurrentScheduled(params.row));
                navigate("/edit-scheduled-visit");
              }}
              initialState={{
                pinnedColumns: { right: ["actions"] },
              }}
            />
          ) : (
            <Skeleton width={"100%"} height={"200px"} />
          )}
        </Box>
        <DateDialog
          open={dateDialog}
          handleClose={() => {
            setDateDialog(false);
          }}
          anchorEl={dateAnchor}
          onClickDate={onClickDate}
        />
      </Box>
    </>
  );
};

export default ScheduledVisitsView;
