import React, { useState } from "react";
// material-ui
import {
  Box,
  Button,
  Grid,
  Input,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";

// project imports
import { InputTitle, PageTitle } from "../../../components/Typography";

//sx class definition
import { classes } from "../../../utils/class";
import { SearchBar } from "../../../components/CustomInput";
import { DataTable } from "../../../components/Table";
import { useSelector, useDispatch } from "react-redux";
import { addTicketsInventory } from "../../../slices/ticketsSlice";
import { useEffect } from "react";
import { showNotification } from "../../../utils/helpers";

const TicketsInventoryPage = ({ setStage, setItems }) => {
  const dispatch = useDispatch();
  const [filterModel, setFilterModel] = useState({ items: [] });

  const offers = useSelector((state) => state.inventory.offers);
  const [itemsToRequest, setItemsToRequest] = useState([]);

  useEffect(() => {
    setItemsToRequest(offers.map((offer) => ({ ...offer, amount: 0 })));
  }, [offers]);

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "category_name", headerName: "Category", width: 200 },
    {
      field: "offer_price",
      headerName: "Value",
      width: 100,
      renderCell: (props) =>
        `${props.row.offer_price}${props.row.offer_unit === "EUR" ? "€" : "$"}`,
    },
    { field: "total_amount", headerName: "Available Qty", width: 100 },
    {
      field: "action",
      headerName: "",
      width: 200,
      renderCell: (props) => {
        return (
          <OutlinedInput
            size="small"
            sx={{ width: "4rem", height: "35px" }}
            value={props.row.amount}
            onChange={(e) => {
              if (parseInt(e.target.value) > props.row.total_amount) {
                showNotification("Not Enough Stock", "error");
                return;
              }

              const temp = [...itemsToRequest];
              temp.find((t) => t._id === props.row._id).amount = e.target.value;
              setItemsToRequest(temp);
            }}
          />
        );
      },
    },
  ];

  const onAdd = () => {
    setItems(itemsToRequest.filter((item) => item.amount > 0));
    setStage(1);
  };

  return (
    <Box sx={{ minHeight: "100vh", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
          paddingX: "60px",
        }}
      >
        <Button
          variant="outlined"
          sx={classes.buttonPrimary}
          onClick={() => setStage(1.0)}
        >
          Back
        </Button>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            sx={classes.buttonCancel}
            onClick={() => setStage(1.0)}
          >
            Cancel
          </Button>
          <Button variant="contained" sx={classes.buttonSave} onClick={onAdd}>
            Add
          </Button>
        </Stack>
      </Box>
      <Stack direction="column" sx={{ margin: "30px 100px" }}>
        <PageTitle>Items to deliver</PageTitle>
        <Typography sx={{ width: "500px", marginBottom: "50px" }}>
          Please select from the general inventory presented below all the
          equipment you wish to add to your visit regist.
        </Typography>
        <Box
          sx={{
            paddingLeft: "20px",
            width: "300px",
          }}
        >
          <SearchBar
            label="Search Items"
            onSearchChange={(value) => {
              setFilterModel({
                items: [
                  { id: 1, field: "name", operator: "contains", value: value },
                ],
              });
            }}
          />
        </Box>
        <DataTable
          columns={columns}
          rows={itemsToRequest}
          sx={classes.grid}
          filterModel={filterModel}
        />
      </Stack>
    </Box>
  );
};
export default TicketsInventoryPage;
