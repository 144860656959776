import { useState } from "react";
import AddRawWasteView from "./raw-waste/AddRawWasteView";
import SelectRawWasteView from "./raw-waste/SelectRawWasteView";
import RawWasteListView from "./raw-waste/RawWasteListView";
import EditRawWasteView from "./raw-waste/EditRawWasteView";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchRawWastes } from "../../../actions/organizations";

const RawWastePage = () => {
  const dispatch = useDispatch();
  const [stage, setStage] = useState(1);
  const rawWastes = useSelector((state) => state.organizations.rawWastes);

  useEffect(() => {
    dispatch(fetchRawWastes());
  }, []);

  if (!rawWastes.length) return <AddRawWasteView setStage={setStage} />;
  if (stage === 1) return <RawWasteListView setStage={setStage} />;
  if (stage === 2) return <SelectRawWasteView setStage={setStage} />;
  if (stage === 3) return <EditRawWasteView setStage={setStage} />;
};

export default RawWastePage;
