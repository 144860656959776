import { Box, Button, Stack } from "@mui/material";
import { PageTitle } from "../../../components/Typography";
import { SearchBar } from "../../../components/CustomInput";
import { useEffect, useState } from "react";
import { classes } from "../../../utils/class";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "../../../components/Table";
import { fetchPickupPoints } from "../../../actions/pickup";
import { useParams } from "react-router-dom";
import {
  addPickupPointToZone,
  fetchZonesPickup,
  removeZonePickup,
} from "../../../actions/zone";
import { GridLogicOperator } from "@mui/x-data-grid";

const ManagePickupPoint = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const [addedPoints, setAddedPoints] = useState({});
  const rows = useSelector((state) => {
    return state.pickup.pickupList;
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 100,
      flex: 1,
      renderCell: (props) => {
        return (
          <>
            <Stack>
              <Stack>
                <Stack
                  sx={{
                    fontSize: "18px",
                    fontWeight: 700,
                  }}
                >
                  {props.row.name}
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                gap={1}
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  color: "text.accent",
                }}
              >
                <Stack>{props.row.official_id}</Stack>
                <Stack>{props.row.id}</Stack>
                <Stack>{props.row.producer}</Stack>
              </Stack>
            </Stack>
          </>
        );
      },
    },
    {
      field: "raw_waste",
      headerName: "Raw waste",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "manager",
      headerName: "Manager",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "zones",
      headerName: "Zone",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "location_g",
      width: 350,
      headerName: "Location",
      renderCell: (props) => {
        return (
          <>
            <Box
              sx={{
                width: 250,
                whiteSpace: "normal",
                wordBreak: "break-word",
                lineHeight: "normal",
              }}
            >
              {props.row.addr_country && props.row.addr_city ? (
                <>
                  {props.row.addr_country},{props.row.addr_city}
                </>
              ) : (
                <></>
              )}
            </Box>
            <Box position={"absolute"} right={20} zIndex={999999}>
              {addedPoints[props.row.id] === true ? (
                <Button
                  sx={{
                    ...classes.buttonBack,
                    border: "none",
                    color: "#D64C56",
                    backgroundColor: "#D64C561A",
                  }}
                  onClick={() => {
                    dispatch(
                      removeZonePickup(id, { pickup_point: props.row._id })
                    );
                  }}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  sx={{
                    ...classes.buttonBack,
                    border: "none",
                    color: "#2CB53B",
                    backgroundColor: "#2CB53B1A",
                  }}
                  onClick={() => {
                    dispatch(
                      addPickupPointToZone(id, { pickup_point: props.row._id })
                    );
                  }}
                >
                  Add
                </Button>
              )}
            </Box>
          </>
        );
      },
    },
  ];

  const [filterModel, setFilterModel] = useState({ items: [] });
  const { currentZonePickup } = useSelector((state) => state.zone);

  useEffect(() => {
    dispatch(fetchPickupPoints());
  }, []);

  useEffect(() => {
    dispatch(fetchZonesPickup(id));
  }, []);

  useEffect(() => {
    let t = {};
    for (let pick of currentZonePickup) {
      t[pick.id] = true;
    }
    setAddedPoints(t);
  }, [currentZonePickup]);

  return (
    <Stack
      direction={"column"}
      marginTop={3}
      gap={2}
      paddingLeft={"60px"}
      paddingRight={"60px"}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <PageTitle>Manage collection points</PageTitle>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Button
            variant="outlined"
            sx={classes.buttonPrimary}
            onClick={() => {
              window.history.back();
            }}
          >
            Close
          </Button>
        </Stack>
      </Stack>
      <Stack
        justifyContent={"flex-start"}
        direction={"row"}
        alignItems={"center"}
        gap={1}
      >
        <SearchBar
          placeholder="Search collection point"
          sx={{ width: "275px" }}
          onSearchChange={(value) => {
            setFilterModel({
              items: [
                {
                  id: 1,
                  field: "name",
                  operator: "contains",
                  value: value,
                },
                // {
                //   id: 2,
                //   field: "raw_waste",
                //   operator: "contains",
                //   value: value,
                // },
              ],
              logicOperator: GridLogicOperator.Or,
            });
          }}
        />
        {Object.keys(addedPoints).length} added collection points
      </Stack>

      <Box paddingRight={20}>
        <DataTable
          filterModel={filterModel}
          rows={rows}
          columns={columns}
          autoSize={true}
          expandable
        />
      </Box>
    </Stack>
  );
};
export default ManagePickupPoint;
