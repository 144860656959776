import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Button } from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

import { CAutoComplete, CTextInput } from "../../../../components/CustomInput";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoleList } from "../../../../actions/member";
const ChangeRoleDialog = ({
  open = false,
  role = null,
  roleName = null,
  handleClose = function () {},
  handleContinue = function () {},
}) => {
  const dispatch = useDispatch();
  const [roleOption, setRoleOption] = useState([]);
  const { roles } = useSelector((state) => state.member);

  const [newRole, setNewRole] = useState(null);
  useEffect(() => {
    if (role && roleName) setNewRole({ name: roleName, value: role });
    else setNewRole(null);
  }, [role, roleName]);
  useEffect(() => {
    dispatch(fetchRoleList());
  }, []);
  useEffect(() => {
    const list = roles.map((role, index) => {
      return {
        ...role,
        name: role.display_name,
        value: role.name,
      };
    });
    setRoleOption(list);
  }, [roles]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} padding={5} width={"420px"}>
            <PageTitle>Change member role</PageTitle>
            <CAutoComplete
              title="Role"
              placeholder="Select a role"
              required
              options={roleOption}
              fixed
              value={newRole}
              onChange={(val) => {
                if (val) {
                  setNewRole(val);
                }
              }}
            />
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <Button sx={classes.buttonCancel} onClick={handleClose}>
                Discard changes
              </Button>
              <Button
                sx={classes.buttonSave}
                onClick={() => {
                  handleClose();
                  handleContinue(newRole.value);
                }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ChangeRoleDialog;
