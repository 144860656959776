import React, { useState } from "react";

// material-ui
import { Box, Stack, Typography } from "@mui/material";

// project import
import { SearchBar } from "./CustomInput";

// utills
import { findString } from "../utils/string";

const ItemList = ({ children, onSelectChange, value, label = "Search" }) => {
  const [searchText, setSearchText] = useState("");
  const onSearchChange = (value) => {
    setSearchText(value);
  };

  return (
    <>
      <Stack
        minWidth={250}
        maxWidth={250}
        sx={{
          backgroundColor: "#F3F1EB",
        }}
      >
        <Box sx={{ paddingX: "20px" }} mb={5}>
          <SearchBar
            noBorder
            onSearchChange={onSearchChange}
            placeholder={label}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
          }}
        >
          {React.Children.map(children, (item, index) => {
            if (
              item.props != null &&
              findString(searchText, Object.values(item.props).toString())
            )
              return (
                <Item
                  key={index}
                  id={index}
                  onItemChange={onSelectChange}
                  props={{
                    ...item.props,
                    selected: index === value || item.props.selected,
                  }}
                />
              );
          })}
        </Box>
      </Stack>
    </>
  );
};

const Item = ({ id, props, onItemChange }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: props.selected ? "text.contrast" : "#F3F1EB",
          padding: "16px",
          paddingLeft: "32px",
          // borderBottom: "1px solid",
          // borderColor: "background.primary",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "#F5F7F9",
            "& > *": {
              color: "text.lighter",
            },
          },
          "&:active": {
            backgroundColor: "background.bright",
          },
        }}
        onClick={() => onItemChange(id)}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontFamily: "Manrope",
            color: props.selected ? "text.primary" : "text.light",
            fontWeight: 700,
          }}
        >
          {props.title}
        </Typography>

        <Typography
          sx={{
            fontSize: "12px",
            color: props.selected ? "text.primary" : "text.light",
            fontFamily: "Manrope",
            fontWeight: 400,
          }}
        >
          {props.content}
        </Typography>
      </Box>
    </>
  );
};
export { ItemList, Item };
