import PickupService from "../services/PickupService";
import {
  setDocumentList,
  setExtraInfo,
  setPickupDetail,
  setPickupList,
  setVisitList,
  setTransferList,
  setScheduledVisitList,
  setDepositList,
  setPickupInventoryList,
  setPickupOfferList,
  setPickupRawWastes,
  setRawWasteList,
} from "../slices/pickupSlice";
import { ERROR } from "../utils/constants";
import { showNotification } from "../utils/helpers";
import { SUCCESS } from "../utils/type";
import VisitService from "../services/VisitService";
import ProducerService from "../services/ProducerService";
import { fetchProducerPickupList } from "./producers";
import { signOut } from "./user";

export const fetchPickupPoints =
  (callBack = () => {}) =>
  (dispatch) => {
    PickupService.fetchPickupPoints()
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setPickupList(res.data.data.data));
          dispatch(
            setExtraInfo({
              count_without_manager: res.data.data.count_without_manager,
              count_expiring_documents: res.data.data.count_expiring_documents,
              count_expired_documents: res.data.data.count_expired_documents,
              count_with_zero_waste_collected:
                res.data.data.count_with_zero_waste_collected,
            })
          );
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
        if (err.response) {
          if (err.response.data.message === "authentication_error")
            dispatch(signOut());
        }
      });
  };

export const fetchPickupPointDetail =
  (pickupId, callBack = () => {}) =>
  (dispatch) => {
    PickupService.fetchPickupPointDetail(pickupId)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setPickupDetail(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
        if (err.response) {
          if (err.response.data.message === "authentication_error")
            dispatch(signOut());
        }
      });
  };

export const fetchPickupPointTransfers =
  (pickupId, callBack = () => {}) =>
  (dispatch) => {
    PickupService.fetchPickupPointTransfers(pickupId)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setTransferList(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
        if (err.response) {
          if (err.response.data.message === "authentication_error")
            dispatch(signOut());
        }
      });
  };

export const fetchPickupPointDocuments =
  (pickupId, callBack = () => {}) =>
  (dispatch) => {
    PickupService.fetchDocumentList(pickupId)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setDocumentList(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
        if (err.response) {
          if (err.response.data.message === "authentication_error")
            dispatch(signOut());
        }
      });
  };

export const fetchPickupPointVisits =
  (pickupId, callBack = () => {}) =>
  (dispatch) => {
    PickupService.fetchPickupPointVisits(pickupId)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setVisitList(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
        if (err.response) {
          if (err.response.data.message === "authentication_error")
            dispatch(signOut());
        }
      });
  };

export const fetchPickupPointScheduledVisits =
  (pickupId, callBack = () => {}) =>
  (dispatch) => {
    PickupService.fetchPickupPointScheduledVisits(pickupId)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setScheduledVisitList(res.data.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
        if (err.response) {
          if (err.response.data.message === "authentication_error")
            dispatch(signOut());
        }
      });
  };
//POST

export const editPickup = (id, data, callBack) => (dispatch) => {
  PickupService.updatePickupPoint(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Profile updated successfully", "success");
        dispatch(fetchPickupPoints());
        dispatch(fetchPickupPointDetail(id));
        callBack();
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const activatePickup =
  (id, callBack = () => {}) =>
  (dispatch) => {
    PickupService.activatePickupPoint(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(fetchPickupPointDetail(id));
          dispatch(fetchPickupPoints());
          showNotification("Collection Point is activated", "success");
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
        callBack();
      });
  };

export const archivePickup =
  (id, callBack = () => {}) =>
  (dispatch) => {
    PickupService.archivePickupPoint(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(fetchPickupPointDetail(id));
          dispatch(fetchPickupPoints());
          showNotification("Collection Point is archived", "success");
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
        callBack();
      });
  };

export const unarchivePickup =
  (id, callBack = () => {}) =>
  (dispatch) => {
    PickupService.unarchivePickupPoint(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(fetchPickupPointDetail(id));
          dispatch(fetchPickupPoints());
          showNotification("Collection Point is unarchived", "success");
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
        callBack();
      });
  };
export const deactivatePickup = (id) => (dispatch) => {
  PickupService.deactivatePickupPoint(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchPickupPointDetail(id));
        dispatch(fetchPickupPoints());
        showNotification("Collection Point is deactivated", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const createPickupPoint =
  (data, callBack = () => {}) =>
  (dispatch) => {
    PickupService.createPickupPoint(data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("New Collection Point added", "success");
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const addDocument =
  (pickupId, data, callback = () => {}) =>
  (dispatch) => {
    PickupService.addDocument(pickupId, data)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          showNotification("Documents has been successfully added ", "success");
          dispatch(fetchPickupPointDetail(pickupId));
          callback();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const reschedulePickupVisit = (pickupId, id, data) => (dispatch) => {
  VisitService.rescheduleVisit(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchPickupPointScheduledVisits(pickupId));
        showNotification("Visit is rescheduled", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const deleteScheduleVisit = (pickupId, id) => (dispatch) => {
  VisitService.deleteScheduledVisit(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchPickupPointScheduledVisits(pickupId));
        showNotification("Schedule Visit removed", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const deleteDocumentForPickup = (pickupId, doc_id) => (dispatch) => {
  PickupService.deletePickupPointDocuments(doc_id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchPickupPointDetail(pickupId));
        showNotification("Document is removed", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const checkVisit = (pickupId, id, data) => (dispatch) => {
  VisitService.updateVisitStatus(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchPickupPointScheduledVisits(pickupId));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const fetchPickupInventory =
  (id, callBack = () => {}) =>
  (dispatch) => {
    PickupService.fetchPickupInventory(id, false)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setPickupInventoryList(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const fetchPickupOffer =
  (id, callBack = () => {}) =>
  (dispatch) => {
    PickupService.fetchPickupInventory(id, true)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setPickupOfferList(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };
export const fetchPickupDeposits =
  (id, callBack = () => {}) =>
  (dispatch) => {
    PickupService.fetchPickupDeposits(id)
      .then((res) => {
        if (res.data.message === SUCCESS) {
          dispatch(setDepositList(res.data.data));
          callBack();
        }
      })
      .catch((err) => {
        showNotification(
          err.response
            ? ERROR[err.response.data.message] || "Network Error"
            : err.message,
          "error"
        );
      });
  };

export const addPickupPointDeposit = (id, data) => (dispatch) => {
  PickupService.addPickupPointDeposit(id, data)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchPickupDeposits(id));
        showNotification("New raw waste added", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const fetchAvailableRawWaste = (producerId) => (dispatch) => {
  ProducerService.fetchProducerRawWasteAvailableList(producerId)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setRawWasteList(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const editDeposit = (id, pickupId, data) => (dispatch) => {
  PickupService.editPickupPointDeposit(id, data)
    .then((res) => {
      dispatch(fetchPickupDeposits(pickupId));
      showNotification("Capacity has been changed", "success");
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const removeDeposit = (id, pickupId) => (dispatch) => {
  PickupService.removePickupPointDeposit(id)
    .then((res) => {
      dispatch(fetchPickupDeposits(pickupId));
      showNotification("Capacity has been removed", "success");
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const activateDeposit = (id, pickupId) => (dispatch) => {
  PickupService.activatePickupPointDeposit(id)
    .then((res) => {
      dispatch(fetchPickupDeposits(pickupId));
      showNotification("Raw waste has been activated", "success");
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const deactivateDeposit = (id, pickupId) => (dispatch) => {
  PickupService.deactivatePickupPointDeposit(id)
    .then((res) => {
      dispatch(fetchPickupDeposits(pickupId));
      showNotification("Raw waste has been deactivated", "success");
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const unarchiveDeposit = (id, pickupId) => (dispatch) => {
  PickupService.unarchivePickupPointDeposit(id)
    .then((res) => {
      dispatch(fetchPickupDeposits(pickupId));
      showNotification("Raw waste has been unarchived", "success");
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const archiveDeposit = (id, pickupId) => (dispatch) => {
  PickupService.archivePickupPointDeposit(id)
    .then((res) => {
      dispatch(fetchPickupDeposits(pickupId));
      showNotification("Raw waste has been archived", "success");
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const validateDocument = (id, pickupId) => (dispatch) => {
  PickupService.validateDocument(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchPickupPointDocuments(pickupId));
        showNotification("Document has been validated", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const revokeDocument = (id, pickupId) => (dispatch) => {
  PickupService.revokeDocument(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(fetchPickupPointDocuments(pickupId));
        showNotification("Document has been revoked", "success");
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const fetchPickupRawWastes = (id) => (dispatch) => {
  PickupService.fetchPickupRawWastes(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        dispatch(setPickupRawWastes(res.data.data));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const removePickup = (id) => (dispatch) => {
  PickupService.removePickupPoint(id)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Collection Point has been removed", "success");
        dispatch(fetchPickupPoints());
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const archiveProducerPickup = (id, data) => (dispatch) => {
  PickupService.archivePickupPoint(data.pickup_point)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Collection Point has been archived", "success");
        dispatch(fetchProducerPickupList(id));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const unarchiveProducerPickup = (id, data) => (dispatch) => {
  PickupService.unarchivePickupPoint(data.pickup_point)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Collection Point has been unarchived", "success");
        dispatch(fetchProducerPickupList(id));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};

export const activateProducerPickup = (id, data) => (dispatch) => {
  PickupService.activatePickupPoint(data.pickup_point)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Collection Point has been activated", "success");
        dispatch(fetchProducerPickupList(id));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
export const deactivateProducerPickup = (id, data) => (dispatch) => {
  PickupService.deactivatePickupPoint(data.pickup_point)
    .then((res) => {
      if (res.data.message === SUCCESS) {
        showNotification("Collection Point has been deactivated", "success");
        dispatch(fetchProducerPickupList(id));
      }
    })
    .catch((err) => {
      showNotification(
        err.response
          ? ERROR[err.response.data.message] || "Network Error"
          : err.message,
        "error"
      );
    });
};
