import { Stack } from "@mui/material";
import { CardPrimary, CardProfile } from "../../../../components/Card";
import {
  ZONE_IDENTIFICATION_MODAL,
  ZONE_DETAIL_MODAL,
  SUCCESS,
} from "../../../../utils/type";
import ZoneSerivce from "../../../../services/ZoneService";
import { useDispatch, useSelector } from "react-redux";
import { fetchZoneDetail, fetchZoneList } from "../../../../actions/zone";
import { showNotification } from "../../../../utils/helpers";
import {
  BACKOFFICE,
  ERROR,
  OWNER,
  SALES_MANAGER,
  WAREHOUSE_MANAGER,
} from "../../../../utils/constants";

const Profile = ({ props, extraInfo }) => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);

  return (
    <>
      <Stack gap={2}>
        <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
          <CardPrimary
            type={1}
            title={"Nº Producers"}
            content={extraInfo?.count_of_producers}
          />
          <CardPrimary
            type={2}
            title={"Nº Collection Points"}
            content={extraInfo?.count_of_pickup_points}
          />
          <CardPrimary
            type={3}
            title={"Total collected"}
            content={extraInfo?.total_collected}
          />
        </Stack>
        <Stack
          marginBottom={4}
          gap={2}
          direction={"row"}
          justifyContent={"flex-start"}
          flexWrap={"wrap"}
        >
          <Stack gap={2}>
            <CardProfile
              disabled={
                role !== BACKOFFICE &&
                role !== OWNER &&
                role !== WAREHOUSE_MANAGER &&
                role !== SALES_MANAGER
              }
              title={"Identification"}
              data={{
                name: props?.name,
                "UCO Network ID": props?.id,
              }}
              align={[1, 1]}
              type={ZONE_IDENTIFICATION_MODAL}
              onSave={(data) => {
                return new Promise((resolve, rej) => {
                  ZoneSerivce.updateZone(props._id, data)
                    .then((res) => {
                      if (res.data.message === SUCCESS) {
                        showNotification(
                          "Name changed successfully",
                          "success"
                        );
                        dispatch(fetchZoneDetail(props._id));
                        dispatch(fetchZoneList());
                        resolve(true);
                      } else {
                        rej(false);
                      }
                    })
                    .catch((err) => {
                      showNotification(
                        err.response
                          ? ERROR[err.response.data?.message] || "Network Error"
                          : err.message,
                        "error"
                      );
                      rej(false);
                    });
                });
              }}
            />
          </Stack>
          <Stack gap={2}>
            <CardProfile
              disabled={
                role !== BACKOFFICE &&
                role !== OWNER &&
                role !== WAREHOUSE_MANAGER &&
                role !== SALES_MANAGER
              }
              title={"Zone details"}
              data={{
                vehicle: props?.vehicle_name,
                driver: props?.driver_name,
                warehouse: props?.warehouse_name,
              }}
              warehouse={props?.warehouse}
              vehicle={props?.vehicle}
              driver={props?.driver}
              align={[1, 1, 1]}
              type={ZONE_DETAIL_MODAL}
              onSave={(data) => {
                return new Promise((resolve, rej) => {
                  ZoneSerivce.updateZone(props._id, data)
                    .then((res) => {
                      if (res.data.message === SUCCESS) {
                        showNotification(
                          "Detail changed successfully",
                          "success"
                        );
                        dispatch(fetchZoneDetail(props._id));
                        resolve(true);
                      } else {
                        rej(false);
                      }
                    })
                    .catch((err) => {
                      showNotification(
                        err.response ? err.response.data?.message : err.message,
                        "error"
                      );
                      rej(false);
                    });
                });
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
export default Profile;
