import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Grid, Button, Stack, Box } from "@mui/material";

import { classes } from "../../../utils/class";
import { showNotification } from "../../../utils/helpers";
import { SubTitle } from "../../../components/Typography";
import {
  CPhoneInput,
  CTextInput,
  CVatNumber,
} from "../../../components/CustomInput";
import { CModalChange } from "../../../components/CustomModal";
import { CustomMap } from "../../../components/CustomMap";
import { fetchAllRawWastes } from "../../../actions/waste";

const CreateCompanyIdPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [producerName, setProducerName] = useState("");
  const [companyTaxName, setCompanyTaxName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [vatCode, setVatCode] = useState("");
  const [vatNumber, setVatNumber] = useState();

  const [locationData, setLocationData] = useState(null);

  const [lineCode, setLineCode] = useState("");
  const [mobileCode, setMobileCode] = useState("");
  const [lineNumber, setLineNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [skipped, setSkipped] = useState(false);
  const [unsavedOpen, setUnsavedOpen] = useState(false);

  const [validation, setValidation] = useState(false);

  const handleSave = () => {
    const type = "producer";
    if (
      producerName &&
      companyTaxName &&
      companyEmail &&
      vatCode &&
      vatNumber
    ) {
      let data = {};
      if (skipped) {
        data = {
          type,
          producerName,
          companyTaxName,
          vatNumber,
          vatCode,
          companyEmail,
        };
      } else {
        data = {
          type,
          producerName,
          companyTaxName,
          vatNumber,
          vatCode,
          companyEmail,
          latitude: locationData?.latitude,
          longitude: locationData?.longitude,
          addrStreet: locationData?.addr_street,
          addrApartment: locationData?.addr_apartment,
          addrPostcode: locationData?.addr_postcode,
          addrCity: locationData?.addr_city,
          addrCountry: locationData?.addr_country,
          phone: lineNumber,
          mobilePhone: mobileNumber,
        };
      }
      setValidation(false);

      showNotification(
        "The company details were saved successfully.",
        "success"
      );
      navigate("/auth/reviewing-producer");
    } else {
      setValidation(true);
    }
  };

  const onCancelConfirm = () => {
    setUnsavedOpen(false);
    window.history.back();
  };

  const onCloseConfirm = () => {
    setUnsavedOpen(false);
  };

  const onSaveConfirm = () => {
    setUnsavedOpen(false);
    navigate("/auth/reviewing-producer");
  };

  useEffect(() => {
    dispatch(fetchAllRawWastes());
  }, []);

  return (
    <>
      <Grid
        sx={{ marginTop: 5 }}
        px={20}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              sx={classes.buttonCancel}
              onClick={() => {
                setUnsavedOpen(true);
              }}
            >
              Back
            </Button>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              sx={classes.buttonPrimary}
              onClick={handleSave}
            >
              Save Company ID
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Stack flexDirection={"column"} mx={20} my={10} alignItems={"center"}>
        <Stack>
          <Stack px={5} pb={5} minWidth={"460px"}>
            <Stack sx={{ paddingBottom: "32px" }}>
              <SubTitle>Setup organization</SubTitle>
            </Stack>
            <CTextInput
              required
              validation={validation}
              title="Producer name"
              placeholder="Enter your producer name"
              value={producerName}
              onChange={(value) => {
                setProducerName(value);
              }}
              sx={{ marginBottom: "24px" }}
            />
            <CTextInput
              required
              validation={validation}
              title="Company tax name"
              placeholder="Enter your company name"
              value={companyTaxName}
              onChange={(value) => {
                setCompanyTaxName(value);
              }}
              sx={{ marginBottom: "24px" }}
            />
            <CVatNumber
              countryCode={vatCode}
              label={"VAT number"}
              required
              validation={validation}
              value={vatNumber}
              onCountryChange={(e, data) => {
                setVatCode(data.code);
              }}
              onChange={(e) => {
                setVatNumber(e.target.value);
              }}
              sx={{ marginBottom: "24px" }}
            />
            <CTextInput
              required
              validation={validation}
              title="Company email"
              placeholder="Enter company e-mail"
              value={companyEmail}
              onChange={(value) => {
                setCompanyEmail(value);
              }}
            />
          </Stack>
          {!skipped && (
            <Stack
              padding={4}
              sx={{
                backgroundColor: "background.lighter",
                borderRadius: "10px",
              }}
            >
              <Stack
                flexDirection={"row"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
              >
                <Stack>
                  <SubTitle>Other configurations</SubTitle>
                  <Box
                    width="325px"
                    mt={2}
                    sx={{
                      fontSize: "10px",
                      fontWeight: "700",
                      lineHeight: "15px",
                    }}
                  >
                    The forms below allow you to further configure your account.
                    They are not mandatory and you can complete them at any time
                    in your account settings.
                  </Box>
                </Stack>
                <Button
                  sx={classes.buttonPrimary}
                  onClick={() => {
                    setSkipped(true);
                  }}
                >
                  Skip
                </Button>
              </Stack>
              <Stack direction={"row"} gap={4} width={"100%"} pt={5}>
                <Stack gap={1} width={"100%"}>
                  <CustomMap
                    direction="column"
                    description={"Organization"}
                    data={locationData}
                    onChange={(data) => {
                      setLocationData(data);
                    }}
                    mapHeight="300px"
                  />
                  <Stack spacing={2} sx={{ width: "100%" }} my={2}>
                    <Typography
                      sx={{
                        fontFamily: "Manrope",
                        fontSize: "18px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      Company coordinates
                    </Typography>
                    <CPhoneInput
                      fullWidth
                      label="Phone"
                      countryCode={lineCode}
                      onCountryChange={(e, data) => {
                        setLineCode(data.code);
                      }}
                      value={lineNumber}
                      onChange={(e) => {
                        setLineNumber(e.target.value);
                      }}
                    />
                    <CPhoneInput
                      fullWidth
                      label="Mobile Phone"
                      countryCode={mobileCode}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                      value={mobileCode}
                      onCountryChange={(e, data) => {
                        setMobileCode(data.code);
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      <CModalChange
        open={unsavedOpen}
        confirmValue="Continue editing"
        handleCancel={onCancelConfirm}
        handleClose={onCloseConfirm}
        handleSave={onSaveConfirm}
      />
    </>
  );
};

export default CreateCompanyIdPage;
