import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { Box, Stack, Typography, Tooltip, Skeleton } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";

// project imports
import { CardPrimary } from "../../../components/Card";
import { SearchBar } from "../../../components/CustomInput";
import { EditDetail } from "../../../components/StateSpan";
import { DataTable } from "../../../components/Table";

// sx
import { classes } from "../../../utils/class";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { fetchVisitsList } from "../../../actions/visit";
import { PURPOSE_TYPES, getDateStringFromNum } from "../../../utils/helpers";
import { setSelectedVisitId } from "../../../slices/visitsSlice";

// action import
const VisitsView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { visits: visitsData, total_collected } = useSelector(
    (state) => state.visits
  );
  const orgData = useSelector((state) => state.organizations.profile);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    dispatch(
      fetchVisitsList(false, () => {
        setIsLoaded(true);
      })
    );
  }, []);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">
          {`${getDateStringFromNum(props.row.date, orgData.timezone)}`}
        </span>
      ),
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 150,
      renderCell: (props) => (
        <span className="mui-ellipsis">{PURPOSE_TYPES[props.row.purpose]}</span>
      ),
    },
    {
      field: "pickup_point_name",
      headerName: "Collection Point",
      width: 200,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "transfer",
      headerName: "Transfer",
      width: 500,
      renderCell: (props) => {
        const { quantity, ratio } = props.row;
        if (!quantity) return;
        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: "60px" }}>{quantity} kg</Typography>
            <Typography sx={{ color: ratio >= 0 ? "green" : "red" }}>
              {ratio > 0 && "+"}
              {ratio}%
            </Typography>
            <Typography>{props.row.raw_waste_name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "member_name",
      headerName: "Member",
      width: 300,
      renderCell: (props) => (
        <span className="mui-ellipsis">{props.value}</span>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 100,
      renderCell: (props) => {
        return (
          <>
            <div style={{ width: "60px", display: "flex" }}>
              {props.row.notes && (
                <Tooltip
                  slotProps={{
                    tooltip: {
                      sx: {
                        background: "black",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "black",
                      },
                    },
                  }}
                  title={props.row.notes}
                >
                  <SpeakerNotesOutlinedIcon />
                </Tooltip>
              )}
            </div>
            <EditDetail
              options={
                props.row.purpose === "waste_pickup"
                  ? [
                      { label: "View Visit", value: "visit" },
                      { label: "View Transfer", value: "transfer" },
                    ]
                  : [{ label: "View Visit", value: "visit" }]
              }
              props={props}
              onStateChangeHandler={async (status) => {
                if (status === "visit") {
                  dispatch(setSelectedVisitId(props.row.id));
                  navigate("/visits-detail");
                } else if (status === "transfer") {
                  navigate(`/transfer-detail/${props.row.transfer}/view`);
                }
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          gap: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            {isLoaded ? (
              <CardPrimary
                type={1}
                title="Nº Visits"
                content={visitsData.length}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"82px"} />
            )}
            {isLoaded ? (
              <CardPrimary
                type={2}
                title="Total collected"
                content={total_collected}
              />
            ) : (
              <Skeleton variant="rounded" width={"162px"} height={"82px"} />
            )}
          </Box>
        </Box>
        <Box sx={{ marginTop: "20px", width: "300px" }}>
          {isLoaded ? (
            <SearchBar
              label="Search visit"
              onSearchChange={(value) => {
                setFilterModel({
                  items: [
                    {
                      id: 1,
                      field: "pickup_point_name",
                      operator: "contains",
                      value: value,
                    },
                  ],
                });
              }}
            />
          ) : (
            <Skeleton variant="rounded" width={"100%"} />
          )}
        </Box>
      </Box>
      <Box>
        <Box sx={{ maxWidth: `${window.innerWidth - 400}px` }}>
          {isLoaded ? (
            <DataTable
              fit="100%"
              breakpoint="xl"
              rows={visitsData}
              columns={columns}
              sx={classes.grid}
              filterModel={filterModel}
              onRowClick={(params, events, details) => {
                dispatch(setSelectedVisitId(params.row.id));
                navigate("/visits-detail");
              }}
            />
          ) : (
            <Skeleton width={"100%"} height={"200px"} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default VisitsView;
