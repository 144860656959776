import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Modal,
  Stack,
  Button,
  Typography,
  Divider,
} from "@mui/material";

import { PageTitle } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";

import { CheckBoxes } from "../../../../components/CustomInput";
import { CExpandDetail } from "../../../../components/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchInventoryCategory } from "../../../../actions/inventory";

const FiltersDialog = ({
  open = false,
  selectedOptions = {
    category: {},
    availableTo: {},
    status: {},
  },
  handleClose = function () {},
  handleContinue = function () {},
}) => {
  const [filterModel, setFilterModel] = useState({
    ...selectedOptions,
  });
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.inventory.categories);

  useEffect(() => {
    dispatch(fetchInventoryCategory());
  }, [dispatch]);
  useEffect(() => {
    setFilterModel(selectedOptions);
  }, [selectedOptions]);
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "text.contrast",
            Width: "520px",
            maxWidth: "520px",
            minWidth: "520px",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Stack position={"relative"}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} padding={5}>
            <PageTitle>Filters</PageTitle>
            <CExpandDetail
              title="Category"
              expanded={true}
              component={
                <>
                  <Stack>
                    <CheckBoxes
                      options={categories.map(
                        (category, index) => category.name
                      )}
                      selectedOptions={filterModel.category}
                      onChange={(value, checked) => {
                        let t = { ...filterModel.category };
                        t[value] = checked;
                        setFilterModel({
                          ...filterModel,
                          category: t,
                        });
                      }}
                    />
                  </Stack>
                </>
              }
            />
            <Divider />
            <CExpandDetail
              title="Available to"
              expanded={true}
              component={
                <>
                  <Stack>
                    <CheckBoxes
                      selectedOptions={filterModel.availableTo}
                      options={["Available to Driver", "Available to Client"]}
                      onChange={(value, checked) => {
                        let t = { ...filterModel.availableTo };
                        t[value] = checked;
                        setFilterModel({
                          ...filterModel,
                          availableTo: t,
                        });
                      }}
                    />
                  </Stack>
                </>
              }
            />
            <Divider />
            <CExpandDetail
              title="Status"
              expanded={true}
              component={
                <>
                  <Stack>
                    <CheckBoxes
                      selectedOptions={filterModel.status}
                      options={["Available", "Unavailable"]}
                      onChange={(value, checked) => {
                        let t = { ...filterModel.status };
                        t[value] = checked;
                        setFilterModel({
                          ...filterModel,
                          status: t,
                        });
                      }}
                    />
                  </Stack>
                </>
              }
            />
            <Divider />
            <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
              <Button sx={classes.buttonCancel} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                sx={classes.buttonSave}
                onClick={() => {
                  handleContinue(filterModel);
                  handleClose();
                }}
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default FiltersDialog;
