import { Stack, Typography } from "@mui/material";
import { CardPrimary } from "../../../../components/Card";
import { DataTable } from "../../../../components/Table";
import { Euro, Percentage } from "../../../../components/Typography";
import { classes } from "../../../../utils/class";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";

const BalanceHistory = ({ props, extraInfo }) => {
  const rows = [
    {
      id: 1,
      hour: "11:32",
      description: "Waste pickup",
      transfer: {
        amount: "100",
        percentChange: -100,
      },
      amount: 22,
      afterTransaction: 167,
      member: "Britini Lando",
      note: "Hello world",
    },
  ];
  const columns = [
    {
      field: "hour",
      headerNanme: "Hour",
      width: 100,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "transfer",
      headerName: "Transfer",
      width: 200,
      // valueGetter: (props) => {
      //   return props.row.transfer.amount;
      // },
      renderCell: (props) => {
        return (
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Typography>{props.row.transfer.amount}</Typography>
            <Percentage value={props.row.transfer.percentChange} />
            <Typography sx={classes.link} onClick={() => {}}>
              View transfer
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      renderCell: (props) => {
        return <Euro value={props.row.amount} />;
      },
    },
    {
      field: "afterTransaction",
      headerName: "After transaction",
      width: 100,
      renderCell: (props) => {
        return <Euro value={props.row.afterTransaction} />;
      },
    },
    {
      field: "member",
      headerName: "Member",
      width: 200,
      renderCell: (props) => {
        return (
          <>
            <Stack direction={"row"} gap={1}>
              <Stack>{props.row.member}</Stack>
              <Stack>
                <CommentOutlinedIcon sx={{ color: "text.accent" }} />
              </Stack>
            </Stack>
          </>
        );
      },
    },
  ];
  return (
    <Stack gap={2} position={"relative"}>
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        <CardPrimary
          type={1}
          title={"Balance"}
          content={extraInfo?.balance || 0}
        />
        <CardPrimary
          type={2}
          title={"Next collection"}
          content={`in ${extraInfo?.next_picking || 0} days`}
        />
        <CardPrimary
          type={3}
          title={"Expiring documents"}
          content={extraInfo?.count_expiring_documents || 0}
        />
      </Stack>
      <Stack>
        <DataTable rows={rows} columns={columns} />
      </Stack>
      <Stack
        position={"absolute"}
        sx={{
          backgroundColor: "#ffffffaf",
          textAlign: "center",
          fontWeight: 700,
        }}
        justifyContent={"center"}
        top={0}
        left={0}
        width={"100%"}
        height={"100%"}
      >
        Coming Soon
      </Stack>
    </Stack>
  );
};

export default BalanceHistory;
